export default {
  'component.officeceo.title': 'Daglig leder',
  'component.officeceo.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officeceo.addofficeceo': 'Legg til daglig leder',
  'component.officeceo.addofficeceo.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officeceo.editofficeceo': 'Rediger daglig leder',
  'component.officeceo.feedback': 'Tilbakemelding',
  'component.officeceo.media': 'Presse',
  'component.officeceo.editofficeceo.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officeceo.tablesearch': 'Søk etter navn, e-post, telefon og tittel',
  'component.officeceo.deletemodal.title': 'Slett daglig leder',
  'component.officeceo.deletemodal.text': 'Er du sikker på at du vil slette denne daglig leder?',
};
