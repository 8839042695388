import React from 'react';
import {Button, Col, Row, Typography} from 'antd';
import {decryptId} from '../../../utils/utils';
import {FINAL_SUBMIT_AGREEMENT, FIRST_SELLER_SUBMIT} from '../../../../query/AgentQuery';
import {useQuery} from "@apollo/react-hooks";

// import { formatMessage } from 'umi-plugin-react/locale';
// import Wlogo from '../assets/webmegling_inner_logo.svg';

const {Title} = Typography;

export default function congratulations(props) {
    const {match, location} = props;
    let router = props.history;

    let params = JSON.parse(decryptId(props.match.params.totalOwner))

    let variables;
    if (params.total_owners === 1) {
        variables = {
            input: params
        }
    } else {
        variables = {
            email_verify: params?.is_email,
            property_id: params?.property_id
        }
    }
    params.is_email === 0 ? useQuery(params.total_owners === 1 ? FIRST_SELLER_SUBMIT : FINAL_SUBMIT_AGREEMENT, {
        variables: variables
    }) : null;
    return (
        <section
            className="d-flex w-100 h-100 bg-primary"
            style={{justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}
        >
            <div className="text-center">
                {/* <img src={Wlogo} alt="logo" className="img-fluid mb-20" style={{ maxWidth: '16%' }} /> */}
                <Title
                    level={1}
                    className="text-secondary  mb-30"
                >
                    Gratulerer!
                </Title>
                <p className="text-white text-large mb-30">Oppdragsavtalen er nå signert</p>
                <Row className="">
                    <Col xs={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => router.replace({
                                pathname: `/`
                            })}
                            className="btn-secondary buttons buttons-large admin-forms-btn back-button ml-0"
                            size="large"
                            style={{paddingLeft: '50px', paddingRight: '50px'}}
                        >
                            Hjem
                        </Button>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
