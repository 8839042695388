import React from 'react'
import {ActivityIndicator, Animated, View} from 'react-native'
import {Button} from "@ant-design/react-native/lib/button";

import {connect} from "react-redux";
import {CustomButton, Label} from "components/src/components";

import {Query} from "@apollo/react-components";

import styles from './styles.js';
import {Color, CommonStyle, DateUtils, Strings, ThemeUtils} from "components/src/utils";
import {SELLER_INTERVIEW} from "../../../../../../../api/query";
import moment from "moment";

// import {Button, InputItem} from "@ant-design/react-native";


// import { DatePickerView } from 'antd-mobile';
const STEPS = [
    `${Strings.ownerShip} / ${Strings.property}`,
    Strings.location,
    Strings.standard,
    `${Strings.sammie}/ ${Strings.skinAssociation} / ${Strings.veilag} etc`,
    Strings.rights,
    Strings.vvs,
    Strings.technicalInstallations,
    Strings.appliances,
    Strings.other
];
const questions = [{
    id: 1,
    question: 'When was the property purchased and Why?'
}, {
    id: 2,
    question: 'What is it that leads to the property being sold?'
}, {
    id: 3,
    question: 'What do you like most about the home?'
}, {
    id: 4,
    question: 'Have any work required to apply for the housing that has not been notified?'
}];

const qData = [
    {
        "question": "What is the reason for you to sell the property?",
        "interview_section": 1,
        "question_no": 1,
        "type": "TEXT",
        "answer": "QUestion 1",
        "extra": null,
        "comment": null
    },
    {
        "question": "What do you like best about the home?",
        "interview_section": 1,
        "question_no": 2,
        "type": "TEXT",
        "answer": "Question 2",
        "extra": null,
        "comment": null
    },
    {
        "question": "What facilities apply to your home?",
        "interview_section": 2,
        "question_no": 3,
        "type": "CHECKBOX",
        "answer": [
            "Air Conditioning",
            "Alarm",
            "Resorts",
            "Balcony/ Terrace"
        ],
        "extra": null,
        "comment": null
    },
    {
        "question": "What do you like best about the location of the home?",
        "interview_section": 2,
        "question_no": 4,
        "type": "TEXT",
        "answer": "Question 3",
        "extra": null,
        "comment": null
    },
    {
        "question": "Are there any hiking/ activity areas that should be highlighted?",
        "interview_section": 2,
        "question_no": 5,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "Question 4"
    },
    {
        "question": "Briefly describe who your closest neighbors are, for example: \"in the apartment on the right there lives a nice couple in their mid-30s\"",
        "interview_section": 2,
        "question_no": 6,
        "type": "TEXT",
        "answer": "Question 5",
        "extra": null,
        "comment": null
    },
    {
        "question": "Do many families with children live in the area?",
        "interview_section": 2,
        "question_no": 7,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "Question 6"
    },
    {
        "question": "How are the sun conditions",
        "interview_section": 2,
        "question_no": 8,
        "type": "DROPDOWN",
        "answer": [
            {
                "Midtsommers": [
                    "05:02:48",
                    "05:02:50"
                ],
                "Vinterstid": [
                    "05:53:37",
                    "05:02:52"
                ]
            }
        ],
        "extra": null,
        "comment": ""
    },
    {
        "question": "Describe what, when and by whom?",
        "comment_questions": "Are there any other qualities of the home you would like to highlight?",
        "interview_section": 3,
        "question_no": 9,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "Question 8"
    },
    {
        "question": "Have any upgrades / maintenance been done that should be highlighted?",
        "interview_section": 3,
        "question_no": 10,
        "type": "TEXT",
        "answer": "Question 9",
        "extra": null,
        "comment": null
    },
    {
        "question": "Is there a compulsory membership in welfare society?",
        "comment_questions": [
            "Contact information for the leader of the welfare society",
            "Contact info for chairman"
        ],
        "interview_section": 4,
        "question_no": 11,
        "type": "RADIO",
        "answer": 'No',
        "extra": null,
        "comment": [
            {
                "welfaresociety": {
                    "name": "sdfsdfff",
                    "phone": "123456",
                    "email": "sdfsdf"
                }
            },
            {
                "chairman": {
                    "name": "sdfsdfff",
                    "phone": "123456",
                    "email": "sdfsdf"
                }
            }
        ]
    },
    {
        "question": "How much do you pay in joint costs?",
        "interview_section": 4,
        "question_no": 12,
        "type": "TEXT",
        "answer": "4553453",
        "extra": 'Yes',
        "comment": null
    },
    {
        "question": "What do the common costs include?",
        "interview_section": 4,
        "question_no": 13,
        "type": "TEXT",
        "answer": "sdfd",
        "extra": null,
        "comment": null
    },
    {
        "question": "Are there any related duties such as downing / broyting or the like?",
        "interview_section": 4,
        "question_no": 14,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "fgfdgdfg"
    },
    {
        "question": "Are there any agreements, rights or obligations associated with the property?",
        "interview_section": 5,
        "question_no": 14,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": ""
    },
    {
        "question": "Is there a garage / parking space attached to the home?",
        "comment_questions": "If so, can this be documented?",
        "interview_section": 5,
        "question_no": 15,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": 'No',
        "comment": "dfd"
    },
    {
        "question": "Is it associated with common areas / real estate (playground, ball court etc.)?",
        "comment_questions": "What and where this is?",
        "interview_section": 5,
        "question_no": 16,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "sdfsdff"
    },
    {
        "question": "What are the heat sources in the home?",
        "interview_section": 6,
        "question_no": 17,
        "type": "CHECKBOX",
        "answer": [
            {
                "heat_pump": [
                    "Air to air",
                    "Air to water"
                ]
            },
            {
                "fireplace": [
                    "Bio-fireplace"
                ]
            },
            {
                "berth": [
                    "Heating cables",
                    "Radiator",
                    "Balanced ventilation with heat recovery",
                    "Heat pump",
                    "Fireplace",
                    "Wood Stove"
                ]
            }
        ],
        "extra": null,
        "comment": "dsfdsfdsf"
    },
    {
        "question": "Are pipes in the home changed?",
        "interview_section": 6,
        "question_no": 18,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": null
    },
    {
        "question": "Where is it slated and when?",
        "interview_section": 6,
        "question_no": 19,
        "type": "TEXT",
        "answer": "sdfdfsf",
        "extra": null,
        "comment": null
    },
    {
        "question": "Where is the hot water tank located?",
        "interview_section": 6,
        "question_no": 20,
        "type": "TEXT",
        "answer": "sdfdsff",
        "extra": null,
        "comment": null
    },
    {
        "question": "Who is the provider of the internet?",
        "interview_section": 7,
        "question_no": 21,
        "type": "DROPDOWN",
        "answer": "Altibox",
        "extra": null,
        "comment": null
    },
    {
        "question": "What kind of internet is it?",
        "interview_section": 7,
        "question_no": 22,
        "type": "DROPDOWN",
        "answer": "Other",
        "extra": null,
        "comment": null
    },
    {
        "question": "Who is the provider of TV?",
        "interview_section": 7,
        "question_no": 23,
        "type": "DROPDOWN",
        "answer": "Viasat",
        "extra": null,
        "comment": null
    },
    {
        "question": "How are the TV signals delivered?",
        "interview_section": 7,
        "question_no": 24,
        "type": "DROPDOWN",
        "answer": "Satellite Dish",
        "extra": null,
        "comment": null
    },
    {
        "question": "How high is the annual average power consumption?",
        "interview_section": 7,
        "question_no": 25,
        "type": "TEXT",
        "answer": "fdsfsfssf",
        "extra": null,
        "comment": null
    },
    {
        "question": "Where is the home's security cabinet located?",
        "interview_section": 7,
        "question_no": 26,
        "type": "TEXT",
        "answer": "sdfsdfsdf",
        "extra": null,
        "comment": null
    },
    {
        "question": "Are there any appliances that should not be included in the trade?",
        "comment_questions": "Where is it slated and when?",
        "interview_section": 8,
        "question_no": 27,
        "type": "RADIO",
        "answer": 'No',
        "extra": null,
        "comment": "dfsf"
    },
    {
        "question": "Should a washer or dryer be included in the trade?",
        "interview_section": 8,
        "question_no": 28,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "sdfsdf"
    },
    {
        "question": "Norges Eiendomsmeglerforbund has compiled a list of movable property and contents that will accompany the property on sale: https://www.nef.no/losore-og-tilbehor/ Is there anything on this list that should not be included when you are going to sell?",
        "interview_section": 9,
        "question_no": 29,
        "type": "RADIO",
        "answer": 'Yes',
        "extra": null,
        "comment": "sdfsdf"
    },
    {
        "question": "Is there any other information you think is relevant to informing a real estate agent and buying about?",
        "interview_section": 9,
        "question_no": 30,
        "type": "TEXT",
        "answer": "fsdf",
        "extra": null,
        "comment": null
    },
    {
        "question": "When do you want to take over the property?",
        "interview_section": 9,
        "question_no": 31,
        "type": "DATEPICKER",
        "answer": "2020-05-05",
        "extra": null,
        "comment": "fgfdsgg"
    }
];

class SellerInterview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStep: 0,
            answers: {},
            qData: []
        };
        this.scrollY = new Animated.Value(0)
    }


    nextStep = () => {
        if (this.state.selectedStep < STEPS.length - 1) {
            this.setState(prevState => ({
                selectedStep: prevState.selectedStep + 1
            }))
        }
    };
    prevStep = () => {
        if (this.state.selectedStep > 0) {
            this.setState(prevState => ({
                selectedStep: prevState.selectedStep - 1
            }))
        }
    };


    renderQuestion = (item, index) => {
        if (!item) {
            return;
        }
        return (
            <>
                <Label small
                       font_medium
                       mt={15}
                       mb={10}>
                    {item.question}
                </Label>
                <Label small>
                    {item.answer}
                </Label>

                {item.comment_questions ? (
                    <Label small
                           font_medium
                           mt={15}
                           mb={10}>
                        {item.comment_questions}
                    </Label>
                ) : null}

                {item.extra ? (
                    <Label small>
                        {item.extra}
                    </Label>
                ) : null}
                {item.comment ? (
                    <Label small>
                        {`${Strings.comment}${item.comment}`}
                    </Label>
                ) : null}
            </>
        )
    };

    renderFirstStep = () => {
        return this.state.qData.filter(item => item.interview_section === 1).map(this.renderQuestion)
    };

    renderSecondStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 2);
        return (
            <View>
                {this.renderQuestion({question: data[0]?.question, answer: data[0]?.answer.join(', ')})}
                {this.renderQuestion(data[1])}
                {this.renderQuestion(data[2])}
                {this.renderQuestion(data[3])}
                {this.renderQuestion(data[4])}
                {this.renderQuestion({
                    question: data[5].question,
                    comment: data[5].comment,
                    answer: `${Strings?.midSummer} ${moment(data[5].answer[0].Midtsommers[0], DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(data[5].answer[0].Midtsommers[1], DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                    \n${Strings.midWinter} ${moment(data[5].answer[0].Vinterstid[0], DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(data[5].answer[0].Vinterstid[1], DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`
                })}
            </View>
        )
    };

    renderThirdStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 3);
        return data.map(this.renderQuestion)
    };

    renderFourthStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 4);
        return (
            <View>
                {this.renderQuestion({
                    question: data[0]?.question,
                    answer: data[0]?.answer,
                    //TODO:BAKI
                })}
                {this.renderQuestion(data[1])}
                {this.renderQuestion(data[2])}
                {this.renderQuestion(data[3])}
            </View>
        )
    };

    renderFifthStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 5);
        return data.map(this.renderQuestion)
    };

    renderSixthStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 6);
        return (
            <View>
                {this.renderQuestion(data[1])}
                {this.renderQuestion(data[2])}
                {this.renderQuestion(data[3])}
            </View>
        )
    };

    renderSeventhStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 7);
        return data.map(this.renderQuestion)
    };

    renderEightthStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 8);
        return data.map(this.renderQuestion)
    };

    renderNinethStep = () => {
        let data = this.state.qData.filter(item => item.interview_section === 9);
        return (
            <View>
                {this.renderQuestion(data[0])}
                {this.renderQuestion(data[1])}
                {data[2] ? this.renderQuestion({...data[2], ...{answer: moment(data[2]?.answer, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}}) : null}
            </View>
        )
    };


    renderStep = () => {
        switch (this.state.selectedStep) {
            case 0:
                return this.renderFirstStep();
            case 1:
                return this.renderSecondStep();
            case 2:
                return this.renderThirdStep();
            case 3:
                return this.renderFourthStep();
            case 4:
                return this.renderFifthStep();
            case 5:
                return this.renderSixthStep();
            case 6:
                return this.renderSeventhStep();
            case 7:
                return this.renderEightthStep();
            case 8:
                return this.renderNinethStep()

        }
    };

    render() {
        let {selectedStep} = this.state;

        return (
            <View style={CommonStyle.flex}>

                <Query query={SELLER_INTERVIEW}
                       onCompleted={(data) => {
                           if (data?.moovin_seller_interviews?.answer_json) {
                               this.setState({
                                   qData: JSON.parse(data?.moovin_seller_interviews?.answer_json)
                               })
                           }
                       }}
                       variables={{
                           propertyId: this.props.propertyId,
                           status: 1
                       }}>
                    {({loading, data, error}) => {
                        if (loading && !data?.moovin_seller_interviews) {
                            return (
                                <ActivityIndicator color={Color.PRIMARY}
                                                   style={CommonStyle.loader}/>
                            )
                        }
                        if (!data?.moovin_seller_interviews) {
                            return (
                                <Label font_medium
                                       style={CommonStyle.loader}
                                       align={'center'}>
                                    {"Ingen data"}
                                </Label>
                            )
                        }
                        return (
                            <>
                                <Animated.ScrollView scrollEventThrottle={1}
                                                     contentContainerStyle={{paddingBottom: ThemeUtils.APPBAR_HEIGHT}}>


                                    <View style={styles.container}>
                                        <View style={styles.topContainer}>
                                            <View style={styles.labelContainer}>
                                                <Label font_medium
                                                       mb={10}
                                                       align={'left'}>
                                                    {`${selectedStep + 1}. ${STEPS[selectedStep]}`}
                                                </Label>
                                            </View>
                                            {this.renderStep(data?.moovin_seller_interviews)}
                                        </View>

                                    </View>

                                </Animated.ScrollView>

                                <View style={styles.bottomNavCont}>
                                    <CustomButton borderColor={Color.PRIMARY_LIGHT}
                                                  borderWidth={1}
                                                  style={{flex: 0.5}}
                                                  onPress={this.prevStep}
                                                  textColor={Color.PRIMARY_LIGHT}
                                                  title={Strings.prev}
                                                  bgColor={Color.WHITE}/>

                                    <Label small
                                           font_medium
                                           align={'center'}
                                           style={CommonStyle.flex}
                                           color={Color.PRIMARY}>
                                        {`${selectedStep + 1} / ${STEPS.length}`}
                                    </Label>
                                    <CustomButton
                                        style={{flex: 0.5}}
                                        onPress={this.nextStep}
                                        textColor={Color.WHITE}
                                        title={Strings.next}
                                    />
                                </View>
                            </>
                        )
                    }}

                </Query>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SellerInterview);
