import {StyleSheet} from 'react-native'
import {Color, IS_WEB, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    bubbleContRight: {
        marginVertical:ThemeUtils.relativeRealHeight(1),
        marginEnd:ThemeUtils.relativeRealWidth(6),
        maxWidth:ThemeUtils.relativeRealWidth(70),
        alignSelf:'flex-end'
    },
    bubbleContLeft: {
        marginVertical:ThemeUtils.relativeRealHeight(1),
        maxWidth:ThemeUtils.relativeRealWidth(70),
        marginStart:ThemeUtils.relativeRealWidth(6),
        alignSelf:'flex-start'
    },
    lblContRight:{
        backgroundColor: Color.PRIMARY,
        paddingVertical:ThemeUtils.relativeRealWidth(2.5),
        paddingHorizontal:ThemeUtils.relativeRealWidth(3.5),
        borderRadius: ThemeUtils.relativeRealWidth(3),
        borderBottomEndRadius: 0,
    },
    lblContLeft:{
        backgroundColor: Color.SECONDARY,
        paddingVertical:ThemeUtils.relativeRealWidth(2.5),
        paddingHorizontal:ThemeUtils.relativeRealWidth(3.5),
        borderRadius: ThemeUtils.relativeRealWidth(3),
        borderBottomStartRadius: 0,
    }
})
