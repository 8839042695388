import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    contentCont: {
        marginHorizontal: ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    btnCont: {
        flexDirection: 'row',
        marginBottom: 15
    },
    bottomButtonContainer: {
        marginHorizontal: ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: 10,
        alignItems: 'center'
    },
    serviceCont: {

        paddingHorizontal: ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    billCont: {
        backgroundColor: Color.PRIMARY,
        paddingVertical: 10,
        marginVertical: ThemeUtils.relativeRealHeight(2)
    },
    billListCont: {
        backgroundColor: Color.PRIMARY,
        paddingVertical: 10,

    },
    lineStyle: {
        backgroundColor: Color.WHITE
    },
    rebateLblCont: {
        flexDirection: 'row',
        alignItems: "center",
        marginVertical: ThemeUtils.relativeRealHeight(2)
    },
    chk: {
        marginStart: 10
    }

})
