import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, SafeAreaView, ScrollView, View} from 'react-native';
import {
    CustomButton,
    Dashboard,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    Modal,
    PagerTabIndicator,
    Ripple
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    DateUtils,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AGENT_INSPECTION_PROPERTY_LIST, ASSIGN_UTTLEG_SERVICE} from "components/src/api/query";

import energy_labeling from "../../../../assets/images/W_Befaring.jpg";
import photo from "../../../../assets/images/Photo.png";
import digitalMarketing from "../../../../assets/images/Digital_marketing.jpg";
import marketingMaterial from "../../../../assets/images/Print_Marketing.jpg";
import Search from './InpectionApplicationSearch'
import moment from "moment";
import {connect} from "react-redux";
import {setCurrentInspectionDetails} from "../../../../redux/action";


const DASHBOARD = [{
    name: `${Strings.photo} / ${Strings.styling}`,
    icon: 'photo',
    bg: photo,
    route: Routes.PhotoStyling
}, {
    name: Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
    route: Routes.DigitalMarketing
}, {
    name: Strings.printMarketing,
    icon: 'print-marketing',
    bg: marketingMaterial,
    route: Routes.PrintMarketing
}, {
    name: Strings.wBefaring,
    icon: 'search',
    bg: energy_labeling,
    route: Routes.WBefaring
}];

const TABS = [{
    text: Strings.propertyList,
}, {
    text: Strings.personalTemplate
}];

function PropertyList(props) {
    const {navigation, history} = props;
    const scrollY = new Animated.Value(0)
    const [isSearchVisible, setSearchVisible] = useState(false)
    const [currentScreen, setCurrentScreen] = useState(0);
    const [isFetchMore, setFetchMore] = useState(false);


    const [assignUttleg] = useMutation(ASSIGN_UTTLEG_SERVICE);

    const {loading, data, error, refetch, fetchMore} = useQuery(AGENT_INSPECTION_PROPERTY_LIST, {
        variables: {
            input: {
                first: 30,
                page: 1,
                status: true,
                sorting: {
                    field: "id",
                    order: "ASC"
                }
            }
        }
    });

    const navigate = (item) => {
        setSearchVisible(false)

        assignUttleg({
            variables: {
                input: {
                    property_id: item?.id
                }
            }
        }).then(() => {
            if (IS_WEB) {
                history.push(`${Routes.InspectionPropertyDetails}/${encryptId(item.id)}`, {
                    property: item
                })
            } else {
                navigation.navigate(Routes.InspectionPropertyDetails, {
                    property: item
                })
            }
        }).catch((e) => {
            console.log(e, "ERRR")
        })

    };

    const renderPropertyList = ({item, index}) => {

        let primarySeller = item?.property_seller.find(seller => seller?.is_primary_seller)
        return (
            <>

                <Ripple style={styles.ProCont}
                        onPress={() => navigate(item)}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View style={styles.content}>
                            <Label small
                                   mt={10}
                                   font_medium
                                   numberOfLines={3}>
                                {`${item?.street_adress} ${item?.city ?? ""} ${item?.zipcode ?? ""}`}
                            </Label>
                            <Label small
                                   mt={10}
                                   font_regular
                                   mb={5}
                                   color={Color.SECONDARY_TEXT_COLOR}
                                   numberOfLines={2}>
                                {`${primarySeller?.firstName ?? ""} ${primarySeller?.lastName ?? ""}`}
                            </Label>
                            {item?.property_inspection?.start_time ? (
                                <Label small
                                       mt={10}
                                       font_regular
                                       mb={5}
                                       color={Color.SECONDARY_TEXT_COLOR}
                                       numberOfLines={2}>
                                    {`${moment(item?.property_inspection?.start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}${item?.property_inspection?.start_date ? ", " + moment(item?.property_inspection?.start_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : ""}`}
                                </Label>) : item?.propertyActivity?.start_date ? (
                                <Label small
                                       mt={10}
                                       font_regular
                                       mb={5}
                                       color={Color.SECONDARY_TEXT_COLOR}
                                       numberOfLines={2}>
                                    {`${moment(item?.propertyActivity?.start_date, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.hh_mm_dd_mm_yyy)}`}
                                </Label>
                            ) : null}
                        </View>
                        <View style={{alignSelf: 'center'}}>
                            <Icon name={'drop-normal'}
                                  color={Color.SECONDARY_TEXT_COLOR}/>
                        </View>
                    </View>

                </Ripple>
                <Hr/>
            </>
        )
    };

    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Search onClose={() => setSearchVisible(false)}
                                renderItem={renderPropertyList}/>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}/>
        )
    };

    const handleDashboardItemPress = route => {
        if (IS_WEB) {
            props.history.push(`${Routes.InspectionApplication}/${route}`)
        } else {
            props.navigation.navigate(route)
        }
    }

    return (
        <View style={styles.container}>
            {renderSearchModal()}
            <Header navigation={IS_WEB ? history : navigation}
                    headerRightFirst={currentScreen === 0 ? 'search' : null}
                    onPressRightFirst={() => setSearchVisible(true)}
                    style={CommonStyle.blankHeader}
            />

            <View style={styles.cont}>

                <Icon name={'inspection-application'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY_TEXT_COLOR}/>

                <Label xlarge
                       font_medium
                       ml={10}>
                    {Strings.inspectionApplications}
                </Label>

            </View>

            <IndicatorViewPager indicator={renderTabIndicator()}
                                onPageSelected={(e) => setCurrentScreen(e.position)}
                                style={CommonStyle.flex}>

                <View>


                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <ScrollView>
                            <Label small
                                   mt={20}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {'Nedenfor finner du alle eiendommer som er klare til befaring. Husk at følgende felter er obligatoriske før du starter en befaring: Eiendommens addresse, selger informasjon med navn, epost og telefonnummer, eiendomstype, eierform, Hovedmegler/saksbehandler, og Ansvarlig megler.'}
                            </Label>
                            <Label small
                                   mt={5}
                                   mb={15}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {'Dersom eiendommen ikke vises i listen nedenfor, sjekk at all informasjon er lagt inn i Vitec Next.'}
                            </Label>
                            <Hr/>
                            <FlatList data={data?.office_properties?.data ?? []}
                                      onRefresh={refetch}
                                      refreshing={loading}
                                      renderItem={renderPropertyList}
                                /*ListEmptyComponent={
                                    <View style={CommonStyle.content_center}>
                                        <Label mt={40}>
                                            {Strings.noOrder}
                                        </Label>
                                    </View>
                                }*/
                                      ListFooterComponent={

                                          data?.office_properties?.paginatorInfo?.hasMorePages ?
                                              (
                                                  <CustomButton title={Strings.loadMore}
                                                                loading={isFetchMore}
                                                                mt={10}
                                                                mb={10}
                                                                style={{
                                                                    width: ThemeUtils.relativeWidth(40),
                                                                    alignSelf: 'center'
                                                                }}
                                                                onPress={() => {
                                                                    setFetchMore(true)
                                                                    fetchMore({
                                                                        variables: {
                                                                            input: {
                                                                                first: 30,
                                                                                status: true,
                                                                                sorting: {
                                                                                    field: "id",
                                                                                    order: "ASC"
                                                                                },
                                                                                page: parseInt(data?.office_properties?.paginatorInfo?.currentPage, 10) + 1,
                                                                            }
                                                                        },
                                                                        updateQuery: (prev, {fetchMoreResult}) => {

                                                                            setFetchMore(false)
                                                                            if (!fetchMoreResult) {
                                                                                return prev;
                                                                            } else {
                                                                                let finalResult = Object.assign({}, fetchMoreResult);
                                                                                finalResult.office_properties.data = [...prev.office_properties.data, ...fetchMoreResult.office_properties.data];
                                                                                return finalResult;
                                                                            }
                                                                        },
                                                                    });
                                                                }}/>

                                              ) : null
                                      }/>
                        </ScrollView>
                    )}

                </View>
                <View>
                    <Dashboard dashboardData={DASHBOARD}
                               onPressItem={handleDashboardItemPress}/>
                </View>
            </IndicatorViewPager>

        </View>

    )
}

const mapStateToProps = () => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentInspectionData: (inspection) => dispatch(setCurrentInspectionDetails(inspection))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyList)
