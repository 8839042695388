import React from 'react';
//Components
import {ScrollView, View} from 'react-native';
import {Header, Label} from "components/src/components";
//Third party
//Utils
import {CommonStyle, IS_WEB, Strings} from "components/src/utils";
import styles from './styles';


export default function UserDetails(props) {
    const {navigation, history} = props;

    const user = IS_WEB ? props.history.location?.state?.user ?? {} : props.navigation.getParam('user', {});

    const _renderUserData = () => {
        return dataOfUser.map(user => {
            return (
                <View style={styles.subView}>
                    <Label small font_medium mt={18}>{Strings.viewName}</Label>
                    <Label small font_regular mt={10}>{`${user?.first_name} ${user?.last_name}`}</Label>

                    <Label small font_medium mt={18}>{Strings.viewemail}</Label>
                    <Label small font_regular mt={10}>{user.email}</Label>

                    <Label small font_medium mt={18}>{Strings.viewPhone}</Label>
                    <Label small font_regular mt={10}>{user.phone}</Label>

                    {user?.send_info_about_property !== undefined ?
                        (<>
                                <Label small
                                       font_medium
                                       mt={18}>
                                    {Strings.viewPropertyInformation}
                                </Label>
                                <Label small
                                       font_regular
                                       mt={10}>
                                    {user?.send_info_about_property ? Strings.yes : Strings.no}
                                </Label>
                            </>
                        ) : null}

                    {user?.bid_notification !== undefined ?
                        (<>
                            <Label small
                                   font_medium
                                   mt={18}>
                                {Strings.viewBidSMSNotification}
                            </Label>
                            <Label small
                                   font_regular
                                   mt={10}>
                                {user?.bid_notification ? Strings.yes : Strings.no}
                            </Label>
                        </>) : null}

                    {user?.contact_me_private_viewing_time !== undefined ?
                        (<>
                            <Label small
                                   font_medium
                                   mt={18}>
                                {Strings.privateViewing}
                            </Label>
                            <Label small
                                   font_regular
                                   mt={10}>
                                {user?.contact_me_private_viewing_time ? Strings.yes : Strings.no}
                            </Label>
                        </>) : null}

                    {user?.plan_set_community_shows !== undefined ?
                        (<>
                            <Label small
                                   font_medium
                                   mt={18}>
                                {Strings.communityViews}
                            </Label>
                            <Label small
                                   font_regular
                                   mt={10}>
                                {user?.plan_set_community_shows ? Strings.yes : Strings.no}
                            </Label>
                        </>) : null}

                    {user?.is_notify_me !== undefined ?
                        (<>
                            <Label small
                                   font_medium
                                   mt={18}>
                                {Strings.notifyOnBid}
                            </Label>
                            <Label small
                                   font_regular
                                   mt={10}>
                                {user?.is_notify_me ? Strings.yes : Strings.no}
                            </Label>
                        </>) : null}

                </View>

            )
        })
    }

    return (
        <View style={CommonStyle.flex}>
            <Header style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>
            <ScrollView>
                <View style={styles.contentCont}>
                    {_renderUserData()}
                </View>
            </ScrollView>
        </View>
    )
}
