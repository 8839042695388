import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,

    },
    datePickerCont: {
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: 10
    },
    dateCont: {
        flexDirection: 'row',
        alignItems: 'center',
        marginEnd: 20
    },
    modalCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginTop:ThemeUtils.relativeRealHeight(6)
    },
    energyradio: {
        marginVertical: 10,

    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 20
    },
    orderConfirm: {
        flexDirection: 'row',
        alignItems: "center",
        marginTop: ThemeUtils.relativeRealHeight(2)
    },
})
