export default {
  'component.editprofile.title': 'Rediger profil',
  'component.editprofile.profilepic': 'Profilbilde',
  'component.editprofile.firstname': 'Fornavn',
  'component.editprofile.lastname': 'Etternavn',
  'component.editprofile.email': 'E-post',
  'component.editprofile.phone': 'telefon',
  'component.editprofile.address': 'Adresse',
  'component.editprofile.city': 'By',
  'component.editprofile.zipcode': 'Postnummer',
  'component.editprofile.save': 'Large',
  'component.editprofile.cancel': 'Avbryt',
  'component.editprofile.success': 'Profilen ble oppdatert',
};
