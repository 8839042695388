import gql from 'graphql-tag';

export const CHANGE_PROFILE_PIC = gql`
mutation changeProfilePic($file:Upload!){
  change_profile_pic(profile_pic:$file)
}`;

export const UPDATE_AGENT_PROFILE = gql`
mutation updateAgentDetails(
  $id: Int!
  $officeId: Int!
  $first_name: String!
  $last_name: String!
  $email: String!
  $mobile: String
  $title: String!
  $education: String
  $summary: String
  $upload_cv: Upload
  $other_document: [Upload]
  $is_in_admin_office: Boolean!
) {
  update_agent(
    id: $id
    office_id: $officeId
    first_name: $first_name
    last_name: $last_name
    email: $email
    mobile: $mobile
    title: $title
    education: $education
    about: $summary
    cv: $upload_cv
    other_doc: $other_document
    is_in_admin_office: $is_in_admin_office
  ) {
    id
    user_type_id
    first_name
    last_name
    email
    title
    mobile
    profile_image
    city
    zipcode

    user_detail {
      id
      education
      education
      about
      cv_url
    }
    user_document {
      user_id
      doc_url
      doc_type
      id
    }
  }
}`

export const LOG_OUT = gql`
mutation logout {
  logout {
    meta {
      status
      message
      code
    }
  }
}`;

export const ADD_FEEDBACK = gql`
mutation addFeedback($input:FeedbackInput!){
	add_agent_feedback(input:$input){
    id
    user_id
    rating
  }
}`


export const EDIT_FEEDBACK = gql`
mutation editFeedBack($id:Int!,$agent_id:Int!,$rating:Decimal!,$given_by:String!,$feedback:String!){
  edit_agent_feedback(id:$id,agent_id:$agent_id,rating:$rating,given_by:$given_by,feedback:$feedback){
    id
    user_id
    rating
    feedback
    given_by
  }
}`;

export const DELETE_FEEDBACK = gql`
mutation deleteFeedBack($id:Int!,$user_id:Int!){
  delete_agent_feedback(id:$id,agent_id:$user_id){
    id
    user_id
  }
}`

export const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email:String!,$userTypeId:Int!){
  forgot_password_email(email:$email,user_type_id:$userTypeId){
    meta{
      status
      message
      code
    }
  }
}`;

export const CHANGE_PASSWORD = gql`
  mutation change_password($input: ChangePasswordInput!) {
    change_password(input: $input) {
      user {
        id
        office_id
        office{
          name
        }
        first_name
        last_name
        email
        address
        mobile
        profile_image
        city
        zipcode
        status
        user_detail{
          id
          user_id
          education
          summary
          cv_url
          status
        }
        userZipCodes {
          user_id
          user_type_id
          zipcode
        }
      }
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`;

export const LOGIN = gql`
mutation login($input: LoginInput!) {
  login(input: $input) {
    user {
      id
      access_level
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      title
      zipcode
      is_in_admin_office
      office_id
      office{ 
        id
        name
      }
      user_detail {
        id
        user_id
        education
        about
        cv_url
      }
      feedback {
        id
        rating
        feedback
        given_by
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      agentBefaringsbekreftelse {
        id
        description
      }
    }
    firebase_token
    token {
      access_token
      token_type
      expires_in
    }
  }
}`;

export const UN_SUBSCRIBE_SERVICE = gql`
mutation removeB2BService($id: Int!,$office_id:Int!) {
  remove_b2b_service(service_subscribe_id: $id,office_id:$office_id) {
    meta {
      status
      message
      code
    }
  }
}`;


export const ATTACH_VIDEO_LINK = gql`
mutation attachVideoLink($input: AttachLinkInput!) {
  attach_video_link(input: $input) {
    attach_video {
      id
      property_id
      video_url
    }
  }
}
`;
export const ATTACH_360_LINK = gql`
mutation attach360Link($input: AttachLinkInput!) {
  attach_360_view_link(input: $input) {
    attach_video {
      id
      order_photo_id
      view_360_url
    }
  }
}`;

export const UPLOAD_PHOTO = gql`
mutation uploadPhoto($photo: [Upload!], $photoOrderId: Int) {
  upload_photo(photo: $photo, photo_order_id: $photoOrderId) {
    photos {
      id
      order_photo_id
    }
  }
}`;

export const UPLOAD_MARKET_MATERIAL = gql`
mutation uploadMarketingMaterial($file:[Upload!],$title:String!,$propertyId:Int){
  upload_market_matreial(matreial_file:$file,title:$title,property_id:$propertyId){
    materials{
      id
      property_id
    }
  }
}`;

export const ADD_FINN_AD = gql`
mutation addFinnAdd($input: finnNoInput) {
  add_finn_no_ad(input: $input) {
    id
    property_id
    url
    is_approved
    approved_date
    finn_comments {
      id
      comment
      created_at
    }
  }
}`;

export const ADD_DS_ASSIGNMENT = gql`
mutation addDsAssignment($input: DSAssignmentInput) {
  add_digital_sales_assi(input: $input) {
    id
    property_id
    url
    is_approved
    approved_date
    digital_sales_assi_comment {
      id
      comment
    }
  }
}`;

export const ADD_BUYER_DETAILS = gql`
mutation addBuyer($input: BuyerInput) {
  add_buyer_user_deails(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`;


export const ADD_FINANCING = gql`
mutation adFinancingDetails($input: aplicantInput) {
  agent_add_financing_detail(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`;

export const REPLACE_MATERIAL = gql`
mutation replaceMaterial($file: [Upload!], $id: ID!) {
  replace_market_matreial_docu(matreial_file: $file, id: $id) {
    materials {
      id
    }
  }
}`;

export const UPDATE_AGENT_TEAM = gql`
mutation updatePropertyAgent($propertyId: Int!, $agentTeamID: Int!) {
  update_property_agent(property_id: $propertyId, agent_team_id: $agentTeamID) {
    meta {
      status
      message
      code
    }
  }
}`;

export const DELETE_DOC = gql`
mutation deleteDocument($id: Int!) {
  delete_property_document(id: $id) {
    meta {
      status
      message
    }
  }
}`;

export const UPLOAD_DOC = gql`
mutation uploadDoc($file:[Upload!],$title:String!,$propertyId:Int!,$docFor:ForDoc){
  upload_document(document_file:$file,title:$title,property_id:$propertyId,document_for:$docFor){
    document{
      id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property_id
    }
  }
}`;

export const UPDATE_PROPERTY_TIME_LINE = gql`
mutation updatePropertyTimeline($propertyId: ID!, $status: Int!) {
  update_property_timeline(property_id: $propertyId, status: $status) {
    success
  }
}`;

export const UPDATE_BUYER_TIMELINE = gql`
mutation updateBuyerPropertyTimeline($propertyId: ID!, $status: Int!) {
  update_buyer_timeline(property_id: $propertyId, status: $status) {
    success
  }
}`;

export const SEND_AGENT_NOTIFICATION = gql`
mutation notificationByAgent($propertyId:Int!,$notifyType:String!){
  notification_by_agent(property_id:$propertyId,notify_type:$notifyType){
    meta{
      status
      message
      code
    }
  }
}`;

export const SEND_48_HRS_NOTIFICATION = gql`
mutation resendOrderNotification($propertyId:Int!,$notifyType:String!){
  resend_order_notification(property_id:$propertyId,notify_type:$notifyType){
    meta{
      status
      message
      code
    }
  }
}`;
export const SEND_24_HRS_NOTIFICATION = gql`
mutation resendMarketAndContractOrderNotification($propertyId:Int!,$notifyType:String!){
  resend_market_and_contract_notify(property_id:$propertyId,notify_type:$notifyType){
    meta{
      status
      message
      code
    }
  }
}`;

export const SEND_CUSTOM_NOTIFICATION = gql`
mutation customPushNotification($propertyId:Int!,$desc:String!,$sendTo:[String!]!){
  custome_push_notification(property_id:$propertyId,notification_desc:$desc,send_to:$sendTo){
    meta{
      status
      message
      code
    }
  }
}`;

/*-------------------------New-------------------------------*/
export const SAVE_OFFICE_TEMPLATE_SERVICE = gql`
mutation updateOfficeTemplate($input: saveTemplateTemplateInput!) {
  update_office_template(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`;

export const OFFICE_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES = gql`
mutation uploadPhotos3dAndPhotos(
  $images: [Upload!]
  $templateId: Int
  $type: PhotoType!
) {
  photo_3d_service_media(
    photos: $images
    office_template_id: $templateId
    type: $type
  ) {
    id
    media_url
  }
}`;

export const OFFICE_TEMPLATE_STYLING_UPLOAD_IMAGES = gql`
mutation stylingUploadImages(
  $oldImage: Upload!
  $newImage: Upload!
  $templateId: Int
) {
  styling_service_media(
    old_photo: $oldImage
    new_photo: $newImage
    office_template_id: $templateId
  ) {
    id
    office_template_id
  }
}`;


export const DELETE_OFFICE_SERVICE_MEDIA = gql`
mutation deleteServiceMedia($ids: [Int!]!, $templateId: Int) {
  delete_service_media(office_template_id: $templateId, ids: $ids) {
    id
    office_template_id
    media_url
  }
}`;

export const OFFICE_TEMPLATE_PHOTO_360_ATTACH_LINK = gql`
mutation attach360Link($url: String!, $templateId: Int) {
  view360_service_media(url: $url, office_template_id: $templateId) {
    id
    office_template_id
  }
}`;

export const OFFICE_REPLACE_3D_PHOTO_IMAGE = gql`
mutation replaceImage(
  $id: Int!
  $templateId: Int
  $photo: Upload!
  $type: PhotoType
) {
  replace_photo_3d_media(
    id: $id
    office_template_id: $templateId
    photo: $photo
    type: $type
  ) {
    id
    office_template_id
  }
}`;

export const OFFICE_REPLACE_STYLING_IMAGE = gql`
mutation replaceStyleImage(
  $id: Int!
  $templateId: Int
  $photo: Upload!
  $oldPhoto: Upload!
) {
  replace_styling_photo_url(
    id: $id
    office_template_id: $templateId
    old_photo: $oldPhoto
    new_photo: $photo
  ) {
    id
    office_template_id
  }
}`;

export const OFFICE_SAVE_OPPDRAG_AGREEMENT = gql`
mutation saveOppDragAgreement($input: saveOppdragAgreementInput!) {
  save_oppdrag_agreement_edits(input: $input) {
    id
    office_id
  }
}
`;

export const UPDATE_PROPERTY_UTLEGG = gql`
mutation update_property_utlegg($input:updatePropertyUtlegg!){
  update_property_utlegg(input:$input){
      meta{
        status
        message
        code
      }
    }
}`;

export const START_INSPECTION = gql`
mutation start_inspection($input:StartInspectionInput!){
  start_inspection(input:$input){
      id
      property_id
      template_id
      start_date
      start_time
      template_type    
    }
}`;

export const PROPERTY_SERVICES = gql`
  mutation property_services($input:SavePropertyServices!){
    property_services(input:$input)
      {
        id
        property_id
        usable_service_id
        usable_service_class
        price
        quantity
      }
}`;

export const SEND_PREFERENCE = gql`
mutation sendPreference($input:SavePropertyPreference!){
  property_preference(input:$input){
    id
    property_id
    preferable_price
    percentage
    fix_price
    rebate_amount
  }
}`;

export const PURCHASE_B2B_SERVICE = gql`
mutation purchaseB2Bservice($ids: [Int]!, $office_id: Int!) {
  purchase_b2b_services(service_id: $ids, office_id: $office_id) {
    meta {
      status
      message
      code
    }
  }
}

`;

export const ADD_UPDATE_BEFARING = gql`
mutation addUpdateBefaring($input:BefaringsbekreftelseInput!){
  add_update_befaringsbekreftelse(input:$input){
    office_id
    description
  }
}`;

export const ADD_UPDATE_ABOUT_W = gql`
mutation addUpdateAboutW($input:AboutWInput!){
  add_update_about_w(input:$input){
    office_id
    description
    headline
    image
  }
}`;

export const ADD_UPDATE_PREPARATION = gql`
mutation addUpdatePreparation($input:AboutWInput!){
  add_update_preparation(input:$input){
    office_id
    description
    headline
    image
  }
}`;
export const ADD_UPDATE_QUESTION_TO_ASK = gql`
mutation addUpdateQuestionToAsk($input:AboutWInput!){
  add_update_question_to_ask(input:$input){
    office_id
    description
    headline
    image
  }
}`;

export const ADD_UPDATE_PRODUCT_GALLARY_IMAGE = gql`
mutation addUpdateProductGallary($input: ProductGalleryInput!) {
  add_update_production_gallery(input: $input) {
    office_id
    headline
  }
}
`;

export const DELETE_GALLARY_IMAGES = gql`
mutation delete_gallery_images($ids: [Int!]!, $gallaryId: Int!) {
  delete_gallery_images(ids: $ids, product_gallery_id: $gallaryId) {
    meta {
      status
      message
      code
    }
  }
}`;

export const REPLACE_GALLERY_IMAGE = gql`
mutation replace_gallery_images($id: Int!, $gallaryId: Int!, $photo: Upload!) {
  replace_gallery_images(
    id: $id
    product_gallery_id: $gallaryId
    image: $photo
  ) {
    id
    office_id
    headline
    ProductGalleryImages {
      id
      product_gallery_id
      url
    }
  }
}
`;


export const ADD_B2C_SERVICE = gql`
mutation addB2cService($input: saveServiceInput!) {
  add_b2c_service(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`;

export const UPDATE_B2C_SERVICE = gql`
mutation upadateB2cService($id: ID!, $input: updateServiceInput!) {
  update_b2c_service(id: $id, input: $input) {
    meta {
      status
      message
      code
    }
  }
}`;

export const DELETE_B2C_SERVICE = gql`
mutation deleteB2cService($id: ID!) {
  delete_b2c_service(id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const ADD_UTLEGG_SERVICE = gql`
mutation createUtleggService($input:saveUtleggService!){
  create_utlegg_service(input:$input){
    meta{
      status
      message
      code
    }
  }
}`;

export const UPDATE_UTLEGG_SERVICE = gql`
mutation updateUtleggService($id:ID!,$input:saveUtleggService!){
  update_utlegg_service(id:$id,input:$input){
    meta{
      status
      message
      code
    }
  }
}`;

export const DELETE_UTLEGG_SERVICE = gql`
mutation deleteUtleggService($id: ID!) {
  delete_utlegg_service(id: $id) {
    meta {
      status
      message
      code
    }
  }
}
`;

/*-----------------Personal template-----------*/

export const SAVE_PERSONAL_TEMPLATE_SERVICE = gql`
mutation updatePersonalTemplate(
  $type: UploadType
  $templateId: Int
  $video: Upload
  $iFrame: String
) {
  personal_update_office_template(
    type: $type
    office_template_id: $templateId
    video: $video
    iframeLink: $iFrame
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const PERSONAL_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES = gql`
mutation personalUploadPhotos3dAndPhotos(
  $images: [Upload!]
  $templateId: Int
  $type: PhotoType!
) {
  personal_photo_3d_service_media(
    photos: $images
    office_template_id: $templateId
    type: $type
  ) {
    id
    media_url
  }
}`;

export const PERSONAL_TEMPLATE_STYLING_UPLOAD_IMAGES = gql`
mutation personalStylingUploadImages(
  $oldImage: Upload!
  $newImage: Upload!
  $templateId: Int
) {
  personal_styling_service_media(
    old_photo: $oldImage
    new_photo: $newImage
    office_template_id: $templateId
  ) {
    id
    office_template_id
  }
}
`;


export const DELETE_PERSONAL_SERVICE_MEDIA = gql`
mutation deleteServiceMedia($ids: [Int!]!, $templateId: Int) {
  personal_delete_service_media(office_template_id: $templateId, ids: $ids) {
    id
    office_template_id
    media_url
  }
}`;

export const PERSONAL_TEMPLATE_PHOTO_360_ATTACH_LINK = gql`
mutation attach360Link($url: String!, $templateId: Int) {
  personal_view360_service_media(url: $url, office_template_id: $templateId) {
    id
    office_template_id
  }
}`;

export const PERSONAL_REPLACE_3D_PHOTO_IMAGE = gql`
mutation personalReplaceImage(
  $id: Int!
  $templateId: Int
  $photo: Upload!
  $type: PhotoType
) {
  personal_replace_photo_3d_media(
    id: $id
    office_template_id: $templateId
    photo: $photo
    type: $type
  ) {
    id
    office_template_id
  }
}`;

export const PERSONAL_REPLACE_STYLING_IMAGE = gql`
mutation personalReplaceStyleImage(
  $id: Int!
  $templateId: Int
  $photo: Upload!
  $oldPhoto: Upload!
) {
  personal_replace_styling_photo_url(
    id: $id
    office_template_id: $templateId
    old_photo: $oldPhoto
    new_photo: $photo
  ) {
    id
    office_template_id
  }
}`;

export const UPDATE_AGENT_BEFARING = gql`
mutation updateAgentProductGallery($desc: String!) {
  personal_befaring_content(description: $desc) {
    id
    agent_id
  }
}`;

export const UPLOAD_AGENT_BEFARING_DATA = gql`
mutation uploadAgentBefaringMedia($headline: String!, $images: [Upload!]) {
  personal_befaring_product_media(headline: $headline, photos: $images) {
    meta {
      status
      message
      code
    }
  }
}`;

export const REPLACE_AGENT_BEFARING_MEDIA = gql`
mutation replaceAgentBefaringMedia(
  $id: Int!
  $galleryId: Int!
  $photo: Upload!
) {
  replace_befaring_product_media(
    id: $id
    agent_product_gallery_id: $galleryId
    photo: $photo
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const DELETE_BEFARING_MEDIA = gql`
mutation delteBefringMedia($ids: [Int!]!, $galleryId: Int!) {
  personal_delete_befering_media(
    ids: $ids
    agent_product_gallery_id: $galleryId
  ) {
    meta {
      status
      message
      code
    }
  }
}
`
/*---------------signature-----------------*/
export const ADD_OPP_DRAG_NO = gql`
mutation addOppDragNo($id: Int!, $oppNo: String!) {
  add_oppdragnummer(id:$id,oppdrags_nummer:$oppNo) {
    meta {
      status
      message
      code
    }
  }
}`;

export const AGENT_SIGN_AGREEMENT = gql`
  mutation agentSignInitSession($input: signantAPIInput) {
    agentSignInitSession(input: $input) {
      initial_session {
        AuthenticationUrl
        RequestID
        TrackingID
      }
    
    }
  }`;

export const SEND_INSPECTION_OFFER = gql`
mutation send_inspection_offer($property_id:Int!,$headline:String,$description:String,$start_date:Date!,$start_time:String!,$is_confirmation_email:Boolean!) {
  send_inspection_offer(
    property_id: $property_id
    headline: $headline
    description:$description
    start_date:$start_date
    start_time:$start_time
    is_confirmation_email:$is_confirmation_email
  ) {
    meta {
      status
      message
      code
    }
  }
}
`


export const STORE_QUESTIONS = gql`
mutation storeQuestion($input: selectedQuestions) {
  submit_agreement_question(input: $input) {
    meta {
      status
      message
      code
    }
  }
}
`

export const OWNER_SIGN_AGREEMENT = gql`
mutation ownerSignAgreement($input:ownerSignInput){
  owner_sign_agreement(input:$input){
    ownerAgreement_sign
  }
}`

export const SEND_CO_OWNER_EMAIL = gql`
mutation agreement_submit($input: sellerSignupInput) {
  agreement_submit(input: $input) {
    meta {
      status
      message
      code
    }
  }
}
`

/*

export const BOOK_CALENDAR = gql`
mutation calendar_book_date(
  $date: Date!
  $propertyId: Int!
  $timelineId: Int!
  $book_start_time: String
  $book_end_time: String
  $styling_type_id: Int
) {
  calendar_book_date(
    property_id: $propertyId
    timeline_id: $timelineId
    book_date: $date
    book_start_time: $book_start_time
    book_end_time: $book_end_time
    styling_type_id: $styling_type_id
  ) {
    meta {
      status
      message
      code
    }
  }
}`
*/

export const BOOK_CALENDAR = gql`
mutation calendar_book_date(
  $date: Date!
  $propertyId: Int!
  $timelineId: Int!
  $book_start_time:String
  $book_end_time:String
  $styling_type:Int
) {
  calendar_book_date(
    property_id: $propertyId
    timeline_id: $timelineId
    book_date: $date
    book_start_time:$book_start_time
    book_end_time:$book_end_time
    styling_type_id:$styling_type
  ) {
    meta {
      status
      message
      code
    }
  }
}
`

export const ADD_AGENT_MEDIA = gql`
mutation agentAddMedia($userId:Int!,$title:String!,$url:String!,$image:Upload!){
  agent_add_media(agent_id:$userId,title:$title,redirection_url:$url,media_image:$image){
    id
    user_id
  }
}`

export const EDIT_AGENT_MEDIA = gql`
mutation agentUpdateMedia($userId:Int!,$id:Int!,$title:String!,$url:String!,$image:Upload!){
  agent_update_media(id:$id,agent_id:$userId,title:$title,redirection_url:$url,media_image:$image){
    id
    user_id
  }
}`


export const DELETE_AGENT_MEDIA = gql`
mutation agentDeleteMedia($id:Int!,$userId:Int!){
  delete_agent_media(media_id:$id,agent_id:$userId){
    id
    user_id
  }
}`


export const DELETE_PHOTOS = gql`
mutation deletePhoto($ids:[Int!]!){
  delete_photo(ids:$ids){
    meta{
      status
      message
      code
    }
  }
}`

export const REPLACE_PHOTO = gql`
mutation replacePhoto($image:Upload!,$orderId:Int!,$id:Int!){
  replace_photo(photo:$image,photo_order_id:$orderId,photo_id:$id){
    photo{
      id
      order_photo_id
    }
  }
}`

export const DELETE_AGENT_DOCUMENT = gql`
mutation delete_document($agentId: Int!, $userDocumentId: Int!) {
  delete_document(agent_id: $agentId, user_document_id: $userDocumentId) {
    id
    first_name
    last_name
    email
    address
    mobile
    profile_image
    city
    zipcode
    status
    access_level
    user_type_id
    office_id
    title
    user_detail {
      id
      user_id
      education
      about
      cv_url
      status
    }
    user_media {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
    user_document {
      id
      user_id
      doc_url
      doc_type
    }
    feedback {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
}
`;

export const DELETE_AGENT_CV = gql`
mutation delete_cv($agentId: Int!) {
  delete_cv(agent_id: $agentId) {
    id
    first_name
    last_name
    email
    address
    mobile
    profile_image
    city
    zipcode
    status
    access_level
    user_type_id
    office_id
    title
    user_detail {
      id
      user_id
      education
      about
      cv_url
      status
    }
    user_media {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
    user_document {
      id
      user_id
      doc_url
      doc_type
    }
    feedback {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
}
`;

export const DELETE_CALENDAR_BOOK_DATE = gql`
mutation delete_calender_book_date($id: Int!, $propertyId: Int!) {
  delete_calender_book_date(property_id: $propertyId, timeline_id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const SELECT_INSURANCE_TYPE = gql`
mutation select_boligselger_Insurance(
  $insuranceType: InsuranceTypeEnum
  $propertyId: Int!
  $id: Int!
) {
  select_boligselger_Insurance(
    property_id: $propertyId
    insurance_saving_id: $id
    insurance_type: $insuranceType
  ) {
    meta {
      status
      message
      code
    }
  }
}
`
export const AGENT_ADD_CALENDAR_EVENT = gql`mutation agentAddEvent(
  $title: String!
  $propertyId: Int!
  $isBuyer: Boolean
  $isSeller: Boolean
  $date: DateTime!
) {
  agent_add_calendar_event(
    title: $title
    property_id: $propertyId
    user_type_seller: $isSeller
    user_type_buyer: $isBuyer
    event_date: $date
  ) {
    id
  }
}
`;

export const AGENT_UPDATE_CALENDAR_EVENT = gql`
mutation agentUpdateEvent(
  $id:Int!,
  $title: String!
  $propertyId: Int!
  $isBuyer: Boolean
  $isSeller: Boolean
  $date: DateTime!
) {
  agent_update_calendar_event(
    id:$id,
    title: $title
    property_id: $propertyId
    user_type_seller: $isSeller
    user_type_buyer: $isBuyer
    event_date: $date
  ) {
    id
  }
}`;
export const DELETE_CALENDAR_EVENT = gql`
mutation deleteAgentCalendarEvent($id:Int!){
  agent_delete_calendar_event(id:$id){
    meta{
      status
      message
      code
    }
  }
}`;

export const REFRESH_TOKEN = gql`
mutation refresh_token{
  refresh_token{
    token{
      access_token
      token_type
      expires_in
    }
  }
}`

export const CREATE_PACKAGE = gql`
mutation createMarketingPackage(
  $name: String!
  $price: Decimal
  $description: String
  $image: Upload!
  $services: [Int!]!
) {
  create_marketing_package(
    name: $name
    price: $price
    description: $description
    image_url: $image
    service_id: $services
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const UPDATE_PACKAGE = gql`
mutation editMarketingPackage(
  $id:Int!,
  $name: String!
  $price: Decimal
  $description: String
  $image: Upload
  $services: [Int]
) {
  edit_marketing_package(
    id:$id,
    name: $name
    price: $price
    description: $description
    image_url: $image
    service_id: $services
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const DELETE_MARKETING_PACKAGE = gql`
mutation deleteMarketingPackage($id:Int!){
  delete_marketing_package(id:$id){
    meta{
      status
      message
      code
    }
  }
}`


export const SAVE_UTLEGG = gql`
mutation SaveUttleggService($propertyId:Int!,$isDelete:Boolean!,$serviceId:Int!){
  save_proeprty_utlagg(property_id:$propertyId,is_delete_action:$isDelete,property_utlagg_service_id:$serviceId){
    meta{
      message
      status
      code
    }
  }
}`;

export const SAVE_NOTES = gql`
mutation saveNotes($inspectionId: Int!, $image: Upload!) {
  save_befaring_note(property_inspection_id: $inspectionId, image: $image) {
    id
    property_inspection_id
    image
  }
}`

export const UPDATE_BROKER_ON_VIEW = gql`
mutation updateBrokerView(
  $id: Int!
  $name: String!
  $description: String!
  $image: Upload
  $price: Decimal!
) {
  update_broker_view(
    id: $id
    name: $name
    description: $description
    image: $image
    price: $price
  ) {
    meta {
      status
      message
      code
    }
  }
}
`;

export const SAVE_MARKETING_PACKAGE_DATA = gql`
mutation saveMarketingPackage($heading:String!,$desc:String!){
  save_marketing_package_detail(heading:$heading,description:$desc){
    id
    office_id
    heading
    description
  }
}`;

export const ADD_MARKETING_PACKAGE_IMAGES = gql`
mutation uploadPackageImages($id:Int!,$photos:[Upload!]){
  upload_package_images(photos:$photos,marketing_package_detail_id:$id){
    id
    marketing_package_detail_id
    image
    priority
  }
}`

export const REPLACE_PACKAGE_IMAGE = gql`
mutation replacePackageImage($id: Int!, $detailsId: Int!, $photo: Upload!) {
  replace_package_image(
    id: $id
    marketing_package_detail_id: $detailsId
    photo: $photo
  ) {
    id
    marketing_package_detail_id
    image
    priority
  }
}`;

export const DELETE_PACKAGE_IMAGE = gql`
mutation deleteImagesPackage($ids: [Int!]!, $detailsId: Int!) {
  delete_package_images(ids: $ids, marketing_package_detail_id: $detailsId) {
    id
    marketing_package_detail_id
    image
    priority
  }
}
`
export const IMPORT_DS = gql`
mutation importDS($oppdragNo: String!) {
  import_ds(oppdragsnummer: $oppdragNo) {
    meta {
      status
      message
      code
    }
  }
}`;

export const OFFICE_ADD_IMAGE_AFTER_BEFARING = gql`
mutation afterAddUpdateProductionGallery($input: ProductGalleryInput!) {
  after_add_update_production_gallery(input: $input) {
    id
    office_id
  }
}`;

export const OFFICE_REPLACE_IMAGE_AFTER_BEFARING = gql`
mutation replaceAfterGallertyImages(
  $id: Int!
  $galleryId: Int!
  $photo: Upload!
) {
  replace_after_gallery_images(
    id: $id
    product_gallery_id: $galleryId
    image: $photo
  ) {
    id
    office_id
    headline
    AfterProductGalleryImages{
      id
      product_gallery_id
      url
    }
  }
}`;

export const OFFICE_DELETE_IMAGE_AFTER_BEFARING = gql`
mutation deleteAfterGalleryImages($ids: [Int!]!, $galleryId: Int!) {
  delete_after_gallery_images(ids: $ids, product_gallery_id: $galleryId) {
    meta {
      status
      message
      code
    }
  }
}`;

export const PERSONAL_ADD_IMAGE_AFTER_BEFARING = gql`
mutation afterPersonalAddUpdateProductionGallery(
  $headline: String!
  $photos: [Upload!]
) {
  personal_after_befaring_product_media(headline: $headline, photos: $photos) {
    meta {
      status
      message
      code
    }
  }
}`;

export const PERSONAL_REPLACE_IMAGE_AFTER_BEFARING = gql`
mutation replacePersonalAfterGallertyImages(
  $id: Int!
  $galleryId: Int!
  $photo: Upload!
) {
  replace_after_befaring_product_media(
    id: $id
    agent_product_gallery_id: $galleryId
    photo: $photo
  ) {
    meta{
      status
      message
      __typename
    }
  }
}`;

export const PERSONAL_DELETE_IMAGE_AFTER_BEFARING = gql`
mutation deletePersonalAfterGalleryImages($ids: [Int!]!, $galleryId: Int!) {
  personal_delete_after_befering_media(
    ids: $ids
    agent_product_gallery_id: $galleryId
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const ADD_VENDOR_SERVICE = gql`
mutation addVendorService(
  $vendor_id: Int!
  $service_type_id: Int!
  $name: String!
  $price_or_other: String!
  $price: Decimal
  $other_text: String
  $description: String!
  $image: Upload!
) {
  add_vendor_service(
    vender_id: $vendor_id
    service_type_id: $service_type_id
    name: $name
    price_or_other: $price_or_other
    price: $price
    other_text: $other_text
    description: $description
    image: $image
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const UPDATE_VENDOR_SERVICE = gql`
mutation updateVendorService(
  $id: Int!
  $name: String!
  $price_or_other: String!
  $price: Decimal
  $other_text: String
  $description: String!
  $image: Upload
) {
  update_vendor_service(
    id: $id
    name: $name
    price_or_other: $price_or_other
    price: $price
    other_text: $other_text
    description: $description
    image: $image
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const DELETE_VENDOR_SERVICE = gql`
mutation deleteVendorService($id: Int!) {
  delete_vendor_service(id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const ASSIGN_VENDOR_TO_PROPERTY = gql`
mutation assignVendorToProperty(
  $vendorId: Int!
  $typeId: Int!
  $propertyId: Int!
) {
  assign_vendor_to_property(
    vendor_id: $vendorId
    user_type_id: $typeId
    property_id: $propertyId
  ) {
    meta {
      status
      message
      code
    }
  }
}`;

export const ADD_PROPERTY_SHOWCASE=gql`
mutation addPropertyShowCase($heading: String!, $coverImage: Upload!) {
  add_property_showcase(heading: $heading, cover_image: $coverImage) {
    meta {
      status
      message
      code
    }
  }
}`;

export const EDIT_PROPERTY_SHOWCASE=gql`
mutation editPropertyShowCase(
  $id: Int!
  $heading: String!
  $coverImage: Upload
) {
  edit_property_showcase(id: $id, heading: $heading, cover_image: $coverImage) {
    meta {
      status
      message
      code
    }
  }
}
`;

export const REMOVE_PROPERTY_SHOWCASE=gql`
mutation removePropertyShowcase($id: Int!) {
  property_showcase_status(id: $id) {
    meta {
      status
      message
      code
    }
  }
}`;

export const UPLOAD_SHOW_CASE_IMAGE=gql`
mutation uploadShowCaseImage($photos: [Upload!], $id: Int!) {
  upload_showcase_images(property_showcase_id: $id, photos: $photos) {
    id
    property_showcase_id
    image
    priority
  }
}`;

export const DELETE_SHOW_CASE_IMAGE=gql`
mutation deleteShowCaseImage($ids: [Int!]!, $showCaseId: Int!) {
  delete_showcase_images(property_showcase_id: $showCaseId, ids: $ids) {
    id
    property_showcase_id
    image
    priority
  }
}`;

export const REPLACE_SHOW_CASE_IMAGE=gql`
mutation replaceShowCaseImage($id: Int!, $showCaseId: Int!, $photo: Upload!) {
  replace_showcase_image(
    property_showcase_id: $showCaseId
    id: $id
    photo: $photo
  ) {
    id
    property_showcase_id
    image
    priority
  }
}`;

export const SELLER_ADD_PHOTO_SERVICE = gql`
mutation orderPhotoService($input: OrderPhotoInput!) {
  order_photo(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`

export const SELLER_RATE = gql`
  mutation order_rate($input: OrderRateInput!) {
    order_rate(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;


export const SELLER_STYLISH_ORDER = gql`
  mutation order_stylish($input: OrderStylishInput!) {
    order_stylish(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
