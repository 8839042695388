import React from 'react';
//Components
import {ScrollView, View} from 'react-native';
import {CustomButton, Label} from "components/src/components";
//Utils
import {Color, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';


function SignSuccess(props) {

    const {navigation, history} = props;

    const renderContracter = (item, index) => {
        return (
            <>
                <Label xlarge
                       font_medium
                       mt={10}
                       ml={ThemeUtils.relativeRealWidth(6)}
                       mr={ThemeUtils.relativeRealWidth(6)}
                       align={'center'}>
                    {item}
                </Label>
                {!isSigned && index === 1 ? (
                    <View style={styles.signRemaining}>
                        <Label small
                               color={Color.WHITE}
                               align={'center'}>
                            {Strings.signRemaining}
                        </Label>
                    </View>) : null}
            </>
        )
    }
    return (
        <View style={styles.container}>


            <ScrollView contentContainerStyle={{flexGrow: 1, backgroundColor: Color.PRIMARY_BG_COLOR}}>
                <View style={styles.contentCont}>

                    <Icon name={'about-w'}
                          size={ThemeUtils.responsiveScale(120)}
                          color={Color.SECONDARY}/>

                    <Label large
                           mt={10}
                           font_medium
                           color={Color.WHITE}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           align={'center'}>
                        {Strings.welcomeToBefaring}
                    </Label>
                </View>
                <CustomButton title={Strings.getStarted}
                              onPress={() => IS_WEB ? history.replace(Routes.GetStartedDashboard) : navigation.navigate(Routes.GetStartedDashboard)}
                              mt={10}
                              ml={ThemeUtils.relativeRealWidth(4)}
                              mr={ThemeUtils.relativeRealWidth(4)}/>
            </ScrollView>


        </View>
    )
}

export default SignSuccess;
