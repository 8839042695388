import { StyleSheet } from "react-native";
import { Color, ThemeUtils } from "components/src/utils";

const styles = StyleSheet.create({
  container: {
    flex: 1,

    backgroundColor: Color.SECONDARY_BG_COLOR,
  },

  topContainer: {
    flex: 1,
    marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    alignContent: "center",
  },

  viewicon: {
    marginTop: ThemeUtils.relativeRealHeight(1.5),
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonContainer: {
    marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    marginBottom: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    flexDirection: "row",
  },
  SubViewIcon: {
    backgroundColor: Color.GRAY,
    height: ThemeUtils.responsiveScale(200),
    width: ThemeUtils.responsiveScale(200),
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center'
  },
});
export { styles };
