import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: ThemeUtils.relativeRealHeight(6),
    },
    vendorCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: ThemeUtils.relativeRealHeight(2)
    },
    lbl: {
        marginTop: ThemeUtils.relativeRealHeight(1)
    },
    img:{
        height: ThemeUtils.responsiveScale(180),
        width: ThemeUtils.responsiveScale(180),
        borderRadius: 4,
        borderWidth:0.8,
        borderColor:Color.PRIMARY_LIGHT

    },
    imgThumb: {
        height: ThemeUtils.responsiveScale(75),
        width: ThemeUtils.responsiveScale(75),
        backgroundColor:Color.GRAY,
        borderRadius: 10,
    },
    optionContainer: {
        marginVertical: ThemeUtils.relativeRealHeight(2),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
});

export default styles;
