import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, TextInput, View} from 'react-native'
import {Header, Label, Ripple} from "components/src/components";
//Third party
import debounce from 'lodash.debounce';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Query} from "@apollo/react-components";
import {AGENT_VENDOR_LIST} from "components/src/api/query";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';


class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            searchValue: '',
        }
        this.scrollY = new Animated.Value(0)
        this.changeText = debounce(() => {
            this.setState((state) => ({searchValue: state.searchText}))
        }, 500)
    }


    render() {
        const {searchText, searchValue} = this.state
        return (
            <View style={CommonStyle.flex}>
                <Header style={CommonStyle.blankHeader}
                        onPressLeft={this.props.onClose}/>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Label xlarge
                                   font_medium
                                   mt={15}

                                   mb={15}
                                   align={'left'}>
                                {Strings.search}
                            </Label>

                        </View>
                    </View>


                    <View style={styles.SearchMainView}>


                        <Icon name={'search'}
                              color={Color.PRIMARY}
                              size={ThemeUtils.fontSmall}/>


                        <TextInput style={[styles.Textinput, IS_WEB ? {outline: 'none'} : null]}
                                   onChangeText={(text) => {
                                       this.setState({searchText: text}, () => {
                                           this.changeText()
                                       })
                                   }}
                                   value={searchText}
                                   placeholder={Strings.search}/>
                        {searchText ?
                            (<Ripple style={styles.closeIconView}
                                     onPress={() => this.setState({
                                         searchText: '',
                                         searchValue: ''
                                     })}
                                     rippleContainerBorderRadius={20}>
                                <Icon name={'close'}
                                      size={ThemeUtils.fontXSmall}
                                      color={Color.PRIMARY} align={'right'}/>
                            </Ripple>) : null}

                    </View>


                    <Query query={AGENT_VENDOR_LIST}
                           skip={!searchValue}
                           variables={{
                               input: {
                                   status: true,
                                   user_type_id: this.props.userType,
                                   page: 1,
                                   first: 10,
                                   key: searchValue,
                                   sorting: {
                                       field: 'id',
                                       order: 'DESC',
                                   },
                               },
                           }}>
                        {({data, loading, error, fetchMore}) => {
                            if (loading) {
                                return (<ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>)
                            }
                            return (
                                <View>
                                    {searchValue ? (<FlatList data={data?.office_vendor?.data ?? []}
                                                              renderItem={({item, index}) => this.props.renderItem(item, index)}/>) : null}

                                </View>

                            )
                        }}
                    </Query>


                </View>

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
