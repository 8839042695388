export default {
  'component.jobs.title': 'Stilling ledig',
  'component.jobs.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
  'component.jobs.addjobs': 'Legg til stilling ledig',
  'component.jobs.addjobs.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
  'component.jobs.editjobs.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
  'component.jobs.editjobs': 'Rediger stilling ledig',
  'component.jobs.jobtitle': 'Stilling ledig Tittel',
  'component.jobs.jobtitle.placeholder': 'Skriv inn stilling ledig tittel',
  'component.jobs.company': 'Avdeling',
  'component.jobs.company.placeholder': 'Skriv inn Avdeling',
  'component.jobs.location': 'Plassering',
  'component.jobs.location.placeholder': 'Skriv inn Plassering',
  'component.jobs.jobid': 'Stilling ledig id',
  'component.jobs.reqid.placeholder': 'Skriv inn stilling ledig id',
  'component.jobs.party': 'Selskap',
  'component.jobs.party.placeholder': 'Angi selskap',
  'component.jobs.contactname': 'Kontakt navn',
  'component.jobs.contactemail': 'Kontakt epost',
  'component.jobs.contactphone': 'Kontakt telefon',
  'component.jobs.rightsidedescription': 'Høyre side intro tekst',
  'component.jobs.whatareyoulookingfor': 'Beskrivelse, hva søker vi etter',
  'component.jobs.whatyouoffer': 'Hva tilbyr vi',
  'component.jobs.jobtype': 'Stilling ledig Type',
  'component.jobs.jobtype.placeholder': 'Velg stilling ledig Type',
  'component.jobs.jobdescription': 'Stilling ledig Beskrivelse',
  'component.jobs.successful': 'Suksessfult gjennomført !',
  'component.jobs.modal.text': 'Jobben ble lagt til !',
  'component.jobs.jobdescription.placeholder': 'Skriv inn stilling ledig Beskrivelse...',
  'component.jobs.tablesearch': 'Søk etter alternativ, navn, e-post, telefon og pantelån',
};
