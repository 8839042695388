import React from 'react'
import {FlatList, Image, TextInput, TouchableOpacity, View} from 'react-native'
import styles from './styles'
import {ChatBubble, Header, Hr, Label} from "components/src/components";
import {
    Color,
    DateUtils,
    CommonStyle,
    Constants,
    decryptId,
    getUserString,
    Icon,
    IS_WEB,
    Strings,
    ThemeUtils
} from "components/src/utils";
import userPlaceholder from 'components/src/assets/images/user_placeholder.png'

import {connect} from "react-redux";
import {withFirebase} from "components/src/HOC/Firebase";
import moment from "moment";
import {USER_DETAILS_LIST} from "components/src/api/query";

import {Query} from "@apollo/react-components";

let flatList;
let scrollPosition;
let contentHeight;
let originalHeight;

class ChatDetails extends React.Component {

    constructor(props) {
        super(props);
        this.receiverId = IS_WEB ? decryptId(props.match.params.receiverId) : props.navigation.getParams('receiverId')
        this.propertyId = props.property?.id
        this.receiverUser = IS_WEB ? props.location.state?.user : props.navigation.getParams('user')
        if (IS_WEB) {
            this.propertyId = decryptId(props.match.params.propertyId)
        }
        this.state = {
            messageList: [],
            messageText: "",
            chatId: null,
            isFirstTime: false
        }

    }

    componentDidMount() {
        const {history, database, location, navigation, property, user} = this.props
        this.database = database().ref('v1')


        this.database.child(`users/${user?.id}/${this.propertyId}/${this.receiverId}`).once('value', (e) => {
            let chatId = e.val()?.chatId
            if (chatId) {
                this.setState({
                    chatId: chatId
                })
                this.chatDbRef = database().ref('v1').child(`conversations/${chatId}`).orderByChild('timestamp');
                this.chatDbRef.on('value', (snapShot) => {
                    const messages = []
                    snapShot.forEach((child) => {
                        if (child.hasChild("isDeleted")) {
                            if (!child.val().isDeleted.includes(user?.id)) {
                                messages.push(child.val());
                            }
                        } else {
                            messages.push(child.val());
                        }
                        if (!child.val().isRead && child.val().receiverId === parseInt(user?.id, 10)) {
                            child.ref
                                .update({isRead: true})
                        }
                    });
                    if (!e.val()?.isRead) {
                        database().ref("v1/users")
                            .child(user.id)
                            .child(this.propertyId)
                            .child(this.receiverId)
                            .update({isRead: true})
                    }
                    this.setState({messageList: messages}, () => {
                        if ((this.contentHeight - this.originalHeight) !== this.scrollPosition) {
                            setTimeout(() => flatList && flatList.scrollToOffset({animated: true, offset: 0}), 250)
                        }
                    })
                })
            } else {
                IS_WEB ? history.goBack() : navigation.goBack();
            }
        })
    }

    componentWillUnmount() {

        this.database && this.database.off('value')
        this.chatDbRef && this.chatDbRef.off('value')

    }

    onLayout = e => {
        let {height} = e.nativeEvent.contentSize;
        if (this.state.inputHeight < 100)
            this.setState({inputHeight: height})
    }

    renderChatBubble = ({item, index}) => {
        let isSection = true
        let messages = this.state.messageList.slice().reverse()

        if ((index !== messages.length - 1) && moment(moment(messages[index + 1]?.timestamp).format(DateUtils.dd_mm_yyyy),DateUtils.dd_mm_yyyy).diff(moment(moment(item?.timestamp).format(DateUtils.dd_mm_yyyy),DateUtils.dd_mm_yyyy), 'd') === 0) {            isSection = false
        }
        /*let messages = this.state.messageList.slice()
        if ((index !== 0) && moment(messages[index - 1]?.timestamp).diff(item.timeStamp, 'd') === 0) {
            isSection = false
        }*/

        return (
            <ChatBubble left={item?.senderId !== this.props?.user?.id}
                        key={index.toString()}
                        message={item}
                        section={isSection}/>
        )
    }

    render() {
        const {history, navigation, location, property, user, database} = this.props;
        let {messageList, messageText, inputHeight, chatId} = this.state;

        return (
            <View style={CommonStyle.flex}>
                <Query query={USER_DETAILS_LIST}
                       skip={this.receiverUser}
                       variables={{
                           ids: [this.receiverId]
                       }}>
                    {({loading, data, error}) => {
                        if (data && !this.receiverUser && data?.users_detail_list?.length) {
                            this.receiverUser = data?.users_detail_list[0]
                        }
                        return (
                            <View style={CommonStyle.flex}>
                                <Header navigation={IS_WEB ? history : navigation}
                                        titleStyle={styles.headerTitle}
                                        renderTitle={() => (
                                            <View style={styles.chatCont}>
                                                <Image style={styles.chatImage}
                                                       source={this.receiverUser?.profile_image ? {uri: `${Constants.APIConfig.STORAGE_URL}${this.receiverUser?.profile_image}`} : userPlaceholder}/>
                                                <View style={styles.lblCont}>
                                                    <Label small
                                                           font_medium>
                                                        {`${this.receiverUser?.first_name ?? ""} ${this.receiverUser?.last_name ?? ""}`}
                                                    </Label>
                                                    <Label small>
                                                        {getUserString(this.receiverUser?.user_type_id)}
                                                    </Label>
                                                </View>
                                            </View>
                                        )}
                                        style={CommonStyle.blankHeader}/>

                                <Hr/>


                                {/* <ScrollView ref={r => flatList = r}

                                            initialScrollIndex={messageList.length - 1}
                                            scrollEventThrottle={16}
                                            onLayout={(e) => {
                                                originalHeight = e.nativeEvent.layout.height
                                            }}
                                            onScroll={(e) => {
                                                scrollPosition = e.nativeEvent.contentOffset.y
                                                contentHeight = e.nativeEvent.contentSize.height
                                            }}
                                            style={styles.flatListContentContainer}>
                                    {messageList.map(this.renderChatBubble)}
                                </ScrollView>*/}


                                <FlatList
                                    inverted
                                    keyboardShouldPersistTaps={'always'}
                                    ref={r => flatList = r}
                                    onLayout={(e) => {
                                        this.originalHeight = e.nativeEvent.layout.height
                                    }}
                                    onScroll={(e) => {
                                        this.scrollPosition = e.nativeEvent.contentOffset.y
                                        this.contentHeight = e.nativeEvent.contentSize.height
                                    }}
                                    style={styles.flatListContentContainer}
                                    extraData={messageList}
                                    data={messageList.slice().reverse()}
                                    keyExtractor={(item) => item.timestamp.toString()}
                                    renderItem={this.renderChatBubble}/>

                                <View style={styles.inptCont}>

                                    <View style={CommonStyle.flex}>
                                        <TextInput
                                            style={[styles.input, IS_WEB ? {
                                                height: inputHeight,
                                                outline: "none",
                                                paddingTop: 8
                                            } : null]}
                                            multiline
                                            value={messageText}
                                            onChangeText={(text) => this.setState({
                                                messageText: text,
                                                inputHeight: text ? inputHeight : 1
                                            })}
                                            onContentSizeChange={(e) => IS_WEB ? this.onLayout(e) : null}
                                            placeholder={Strings.chatPlaceholder}/>
                                    </View>

                                    <TouchableOpacity activeOpacity={0.5}
                                                      disabled={!messageText}
                                                      onPress={() => {
                                                          this.database.child(`conversations/${chatId}`).push().set({
                                                              [Constants.CHAT_KEY.CONTENT]: messageText.trim(),
                                                              [Constants.CHAT_KEY.SENDER_ID]: parseInt(user?.id, 10),
                                                              [Constants.CHAT_KEY.TIMESTAMP]: database.ServerValue.TIMESTAMP,
                                                              [Constants.CHAT_KEY.MESSAGE_TYPE]: Constants.CHAT_TYPE.TEXT,
                                                              [Constants.CHAT_KEY.IS_READ]: false,
                                                              [Constants.CHAT_KEY.RECEIVER_ID]: parseInt(this.receiverId, 10),
                                                              [Constants.CHAT_KEY.PROPERTY_ID]: parseInt(this.propertyId, 10)
                                                          })
                                                          this.database.child("users")
                                                              .child(this.receiverId)
                                                              .child(this.propertyId)
                                                              .child(user.id)
                                                              .update({
                                                                  [Constants.CHAT_KEY.IS_READ]: false,
                                                                  [Constants.CHAT_KEY.LAST_MESSAGE]: messageText.trim(),
                                                                  [Constants.CHAT_KEY.LAST_MESSAGE_TIMESTAMP]: database.ServerValue.TIMESTAMP,
                                                                  [Constants.CHAT_KEY.RECEIVER_ID]: parseInt(user?.id, 10),
                                                              })
                                                          this.database.child("users")
                                                              .child(user?.id)
                                                              .child(this.propertyId)
                                                              .child(this.receiverId)
                                                              .update({
                                                                  [Constants.CHAT_KEY.IS_READ]: true,
                                                                  [Constants.CHAT_KEY.LAST_MESSAGE]: messageText.trim(),
                                                                  [Constants.CHAT_KEY.LAST_MESSAGE_TIMESTAMP]: database.ServerValue.TIMESTAMP,
                                                                  [Constants.CHAT_KEY.RECEIVER_ID]: parseInt(this.receiverId, 10),
                                                              })

                                                          this.setState({
                                                              messageText: "",
                                                              inputHeight: 1
                                                          })

                                                          setTimeout(() => flatList && flatList.scrollToOffset({
                                                              animated: false,
                                                              offset: 0,
                                                          }))
                                                      }}
                                                      style={styles.iconCont}>
                                        <Icon name={'send'}
                                              size={ThemeUtils.fontNormal}
                                              color={Color.SECONDARY}/>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )
                    }}
                </Query>

            </View>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
}
export default connect(mapStateToProps)(withFirebase(ChatDetails))
