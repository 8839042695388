import React from 'react'
//Components
import {ActivityIndicator, View} from 'react-native'
import {CustomButton, Hr, Label, Ripple} from 'components/src/components'
//Network
import {useQuery} from "@apollo/react-hooks";
import {DASHBOARD_STATES, NEWS_LIST} from "components/src/api/query";
//Utils
import {Color, CommonStyle, DateUtils, encryptId, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import moment from "moment-timezone";
import HtmlView from "../../../../components/view/HTMLView";


const STATE_DATA = [{
    name: "Daniel",
    childId: "agent",
    stats: [{
        id: "inspection",
        name: "Befaring",
    }, {
        name: "Oppdrag",
    }, {
        id: "sale",
        name: "Salg",
    }, {
        name: "Omsetning (tall i tusen)",
    }]
}, {
    name: "Emmily",
    childId: "company",
    stats: [{
        id: "inspection",
        name: "Befaring",
    }, {
        name: "Oppdrag",
    }, {
        id: "sale",
        name: "Salg",
    }, {
        name: "Omsetning (tall i tusen)",

    }]
}]

function Home(props) {
    const {navigation, history} = props;

    const {loading, error, data} = useQuery(NEWS_LIST, {
        variables: {
            status: true,
            first: 3,
            page: 1,
            orderBy: [{field: "id", order: "DESC"}],
        }
    });

    const statsQuery = useQuery(DASHBOARD_STATES);

    const navigate = (news) => {
        if (IS_WEB) {
            history.push(`/${Routes.News}/${Routes.NewsDetails}/${encryptId(news.id)}`, {
                news: news
            })
        } else {
            navigation.navigate(Routes.NewsDetails, {
                news: news
            })
        }
    };

    const renderNews = (item, index) => {
        return (
            <>
                {index !== 0 ? (<Hr/>) : null}
                <Ripple style={styles.newsCont}
                        onPress={() => navigate(item)}>
                    <Label mb={8}
                           font_medium>
                        {item.title}
                    </Label>
                    {/*<View style={{height: 44, overflow: 'hidden'}}>*/}
                    {console.log(item.description)}
                    {item.description ? (

                        <View style={{overflow: 'hidden'}}>
                            <HtmlView value={item.description}
                                      stylesheet={{
                                          p: {maxHeight: 48, lineHeight: 16}
                                      }}
                                      textComponentProps={{numberOfLines: 3,}}/>
                        </View>) : null}
                    <Label small
                           mt={10}
                           color={Color.SECONDARY_TEXT_COLOR}
                           numberOfLines={2}>
                        {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.dd_mm_yyyy_hh_mm)}
                    </Label>
                </Ripple>
            </>
        )
    };

    const renderEachStates = (item, index, childId) => {
        return (
            <>
                <View
                    style={[ThemeUtils.isTablet() ? styles.statTabMargin : styles.stateMargin, styles.statsSingle, index % 2 !== 0 ? styles.stateGray : styles.stateSecondary]}>
                    <Label small
                           font_medium
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {item.name}
                    </Label>
                    <View style={styles.flexRow}>
                        <View style={CommonStyle.flex}>
                            <Label xlarge
                                   font_medium>
                                {item?.id ? statsQuery?.data?.dashboard_stat[item.id]?.[childId]?.month : "-"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {"Måned"}
                            </Label>
                        </View>
                        <View style={CommonStyle.flex}>
                            <Label xlarge
                                   font_medium>
                                {item?.id ? statsQuery?.data?.dashboard_stat[item.id]?.[childId]?.quarter : "-"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {"Kvartal"}
                            </Label>
                        </View>
                        <View style={CommonStyle.flex}>
                            <Label xlarge
                                   font_medium>
                                {item?.id ? statsQuery?.data?.dashboard_stat[item.id]?.[childId]?.year : "-"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {"År"}
                            </Label>
                        </View>
                    </View>
                </View>

            </>
        )
    }

    const renderStats = (stat, index) => {
        return (
            <View>
                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mt={20}
                       mb={10}
                       font_medium>
                    {/*{item.name}*/}
                </Label>
                <View style={ThemeUtils.isTablet() ? styles.stateTab : null}>
                    {stat.stats.map((item, index) => renderEachStates(item, index, stat.childId))}
                </View>
            </View>
        )
    };


    return (
        <View>

            <Label font_medium
                   large
                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                   mb={15}
                   mt={15}>
                {Strings.news}
            </Label>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}/>
            ) : (

                <View>

                    {data?.news?.data?.map(renderNews)}
                    {data?.news?.data.length ?
                        (<CustomButton title={Strings.viewAllNews}
                                       mt={10}
                                       onPress={() => IS_WEB ? history.push(Routes.News) : navigation.navigate(Routes.News)}
                                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}/>) : null}
                    <View>
                        <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               large
                               mt={20}
                               mb={10}
                               font_medium>
                            {"Gjøremål"}
                        </Label>
                        <View style={styles.taskCont}>
                            <View style={[styles.stats, styles.statePrimary]}>
                                <Label xlarge
                                       font_medium
                                       color={Color.TERNARY_TEXT_COLOR}>
                                    {"-"}
                                </Label>
                                <Label small
                                       mt={10}
                                       color={Color.TERNARY_TEXT_COLOR}>
                                    {"Forfaller i dag"}
                                </Label>
                            </View>
                            <View style={[styles.stats, styles.stateGray]}>
                                <Label xlarge
                                       font_medium>
                                    {"-"}
                                </Label>
                                <Label small
                                       mt={10}>
                                    {"Forfaller denne uken"}
                                </Label>
                            </View>
                        </View>
                        <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               large
                               mt={20}
                               mb={10}
                               font_medium>
                            {"Aktiv bud"}
                        </Label>

                        <View style={styles.taskCont}>
                            <View style={[styles.stats, styles.stateSecondary]}>
                                <Label xlarge
                                       font_medium>
                                    {"-"}
                                </Label>
                                <Label small
                                       mt={10}>
                                    {"Aktive bud"}
                                </Label>
                            </View>
                            <View style={[styles.stats]}>
                            </View>
                        </View>


                        <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               large
                               mt={20}
                               font_medium>
                            {"Salgsstatistikk"}
                        </Label>
                        <View style={{marginBottom: 20}}>
                            {STATE_DATA.map(renderStats)}
                        </View>
                    </View>
                </View>

            )}
        </View>
    )
}

export default Home;
