import {StyleSheet} from 'react-native';
import {ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    versionCont: {
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal: ThemeUtils.relativeRealWidth(4),
        flexDirection: "row",
        alignItems:'center'
    },

});


