import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    labelCont: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 5
    },
    topContainer: {
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: 'center',
        justifyContent: 'space-between'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    viewicon: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mainviewdata: {
        marginTop:ThemeUtils.relativeRealHeight(2)
    },
    subviewdata: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16
    },
    cont: {
        marginTop: 20,
        paddingHorizontal: 20

    },
    ProCont: {
        flex: 1,
        paddingHorizontal: 20,
        paddingVertical: 10
    },
    imgCont: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    content: {
        flex: 1
    },
    commentview: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },


});
export {styles};
