import React from 'react'
import {View} from 'react-native'
import {Label} from "components/src/components";
import styles from './styles';

function Tag(props) {
    const {style, name,mt,ml, color} = props
    return (
        <View style={[styles.cont, {
            borderColor: color,
            backgroundColor: `${color}11`,
            marginTop:mt,
            marginStart:ml,
        }, style]}>
            <Label small
                   color={color}>
                {name}
            </Label>
        </View>
    )
}

export default Tag;
