import React, {useEffect, useState} from 'react'
import {Animated, View} from 'react-native'
import {IndicatorViewPager, Label, Ripple, WebView} from "components/src/components";
import {Color, CommonStyle, formatPrice} from "components/src/utils";
import styles from './styles';
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../../api/mutation";


function FullView(props) {

    const {data, vendorData, inspection, isCompanyTemplate, packages} = props;
    const IMAGES = isCompanyTemplate ? data?.office_service_media ?? [] : data?.agent_service_media ?? []

    const [selectedService, setSelectedService] = useState(vendorData?.length ? vendorData.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id) : data?.purchased_office_services?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id));

    const [serviceInPackage, setServiceInPackage] = useState([]);

    useEffect(() => {
        let service = []
        packages.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [packages])

    const addOrRemoveItemFromArray = (item) => {
        let updatedService = selectedService.slice();
        let variables = {
            property_id: inspection?.property_id,
            broker_on_view: false
        }
        if (updatedService.includes(item.id)) {
            updatedService.splice(updatedService.indexOf(item.id), 1)
            if (vendorData?.length) {
                variables.unselect_vendor_service_ids = item.service_selected?.id
            } else {
                variables.unselect_pro_service_ids = item.service_selected?.id
            }
            saveServices({
                variables: {
                    input: variables
                }
            }).catch(() => {
            })
        } else {
            updatedService.push(item.id)
        }
        //out side of else cause single selected properties
        if (vendorData?.length) {
            variables.vendor_service_ids = updatedService
        } else {
            variables.service_ids = updatedService
        }
        saveServices({
            variables: {
                input: variables
            }
        }).catch(() => {
        })
        setSelectedService(updatedService)
    }

    const [selectedImage, setSelectedImage] = useState(1);

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {
            props.refetch()
        }
    });

    const renderService = (item, index, self) => {
        let isSelected = vendorData?.length ? selectedService.includes(item.id) : (selectedService.includes(item.id) || serviceInPackage.includes(item.id))
        return (
            <Ripple
                style={[styles.serviceCont, isSelected ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => serviceInPackage.includes(item.id) && !vendorData.length ? null : addOrRemoveItemFromArray(item)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };

    return (
        <View style={styles.container}>


            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        <View style={styles.imgCountCont}>
                            <Label small
                                   color={Color.WHITE}>
                                {` ${selectedImage} / ${IMAGES.length} `}
                            </Label>
                        </View>
                        <IndicatorViewPager style={CommonStyle.flex}
                                            onPageSelected={(e) => setSelectedImage(e.position + 1)}>
                            {IMAGES.map(item => (
                                <View>
                                    <WebView source={{uri: IMAGES[selectedImage - 1]?.media_url}}/>
                                </View>
                            ))}
                        </IndicatorViewPager>

                    </View>


                    <View style={styles.lblCont}>
                        <Label large
                               font_medium>
                            {data?.headline}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR}
                               mt={10}
                               mb={10}>
                            {data?.description}
                        </Label>
                        {vendorData?.length ? vendorData.map(renderService) : data?.purchased_office_services?.map(renderService)}
                    </View>

                </View>
            </Animated.ScrollView>
        </View>

    )
}

export default FullView
