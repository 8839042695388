import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY,
    },
    contentCont: {
        flex: 1,
        alignItems: 'center',
    },
    header: {
        backgroundColor: Color.PRIMARY,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
    },
    img: {
        width: ThemeUtils.relativeWidth(100),
        height: ThemeUtils.relativeHeight(50)
    },
    lblCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    icClose: {
        position: 'absolute',
        zIndex: 100,
        top: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        start: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    dropDown: {
        borderWidth: 0,
        backgroundColor: Color.PRIMARY,
        borderRadius:0
    },
    modalCont: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: ThemeUtils.relativeWidth(10),
        backgroundColor: Color.PRIMARY_TRANSPARENT
    },
    dialogCont: {
        backgroundColor: Color.WHITE,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingTop: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 8,
        maxHeight: 800
    },
    imagePicker: {
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(110),
        width: ThemeUtils.responsiveScale(110),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    pickerCont: {
        flexDirection: 'row',
        marginTop:20,
        justifyContent: 'space-evenly'
    },
})

