import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    logo:{
        alignSelf:'center',
        height:ThemeUtils.responsiveScale(36),
        width:ThemeUtils.responsiveScale(70),
    }
});

export default styles;
