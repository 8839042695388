export default {
  'component.professional.title': 'Faglig',
  'component.professional.addprofessional': 'Legge til faglig',
  'component.professional.professionaltitle': 'Faglig tittel',
  'component.professional.professionaltitleplaceholder': 'Skriv inn faglig tittel',
  'component.professional.professionaldesc': 'Faglig beskrivelse',
  'component.professional.professionaldescplaceholder': 'Skriv inn faglig beskrivelse her ...',
  'component.professional.editprofessional': 'Rediger faglig',
  'component.professional.deleteprofessional': 'Slett faglig',
  'component.professional.deleteprofessionaldesc': 'Er du sikker på at du vil slette denne faglig?',
};
