import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    bottomNavCont: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'space-between',
        paddingVertical: ThemeUtils.relativeWidth(2),
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.PRIMARY
    },
    topContainer: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        alignContent: 'center',
        marginTop: 20
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labelContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    radioCont: {
        flexDirection: 'row'
    },
    radio: {
        alignItems: 'center'
    },
    chk: {
        marginVertical: 5
    },
    chkSub: {
        marginVertical: 5,
        marginStart: 20
    }
});
export default styles;
