import React from 'react';
//Components
import {View} from 'react-native';
import {Dashboard, Header, Label} from "components/src/components";
//Third party
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import energy_labeling from "../../../../assets/images/OppdragAgreementEdits.jpg";
import property_image_gallery from "../../../../assets/images/property_image_gallery.png";
import photo from "../../../../assets/images/Photo.png";
import digitalMarketing from "../../../../assets/images/Digital_marketing.jpg";
import marketingMaterial from "../../../../assets/images/Print_Marketing.jpg";


const DASHBOARD = [{
    name: `${Strings.photo} / ${Strings.styling}`,
    icon: 'photo',
    bg: photo,
    route: Routes.OfficePhotoStyling
}, {
    name: Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
    route: Routes.OfficeDigitalMarketing
}, {
    name: Strings.printMarketing,
    icon: 'print-marketing',
    bg: marketingMaterial,
    route: Routes.OfficePrintMarketing
}, {
    name: Strings.oppDragAgreement,
    icon: 'search',
    bg: energy_labeling,
    route: Routes.OfficeOppdragAgreement
}, {
    name: Strings.propertyImageGallery,
    icon: 'ds-property',
    bg: property_image_gallery,
    route: Routes.OfficePropertyImageGallery
}];

export default function OfficeTemplate(props) {
    const {navigation, history} = props;

    const handleDashboardItemPress = route => {
        if (IS_WEB) {
            props.history.push(`/${Routes.OfficeTemplate}/${route}`)
        } else {
            props.navigation.navigate(route)
        }
    };

    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}
            />

            <View style={styles.cont}>

                <Icon name={'office-template'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY_TEXT_COLOR}/>

                <Label xlarge
                       font_medium
                       ml={10}
                       mb={20}>
                    {Strings.officeTemplate}
                </Label>
            </View>
            <Dashboard dashboardData={DASHBOARD}
                       onPressItem={handleDashboardItemPress}/>
        </View>
    )
}
