// import { DownloadOutlined } from '@ant-design/icons';
import userPlaceholder from '../../../../assets/user_profile_placeholder.png';
import BefaringLayout from '../../../layouts/BefaringLayout';
import {STORAGE_URL} from '../../../utils/constant';
import {getDecryptedId} from '../../../utils/utils';
import {Button, Carousel, Col, Rate, Row, Spin, Typography} from 'antd';
import React, {useState} from 'react';
import {Query} from 'react-apollo';
import {SEND_OFFER_DETAIL} from '../../../../query/AgentQuery';
import './aboutagent.scss';
import Strings from '../../../../locales/nb-NO';


const formatMessage = ({id}) => {
    return Strings[id]
}
const {Title, Paragraph} = Typography;


const props1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    default: true,
};

const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    centerMode: true,
    centerPadding: '30%',
    responsive: [
        {
            breakpoint: 769,
            settings: {
                arrows: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                infinite: true,
                centerPadding: '15%',
            },
        },
        {
            breakpoint: 600,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '15%',
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '15%',
            },
        },
    ],
};

const mediaprops = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    default: true,
};

let carousel = React.createRef();
let carousel1 = React.createRef();
let galleryCarousel = React.createRef();

const next = () => {
    carousel.next();
};

const previous = () => {
    carousel.prev();
};

const next1 = () => {
    carousel1.next();
};

const previous1 = () => {
    carousel1.prev();
};

const galleryCarouselNext = () => {
    galleryCarousel.next();
};

const galleryCarouselPrevious = () => {
    galleryCarousel.prev();
};

export const AboutAAgent = props => {
    let router = props.history;
    let propertyId;
    if (props && props.match && props.match.params) {
        propertyId = getDecryptedId(props.match.params.propertyId);
    } else {
        router.push('/');
    }
    if (propertyId === 'error') {
        router.push('/');
    }
    console.log('PropertyId==>', propertyId);
    const [viewGallery, setViewGallery] = useState(false);
    const [viewGallerySlider, setViewGallerySlider] = useState(false);

    const onChange = () => {
        console.log('all well');
    };

    const handleGalleryMode = () => {
        document.body.classList.add('overflow-hidden');
        setViewGallery(true);
    };

    const handleGalleryClose = () => {
        console.log('clicked');
        document.body.classList.remove('overflow-hidden');
        setViewGallery(false);
    };

    return (
        <BefaringLayout>
            <div className="contentshadow bg-none broker-section container_800">
                <Query
                    query={SEND_OFFER_DETAIL}
                    fetchPolicy="cache-and-network"
                    variables={{
                        id: propertyId,
                    }}
                >
                    {({data, error, loading, fetchMore, refetch}) => {
                        if (data && !loading) {
                            if (data.send_offer_detail) {
                                return (
                                    <>
                                        <Row gutter={48}>
                                            <Col xs={24} lg={8}>
                                                <img
                                                    src={
                                                        data?.send_offer_detail?.agent?.profile_image
                                                            ? `${STORAGE_URL}${data?.send_offer_detail?.agent?.profile_image}`
                                                            : userPlaceholder
                                                    }
                                                    alt="profile"
                                                    className="img-fluid"
                                                />
                                                {data?.send_offer_detail?.agent?.title && (
                                                    <>
                                                        <Title level={3} className="mt-3 commonClass">
                                                            Tittel
                                                        </Title>
                                                        <div>
                                                            <p className="commonText">{data?.send_offer_detail?.agent?.title}</p>
                                                        </div>
                                                    </>
                                                )}
                                                {data?.send_offer_detail?.agent?.user_detail?.education && (
                                                    <>
                                                        <Title level={4} className="mt-3 commonClass">
                                                            Utdanning
                                                        </Title>
                                                        <div>
                                                            <p className="commonText">
                                                                {data?.send_offer_detail?.agent?.user_detail?.education}
                                                            </p>
                                                        </div>
                                                    </>
                                                )}
                                                {/* {data?.send_offer_detail?.agent?.office?.name && (
                          <>
                            <Title level={4} className="mt-3 commonClass">
                              Kontor/foretak
                            </Title>
                            <p className="commonText" style={{ color: 'red' }}>
                              {data?.send_offer_detail?.agent?.office?.name}
                            </p>
                          </>
                        )} */}
                                            </Col>
                                            <Col xs={24} lg={16}>
                                                <h1 className="text-uppercase agentNameClass">
                                                    {data?.send_offer_detail?.agent?.first_name}{' '}
                                                    {data?.send_offer_detail?.agent?.last_name}
                                                </h1>
                                                <p className="agentPage mb-0">{data?.send_offer_detail?.agent?.title}</p>
                                                <p className="agentPage">
                                                    E:{' '}
                                                    <a
                                                        className="text-secondary"
                                                        href={`mailto:${data?.send_offer_detail?.agent?.email}`}
                                                    >
                                                        {data?.send_offer_detail?.agent?.email}
                                                    </a>
                                                </p>
                                                <p className="agentPage">M: {data?.send_offer_detail?.agent?.mobile}</p>
                                                {data?.send_offer_detail?.agent?.user_detail?.about && (
                                                    <>
                                                        <Title
                                                            level={4}
                                                            className="kort"
                                                        >{`Kort om ${data?.send_offer_detail?.agent?.first_name} ${data?.send_offer_detail?.agent?.last_name}`}</Title>
                                                        <Paragraph>
                              <span
                                  className="profileInfoRow"
                                  dangerouslySetInnerHTML={{
                                      __html: data?.send_offer_detail?.agent?.user_detail?.about,
                                  }}
                              />
                                                        </Paragraph>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={24}>
                                                {data?.send_offer_detail?.agent?.feedback &&
                                                data?.send_offer_detail?.agent?.feedback.length > 0 && (
                                                    <>
                                                        <Title
                                                            level={4}
                                                            className="mt-3 subTitle titleCenter"
                                                            style={{fontSize: '28px'}}
                                                        >
                                                            Tilbakemelding
                                                            fra {data?.send_offer_detail?.agent?.first_name}{' '}
                                                            {data?.send_offer_detail?.agent?.last_name} sine kunder
                                                        </Title>
                                                        <hr className="heading_line"/>
                                                        <Row>
                                                            <Col xs={24} className="position-relative">
                                                                <div className="arrows left" onClick={previous}>
                                                                    <i className=" icon-arrow-l"/>
                                                                </div>

                                                                <Carousel
                                                                    className="review_slider"
                                                                    ref={node => {
                                                                        carousel = node;
                                                                    }}
                                                                    {...props1}
                                                                >
                                                                    {data?.send_offer_detail?.agent?.feedback.map(i => {
                                                                        return (
                                                                            <div className="mx-auto">
                                                                                <div
                                                                                    className="carousel_img text-center feedback_box">
                                                                                    <Rate
                                                                                        allowHalf
                                                                                        defaultValue={parseInt(i.rating, 10)}
                                                                                        disabled
                                                                                        style={{color: '#A3875B'}}
                                                                                    />
                                                                                    <p>{i.feedback}</p>
                                                                                    <p className="givenby">{i.given_by}</p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Carousel>
                                                                <div className="arrows right" onClick={next}>
                                                                    <i
                                                                        className=" icon-arrow-r"
                                                                        style={{color: '#A3875B !important'}}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                        {/* <hr className="mt-3 mb-1" /> */}
                                        <Row className="mt-3 mb-1">
                                            <Col xs={24}>
                                                {data?.send_offer_detail?.agent?.user_media &&
                                                data?.send_offer_detail?.agent?.user_media.length > 0 && (
                                                    <>
                                                        <Title level={4}
                                                               className="subTitle titleCenter presse-section">
                                                            Presse
                                                        </Title>
                                                        <hr className="heading_line"/>
                                                        <Row>
                                                            <Col xs={24} className="position-relative presse-slider">
                                                                <div className="arrows left befaring-left"
                                                                     onClick={previous1}>
                                                                    <i className=" icon-arrow-l"/>
                                                                </div>
                                                                <Carousel
                                                                    ref={node => {
                                                                        carousel1 = node;
                                                                    }}
                                                                    {...setting}
                                                                >
                                                                    {data?.send_offer_detail?.agent?.user_media.map(i => (
                                                                        <a
                                                                            href={i.redirect_url}
                                                                            target="_blank"
                                                                            className="card-image img-fluid"
                                                                        >
                                                                            <img
                                                                                style={{ padding: '16px 0' }}
                                                                                src={`${STORAGE_URL}${i.media_url}`}
                                                                                alt="profile"
                                                                                className="img-fluid"
                                                                            />
                                                                            <p className="text-left press_description">{i.title}</p>
                                                                        </a>
                                                                    ))}
                                                                </Carousel>
                                                                <div className="arrows right" onClick={next1}>
                                                                    <i className=" icon-arrow-r"/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                );
                            }
                            return <div>{formatMessage({id: 'component.nodata'})}</div>;
                        }
                        return (
                            <div className="loader-wrapper">
                                <Spin/>
                            </div>
                        );
                    }}
                </Query>

                <Row className="mb-3">
                    <Col xs={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-secondary buttons admin-forms-btn mb-3 back-button ml-0"
                            size="large"
                            onClick={router.goBack}
                        >
                            {formatMessage({
                                id: 'component.form.back',
                            })}
                        </Button>
                    </Col>
                </Row>
            </div>
        </BefaringLayout>
    );
};

export default AboutAAgent;
