import React, {useEffect, useState} from 'react'
import {ActivityIndicator, ScrollView, TouchableOpacity, View} from 'react-native'
import {
    AddServiceView,
    CostingAddServiceView,
    CustomButton,
    DropDown,
    Header,
    Hr,
    InputField,
    Label,
    LogoForHeader,
    Notes,
    PagerTabIndicator,
    Toast
} from "components/src/components";
import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    formatPrice,
    Icon,
    IS_WEB,
    Routes, showMessage,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import styles from './styles';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PROPERTY_SERVICES, SEND_PREFERENCE} from "../../../../../../api/mutation";
import {connect} from "react-redux";
import {PROPERTY_PREFERENCE, SELECTED_PROPERTY_SERVICE} from "../../../../../../api/query";
import debounce from "lodash.debounce";

const PRICE = [
    {
        "name": "0,-",
        "value": 0
    },
    {
        "name": "50.000,-",
        "value": 50000
    },
    {
        "name": "100.000,-",
        "value": 100000
    },
    {
        "name": "150.000,-",
        "value": 150000
    },
    {
        "name": "200.000,-",
        "value": 200000
    },
    {
        "name": "250.000,-",
        "value": 250000
    },
    {
        "name": "300.000,-",
        "value": 300000
    },
    {
        "name": "350.000,-",
        "value": 350000
    },
    {
        "name": "400.000,-",
        "value": 400000
    },
    {
        "name": "450.000,-",
        "value": 450000
    },
    {
        "name": "500.000,-",
        "value": 500000
    },
    {
        "name": "550.000,-",
        "value": 550000
    },
    {
        "name": "600.000,-",
        "value": 600000
    },
    {
        "name": "650.000,-",
        "value": 650000
    },
    {
        "name": "700.000,-",
        "value": 700000
    },
    {
        "name": "750.000,-",
        "value": 750000
    },
    {
        "name": "800.000,-",
        "value": 800000
    },
    {
        "name": "850.000,-",
        "value": 850000
    },
    {
        "name": "900.000,-",
        "value": 900000
    },
    {
        "name": "950.000,-",
        "value": 950000
    },
    {
        "name": "1.000.000,-",
        "value": 1000000
    },
    {
        "name": "1.050.000,-",
        "value": 1050000
    },
    {
        "name": "1.100.000,-",
        "value": 1100000
    },
    {
        "name": "1.150.000,-",
        "value": 1150000
    },
    {
        "name": "1.200.000,-",
        "value": 1200000
    },
    {
        "name": "1.250.000,-",
        "value": 1250000
    },
    {
        "name": "1.300.000,-",
        "value": 1300000
    },
    {
        "name": "1.350.000,-",
        "value": 1350000
    },
    {
        "name": "1.400.000,-",
        "value": 1400000
    },
    {
        "name": "1.450.000,-",
        "value": 1450000
    },
    {
        "name": "1.500.000,-",
        "value": 1500000
    },
    {
        "name": "1.550.000,-",
        "value": 1550000
    },
    {
        "name": "1.600.000,-",
        "value": 1600000
    },
    {
        "name": "1.650.000,-",
        "value": 1650000
    },
    {
        "name": "1.700.000,-",
        "value": 1700000
    },
    {
        "name": "1.750.000,-",
        "value": 1750000
    },
    {
        "name": "1.800.000,-",
        "value": 1800000
    },
    {
        "name": "1.850.000,-",
        "value": 1850000
    },
    {
        "name": "1.900.000,-",
        "value": 1900000
    },
    {
        "name": "1.950.000,-",
        "value": 1950000
    },
    {
        "name": "2.000.000,-",
        "value": 2000000
    },
    {
        "name": "2.050.000,-",
        "value": 2050000
    },
    {
        "name": "2.100.000,-",
        "value": 2100000
    },
    {
        "name": "2.150.000,-",
        "value": 2150000
    },
    {
        "name": "2.200.000,-",
        "value": 2200000
    },
    {
        "name": "2.250.000,-",
        "value": 2250000
    },
    {
        "name": "2.300.000,-",
        "value": 2300000
    },
    {
        "name": "2.350.000,-",
        "value": 2350000
    },
    {
        "name": "2.400.000,-",
        "value": 2400000
    },
    {
        "name": "2.450.000,-",
        "value": 2450000
    },
    {
        "name": "2.500.000,-",
        "value": 2500000
    },
    {
        "name": "2.550.000,-",
        "value": 2550000
    },
    {
        "name": "2.600.000,-",
        "value": 2600000
    },
    {
        "name": "2.650.000,-",
        "value": 2650000
    },
    {
        "name": "2.700.000,-",
        "value": 2700000
    },
    {
        "name": "2.750.000,-",
        "value": 2750000
    },
    {
        "name": "2.800.000,-",
        "value": 2800000
    },
    {
        "name": "2.850.000,-",
        "value": 2850000
    },
    {
        "name": "2.900.000,-",
        "value": 2900000
    },
    {
        "name": "2.950.000,-",
        "value": 2950000
    },
    {
        "name": "3.000.000,-",
        "value": 3000000
    },
    {
        "name": "3.050.000,-",
        "value": 3050000
    },
    {
        "name": "3.100.000,-",
        "value": 3100000
    },
    {
        "name": "3.150.000,-",
        "value": 3150000
    },
    {
        "name": "3.200.000,-",
        "value": 3200000
    },
    {
        "name": "3.250.000,-",
        "value": 3250000
    },
    {
        "name": "3.300.000,-",
        "value": 3300000
    },
    {
        "name": "3.350.000,-",
        "value": 3350000
    },
    {
        "name": "3.400.000,-",
        "value": 3400000
    },
    {
        "name": "3.450.000,-",
        "value": 3450000
    },
    {
        "name": "3.500.000,-",
        "value": 3500000
    },
    {
        "name": "3.550.000,-",
        "value": 3550000
    },
    {
        "name": "3.600.000,-",
        "value": 3600000
    },
    {
        "name": "3.650.000,-",
        "value": 3650000
    },
    {
        "name": "3.700.000,-",
        "value": 3700000
    },
    {
        "name": "3.750.000,-",
        "value": 3750000
    },
    {
        "name": "3.800.000,-",
        "value": 3800000
    },
    {
        "name": "3.850.000,-",
        "value": 3850000
    },
    {
        "name": "3.900.000,-",
        "value": 3900000
    },
    {
        "name": "3.950.000,-",
        "value": 3950000
    },
    {
        "name": "4.000.000,-",
        "value": 4000000
    },
    {
        "name": "4.050.000,-",
        "value": 4050000
    },
    {
        "name": "4.100.000,-",
        "value": 4100000
    },
    {
        "name": "4.150.000,-",
        "value": 4150000
    },
    {
        "name": "4.200.000,-",
        "value": 4200000
    },
    {
        "name": "4.250.000,-",
        "value": 4250000
    },
    {
        "name": "4.300.000,-",
        "value": 4300000
    },
    {
        "name": "4.350.000,-",
        "value": 4350000
    },
    {
        "name": "4.400.000,-",
        "value": 4400000
    },
    {
        "name": "4.450.000,-",
        "value": 4450000
    },
    {
        "name": "4.500.000,-",
        "value": 4500000
    },
    {
        "name": "4.550.000,-",
        "value": 4550000
    },
    {
        "name": "4.600.000,-",
        "value": 4600000
    },
    {
        "name": "4.650.000,-",
        "value": 4650000
    },
    {
        "name": "4.700.000,-",
        "value": 4700000
    },
    {
        "name": "4.750.000,-",
        "value": 4750000
    },
    {
        "name": "4.800.000,-",
        "value": 4800000
    },
    {
        "name": "4.850.000,-",
        "value": 4850000
    },
    {
        "name": "4.900.000,-",
        "value": 4900000
    },
    {
        "name": "4.950.000,-",
        "value": 4950000
    },
    {
        "name": "5.000.000,-",
        "value": 5000000
    },
    {
        "name": "5.050.000,-",
        "value": 5050000
    },
    {
        "name": "5.100.000,-",
        "value": 5100000
    },
    {
        "name": "5.150.000,-",
        "value": 5150000
    },
    {
        "name": "5.200.000,-",
        "value": 5200000
    },
    {
        "name": "5.250.000,-",
        "value": 5250000
    },
    {
        "name": "5.300.000,-",
        "value": 5300000
    },
    {
        "name": "5.350.000,-",
        "value": 5350000
    },
    {
        "name": "5.400.000,-",
        "value": 5400000
    },
    {
        "name": "5.450.000,-",
        "value": 5450000
    },
    {
        "name": "5.500.000,-",
        "value": 5500000
    },
    {
        "name": "5.550.000,-",
        "value": 5550000
    },
    {
        "name": "5.600.000,-",
        "value": 5600000
    },
    {
        "name": "5.650.000,-",
        "value": 5650000
    },
    {
        "name": "5.700.000,-",
        "value": 5700000
    },
    {
        "name": "5.750.000,-",
        "value": 5750000
    },
    {
        "name": "5.800.000,-",
        "value": 5800000
    },
    {
        "name": "5.850.000,-",
        "value": 5850000
    },
    {
        "name": "5.900.000,-",
        "value": 5900000
    },
    {
        "name": "5.950.000,-",
        "value": 5950000
    },
    {
        "name": "6.000.000,-",
        "value": 6000000
    },
    {
        "name": "6.050.000,-",
        "value": 6050000
    },
    {
        "name": "6.100.000,-",
        "value": 6100000
    },
    {
        "name": "6.150.000,-",
        "value": 6150000
    },
    {
        "name": "6.200.000,-",
        "value": 6200000
    },
    {
        "name": "6.250.000,-",
        "value": 6250000
    },
    {
        "name": "6.300.000,-",
        "value": 6300000
    },
    {
        "name": "6.350.000,-",
        "value": 6350000
    },
    {
        "name": "6.400.000,-",
        "value": 6400000
    },
    {
        "name": "6.450.000,-",
        "value": 6450000
    },
    {
        "name": "6.500.000,-",
        "value": 6500000
    },
    {
        "name": "6.550.000,-",
        "value": 6550000
    },
    {
        "name": "6.600.000,-",
        "value": 6600000
    },
    {
        "name": "6.650.000,-",
        "value": 6650000
    },
    {
        "name": "6.700.000,-",
        "value": 6700000
    },
    {
        "name": "6.750.000,-",
        "value": 6750000
    },
    {
        "name": "6.800.000,-",
        "value": 6800000
    },
    {
        "name": "6.850.000,-",
        "value": 6850000
    },
    {
        "name": "6.900.000,-",
        "value": 6900000
    },
    {
        "name": "6.950.000,-",
        "value": 6950000
    },
    {
        "name": "7.000.000,-",
        "value": 7000000
    },
    {
        "name": "7.050.000,-",
        "value": 7050000
    },
    {
        "name": "7.100.000,-",
        "value": 7100000
    },
    {
        "name": "7.150.000,-",
        "value": 7150000
    },
    {
        "name": "7.200.000,-",
        "value": 7200000
    },
    {
        "name": "7.250.000,-",
        "value": 7250000
    },
    {
        "name": "7.300.000,-",
        "value": 7300000
    },
    {
        "name": "7.350.000,-",
        "value": 7350000
    },
    {
        "name": "7.400.000,-",
        "value": 7400000
    },
    {
        "name": "7.450.000,-",
        "value": 7450000
    },
    {
        "name": "7.500.000,-",
        "value": 7500000
    },
    {
        "name": "7.550.000,-",
        "value": 7550000
    },
    {
        "name": "7.600.000,-",
        "value": 7600000
    },
    {
        "name": "7.650.000,-",
        "value": 7650000
    },
    {
        "name": "7.700.000,-",
        "value": 7700000
    },
    {
        "name": "7.750.000,-",
        "value": 7750000
    },
    {
        "name": "7.800.000,-",
        "value": 7800000
    },
    {
        "name": "7.850.000,-",
        "value": 7850000
    },
    {
        "name": "7.900.000,-",
        "value": 7900000
    },
    {
        "name": "7.950.000,-",
        "value": 7950000
    },
    {
        "name": "8.000.000,-",
        "value": 8000000
    },
    {
        "name": "8.050.000,-",
        "value": 8050000
    },
    {
        "name": "8.100.000,-",
        "value": 8100000
    },
    {
        "name": "8.150.000,-",
        "value": 8150000
    },
    {
        "name": "8.200.000,-",
        "value": 8200000
    },
    {
        "name": "8.250.000,-",
        "value": 8250000
    },
    {
        "name": "8.300.000,-",
        "value": 8300000
    },
    {
        "name": "8.350.000,-",
        "value": 8350000
    },
    {
        "name": "8.400.000,-",
        "value": 8400000
    },
    {
        "name": "8.450.000,-",
        "value": 8450000
    },
    {
        "name": "8.500.000,-",
        "value": 8500000
    },
    {
        "name": "8.550.000,-",
        "value": 8550000
    },
    {
        "name": "8.600.000,-",
        "value": 8600000
    },
    {
        "name": "8.650.000,-",
        "value": 8650000
    },
    {
        "name": "8.700.000,-",
        "value": 8700000
    },
    {
        "name": "8.750.000,-",
        "value": 8750000
    },
    {
        "name": "8.800.000,-",
        "value": 8800000
    },
    {
        "name": "8.850.000,-",
        "value": 8850000
    },
    {
        "name": "8.900.000,-",
        "value": 8900000
    },
    {
        "name": "8.950.000,-",
        "value": 8950000
    },
    {
        "name": "9.000.000,-",
        "value": 9000000
    },
    {
        "name": "9.050.000,-",
        "value": 9050000
    },
    {
        "name": "9.100.000,-",
        "value": 9100000
    },
    {
        "name": "9.150.000,-",
        "value": 9150000
    },
    {
        "name": "9.200.000,-",
        "value": 9200000
    },
    {
        "name": "9.250.000,-",
        "value": 9250000
    },
    {
        "name": "9.300.000,-",
        "value": 9300000
    },
    {
        "name": "9.350.000,-",
        "value": 9350000
    },
    {
        "name": "9.400.000,-",
        "value": 9400000
    },
    {
        "name": "9.450.000,-",
        "value": 9450000
    },
    {
        "name": "9.500.000,-",
        "value": 9500000
    },
    {
        "name": "9.550.000,-",
        "value": 9550000
    },
    {
        "name": "9.600.000,-",
        "value": 9600000
    },
    {
        "name": "9.650.000,-",
        "value": 9650000
    },
    {
        "name": "9.700.000,-",
        "value": 9700000
    },
    {
        "name": "9.750.000,-",
        "value": 9750000
    },
    {
        "name": "9.800.000,-",
        "value": 9800000
    },
    {
        "name": "9.850.000,-",
        "value": 9850000
    },
    {
        "name": "9.900.000,-",
        "value": 9900000
    },
    {
        "name": "9.950.000,-",
        "value": 9950000
    },
    {
        "name": "10.000.000,-",
        "value": 10000000
    },
    {
        "name": "10.050.000,-",
        "value": 10050000
    },
    {
        "name": "10.100.000,-",
        "value": 10100000
    },
    {
        "name": "10.150.000,-",
        "value": 10150000
    },
    {
        "name": "10.200.000,-",
        "value": 10200000
    },
    {
        "name": "10.250.000,-",
        "value": 10250000
    },
    {
        "name": "10.300.000,-",
        "value": 10300000
    },
    {
        "name": "10.350.000,-",
        "value": 10350000
    },
    {
        "name": "10.400.000,-",
        "value": 10400000
    },
    {
        "name": "10.450.000,-",
        "value": 10450000
    },
    {
        "name": "10.500.000,-",
        "value": 10500000
    },
    {
        "name": "10.550.000,-",
        "value": 10550000
    },
    {
        "name": "10.600.000,-",
        "value": 10600000
    },
    {
        "name": "10.650.000,-",
        "value": 10650000
    },
    {
        "name": "10.700.000,-",
        "value": 10700000
    },
    {
        "name": "10.750.000,-",
        "value": 10750000
    },
    {
        "name": "10.800.000,-",
        "value": 10800000
    },
    {
        "name": "10.850.000,-",
        "value": 10850000
    },
    {
        "name": "10.900.000,-",
        "value": 10900000
    },
    {
        "name": "10.950.000,-",
        "value": 10950000
    },
    {
        "name": "11.000.000,-",
        "value": 11000000
    },
    {
        "name": "11.050.000,-",
        "value": 11050000
    },
    {
        "name": "11.100.000,-",
        "value": 11100000
    },
    {
        "name": "11.150.000,-",
        "value": 11150000
    },
    {
        "name": "11.200.000,-",
        "value": 11200000
    },
    {
        "name": "11.250.000,-",
        "value": 11250000
    },
    {
        "name": "11.300.000,-",
        "value": 11300000
    },
    {
        "name": "11.350.000,-",
        "value": 11350000
    },
    {
        "name": "11.400.000,-",
        "value": 11400000
    },
    {
        "name": "11.450.000,-",
        "value": 11450000
    },
    {
        "name": "11.500.000,-",
        "value": 11500000
    },
    {
        "name": "11.550.000,-",
        "value": 11550000
    },
    {
        "name": "11.600.000,-",
        "value": 11600000
    },
    {
        "name": "11.650.000,-",
        "value": 11650000
    },
    {
        "name": "11.700.000,-",
        "value": 11700000
    },
    {
        "name": "11.750.000,-",
        "value": 11750000
    },
    {
        "name": "11.800.000,-",
        "value": 11800000
    },
    {
        "name": "11.850.000,-",
        "value": 11850000
    },
    {
        "name": "11.900.000,-",
        "value": 11900000
    },
    {
        "name": "11.950.000,-",
        "value": 11950000
    },
    {
        "name": "12.000.000,-",
        "value": 12000000
    },
    {
        "name": "12.050.000,-",
        "value": 12050000
    },
    {
        "name": "12.100.000,-",
        "value": 12100000
    },
    {
        "name": "12.150.000,-",
        "value": 12150000
    },
    {
        "name": "12.200.000,-",
        "value": 12200000
    },
    {
        "name": "12.250.000,-",
        "value": 12250000
    },
    {
        "name": "12.300.000,-",
        "value": 12300000
    },
    {
        "name": "12.350.000,-",
        "value": 12350000
    },
    {
        "name": "12.400.000,-",
        "value": 12400000
    },
    {
        "name": "12.450.000,-",
        "value": 12450000
    },
    {
        "name": "12.500.000,-",
        "value": 12500000
    },
    {
        "name": "12.550.000,-",
        "value": 12550000
    },
    {
        "name": "12.600.000,-",
        "value": 12600000
    },
    {
        "name": "12.650.000,-",
        "value": 12650000
    },
    {
        "name": "12.700.000,-",
        "value": 12700000
    },
    {
        "name": "12.750.000,-",
        "value": 12750000
    },
    {
        "name": "12.800.000,-",
        "value": 12800000
    },
    {
        "name": "12.850.000,-",
        "value": 12850000
    },
    {
        "name": "12.900.000,-",
        "value": 12900000
    },
    {
        "name": "12.950.000,-",
        "value": 12950000
    },
    {
        "name": "13.000.000,-",
        "value": 13000000
    },
    {
        "name": "13.050.000,-",
        "value": 13050000
    },
    {
        "name": "13.100.000,-",
        "value": 13100000
    },
    {
        "name": "13.150.000,-",
        "value": 13150000
    },
    {
        "name": "13.200.000,-",
        "value": 13200000
    },
    {
        "name": "13.250.000,-",
        "value": 13250000
    },
    {
        "name": "13.300.000,-",
        "value": 13300000
    },
    {
        "name": "13.350.000,-",
        "value": 13350000
    },
    {
        "name": "13.400.000,-",
        "value": 13400000
    },
    {
        "name": "13.450.000,-",
        "value": 13450000
    },
    {
        "name": "13.500.000,-",
        "value": 13500000
    },
    {
        "name": "13.550.000,-",
        "value": 13550000
    },
    {
        "name": "13.600.000,-",
        "value": 13600000
    },
    {
        "name": "13.650.000,-",
        "value": 13650000
    },
    {
        "name": "13.700.000,-",
        "value": 13700000
    },
    {
        "name": "13.750.000,-",
        "value": 13750000
    },
    {
        "name": "13.800.000,-",
        "value": 13800000
    },
    {
        "name": "13.850.000,-",
        "value": 13850000
    },
    {
        "name": "13.900.000,-",
        "value": 13900000
    },
    {
        "name": "13.950.000,-",
        "value": 13950000
    },
    {
        "name": "14.000.000,-",
        "value": 14000000
    },
    {
        "name": "14.050.000,-",
        "value": 14050000
    },
    {
        "name": "14.100.000,-",
        "value": 14100000
    },
    {
        "name": "14.150.000,-",
        "value": 14150000
    },
    {
        "name": "14.200.000,-",
        "value": 14200000
    },
    {
        "name": "14.250.000,-",
        "value": 14250000
    },
    {
        "name": "14.300.000,-",
        "value": 14300000
    },
    {
        "name": "14.350.000,-",
        "value": 14350000
    },
    {
        "name": "14.400.000,-",
        "value": 14400000
    },
    {
        "name": "14.450.000,-",
        "value": 14450000
    },
    {
        "name": "14.500.000,-",
        "value": 14500000
    },
    {
        "name": "14.550.000,-",
        "value": 14550000
    },
    {
        "name": "14.600.000,-",
        "value": 14600000
    },
    {
        "name": "14.650.000,-",
        "value": 14650000
    },
    {
        "name": "14.700.000,-",
        "value": 14700000
    },
    {
        "name": "14.750.000,-",
        "value": 14750000
    },
    {
        "name": "14.800.000,-",
        "value": 14800000
    },
    {
        "name": "14.850.000,-",
        "value": 14850000
    },
    {
        "name": "14.900.000,-",
        "value": 14900000
    },
    {
        "name": "14.950.000,-",
        "value": 14950000
    },
    {
        "name": "15.000.000,-",
        "value": 15000000
    },
    {
        "name": "15.050.000,-",
        "value": 15050000
    },
    {
        "name": "15.100.000,-",
        "value": 15100000
    },
    {
        "name": "15.150.000,-",
        "value": 15150000
    },
    {
        "name": "15.200.000,-",
        "value": 15200000
    },
    {
        "name": "15.250.000,-",
        "value": 15250000
    },
    {
        "name": "15.300.000,-",
        "value": 15300000
    },
    {
        "name": "15.350.000,-",
        "value": 15350000
    },
    {
        "name": "15.400.000,-",
        "value": 15400000
    },
    {
        "name": "15.450.000,-",
        "value": 15450000
    },
    {
        "name": "15.500.000,-",
        "value": 15500000
    },
    {
        "name": "15.550.000,-",
        "value": 15550000
    },
    {
        "name": "15.600.000,-",
        "value": 15600000
    },
    {
        "name": "15.650.000,-",
        "value": 15650000
    },
    {
        "name": "15.700.000,-",
        "value": 15700000
    },
    {
        "name": "15.750.000,-",
        "value": 15750000
    },
    {
        "name": "15.800.000,-",
        "value": 15800000
    },
    {
        "name": "15.850.000,-",
        "value": 15850000
    },
    {
        "name": "15.900.000,-",
        "value": 15900000
    },
    {
        "name": "15.950.000,-",
        "value": 15950000
    },
    {
        "name": "16.000.000,-",
        "value": 16000000
    },
    {
        "name": "16.050.000,-",
        "value": 16050000
    },
    {
        "name": "16.100.000,-",
        "value": 16100000
    },
    {
        "name": "16.150.000,-",
        "value": 16150000
    },
    {
        "name": "16.200.000,-",
        "value": 16200000
    },
    {
        "name": "16.250.000,-",
        "value": 16250000
    },
    {
        "name": "16.300.000,-",
        "value": 16300000
    },
    {
        "name": "16.350.000,-",
        "value": 16350000
    },
    {
        "name": "16.400.000,-",
        "value": 16400000
    },
    {
        "name": "16.450.000,-",
        "value": 16450000
    },
    {
        "name": "16.500.000,-",
        "value": 16500000
    },
    {
        "name": "16.550.000,-",
        "value": 16550000
    },
    {
        "name": "16.600.000,-",
        "value": 16600000
    },
    {
        "name": "16.650.000,-",
        "value": 16650000
    },
    {
        "name": "16.700.000,-",
        "value": 16700000
    },
    {
        "name": "16.750.000,-",
        "value": 16750000
    },
    {
        "name": "16.800.000,-",
        "value": 16800000
    },
    {
        "name": "16.850.000,-",
        "value": 16850000
    },
    {
        "name": "16.900.000,-",
        "value": 16900000
    },
    {
        "name": "16.950.000,-",
        "value": 16950000
    },
    {
        "name": "17.000.000,-",
        "value": 17000000
    },
    {
        "name": "17.050.000,-",
        "value": 17050000
    },
    {
        "name": "17.100.000,-",
        "value": 17100000
    },
    {
        "name": "17.150.000,-",
        "value": 17150000
    },
    {
        "name": "17.200.000,-",
        "value": 17200000
    },
    {
        "name": "17.250.000,-",
        "value": 17250000
    },
    {
        "name": "17.300.000,-",
        "value": 17300000
    },
    {
        "name": "17.350.000,-",
        "value": 17350000
    },
    {
        "name": "17.400.000,-",
        "value": 17400000
    },
    {
        "name": "17.450.000,-",
        "value": 17450000
    },
    {
        "name": "17.500.000,-",
        "value": 17500000
    },
    {
        "name": "17.550.000,-",
        "value": 17550000
    },
    {
        "name": "17.600.000,-",
        "value": 17600000
    },
    {
        "name": "17.650.000,-",
        "value": 17650000
    },
    {
        "name": "17.700.000,-",
        "value": 17700000
    },
    {
        "name": "17.750.000,-",
        "value": 17750000
    },
    {
        "name": "17.800.000,-",
        "value": 17800000
    },
    {
        "name": "17.850.000,-",
        "value": 17850000
    },
    {
        "name": "17.900.000,-",
        "value": 17900000
    },
    {
        "name": "17.950.000,-",
        "value": 17950000
    },
    {
        "name": "18.000.000,-",
        "value": 18000000
    },
    {
        "name": "18.050.000,-",
        "value": 18050000
    },
    {
        "name": "18.100.000,-",
        "value": 18100000
    },
    {
        "name": "18.150.000,-",
        "value": 18150000
    },
    {
        "name": "18.200.000,-",
        "value": 18200000
    },
    {
        "name": "18.250.000,-",
        "value": 18250000
    },
    {
        "name": "18.300.000,-",
        "value": 18300000
    },
    {
        "name": "18.350.000,-",
        "value": 18350000
    },
    {
        "name": "18.400.000,-",
        "value": 18400000
    },
    {
        "name": "18.450.000,-",
        "value": 18450000
    },
    {
        "name": "18.500.000,-",
        "value": 18500000
    },
    {
        "name": "18.550.000,-",
        "value": 18550000
    },
    {
        "name": "18.600.000,-",
        "value": 18600000
    },
    {
        "name": "18.650.000,-",
        "value": 18650000
    },
    {
        "name": "18.700.000,-",
        "value": 18700000
    },
    {
        "name": "18.750.000,-",
        "value": 18750000
    },
    {
        "name": "18.800.000,-",
        "value": 18800000
    },
    {
        "name": "18.850.000,-",
        "value": 18850000
    },
    {
        "name": "18.900.000,-",
        "value": 18900000
    },
    {
        "name": "18.950.000,-",
        "value": 18950000
    },
    {
        "name": "19.000.000,-",
        "value": 19000000
    },
    {
        "name": "19.050.000,-",
        "value": 19050000
    },
    {
        "name": "19.100.000,-",
        "value": 19100000
    },
    {
        "name": "19.150.000,-",
        "value": 19150000
    },
    {
        "name": "19.200.000,-",
        "value": 19200000
    },
    {
        "name": "19.250.000,-",
        "value": 19250000
    },
    {
        "name": "19.300.000,-",
        "value": 19300000
    },
    {
        "name": "19.350.000,-",
        "value": 19350000
    },
    {
        "name": "19.400.000,-",
        "value": 19400000
    },
    {
        "name": "19.450.000,-",
        "value": 19450000
    },
    {
        "name": "19.500.000,-",
        "value": 19500000
    },
    {
        "name": "19.550.000,-",
        "value": 19550000
    },
    {
        "name": "19.600.000,-",
        "value": 19600000
    },
    {
        "name": "19.650.000,-",
        "value": 19650000
    },
    {
        "name": "19.700.000,-",
        "value": 19700000
    },
    {
        "name": "19.750.000,-",
        "value": 19750000
    },
    {
        "name": "19.800.000,-",
        "value": 19800000
    },
    {
        "name": "19.850.000,-",
        "value": 19850000
    },
    {
        "name": "19.900.000,-",
        "value": 19900000
    },
    {
        "name": "19.950.000,-",
        "value": 19950000
    },
    {
        "name": "20.000.000,-",
        "value": 20000000
    },
    {
        "name": "20.050.000,-",
        "value": 20050000
    },
    {
        "name": "20.100.000,-",
        "value": 20100000
    },
    {
        "name": "20.150.000,-",
        "value": 20150000
    },
    {
        "name": "20.200.000,-",
        "value": 20200000
    },
    {
        "name": "20.250.000,-",
        "value": 20250000
    },
    {
        "name": "20.300.000,-",
        "value": 20300000
    },
    {
        "name": "20.350.000,-",
        "value": 20350000
    },
    {
        "name": "20.400.000,-",
        "value": 20400000
    },
    {
        "name": "20.450.000,-",
        "value": 20450000
    },
    {
        "name": "20.500.000,-",
        "value": 20500000
    },
    {
        "name": "20.550.000,-",
        "value": 20550000
    },
    {
        "name": "20.600.000,-",
        "value": 20600000
    },
    {
        "name": "20.650.000,-",
        "value": 20650000
    },
    {
        "name": "20.700.000,-",
        "value": 20700000
    },
    {
        "name": "20.750.000,-",
        "value": 20750000
    },
    {
        "name": "20.800.000,-",
        "value": 20800000
    },
    {
        "name": "20.850.000,-",
        "value": 20850000
    },
    {
        "name": "20.900.000,-",
        "value": 20900000
    },
    {
        "name": "20.950.000,-",
        "value": 20950000
    },
    {
        "name": "21.000.000,-",
        "value": 21000000
    },
    {
        "name": "21.050.000,-",
        "value": 21050000
    },
    {
        "name": "21.100.000,-",
        "value": 21100000
    },
    {
        "name": "21.150.000,-",
        "value": 21150000
    },
    {
        "name": "21.200.000,-",
        "value": 21200000
    },
    {
        "name": "21.250.000,-",
        "value": 21250000
    },
    {
        "name": "21.300.000,-",
        "value": 21300000
    },
    {
        "name": "21.350.000,-",
        "value": 21350000
    },
    {
        "name": "21.400.000,-",
        "value": 21400000
    },
    {
        "name": "21.450.000,-",
        "value": 21450000
    },
    {
        "name": "21.500.000,-",
        "value": 21500000
    },
    {
        "name": "21.550.000,-",
        "value": 21550000
    },
    {
        "name": "21.600.000,-",
        "value": 21600000
    },
    {
        "name": "21.650.000,-",
        "value": 21650000
    },
    {
        "name": "21.700.000,-",
        "value": 21700000
    },
    {
        "name": "21.750.000,-",
        "value": 21750000
    },
    {
        "name": "21.800.000,-",
        "value": 21800000
    },
    {
        "name": "21.850.000,-",
        "value": 21850000
    },
    {
        "name": "21.900.000,-",
        "value": 21900000
    },
    {
        "name": "21.950.000,-",
        "value": 21950000
    },
    {
        "name": "22.000.000,-",
        "value": 22000000
    },
    {
        "name": "22.050.000,-",
        "value": 22050000
    },
    {
        "name": "22.100.000,-",
        "value": 22100000
    },
    {
        "name": "22.150.000,-",
        "value": 22150000
    },
    {
        "name": "22.200.000,-",
        "value": 22200000
    },
    {
        "name": "22.250.000,-",
        "value": 22250000
    },
    {
        "name": "22.300.000,-",
        "value": 22300000
    },
    {
        "name": "22.350.000,-",
        "value": 22350000
    },
    {
        "name": "22.400.000,-",
        "value": 22400000
    },
    {
        "name": "22.450.000,-",
        "value": 22450000
    },
    {
        "name": "22.500.000,-",
        "value": 22500000
    },
    {
        "name": "22.550.000,-",
        "value": 22550000
    },
    {
        "name": "22.600.000,-",
        "value": 22600000
    },
    {
        "name": "22.650.000,-",
        "value": 22650000
    },
    {
        "name": "22.700.000,-",
        "value": 22700000
    },
    {
        "name": "22.750.000,-",
        "value": 22750000
    },
    {
        "name": "22.800.000,-",
        "value": 22800000
    },
    {
        "name": "22.850.000,-",
        "value": 22850000
    },
    {
        "name": "22.900.000,-",
        "value": 22900000
    },
    {
        "name": "22.950.000,-",
        "value": 22950000
    },
    {
        "name": "23.000.000,-",
        "value": 23000000
    },
    {
        "name": "23.050.000,-",
        "value": 23050000
    },
    {
        "name": "23.100.000,-",
        "value": 23100000
    },
    {
        "name": "23.150.000,-",
        "value": 23150000
    },
    {
        "name": "23.200.000,-",
        "value": 23200000
    },
    {
        "name": "23.250.000,-",
        "value": 23250000
    },
    {
        "name": "23.300.000,-",
        "value": 23300000
    },
    {
        "name": "23.350.000,-",
        "value": 23350000
    },
    {
        "name": "23.400.000,-",
        "value": 23400000
    },
    {
        "name": "23.450.000,-",
        "value": 23450000
    },
    {
        "name": "23.500.000,-",
        "value": 23500000
    },
    {
        "name": "23.550.000,-",
        "value": 23550000
    },
    {
        "name": "23.600.000,-",
        "value": 23600000
    },
    {
        "name": "23.650.000,-",
        "value": 23650000
    },
    {
        "name": "23.700.000,-",
        "value": 23700000
    },
    {
        "name": "23.750.000,-",
        "value": 23750000
    },
    {
        "name": "23.800.000,-",
        "value": 23800000
    },
    {
        "name": "23.850.000,-",
        "value": 23850000
    },
    {
        "name": "23.900.000,-",
        "value": 23900000
    },
    {
        "name": "23.950.000,-",
        "value": 23950000
    },
    {
        "name": "24.000.000,-",
        "value": 24000000
    },
    {
        "name": "24.050.000,-",
        "value": 24050000
    },
    {
        "name": "24.100.000,-",
        "value": 24100000
    },
    {
        "name": "24.150.000,-",
        "value": 24150000
    },
    {
        "name": "24.200.000,-",
        "value": 24200000
    },
    {
        "name": "24.250.000,-",
        "value": 24250000
    },
    {
        "name": "24.300.000,-",
        "value": 24300000
    },
    {
        "name": "24.350.000,-",
        "value": 24350000
    },
    {
        "name": "24.400.000,-",
        "value": 24400000
    },
    {
        "name": "24.450.000,-",
        "value": 24450000
    },
    {
        "name": "24.500.000,-",
        "value": 24500000
    },
    {
        "name": "24.550.000,-",
        "value": 24550000
    },
    {
        "name": "24.600.000,-",
        "value": 24600000
    },
    {
        "name": "24.650.000,-",
        "value": 24650000
    },
    {
        "name": "24.700.000,-",
        "value": 24700000
    },
    {
        "name": "24.750.000,-",
        "value": 24750000
    },
    {
        "name": "24.800.000,-",
        "value": 24800000
    },
    {
        "name": "24.850.000,-",
        "value": 24850000
    },
    {
        "name": "24.900.000,-",
        "value": 24900000
    },
    {
        "name": "24.950.000,-",
        "value": 24950000
    },
    {
        "name": "25.000.000,-",
        "value": 25000000
    }
]

const PERCENTAGE = [
    {name: '0.0', value: 0},
    {name: '0.1', value: 0.1},
    {name: '0.2', value: 0.2},
    {name: '0.3', value: 0.3},
    {name: '0.4', value: 0.4},
    {name: '0.5', value: 0.5},
    {name: '0.6', value: 0.6},
    {name: '0.7', value: 0.7},
    {name: '0.8', value: 0.8},
    {name: '0.9', value: 0.9},
    {name: '1.0', value: 1.0},
    {name: '1.1', value: 1.1},
    {name: '1.2', value: 1.2},
    {name: '1.3', value: 1.3},
    {name: '1.4', value: 1.4},
    {name: '1.5', value: 1.5},
    {name: '1.6', value: 1.6},
    {name: '1.7', value: 1.7},
    {name: '1.8', value: 1.8},
    {name: '1.9', value: 1.9},
    {name: '2.0', value: 2.0},
    {name: '2.1', value: 2.1},
    {name: '2.2', value: 2.2},
    {name: '2.3', value: 2.3},
    {name: '2.4', value: 2.4},
    {name: '2.5', value: 2.5},
    {name: '2.6', value: 2.6},
    {name: '2.7', value: 2.7},
    {name: '2.8', value: 2.8},
    {name: '2.9', value: 2.9},
    {name: '3.0', value: 3.0},
    {name: '3.1', value: 3.1},
    {name: '3.2', value: 3.2},
    {name: '3.3', value: 3.3},
    {name: '3.4', value: 3.4},
    {name: '3.5', value: 3.5}
]

let scrollRef;

function MissionCost(props) {

    const {history, inspection, navigation} = props;
    const [toast, setToast] = useState()
    const [billListPosition, setBillListPosition] = useState()
    const [selectedPackage, setSelectedPackage] = useState([]);

    const [isFixedPrice, setFixedPriceAvailable] = useState([]);
    const [isRebateVisible, setRebateVisible] = useState(false);
    const [fixedPrice, setFixedPrice] = useState("");
    const [preferablePrice, setPreferablePrice] = useState("");
    const [percentage, setPercentage] = useState("");
    const [rebate, setRebate] = useState("");
    const [service, setService] = useState("");
    const [visible, setVisible] = useState(false)
    const [serviceInPackage, setServiceInPackage] = useState([])

    let propertyId;
    if (IS_WEB) {
        propertyId = decryptId(props.match.params.id);
    }
    const [remunerationType, setRemuneration] = useState("");

    const [sendPreference, sendPreferenceMutation] = useMutation(SEND_PREFERENCE);

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES);


    const saveServiceWithDebounce = React.useCallback(debounce((variables) => {
        saveServices({
            variables: {
                input: {
                    ...variables,
                    broker_on_view: false
                }
            }
        }).then(() => {
            psQuery.refetch()
        }).catch(() => {
        })
    }, 2000), [])

    let {loading, data, error, refetch} = useQuery(PROPERTY_PREFERENCE, {
        onCompleted: (data) => {
            if (!loading && data?.property?.property_preference) {
                setPreferablePrice(data?.property?.property_preference?.preferable_price)
                setPercentage(data?.property?.property_preference?.percentage)
                setFixedPriceAvailable([])
                if (data?.property?.property_preference?.fix_price) {
                    setFixedPrice(data?.property?.property_preference?.fix_price)
                    setFixedPriceAvailable([1])
                }
            }
        },
        fetchPolicy: 'network-only',
        variables: {
            id: inspection?.property_id ?? propertyId
        }
    })

    useEffect(() => {
console.log(data?.property?.property_preference,"PREF")
        if (!loading && data?.property?.property_preference) {
            setPreferablePrice(parseInt(data?.property?.property_preference?.preferable_price, 10))
            setPercentage(data?.property?.property_preference?.percentage)
            setFixedPriceAvailable([])
            if (data?.property?.property_preference?.fix_price) {
                setFixedPrice(parseInt(data?.property?.property_preference?.fix_price))
                setFixedPriceAvailable([1])
            }
            if (data?.property?.property_preference?.rebate_amount) {
                setRebateVisible(true)
                setRebate(data?.property?.property_preference?.rebate_amount)
            }

        }
    }, [data])

    const psQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.PhotoStyling,
            propertyId: inspection?.property_id ?? propertyId
        }
    });


    const dmQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.DigitalMarketing,
            propertyId: inspection?.property_id ?? propertyId
        }
    });

    const pmQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.PrintMarketing,
            propertyId: inspection?.property_id ?? propertyId
        }
    });

    useEffect(() => {
        let remuneration = psQuery.data?.selected_property_services?.remunerations.filter(item => item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? propertyId))
        let packages = psQuery.data?.selected_property_services?.marketing_package.filter(item => item.property_package && item.property_package?.property_id === parseInt(inspection?.property_id ?? propertyId, 10)).map(filteredItem => filteredItem?.id)
        if (remuneration && remuneration.length) {
            setRemuneration(remuneration[0].id)
        }
        if (packages?.length) {
            setSelectedPackage(packages)
        } else {
            setSelectedPackage([])
        }
    }, [psQuery.data])

    useEffect(() => {
        let selectedPackage = psQuery?.data?.selected_property_services?.marketing_package ? psQuery?.data?.selected_property_services?.marketing_package.filter(item => item.property_package) : []
        let service = []
        selectedPackage.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [psQuery.data]);

    const renderRemuneration = (item, index, self) => {
        return (
            <AddServiceView desc={item.description}
                            key={index.toString()}
                            onPress={() => {
                                setRemuneration(item.id)
                                saveServices({
                                    variables: {
                                        input: {
                                            property_id: inspection?.property_id ?? propertyId,
                                            remuneration_id: item.id,
                                            broker_on_view: false
                                        }
                                    }
                                }).then(() => {
                                    pmQuery.refetch()
                                }).catch(() => {
                                })
                            }}

                            isHtml
                            mt={15}
                            image={item.image}
                            isSelected={item.id === remunerationType}
                            title={item.name}
                            name={'disclaimer'}
                            price={`Kr ${formatPrice(item.usableServiceClass ? (parseInt(item.usableServiceClass.price) * (item?.usableServiceClass?.quantity ? parseInt(item?.usableServiceClass?.quantity, 10) : 1)) : (parseInt(item.price) * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`}/>
        )
    };

    const renderPackageBillItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    saveServices({
                        variables: {
                            input: {
                                property_id: inspection?.property_id ?? propertyId,
                                unselect_marketing_package: [item?.property_package?.id],
                                broker_on_view: false
                            }
                        }
                    }).then(() => {
                        psQuery.refetch()
                    }).catch(() => {
                    })
                }}
                                       isDeletable={!item?.office_service}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}
                                       desc={item.description}
                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.property_package ? item.property_package.price : item.price)},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    const renderBillItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    if (!item.is_default) {
                        saveServices({
                            variables: {
                                input: {
                                    property_id: inspection?.property_id ?? propertyId,
                                    service_ids: [],
                                    unselect_pro_service_ids: item.service_selected ? [item.service_selected.id] : item?.property_package ? [item?.property_package?.id] : [item.usableServiceClass.id],
                                    broker_on_view: false
                                }
                            }
                        }).then(() => {
                            pmQuery.refetch()
                            psQuery.refetch()
                            dmQuery.refetch()
                        }).catch(() => {
                        })
                    }

                }}
                                       isDeletable={(item.is_default === 0 || !!item?.service_selected)}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}

                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.service_selected ? item.service_selected.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    const renderUttleggItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    if (!item.is_default) {
                        saveServices({
                            variables: {
                                input: {
                                    property_id: inspection?.property_id ?? propertyId,
                                    service_ids: [],
                                    unselect_utlegg_service_ids: [item.usableServiceClass.id],
                                    broker_on_view: false
                                }
                            }
                        }).then(() => {
                            psQuery.refetch()
                        }).catch(() => {
                        })
                    }

                }}
                                       isDeletable={(item.is_default === 0 || !!item?.service_selected)}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}

                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.service_selected ? item.service_selected.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    const renderPhotoBillItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    if (!item.is_default) {
                        saveServices({
                            variables: {
                                input: {
                                    property_id: inspection?.property_id ?? propertyId,
                                    service_ids: [],
                                    unselect_vendor_service_ids:  [item.service_selected?.id],
                                    broker_on_view: false
                                }
                            }
                        }).then(() => {
                            psQuery.refetch()
                        }).catch(() => {
                        })
                    }

                }}
                                       isDeletable={(item.is_default === 0 || !!item?.service_selected)}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}

                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.service_selected ? item.service_selected.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    const renderRemunerationBillItem = item => {

        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    saveServices({
                        variables: {
                            input: {
                                property_id: inspection?.property_id ?? propertyId,
                                service_ids: [],
                                unselect_pro_service_ids: item.service_selected ? [item.service_selected.id] : item?.property_package ? [item?.property_package?.id] : [item.usableServiceClass.id],
                                broker_on_view: false
                            }
                        }
                    }).then(() => {
                        pmQuery.refetch()
                        psQuery.refetch()
                        dmQuery.refetch()
                    }).catch(() => {
                    })
                }}
                                       isDeletable={(item.is_default === 0 || !!item?.service_selected)}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}

                                       iconColor={Color.DARK_GRAY}
                                       price={`Kr ${formatPrice(item.usableServiceClass ? (parseInt(item.usableServiceClass.price) * (item?.usableServiceClass?.quantity ? parseInt(item?.usableServiceClass?.quantity, 10) : 1)) : (parseInt(item.price) * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`}
                />
            </View>
        );
    }

    const renderPurchasedBillList = (service, index) => {
        let allServices = service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id));
        return (
            <View>
                {allServices?.map(renderBillItem)}
            </View>

        )
    };

    const renderPhotoPurchasedBillList = (service, index) => {
        if (index === 0) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[0];
            console.log(vendorServices,"VENDOR SERVICES")
            if (vendorServices?.vendor_services?.length) {
                return vendorServices?.vendor_services?.filter(item=>item?.service_selected)?.map(renderPhotoBillItem);
            }
        }
        if (index === 1) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[1];
            if (vendorServices?.vendor_services?.length) {
                return vendorServices?.vendor_services?.filter(item=>item?.service_selected)?.map(renderPhotoBillItem);
            }
        }
        if (index === 3) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[2];
            if (vendorServices?.vendor_services?.length) {
                return vendorServices?.vendor_services?.filter(item=>item?.service_selected)?.map(renderPhotoBillItem);
            }
        }
        if (index === 4) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[3];
            if (vendorServices?.vendor_services?.length) {
                return vendorServices?.vendor_services?.filter(item=>item?.service_selected)?.map(renderPhotoBillItem);
            }
        }
        let allServices = service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id));
        return (
            <View>
                {allServices?.map(renderBillItem)}
            </View>

        )
    };

    const renderBillSection = (name, data) => {
        let isRenderTitle = false
        data?.map(service => {
            if (service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id))?.length) {
                isRenderTitle = true
            }
        })
        if (!isRenderTitle) {
            return;
        }
        return (
            <>
                <Label font_medium
                       ml={ThemeUtils.isTablet() ? 28 : 14}
                       mt={10}
                       mb={5}
                       color={Color.TERNARY_TEXT_COLOR}>
                    {name}
                </Label>
                {data.map(renderPurchasedBillList)}
            </>
        )
    }

    const renderPhotoBillSection = (name, data) => {

        let isRenderTitle = false
        let vendorServices = {}
        data?.map((service, index) => {
            vendorServices = {}
            if (index === 0) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[0];
            }
            if (index === 1) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[1];
            }
            if (index === 3) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[2];
            }
            if (index === 4) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[3];
            }
            if (vendorServices?.vendor_services?.length) {
                if(vendorServices?.vendor_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).length){
                    isRenderTitle = true;
                }
            } else if (service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id))?.length) {
                isRenderTitle = true
            }
        });
        if (!isRenderTitle) {
            return;
        }
        return (
            <>
                <Label font_medium
                       ml={ThemeUtils.isTablet() ? 28 : 14}
                       mt={10}
                       mb={5}
                       color={Color.TERNARY_TEXT_COLOR}>
                    {name}
                </Label>
                {data.map(renderPhotoPurchasedBillList)}
            </>
        )
    }

    const renderPackages = (item, index, self) => {
        let property_id = inspection?.property_id ?? propertyId

        let isSelected = selectedPackage.includes(item.id)
        return (
            <AddServiceView key={index.toString()}
                            loading={saveServicesMutation.loading && service === item.id}
                            service={item?.marketing_package_service?.map(item => item.office_service)}
                            onPress={() => {
                                // addOrRemoveItemFromArray(item)
                                if (!loading) {
                                    let previousSelected = self?.filter(item => item.property_package && item.property_package?.property_id === parseInt(property_id, 10)).map(filteredItem => filteredItem?.property_package?.id)

                                    let variables = {
                                        property_id: inspection?.property_id ?? propertyId,
                                        marketing_package: [item.id],
                                    }
                                    if (!previousSelected.includes(item?.property_package?.id)) {
                                        variables = {
                                            ...variables,
                                            unselect_marketing_package: previousSelected
                                        }
                                    }
                                    if (!selectedPackage.includes(item.id)) {
                                        saveServiceWithDebounce(variables)
                                        setSelectedPackage([item.id])
                                    } else if (item.property_package?.id) {
                                        variables = {
                                            property_id: inspection?.property_id ?? propertyId,
                                            unselect_marketing_package: [item.property_package?.id]
                                        }
                                        saveServiceWithDebounce(variables)
                                        setSelectedPackage([])
                                    }
                                }
                            }}
                            mb={15}
                            image={item?.image_url}
                            title={item.name}
                            price={item?.is_free ? item?.free_text : `Kr ${formatPrice(item.property_package ? item.property_package.price : item.price)},-`}
                            desc={item.description}
                            isSelected={isSelected}
                            name={'disclaimer'}/>
        )
    };

    const renderServiceDetails = (item, index, self) => {
        let property_id = inspection?.property_id ?? propertyId

        let isSelected = (item.service_selected && item.service_selected.property_id === parseInt(property_id, 10)) || serviceInPackage.includes(item.id)

        return (
            <AddServiceView key={index.toString()}
                            loading={saveServicesMutation.loading && service === item.id}
                            onPress={() => {
                                // const data = self.filter(item => item.service_selected && item.service_selected.property_id === inspection?.property_id).map(item => item.service_selected.id)
                                if (!serviceInPackage.includes(item.id)) {
                                    setService(item.id)
                                    if (!item.service_selected) {
                                        saveServices({
                                            variables: {
                                                input: {
                                                    property_id: inspection?.property_id ?? propertyId,
                                                    service_ids: [item.id],
                                                    broker_on_view: false
                                                }
                                            }
                                        }).then(() => {
                                            pmQuery.refetch()
                                            psQuery.refetch()
                                            dmQuery.refetch()
                                        }).catch(() => {
                                        })
                                    } else {
                                        saveServices({
                                            variables: {
                                                input: {
                                                    property_id: inspection?.property_id ?? propertyId,
                                                    unselect_pro_service_ids: [item.service_selected.id],
                                                    broker_on_view: false
                                                }
                                            }
                                        }).then(() => {
                                            pmQuery.refetch()
                                            psQuery.refetch()
                                            dmQuery.refetch()
                                        }).catch(() => {
                                        })
                                    }
                                }
                            }}
                            mb={15}
                            image={item.image}
                            title={item.name}
                            price={item?.is_free ? item?.free_text : `Kr ${formatPrice(item.service_selected ? item.service_selected.price : item.price)},-`}
                            desc={item.description}
                            isSelected={isSelected}
                            name={'disclaimer'}/>
        )
    };

    const renderService = (item, index) => {
        if (!item?.purchased_office_template?.purchased_office_services?.length) {
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       small
                       mt={10}
                       mb={10}>
                    {`${item.name} :`}
                </Label>
                {item?.purchased_office_template?.purchased_office_services?.map(renderServiceDetails)}
            </View>
        )
    };

    const renderPhotoService = (item, index) => {
        if (index === 0) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[0];
            if (vendorServices?.vendor_services?.length) {
                return renderVendorService(vendorServices, index);
            }
        }
        if (index === 1) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[1];
            if (vendorServices?.vendor_services?.length) {
                return renderVendorService(vendorServices, index);
            }
        }
        if (index === 3) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[2];
            if (vendorServices?.vendor_services?.length) {
                return renderVendorService(vendorServices, index);
            }
        }
        if (index === 4) {
            let vendorServices = psQuery.data?.selected_property_services?.service_types[3];
            if (vendorServices?.vendor_services?.length) {
                return renderVendorService(vendorServices, index);
            }
        }
        if (!item?.purchased_office_template?.purchased_office_services?.length) {
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       small
                       mt={10}
                       mb={10}>
                    {`${item.name} :`}
                </Label>
                {item?.purchased_office_template?.purchased_office_services?.map(renderServiceDetails)}
            </View>
        )
    };

    const renderVendorServiceDetails = (item, index, self) => {
        let property_id = inspection?.property_id ?? propertyId

        let isSelected = (item.service_selected && item.service_selected.property_id === parseInt(property_id, 10))

        return (
            <AddServiceView key={index.toString()}
                            loading={saveServicesMutation.loading && service === item.id}
                            onPress={() => {
                                // const data = self.filter(item => item.service_selected && item.service_selected.property_id === inspection?.property_id).map(item => item.service_selected.id)
                                setService(item.id)
                                if (!item.service_selected) {
                                    saveServices({
                                        variables: {
                                            input: {
                                                property_id: inspection?.property_id ?? propertyId,
                                                vendor_service_ids: [item.id],
                                                broker_on_view: false
                                            }
                                        }
                                    }).then(() => {
                                        psQuery.refetch()
                                    }).catch(() => {
                                    })
                                } else {
                                    saveServices({
                                        variables: {
                                            input: {
                                                property_id: inspection?.property_id ?? propertyId,
                                                unselect_vendor_service_ids: [item.service_selected.id],
                                                broker_on_view: false
                                            }
                                        }
                                    }).then(() => {
                                        psQuery.refetch()
                                    }).catch(() => {
                                    })
                                }

                            }}
                            mb={15}
                            image={item.image}
                            title={item.name}
                            price={item?.is_free ? item?.free_text : `Kr ${formatPrice(item.service_selected ? item.service_selected.price : item.price)},-`}
                            desc={item.description}
                            isSelected={isSelected}
                            name={'disclaimer'}/>
        )
    };

    const renderVendorService = (item, index) => {
        if (!item?.vendor_services?.length) {
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       small
                       mt={10}
                       mb={10}>
                    {`${item.name} :`}
                </Label>
                {item?.vendor_services?.map(renderVendorServiceDetails)}
            </View>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator scrollEnabled={!ThemeUtils.isTablet()}
                                  tabs={[{
                                      text: `${Strings.PHOTO}/${Strings.STYLING}`
                                  }, {
                                      text: Strings.digitalMarketing
                                  }, {
                                      text: Strings.printMarketing
                                  }]}/>;
    };

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }


    /*------------------For total only-----------------------*/
    let allPurchasedServices = [];
    let purchasedUttlegg = [];
    let purchasedVederlegg = [];
    let purchasedPackages = [];
    let remunration = [];
    if (pmQuery.data && psQuery.data && dmQuery.data) {

        psQuery?.data?.selected_property_services?.services?.map((service, index) => {
            let vendorServices = []
            if (index === 0) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[0];
            }
            if (index === 1) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[1];
            }
            if (index === 3) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[2];
            }
            if (index === 4) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[3];
            }
            if (vendorServices?.vendor_services?.length) {
                vendorServices?.vendor_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId)))).map(item => {
                    allPurchasedServices.push(item)
                });
                return;
            }
            service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                allPurchasedServices.push(item)
            })
        });
        pmQuery?.data?.selected_property_services?.services?.map(service => {
            service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                allPurchasedServices.push(item)
            })
        })
        dmQuery?.data?.selected_property_services?.services?.map(service => {
            service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                allPurchasedServices.push(item)
            })
        })

        purchasedUttlegg = psQuery?.data?.selected_property_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.UTLEGG && item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId, 10))).sort((a, b) => b.is_default - a.is_default);
        purchasedVederlegg = psQuery?.data?.selected_property_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.VEDERLEGG && item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId, 10))).sort((a, b) => b.is_default - a.is_default);

        purchasedPackages = psQuery?.data?.selected_property_services?.marketing_package?.filter(item => item.property_package && item.property_package.property_id === (inspection?.property_id ?? parseInt(propertyId, 10)));
        remunration = psQuery?.data?.selected_property_services?.remunerations?.filter(item => item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId)))
        allPurchasedServices = [
            ...allPurchasedServices,
            ...purchasedUttlegg,
            ...purchasedVederlegg,
            ...purchasedPackages
        ];
    }
    let total = allPurchasedServices
        .map((item) => parseInt((item.service_selected ? item.service_selected.price : item?.property_package ? item?.property_package.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))))
        .reduce((a, b) => a + b, 0);

    total += remunration.map(item => item.usableServiceClass ? item.usableServiceClass.price * (item?.usableServiceClass?.quantity ?? 1) : item.price * (item?.hours ?? 1)).reduce((a, b) => a + b, 0);
    if (data?.property?.property_preference?.rebate_amount) {
        total -= parseInt(data?.property?.property_preference?.rebate_amount)
    }
    /*------------------For total only-----------------------*/
    return (
        <View style={styles.container}>
            {renderNoteModal()}

            <Toast ref={(r) => setToast(r)}
                   opacity={1}/>
            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            <ScrollView contentContainerStyle={{flexGrow: 1}}
                        ref={r => scrollRef = r}>
                <View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={[CommonStyle.loader, {marginBottom: 20}]}/>
                    ) : (
                        <View style={styles.contentCont}>
                            {/*<DropDown onSelectOptions={(value) => setPreferablePrice(value)}
                                      title={Strings.yourPreferablePrice}
                                      options={PRICE}>*/}
                            {console.log(preferablePrice,"PRICE")}
                            <InputField errorEnabled={false}
                                        type={'number'}
                                        mt={ThemeUtils.relativeRealHeight(3)}
                                        labelText={Strings.yourPreferablePrice}
                                        placeholder={Strings.yourPreferablePrice}
                                        value={preferablePrice?.toString()}
                                        onChange={setPreferablePrice}>

                                <View style={CommonStyle.inputExtra}>
                                    <Label font_medium>
                                        {'Kr.'}
                                    </Label>
                                </View>
                            </InputField>
                            {/*</DropDown>*/}

                            <DropDown disabled={isFixedPrice.includes(1)}
                                      title={Strings.setPercentage}
                                      onSelectOptions={(value) => setPercentage(value)}
                                      options={PERCENTAGE}>
                                <InputField type={'number'}
                                            disabled={isFixedPrice.includes(1)}
                                            placeholder={Strings.setPercentage}
                                            value={percentage?.toString()}
                                            onChange={setPercentage}
                                            extra={<View style={[CommonStyle.inputExtra, {width: 40}]}>
                                                <Label font_medium>
                                                    {'%'}
                                                </Label>
                                            </View>}/>
                            </DropDown>
                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => setFixedPriceAvailable(isFixedPrice.includes(1) ? [] : [1])}
                                              style={{flexDirection: 'row', alignItems: "center"}}>
                                <Icon name={isFixedPrice.includes(1) ? 'tickmark' : 'radio-normal'}
                                      size={ThemeUtils.fontNormal}
                                      color={isFixedPrice.includes(1) ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                                <Label small
                                       ml={15}
                                       font_medium>
                                    {Strings.fixedPrice}
                                </Label>
                            </TouchableOpacity>
                            <InputField type={'number'}
                                        placeholder={Strings.fixedPrice}
                                        errorEnabled={false}
                                        value={fixedPrice}
                                        onChange={setFixedPrice}
                                        mt={10}
                                        disabled={!isFixedPrice.includes(1)}
                                        labelProps={{
                                            small: true,
                                            font_medium: true
                                        }}/>


                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => setRebateVisible(prevVal => !prevVal)}
                                              style={styles.rebateLblCont}>

                                <View style={CommonStyle.flex}>
                                    <Hr/>
                                </View>
                                <Icon name={'drop-down'}
                                      size={ThemeUtils.fontNormal}
                                      style={[styles.chk, {
                                          transform: [{rotate: isRebateVisible ? '180deg' : '0deg'}]
                                      }]}
                                      color={isRebateVisible ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            </TouchableOpacity>

                            {isRebateVisible ? (
                                <InputField errorEnabled={false}
                                            labelText={Strings.rebate}
                                            type={'number'}
                                            placeholder={Strings.rebate}
                                            value={rebate}
                                            mb={ThemeUtils.relativeRealHeight(2)}
                                            onChange={setRebate}>

                                    <View style={CommonStyle.inputExtra}>
                                        <Label font_medium>
                                            {'Kr.'}
                                        </Label>
                                    </View>
                                </InputField>) : null}

                            <View style={[ThemeUtils.isTablet() ? styles.btnCont : null]}>
                                <CustomButton title={Strings.save}
                                              maxWidth={220}
                                              style={ThemeUtils.isTablet() ? {minWidth: ThemeUtils.relativeRealWidth(10)} : 0}
                                              loading={sendPreferenceMutation.loading}
                                              mb={!ThemeUtils.isTablet() ? 20 : 0}
                                              onPress={() => {
                                                  let isError = false
                                                  let variables = {
                                                      input: {
                                                          property_id: inspection?.property_id ?? propertyId,
                                                          preferable_price: parseInt(preferablePrice, 10),

                                                      }
                                                  }
                                                  if (!preferablePrice) {
                                                      isError = true
                                                      showMessage("Vennligst skriv inn prisantydning", Constants.MessageType.FAILED);
                                                  }
                                                  if (isFixedPrice.includes(1)) {
                                                      if (fixedPrice) {
                                                          variables.input.fix_price = parseInt(fixedPrice, 10);
                                                      } else {
                                                          isError = true
                                                          showMessage("Vennligst skriv inn fastpris", Constants.MessageType.FAILED);
                                                      }

                                                  } else {
                                                      if (percentage) {
                                                          variables.input.percentage = percentage;
                                                      } else {
                                                          isError = true
                                                          showMessage("Vennligst skriv inn prosent", Constants.MessageType.FAILED);
                                                      }
                                                  }
                                                  if (rebate) {
                                                      variables.input.rebate_amount = parseInt(rebate, 10)
                                                  }
                                                  if (!isError) {
                                                      sendPreference({
                                                          variables: variables
                                                      }).then(() => {
                                                          psQuery.refetch();
                                                          refetch && refetch()
                                                      }).catch((e) => {
                                                          showMessage(e?.graphQLErrors[0]?.message, Constants.MessageType.FAILED);
                                                      })
                                                  }
                                              }}/>
                                {/*<CustomButton title={Strings.reset}
                                              maxWidth={220}
                                              style={ThemeUtils.isTablet() ? {minWidth: ThemeUtils.relativeRealWidth(10)} : 0}
                                              ml={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(2) : 0}
                                              onPress={() => {
                                                  setFixedPrice("")
                                                  setPreferablePrice("")
                                                  setPercentage("")
                                                  setFixedPriceAvailable([])
                                              }}
                                              bgColor={Color.WHITE}
                                              textColor={Color.PRIMARY_TEXT_COLOR}
                                              borderColor={Color.PRIMARY_TEXT_COLOR}
                                              borderWidth={1}
                                />*/}

                            </View>
                        </View>
                    )}

                    <Hr marginTop={20}/>
                    <View style={styles.contentCont}>
                        <Label large
                               font_medium
                               mt={ThemeUtils.relativeRealHeight(2)}>
                            {Strings.chooseTypeOfRemuneration}
                        </Label>
                        {psQuery.loading && !psQuery.data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : psQuery.data?.selected_property_services?.remunerations.map(renderRemuneration)}

                    </View>
                    {billListPosition ? (
                        <View style={[styles.contentCont, ThemeUtils.isTablet() ? styles.btnCont : null]}>
                            <CustomButton title={"Oversikt"}
                                          mt={ThemeUtils.relativeRealHeight(2)}
                                          maxWidth={220}
                                          style={ThemeUtils.isTablet() ? {minWidth: ThemeUtils.relativeRealWidth(10)} : 0}
                                          onPress={() => scrollRef && scrollRef.scrollTo({
                                              ...billListPosition,
                                              animated: true
                                          })}/>
                        </View>) : null}
                    {/*----------------------Marketing Packages---------------------------------*/}
                    <View style={styles.contentCont}>
                        <Label large
                               font_medium
                               mt={ThemeUtils.relativeRealHeight(2)}
                               mb={ThemeUtils.relativeRealHeight(1)}>
                            {Strings.marketingPackages}
                        </Label>
                        {psQuery.loading && !psQuery.data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : psQuery.data?.selected_property_services?.marketing_package.map(renderPackages)}

                    </View>
                    {/*----------------------Marketing Packages---------------------------------*/}
                    <Label large
                           font_medium
                           ml={ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mt={ThemeUtils.relativeRealHeight(2)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {`${Strings.PHOTO}/${Strings.STYLING}`}
                    </Label>
                    {psQuery.loading && !psQuery.data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={styles.serviceCont}>
                            {psQuery.data?.selected_property_services?.services?.map(renderPhotoService)}
                        </View>)}
                    <Label large
                           font_medium
                           ml={ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mt={ThemeUtils.relativeRealHeight(2)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {Strings.digitalMarketing}
                    </Label>
                    {dmQuery.loading && !dmQuery.data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={styles.serviceCont}>
                            {dmQuery.data?.selected_property_services?.services?.map(renderService)}
                        </View>)}
                    <Label large
                           font_medium
                           ml={ThemeUtils.isTablet() ? ThemeUtils.COMMON_HORIZONTAL_MARGIN * 2 : ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mt={ThemeUtils.relativeRealHeight(2)}
                           mb={ThemeUtils.relativeRealHeight(1)}>
                        {Strings.printMarketing}
                    </Label>
                    {pmQuery.loading && !pmQuery.data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={styles.serviceCont}>
                            {pmQuery.data?.selected_property_services?.services?.map(renderService)}
                        </View>)}


                    <View onLayout={r => setBillListPosition({x: r.nativeEvent.layout.x, y: r.nativeEvent.layout.y})}>

                        <View style={styles.billCont}>
                            {psQuery?.data?.selected_property_services?.remunerations?.filter(item => item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId)))?.length ? (
                                <Label font_medium
                                       ml={ThemeUtils.isTablet() ? 28 : 14}
                                       mt={10}
                                       mb={5}
                                       color={Color.TERNARY_TEXT_COLOR}>
                                    {Strings.remuneration}
                                </Label>) : null}
                            {psQuery?.data?.selected_property_services?.remunerations?.filter(item => item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId))).map(renderRemunerationBillItem)}
                            {purchasedPackages?.length ? (
                                <Label font_medium
                                       ml={ThemeUtils.isTablet() ? 28 : 14}
                                       mt={10}
                                       mb={5}
                                       color={Color.TERNARY_TEXT_COLOR}>
                                    {Strings.marketingPackages}
                                </Label>) : null}
                            {purchasedPackages?.map(renderPackageBillItem)}
                            {renderPhotoBillSection(`${Strings.PHOTO}/${Strings.STYLING}`, psQuery?.data?.selected_property_services?.services)}
                            {renderBillSection(Strings.digitalMarketing, dmQuery?.data?.selected_property_services?.services)}
                            {renderBillSection(Strings.printMarketing, pmQuery?.data?.selected_property_services?.services)}
                            {purchasedUttlegg?.length || purchasedVederlegg?.length ?
                                (<Label font_medium
                                        ml={ThemeUtils.isTablet() ? 28 : 14}
                                        mt={10}
                                        mb={10}
                                        color={Color.TERNARY_TEXT_COLOR}>
                                    {"Andre kostnader"}
                                </Label>) : null}

                            {purchasedVederlegg?.length ?
                                (<Label font_medium
                                        ml={ThemeUtils.isTablet() ? 28 : 14}
                                        mt={10}
                                        mb={5}
                                        color={Color.TERNARY_TEXT_COLOR}>
                                    {Strings.vederlag}
                                </Label>) : null}
                            {purchasedVederlegg?.map(renderUttleggItem)}

                            {purchasedUttlegg?.length ?
                                (<Label font_medium
                                        ml={ThemeUtils.isTablet() ? 28 : 14}
                                        mt={10}
                                        mb={5}
                                        color={Color.TERNARY_TEXT_COLOR}>
                                    {Strings.utlegg}
                                </Label>) : null}
                            {purchasedUttlegg?.map(renderUttleggItem)}

                            {data?.property?.property_preference?.rebate_amount ? (
                                <View style={styles.billListCont}>
                                    <CostingAddServiceView isDeletable={false}
                                                           title={"Avslag på totalprisen"}
                                                           color={Color.TERNARY_TEXT_COLOR}
                                                           iconColor={Color.DARK_GRAY}
                                                           price={`Kr ${formatPrice(data?.property?.property_preference?.rebate_amount)},-`}/>

                                </View>
                            ) : null}
                            <Hr lineStyle={styles.lineStyle}/>
                            <CostingAddServiceView title={Strings.totalCosting}
                                                   isTotalAmount
                                                   mt={15}
                                                   mb={5}
                                                   color={Color.WHITE}
                                                   price={`Kr ${formatPrice(total)},-`}
                            />
                        </View>

                        <View style={[styles.bottomButtonContainer, ThemeUtils.isTablet() ? styles.btnCont : null]}>

                            <CustomButton title={Strings.gotoAssignment}

                                // disabled={!(sendPreference.data || data?.property?.property_preference)}
                                          onPress={() => {
                                              if (remunerationType) {
                                                  IS_WEB ? history.push(Routes.Questions) : navigation.navigate(Routes.Questions)
                                              } else {
                                                  showMessage(Strings.chooseTypeOfRemuneration, Constants.MessageType.FAILED)
                                              }
                                          }}
                                          mb={ThemeUtils.isTablet() ? 0 : 20}
                                          maxWidth={220}
                                          style={ThemeUtils.isTablet() ? {minWidth: ThemeUtils.relativeRealWidth(30)} : null}

                            />
                            <CustomButton title={Strings.saveOffer}
                                          onPress={() => {
                                              IS_WEB ? history.replace(`/${Routes.InspectionApplication}`) : navigation.pop(4)
                                          }}
                                          ml={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(2) : 0}
                                          style={ThemeUtils.isTablet() ? {minWidth: ThemeUtils.relativeRealWidth(30)} : null}
                                          bgColor={Color.WHITE}
                                          maxWidth={220}
                                          textColor={Color.PRIMARY}
                                          borderWidth={1}/>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>

    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MissionCost);
