import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: 'center',
    },


});

export {styles};
