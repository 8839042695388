import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {
    CustomButton,
    Header,
    IndicatorViewPager,
    InputField,
    Label,
    PagerTabIndicator
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    Constants, decryptId,
    IS_WEB,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PERSONAL_TEMPLATE_DETAILS} from "components/src/api/query";
import {SAVE_PERSONAL_TEMPLATE_SERVICE} from "components/src/api/mutation";
import {connect} from "react-redux";
import Gallery from '../Finn'

const scrollY = new Animated.Value(0);

const FinnAd = props => {

    const {history, user, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.PhotoStyling) : navigation.goBack();
    }

    const [link, setLink] = useState("");
    const [errLink, setErrLink] = useState(null);

    const [saveServiceDetails, saveDetailsMutation] = useMutation(SAVE_PERSONAL_TEMPLATE_SERVICE);

    const {loading, data, error} = useQuery(PERSONAL_TEMPLATE_DETAILS, {
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
            status: 1,
            agentId: user?.id
        }
    });

    useEffect(() => {
        if (data?.personal_template_details) {
            let linkData=data?.personal_template_details?.find(item => item?.media_type === "iframe")
            linkData && setLink(linkData?.media_url)
        }
    }, [data]);



    const validate = () => {

        let error = validation('link', link)
        if (error) {
            setErrLink(error)
        } else {
            saveServiceDetails({
                variables: {
                    type: "IFRAME",
                    templateId: serviceFromPage?.office_template?.id,
                    iFrame: link,
                }
            }).then((data) => {
                showMessage(data?.data?.personal_update_office_template?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        }
    };


    const renderDroneDetails = () => {
        return (
            <>
                <InputField labelText={Strings.insertIframeLink}
                            placeholder={Strings.insertIframeLink}
                            error={errLink}
                            value={link}
                            type={'text'}
                            onChange={setLink}/>

            </>
        );
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={[
            {
                text: `${serviceFromPage?.name} ${Strings.details}`
            }, {
                text: Strings.imageGallery
            }]}/>;
    };


    return (
        <View style={CommonStyle.flex}>

            <Header animatedTitle={serviceFromPage?.name}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedValue={scrollY}
                    navigation={IS_WEB ? history : navigation}/>

            <Label
                mt={ThemeUtils.relativeRealHeight(6)}
                small
                align={'left'}>
                {/*{serviceFromPage?.office_template?.description}*/}
            </Label>

            <IndicatorViewPager
                indicator={_renderTabIndicator()}
                style={{flex: 1}}>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}>
                        <View style={styles.container}>
                            {loading && !data ? (
                                <ActivityIndicator color={Color.PRIMARY}
                                                   style={CommonStyle.loader}/>
                            ) : (
                                <View style={styles.orderCont}>
                                    {renderDroneDetails()}
                                </View>)}
                        </View>
                    </Animated.ScrollView>
                    <View style={styles.bottomButtonContainer}>
                        <CustomButton loading={saveDetailsMutation.loading}
                                      style={{flex: 0.45}}
                                      title={Strings.save}
                                      onPress={validate}/>
                        <CustomButton bgColor={Color.SECONDARY}
                                      style={{flex: 0.45}}
                                      title={Strings.cancel}
                                      onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}/>
                    </View>
                </View>
                <View>
                    <Animated.ScrollView>
                        <Gallery {...props}/>
                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>

        </View>
    )

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(FinnAd);
