import React from 'react';
import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
    },
    scrollCont: {
        paddingTop: ThemeUtils.relativeRealHeight(1.5),
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN

    },
    stepCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    steps: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Color.PRIMARY,
        paddingHorizontal: 18,
        paddingVertical: 9,
        marginVertical: 5,
        marginStart: 0,
        marginEnd: 10
    }
})
