import { StyleSheet } from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },
    titleCont: {
        // marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },


    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    checkbox:{alignItems:'flex-start',flex:1,flexDirection:'column',marginTop:15,alignContent:'center',paddingTop:12,},

    energyradio:{
        marginBottom:15,
        fontSize:12,
        alignContent:'center',

    },
    fieldView:
    {

         flexDirection: 'row',
          justifyContent: 'flex-start',
    alignItems: 'center'
},
uploadView:
    {
        flexDirection:'row',
        width:'125px',
        height:'48px',
        alignItems:'center',
        borderWidth:2,
        borderColor:Color.INPUT_BORDER,
        justifyContent:'center',
        alignContent:'center',
        marginBottom:15,
        borderRadius:6
    },

});
export { styles };
