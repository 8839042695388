import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {
    AddServiceView,
    CostingAddServiceView,
    CustomButton,
    Header,
    Hr,
    Label,
    PagerTabIndicator
} from "components/src/components";
//Network
import {useQuery} from "@apollo/react-hooks";
//Utils
import styles from './styles';

import {Constants,Color, CommonStyle, decryptId, formatPrice, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {
    BEFARING_PACKAGES,
    NEW_OTHER_SERVICES,
    PROPERTY_PREFERENCE,
    PURCHASED_SERVICES
} from "../../../../../../api/query";
import {connect} from "react-redux";


const TABS = [{
    text: Strings.purchasedService
}, {
    text: Strings.wFlexAdditionalService
}];


function MyOrders(props) {

    const {history, navigation} = props;

    const scrollY = new Animated.Value(0)

    let propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const [selectedServices, setSelectedServices] = useState([])

    const [purchasedServices, setPurchasedServices] = useState([])
    const [additionalServices, setAdditionalServices] = useState([])

    const [purchasedPackages, setPurchasedPackages] = useState([])
    const [additionalPackages, setAdditionalPackages] = useState([])
    const [serviceInPackage, setServiceInPackage] = useState([])

    const [purchasedVederlegg, setPurchasedVederlag] = useState([])
    const [purchasedUttlegg, setPurchasedUttlegg] = useState([])
    const [remunration, setRemunration] = useState([])

    let propertyPreferenceQuery = useQuery(PROPERTY_PREFERENCE, {
        fetchPolicy: 'network-only',
        variables: {
            id: propertyId
        }
    })

    const {loading, data, error} = useQuery(NEW_OTHER_SERVICES, {
        skip: true,
        fetchPolicy: 'network-only',
        variables: {
            propertyId: propertyId
        }
    });

    const packagesQuery = useQuery(BEFARING_PACKAGES, {
        variables: {
            propertyId: propertyId,
        }
    })

    const purchaseServicesQuery = useQuery(PURCHASED_SERVICES, {
        skip: !packagesQuery.data,
        variables: {
            status: 1,
            property_id: propertyId,
        }
    });


    useEffect(() => {
        if (purchaseServicesQuery.data?.purchased_services) {

            let allPurchasedServices = [];
            let additioanlSServices = [];
            let purchasedUttlegg = purchaseServicesQuery?.data?.purchased_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.UTLEGG && item.usableServiceClass).sort((a, b) => b.is_default - a.is_default);
            let purchasedVederlegg = purchaseServicesQuery?.data?.purchased_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.VEDERLEGG && item.usableServiceClass).sort((a, b) => b.is_default - a.is_default);
            let remunration = purchaseServicesQuery?.data?.purchased_services?.remunerations?.filter(item => item.usableServiceClass)
            purchaseServicesQuery.data?.purchased_services?.purchased_services?.map((service, index) => {
                let vendorServices = []
                if (index === 0) {
                    vendorServices = purchaseServicesQuery.data?.purchased_services?.service_types[0];
                }
                if (index === 1) {
                    // vendorServices = purchaseServicesQuery.data?.purchased_services?.service_types[1];
                    vendorServices = []
                }
                if (index === 3) {
                    vendorServices = purchaseServicesQuery.data?.purchased_services?.service_types[2];
                }
                if (index === 4) {
                    vendorServices = purchaseServicesQuery.data?.purchased_services?.service_types[3];
                }
                if (vendorServices?.vendor_services?.length) {

                    let purchasedServiceCount = vendorServices?.vendor_services?.filter(item => item.service_selected || serviceInPackage.includes(item.id)).length;
                    if (purchasedServiceCount !== vendorServices?.vendor_services?.length) {
                        additioanlSServices.push(vendorServices)
                        if (purchasedServiceCount !== 0) {
                            allPurchasedServices.push(vendorServices)
                        }
                    } else {
                        allPurchasedServices.push(vendorServices)
                    }
                } else {
                    let purchasedServiceCount = service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected || serviceInPackage.includes(item.id)).length;
                    if (purchasedServiceCount !== service.purchased_office_template?.purchased_office_services?.length) {
                        additioanlSServices.push(service)
                        if (purchasedServiceCount !== 0) {
                            allPurchasedServices.push(service)
                        }
                    } else {
                        allPurchasedServices.push(service)
                    }
                }
            })

            setPurchasedServices(allPurchasedServices)
            setAdditionalServices(additioanlSServices)
            setRemunration(remunration)
            setPurchasedUttlegg(purchasedUttlegg)
            setPurchasedVederlag(purchasedVederlegg)
        }
    }, [purchaseServicesQuery.data])

    useEffect(() => {
        let service = []
        if (packagesQuery.data?.befaring_packages) {
            let selectedPackage = packagesQuery.data?.befaring_packages?.filter(item => item.property_package)
            if (selectedPackage.length) {
                setPurchasedPackages(selectedPackage)
            } else {
                setAdditionalPackages(packagesQuery.data?.befaring_packages?.filter(item => !item.property_package))
            }
            selectedPackage.map(packageItem => {
                service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
            })
            setServiceInPackage(service)
        }
    }, [packagesQuery.data])


    const toggleSelectedService = (id) => {
        // item.isSelected = !item.isSelected;
        let updatedServices = selectedServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }
        setSelectedServices(updatedServices)
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    const renderMyOrderItem = (item) => {
        let price = item.is_free ? item.free_text : `Kr ${formatPrice(item.price)},-`
        if (item?.property_package) {
            price = item.is_free ? item.free_text : `Kr ${formatPrice(item?.property_package?.price)},-`
        }
        return (
            <AddServiceView title={item.name}
                            price={price}
                            service={item?.marketing_package_service?.map(item => item.office_service)}
                            image={item.image ? item.image : item.image_url}
                            extraNote={item.external_url}
                            name={'disclaimer'}
                            desc={item.description}
                            mb={9}/>)
    };

    const renderAdditionalServiceItem = (item) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
                            isSelected={selectedServices.includes(parseInt(item.id))}
                            onPress={() => toggleSelectedService(parseInt(item.id))}/>)
    };

    const renderBillList = (item) => {
        let quantity = 1
        if (item.service_selected) {
            quantity = item?.service_selected?.quantity ?? 1
        }
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView isDeletable={false}
                                       isDeleteSpaceNotRequired={true}
                                       title={item.name}
                    // color={Color.TERNARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={item.is_free ? item.free_text : `Kr ${formatPrice(item?.service_selected ? item?.service_selected?.price : item?.property_package ? item.property_package?.price : item.price)},- ${quantity && quantity !== 1 ? " X " + item?.service_selected?.quantity : ""}`}/>

            </View>
        )
    };

    const renderUttleggItem = item => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView isDeletable={false}
                                       isDeleteSpaceNotRequired={true}
                                       title={item.name}
                                       color={Color.PRIMARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.service_selected ? item.service_selected.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1)))},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    }

    const renderService = (item, index) => {
        if (item?.vendor_services?.length) {
            let vendorPurchasedServices = item.vendor_services.filter(item => item.service_selected)
            if (!vendorPurchasedServices.length) {
                return;
            }
            return (
                <View key={index.toString()}>
                    <Label font_medium
                           mb={10}>
                        {item.name}
                    </Label>
                    {vendorPurchasedServices?.map(renderMyOrderItem)}
                </View>
            )

        }
        let data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected && !serviceInPackage.includes(item.id))
        if (!data.length) {
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data?.map(renderMyOrderItem)}
            </View>
        )
    }

    const renderAdditionalService = (item, index) => {

        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {item?.purchased_office_template?.purchased_office_services?.map(renderAdditionalServiceItem)}
            </View>
        )
    }


    let allPurchasedServices = []

    purchasedServices.map(service => {
        if (service?.vendor_services?.length) {
            service.vendor_services?.filter(item => item.service_selected).map(item => {
                allPurchasedServices.push(item)
            })
        } else {
            service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected && !serviceInPackage.includes(item.id)).map(item => {
                allPurchasedServices.push(item)
            })
        }
    })

    const packageTotal = purchasedPackages.map(item => parseInt(item?.property_package?.price)).reduce((a, b) => a + b, 0);
    const uttleggTotal = purchasedUttlegg.map(item => parseInt(item?.price)).reduce((a, b) => a + b, 0);
    const vedarleggTotal = purchasedVederlegg.map(item => parseInt(item?.price)).reduce((a, b) => a + b, 0);
    const remunerationTotal = remunration.map(item => item.usableServiceClass ? item.usableServiceClass.price * (item?.usableServiceClass?.quantity ?? 1) : item.price * (item?.hours ?? 1)).reduce((a, b) => a + b, 0);
    let total = allPurchasedServices.map(item => {
        if (item?.service_selected?.quantity) {
            return parseInt(item.price) * item?.service_selected.quantity
        } else {
            return parseInt(item.price)
        }

    }).reduce((a, b) => a + b, 0);
    let brokerOnViewPrice = 0
    if (data?.office_broker_on_view_service?.service_selected) {
        brokerOnViewPrice = data?.office_broker_on_view_service?.service_selected?.price * data?.office_broker_on_view_service?.service_selected?.quantity
    }
    total += packageTotal
    total += brokerOnViewPrice;
    total += uttleggTotal;
    total += vedarleggTotal;
    total += remunerationTotal;
    if (propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount) {
        total -= parseInt(propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount)
    }
    return (
        <View style={styles.cont}>
            <Header navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.sellerOrder}
                    animatedValue={scrollY}/>


            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.flexGrow}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>

                    <View style={styles.contentCont}>
                        <View style={styles.titleCont}>
                            <Icon name={'my-order'}
                                  size={ThemeUtils.fontXXLarge}/>
                        </View>
                        <Label small
                               mb={20}
                               style={styles.lblInst}>
                            {"Her finner du en oversikt over de tjenester/produkter selger har bestilt."}
                        </Label>
                    </View>
                    {loading || packagesQuery?.loading || purchaseServicesQuery?.loading ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <>
                            {data?.office_broker_on_view_service?.service_selected ? (
                                <View style={styles.orderCont}>
                                    <AddServiceView image={data?.office_broker_on_view_service?.image}
                                                    title={data?.office_broker_on_view_service?.name}
                                                    desc={data?.office_broker_on_view_service?.description}
                                                    name={'disclaimer'}
                                                    price={`Kr ${formatPrice(data?.office_broker_on_view_service?.service_selected?.price)},-`}/>
                                </View>) : null}
                            {/*{purchasedPackages.length ? (
                                <Label font_medium
                                       mt={15}
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                    {Strings.marketingPackages}
                                </Label>
                            ) : null}
                            <View style={styles.orderCont}>
                                {purchasedPackages.map(renderMyOrderItem)}
                            </View>
                            {purchasedServices.length ? (
                                <Label font_medium
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                    {Strings.services}
                                </Label>
                            ) : null}
                            <View style={styles.orderCont}>
                                {purchasedServices.map(renderService)}
                            </View>*/}
                            <View style={styles.billCont}>
                                {/*<View style={styles.billListCont}>
                                    <CostingAddServiceView isDeletable={false}
                                                           title={'Home sales fixed low price'}
                                                           color={Color.TERNARY_TEXT_COLOR}
                                                           iconColor={Color.DARK_GRAY}
                                                           price={'Kr 35.000,-'}/>
                                </View>*/}


                                {/*{data?.office_broker_on_view_service?.service_selected ? renderBillList(data?.office_broker_on_view_service) : null}*/}
                                {remunration.map(renderBillList)}
                                {purchasedPackages.length ? (
                                    <Label font_medium
                                           mb={10}
                                           mt={15}
                                        // color={Color.TERNARY_TEXT_COLOR}
                                           ml={ThemeUtils.relativeRealWidth(4)}>
                                        {Strings.marketingPackages}
                                    </Label>
                                ) : null}
                                {purchasedPackages.map(renderBillList)}
                                {purchasedPackages?.length ? (<Hr lineStyle={styles.lineStyle}/>) : null}
                                {allPurchasedServices.length ? (
                                    <Label font_medium
                                        // color={Color.TERNARY_TEXT_COLOR}
                                           mb={10}
                                           ml={ThemeUtils.relativeRealWidth(4)}>
                                        {Strings.services}
                                    </Label>
                                ) : null}
                                {allPurchasedServices.map(renderBillList)}
                                {purchasedUttlegg?.length || purchasedVederlegg?.length ?
                                    (<Label font_medium
                                            mt={15}
                                            mb={10}

                                            ml={ThemeUtils.relativeRealWidth(4)}>
                                        {"Andre kostnader"}
                                    </Label>) : null}

                                {purchasedVederlegg?.length ?
                                    (<Label font_medium
                                            mt={10}
                                            mb={5}
                                            ml={ThemeUtils.relativeRealWidth(4)}>
                                        {Strings.vederlag}
                                    </Label>) : null}
                                {purchasedVederlegg?.map(renderUttleggItem)}

                                {purchasedUttlegg?.length ?
                                    (<Label font_medium
                                            mt={10}
                                            mb={5}

                                            ml={ThemeUtils.relativeRealWidth(4)}>
                                        {Strings.utlegg}
                                    </Label>) : null}
                                {purchasedUttlegg?.map(renderUttleggItem)}
                                {propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount ? (
                                    <View style={styles.billListCont}>
                                        <CostingAddServiceView isDeletable={false}
                                                               title={"Avslag på totalprisen"}
                                                               color={Color.TERNARY_TEXT_COLOR}
                                                               iconColor={Color.DARK_GRAY}
                                                               price={`Kr ${formatPrice(propertyPreferenceQuery?.data?.property?.property_preference?.rebate_amount)},-`}/>

                                    </View>
                                ) : null}
                                <Hr lineStyle={styles.lineStyle}/>
                                <CostingAddServiceView title={Strings.totalCosting}
                                                       isDeleteSpaceNotRequired={true}
                                                       isTotalAmount
                                                       mt={5}
                                                       mb={5}
                                    // color={Color.WHITE}
                                                       price={`Kr ${formatPrice(total)},-`}/>
                            </View>
                            <CustomButton title={Strings.okay}
                                          onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                                          ml={ThemeUtils.relativeRealWidth(3)}
                                          mt={15}
                                          mb={15}
                                          mr={ThemeUtils.relativeRealWidth(3)}/>
                        </>
                    )}

                </View>
            </Animated.ScrollView>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        property: state.property
    }
}
export default connect(mapStateToProps)(MyOrders);
