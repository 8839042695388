const Errors = {


    /*headlineRequired: "Venligst skriv inn overskrift.",
    introRequired: "Venligst skriv inn introtekst.",
    servicePriceRequired: "Venligst oppgi pris.",
    stakerRequired: "Venligst oppgi oppdragstaker",
    settlementRequired: "Venligst oppgi særlige bestemmelser",
    oppgjorRequired: "Venligst oppgi oppgjørsforetak",
    employeesRequired: "Venligst oppgi oppgjørsmedarbeidere er",
    timespentRequired: "Venligst oppgi tidsbruken på Oppgaven er total",
    hourlyRateRequired: "Venligst oppgi timesatsen",
    remunerationRequired: "Venligst oppgi Vederlag",
    warrentyRequired: "Venligst oppgi salgsgaranti",
    startDate:'Vennligst dato.',*/

    headlineRequired: "Vennligst skriv en overskrift",
    introRequired: "Vennligst skriv en introduksjonstekst",
    servicePriceRequired: "Vennligst legg til pris",
    stakerRequired: "Oppdragstaker",
    settlementRequired: "Vennligst oppgi salgsgaranti tekst.",
    oppgjorRequired: "Vennligst angi oppgjørsforetak",
    employeesRequired: "Vennligst oppgi oppgjørsmedarbeidere",
    timespentRequired: "Oppgi estimat på timesforbruk",
    hourlyRateRequired: "Oppgi pris pr time",
    remunerationRequired: "Oppgi vederlagstype",
    warrentyRequired: "Vennligst angi salgsgaranti",
    nameBlank: 'Venligst oppgi navn',
    firmNameBlank: 'Vennligst oppgi meglerforetak',
    nameLength: 'Maksimalt antall tegn på navn',
    nameInvalid: 'Navn er ikke gylidg',
    emailBlank: 'Angi gyldig epost',
    emailValidity: 'Epost ikke gyldig',
    pwdBlank: 'Vennligst oppgi passord',
    pwdInvalid: 'Passord må inneholde en kombinasjon av tall, små og store bokstaver og et spesialtegn',
    pwdLengthMinimum: 'Passord må være på minst 8 tegn',
    pwdMisMatch: 'Passordene matcher ikke',
    address: 'Oppgi addresse',

    denominationkr: 'Please enter Denomination Kr',
    residualDebtkr: 'Please enter ResidualDebt Kr',
    loanNo: 'Please enter loan number ',
    accountnofortransfer: 'Oppgi telefonnummer',
    phoneBlank: 'Vennligst fyll inn Telefon',
    phoneInvalid: 'Telefonnummer er ikke gylidg.',
    phoneTooLong: 'Telefonnummer har for mange sifre.',
    phoneTooShort: 'Oppgi et gyldig telefonnummer.',
    contacts: 'Oppgi kontaktperson.',
    serverError: 'Noe gikk galt vennligst prøv igjen.',
    internetError: 'Internett er ikke tilgjengelig, sjekk din internett tilkobling.',
    invalidToken: ' Bruker er ikke autorisert. Logg inn om igjen.',
    requestTimeOut: 'Noe gikk galt, vennligst prøv igjen.',

    zipCode: 'Oppgi postnummer',
    zipCodeLength: 'Postnummer kan ikke være mer en fire siffer',
    defaultCity: 'Vennligst fyll inn poststed',
    recommendation: 'Please fill your recommendation.',
    question: 'Please write your question.',
    cbConsideration: 'Vennligst velg et alternativ',


    lastNameRequired: 'Vennligst oppgi etternavn',
    nameLengthMin: 'Navn må inneholde minst to tegn',
    nameLengthMax: 'Navn kan være på maksimalt 30 tegn',
    valuerate: 'Oppgi verditakst',
    loanrate: 'Oppgi lånetakst',
    bta: 'Oppgi BTA',
    bra: 'Oppgi BRA',
    prom: 'Oppgi P-rom',
    constyears: 'Oppgi byggeår',
    buyerRequired: 'Angi navn på kjøper',
    saveSearchName: 'Please enter search name.',
    saveSearchNameLength: 'Search name should be of 3 characters.',
    descriptionRequired: 'Vennligst oppgi beskrivelse.',
    descriptionRequiredLengthMin: 'Description must be of at least 10 characters.',
    descriptionRequiredLengthMax: 'Description should not be more than 160 characters.',
    interestRequired: 'Please Enter Interest',
    percentageRequired: 'Please enter percentage.',
    floorType: 'Please Enter Floor Type.',
    hamling: 'Please enter hamling.',
    wallType: 'Please Enter Wall Type.',
    workTop: 'Please Enter Work Top Type.',
    appliances: 'Please Enter Appliance.',
    orderDate: 'Vennligst velg dato',
    taxDate: 'Oppgi takst dato',
    startTime: 'Oppgi tidligst starttid',
    endTime: 'Oppgi senest sluttid',
    file: 'Velg vedlegg',
    commentBlank: 'Please enter comment',
    fashion: "Please select fashion.",
    errTimeGap: "Du må ha minimum 4 timer mellom start og sluttid for ønsket tidsvindu.",
    errTimeGapOneHr: "Du må ha minimum 1 timer mellom start og sluttid for ønsket tidsvindu.",
    title: 'Angi tittel',
    education: 'Angi utdanning',
    summaryAboutAgent: 'Angi meglerbeskrivelse',
    oppDragNoRequired: 'Angi oppdragsnummer',
    invalidOppDrag: 'Angi gyldig oppdragnummer',
    amount: 'Angi beløp',
    commune: 'Angi kommune',
    gnr: 'Angi Gnr',
    bnr: 'Angi Bnr',
    fnr: 'Angi Fnr',
    orgNr: 'Angi Org.nr.',
    snr: 'Angi Snr/Andelsnr',
    loanamount: 'Angi lånebeløp',
    linkRequired: 'Angi link',
    linkInvalid: 'Angi gyldig link',
    docTitle: 'Angi dokumentnavn',
    docRequired: 'Velg dokument for opplasting',
    docForRequired: 'Velg dokument for opplasting',
    image:"Vennligst fyll inn bilder",
    services:"Please select atleast one service",

    userType:"Please select user type",
    property:"Please select property",
    /*------------------------------------*/
    personNumber:"Vennligst velg person personnummer",
    ownership:"Vennligst velg eierandel",
    organizationNo:"Vennligst velg organisasjonsnummer",
    securityNo:"Vennligst velg  fødsel og personnummer kontaktperson",

    equity:"Vennligst velg bank for egenkapital",
    originEquity:"Vennligst velg opprinnelse egenkapital",
    purposeOfPurchase:"Vennligst velg formål med kjøp",
    receivables:"Vennligst velg overføres til kontonummer",
    receivablesInvalid:"Kontonummer må bestå av 12 tall og ingen bokstaver",
};

const PermissionMessage = {
    cancel: 'Cancel',
    ok: 'Okay',
    openSetting: 'Open Settings',
    authorized: 'authorized',
    denied: 'denied',
    undetermined: 'undetermined',
    restricted: 'restricted',
    granted: 'granted',
    never_ask_again: 'never_ask_again',

    //Camera
    cameraPermissionTitle: 'Kamera',
    cameraPermissionMessage: "Dette lar applikasjonen ta bilder, du må gi tillatelse i dine instillinger",

    //Photo
    photoPermissionTitle: 'Photo',
    photoPermissionMessage: "This lets you use the app to upload photos, you'll need to allow photo access in Settings.",

    //Location
    locationPermissionTitle: 'Location',
    locationPermissionMessage: "HouzQuest needs the location access to show you nearby properties, you'll need to allow location access in Settings.",


    //Notification
    notificationPermissionTitle: 'Notification',
    notificationPermissionMessage: 'Notification may include alerts, sounds and icon badges, this can be configured in Settings.',

    //Storage
    storagePermissionTitle: 'Storage',
    storagePermissionMessage: "This lets you upload the profile picture, you'll need to allow photo access in Settings.",

    //Contacts
    contactPermissionTitle: 'Contact',
    contactPermissionMessage: "We need access so we can get your contacts, please open this app's setting and allow contacts access.",

    //Phone Call
    phoneCallPermissionTitle: 'PhoneCall',
    phoneCallPermissionMessage: "We need access so we can allow you to call, please open this app's setting and allow phone call access.",

    //Read Sms
    readSmsPermissionTitle: 'ReadSms',
    readSmsPermissionMessage: "We need access so we can get your sms, please open this app's setting and allow message access.",
};
export default {Errors, PermissionMessage};
