import {createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import reducer from './reducer';
import {IS_WEB} from "../utils";


const persistConfig = {
    key: 'root',
    storage
};


const persistedReducer = persistReducer(persistConfig, reducer);

export let store = createStore(persistedReducer);

export let persistor = persistStore(store, null, () => {
    console.log('rehydrated');
});
