import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";
const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

export default StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,
    },
    contentCont: {
        flex: 1,
        marginHorizontal: 20,
    },
    titleCont: {
        marginTop: 5,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    orderCont: {
        marginHorizontal: containerHorizontalMargin,
        marginTop: 15,
    },

    lineStyle: {
        backgroundColor: Color.LIGHT_GRAY,
        marginHorizontal: ThemeUtils.relativeRealWidth(3),
        marginVertical: 10
    },
    btnOkay: {
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginVertical: 10
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },

    SubViewIcon: {

        backgroundColor: Color.PRIMARY,
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        maxHeight: 200,
        maxWidth: 200,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    propertySelectionCont: {
        flexDirection: 'row',
        alignItems:'center',
        marginTop: 15,
    },
    buttonContainer:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10
    },
    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: 20
    },
    GridImage: {
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        height: ThemeUtils.responsiveScale(200),
        // width: ThemeUtils.responsiveScale(200),
        borderWidth: 0.5,
        borderColor: Color.PRIMARY_LIGHTER
    },
    selectedImage: {
        padding: 10,
        position: 'absolute',
        start: 0,
        end: 0,
        height: ThemeUtils.responsiveScale(200),
        // width: ThemeUtils.responsiveScale(200),
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        backgroundColor: Color.PRIMARY_LIGHT
    },
    img:{
        borderRadius:4,
        marginTop:ThemeUtils.relativeRealHeight(2),
        width:"100%",
        height:ThemeUtils.relativeRealHeight(20),
        backgroundColor:Color.GRAY
    }
})
