import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: containerHorizontalMargin / 1.5,
        alignContent: 'center',
    },

    optionContainer: {
        marginVertical: containerHorizontalMargin,
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    imgThumb: {
        height: ThemeUtils.responsiveScale(75),
        width: ThemeUtils.responsiveScale(75),
        backgroundColor: Color.GRAY,
        borderRadius: 10,
    },
    iconView: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dividerLine: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
});

export default styles;
