import {Col, Layout, Row} from 'antd';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
// import logo from '../../../assets/webmegling.svg';
import '../../layouts/userheader.scss';
import BefaringFooter from '../BefaringFooter'
import {getWmeglingInnerLogo} from "../../utils/utils";

const {Header, Content} = Layout;


class BefaringLayout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            current: '',
            isTop: true,
            hovered: false,
        };
        // this.logoRef = React.createRef();
        // this.whiteLogoRef = React.createRef();
    }

    render() {
        const {hovered, isTop, current} = this.state;
        const {children} = this.props;
        return (
            <>
                <Header className="w-100 ant-layout-header admin-header bg-primary">
                    <Row className="w-100">
                        <Col xs={24} lg={24}>
                            <div className="admin-layout-wrapper">
                                <div className="logo-wrapper">
                                    <div className="logo-container">
                                        <Link to="/dashboard">
                                            <img
                                                src={getWmeglingInnerLogo()}
                                                className="logoicon w-100"
                                                style={{color: '#b9e2bf'}}
                                                alt="Weiendomsmegling"
                                            />
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Header>
                <Layout>
                    <Content>
                        {/*<View style={{flexGrow: 1, overflow: 'hidden'}}>*/}
                        {children}
                        {/*</View>*/}
                    </Content>
                </Layout>
                <BefaringFooter/>

            </>
        );
    }
}

export default BefaringLayout;
