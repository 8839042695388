import React, {useState} from 'react'
//Components
import {Image, SafeAreaView, ScrollView, View} from 'react-native'
import {Dialog, Header, UploadImageDialog, ZoomView} from "components/src/components";
//Third party
//Network
//Utils
import styles from './styles';
import {Color, CommonStyle, Constants, Strings} from "components/src/utils";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_OFFICE_SERVICE_MEDIA} from "../../../api/mutation";
import {OFFICE_SERVICE_DETAILS} from "../../../api/query";

export default function PhotoDetails(props) {
    const {mutation, refetchQuery, deleteMutation, variables, replaceVariables, oldPhotoRequired, loading, onReplaceImage, renderImage, imageForPreview, onClose} = props;
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [replaceImageVisible, setReplaceImageVisible] = useState(false)

    let option = refetchQuery ? refetchQuery ?? {} : {
        refetchQueries: [{
            query: OFFICE_SERVICE_DETAILS,
            variables: {
                id: imageForPreview?.office_template_id
            }
        }]
    }
    const [deleteImages, deleteImagesMutation] = useMutation(deleteMutation, option);

    const replaceImageModal = () => {
        return (
            <UploadImageDialog visible={replaceImageVisible}
                               loading={loading}
                               oldPhotoRequired={oldPhotoRequired}
                               onPositivePress={(newImage, oldImage) => {
                                   let variables = {
                                       ...replaceVariables,
                                       photo: newImage
                                   }
                                   if (oldPhotoRequired) {
                                       variables.oldPhoto = oldImage
                                   }
                                   onReplaceImage({
                                       variables: variables
                                   }).then((data) => {
                                       setReplaceImageVisible(false)
                                       onClose(false, data);
                                   }).catch(() => {

                                   })
                               }}
                               onNegativePress={() => setReplaceImageVisible(false)}
                               title={Strings.uploadPhoto}/>
        )
    };

    return (
        <SafeAreaView style={CommonStyle.safeArea}>
            <View style={CommonStyle.container}>
                {replaceImageModal()}
                <Header style={styles.header}
                        tintColor={Color.WHITE}
                    // title={Strings.Photos}
                        onPressRightSecond={() => setDeleteVisible(true)}
                        onPressRightFirst={() => setReplaceImageVisible(true)}
                        headerRightFirst={'replace'}
                        headerRightSecond={'delete'}
                        onPressLeft={onClose}/>
                <Dialog title={Strings.deletePhoto}
                        loading={deleteImagesMutation.loading}
                        visible={deleteVisible}
                        onNegativePress={() => setDeleteVisible(false)}
                        onPositivePress={() => {
                            deleteImages({
                                variables: variables
                            }).then(() => {
                                setDeleteVisible(false)
                                onClose && onClose(true)
                            }).catch(() => {

                            });

                        }}
                        desc={Strings.deleteConfirmation}/>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <ZoomView maxZoom={1.5}
                              minZoom={0.5}
                              zoomStep={0.5}
                              initialZoom={1}
                              bindToBorders={true}
                              style={{backgroundColor: Color.PRIMARY}}>
                        {renderImage ? renderImage() : (
                            <Image style={styles.img}
                                   resizeMode={'contain'}
                                   source={{uri: `${Constants.APIConfig.STORAGE_URL}${imageForPreview?.url ? imageForPreview?.url : imageForPreview?.image ? imageForPreview?.image : imageForPreview?.media_url}`}}/>)}

                    </ZoomView>
                </ScrollView>
                {/*<DropDown options={new Array(10).fill({name: 1})}
                      placeTitle={'Image no.'}
                      tintColor={Color.WHITE}
                      placeholderColor={Color.WHITE}
                      onSelectOptions={() => {

                      }}
                      dropDownStyle={styles.dropDown}/>*/}
            </View>
        </SafeAreaView>
    )

}
PhotoDetails.defaultProps = {
    deleteMutation: DELETE_OFFICE_SERVICE_MEDIA,

}
