export default {
  'component.disclaimer.title': 'Vilkår for bruk',
  'component.disclaimer.li1': 'Introduksjon',
  'component.disclaimer.li2': 'Personvern:',
  'component.disclaimer.li3': 'Beskrivelse av tjenesten:',
  'component.disclaimer.li4': 'Bruk av copyright / materiale / varemerker',
  'component.disclaimer.li5': 'Hyperkoblinger til dette nettstedet',
  'component.disclaimer.li6': 'Brukerkontoer, passord og sikkerhet',
  'component.disclaimer.li7':
    ' Innhold som overlates til Webmegging for bruk eller distribusjon gjennom Webmegging',
  'component.disclaimer.li8': 'Generell praksis for bruk av nettmegling:',
  'component.disclaimer.li9': 'Endring av tjeneste:',
  'component.disclaimer.li10': 'Avslutning:',
  'component.disclaimer.li11': 'Ansvarsfraskrivelse:',
  'component.disclaimer.li12': 'Ulovlig eller ulovlig bruk',
  'component.disclaimer.li13': 'Annonsering og lenker til tredjeparts nettsteder:',
  'component.disclaimer.li14': 'Gjeldende lovgivning:',
  'component.disclaimer.Privacy.li1': 'Introduksjon',
  'component.disclaimer.Privacy.li2': 'Ansvarlig for behandlingen',
  'component.disclaimer.Privacy.li3': 'Processing of personal data',
  'component.disclaimer.Privacy.li4': 'Behandling av personopplysninger',
  'component.disclaimer.Privacy.li4.1': 'Det er nødvendig å oppfylle en avtale med deg',
  'component.disclaimer.Privacy.li4.2': 'Vi har en legitim interesse i behandlingen',
  'component.disclaimer.Privacy.li4.3': 'Det er nødvendig å overholde lovpålagte forpliktelser',
  'component.disclaimer.Privacy.li4.4': 'Ditt samtykke',
  'component.disclaimer.Privacy.li.5': 'Sikring av personopplysninger',
  'component.disclaimer.Privacy.li.6': 'Utlevering av personopplysninger til andre',
  'component.disclaimer.Privacy.li.7': 'Informasjonskapsler / informasjonskapsler',
  'component.disclaimer.Privacy.li.8': 'Lagringstid',
  'component.disclaimer.Privacy.li.9': 'Dine rettigheter',
  'component.disclaimer.Privacy.li.10': 'Endringer',
  'component.disclaimer.Footer.heading1': 'Kjeks',
};
