import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,
        justifyContent: 'center'
    },
    dialogCont: {
        marginHorizontal: ThemeUtils.isTablet()?ThemeUtils.relativeRealWidth(30):ThemeUtils.relativeRealWidth(5),
        borderRadius: 8,
        backgroundColor: Color.WHITE
    },
    divider: {
        width: 1,
        backgroundColor: Color.PRIMARY_LIGHTER
    },
    btnCont: {
        flexDirection: 'row',
    },
    lblTitle: {
        paddingTop: 10
    },
    MainView: {
        marginTop: 10,
        paddingHorizontal:ThemeUtils.relativeRealWidth(2)
    },
    btn: {
        flex: 1,
        height: ThemeUtils.relativeRealHeight(6),
        alignItems: 'center',
        justifyContent: 'center'
    },
    pickerCont: {
        flexDirection: 'row',
        marginVertical: ThemeUtils.relativeRealHeight(2),

    },
    imagePicker: {
        marginStart:ThemeUtils.relativeRealWidth(2),
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(110),
        width: ThemeUtils.responsiveScale(110),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },

})
