import {StyleSheet} from 'react-native';
import {ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    newsCont:{
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:ThemeUtils.relativeRealHeight(1.5)
    },
    imgCont:{
        flexDirection:'row',
        alignItems:'center',
    },
    imgNews:{
        height:ThemeUtils.responsiveScale(120),
        width:ThemeUtils.responsiveScale(120),
        borderRadius:ThemeUtils.responsiveScale(10)
    }
})
