import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:Color.PRIMARY_LIGHT,
        justifyContent:'center'
    },
    dialogCont:{
        marginHorizontal:ThemeUtils.isTablet()?ThemeUtils.relativeRealWidth(40):ThemeUtils.relativeRealWidth(6),
        borderRadius:8,
        backgroundColor: Color.WHITE
    },
    divider:{
      width:1,
      backgroundColor:Color.PRIMARY_LIGHTER
    },
    btnCont:{
        flexDirection:'row',
    },
    lblTitle:{
        paddingTop:10
    }
})
