import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    cont: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        borderWidth: 1,
        borderRadius: 3,
        paddingVertical: 5,
        paddingHorizontal: 10,
    }
})
