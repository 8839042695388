import React from 'react'
//Components
import {Animated, View} from 'react-native'
import {CustomButton, Header, Label} from "components/src/components";
//Utils
import {CommonStyle, IS_WEB, Strings} from "components/src/utils";
import styles from './styles';


export default function NotifcationSuccess(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0)


    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.pushNotifications}
                    initialMarginLeft={20}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>

            <View style={CommonStyle.flex}>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.scrollCont}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View>
                        <Label small
                               font_medium>
                            {Strings.msgSentSuccess}
                        </Label>
                        <Label small
                               mt={15}>
                            {'Selger og kjøper får automatiske påminnelser og meldinger etter som salgsprosessen går sin gang. Men har kan du som megler enkelt sende ut en påminnelse til dine kunder ved et tastetrykk.'}
                        </Label>

                    </View>
                </Animated.ScrollView>
            </View>
            <CustomButton title={Strings.okay}
                          onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                          mb={10}
                          ml={10}
                          mr={10}/>
        </View>
    )
}
