import React, {useState} from "react";
import {ActivityIndicator, View} from "react-native";
import {Header, IndicatorViewPager, LogoForHeader, Notes, PagerTabIndicator,} from "components/src/components";
import {Color, CommonStyle, Constants, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import IntroFilmMobile from "./Mobile/IntroFilm";
import IntroFilmTab from "./Tab/IntroFilm";
import WadsMobile from "./Mobile/WAds";
import WadsTab from "./Tab/WAds";
import FinnNoMobile from "./Mobile/FinnNo";
import FinnNoTab from "./Tab/FinnNo";
import DigitalSalesAssignmentMobile from "./Mobile/DigitalSalesAssignment";
import DigitalSalesAssignmentTab from "./Tab/DigitalSalesAssignment";
import {useQuery} from "@apollo/react-hooks";
import {SELECTED_PROPERTY_SERVICE} from "../../../../../../api/query";
import {connect} from "react-redux";

const TABS = [
    {
        text: Strings.INTROFILM,
    },
    {
        text: Strings.WADS,
    },
    {
        text: Strings.FINNNO,
    },
    {
        text: Strings.DIGITALSALESASSIGNMENT,
    },
];

function DigitalMarketing(props) {
    const {history, inspection, navigation} = props;
    const [visible, setVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    const {loading, data, refetch, error} = useQuery(SELECTED_PROPERTY_SERVICE, {
        variables: {
            status: true,
            category: Constants.ServiceCategory.DigitalMarketing,
            propertyId: inspection?.property_id
        }
    });

    let introFilmData = data?.selected_property_services?.services ? data?.selected_property_services?.services[0]?.purchased_office_template : {}
    let adsData = data?.selected_property_services?.services ? data?.selected_property_services?.services[1]?.purchased_office_template : {}
    let finnNoData = data?.selected_property_services?.services ? data?.selected_property_services?.services[2]?.purchased_office_template : {}
    let dsAssignmentData = data?.selected_property_services?.services ? data?.selected_property_services?.services[3]?.purchased_office_template : {}

    let selectedPackage = data?.selected_property_services?.marketing_package ? data?.selected_property_services?.marketing_package.filter(item => item.property_package) : []
    let isCompanyTemplate = inspection.template_type !== "PERSONAL"

    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}
                               scrollEnabled={!ThemeUtils.isTablet()}/>
        );
    };

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }

    return (
        <View style={styles.container}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}
                    isCompanyTemplate={isCompanyTemplate}
                    refetch={refetch}
                    {...props}/>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <IndicatorViewPager
                    indicator={renderTabIndicator()}
                    scrollEnabled={false}
                    onPageSelected={(e) => setCurrentPage(e.position)}
                    style={CommonStyle.flex}>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <IntroFilmTab data={introFilmData}
                                          isFocused={currentPage === 0}
                                          isCompanyTemplate={isCompanyTemplate}
                                          packages={selectedPackage}
                                          refetch={refetch}
                                          {...props}/> :
                            <IntroFilmMobile data={introFilmData}
                                             isFocused={currentPage === 0}
                                             isCompanyTemplate={isCompanyTemplate}
                                             packages={selectedPackage}
                                             refetch={refetch}
                                             {...props}/>
                        }
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <WadsTab data={adsData}
                                     isFocused={currentPage === 1}
                                     isCompanyTemplate={isCompanyTemplate}
                                     packages={selectedPackage}
                                     refetch={refetch}
                                     {...props}/> :
                            <WadsMobile data={adsData}
                                        isFocused={currentPage === 1}
                                        isCompanyTemplate={isCompanyTemplate}
                                        packages={selectedPackage}
                                        refetch={refetch}
                                        {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <FinnNoTab data={finnNoData}
                                       isCompanyTemplate={isCompanyTemplate}
                                       packages={selectedPackage}
                                       refetch={refetch}
                                       {...props}/> :
                            <FinnNoMobile data={finnNoData}
                                          isCompanyTemplate={isCompanyTemplate}
                                          packages={selectedPackage}
                                          refetch={refetch}
                                          {...props}/>
                        }
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ? (
                            <DigitalSalesAssignmentTab data={dsAssignmentData}
                                                       isCompanyTemplate={isCompanyTemplate}
                                                       packages={selectedPackage}
                                                       refetch={refetch}
                                                       {...props}/>
                        ) : (
                            <DigitalSalesAssignmentMobile data={dsAssignmentData}
                                                          isCompanyTemplate={isCompanyTemplate}
                                                          packages={selectedPackage}
                                                          refetch={refetch}
                                                          {...props}/>
                        )}
                    </View>
                </IndicatorViewPager>
            )}
        </View>
    );
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(DigitalMarketing)
