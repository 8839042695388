import {
    SET_TOKEN,
    SET_USER,
    SET_INSPECTION_DETAILS, SET_INITIAL_SESSION
} from './action-types';

import {REHYDRATE} from 'redux-persist/src/constants';

let initialState = {
    user: null,
    token: null,
};

const setToken = (token) => ({type: SET_TOKEN, token});
const setUser = (user) => ({type: SET_USER, user});
const setCurrentInspectionDetails = (inspection) => ({type: SET_INSPECTION_DETAILS, inspection});
const logout = () => ({type: REHYDRATE, payload: initialState});
const setAgentSession = (session) => ({type: SET_INITIAL_SESSION, session});

export {
    setToken,
    setUser,
    logout,
    setAgentSession,
    setCurrentInspectionDetails,
};
