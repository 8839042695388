export default {
  'component.sellerinterview.title': 'Selgers intervju',
  'component.sellerinterview.description':
    'Dette er spørsmål som kan være viktig for potensielle kjøpere. Alle spørsmål vil ikke være like relevant for alle boligtyper, men svar så utfyllende som mulig.',
  // stepper titles.
  'component.sellerinterview.step1.title': 'Eierforhold/ Boligen',
  'component.sellerinterview.step2.title': 'Beliggenhet',
  'component.sellerinterview.step3.title': 'Standard',
  'component.sellerinterview.step4.title': 'Sameie/Velforening/Veilag etc',
  'component.sellerinterview.step5.title': 'Rettigheter',
  'component.sellerinterview.step6.title': 'VVS',
  'component.sellerinterview.step7.title': 'Tekniske installasjoner',
  'component.sellerinterview.step8.title': 'Hvitevarer',
  'component.sellerinterview.step9.title': 'Annet',
  // questionnaire
  'component.sellerinterview.step1.ques1': 'Hva er grunnen til at dere skal selge boligen?',
  'component.sellerinterview.step1.ques2': 'Hva liker dere best ved boligen?',
  'component.sellerinterview.step2.ques1': 'Hvilke fasiliteter gjelder for din bolig?',
  'component.sellerinterview.step2.ques1.aircondition': 'Air Condition',
  'component.sellerinterview.step2.ques1.alarm': 'Alarm',
  'component.sellerinterview.step2.ques1.resort': 'Alpinanlegg',
  'component.sellerinterview.step2.ques1.swimming': 'Bademuilgheter',
  'component.sellerinterview.step2.ques1.balcony': 'Balkong/Terrasse',
  'component.sellerinterview.step2.ques1.kidfriendly': 'Barnevennlig',
  'component.sellerinterview.step2.ques1.broadband': 'Bredbånd',
  'component.sellerinterview.step2.ques1.batplass': 'Båtplass',
  'component.sellerinterview.step2.ques1.communalwashing': 'Fellesvask',
  'component.sellerinterview.step2.ques1.fishing': 'Fiskemuligheter',
  'component.sellerinterview.step2.ques1.garage': 'Garasje/P-plass',
  'component.sellerinterview.step2.ques1.guestparking': 'Gjesteparkering',
  'component.sellerinterview.step2.ques1.golfcourse': 'Golfbane',
  'component.sellerinterview.step2.ques1.garden': 'Hage',
  'component.sellerinterview.step2.ques1.elevator': 'Heis',
  'component.sellerinterview.step2.ques1.petsallowed': 'Husdyr tillatt',
  'component.sellerinterview.step2.ques1.noresidents': 'Ingen gjenboere',
  'component.sellerinterview.step2.ques1.cabletv': 'Kabel-TV',
  'component.sellerinterview.step2.ques1.offwater': 'Off.vann og avløp',
  'component.sellerinterview.step2.ques1.parquet': 'Parkett',
  'component.sellerinterview.step2.ques1.fireplace': 'Peis/Ildsted',
  'component.sellerinterview.step2.ques1.reception': 'Resepsjon Rolig Sentralt',
  'component.sellerinterview.step2.ques1.shoreline': 'Strandlinje',
  'component.sellerinterview.step2.ques1.roof': 'Takterasse',
  'component.sellerinterview.step2.ques1.hiking': 'Turterreng',
  'component.sellerinterview.step2.ques1.view': 'Utsikt',
  'component.sellerinterview.step2.ques1.expandability': 'Utvidelsesmuligheter',
  'component.sellerinterview.step2.ques1.janitoral': 'Vaktmester-/vektertjeneste',
  'component.sellerinterview.step2.ques2': 'Hva liker dere best med beliggenheten til boligen?',
  'component.sellerinterview.step2.ques3':
    'Er det noen turområder/aktivitetsmuligheter som bør fremheves?',
  'component.sellerinterview.step2.ques4':
    'Beskriv kort hvem de nærmeste naboene er, eksempelvis: «i leiligheten til høyre bor det et hyggelig par i midten av 30-årene»',
  'component.sellerinterview.step2.ques5': 'Bor det mange barnefamilier i området?',
  'component.sellerinterview.step2.ques6': 'Hvordan er solforholdene?',
  'component.sellerinterview.step2.ques6.subitem1': 'Midtsommers',
  'component.sellerinterview.step2.ques6.subitem2': 'Vinterstid',
  'component.sellerinterview.step3.ques1':
    'Er det gjort oppgraderinger/vedlikehold som bør fremheves?',
  'component.sellerinterview.step3.ques1.yes.ques': 'Beskriv hva, når og av hvem',
  'component.sellerinterview.step3.ques2':
    'Er det andre kvaliteter ved boligen dere gjerne vil fremheve?',
  'component.sellerinterview.step4.ques1': 'Er det pliktig medlemskap i velforening?',
  'component.sellerinterview.step4.ques1.yes.ques': 'Kontaktinfo til lederen i velforeningen',
  'component.sellerinterview.step4.ques2': 'Kontaktinfo til styreleder',
  'component.sellerinterview.step4.ques3': 'Hvor mye betaler dere i felleskostnader?',
  'component.sellerinterview.step4.ques3.permonth': 'Pr måned',
  'component.sellerinterview.step4.ques3.perquarter': 'Pr kvartal',
  'component.sellerinterview.step4.ques3.peryear': 'Pr år',
  'component.sellerinterview.step4.ques4': 'Hva inkluderer felleskostnadene?',
  'component.sellerinterview.step4.ques5':
    'Er det noen tilhørende plikter som dugnad/brøyting eller lignende?',
  'component.sellerinterview.step5.ques1':
    'Foreligger det avtaler, rettigheter eller forpliktelser tilknyttet eiendommen?',
  'component.sellerinterview.step5.ques2':
    'Er det tilknyttet garasje-/parkeringsplass til boligen?',
  'component.sellerinterview.step5.ques2.yes.ques': 'Kan dette i så tilfelle dokumenteres?',
  'component.sellerinterview.step5.ques3':
    'Er det tilknyttet fellesarealer/realsameie (lekeplass, ballbane etc.)?',
  'component.sellerinterview.step5.ques3.yes.ques': 'Hva og hvor er dette?',
  'component.sellerinterview.step6.ques1': 'Hvilke varmekilder er det i boligen?',
  'component.sellerinterview.step6.ques1.heatingcable': 'Varmekabler',
  'component.sellerinterview.step6.ques1.waterborneheat': 'Vannboren varme',
  'component.sellerinterview.step6.ques1.radiator': 'Radiator',
  'component.sellerinterview.step6.ques1.heatpump': 'Varmepumpe',
  'component.sellerinterview.step6.ques1.heatpump.airtoair': 'Luft til luft',
  'component.sellerinterview.step6.ques1.heatpump.airtowater': 'Luft til vann',
  'component.sellerinterview.step6.ques1.heatpump.watertowater': 'Vann til vann',
  'component.sellerinterview.step6.ques1.balancedventilation':
    'Balansert ventilasjon med varmegjenvinning',
  'component.sellerinterview.step6.ques1.heaters': 'Panelovner',
  'component.sellerinterview.step6.ques1.woodstove': 'Vedovn',
  'component.sellerinterview.step6.ques1.fireplace': 'Peis',
  'component.sellerinterview.step6.ques1.fireplace.bio': 'Biopeis',
  'component.sellerinterview.step6.ques1.fireplace.gas': 'Gasspeis',
  'component.sellerinterview.step6.ques1.oilheating': 'Oljefyring',
  'component.sellerinterview.step6.ques2': 'Er rør i boligen skiftet?',
  'component.sellerinterview.step6.ques2.yes.ques': 'Hvor er det skifet og når?',
  'component.sellerinterview.step6.ques3': 'Hvor er varmtvannstanken plassert?',
  'component.sellerinterview.step7.ques1': 'Hvem er leverandør av internett',
  'component.sellerinterview.step7.ques2': 'Hvilken type internett er det?',
  'component.sellerinterview.step7.ques3': 'Hvem er leverandør av TV',
  'component.sellerinterview.step7.ques4': 'Hvordan leveres TV signalene?',
  'component.sellerinterview.step7.ques5': 'Hvor høyt er årlig gjennomsnittlig strømforbruk?',
  'component.sellerinterview.step7.ques6': 'Hvor er boligens sikringsskap plassert?',
  'component.sellerinterview.step8.ques1': 'Er det hvitevarer som ikke skal medfølge i handelen?',
  'component.sellerinterview.step8.ques2':
    'Skal vaskemaskin eller tørketrommel medfølge i handelen?',
  'component.sellerinterview.step9.ques1':
    'Norges Eiendomsmeglerforbund har utarbeidet en liste over løsøre og innbo som skal følge med boligen ved salg: https://www.nef.no/losore-og-tilbehor/ Er det noe på denne listen som ikke skal medfølge når dere nå skal selge?',
  'component.sellerinterview.step9.ques2':
    'Er det andre opplysninger dere mener er relevant å opplyse eiendomsmegler og kjøper om?',
  'component.sellerinterview.step9.ques3': 'Når ønsker dere overtakelse av boligen?',

  // yes no options.

  'component.sellerinterview.yes': 'Ja',
  'component.sellerinterview.no': 'Nei',
  'component.sellerinterview.unsure': 'vet ikke',
  'component.sellerinterview.notrelevant': 'Ikke relevant',
  'component.sellerinterview.next': 'Lagre & Neste',
  'component.sellerinterview.previous': 'Forrige',
  'component.sellerinterview.exit': 'Avslutt',
};
