import React from 'react';
import {View} from 'react-native'
import styles from './styles';
import {Color, CommonStyle, Icon, ThemeUtils} from "../../../utils";
import {Label, Ripple} from "../../index";

export default function Attachment(props) {
    const {name,isDownloadable, isDeletable, onPressDelete, style, onPressDownload, isTransparent, textColor, iconColor} = props;

    const bgColor = isTransparent ? 'transparent' : Color.GRAY;
    const textClr = textColor ? textColor : Color.PRIMARY_LIGHT;
    const iconClr = iconColor ? iconColor : Color.PRIMARY_LIGHT;

    return (
        <View style={[styles.cont, style, {backgroundColor: bgColor}]}>
            <Icon name={'attachment'}
                  size={ThemeUtils.fontNormal}
                  color={Color.PRIMARY_LIGHT}/>
            <Label small
                   mb={4}
                   ml={10}
                   style={CommonStyle.flex}
                   color={textClr}>
                {name}
            </Label>
            {isDownloadable ? (
                <Ripple style={styles.icCont}
                        onPress={onPressDownload}
                        rippleContainerBorderRadius={12}>
                    <Icon name={'download'}
                          size={ThemeUtils.fontNormal}
                          color={iconClr}/>
                </Ripple>) : null}

            {isDeletable ? (
                <Ripple style={styles.icCont}
                        onPress={onPressDelete}
                        rippleContainerBorderRadius={12}>
                    <Icon name={'delete'}
                          size={ThemeUtils.fontNormal}
                          color={iconClr}/>
                </Ripple>) : null}
        </View>
    )
}
