import React, {useEffect, useState} from 'react'
//Components
import {Text, View} from 'react-native'
import {CustomButton, Header, Label} from "components/src/components";
//Third party
//Network
//redux
//Utils
import {styles} from './styles.js';
import {
    Color,
    CommonStyle,
    Constants, decryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {useQuery} from "@apollo/react-hooks";
import {AGENT_SIGN} from "../../../../../api/query";
import {setAgentSession} from "../../../../../redux/action";
import {connect} from "react-redux";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';


function SignAssignmentSuccess(props) {

    const {history, navigation} = props;
    let propertyId;
    let initialParams
    if(IS_WEB){
        if (props && props.match && props.match.params.id) {
            propertyId = JSON.parse(decryptId(props.match.params.id)).property_id;
            initialParams = JSON.parse(localStorage.getItem('agentSign'));
        }
    }
    const {loading,data,error}=useQuery(AGENT_SIGN,{
        variables:{
            input:{
                property_id: parseInt(propertyId, 10),
                InititalParam: {
                    AuthenticationUrl:initialParams?.AuthenticationUrl,
                    RequestID:initialParams?.RequestID,
                    TrackingID:initialParams?.TrackingID
                },
            }
        }
    })


    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>
            <View style={CommonStyle.flex}>
                <Label xlarge
                       font_medium
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mb={ThemeUtils.relativeRealHeight(1.5)}>
                    {Strings.congratulations}
                </Label>
                <Label small
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {Strings.youSuccessfullySignedTheAgreement}
                </Label>
            </View>
            <CustomButton title={Strings.okay}
                          onPress={() => IS_WEB ? history.replace(`/${Routes.SignatureAssignment}`) : navigation.pop(2)}
                          ml={20}
                          mr={20}
                          mb={20}/>
        </View>)
}

const mapStateToProps = (state) => {
                console.log(state)
    return {
        session:state.session
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setSession: (session) => setAgentSession(session)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignAssignmentSuccess);
