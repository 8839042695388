export default {
  'component.changepassword.title': 'Bytt passord',
  'component.changepassword.line1.heading1': 'Nåværende passord',
  'component.changepassword.line1.heading1.placeholder': 'Tast inn Nåværende passord',
  'component.changepassword.line2.heading1': 'Ny passord',
  'component.changepassword.line2.heading1.placeholder': 'Tast inn Ny passord',
  'component.changepassword.line2.heading2': 'Bekrefte passord',
  'component.changepassword.line2.heading2.placeholder': 'Tast inn Bekrefte passord',
  'component.changepassword.line3.button1': 'Lagre',
  'component.changepassword.line3.button2': 'Avbryt',
  'component.changepassword.success': 'Passordet ble endret',
};
