import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: Color.PRIMARY_LIGHT,
        flex: 1,
    },
    dropDownCont: {
        borderWidth: 1,
        borderRadius: ThemeUtils.relativeRealWidth(1),
        alignItems:'center',
        paddingHorizontal:ThemeUtils.relativeRealWidth(3),
        paddingVertical:ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        borderColor:Color.INPUT_BORDER
    },
    bottomViewContainer: {
        backgroundColor:Color.WHITE,
        width: '100%',
        position: 'absolute',
        bottom: 0,
        alignSelf: 'center',
    },
    titleContainer: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent: 'space-between',
        paddingVertical: 15,
        paddingHorizontal: ThemeUtils.relativeWidth(6),

        shadowColor: Color.BLACK,
        backgroundColor:Color.WHITE,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },

    closeBtnContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        width: 40,
        height: 40,
        position: 'absolute',
    },
    selectorStyle: {
        flex:1,
    },
    selectedItem:{
        backgroundColor:Color.UPLOAD_BG_COLOR,
    },
    itemCont: {
        flexDirection:'row',
        alignItems:'center',
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        paddingVertical:10,
    },
    mobilePickerCont:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: "#FFF",
        paddingHorizontal: ThemeUtils.relativeRealWidth(4),
        paddingVertical: ThemeUtils.relativeRealHeight(1.5),
    }
});
export default styles;
