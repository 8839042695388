import React, {useState} from 'react'
import {View,ScrollView} from 'react-native'
import {Header, Notes} from "components/src/components";
import {CommonStyle,Color,Routes, IS_WEB, ThemeUtils} from "components/src/utils";
import styles from './styles';
import BoliselgerforsikringMobile from './Mobile';
import BoliselgerforsikringTab from './Tab';

function Calendar(props) {
    const {history, navigation} = props;
    const [visible, setVisible] = useState(false)

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={()=>{
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }


    return (
        <View style={[styles.container,{overflow:'hidden',}]}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
 title={'about-w'} tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            <View style={CommonStyle.flex}>

                {ThemeUtils.isTablet() ? <BoliselgerforsikringTab {...props}/> : <BoliselgerforsikringMobile {...props}/>}
            </View>
        </View>

    )
}

export default Calendar
