import React from 'react';
//Components
import { Animated, Image, View, TextInput } from 'react-native';
import { Header, Hr, Label, Ripple } from "components/src/components";
//Third party

//Utils
import {Color, Routes, Icon, IS_WEB, Strings, ThemeUtils, CommonStyle, encryptId} from "components/src/utils";
import styles from './styles';


const PRO_SEARCH = [
    {
        id: 1,
        desc: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
        OppdragNo: 'Oppdrag No. : 1005619'
    },
    {
        id: 2,
        desc: 'Furehogen 24, 6847 Vassenden, Norway',
        OppdragNo: 'Oppdrag No. : 1000620'
    },
    {
        id: 3,
        desc: 'Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350',
        OppdragNo: 'Oppdrag No. : 1000325'
    },




];

export default function Property_Search(props) {
    const { navigation, history } = props;
    const scrollY = new Animated.Value(0)

    const navigate = (news) => {
        if (IS_WEB) {

                history.push(`/${Routes.News}/${Routes.NewsDetails}/${encryptId(news.id)}`)
        } else {
            navigation.navigate(Routes.NewsDetails)
        }
    }
    const Property_Search = (item, index) => {
        return (
            <>

                <Ripple style={styles.ProCont}
                    onPress={()=>navigate(item)}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={styles.content}>
                            <Label small
                                mt={10}
                                font_medium
                                numberOfLines={3}>
                                {item.desc}
                            </Label>
                            <Label small
                                mt={10}
                                font_regular
                                mb={5}
                                color={Color.SECONDARY_TEXT_COLOR}
                                numberOfLines={2}>
                                {item.OppdragNo}
                            </Label>
                        </View>
                        <View style={{ alignSelf: 'center' }}>
                            <Icon name={'drop-normal'} color={Color.SECONDARY_TEXT_COLOR}
                            />
                        </View>
                    </View>

                </Ripple>
                <Hr />
            </>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.search}
                navigation={IS_WEB ? history : navigation}
                initialMarginLeft={10}
                animatedValue={scrollY} />
            <Animated.ScrollView scrollEventThrottle={1}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                    { useNativeDriver: true })}>
                <View>
                    <View style={styles.cont}>

                    </View>
                    <View style={styles.SearchMainView}>
                        <View style={styles.ContainView}>
                            <View style={styles.IconViews}>
                                <View style={styles.IconViews}>
                                    <Icon name={'search'} color={Color.PRIMARY} size={ThemeUtils.fontSmall} />
                                </View>

                                <TextInput style={[styles.Textinput,IS_WEB?{outline:'none'}:null]} placeholderTextColor={Color.PRIMARY} placeholder={Strings.search} />
                            </View>
                            <View style={styles.closeIconView}>
                                <Icon name={'close'} size={ThemeUtils.fontXSmall} style={{ marginLeft: 40 }} color={Color.PRIMARY} align={'right'} />
                            </View>
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}> {PRO_SEARCH.map(Property_Search)}</View>
                </View>
            </Animated.ScrollView>
        </View>
    )
}
