import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    imgOverlay: {
        top: 0,
        bottom: 0,
        start: 0,
        end: 0,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: Color.PRIMARY_LIGHT
    },
    imgNews: {
        flex:1,
        height: ThemeUtils.relativeRealWidth(50),
        backgroundColor: Color.GRAY
    },
    descCont:{
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom:15
    }
})
