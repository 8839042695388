export default {
  'component.b2cservice.title': 'Tjenester / Utlegg',
  'component.b2cservice.addnewservice': 'Legg til tjeneste/produkt',
  'component.b2cservice.variableutlegg.title': 'Eiendomsavhengige utlegg',
  'component.b2cservice.deleteservice': 'Slette tjeneste',
  'component.b2cservice.deleteservicedesc': 'Er du sikker på at du vil slette denne tjenesten?',
  'component.b2cservice.addservice': 'Legg til utlegg',
  'component.b2cservice.variableutlegg.searchplaceholder': 'Søk etter utlegg navn og utlegg type',
  'component.b2cservice.marketingpackage': 'Markedspakker',
  'component.b2cservice.marketingpackagegallery': 'Markedsføring galleri',
  'component.b2cservice.otherservices':'Other Services',
  'component.b2cservice.addmarketingpackage': 'Legg til markedspakker',
  'component.b2cservice.editmarketingpackage': 'Rediger tjeneste',
};
