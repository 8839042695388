import React from 'react';
import {Animated, View} from 'react-native'
import PropTypes from 'prop-types';

import {Label, Ripple} from 'components/src/components'
import {Color, CommonStyle, Icon} from 'components/src/utils';
import styles from './styles';

const AnimatedIcon = Animated.createAnimatedComponent(Icon);

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            isClose: !props.isExpanded,
            isExpanded: props.isExpanded
        };
        this.animatedHeight = new Animated.Value(props.isExpanded ? undefined : null)
        this.animatedValue = new Animated.Value(0)
    }

    componentDidMount() {
        this.hiddenView && setTimeout(() => {
            this?.hiddenView?.measure((ox, oy, width, height, px, py) => {
                // Sets content height in state

                this.setState({
                    height: height,
                })
            })
        },1000)
    }

    toggleAnimation = () => {
        let {isExpanded} = this.state;
        this.setState(prevState => ({
            isClose: !prevState.isClose,
        }), () => {
            if (this.state.isExpanded) {
                this.animatedHeight = new Animated.Value(this.state.height)
                this.setState({
                    isExpanded: false
                }, () => {
                    Animated.parallel([
                        Animated.timing(this.animatedHeight, {
                            toValue: this.state.isClose ? 0 : this.state.height,
                            duration: 150
                        }),
                        Animated.timing(this.animatedValue, {
                            toValue: this.state.isClose ? 0 : 100,
                            duration: 150
                        })
                    ]).start()
                })
            } else {
                console.log(isExpanded, this.state.isClose)

                Animated.parallel([
                    Animated.timing(this.animatedHeight, {
                        toValue: this.state.isClose ? 0 : this.state.height,
                        duration: 150
                    }),
                    Animated.timing(this.animatedValue, {
                        toValue: this.state.isClose ? 0 : 100,
                        duration: 150
                    })
                ]).start()
            }

        })
    };

    getRotation = () => {
        return this.animatedValue.interpolate({
            inputRange: [0, 100],
            outputRange: ['0deg', '90deg']
        })
    };

    render() {
        const {
            renderHeader,
            renderContent,
            contentText,
            headerText,
            contentContainerStyle,
            headerContainerStyle,
            style,
            iconColor,
            tintColor,
        } = this.props;

        const {isExpanded} = this.state;

        const rotation = this.getRotation();
        return (
            <View style={style}>
                <Ripple style={[styles.accordionCont, headerContainerStyle]}
                        rippleContainerBorderRadius={4}
                        onPress={this.toggleAnimation}>

                    <View style={styles.titleCont}>
                        {renderHeader ? renderHeader() : (<Label small
                                                                 font_medium
                                                                 style={CommonStyle.flex}>
                            {headerText}
                        </Label>)}
                        <AnimatedIcon name={'drop-normal'}
                                      size={12}
                                      style={{transform: [{rotate: isExpanded ? '90deg' : rotation}]}}
                                      color={iconColor}/>
                    </View>
                </Ripple>

                <Animated.View style={[styles.container, this.state.height ? {
                    height: this.state.isExpanded ? undefined : this.animatedHeight,
                } : {
                    opacity: 0
                }]}>
                    <View ref={ref => this.hiddenView = ref}
                          style={[styles.contentCont, contentContainerStyle]}>
                        {renderContent ? renderContent() : (
                            <Label small
                                   font_regular
                                   mt={15}
                                   mb={15}
                                   color={tintColor}>
                                {contentText}
                            </Label>)}
                    </View>
                </Animated.View>
            </View>
        )
    }
}

Accordion.propTypes = {
    renderHeader: PropTypes.func,
    renderContent: PropTypes.func,
    contentText: PropTypes.string,
    headerText: PropTypes.string,
    iconColor: PropTypes.string,
    contentContainerStyle: PropTypes.style,
    headerContainerStyle: PropTypes.style,
    isExpanded: PropTypes.bool

}
Accordion.defaultProps = {
    iconColor: Color.PRIMARY,
    tintColor: Color.WHITE
};

export default Accordion;
