import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {Color, CommonStyle, Icon, ThemeUtils} from "components/src/utils";
import {connect} from "react-redux";
import styles from "../../../../../../../../components/ui/RadioGroup/styles";


const OPTIONS_1 = [
    {name: 'Hvilket inntrykk har dere av de ulike meglerne dere møtte?', value: 1},
    {name: 'Gjorde alle en like god jobb eller var det noe som var mindre bra?', value: 2},
];

function SecondQuestion(props) {
    const {onChangeAnswer, fromPrev, setForm, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();

    const [bra, setBra] = useState();

    useEffect(() => {
        props.onAnswer(answersSec)
    }, [answersSec])

    const getQuestion = () => {
        switch (fromPrev) {
            case 1:
                return "Gratulerer! Hvordan foregikk den prosessen?";
            case 2:
                return "Var dere lenge på jakt før dere fant «drømmeboligen»?";
            case 3:
                return "Fordelen med det er jo at dere da vet nøyaktig hva dere har i budsjett, men hva gjør dere om dere må overlevere boligen deres før dere har funnet en ny?";
            case 4:
                return "Har dere vært på (mange) visninger?";

        }
    }
    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {getQuestion()}
            </Label>
            {fromPrev === 1 ? (
                <View>
                    <TouchableOpacity activeOpacity={1}
                                      style={{marginVertical: 10}}
                                      onPress={() => setBra('Yes')}>
                        <View style={[styles.container]}>
                            <Icon name={bra === 'Yes' ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={bra === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Bra'}
                            </Label>
                        </View>
                    </TouchableOpacity>
                    {bra === "Yes" ? (
                        <Label ml={ThemeUtils.relativeWidth(10)}>
                            {"Hva gikk bra?"}
                        </Label>) : null}
                    <TouchableOpacity activeOpacity={1}
                                      style={{marginVertical: 10}}
                                      onPress={() => setBra('No')}>
                        <View style={[styles.container]}>
                            <Icon name={bra === 'No' ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={bra === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'- Vi var ikke helt fornøyd'}
                            </Label>
                        </View>
                    </TouchableOpacity>
                    {bra === "No" ? (
                        <Label ml={ThemeUtils.relativeWidth(10)}>
                            {"- Hva var det som ikke var bra?"}
                        </Label>) : null}
                </View>
            ) : null}
            {fromPrev === 4 || fromPrev === 2 ? (
                <>
                    <TouchableOpacity activeOpacity={1}
                                      style={{marginVertical: 10}}
                                      onPress={() => setAnswerFirst('Yes')}>
                        <View style={[styles.container]}>
                            <Icon name={answers === 'Yes' ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={answers === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Ja'}
                            </Label>
                        </View>
                    </TouchableOpacity>

                    {answers === 'Yes' ? (<RadioGroup options={OPTIONS_1}
                                                      style={{
                                                          marginVertical: 10,
                                                          marginStart: ThemeUtils.relativeWidth(10)
                                                      }}
                                                      textStyle={CommonStyle.flex}
                                                      labelProps={{
                                                          large: true
                                                      }}
                                                      onSelectOption={setAnswer}/>) : null}

                    <TouchableOpacity activeOpacity={1}
                                      style={{marginVertical: 10}}
                                      onPress={() => setAnswerFirst('No')}>
                        <View style={[styles.container]}>
                            <Icon name={answers === 'No' ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={answers === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Nei'}
                            </Label>
                        </View>
                    </TouchableOpacity>
                </>
            ) : null}
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(SecondQuestion)
