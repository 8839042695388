import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Label,} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
//Network
import {useQuery} from "@apollo/react-hooks";
import {STYLING_DETAILS} from "components/src/api/query";
//Utils
import {Color, CommonStyle, DateUtils, decryptId, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
import {connect} from "react-redux";


function Styling(props) {
    const {navigation, user, history} = props;
    const scrollY = new Animated.Value(0)
    const propertyId = IS_WEB ? props.history.location?.state?.id ??parseInt(decryptId(props.match.params.id),10) : props.navigation.getParam('id');

    const {loading, data, error} = useQuery(STYLING_DETAILS, {
        variables: {
            propertyId: propertyId
        }
    })

    let stylistData = data?.moovin_stylist_details ?? {}
    return (
        <Provider locale={enUS}>
            <Header animatedTitle={Strings.styling}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'styling'}
                                color={Color.PRIMARY_DARK}/>
                        </View>
                        <Label small font_regular mt={18}>
                            {Strings.stylingInfo}
                        </Label>
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (<View>
                        {data?.moovin_stylist_details ? (
                                <View style={styles.topContainer}>
                                    <Label small font_medium mt={23}>
                                        {Strings.oppDragNo}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {stylistData?.property?.oppdrags_nummer}
                                    </Label>
                                    <Label small font_medium mt={25}>
                                        {Strings.sellerName}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {`${stylistData?.property?.seller?.first_name ?? ''} ${stylistData?.property?.seller?.last_name ?? ''}`}
                                    </Label>
                                    <Label small font_medium mt={25}>
                                        {Strings.Email}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {stylistData?.property?.seller?.email}
                                    </Label>
                                    <Label small font_medium mt={25}>
                                        {Strings.Phone}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {stylistData?.property?.seller?.mobile ?? '-'}
                                    </Label>
                                    <Label small font_medium mt={25}>
                                        {Strings.styleType}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {stylistData?.styling_type?.name}
                                    </Label>

                                    <Label small font_medium mt={25}>
                                        {Strings.Date}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {stylistData.order_date ? moment(stylistData?.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : ''}
                                    </Label>
                                    <Label small font_medium mt={25}>
                                        {Strings.Time}
                                    </Label>
                                    {stylistData?.order_status === "Ordered" ?
                                        (
                                            <Label small
                                                   mt={8}>
                                                {`${moment(stylistData.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(stylistData?.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                                            </Label>) : (
                                            <Label small
                                                   mt={8}>
                                                {moment(stylistData.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                            </Label>
                                        )}


                                    <Label small font_medium mt={25}>
                                        {Strings.inspectionAgent}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {`${user?.first_name ?? ''} ${user?.last_name ?? ''}`}
                                    </Label>


                                    <Label small font_medium mt={25}>
                                        {Strings.propertyAddress}
                                    </Label>
                                    <Label small font_regular mt={8}>
                                        {`${stylistData?.property?.street_adress??""} ${stylistData?.property?.city??""} ${stylistData?.property?.zipcode??""}`}
                                    </Label>
                                    {stylistData?.comment ? (
                                        <Label small font_medium mt={25}>
                                            {Strings.Comments}
                                        </Label>) : null}
                                    <Label small font_regular mt={8} mb={35}>
                                        {stylistData?.comment}
                                    </Label>
                                </View>) :
                            (
                                <Label align={'center'}
                                       style={CommonStyle.loader}
                                       font_medium>
                                    {'Ingen data'}
                                </Label>
                            )}
                    </View>)}
                </View>

            </Animated.ScrollView>
        </Provider>);
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(Styling)
