import React from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {CustomButton, Header, Label} from "components/src/components";
//Third party
//redux
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {PROPERTY_ORDER_DETAILS, PROPERTY_RATE} from "../../../../../../api/query";
import moment from "moment";
// import {Button, InputrateData} from "@ant-design/react-native";
// import { Radio, WhiteSpace } from '@ant-design/react-native';

// import { DatePickerView } from 'antd-mobile';


export default function Rate(props) {
    const {navigation, history} = props;
    const propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');
    const scrollY = new Animated.Value(0)

    let {loading, error, data} = useQuery(PROPERTY_RATE, {
        variables: {
            propertyId: propertyId
        }
    })

    let orderRateQuery = useQuery(PROPERTY_ORDER_DETAILS, {
        variables: {
            id: propertyId
        }
    })


    const rateData = data?.moovin_property_rate ?? null
    const orderRateData = orderRateQuery?.data?.property?.orderrate ?? null
    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.rate}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>

                    <View style={styles.topContainer}>
                        <View style={styles.viewicon}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}

                                name={'rate'}
                                color={Color.PRIMARY_DARK}/>

                        </View>
                        <Label small font_regular mt={18}
                               mb={16}>
                            {"Her finner du takstinformasjonen. Takstdokumentet er lastet direkte opp i meglersystemet."}
                        </Label>

                        {(loading && !data) || orderRateQuery?.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : rateData ? (
                            <View>
                                <View>
                                    <Label
                                        mb={18}
                                        small
                                        font_regular
                                        align={'left'}>
                                        {rateData.text}
                                    </Label>

                                    <View style={styles.mainviewdata}>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.taxDate}
                                            </Label>
                                            <Label
                                                small
                                                font_regular
                                                align={'left'}>
                                                {rateData.tax_date ? moment(rateData.tax_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : ''}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.valueRate}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   align={'left'}>
                                                {parseFloat(rateData.value_rate).toString()}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.loanRate}
                                            </Label>
                                            <Label
                                                small
                                                font_regular
                                                align={'left'}>
                                                {parseFloat(rateData.loan_rate).toString()}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.bta}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   align={'left'}>
                                                {rateData.bta}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.bra}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   align={'left'}>
                                                {rateData.bra}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.pRom}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   align={'left'}>
                                                {rateData.p_rom}
                                            </Label>
                                        </View>
                                        <View style={styles.subviewdata}>
                                            <Label small
                                                   font_medium>
                                                {Strings.constructionYear}
                                            </Label>
                                            <Label small
                                                   font_regular
                                                   align={'left'}>
                                                {rateData.construction_year}
                                            </Label>
                                        </View>

                                    </View>
                                </View>

                                <CustomButton onPress={() => {
                                    openInNewTab(`${Constants.APIConfig.STORAGE_URL}${rateData?.document?.doc_url}`)
                                }}
                                              mt={45}
                                              disabled={!rateData?.document?.doc_url}
                                              font_medium
                                              large
                                              title={Strings.downloadPDFButton}/>
                            </View>
                        ) : orderRateData ? (
                            <View>


                                <View style={styles.mainviewdata}>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.date}
                                        </Label>
                                        <Label small
                                               font_regular
                                               align={'left'}>
                                            {moment(orderRateData?.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.startTime}
                                        </Label>
                                        <Label
                                            small
                                            font_regular
                                            align={'left'}>
                                            {moment(orderRateData?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>
                                    <View style={styles.subviewdata}>
                                        <Label small
                                               font_medium>
                                            {Strings.endTime}
                                        </Label>
                                        <Label small
                                               font_regular

                                               align={'left'}>
                                            {moment(orderRateData?.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                                        </Label>
                                    </View>
                                </View>
                                <View style={styles.commentview}>

                                    <Label small
                                           mb={8}
                                           font_medium
                                           align={'left'}>
                                        {Strings.Comments}
                                    </Label>
                                </View>
                                <Label
                                    mt={5}
                                    mb={5}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {orderRateData?.comment}
                                </Label>
                            </View>
                        ) : (
                            <Label font_medium
                                   align={'center'}
                                   style={CommonStyle.loader}>
                                {"Ingen data"}
                            </Label>
                        )}

                    </View>


                </View>


            </Animated.ScrollView>
        </View>

    )
}
