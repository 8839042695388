import React, {useState} from 'react'
import {View} from 'react-native'
import {Header, LogoForHeader, Notes} from "components/src/components";
import {Color, CommonStyle, IS_WEB, Routes, ThemeUtils} from "components/src/utils";
import styles from './styles';
import BrokerMobile from './Mobile';
import BrokerTab from './Tab';


function Broker(props) {
    const {history, navigation} = props;
    const [visible, setVisible] = useState(false)

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)

                       setVisible(false)
                   }}/>

        )
    }

    return (
        <View style={[styles.container, {overflow: 'hidden',}]}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY}

                    navigation={IS_WEB ? history : navigation}/>
            <View style={CommonStyle.flex}>
                {ThemeUtils.isTablet() ? <BrokerTab {...props}/> : <BrokerMobile {...props}/>}
            </View>
        </View>

    )
}

export default Broker
