import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Clipboard, View} from 'react-native';
import {CustomButton, Header, InputField, Label, Ripple} from "components/src/components";
//Third Party
import moment from 'moment-timezone'
//Network
import {useMutation, useQuery} from '@apollo/react-hooks'
import {ADD_DS_ASSIGNMENT} from 'components/src/api/mutation';
import {DIGITAL_ASSIGNMENT_DETAILS} from 'components/src/api/query';
//Utils
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';


export default function DSAssignment(props) {
    const {history, navigation} = props;
    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');

    const [link, setLink] = useState('')
    const [dsData, setDsData] = useState({})
    const scrollY = new Animated.Value(0);

    const {loading, data, error} = useQuery(DIGITAL_ASSIGNMENT_DETAILS, {
        variables: {
            propertyId: propertyId,
            status: 1
        }
    });

    const [adDsDetails, addDsDetailsMutation] = useMutation(ADD_DS_ASSIGNMENT)

    useEffect(() => {
        if (data?.ds_assignment_detail) {
            setDsData(data?.ds_assignment_detail)
            setLink(data?.ds_assignment_detail?.url)
        }
    }, [data])

    useEffect(() => {
        if (data?.add_digital_sales_assi) {
            setdsData(data?.add_digital_sales_assi);
        }
    }, [addDsDetailsMutation.data])

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.dsAssignment}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <Icon name={'digital-sales-assignment'}
                          color={Color.PRIMARY}
                          size={ThemeUtils.fontXXLarge}/>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <>
                            <View style={styles.inptCont}>
                                <InputField type={'text'}
                                            value={link}
                                            editable={!dsData.is_approved}
                                            errorEnabled={false}
                                            style={{
                                                flex: 1
                                            }}
                                            onChange={setLink}
                                            labelText={Strings.attachLink}
                                            placeholder={Strings.pAttachLink}
                                            mt={10}
                                            labelProps={{
                                                font_medium: true,
                                                small: true
                                            }}/>
                                <Ripple rippleContainerBorderRadius={4}
                                        onPress={() => {
                                            Clipboard.setString(link)
                                        }}
                                        style={styles.icCont}>
                                    <Icon name={'copy'}
                                          color={Color.PRIMARY_LIGHT}
                                          size={16}/>
                                </Ripple>
                            </View>
                            {!dsData?.is_approved ?? true ?
                                (<CustomButton align={'left'}
                                               mt={ThemeUtils.relativeRealHeight(2)}                                               loading={addDsDetailsMutation.loading}
                                               style={{
                                                   alignSelf: 'flex-start',
                                                   width: ThemeUtils.relativeRealWidth(25)
                                               }}
                                               onPress={() => {
                                                   adDsDetails({
                                                       variables: {
                                                           input: {
                                                               property_id: propertyId,
                                                               url: link
                                                           }
                                                       }
                                                   })
                                               }}
                                               title={Strings.sendButton}/>) : null}
                            {dsData?.is_approved ?
                                (<Label small
                                        color={Color.PRIMARY}
                                        align={'center'}
                                        style={styles.lblAprovedDate}>
                                    {`${Strings.approvedOn} ${moment.utc(dsData.approved_date).format(DateUtils.hh_mm_dd_mm_yyy)}`}
                                </Label>) : null}
                            {dsData?.digital_sales_assi_comment?.length ? (
                                    <>
                                        <Label small
                                               font_medium
                                               mt={15}
                                               color={Color.PRIMARY}>
                                            {Strings.commentsFromSeller}
                                        </Label>
                                        {dsData.digital_sales_assi_comment.map(item => (
                                            <>

                                                <Label small
                                                       mt={15}
                                                       color={Color.PRIMARY}>
                                                    {item.comment}
                                                </Label>
                                                {item?.created_at ? (<Label small
                                                                            font_medium
                                                                            mt={10}
                                                                            color={Color.DARK_GRAY}>
                                                    {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                                </Label>) : null}
                                            </>
                                        ))}
                                    </>)

                                : null}

                        </>
                    )}

                </View>
            </Animated.ScrollView>
        </View>
    )
}
