import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from '../../../../../utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleContainer: {
        marginTop: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    optionContainer: {
        marginVertical: 15,
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    iconView: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dividerLine: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },

    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: ThemeUtils.relativeRealHeight(2),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    radio: {
        flexDirection: 'row',
        marginVertical: 10
    },
    SubViewIcon: {
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(180),
        width: ThemeUtils.responsiveScale(180),
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start'
    },
    img:{
        height: ThemeUtils.responsiveScale(180),
        width: ThemeUtils.responsiveScale(180),
        borderRadius: 4,
        borderWidth:0.8,
        borderColor:Color.PRIMARY_LIGHT

    },
    imgThumb: {
        height: ThemeUtils.responsiveScale(75),
        width: ThemeUtils.responsiveScale(75),
        backgroundColor:Color.GRAY,
        borderRadius: 10,
    },

});

export default styles;
