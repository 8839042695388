import React, {useEffect} from 'react';

import {View} from 'react-native';
import AppRoot from './router'
import {useHistory, useLocation} from "react-router-dom";
import {Provider} from 'react-redux';
import {persistor, store} from 'components/src/redux/store';
import {PersistGate} from 'redux-persist/integration/react'
import {ApolloProvider} from "@apollo/react-components";
import client from "components/src/api";
import './main.scss'

import {IS_WEB, Routes, ThemeUtils} from "components/src/utils";
import Cookie from 'js-cookie'
import {setUser} from "components/src/redux/action";
import 'antd/dist/antd.css';
import './utils.scss'
import './defaultstyles.scss'
import './variable.scss'
import './app.scss'
import './layout.scss'
import './form.scss'
import Toast from "components/src/components/ui/Toast";

let befaringPaths = [
    '/Velkommen',
    '/befaring/om-agent',
    '/befaring/start-inspection',
    '/befaring/bypå',
    '/befaring/oppdragsavtale1',
    '/befaring/oppdragsavtale2',
    '/befaring'
]

function usePageViews(props) {


    let nonAuthPath = [
        `/megler/${Routes.SignIn}`,
        '/Velkommen',
        '/megler/Velkommen',
        '/befaring/om-agent',
        '/megler/befaring/om-agent',
        '/befaring/start-inspection',
        '/megler/befaring/start-inspection',
        '/befaring/bypå',
        '/megler/befaring/bypå',
        '/befaring/oppdragsavtale1',
        '/megler/befaring/oppdragsavtale1',
        '/befaring/oppdragsavtale2',
        '/megler/befaring/oppdragsavtale2',
    ];


    let location = useLocation();

    let history = useHistory();


    React.useEffect(() => {
        console.log(location, history, "LOCATION-=-=-=-=-=--")
        let user = store.getState().user
        if (!user && IS_WEB) {
            try {
                if (Cookie.get("Agent_user")) {
                    user = JSON.parse(Cookie.get("Agent_user"))?.user;
                    store.dispatch(setUser(user))
                }
            } catch (e) {

            }
        }

        let isNonAuthPath = nonAuthPath.find(item => location.pathname.startsWith(item))
        let isBefaringPath = befaringPaths.find(item => location.pathname.startsWith(item) || location.pathname.startsWith(`/megler${item}`))
        if (store.getState().token || Cookie.get('Agent_token')) {
            console.log(isNonAuthPath, isBefaringPath)
            if ((isNonAuthPath && !isBefaringPath) || location.pathname === "/megler" || location.pathname === "/megler/") {
                history.replace(`/megler/${Routes.Super_User_Dashboard}`)
            }
        } else if (!isNonAuthPath) {
            history.replace(`/megler/${Routes.SignIn}`, {
                route: location.pathname.split('/')[2] ? location.pathname.split('/').slice().pop() : null
            })
        }
    }, [history, location]);
}

function App(props) {
    usePageViews()
    let location = useLocation();
    useEffect(() => {
        console.log(befaringPaths.find(item => location.pathname?.startsWith(`/megler${item}`) || location.pathname?.startsWith(item)),"MEGELER")
        if (befaringPaths.find(item => location.pathname?.startsWith(`/megler${item}`) || location.pathname?.startsWith(item))) {
            document.getElementById('root').classList.add('full-height');
            document.body.classList.add('body-scroll');
            return () => {
                document.getElementById('root').classList.remove('full-height');
                document.body.classList.remove('body-scroll');
            }
        } else {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null}
                         persistor={persistor}>
                <ApolloProvider client={client}>
                    <View style={{flex: 1}}>
                        {/*<BrowserRouter>*/}
                        {AppRoot}
                        <Toast ref={r =>Toast.setRef(r)}/>
                        {/*</BrowserRouter>*/}
                    </View>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    )

}

export default App;
