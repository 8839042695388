import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {CommonStyle} from "components/src/utils";
import {connect} from "react-redux";


const QUESTIONS = [{
    id: 1,
    name: "Vi har kjøpt ny bolig og skal nå selge"
}, {
    id: 2,
    name: "Vi skal selge først og deretter kjøpe noe nytt"
}];

const OPTIONS_1 = [
    {name: 'Det gikk bra', value: 1},
    {name: 'Nei', value: 2},
];

function FirstQuestion(props) {
    const {onChangeAnswer, fromStart, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers]);

    const handleChangeText = (text, id) => {
        let updatedAnswer = {...answers};
        updatedAnswer[id] = text;
        setAnswerFirst(updatedAnswer)
    };


    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {"Hvordan gikk det?"}
            </Label>

            <RadioGroup options={OPTIONS_1}
                        style={{marginVertical: 10}}
                        textStyle={CommonStyle.flex}
                        labelProps={{
                            large: true
                        }}
                        onSelectOption={setAnswerFirst}/>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstQuestion)
