import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Attachment, CustomButton, FilePicker, FileType, Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Utils
import {
    Color,
    CommonStyle,
    Constants, decryptId,
    IS_WEB,
    openInNewTab,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PERSONAL_TEMPLATE_DETAILS} from "components/src/api/query";
import {SAVE_PERSONAL_TEMPLATE_SERVICE} from "components/src/api/mutation";
import {connect} from "react-redux";

const scrollY = new Animated.Value(0);

const AddVideo = props => {

    const {history, user, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.PhotoStyling) : navigation.goBack();
    }
    const [service, setService] = useState([]);

    const [video, setVideoFile] = useState([]);

    const [saveServiceDetails, saveDetailsMutation] = useMutation(SAVE_PERSONAL_TEMPLATE_SERVICE);

    const {loading, data, error} = useQuery(PERSONAL_TEMPLATE_DETAILS, {
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
            status: 1,
            agentId: user?.id
        }
    });


    useEffect(() => {
        if (data?.personal_template_details) {
            setService(data?.personal_template_details)
        }
    }, [data]);



    const validate = () => {

        if (video.length) {
            saveServiceDetails({
                variables: {
                    type: "VIDEO",
                    templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                    video: video[0],
                }
            }).then((data) => {
                showMessage(data?.data?.personal_update_office_template?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        } else {
            IS_WEB ? history.goBack() : navigation.goBack();
        }
    };


    const renderDroneDetails = () => {
        return (
            <>

                <Label small
                       font_medium
                       mb={10}>
                    {Strings.uploadVideo}
                </Label>

                <FilePicker type={FileType.VIDEO}
                            onFilePicked={(data, file) => {
                                if (file.length) {
                                    setVideoFile(file)
                                }
                            }}/>

                {video?.map(item => (
                    <Attachment style={{marginVertical: 10}}
                                name={item.name}
                                textColor={Color.PRIMARY}

                                onPressDelete={() => {
                                    setVideoFile([])
                                }}
                                isDeletable/>
                ))}

                {!video.length && service?.map(item => (
                    <Attachment style={{marginVertical: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}
                                isDownloadable
                                name={serviceFromPage.id===8?"W Ads film lastet opp":"SoMe film lastet opp"}                                textColor={Color.PRIMARY}
                                onPressDelete={() => {
                                    console.log("Attachment Delete Pressed");
                                }}
                                onPressDownload={() => {
                                    openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.media_url}`)
                                }}
                                isDeletable={false}/>
                ))}


            </>
        );
    };

    return (
        <Provider locale={enUS}>

            <Header animatedTitle={serviceFromPage?.name}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedValue={scrollY}
                    navigation={IS_WEB ? history : navigation}/>

            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>

                        <Label
                            mt={ThemeUtils.relativeRealHeight(6)}
                            small
                            align={'left'}>
                            {/*{serviceFromPage?.office_template?.description}*/}
                        </Label>

                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={styles.orderCont}>
                            {renderDroneDetails()}
                        </View>)}
                </View>
            </Animated.ScrollView>
            <View style={styles.bottomButtonContainer}>
                <CustomButton loading={saveDetailsMutation.loading}
                              style={{flex: 0.45}}
                              title={Strings.save}
                              onPress={validate}/>
                <CustomButton bgColor={Color.SECONDARY}
                              style={{flex: 0.45}}
                              title={Strings.cancel}
                              onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}/>
            </View>
        </Provider>
    )

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(AddVideo);
