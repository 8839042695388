import React, { useEffect, useState } from 'react';
import { Animated, View } from 'react-native'
//Components
import { CustomButton, FilePicker, FileType, Header, InputField, Label, Ripple } from "components/src/components";
//Third party
//Utils
import { Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils } from "components/src/utils";
import styles from './styles';
import { useMutation } from "@apollo/react-hooks";
import { UPLOAD_MARKET_MATERIAL } from "components/src/api/mutation";
import {PROPERTY_MARKETING_MATERIAL} from "components/src/api/query";


function UploadDoc(props) {
    const { history, navigation } = props;

    const [fileName, setFileName] = useState('');
    const [files, setFiles] = useState([]);
    const scrollY = new Animated.Value(0)

    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');

    const [uploadDoc, uploadDocMutation] = useMutation(UPLOAD_MARKET_MATERIAL, {
        refetchQueries:[{
            query:PROPERTY_MARKETING_MATERIAL,
            variables: {
                id: propertyId,
                status:1
            }
        }]
    });

    useEffect(() => {
        files.length && files[0] && !fileName && setFileName(files[0].name.split('.')[0])
    }, [files])

    const onFilePicked = (data, file) => {
        file && setFiles(file)
    };

    const renderFiles = (item, index) => {
        return (
            <View style={styles.materialCont}>
                <Icon name={'marketing-material-print'}
                    color={Color.PRIMARY}
                    size={ThemeUtils.fontSmall} />
                <View style={styles.lblCont}>
                    <Label font_medium
                        small>
                        {item.name}
                    </Label>

                </View>
               {/*  <Ripple rippleContainerBorderRadius={14}
                    style={styles.icFiles}>
                    <Icon name={'download'}
                        color={Color.PRIMARY_LIGHT}
                        size={14} />
                </Ripple>
                <Ripple rippleContainerBorderRadius={14}
                    style={styles.icFiles}>
                    <Icon name={'delete'}
                        color={Color.PRIMARY_LIGHT}
                        size={14} />
                </Ripple> */}
            </View>
        )
    }
    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.uploadDoc}
                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                navigation={IS_WEB ? history : navigation}
                animatedValue={scrollY} />
            <View style={CommonStyle.flex}>
                <Animated.ScrollView scrollEventThrottle={1}
                    contentContainerStyle={styles.scrollCont}
                    onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                        { useNativeDriver: true })}>
                    <View style={styles.contentCont}>
                        <InputField
                            labelText={Strings.docTitle}
                            mt={ThemeUtils.relativeRealHeight(6)}
                            type={'email'}
                            placeholder={Strings.docTitle}
                            value={fileName}
                            onChange={setFileName}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }} />
                        <Label small
                            font_medium
                            mb={10}>
                            {Strings.uploadDoc}
                        </Label>
                        <FilePicker type={FileType.PDF}
                            onFilePicked={onFilePicked} />
                        {files.map(renderFiles)}
                    </View>
                </Animated.ScrollView>
            </View>
            <View style={styles.btnCont}>
                <CustomButton title={Strings.save}
                    loading={uploadDocMutation.loading}
                    onPress={() => {
                        uploadDoc({
                            variables: {
                                file: files,
                                title: fileName,
                                propertyId: propertyId
                            },
                        }).then(() => IS_WEB ? history.goBack() : navigation.goBack()).catch(() => {

                        })
                    }}
                    mr={5}
                    style={{ flex: 1 }} />
                <CustomButton onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    title={Strings.cancel}
                    bgColor={Color.WHITE}
                    textColor={Color.PRIMARY}
                    borderColor={Color.PRIMARY}
                    borderWidth={1}
                    ml={5}
                    style={{ flex: 1 }} />
            </View>

        </View>
    )
}

export default UploadDoc;
