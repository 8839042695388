import { StyleSheet } from 'react-native';
import { ThemeUtils, Color } from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,

    },
    contentCont: {
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    },
    titleCont: {
        marginTop: 5,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    displayTimeCont: {
        flex: 1,
        marginHorizontal:5,
        flexDirection: 'row'
    },
    displayContentCont: {
        flex: 1,
        flexDirection: 'row'
    },
    accordionHeader: {
        marginBottom: 0,
        backgroundColor: Color.WHITE,
        borderRadius: 0
    },
    accordionContent: {
        marginTop: 0,
        paddingVertical: 2,
        backgroundColor: Color.WHITE,
        borderRadius: 0
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    dataMainView:{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',marginTop:8 },
    dataSubView:{ alignSelf: 'center', alignItems: 'center', alignContent: 'center',  },



})
