import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label} from "components/src/components";
//Utils
import {connect} from "react-redux";
import {Color, Icon, ThemeUtils} from "../../../../../../../../utils";
import styles from "../../../../../../../../components/ui/RadioGroup/styles";


const OPTIONS_1 = [
    {name: 'Hva var det som var bra?', value: 1},
    {name: 'Var det noe dere synes ikke gikk bra?', value: 2},
];
const OPTIONS_2 = [
    {name: 'Hva var det som var bra?', value: 3},
    {name: 'Hva har dere tenkt å gjøre annerledes nå slik at det ikke skjerigjen?', value: 4},
    {name: 'Hvordan kan dere være sikre på at megleren dere velger ikke gjør det samme denne gang?', value: 5},
];

function SecondQuestion(props) {
    const {onChangeAnswer, fromPrev, setForm, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answerThird, setAnswertThird] = useState();
    const [answerFifth, setFifthQuestion] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers])

    /*useEffect(() => {
        props.onAnswer(answersSec)
    }, [answersSec])*/

    const getQuestion = () => {
        switch (fromPrev) {
            case 1:
                return "Pris – Den letteste metoden for å oppnå et raskt salg er å sette en lav " +
                    "prisantydning som dere også må være villige til å annonsere. Jeg antar selvsagt at " +
                    "dere også ønsker å oppnå den beste prisen?";
            case 2:
                return "Presentasjon – Tenker dere at hvordan boligen presenteres kan være " +
                    "avgjørende?";
            case 3:
                return "Bilder – Tror dere at bildene kan påvirke resultatet?";
            case 4:
                return "Markedsføring – Hvis vi nå har den beste presentasjonen og de beste bildene, hva " +
                    "må vi gjøre for at flest mulig skal få med seg at boligen er til salgs?";
            case 5:
                return "Megler – Tror dere megleren kan være avgjørende for hvor fort boligen deres blir " +
                    "solgt?";
        }
    }

    const renderFirstAnswer = () => {
        return (
            <>
                <TouchableOpacity activeOpacity={1}
                                  style={{
                                      marginVertical: 10,
                                  }}
                                  onPress={() => setAnswertThird('Yes')}>
                    <View style={[styles.container]}>
                        <Icon name={answerThird === 'Yes' ? 'radio-select' : 'radio-normal'}
                              size={ThemeUtils.fontNormal}
                              color={answerThird === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                        <Label large
                               style={[styles.rightText]}>
                            {'Ja'}
                        </Label>
                    </View>
                </TouchableOpacity>

                {answerThird === 'Yes' ? (
                    <Label large
                           ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                        {"Det er samme prinsipper som er gjeldende for å oppnå et raskt " +
                        "salg som for å oppnå den høysete prisen."}
                    </Label>) : null}

                <TouchableOpacity activeOpacity={1}
                                  style={{
                                      marginVertical: 10,
                                  }}
                                  onPress={() => setAnswertThird('No')}>
                    <View style={[styles.container]}>
                        <Icon name={answerThird === 'No' ? 'radio-select' : 'radio-normal'}
                              size={ThemeUtils.fontNormal}
                              color={answerThird === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                        <Label large
                               style={[styles.rightText]}>
                            {'Nei'}
                        </Label>
                    </View>
                </TouchableOpacity>
                {answerThird === 'No' ? (
                    <Label large
                           ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                        {"Det er samme prinsipper som er gjeldende for å oppnå et raskt " +
                        "salg som for å oppnå den høysete prisen."}
                    </Label>) : null}
            </>
        )
    };
    const renderSecondAnswer = () => {
        return (
            <Label large>
                {"- Folk tar avgjørelser basert på følelser – psykologi\n\n" +
                "- Avgjørende for å få de beste bildene"}
            </Label>
        )
    };
    const renderThirdAnswer = () => {
        return (
            <Label large>
                {"- Det viktigste virkemiddelet for at folk skal trykke seg inn på annonsen, det " +
                "være seg på finn.no, sosiale medier, nettsider og avis.\n\n" +
                "- Situasjonen er den at det ligger mange boliger til salgs på finn. De som " +
                "aktivt er på finn.no og ser etter bolig i dette området vil nok få med seg " +
                "annonsen uansett. Vi vet derimot at mange er åpne for å bo ulike steder " +
                "så lenge boligen og området oppfyller kriteriene deres.\n\n" +
                "- Problemet med det er at annonsen deres kan drukne i mengden og man " +
                "kan gå glipp av potensielle budgivere.\n\n" +
                "- Konsekvensen av å miste en budgiver er at det fort kan ende opp med at " +
                "boligen blir liggende ute lenge kan, og igjen tape 100.000 – 150.000,-. Er\n\n" +
                "det mye penger for dere? Ja. Er dere villige til å tape dette? Nei. " +
                "- Ser dere da Nytten av å ha de beste bildene? (Ja, svarer kunden)."}
            </Label>
        )
    };
    const renderFourthAnswer = () => {
        return (
            <>
                <Label large
                       font_medium>
                    {"Hvilke tanker har dere om markedsføring?"}
                </Label>
                <Label large
                       ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                    {"TEGN OG FORKLAR"}
                </Label>
                <View style={{marginStart: ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}}>
                    <Label large>
                        {"- Nå ut til alle potensielle kjøpere"}
                    </Label>
                    <Label large>
                        {"- Hjelper ikke med gode bilder om ingen ser de"}
                    </Label>
                </View>
            </>
        )
    };
    const renderFifthAnswer = () => {
        return (
            <>
                <TouchableOpacity activeOpacity={1}
                                  style={{
                                      marginVertical: 10,
                                  }}
                                  onPress={() => setFifthQuestion('Yes')}>
                    <View style={[styles.container]}>
                        <Icon name={answerFifth === 'Yes' ? 'radio-select' : 'radio-normal'}
                              size={ThemeUtils.fontNormal}
                              color={answerFifth === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                        <Label large
                               style={[styles.rightText]}>
                            {'Ja'}
                        </Label>
                    </View>
                </TouchableOpacity>

                {answerFifth === 'Yes' ? (
                    <>
                        <Label large
                               ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                            {"Hva tenker dere er avgjørende med megler?"}
                        </Label>
                        <View style={{marginStart: ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}}>
                            <Label large>
                                {"- Rådgivning"}
                            </Label>
                            <Label large>
                                {"- Kundekontakt/oppfølging"}
                            </Label>
                            <Label large>
                                {"- Visningsstrategi"}
                            </Label>
                            <Label large>
                                {"- Budrundestrategi"}
                            </Label>
                        </View>
                    </>) : null}

                <TouchableOpacity activeOpacity={1}
                                  style={{
                                      marginVertical: 10,
                                  }}
                                  onPress={() => setFifthQuestion('No')}>
                    <View style={[styles.container]}>
                        <Icon name={answerFifth === 'No' ? 'radio-select' : 'radio-normal'}
                              size={ThemeUtils.fontNormal}
                              color={answerFifth === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                        <Label large
                               style={[styles.rightText]}>
                            {'Nei'}
                        </Label>
                    </View>
                </TouchableOpacity>
                {answerFifth === 'No' ? (
                    <Label large
                           ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                        {"Dere nevnte i sted at dere hadde opplevd store forskjeller på " +
                        "meglere når dere var på boligjakt. Er det riktig at dere likevel " +
                        "mener at det ikke kan påvirke salget?"}
                    </Label>) : null}
            </>
        )
    };
    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}
                   mb={15}>
                {getQuestion()}
            </Label>

            {fromPrev === 1 ? renderFirstAnswer() : null}
            {fromPrev === 2 ? renderSecondAnswer() : null}
            {fromPrev === 3 ? renderThirdAnswer() : null}
            {fromPrev === 4 ? renderFourthAnswer() : null}
            {fromPrev === 5 ? renderFifthAnswer() : null}


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(SecondQuestion)
