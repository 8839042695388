export default {
    //Mobile
    GetStarted: "getStarted",
    GetStartedDashboard: "getStartedDashboard",
    GetStartedProductGallery: "getStartedProductGallery",
    GetStartedBefaringsbekreftelse: "getStartedBefaringsbekreftelse",
    GetStartedAboutW: "getStartedAboutW",
    GetStartedPreparation: "getStartedPreparation",
    GetStartedQuestion: "getStartedQuestion",
    Authenticated: 'Authenticated',
    UnAuthenticated: 'UnAuthenticated',
    SplashScreen: 'SplashScreen',
    SuperUser: 'superUser',

    SignIn: 'login',
    ForgotPassword: 'glemt-passord',
    News: 'nyheter',
    NewsDetails: 'nyhet-detaljer',
    Profile: 'redigerprofil',
    SUProfile: 'suProfile',
    ChangePassword: 'bytt-passord',
    PropertyListing: 'moovin',
    Search: 'Search',
    SignatureAssignment: 'signaturoppgave',
    AssignmentDetails: 'assignmentDetails',
    SignAssignmentSuccess: 'SignAssignmentSuccess',
    AgentDashboard: 'dashboard',
    Rate: 'takst',
    Seller: 'selgersdetaljer',
    Tasks: 'Tasks',
    SUTasks: 'suTasks',
    Professional: 'faglig',
    ProfessionalDetails: 'faglig-detaljer',
    PropertyDashboard: 'oppdrag-ingen-detaljer',
    Marketing: 'markedsforing',
    SeeFinnAd: 'seeFinnAd',
    DSAssignment: 'dsAssignment',
    MarketingMaterials: 'markedsføringsmateriell',
    MarketingMaterialDetails: 'MarketingMaterialDetails',
    UploadDoc: 'LastoppDokument',
    notifications: 'notification',
    NotificationSuccess: 'notificationSuccess',
    Timeline: 'tidslinje',
    InfoFromSeller: 'infofraselger',
    Bids: 'bud',
    Buyer: 'kjøper',
    Styling: 'Styling',
    Photo: 'bilder',
    UploadPhoto: 'UploadPhoto',
    Financing: 'finansiering',
    Views: 'visninger',
    UserDetails: 'UserDetails',
    SellerOrder: 'selgersbestillinger',
    Document: 'dokument',

    Documents: 'Documents',
    UploadDocument: 'UploadDocument',
    Super_User_Dashboard: 'dashboard',
    Befaringsbekreftelse: 'Befaringsbekreftelse',
    AboutW: 'om-w',
    Preparation: 'forberedelser',
    ProductGallery: 'produktgalleri',
    QuestionToAsk: 'spørsmål-å-stille',
    B2BServices: 'b2b-tjenester',
    B2CServices: 'utlegg',
    B2CServiceDetails: 'B2CServiceDetails',
    Agent: 'agent',
    /*-W Befaring*/

    WBefaringDashboard: "befaringsbekreftelse",
    /*Office Template*/
    OfficeTemplate: 'selskapsmal',
    OfficePhotoStyling: 'bilder',
    Office3D: '3d-illustrasjoner',
    OfficePhoto: 'bilder',
    OfficeDrone: 'drone',
    OfficeVideo: 'video',
    OfficeStyling: 'styling',
    Office360View: '360-visning',

    OfficeDigitalMarketing: 'digital-markedsføring',
    OfficeFinnAd: 'finn-no',
    OfficeWAds: 'w-ads',
    OfficeIntroFilm: 'some-film',
    OfficeDS: 'digital-salgsoppgave',
    OfficePrintMarketing: 'print-markedsføring',
    OfficePrintMarketingServiceDetails: 'prospekt',
    OfficeAvisan: 'avisannonsering',
    OfficeDM: 'dm',
    OfficeRomplakater: 'romplakater',
    OfficeTilsalgs: 'til-salgs-plakat',
    OfficeOppdragAgreement: 'oppdragsavtalen-redigerer',
    OfficePropertyImageGallery: 'unike-presentasjoner',

    /*------------------inspection----------------*/
    InspectionApplication: 'inspeksjonsapplikasjoner',
    InspectionPropertyDetails: 'eiendommen-detalj',
    InspectionPhoto: 'bilde',
    InspectionBroker: 'megler',
    InspectionDigitalMarketing: 'digital-markedsføring',
    InspectionPrintingMarketing: 'print-markedsføring',
    StartInspection: 'start-inspeksjonen',
    MissionCost: 'oppdrag-kostnad',
    Boligselgerforsikring: 'boligselgerforsikring',
    InspectionCalendar: 'kalender',
    InspectionPropertyImageList: 'unike-presentasjoner',

    /*Personal Template*/

    PhotoStyling: 'bilde',
    PhotoTemplate: 'rediger-bilde',
    StylingTemplate: 'rediger-styling',
    DegreeVideo: 'rediger-360-graders-utsikt',
    AddVideo: 'rediger-video',
    PrintMarketing: 'print-markedsføring',


    DigitalMarketing: 'digital-markedsføring',
    IntroFilm: 'rediger-digital-markedsføring',
    FinnAd: 'rediger-finnno',
    WBefaring: 'befaring',

    B2CUttleggServices: "variable-utlegg",
    B2CPrintMarketing: "b2c-print-markedsføring",
    B2CDigitalMarketing: "b2c-digital-markedsføring",
    B2CPhotoStyling: "bilder",
    MarketingPackages: "markedspakker",
    NewOtherServices: "otherservices",

    DigitalSalesAssignment: "digital-salgsoppave",
    DigitalSalesAssignmentDetails: "digitalSalesAssignmentDetails",

    Questions: 'questions-for-agent',
    SignAgreement: 'oppdragsavtale1',
    CoOwnerSignAgreement: 'oppdragsavtale2',
    SignSuccess: "gratulerer",
    ClientQuestion: "clientQuestion",
    ChatDetails: "chatDetails",
    Chat: "chat",
    Calendar: "kalender",
    Notifications:"varsler",
    PrintMarketingServiceDetails: 'printMarketingServiceDetails',
    DSHistory:"dshistorikk",

    VendorServicesDetails:"VendorServicesDetails",
    Photographer:"fotografer",
    PhotographerServices:"fotografer",
    Takst:"takst",
    Stylist:"stylist",
    StylistServices:"stylist",
    OrderPhoto:"orderPhoto",
    OrderRate:"orderRate",
    OrderStyling:"orderStyling",
}
