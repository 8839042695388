export default {
    'component.finance.title.line1': 'Få bedre boliglånsbetingelser',
    'component.finance.description':
      'Har du behov for boliglån, eller ønsker å refinansiere for bedre betingelser? La vår samarbeidspartnere gi deg et godt tilbud!',
      'component.finance.option1': 'Nytt boliglån',
      'component.finance.option2': 'Refinansiering',
      'component.finance.option3': 'Jeg samtykker med dette til å bli kontaktet av lånerådgivere for informasjon om boliglån.',
      'component.finance.applicantdetails': 'Søkers detaljer',
      'component.finance.loanamount': 'Lånebeløp',
      'component.finance.loanamountplaceholder': 'Angi lånebeløp',
      'component.finance.coapplicantdetails': 'Informasjon om medsøker',
      'component.loanapplication.applicantTitle': 'Søker',
      'component.loanapplication.coapplicantTitle': 'Medsøker',
  };
  