export const actions = {
    content: 'content',

    updateHeight: 'UPDATE_HEIGHT',

    setBold: 'bold',
    setItalic: 'italic',
    setUnderline: 'underline',
    heading1: 'h1',
    heading2: 'h2',
    heading3: 'h3',
    heading4: 'h4',
    heading5: 'h5',
    heading6: 'h6',
    setParagraph: 'SET_PARAGRAPH',
    removeFormat: 'REMOVE_FORMAT',
    alignLeft: 'justifyLeft',
    alignCenter: 'justifyCenter',
    alignRight: 'justifyRight',
    alignFull: 'justifyFull',
    insertBulletsList: 'unorderedList',
    insertOrderedList: 'orderedList',
    insertLink: 'link',
    insertImage: 'image',
    setSubscript: 'subscript',
    setSuperscript: 'superscript',
    setStrikethrough: 'strikeThrough',
    setHR: 'horizontalRule',
    setIndent: 'indent',
    setOutdent: 'outdent',
    setTitlePlaceholder: 'SET_TITLE_PLACEHOLDER',
    setContentPlaceholder: 'SET_CONTENT_PLACEHOLDER',
    setTitleFocusHandler: 'SET_TITLE_FOCUS_HANDLER',
    setContentFocusHandler: 'SET_CONTENT_FOCUS_HANDLER',
    prepareInsert: 'PREPARE_INSERT',
    restoreSelection: 'RESTORE_SELECTION',
    setCustomCSS: 'SET_CUSTOM_CSS',
    setTextColor: 'SET_TEXT_COLOR',
    setBackgroundColor: 'SET_BACKGROUND_COLOR',
    init: 'ZSSS_INIT',
    setEditorHeight: 'SET_EDITOR_HEIGHT',
    setFooterHeight: 'SET_FOOTER_HEIGHT',
    setPlatform: 'SET_PLATFORM',
};

export const messages = {
    CONTENT_HTML_RESPONSE: 'CONTENT_HTML_RESPONSE',
    LOG: 'LOG',
    CONTENT_FOCUSED: 'CONTENT_FOCUSED',
    SELECTION_CHANGE: 'SELECTION_CHANGE',
    CONTENT_CHANGE: 'CONTENT_CHANGE',
    SELECTED_TEXT_RESPONSE: 'SELECTED_TEXT_RESPONSE',
    LINK_TOUCHED: 'LINK_TOUCHED',
    SELECTED_TEXT_CHANGED: 'SELECTED_TEXT_CHANGED',
    OFFSET_HEIGHT: 'OFFSET_HEIGHT',
};
