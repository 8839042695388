import React from 'react'
//Components
import { Animated, ScrollView, View, TouchableOpacity } from 'react-native'
import { Header, Label, Ripple, Hr, Dialog } from "components/src/components";

//redux
import { logout, setToken, setUser } from "components/src/redux/action";
import { connect } from "react-redux";
//Utils
import { Color, Icon, IS_WEB, Strings, ThemeUtils, CommonStyle } from "components/src/utils";
import { styles } from './styles.js';

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dailogVisible: false,
            array: [
                {
                    index: 1,
                    title: "Document Name",
                    uploadTo: 'Uploaded to Seller & Buyer',
                },
                {
                    index: 2,
                    title: "Document Name",
                    uploadTo: 'Uploaded to Seller'
                },

            ],


        };
        this.scrollY = new Animated.Value(0)
    }
    DailogToggleModal = () => {
        this.setState({
            dailogVisible: !this.state.dailogVisible,

        })


    }
    buttonclick = () => {
        if (IS_WEB) {
            this.props.history.push('/uploaddocument')
        } else {
            this.props.navigation.navigate('UploadDocument');
        }
    };

    list = () => {
        return this.state.array.map((item, index) => {
            return (
                <View>
                    <Hr />
                    <View style={styles.topContainer}>
                        <View style={styles.documentcontainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'marketing-material-print'}
                                color={Color.PRIMARY_DARK} />
                            <View>
                                <Label small
                                    ml={9}
                                    font_medium
                                    align={'left'}>{item.title}</Label>
                                <Label small
                                    ml={9}
                                    font_regular
                                    align={'left'}>{item.uploadTo}</Label>
                            </View>
                            <View style={styles.iconView}>
                                <TouchableOpacity onPress={this.DailogToggleModal}>

                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'delete'}
                                        color={Color.RED} />

                                </TouchableOpacity>

                            </View>
                        </View>

                    </View>
                    {index === this.state.array.length - 1 ? <Hr /> : null}
                </View>

            );
        });
    };


    render() {
        return (
            <View style={CommonStyle.flex}>
                <Header animatedTitle={Strings.documents}
                    navigation={IS_WEB ? this.props.history : this.props.navigation}
                    animatedValue={this.scrollY} />
                <Animated.ScrollView scrollEventThrottle={1}
                contentContainerStyle={styles.scrollCont}
                    onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: this.scrollY } } }],
                        { useNativeDriver: true })}>

                    <Dialog visible={this.state.dailogVisible}

                        transparent
                        onNegativePress={this.DailogToggleModal}
                        onPositivePress={this.DailogToggleModal}
                        title={`${Strings.DeleteDocument}`}
                        buttonTitleFirst={Strings.cancel}
                        buttonTitleSecond={Strings.deleteButton}
                        color={Color.RED}
                        desc={Strings.AreYouSureDeleteDoc} />
                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'documents'}
                                    color={Color.PRIMARY_DARK} />

                            </View>

                            <Label
                                mt={10}
                                mb={20}
                                font_regular
                                small
                                align={'left'}>
                                {"Last opp dokumenter til kjøper eller selger her. Klikk på last opp dokument og angi et dokument navn og hvem dokumentet skal sendes til."}
                            </Label>



                        </View>
                        <View>{this.list()}</View>

                    </View>
                </Animated.ScrollView>

                <View style={{ justifyContent: 'space-between' }}>

                    <TouchableOpacity onPress={this.buttonclick}>
                        <View style={styles.btnCont}>
                            <Icon name={'upload'} color={Color.TERNARY_TEXT_COLOR} size={ThemeUtils.fontSmall} />
                            <Label small font_regular color={Color.TERNARY_TEXT_COLOR} ml={9}>{Strings.buttonDocument}</Label>
                        </View>
                    </TouchableOpacity>
                </View>




            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);




