import {Linking, Platform} from 'react-native';
import {Color} from '../utils/Color';
import Messages from '../utils/MessageUtils';
import MessageUtils from '../utils/MessageUtils';
import Constants from '../utils/Constants';
import CommonStyle from '../utils/CommonStyles';
import LocalizedStrings from '../utils/Strings';
import ThemeUtils from '../utils/ThemeUtils';
import {PasswordValidate, validation} from '../utils/ValidationUtils';
import Routes from '../../../mobile/src/router/routes';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import fontelloConfig from '../../config';
import DateUtils from './DateUtils'

import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import moment from 'moment'
import Toast from "components/src/components/ui/Toast";

const Icon = createIconSetFromFontello(fontelloConfig);
let Strings = LocalizedStrings.no
const {
    Version,
    OS,
} = Platform;

export const encryptId = id => {
    if(IS_WEB){
        return btoa(btoa(id));
    }
    let base64Id='base-64'
    let base64 =require(`${base64Id}`);
    return base64.encode(base64.encode(id))
}
export const decryptId = id =>{
    if(IS_WEB){
        return atob(atob(id));
    }
    let base64Id='base-64'
    let base64 =require(`${base64Id}`);
    return base64.decode(base64.decode(id))
}


export const IS_ANDROID = OS === 'android';
export const IS_IOS = OS === 'ios';
export const IS_WEB = OS === 'web';
export const IS_LT_LOLLIPOP = Version < 21;

const getBidColor = (status) => {
    switch (status) {
        case 0:
            return Color.TEXT_COLOR_GREEN;
        case 1:
            return Color.TEXT_COLOR_RED
    }
};

const openInNewTab = (url) => {
    if (window && IS_WEB) {
        window.open(url, '_blank')
    } else {
        console.log(url)
        Linking.openURL(url)

    }
}

function roundDate(date, isRounded) {
    if (!isRounded) {
        return date
    }
    return moment(Math.ceil((date) / (moment.duration(15, "minutes"))) * (moment.duration(15, "minutes")));
}

const UserType = {
    Admin: 1, //Admin
    Seller: 2,
    Agent: 3,
    Photographer: 4,
    Takst: 5,
    Stylist: 6,
    Examination: 7, //Book inspector
    Buyer: 8,
}

const getUserType = (user) => {
    switch (user) {

        case Routes.Agent:
            return UserType.Agent;
        default:
            return UserType.Seller
    }
};
const formatPrice = (price) => {
    if (price) {
        return price.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return price
};

const getBidLabel = (status) => {
    switch (status) {
        case 0:
            return Strings.accepted
        case 1:
            return Strings.expired
    }
};

const showMessage = (message, messageType='info', options = {}) => {
    Toast.show({
        type: messageType,
        text1: message,
        visibilityTime: 1000,
    });
};

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'no',
        debug: true,
        resources: {
            en: {
                translation: LocalizedStrings.en,
            },
            no: {
                translation: LocalizedStrings.no,
            },
        },
    });

const getUserString = (userType) => {
    switch (userType) {
        case UserType.Admin:
            return 'Admin';
        case UserType.Seller:
            return 'Selger';
        case UserType.Photographer:
            return 'Fotograf';
        case UserType.Takst:
            return 'Takst';
        case UserType.Stylist:
            return 'Stylist';
        case UserType.Examination:
            return 'befaring';
        case UserType.Buyer:
            return 'Kjøper';
        case UserType.Agent:
            return 'Eiendomsmegler';

    }
}


const add1Day = () => {
    return new Date(moment().add(1, 'd').set({
        hours: 23,
        minutes: 45,
        seconds: 0
    }))
}

const add4Hours = (date,customizedHour=Constants.TIME_GAP) => {
    return new Date(moment(date).add(customizedHour, 'h'))
}



const checkDiff = (date,diffHour=Constants.TIME_GAP) => {

    return moment().add(1, 'd').set({
        hours: 23,
        minutes: 45,
        seconds: 0
    }).diff(moment(date), 'h') < diffHour
}


const getDateTimeForNotification = timestamp => {
    if (timestamp) {
        if (moment().diff(moment(moment(timestamp).format(DateUtils.dd_mm_yyyy), DateUtils.dd_mm_yyyy), "d") === 0) {
            return moment(timestamp).fromNow()
        } else {
            return moment(timestamp).format(DateUtils.dd_mm_yyyy_hh_mm)
        }
    } else {
        return moment().format(DateUtils.dd_mm_yyyy_hh_mm)
    }
};


const NOTIFICATION_TYPE = {
    SELLER_ENERGY_LABELING: 1,
    SELLER_RATE_ORDER: 2,
    SELLER_INSPECTION_ORDER: 3,
    SELLER_STYLING_ORDER: 4,
    SELLER_PHOTO_ORDER: 5,
    SELLER_INTERVIEW: 6,
    SELLER_PHOTO_COMMENT: 7,
    SELLER_RATE_DETAILS_COMMENT: 8,
    PHOTO_ORDER_CONFIRM: 9,
    TAKST_ORDER_CONFIRM: 10,
    INSPECTION_ORDER_CONFIRM: 11,
    PHOTO_UPLOAD: 12,
    RATE_DETAILS_ADDED: 13,
    PHOTO_ORDER_COMPLETED: 14,
    RATE_ORDER_COMPLETED: 15,
    INSPECTION_ORDER_COMPLETED: 16,
    SELLER_ADDED_VIEW: 17,
    AGENT_ADDED_FINN_NO: 18,
    AGENT_ADDED_DS: 19,
    AGENT_ADDED_MARKETING_MATERIAL: 20,
    SELLER_APPROVED_FINN_NO: 21,
    SELLER_APPROVED_DS: 22,
    SELLER_APPROVED_MARKETING_MATERIAL: 23,
    SELLER_ADDED_COMMENT_FINN_NO: 24,
    SELLER_ADDED_COMMENT_DS: 25,
    SELLER_ADDED_COMMENT_MARKETING_MATERIAL: 26,
    AGENT_UPLOADED_DOC_SELLER: 27,
    AGENT_UPLOADED_DOC_BUYER: 28,
    SELLER_ADD_ADDITIONAL_SERVICES: 29,
    ADMIN_ADDED_FEGLING: 30,
    ADMIN_ADDED_NEWS: 31,
    CUSTOM_NOTIFICATION: 32,
    SEND_BY_AGENT: 33,
    SELLER_BOOK_INSPECTION_NOTIFY: 34,
    SELLER_ENERGY_LABELING_NOTIFY: 35,
    SELLER_ORDER_TAKST_NOTIFY: 36,
    SELLER_ORDER_PHOTO_NOTIFY: 37,
    SELLER_SUBMIT_INTERVIEW_NOTIFY: 38,
    SELLER_APPROVE_DS_NOTIFY: 39,
    SELLER_APPROVE_FINN_NOTIFY: 40,
    SELLER_APPROVE_MARKETING_MATERIAL_NOTIFY: 41,
    SELLER_SETTLEMENT_INFO_NOTIFY: 42,
    MOVING_GUIDE_CONTACT_ME_NOTIFY: 43,
    SELLER_SUBMIT_SETTLEMENT_INFO: 44,
    BUYER_REGISTER: 45,
    AGENT_PHOTO_DELIVER: 46,
    AGENT_TAKST_DELIVER: 47,
    INSPECTION_DELIVER: 48,
};
export {
    NOTIFICATION_TYPE,
    getDateTimeForNotification,
    getUserString,
    Color,
    Messages,
    Strings,
    Constants,
    CommonStyle,
    ThemeUtils,
    validation,
    getBidColor,
    getBidLabel,
    PasswordValidate,
    MessageUtils,
    Icon,
    DateUtils,
    Routes,
    showMessage,
    formatPrice,
    getUserType,
    UserType,
    openInNewTab,
    roundDate,
    add1Day,
    checkDiff,
    add4Hours,

};
