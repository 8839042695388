import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {CommonStyle, Constants} from "components/src/utils";
import {connect} from "react-redux";


const QUESTIONS = [{
    name: "Vi har kjøpt ny bolig og skal nå selge",
    value:1
}, {
    name: "Vi skal selge først og deretter kjøpe noe nytt",
    value:2
}];


function FirstQuestion(props) {
    const {onChangeAnswer,  interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers]);


    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {"Kan dere fortelle meg litt om situasjonen deres?"}
            </Label>

            <RadioGroup options={QUESTIONS}
                        style={{marginVertical: 10}}
                        textStyle={CommonStyle.flex}
                        labelProps={{
                            large: true
                        }}
                        onSelectOption={setAnswerFirst}/>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstQuestion)
