export default {
  'component.photos.title': 'Bilde',
  'component.photos.description':
    'Standard fotopakke er inkludert i din fastpris. Her kan du booke tid for fotografen, og også bestille utvidede fototjenester.',
  'component.photos.line1':
    'Angi ønsket vindu for når du vil at fotografen skal besøke deg. Minimumstidsvindu er 4 timer',
  'component.photos.note': 'MERKNAD:',
  'component.photos.notice':
    'Gi oss beskjed om preferansen din i delen Ytterligere tjenester for å fortsette med Bilde eller Ikon!',
  'component.photos.line6.button1': 'Bestill',
  'component.photos.line6.button2': 'Avbryt',
  'component.photo.success': 'Bilde lastet opp',
  'component.photos.oldimage': 'Gammelt bilde',
  'component.photos.newimage': 'Nytt stilbilde',
  'component.photo.replacement': 'Bildet ble erstattet',
  'component.photo.order.success': 'Bestill oppdatert',
};
