import React from 'react'
//Components
import {ActivityIndicator, Animated, Keyboard, TouchableOpacity, View} from 'react-native'
import {CustomButton, DatePicker, Header, InputField, Label, PopOver, RadioGroup} from "components/src/components";
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//ThirdParty
import moment from 'moment'
//Network
import {Mutation, Query} from 'react-apollo';
import {SELLER_STYLING_TYPES} from "components/src/api/query";
import {SELLER_STYLISH_ORDER} from "components/src/api/mutation";
//Utils
import styles from './styles.js';
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    encryptId,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
} from "components/src/utils";


// import {Button, InputItem} from "@ant-design/react-native";


// import { DatePickerView } from 'antd-mobile';


class Styling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fashions: [],
            orderDate: null,
            startTime: null,
            endTime: null,
            selectedFashion: undefined,
            comments: "",
            errComments: "",
            fromView: null,
            isVisible: false,
            desc: "",

        }
        this.propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

        this.scrollY = new Animated.Value(0)
    }

    navigateToThankYou = () => {
        if (IS_WEB) {
            this.props.history.replace(`/${Routes.Styling}/${encryptId(this.propertyId)}`, {
                id: this.propertyId
            })
        } else {
            this.props.navigation.replace(Routes.Styling, {
                id: this.propertyId
            })
        }
    };

    validate = (placeOrder) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        const {orderDate, selectedFashion, comments, startTime, endTime} = this.state;
        if (orderDate) {
            if (startTime) {
                if (endTime) {
                    if (selectedFashion) {
                        if (moment(endTime).diff(moment(startTime), 'h') >= Constants.TIME_GAP) {

                            placeOrder({
                                variables: {
                                    input: {
                                        property_id: this.propertyId,
                                        order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                                        styling_type_id: selectedFashion,
                                        earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                        latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                        comment: comments,
                                    }
                                }
                            })
                        } else {
                            showMessage(MessageUtils.Errors.errTimeGap, Constants.MessageType.FAILED)
                        }
                    } else {
                        showMessage(MessageUtils.Errors.fashion, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.endTime, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.startTime, Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
        }

    };

    addIcon = (title) => {
        let touchable;
        // return title;
        return (
            <TouchableOpacity activeOpacity={0.5}
                              ref={(ref) => touchable = ref}
                              onPress={() => {
                                  this.setState({
                                      isVisible: true,
                                      fromView: touchable,
                                      desc: title
                                  })
                              }}>
                <Icon name={'faq'}/>
            </TouchableOpacity>
        )
    }

    render() {
        return (
            <View style={CommonStyle.container}>
                <Provider locale={enUS}>
                    <PopOver isVisible={this.state.isVisible}
                             fromView={this.state.fromView}
                             placement={'top'}
                             onRequestClose={() => this.setState({isVisible: false})}>


                        <View style={{paddingHorizontal: 10}}>

                            <Label small mb={10} mt={10}>{this.state.desc}</Label>
                        </View>

                    </PopOver>
                    <Header animatedTitle={Strings.styling}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedValue={this.scrollY}/>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         keyboardShouldPersistTaps={'always'}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                             {useNativeDriver: true})}>

                        <View style={styles.container}>
                            <View style={styles.topContainer}>
                                <View style={styles.labelContainer}>
                                    <Icon
                                        size={ThemeUtils.fontXXLarge}
                                        name={'styling'}
                                        color={Color.PRIMARY_DARK}/>
                                </View>


                                <Label
                                    mt={16}
                                    small
                                    mb={18}
                                    font_regular
                                    align={'left'}>{Strings.instructionStyling}</Label>
                                <View style={styles.checkbox}>
                                    <Label small
                                           font_medium
                                           mb={5}
                                           align={'left'}>
                                        {Strings.chooseYourStyling}
                                    </Label>
                                    <View style={styles.checkbox}>

                                        {<Query query={SELLER_STYLING_TYPES}
                                                fetchPolicy={'cache-and-network'}
                                                onCompleted={(data) => data?.styling_types.length && this.setState({
                                                    selectedFashion: data?.styling_types[0].id,
                                                    fashions: data?.styling_types ?? []
                                                })
                                                }>
                                            {({data, error, loading}) => {
                                                if (loading && !data) {
                                                    return <ActivityIndicator color={Color.PRIMARY}/>
                                                }
                                                const options = data?.styling_types.map(item => ({
                                                    name: item.name,
                                                    icon: this.addIcon(item.name),
                                                    value: item.id
                                                }))??[];

                                                return (<RadioGroup options={options}
                                                                    style={styles.energyradio}
                                                                    small
                                                                    selectedOptions={1}
                                                                    font_regular
                                                                    onSelectOption={(val) => this.setState({selectedFashion: val})}/>)
                                            }}
                                        </Query>}

                                    </View>
                                </View>
                                <DatePicker min={new Date(moment().add(1, 'd'))} title={Strings.date}
                                            onSelectDate={(time) => this.setState({orderDate: time})}
                                            placeholder={Strings.selectDate}/>

                                <Label
                                    mt={16}
                                    small
                                    font_regular
                                    align={'left'}>{Strings.instructionStyling2}</Label>

                                <DatePicker title={Strings.startTime}
                                            onSelectDate={(time) => this.setState({
                                                startTime: time,
                                                endTime: checkDiff(time) ? add1Day() : add4Hours(time)
                                            })}
                                            placeholder={Strings.selectTime}
                                            minTime={new Date(moment().add(1, 'd').set({
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            }))}
                                            maxTime={add1Day()}
                                            mode={'time'}/>
                                <DatePicker title={Strings.endTime}
                                            selectedDate={this.state?.startTime && !this.state.endTime ? checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime) : this.state.endTime ? new Date(moment(this.state.endTime)) : null}
                                            minTime={checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime)}
                                            maxTime={add1Day()}
                                            onSelectDate={(time) => this.setState({endTime: time})}
                                            placeholder={Strings.selectTime}
                                            mode={'time'}/>

                                <InputField textArea
                                            mt={18}
                                            heightInRow={5}
                                            value={this.state.comments}
                                            placeholder={Strings.writeYourComment}
                                            labelText={Strings.comments}
                                            onChange={(comments) => this.setState({comments: comments})}
                                />

                                <Mutation mutation={SELLER_STYLISH_ORDER}
                                          onCompleted={this.navigateToThankYou}
                                          onError={() => {

                                          }}>
                                    {(placeOrder, {loading, data}) => (
                                        <CustomButton
                                            mt={18}
                                            mb={18}
                                            loading={loading}
                                            title={Strings.order}
                                            onPress={() => this.validate(placeOrder)}/>
                                    )}
                                </Mutation>


                            </View>

                        </View>
                    </Animated.ScrollView>
                </Provider>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Styling);
