import React, {useState} from 'react'
import {ActivityIndicator, ScrollView, View} from 'react-native'
import {
    Color,
    CommonStyle,
    Constants,
    encryptId,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {CustomButton, Header, Hr, InputField, Label, LogoForHeader, RadioGroup} from "components/src/components";
import styles from './styles'
import {useMutation, useQuery} from "@apollo/react-hooks";
import {STORE_QUESTIONS} from "../../../../../../api/mutation";
import {connect} from "react-redux";
import {PROPERTY_DETAILS} from "../../../../../../api/query";


const OPTIONS = [
    {name: 'Ingen', value: 'None'},
    {name: 'Annet meglerforetak', value: 'Other'},
]

function Questions(props) {
    const {history, inspection, navigation} = props

    const [firm, setFirm] = useState('None')
    const [firmName, setFirmName] = useState('')
    const [errFirmName, setErrFirmName] = useState(null)
    const [isPoliticalyExposed, setPoliticalyExposed] = useState('No')
    const [clientClaim, setClientClaim] = useState('None')
    const [isMarried, setMarried] = useState('No')
    const [isBothSign, setBothSign] = useState('Yes')
    const [contractApply, setContractApply] = useState('No')

    const [storeQuestion, storeQuestionMutation] = useMutation(STORE_QUESTIONS);

    const {loading, data, error} = useQuery(PROPERTY_DETAILS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setFirm(data?.property?.contract_assignment ? "None" : "Other")
            setPoliticalyExposed(data?.property?.political_person ? "Yes" : "No")
            setClientClaim(data?.property?.client_claim ? "Yes" : "No")
            setMarried(data?.property?.client_married_partner ? "Yes" : "No")
            setFirmName(data?.property?.name_of_brokerage)
            setBothSign(data?.property?.client_partner_both_sign ? "Yes" : "No")
            setContractApply(data?.property?.contract_apply_housing ? "Yes" : "No")
        },
        variables: {
            id: inspection?.property_id
        }
    });

    const validate = () => {
        let isError=false;
        let variables = {
            property_id: inspection?.property_id,
            same_contract_assignment: firm === "Other" ? 0 : 1,
            political_person: isPoliticalyExposed === 'Yes' ? 1 : 0,
            client_claim: clientClaim === "Yes" ? 1 : 0,
            client_married_partner: isMarried === 'Yes' ? 1 : 0,
        }
        if (clientClaim !== 'None') {
            variables.client_claim = clientClaim === "Yes" ? 1 : 0;
        }
        if (firm === 'Other') {
            let err=validation("firmName",firmName?.trim());
            if(err){
                setErrFirmName(err)
                isError=true
            }
            variables.name_of_brokerage = firmName
        }
        if (isMarried === 'Yes') {
            variables.client_partner_both_sign = isBothSign === 'Yes' ? 1 : 0
        }
        if (isMarried === 'Yes' && isBothSign === 'No') {
            variables.contract_apply_housing = contractApply === 'Yes' ? 1 : 0
        }
        if(!isError){
            storeQuestion({
                variables: {
                    input: variables
                }
            }).then(() => {
                IS_WEB ? history.push(Routes.SignAgreement) : navigation.navigate(Routes.SignAgreement)
            }).catch(() => {

            })
        }
    }
    return (
        <View style={CommonStyle.flex}>
            <Header renderTitle={() => <LogoForHeader/>}
                    headerRightSecond={'dashboard'}
                    headerRightFirst={'close'}
                    onPressRightFirst={()=>{
                        if(IS_WEB){
                            history.replace(`/${Routes.InspectionApplication}`)
                        } else {
                            navigation.pop(6)
                        }
                    }}
                    onPressRightSecond={() =>{
                        if(IS_WEB){
                            console.log(inspection,"ONMS")
                            history.replace(`/${Routes.StartInspection}/${encryptId(inspection.property_id)}`, {
                                property: inspection.property
                            })
                        } else {
                            navigation.pop(2)
                        }
                    }}
                    tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <ScrollView style={styles.cont}>
                    <Label small
                           color={Color.TERNARY_TEXT_COLOR}
                           font_medium
                           mt={ThemeUtils.relativeRealHeight(2)}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"Spørsmål"}
                    </Label>
                    <Label xsmall
                           font_medium
                           style={styles.lbl}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"Følgende megler(e) har arbeidet med salg av samme eiendomsoppdraget de siste 3 måneder før signatur av oppdraget:"}
                    </Label>
                    <RadioGroup xsmall
                                changeOnProp
                                small={false} options={OPTIONS}
                                selectedOptions={firm}
                                onSelectOption={setFirm}
                                contentStyle={styles.radio}/>
                    {firm === 'Other' ? (
                        <InputField type={'text'}
                                    value={firmName}
                                    error={errFirmName}
                                    placeholder={Strings.nameOfBrokerage}
                                    mt={5}
                                    ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                    mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                    onChange={setFirmName}/>) : null}
                    <Hr/>
                    <Label xsmall
                           font_medium
                           style={styles.lbl}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt, et høytstående offentlig verv eller stilling. Er oppdragsgiver en politisk eksponert person (PEP)"}
                    </Label>
                    <RadioGroup xsmall
                                changeOnProp
                                small={false} options={Constants.RADIO_OPTIONS}
                                selectedOptions={isPoliticalyExposed}
                                onSelectOption={setPoliticalyExposed}
                                contentStyle={styles.radio}/>
                    <Hr/>
                    <Label xsmall
                           font_medium
                           style={styles.lbl}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"Dersom en av oppdragsgiverne kjøper ut den andre/en av de andre oppdragsgiverne har oppdragstaker krav på fullt vederlag i henhold til punkt 4. Det samme gjelder dersom oppdragsgiver selger eiendommen til fraflyttet ektefelle. Hvis boligen har vært felles bolig for ektefeller, er boet mellom ektefellene oppgjort?"}
                    </Label>
                    <RadioGroup xsmall
                                changeOnProp
                                small={false}
                                options={Constants.RADIO_OPTIONS}
                                selectedOptions={clientClaim}
                                onSelectOption={setClientClaim}
                                contentStyle={styles.radio}/>
                    <Hr/>
                    <Label small
                           color={Color.TERNARY_TEXT_COLOR}
                           font_medium
                           mt={ThemeUtils.relativeRealHeight(2)}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"Erklæring om sivilstand"}
                    </Label>
                    <Label xsmall
                           font_medium
                           style={styles.lbl}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {"Er oppdragsgiver gift/registrert partner?"}
                    </Label>
                    <RadioGroup xsmall
                                changeOnProp
                                small={false} options={Constants.RADIO_OPTIONS}
                                onSelectOption={setMarried}
                                selectedOptions={isMarried}
                                contentStyle={styles.radio}/>
                    <Hr/>
                    {isMarried === 'Yes' ? (
                        <>
                            <Label xsmall
                                   font_medium
                                   style={styles.lbl}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {"Er oppdragsgiver gift/registrert partner med hverandre og begge underskriver som oppdragsgiver?"}
                            </Label>
                            <RadioGroup xsmall
                                        changeOnProp
                                        small={false} options={Constants.RADIO_OPTIONS}
                                        selectedOptions={isBothSign}
                                        onSelectOption={setBothSign}
                                        contentStyle={styles.radio}/>
                        </>
                    ) : null}
                    <Hr/>
                    {isBothSign === 'No' && isMarried === 'Yes' ? (
                        <>
                            <Label xsmall
                                   font_medium
                                   style={styles.lbl}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                {"Gjelder oppdraget bolig som oppdragsgiver og dennes ektefelle/registrerte partner bruker som fellesbolig?"}
                            </Label>
                            <RadioGroup xsmall
                                        changeOnProp
                                        small={false}
                                        options={Constants.RADIO_OPTIONS}
                                        selectedOptions={contractApply}
                                        onSelectOption={setContractApply}
                                        contentStyle={styles.radio}/>
                        </>) : null}
                    <CustomButton title={Strings.gotoAssignment}
                                  loading={storeQuestionMutation.loading}
                                  mt={20}
                                  ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                  mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                  onPress={validate}
                                  mb={20}/>
                </ScrollView>
            )}

        </View>
    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Questions);
