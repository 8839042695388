import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Spin, Typography} from 'antd';
import './offerNew.scss';
import {useQuery} from "react-apollo";
import {OFFER_LISTING, SELECTED_SERVICE, SEND_OFFER_DETAIL} from "../../../../query/AgentQuery";
import {encryptId, formatCurrency, getDecryptedId} from "../../../utils/utils";
import BefaringLayout from "../../../layouts/BefaringLayout";

const {Title} = Typography;


function OfferListing(props) {
    let router = props.history;
    let propertyId;
    if (props && props.match && props.match.params) {
        propertyId = getDecryptedId(props.match.params.propertyId);
    } else {
        router.push('/');
    }
    if (propertyId === 'error') {
        router.push('/');
    }
    propertyId = parseInt(propertyId);

    const psServices = [];
    const dmServices = [];
    const pmServices = [];
    const [allPsServices, setAllPsServices] = useState([]);
    const [allDmServices, setAllDmServices] = useState([]);
    const [allPmServices, setAllPmServices] = useState([]);
    const [allSelectedUtleggServices, setAllSelectedUtleggServices] = useState([]);
    const [allSelectedVedarlagServices, setAllSelectedVedarlagServices] = useState([]);
    const [allMarketingPackages, setAllMarketingPackages] = useState([]);
    const [remuneration, setRemuneration] = useState([]);
    const [allPurchasedServices, setAllPurchasedServices] = useState([]);
    const [serviceLoaded, setServiceLoaded] = useState([]);

    const photoServiceQuery = useQuery(SELECTED_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: 1,
            category: 'PS',
            propertyId: propertyId,
        },
    });

    const digitalMarketingServiceQuery = useQuery(SELECTED_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: 1,
            category: 'DM',
            propertyId: propertyId,
        },
    });

    const printMarketingServiceQuery = useQuery(SELECTED_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: 1,
            category: 'PM',
            propertyId: propertyId,
        },
    });

    const remunerationQuery = useQuery(OFFER_LISTING, {
        fetchPolicy: 'network-only',
        variables: {
            id: propertyId,
        },
    });

    const rebateAmountQuery = useQuery(SEND_OFFER_DETAIL, {
        fetchPolicy: 'network-only',
        variables: {
            id: propertyId,
        },
    });

    useEffect(() => {
        console.log('MOunted');
        return () => {
            setServiceLoaded([]);
            photoServiceQuery.data = null;
            digitalMarketingServiceQuery.data = null;
            printMarketingServiceQuery.data = null;
            remunerationQuery.data = null;
            rebateAmountQuery.data = null;

            console.log('Unmounted');
        };
    }, []);
    /*useEffect(() => {
          console.log("Service loader==?>?", serviceLoaded)
      }, [serviceLoaded])*/

    //ComponentDidMount if data already here just set it in the state
    /*useEffect(() => {
          //Set Remuneration
          if (remunerationQuery && remunerationQuery.data && !remunerationQuery.loading) {
              setRemuneration(
                  remunerationQuery.data?.offer_listing?.remunerations?.filter(
                      item => item.usableServiceClass,
                  ),
              );
              let services = serviceLoaded.slice();
              services.push('REMUNERATION');
              setServiceLoaded(services);
          }

          //Set PrintMarketing Services
          if (
              printMarketingServiceQuery &&
              printMarketingServiceQuery.data &&
              !printMarketingServiceQuery.loading
          ) {
              console.log('Print Marketing==>', printMarketingServiceQuery.data);
              setPrintServices(printMarketingServiceQuery.data);
              let services = serviceLoaded.slice();
              services.push('PM');
              setServiceLoaded(services);
          }

          //Set DigitalMarketing Services
          if (
              digitalMarketingServiceQuery &&
              digitalMarketingServiceQuery.data &&
              !digitalMarketingServiceQuery.loading
          ) {
              setDigitalServices(digitalMarketingServiceQuery.data);
              let services = serviceLoaded.slice();
              services.push('DM');
              setServiceLoaded(services);
          }

          //Set Photo Service
          if (photoServiceQuery && photoServiceQuery.data && !photoServiceQuery.loading) {
              setPhotoServices(photoServiceQuery.data);
              setPropertyUtlegg(photoServiceQuery.data);
              setMarketingPackage(photoServiceQuery.data);
              let services = serviceLoaded.slice();
              services.push('PS');
              setServiceLoaded(services);
          }

      }, [])*/

    useEffect(() => {
        if (remunerationQuery && remunerationQuery.data && !remunerationQuery.loading) {
            setRemuneration(
                remunerationQuery.data?.offer_listing?.remunerations?.filter(
                    item => item.usableServiceClass,
                ),
            );
            let services = serviceLoaded.slice();
            services.push('REMUNERATION');
            setServiceLoaded(services);
        }
    }, [remunerationQuery.data]);

    useEffect(() => {
        if (
            printMarketingServiceQuery &&
            printMarketingServiceQuery.data &&
            !printMarketingServiceQuery.loading
        ) {
            console.log('Print Marketing==>', printMarketingServiceQuery.data);
            setPrintServices(printMarketingServiceQuery.data);
            let services = serviceLoaded.slice();
            services.push('PM');
            setServiceLoaded(services);
        }
    }, [printMarketingServiceQuery.data]);

    useEffect(() => {
        if (
            digitalMarketingServiceQuery &&
            digitalMarketingServiceQuery.data &&
            !digitalMarketingServiceQuery.loading
        ) {
            setDigitalServices(digitalMarketingServiceQuery.data);
            let services = serviceLoaded.slice();
            services.push('DM');
            setServiceLoaded(services);
        }
    }, [digitalMarketingServiceQuery.data]);

    useEffect(() => {
        if (photoServiceQuery && photoServiceQuery.data && !photoServiceQuery.loading) {
            setPhotoServices(photoServiceQuery.data);
            setPropertyUtlegg(photoServiceQuery.data);
            setMarketingPackage(photoServiceQuery.data);
            let services = serviceLoaded.slice();
            services.push('PS');
            setServiceLoaded(services);
        }
    }, [photoServiceQuery.data]);

    const setPropertyUtlegg = data => {
        const tempUtleggServices = data?.selected_property_services?.property_utlegg
            ?.filter(
                item =>
                    item.usableServiceClass &&
                    item.usableServiceClass.property_id === propertyId &&
                    item.price_type === 'Utlegg',
            )
            .sort((a, b) => b.is_default - a.is_default);
        const tempVedarleggServices = data?.selected_property_services?.property_utlegg
            ?.filter(
                item =>
                    item.usableServiceClass &&
                    item.usableServiceClass.property_id === propertyId &&
                    item.price_type === 'Vederlagg',
            )
            .sort((a, b) => b.is_default - a.is_default);
        setAllSelectedUtleggServices(tempUtleggServices);
        setAllSelectedVedarlagServices(tempVedarleggServices);
        setAllPurchasedServices(prevState => [
            ...prevState,
            ...tempUtleggServices,
            ...tempVedarleggServices,
        ]);
    };

    const setMarketingPackage = data => {
        const selectedMarketingPackageList = data?.selected_property_services?.marketing_package?.filter(
            item => item.property_package && item.property_package.property_id === propertyId,
        );
        setAllMarketingPackages(selectedMarketingPackageList);
        setAllPurchasedServices(prevState => [...prevState, ...selectedMarketingPackageList]);
    };

    const setPhotoServices = data => {
        const selectedPackage = data?.selected_property_services?.marketing_package
            ? data?.selected_property_services?.marketing_package
                .find(item => item.property_package)
                ?.marketing_package_service?.map(item => item.office_service.id)
            : [];
        console.log("Services ID from package", selectedPackage)
        data?.selected_property_services?.services?.map((service, index) => {
            let vendorServices = []
            if (index === 0) {
                vendorServices = data?.selected_property_services?.service_types[0];
            }
            if (index === 1) {
                vendorServices = data?.selected_property_services?.service_types[1];
            }
            if (index === 3) {
                vendorServices = data?.selected_property_services?.service_types[2];
            }
            if (index === 4) {
                vendorServices = data?.selected_property_services?.service_types[3];
            }
            if (vendorServices?.vendor_services?.length) {
                vendorServices?.vendor_services?.filter(item => item.service_selected &&
                    item.service_selected.property_id === propertyId)
                    .map(item => {
                        psServices.push(item)
                    })
                return;
            }
            service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected &&
                !selectedPackage?.includes(item.id) &&
                item.service_selected.property_id === propertyId)
                .map(item => {
                    psServices.push(item)
                })
        })
        /*   data?.selected_property_services?.services?.map(service => {
               const tempPSServices = service.purchased_office_template?.purchased_office_services?.filter(
                   item => item.service_selected && item.service_selected.property_id === propertyId,
               );
               tempPSServices.map(i => psServices.push(i));
           });*/
        setAllPsServices(psServices);
        setAllPurchasedServices(prevState => [...prevState, ...psServices]);
    };

    const setDigitalServices = data => {
        data?.selected_property_services?.services?.map(service => {
            const tempDMServices = service.purchased_office_template?.purchased_office_services?.filter(
                item => item.service_selected && item.service_selected.property_id === propertyId,
            );
            tempDMServices.map(i => dmServices.push(i));
        });
        setAllDmServices(dmServices);
        setAllPurchasedServices(prevState => [...prevState, ...dmServices]);
    };

    const setPrintServices = data => {
        data?.selected_property_services?.services?.map(service => {
            const tempPMServices = service.purchased_office_template?.purchased_office_services?.filter(
                item => item.service_selected && item.service_selected.property_id === propertyId,
            );
            tempPMServices.map(i => pmServices.push(i));
        });
        setAllPmServices(pmServices);
        setAllPurchasedServices(prevState => [...prevState, ...pmServices]);
    };

    console.log('All purchase service-==>>', allPurchasedServices);
    let total = allPurchasedServices
        .map(item =>
            parseInt(
                item.service_selected
                    ? item.service_selected.price
                    : item?.property_package
                    ? item?.property_package.price
                    : item.price * (item?.hours ? parseInt(item.hours, 10) : 1),
            ),
        )
        .reduce((a, b) => a + b, 0);
    total += remuneration
        .map(item =>
            item.usableServiceClass
                ? item.usableServiceClass.price * (item?.usableServiceClass?.quantity ?? 1)
                : item.price * (item?.hours ?? 1),
        )
        .reduce((a, b) => a + b, 0);
    if (rebateAmountQuery.data?.send_offer_detail?.property_preference?.rebate_amount) {
        total -= parseInt(
            rebateAmountQuery.data?.send_offer_detail?.property_preference?.rebate_amount,
        );
    }

    return (
        <BefaringLayout>
            <Row className="offer-layout">
            {serviceLoaded.length > 3 ? (
                <Col md={20} xs={24}>
                    <Title level={1} style={{textAlign: 'center'}}>
                        Tilbud på salg
                    </Title>
                    <hr className="heading_line"/>
                    <Row className="offer-section">
                        <Col md={24} xs={24}>
                            {remuneration.length > 0 &&
                            remuneration.map((i, index) => (
                                <>
                                    <div className="offer-main">
                                        <div>
                                            <span>{i.name}</span>
                                        </div>
                                        <div>{i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}</div>
                                    </div>
                                </>
                            ))}
                            {remuneration.length > 0 && <hr/>}
                        </Col>

                        <Col md={24} xs={24}>
                            {allMarketingPackages.length > 0 ? (
                                <Title level={4} className="text-secondary offer-title">
                                    Markedspakker
                                </Title>
                            ) : (
                                ''
                            )}
                            {allMarketingPackages.length > 0 &&
                            allMarketingPackages.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i?.property_package ? `Kr ${formatCurrency(i?.property_package?.price)},-` : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {allMarketingPackages.length > 0 && <hr/>}
                        </Col>

                        <Col md={24} xs={24}>
                            {allPsServices.length > 0 ? (
                                <Title level={4} className="text-secondary offer-title">
                                    Boligfoto/Styling
                                </Title>
                            ) : (
                                ''
                            )}
                            {allPsServices.length > 0 &&
                            allPsServices.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {allPsServices.length > 0 && <hr/>}
                        </Col>

                        <Col md={24} xs={24}>
                            {allDmServices.length > 0 ? (
                                <Title level={4} className="text-secondary offer-title">
                                    Digital markedsføring
                                </Title>
                            ) : (
                                ''
                            )}
                            {allDmServices.length > 0 &&
                            allDmServices.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {allDmServices.length > 0 && <hr/>}
                        </Col>

                        <Col md={24} xs={24}>
                            {allPmServices.length > 0 ? (
                                <Title level={4} className="text-secondary offer-title">
                                    Print markedsføring
                                </Title>
                            ) : (
                                ''
                            )}
                            {allPmServices.length > 0 &&
                            allPmServices.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {allPmServices.length > 0 && <hr/>}
                        </Col>

                        <Col md={24} xs={24}>
                            {allSelectedUtleggServices.length > 0 || allSelectedVedarlagServices.length > 0 ? (
                                <Title level={4} className="text-secondary offer-title">
                                    Andre kostnader
                                </Title>
                            ) : (
                                ''
                            )}
                            {allSelectedUtleggServices.length > 0 && <h2>Utlegg</h2>}
                            {allSelectedUtleggServices.length > 0 &&
                            allSelectedUtleggServices.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {allSelectedVedarlagServices.length > 0 && <h2>Vederlag</h2>}
                            {allSelectedVedarlagServices.length > 0 &&
                            allSelectedVedarlagServices.map((i, index) => (
                                <div className="offer-list">
                                    <div>
                                        <span>{i.name}</span>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        {i.is_free ? i.free_text : `Kr ${formatCurrency(i.price)},-`}
                                    </div>
                                </div>
                            ))}
                            {(allSelectedUtleggServices.length > 0 || allSelectedVedarlagServices.length > 0) && (
                                <hr/>
                            )}
                        </Col>

                        <Col span={24}>
                            <div className="offer-title">
                                <div>
                                    <Title className="text-secondary" level={2}>
                                        Totalpris
                                    </Title>
                                </div>
                                <div>
                                    <Title className="text-secondary" level={2} style={{textAlign: 'right'}}>
                                        {!isNaN(total) && `Kr ${formatCurrency(total)},-`}
                                    </Title>
                                </div>
                            </div>
                        </Col>

                        <Col span={24} style={{marginTop: '4em'}}>
                            <Button
                                type="primary"
                                className="btn-secondary btn-secondary admin-forms-btn"
                                style={{fontSize: '12px', padding: '8px'}}
                                size="large"
                                onClick={() => {
                                    router.push(`/befaring/${encryptId(propertyId)}/questions-for-agent`);
                                }}
                            >
                                Gå til oppdragsavtale
                            </Button>

                            <Button
                                className="admin-forms-btn offer-btn ml-2"
                                style={{fontSize: '12px'}}
                                size="large"
                                onClick={() => router.goBack()}
                            >
                                Hjem
                            </Button>
                        </Col>
                    </Row>
                </Col>
            ) : (
                <Col md={20} xs={24}>
                    <div className="loader-wrapper">
                        <Spin/>
                    </div>
                </Col>
            )}
        </Row>
        </BefaringLayout>
    );
}

export default OfferListing;

