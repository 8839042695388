import React, {useEffect,useState} from 'react';
import {Animated,TouchableOpacity, StyleSheet, View} from 'react-native';
import {Label} from 'components/src/components'

import InputItem from "@ant-design/react-native/lib/input-item";
import TextareaItem from "@ant-design/react-native/lib/textarea-item";
import InputItemStyle from "@ant-design/react-native/lib/input-item/style/index";

import PropTypes from 'prop-types';
import {Icon,Color, IS_WEB, ThemeUtils} from 'components/src/utils';

/*(
    <TouchableOpacity style={styles.eye}
                      onPress={() => {
                          if (passwordVisible === "password")
                              setPasswordVisible('text')
                          else
                              setPasswordVisible('password')
                      }}>
        <Icon name={passwordVisible === "text" ? "view-general-icon" : 'pwd-hide'}
              size={ThemeUtils.fontSmall}
              color={props.color}/>
    </TouchableOpacity>
)*/
const InputField = (props) => {
    let animatedValue = new Animated.Value(0)
    const [passwordVisible, setPasswordVisible] = useState('password');

    useEffect(() => {
        Animated.timing(animatedValue, {
            toValue: props.error ? 100 : 0,
            duration: 150
        }).start();
    }, [props.error])

    const getStyleArray = () => {
        let stylesArray = [];
        stylesArray.push({
            marginTop: props.mt,
            marginBottom: props.mb,
            marginLeft: props.ml,
            marginRight: props.mr
        });
        return stylesArray
    };

    const marginTop = animatedValue.interpolate({
        inputRange: [0, 100],
        outputRange: [-5, 0],
        extrapolate: 'clamp'
    });

    const opacity = animatedValue.interpolate({
        inputRange: [0, 50],
        outputRange: [0, 1]
    });

    return (
        <View style={[getStyleArray(), props.style]}>
            {props.labelText &&
            <Label align={props.align}
                   small
                   font_medium
                   {...props.labelProps}
                   mb={props.lblMb}>
                {props.labelText}
            </Label>}
            <View style={[styles.container, props.style]}>
                {props.textArea ? (
                    <Animated.ScrollView>
                        <TextareaItem
                            selectionColor={Color.SECONDARY}
                            styles={{
                                ...InputItemStyle,
                                clear: null,
                                container: {
                                    borderColor: Color.TRANSPARENT,
                                },
                                input: {
                                    color: Color.PRIMARY,
                                },
                                text: {
                                    color: Color.PRIMARY,
                                }
                            }}
                            style={[{
                                paddingHorizontal: ThemeUtils.relativeWidth(IS_WEB?2:1),
                                paddingVertical: ThemeUtils.relativeRealHeight(1),
                                fontSize: ThemeUtils.fontNormal,
                                marginTop: 0,
                                minHeight: ThemeUtils.relativeRealHeight(10),
                                borderColor: Color.TRANSPARENT,
                                borderRadius: props.borderRadius,

                                color: Color.PRIMARY,

                            }, IS_WEB ? {outline: 'none'} : null]}
                            value={props.value}
                            editable={props.editable}
                            disabled={props.disabled}
                            rows={props.heightInRow}
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            placeholderTextColor={Color.DARK_GRAY}

                            onFocus={props.onFocus}/>
                    </Animated.ScrollView>
                ) : (
                    <InputItem ref={props.reference}
                               last
                               styles={{
                                   ...InputItemStyle,
                                   container: {
                                       marginTop: 0,
                                       marginBottom: ThemeUtils.isTablet() ? 1 : 0,
                                       marginLeft: 0,
                                       paddingRight:0,
                                       flexDirection: 'row',
                                       borderColor: Color.TRANSPARENT,
                                   },
                                   input: {
                                       flex: 1,
                                       color: props.color,
                                   },
                                   text: {
                                       color: props.color,
                                   }
                               }}
                               labelNumber={2}
                               selectionColor={Color.SECONDARY}
                               extra={props.type === "password" ? null : props.extra}
                               style={[{
                                   flex: 1,
                                   paddingHorizontal: ThemeUtils.relativeRealWidth(2),
                                   paddingVertical: IS_WEB ? ThemeUtils.relativeRealHeight(2) : 0,
                                   fontSize: ThemeUtils.fontNormal,
                               }, IS_WEB ? {outline: 'none'} : null]}
                               disabled={props.disabled}
                               placeholderTextColor={Color.DARK_GRAY}
                               editable={props.editable}
                               value={props.value}
                               type={props.type === "password" ? passwordVisible : props.type}
                               onChange={props.onChange}
                               onFocus={props.onFocus}
                               placeholder={props.placeholder}>
                        {props.children}
                    </InputItem>)}
            </View>
            {props.errorEnabled ? (
                <Animated.View style={{
                    transform: [{translateY: marginTop}],
                    opacity: opacity
                }}>
                    <Label small
                           ml={5}
                           mt={5}
                           style={{height: 30}}
                           color={Color.RED}>
                        {props.error}
                    </Label>
                </Animated.View>) : null}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Color.INPUT_BORDER,
    },
    eye: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingHorizontal: 10
    }
});

InputField.defaultProps = {
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    color: Color.PRIMARY,
    lblMb: ThemeUtils.relativeRealHeight(1),
    type: 'phone',
    disabled: false,
    editable: true,
    errorEnabled: true,
    placeholder: "",
    borderColor: Color.INPUT_BORDER,
    borderRadius: 4,
    heightInRow: 4,

};

InputField.propTypes = {
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
    lblMb: PropTypes.number,
    type: PropTypes.string,
    labelText: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    textArea: PropTypes.bool,
    errorEnabled: PropTypes.bool,
    labelProps: PropTypes.object,
};
export default InputField;
