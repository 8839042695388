export default {
  'component.team.title': 'Team',
  'component.team.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.team.addteam': 'Legg til Team',
  'component.team.addteam.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.team.editteam': 'Rediger Team',
  'component.team.editteam.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.team.tablesearch': 'Søk etter teamnavn, hovedagentenavn og navn på underagent',
  'component.team.teamtitle': 'Teamtittel',
  'component.team.mainagent': 'Hovedagent',
  'component.team.subagent1': 'Underagent1',
  'component.team.subagent2': 'Underagent2',
  'component.team.deletemodal.title': 'Slett Team',
  'component.team.deletemodal.text': 'Er du sikker på at du vil slette dette teamet?',
};
