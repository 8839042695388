import React, {useEffect, useState} from 'react'
import {
    Calendar,
    CustomButton,
    DatePicker,
    Hr,
    Label,
    LocaleConfig,
    Modal,
    PopOver,
    RadioGroup,
    Ripple,
    Toast
} from "components/src/components";
import {ActivityIndicator, ScrollView, TouchableOpacity, View} from 'react-native'
import styles from './styles';
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle, Constants,
    DateUtils,
    decryptId,
    Icon,
    MessageUtils,
    showMessage,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Query} from "@apollo/react-components";
import {BOOK_CALENDAR, DELETE_CALENDAR, DELETE_CALENDAR_BOOK_DATE} from "../../../../../../../api/mutation";
import {CALENDAR_TIMELINE_QUERY, PROPERTY_ORDER_DETAILS, SELLER_STYLING_TYPES} from "../../../../../../../api/query";
import {connect} from "react-redux";
import moment from "moment";


LocaleConfig.locales['no'] = {
    monthNames: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
    monthNamesShort: [
        'Jan',
        'Febr',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'Aug',
        'Sept',
        'Okt',
        'Nov',
        'Des'],
    dayNames: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    dayNamesShort: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
};
LocaleConfig.defaultLocale = 'no';


function CalendarTab(props) {

    const {inspection} = props;
    let toast;
    const propertyId = props?.match?.params?.id ? decryptId(props.match.params.id) : inspection.property_id

    const [bookDate, bookDateMutation] = useMutation(BOOK_CALENDAR, {
        refetchQueries: [{
            query: CALENDAR_TIMELINE_QUERY,
            variables: {
                status: 1,
                propertyId: propertyId
            }
        }]
    });

    const {loading, data, error} = useQuery(CALENDAR_TIMELINE_QUERY, {
        variables: {
            status: 1,
            propertyId: propertyId
        }
    });
    const [deleteCal, deleteCalMutation] = useMutation(DELETE_CALENDAR_BOOK_DATE, {
        refetchQueries: [{
            query: CALENDAR_TIMELINE_QUERY,
            variables: {
                status: 1,
                propertyId: propertyId
            }
        }]
    });

    const [markedDate, setMarkedDate] = useState({});
    const [isOrderVisible, setOrderVisible] = useState(false);


    const [addPackagesVisible, setPackageVisible] = useState();
    const [date, setDate] = useState();
    const [selectedPackage, setPackage] = useState([]);

    const [timelineId, setTimelineId] = useState();

    const [bookDateVisible, setBookDateVisible] = useState(false);
    const [bookItem, setBookItem] = useState();

    const [orderDate, setOrderDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const [selectedFashion, setSelectedFashion] = useState();

    const [desc, setDesc] = useState();
    const [fromView, setFromView] = useState();
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    const orderQuery = useQuery(PROPERTY_ORDER_DETAILS, {
        variables: {
            id: parseInt(propertyId)
        }
    });
    const getOrderDetails = () => {
        switch (bookItem?.service_type) {
            case 4:
                return orderQuery?.data?.property?.order_photo
            case 5:
                return orderQuery?.data?.property?.orderrate
            case 6:
                return orderQuery?.data?.property?.orderstyling
        }
    }

    useEffect(() => {
        if (bookItem) {
            let orderDetails = getOrderDetails()
            setStartTime(orderDetails?.earliest_start_time ? new Date(moment(orderDetails?.earliest_start_time, DateUtils.hh_mm_ss)) : undefined)
            setEndTime(orderDetails?.latest_start_time ? new Date(moment(orderDetails?.latest_start_time, DateUtils.hh_mm_ss)) : undefined)
            if (bookItem.completed_date) {
                setOrderDate(new Date(moment(bookItem.completed_date, DateUtils.yyyy_mm_dd)))
            }
            setSelectedFashion(orderDetails?.styling_type_id)
            // setOrderVisible(!!orderDetails)
            setOrderVisible(false)
        }
    }, [bookItem])

    useEffect(() => {
        if (!addPackagesVisible) {
            setDate("")
            setPackage([])
        }
    }, [addPackagesVisible])


    useEffect(() => {
        if (data?.inspection_timeline_list) {
            let markedDate = {}
            data?.inspection_timeline_list?.filter(item => item.completed_date).sort((a, b) => a?.timeline?.priority - b?.timeline?.priority).map(item => {
                if (markedDate[item.completed_date]) {
                    markedDate[item.completed_date].name.push(item)
                } else {
                    markedDate[item.completed_date] = {
                        selected: true,
                        marked: true,
                        selectedColor: Color.PRIMARY_LIGHTER,
                        name: [item]
                    }
                }

            })
            setMarkedDate(markedDate)

        }
    }, [data])


    const addOrRemoveItem = (index) => {
        let updatedPackageList = selectedPackage.slice()
        if (updatedPackageList.includes(index)) {
            updatedPackageList.splice(updatedPackageList.indexOf(index), 1)
        } else {
            updatedPackageList.push(index)
        }
        setPackage(updatedPackageList)
    }

    const renderPackage = (item, index) => {
        return (
            <Ripple style={[styles.lblCont, selectedPackage.includes(item?.timeline_id) ? styles.selectedLbl : null]}
                    onPress={() => {
                        // addOrRemoveItem(index)
                        setPackage([item?.timeline_id])
                    }}
                    rippleContainerBorderRadius={4}
                    key={index.toString()}>
                <Label small
                       color={selectedPackage.includes(item?.timeline_id) ? Color.WHITE : null}>
                    {item.timeline?.name}
                </Label>
            </Ripple>
        )

    };


    /*const renderDatePicker = (item, index) => {
        return (
            <Ripple onPress={() => {
                setBookDateVisible(true)
                setBookItem(item)
            }}>
                {item?.completed_date ? (
                    <Label small
                           font_medium
                           style={CommonStyle.flex}
                           mt={ThemeUtils.relativeWidth(2)}
                           ml={ThemeUtils.relativeWidth(2)}
                           mb={5}>
                        {moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                    </Label>
                ) : null}
                <View style={styles.datePickerCont}>
                    <Label small
                           style={CommonStyle.flex}>
                        {item?.timeline?.name}
                    </Label>
                    {item?.completed_date ?
                        deleteCalMutation?.loading && item?.timeline_id === timelineId ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <Ripple onPress={() => {
                                setTimelineId(item.timeline_id)
                                deleteCal({
                                    variables: {
                                        propertyId: propertyId,
                                        id: item?.timeline_id
                                    }
                                }).then(() => {

                                }).catch(() => {

                                })
                            }}>
                                <Icon name={'delete'}
                                      color={Color.PRIMARY}
                                      size={ThemeUtils.fontNormal}/>
                            </Ripple>
                        ) : null}
                </View>
            </Ripple>
        )
    }*/

    const renderStepperCustom = (item, index) => {
        return (
            <View style={styles.stepperCont}
                  key={index.toString()}>
                <View style={styles.dotCont}>
                    <View style={styles.dot}/>
                    <View style={styles.line}/>
                </View>
                <View style={styles.lblStepperCont}>
                    <View style={styles.stepperTitleCont}>
                        <Icon name={item.timeline.icon?.replace("icon-", "")}
                              color={Color.SECONDARY}
                              size={ThemeUtils.fontXLarge}/>
                        <Label style={CommonStyle.flex}
                               ml={10}
                               font_medium>
                            {item.timeline.name}
                        </Label>
                    </View>
                    {item.completed_date ?
                        (<View style={styles.lblCompleted}>
                            <Label small
                                   color={Color.WHITE}>
                                {`${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>) : null}
                    {/*<Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item.timeline.description}
                    </Label>*/}
                </View>
            </View>
        );
    };


    const addDate = () => {

        if (orderDate) {
            if (startTime || !bookItem?.service_type) {
                if (endTime || !bookItem?.service_type) {
                    if (selectedFashion || bookItem?.service_type !== 6) {
                        if (moment(endTime).diff(moment(startTime), 'h') >= 1 || !bookItem?.service_type) {
                            let variables = {
                                propertyId: parseInt(propertyId),
                                timelineId: bookItem.timeline_id,
                                date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                            };
                            if (bookItem?.service_type) {
                                variables = {
                                    ...variables,
                                    ...{
                                        book_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                        book_end_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                    }
                                };
                                if (bookItem?.service_type === 6) {
                                    variables = {
                                        ...variables,
                                        styling_type_id: selectedFashion
                                    }
                                }
                            }
                            bookDate({
                                variables: variables
                            }).then((data) => {
                                showMessage(data?.data?.calendar_book_date?.meta?.message, Constants.MessageType.SUCCESS)
                                setBookDateVisible(false)
                            }).catch(() => {

                            })
                        } else {
                            showMessage(MessageUtils.Errors.errTimeGapOneHr, Constants.MessageType.FAILED)
                        }
                    } else {
                        showMessage(MessageUtils.Errors.fashion, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.endTime, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.startTime, Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
        }
    };

    const addIcon = (title) => {
        let touchable;
        return (
            <>
                {`${title} `}
                <TouchableOpacity activeOpacity={0.5}
                                  ref={(ref) => touchable = ref}
                                  onPress={() => {
                                      setIsPopUpVisible(true)
                                      setFromView(touchable)
                                      setDesc(title)
                                  }}>
                    <Icon name={'faq'}/>
                </TouchableOpacity>
            </>
        )
    }

    const renderBookDateModal = () => {
        return (
            <Modal visible={bookDateVisible}
                   onRequestClose={() => setBookDateVisible(false)}
                   transparent>

                <Toast ref={r => toast = r}/>
                <PopOver isVisible={isPopUpVisible}
                         fromView={fromView}
                         placement={'top'}
                         onRequestClose={() => setIsPopUpVisible(false)}>
                    <View style={{paddingHorizontal: 10}}>

                        <Label small mb={10} mt={10}>{desc}</Label>
                    </View>

                </PopOver>
                <View style={styles.modalCont}>
                    <View style={styles.dialogCont}>
                        <View style={styles.header}>
                            <Label large
                                   style={CommonStyle.flex}
                                   font_medium>
                                {bookItem?.timeline?.name}
                            </Label>
                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => {
                                                  setBookDateVisible(false)
                                              }}>
                                <Icon name={'close'}
                                      size={ThemeUtils.fontLarge}
                                      color={Color.PRIMARY}/>
                            </TouchableOpacity>
                        </View>
                        <Hr/>

                        {console.log(orderDate, 'ORDER')}
                        <DatePicker min={new Date(moment().add(1, 'd'))}
                                    title={Strings.date}
                                    selectedDate={orderDate}
                                    onSelectDate={(date) => setOrderDate(date)}
                                    placeholder={Strings.selectDate}/>
                        {bookItem?.service_type ? (

                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => setOrderVisible(prevVal => !prevVal)}
                                              style={styles.orderConfirm}>
                                <Icon name={isOrderVisible ? 'tickmark' : 'radio-normal'}
                                      size={ThemeUtils.fontNormal}
                                      color={isOrderVisible ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                                <Label small
                                       ml={10}>
                                    {"Book tid nå"}
                                </Label>
                            </TouchableOpacity>) : null}

                        {bookItem?.service_type && isOrderVisible ? (
                            <>
                                <DatePicker onSelectDate={(date) => {
                                    setStartTime(date)
                                    setEndTime(checkDiff(date,1) ? add1Day() : add4Hours(date,1))
                                }}
                                            title={Strings.startTimeCalendar}
                                            minTime={new Date(moment().add(1, 'd').set({
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            }))}
                                            selectedDate={startTime}
                                            maxTime={add1Day()}
                                            placeholder={Strings.selectTime}
                                            round={true}
                                            mode={'time'}/>
                                <DatePicker onSelectDate={(date) => setEndTime(endTime)}
                                            selectedDate={startTime && endTime ? checkDiff(startTime,1) ? add1Day() : add4Hours(startTime,1) : endTime ? new Date(moment(endTime)) : null}
                                            minTime={checkDiff(startTime,1) ? add1Day() : add4Hours(startTime,1)}
                                            maxTime={add1Day()}
                                            title={Strings.endTimeCalendar}
                                            style={{marginTop: 20}}
                                            placeholder={Strings.selectTime}
                                            mode={'time'}/>
                            </>
                        ) : null}

                        {bookItem?.service_type === 6 && isOrderVisible ? (
                            <View>
                                <Query query={SELLER_STYLING_TYPES}
                                       fetchPolicy={'cache-and-network'}
                                       onCompleted={(data) => data?.styling_types.length && setSelectedFashion(data?.styling_types[0]?.id)
                                       }>
                                    {({data, error, loading}) => {
                                        if (loading && !data) {
                                            return <ActivityIndicator color={Color.PRIMARY}
                                                                      style={CommonStyle.loader}/>
                                        }
                                        const options = data?.styling_types.map(item => ({
                                            name: addIcon(item.name),
                                            value: item.id
                                        }));
                                        return (<RadioGroup options={options}
                                                            style={styles.energyradio}
                                                            small
                                                            selectedOptions={selectedFashion ?? 1}
                                                            font_regular
                                                            onSelectOption={setSelectedFashion}/>)
                                    }}
                                </Query>
                            </View>
                        ) : null}


                        <View style={styles.btnCont}>
                            <View style={[CommonStyle.flexRow, CommonStyle.flex]}>
                                <CustomButton title={Strings.okay}
                                              loading={bookDateMutation.loading}
                                              onPress={addDate}
                                              style={{width: ThemeUtils.relativeWidth(10)}}
                                              mr={10}/>
                                <CustomButton title={Strings.cancel}
                                              onPress={() => {
                                                  setBookDateVisible(false)
                                              }}
                                              bgColor={Color.WHITE}
                                              style={{width: ThemeUtils.relativeWidth(10)}}
                                              textColor={Color.PRIMARY_TEXT_COLOR}
                                              borderColor={Color.PRIMARY_TEXT_COLOR}
                                              borderWidth={1}
                                              ml={10}/>
                            </View>

                            <CustomButton title={Strings.deleteService}
                                          onPress={() => {
                                              // setTimelineId(bookItem.timeline_id)
                                              deleteCal({
                                                  variables: {
                                                      propertyId: propertyId,
                                                      id: bookItem?.timeline_id
                                                  }
                                              }).then(() => {
                                                  setBookDateVisible(false)
                                              }).catch(() => {

                                              })
                                          }}
                                          bgColor={Color.WHITE}
                                          style={{width: ThemeUtils.relativeWidth(10)}}
                                          textColor={Color.RED}
                                          borderColor={Color.RED}
                                          loading={deleteCalMutation.loading}
                                          borderWidth={1}
                                          ml={10}/>

                        </View>
                    </View>
                </View>
            </Modal>
        )
    }

    const renderAddPackagesModal = () => {
        return (
            <Modal visible={addPackagesVisible}
                   onRequestClose={() => setPackageVisible(false)}
                   transparent>

                <View style={styles.modalCont}>
                    <View style={styles.dialogCont}>
                        <View style={styles.header}>
                            <Label large
                                   style={CommonStyle.flex}
                                   font_medium>
                                {Strings.addPackages}
                            </Label>
                            <TouchableOpacity activeOpacity={0.5}
                                              onPress={() => {
                                                  setPackageVisible(false)
                                              }}>
                                <Icon name={'close'}
                                      size={ThemeUtils.fontLarge}
                                      color={Color.PRIMARY}/>
                            </TouchableOpacity>
                        </View>
                        <Hr/>
                        <Label font_medium
                               mt={20}
                               mb={20}>
                            {Strings.selectPackage}
                        </Label>
                        <ScrollView contentContainerStyle={styles.pkgCont}>
                            {loading && !data ? (
                                <ActivityIndicator color={Color.PRIMARY}
                                                   style={CommonStyle.loader}/>
                            ) : data?.inspection_timeline_list?.filter(item => !item.completed_date).sort((a, b) => a?.timeline?.priority - b?.timeline?.priority).map(renderPackage)}
                        </ScrollView>
                        <Hr/>
                        <View style={styles.btnCont}>
                            <CustomButton title={Strings.okay}
                                          loading={bookDateMutation.loading}
                                          disabled={!selectedPackage.length}
                                          onPress={() => {
                                              let selectedPackageData = data?.inspection_timeline_list.find(item => item.timeline_id === selectedPackage[0])
                                              if (selectedPackageData.service_type) {
                                                  setBookDateVisible(true)
                                                  setPackageVisible(false)
                                                  setBookItem(selectedPackageData)
                                              } else {
                                                  bookDate({
                                                      variables: {
                                                          propertyId: propertyId,
                                                          timelineId: selectedPackage[0],
                                                          date: date
                                                      }
                                                  }).then((data) => {
                                                      showMessage(data?.data?.calendar_book_date?.meta?.message, Constants.MessageType.SUCCESS)
                                                      setPackageVisible(false)
                                                  }).catch(() => {

                                                  })
                                              }

                                          }}
                                          style={{width: ThemeUtils.relativeWidth(20)}}
                                          mr={10}/>
                            <CustomButton title={Strings.cancel}
                                          onPress={() => {
                                              setPackageVisible(false)
                                          }}
                                          bgColor={Color.WHITE}
                                          style={{width: ThemeUtils.relativeWidth(20)}}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          borderColor={Color.PRIMARY_TEXT_COLOR}
                                          borderWidth={1}
                                          ml={10}/>

                        </View>
                    </View>
                </View>
            </Modal>
        )
    }

    const renderMarkedDates = (item, index) => {

        return (
            <View style={styles.markedDateCont}>
                <TouchableOpacity key={index.toString()}
                                  onPress={() => {
                                      setBookItem(item)
                                      setBookDateVisible(true)
                                  }}
                                  style={{
                                      flex: 1,
                                      overflow: 'hidden',
                                      backgroundColor: Color.SECONDARY,
                                      borderRadius: 4,
                                      padding: 5,
                                      marginHorizontal: 2.5,
                                      marginVertical: 2.5,
                                      alignSelf: 'stretch'
                                  }}>
                    <Label xsmall
                           singleLine
                           color={Color.WHITE}>
                        {item.timeline?.name}
                    </Label>
                </TouchableOpacity>
                {/*{deleteCalMutation?.loading && item?.timeline_id === timelineId ? (
                    <ActivityIndicator color={Color.PRIMARY}/>
                ) : (
                    <TouchableOpacity style={styles.icDelete}
                                      onPress={() => {
                                          setTimelineId(item.timeline_id)
                                          deleteCal({
                                              variables: {
                                                  propertyId: propertyId,
                                                  id: item?.timeline_id
                                              }
                                          }).then(() => {

                                          }).catch(() => {

                                          })
                                      }}>
                        <Icon name={'delete'}
                              color={Color.RED}
                              size={ThemeUtils.fontSmall}/>
                    </TouchableOpacity>)}*/}

            </View>
        )
    }

    return (
        <View style={[styles.container]}>
            {renderBookDateModal()}
            {renderAddPackagesModal()}
            <View style={CommonStyle.flex}>
                <Calendar theme={{
                    week: {
                        maxHeight: ThemeUtils.relativeRealHeight(13)
                    }
                }}
                          firstDay={1}
                          onDayPress={(data) => {
                              setDate(data?.dateString)
                              setOrderDate(new Date(moment(data?.dateString, DateUtils.yyyy_mm_dd)))
                              setPackageVisible(true)
                          }}
                          renderMarkedDates={renderMarkedDates}
                          markedDates={markedDate}
                />
            </View>
            <View style={styles.divider}/>

            <View style={styles.contentCont}>
                <ScrollView contentContainerStyle={{paddingVertical: ThemeUtils.relativeRealHeight(2)}}>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View>
                            <Label font_medium
                                   style={styles.stepperCont}
                                   mb={ThemeUtils.relativeRealHeight(2)}>
                                {"Din salgsprosess"}
                            </Label>
                            {data?.inspection_timeline_list?.filter(item => item?.completed_date)?.sort((a, b) => a?.completed_date.localeCompare(b?.completed_date))?.map(renderStepperCustom)}
                        </View>
                    )}
                </ScrollView>
            </View>

        </View>
    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(CalendarTab)
