export default {
  'component.styling.title': 'Styling',
  'component.styling.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  'component.styling.line1.heading1': 'Velg din styling',
  'component.styling.line2.radiobutton1': 'Gratis konsultasjon online',
  'component.styling.line2.radiobutton2': 'Konsultasjon i hjemmet',
  'component.styling.line2.radiobutton3': 'Full Styling',
  'component.styling.line3.heading1': 'Dato',
  'component.styling.line4':
    'Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimumstidsvindu er 4 timer.',
  'component.styling.line5.heading1': 'Tidligste starttid',
  'component.styling.line5.heading2': 'Siste start tidspunkt',
  'component.styling.line6.heading1': 'kommentarer',
  'component.styling.line7.placeholder': 'Skriv kommentarene dine her...',
  'component.styling.line8.button1': 'Rekkefølge',
  'component.styling.line8.button2': 'Avbryt',
  'component.styling.type': 'Stylingtype',
};
