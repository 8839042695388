import React, {useEffect, useState} from 'react';
//Components
import {Image, ScrollView, View} from 'react-native';
import {Header, Label, Ripple} from "components/src/components";
//Third party
import moment from 'moment'
//Network
import {useQuery} from 'react-apollo';
import {FAGLIG_DETAILS} from 'components/src/api/query';
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    openInNewTab,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import HtmlView from "../../../../../components/view/HTMLView";
import imagePlaceholder from "../../../../../assets/images/image_placeholder.png";


export default function FagligDetails(props) {
    const {navigation, history} = props;

    const [news, setNews] = useState(IS_WEB ? props.history.location?.state?.faglig ?? {} : props.navigation.getParam('faglig', {}))

    let newsId;
    if (IS_WEB) {
        newsId = decryptId(props.match.params.id);
    }

    const {loading, data, error} = useQuery(FAGLIG_DETAILS, {
        variables: {
            id: news?.id ?? newsId,
            status: 1
        }
    })

    useEffect(() => {
        if (data) {
            if (data?.professional_detail) {
                setNews(data?.professional_detail)
            } else {
                IS_WEB ? history.goBack() : navigation.goBack()
            }
        }

    }, [data])

    return (
        <View style={CommonStyle.flex}>
            <Header style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>
            <ScrollView>
                <View>
                    {news.cover_image_url ? (
                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${news.cover_image_url}`}}
                               defaultSource={imagePlaceholder}
                               resizeMode={'contain'}
                               style={styles.imgNews}/>) : null}
                    <Label xlarge
                           font_medium
                           mt={20}
                           mb={15}
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                        {news.title}
                    </Label>

                    <Label small
                           mb={10}
                           ml={20}
                           mr={20}>
                        {news.short_description}
                    </Label>
                    {/* <Label font_medium
                           mt={20}
                           mb={15}
                           ml={20}
                           mr={20}>
                        {'Insipidity the Sufficient Discretion'}
                    </Label>*/}
                    {news.description ?
                        (<View style={styles.descCont}>
                            <HtmlView value={news.description}/>
                        </View>) : null}
                    {news.video_url ? (<Ripple onPress={() => {
                        openInNewTab(`${Constants.APIConfig.STORAGE_URL}${news.video_url}`)
                    }}>
                        <Image source={`${Constants.APIConfig.STORAGE_URL}${news.video_url}`}
                               style={styles.imgNews}/>

                        <View style={styles.imgOverlay}>
                            <Icon name={'video'}
                                  size={48}
                                  color={Color.COLOR_ACCENT}/>
                        </View>
                    </Ripple>) : null}
                    <Label small
                           mt={20}
                           mb={20}
                           ml={20}
                           mr={20}
                           color={Color.SECONDARY_TEXT_COLOR}>
                        {`Nyhet er publisert ${moment.utc(news.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.dd_mm_yyyy)}`}
                    </Label>

                </View>
            </ScrollView>
        </View>
    )
}
