import React, {useRef} from 'react'
import {ActivityIndicator, View} from 'react-native'
import {Header, LogoForHeader, Modal, SketchField, Toast, Tools,} from "components/src/components";
import {Color, CommonStyle, Constants, IS_ANDROID, IS_WEB, showMessage, Strings, ThemeUtils} from "../../../utils";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BEFARING_NOTE} from "../../../api/query";
import {SAVE_NOTES} from "../../../api/mutation";
import {ReactNativeFile} from 'apollo-upload-client';

function Notes(props) {
    const {value, visible, id, onClose, onDashboardPress, navigation} = props;
    let sketch
    let toast = useRef();

    let fetchBlob = 'rn-fetch-blob'
    let permissionId = 'react-native-permissions'
    let Permission = {};
    let RNFetchBlob = {};

    if (!IS_WEB) {
        Permission = require(`${permissionId}`);
        RNFetchBlob = require(`${fetchBlob}`).default;
    }

    const {loading, data, error} = useQuery(BEFARING_NOTE, {
        // skip: !visible,
        skip: true,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (IS_WEB) {
                sketch && sketch.addImg(`${Constants.APIConfig.STORAGE_URL}${data?.befaring_note?.image}`, {
                    left: 0,
                    top: 0,
                    scale: 1,
                })
            } else {
                /*console.log(RNFetchBlob.fs.dirs.TemporaryDirectoryPath)
                let PictureDir = RNFetchBlob.fs.dirs.TemporaryDirectoryPath // this is the pictures directory. You can check the available directories in the wiki.
                let options = {
                    fileCache: true,
                    addAndroidDownloads: {
                        useDownloadManager: false, // setting it to true will use the device's native download manager and will be shown in the notification bar.
                        notification: false,
                        path: PictureDir + '/note.png',
                    }
                }
                RNFetchBlob.downloadFile({
                    fromUrl:`${Constants.APIConfig.STORAGE_URL}${data?.befaring_note?.image}`,
                    toFile:PictureDir + '/note.png'
                })
                RNFetchBlob.config(options).fetch('GET', ).then((res) => {
                    /!*res.blob().then((e)=>{
                        console.log(e, "RESp")
                    })

*!/
                    console.log(res, "RES")
                }).catch(e => console.log(e))*/
            }

        },
        variables: {
            inspectionId: id
        }
    });

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    const [saveNotes, saveNotesMutation] = useMutation(SAVE_NOTES)

    const download = (filename, text) => {
        let pom = document.createElement('a');
        pom.setAttribute('href', text);
        pom.setAttribute('download', filename);
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    };

    const saveNote = async () => {
        if (IS_WEB) {
            let data = await sketch.toDataURL();
            saveNotes({
                variables: {
                    inspectionId: id,
                    image: dataURLtoFile(data, "note.png")
                }
            })
        } else {
            sketch && sketch.getBase64('png', false, false, true, true, (err, base64Image) => {
                let path = RNFetchBlob.fs.dirs.DCIMDir + "/Weiendomsmegling/note.png";
                RNFetchBlob.fs.writeFile(path, base64Image, 'base64')
                    .then((res) => {
                        console.log(res, path, "RES")
                        saveNotes({
                            variables: {
                                inspectionId: id,
                                image: new ReactNativeFile({
                                    uri: `file://${path}`,
                                    name: "note.png",
                                    type: 'image/png'
                                })
                            }
                        })
                    });
            })


        }

    };

    const downloadFileOnMobile = async () => {

        let status
        let isGranted
        if (IS_ANDROID) {
            status = await Permission.check(Permission.PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE)
        } else {
            status = await Permission.check(Permission.PERMISSIONS.IOS.WRITE_EXTERNAL_STORAGE)
        }
        switch (status) {
            case Permission.RESULTS.UNAVAILABLE:
                break;
            case Permission.RESULTS.DENIED:
                //For Rationall add as second argument
                /*{
                    title: Strings.permissionRequired,
                    message: Strings.allowForPermission,
                    buttonPositive: Strings.okay,
                    buttonNegative: Strings.no,
                    buttonNeutral: Strings.cancleButton
                }*/
                if (IS_ANDROID) {
                    isGranted = await Permission.request(Permission.PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE)
                } else {
                    isGranted = await Permission.request(Permission.PERMISSIONS.IOS.WRITE_EXTERNAL_STORAGE)
                }

                break;
            case Permission.RESULTS.GRANTED:
                isGranted = status
                break;
            case Permission.RESULTS.BLOCKED:
                Permission.openSettings().catch(() => console.warn('cannot open settings'));
                break;
        }
        if (isGranted === Permission.RESULTS.GRANTED) {
            sketch && sketch.getBase64('png', false, false, true, true, (err, base64Image) => {

                const dirs = RNFetchBlob.fs.dirs;
                let path = dirs.DCIMDir + "/Weiendomsmegling/note.png";
                RNFetchBlob.fs.writeFile(path, base64Image, 'base64')
                    .then((res) => {
                        showMessage(`${Strings.fileSavedSuccess} at ${path}`, Constants.MessageType.SUCCESS)
                        console.log(res, path, "RES")
                    });
            })
        }


    }
    return (
        <Modal visible={visible}
               animated
               hardwareAccelerated
               onRequestClose={() => onClose()}>
            <Toast ref={toast}
                   position={"bottom"}/>
            <Header onPressLeft={() => onClose()}
                    renderTitle={() => <LogoForHeader/>}
                    headerRightSecond={'tickmark'}
                    headerRightThird={'dashboard'}
                    onPressRightThird={() => onDashboardPress()}
                    onPressRightFirst={() => IS_WEB ? download('image.png', sketch.toDataURL()) : downloadFileOnMobile()}
                    onPressRightSecond={saveNote}
                    headerRightFirst={'download'}
                    tintColor={Color.SECONDARY}/>
            {loading ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <View style={CommonStyle.flex}>
                    <SketchField tool={Tools?.Pencil}
                                 lineColor='black'
                                 lineWidth={3}
                                 imageFormat={'png'}
                                 backgroundColor={Color.WHITE}
                                 height={ThemeUtils.relativeHeight(100)}
                                 value={value}
                                 ref={r => IS_WEB ? sketch = r : null}
                                 mobileRef={r => sketch = r}
                                 width={ThemeUtils.relativeWidth(100)}/>
                </View>
            )}

        </Modal>
    )
}

export default Notes
