import React, {useState} from "react";
import {ActivityIndicator, View} from "react-native";
import {Header, IndicatorViewPager, LogoForHeader, Notes, PagerTabIndicator,} from "components/src/components";
import {Color, CommonStyle, Constants, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from "./styles";
import LookBookMobile from "./Mobile/LookBook";
import LookBookTab from "./Tab/LookBook";
import NewsPaperAdvertisingMobile from "./Mobile/NewsPaperAdvertising";
import NewsPaperAdvertisingTab from "./Tab/NewsPaperAdvertising";
import DMMobile from "./Mobile/DM";
import DMTab from "./Tab/DM";
import RomPlakaterMobile from "./Mobile/RomPlakater";
import RomPlakaterTab from "./Tab/RomPlakater";
import ForSalePosterMobile from "./Mobile/ForSalePoster";
import ForSalePosterTab from "./Tab/ForSalePoster";
import {useQuery} from "@apollo/react-hooks";
import {SELECTED_PROPERTY_SERVICE} from "../../../../../../api/query";
import {connect} from "react-redux";

const TABS = [
    {
        text: Strings.LOOKBOOK,
    },
    {
        text: Strings.NEWSPAPERADVERTISING,
    },
    {
        text: Strings.DM,
    },
    {
        text: Strings.ROMPLAKATER,
    },
    {
        text: Strings.FORSALEPOSTER,
    },
];

function PrintingMarketing(props) {
    const {history, inspection, navigation} = props;
    const [visible, setVisible] = useState(false)

    const {loading, data, refetch, error} = useQuery(SELECTED_PROPERTY_SERVICE, {
        variables: {
            status: true,
            propertyId: inspection?.property_id,
            category: Constants.ServiceCategory.PrintMarketing
        }
    });

    let lookbookData = data?.selected_property_services?.services ? data?.selected_property_services?.services[0]?.purchased_office_template : {}
    let newspaperData = data?.selected_property_services?.services ? data?.selected_property_services?.services[1]?.purchased_office_template : {}
    let dmData = data?.selected_property_services?.services ? data?.selected_property_services?.services[2]?.purchased_office_template : {}
    let romplakaterData = data?.selected_property_services?.services ? data?.selected_property_services?.services[3]?.purchased_office_template : {}
    let forSaleData = data?.selected_property_services?.services ? data?.selected_property_services?.services[4]?.purchased_office_template : {}
    let isCompanyTemplate = inspection.template_type !== "PERSONAL"

    let selectedPackage = data?.selected_property_services?.marketing_package ? data?.selected_property_services?.marketing_package.filter(item => item.property_package) : []
    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}
                               scrollEnabled={!ThemeUtils.isTablet()}/>
        );
    };

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }

    return (
        <View style={styles.container}>
            {renderNoteModal()}
            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <IndicatorViewPager
                    scrollEnabled={false}
                    indicator={renderTabIndicator()}
                    style={CommonStyle.flex}>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <LookBookTab data={lookbookData}
                                         isCompanyTemplate={isCompanyTemplate}
                                         packages={selectedPackage}
                                         refetch={refetch}
                                         {...props}/> :
                            <LookBookMobile data={lookbookData}
                                            isCompanyTemplate={isCompanyTemplate}
                                            packages={selectedPackage}
                                            refetch={refetch}
                                            {...props}/>}
                    </View>
                    <View>{ThemeUtils.isTablet() ?
                        <NewsPaperAdvertisingTab data={newspaperData}
                                                 isCompanyTemplate={isCompanyTemplate}
                                                 packages={selectedPackage}
                                                 refetch={refetch}
                                                 {...props}/> :
                        <NewsPaperAdvertisingMobile data={newspaperData}
                                                    isCompanyTemplate={isCompanyTemplate}
                                                    packages={selectedPackage}
                                                    refetch={refetch}
                                                    {...props}/>}
                    </View>

                    <View>{ThemeUtils.isTablet() ?
                        <DMTab data={dmData}
                               isCompanyTemplate={isCompanyTemplate}
                               packages={selectedPackage}
                               refetch={refetch}
                               {...props}/> :
                        <DMMobile data={dmData}
                                  isCompanyTemplate={isCompanyTemplate}
                                  packages={selectedPackage}
                                  refetch={refetch}
                                  {...props}/>}
                    </View>

                    <View>{ThemeUtils.isTablet() ?
                        <RomPlakaterTab data={romplakaterData}
                                        isCompanyTemplate={isCompanyTemplate}
                                        packages={selectedPackage}
                                        refetch={refetch}
                                        {...props}/> :
                        <RomPlakaterMobile data={romplakaterData}
                                           isCompanyTemplate={isCompanyTemplate}
                                           packages={selectedPackage}
                                           refetch={refetch}
                                           {...props}/>}
                    </View>

                    <View>{ThemeUtils.isTablet() ?
                        <ForSalePosterTab data={forSaleData}
                                          isCompanyTemplate={isCompanyTemplate}
                                          packages={selectedPackage}
                                          refetch={refetch}
                                          {...props}/> :
                        <ForSalePosterMobile data={forSaleData}
                                             isCompanyTemplate={isCompanyTemplate}
                                             packages={selectedPackage}
                                             refetch={refetch}
                                             {...props}/>}
                    </View>

                </IndicatorViewPager>
            )}
        </View>
    );
}


const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(PrintingMarketing)
