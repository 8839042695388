import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "../../../utils";

export default StyleSheet.create({
    cont:{
        flex:1,
        flexDirection:'row',
        borderRadius:6,
        paddingStart:ThemeUtils.relativeRealWidth(2),
        paddingEnd:ThemeUtils.relativeRealWidth(1),
        paddingVertical:ThemeUtils.relativeRealHeight(1),
        alignItems:'center',
        maxWidth:720
    },
    icCont:{
        padding:8
    }
})
