import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

})
