export default {
  'component.news.title': 'Nyheter',
  'component.news.addbutton': 'Legg til nyheter',
  'component.news.editbutton': 'Redigere',
  'component.news.newslatest': 'Nyheter Siste overskrift',
  'component.news.coverphoto': 'Forsidebilde',
  'component.news.uploadvideo': 'Last opp video',
  'component.news.publish': 'Publisere',
  'component.news.newstitleplaceholder': 'Skriv inn nyhetstittel',
  'component.news.newstitle': 'Nyhetstittel',
  'component.news.newsdesc': 'Nyhetsbeskrivelse',
  'component.news.newsdescplaceholder': 'Skriv inn nyhetsbeskrivelse her ...',
  'component.news.deletenews': 'Slett nyheter',
  'component.news.deletenewsdesc': 'Er du sikker på at du vil slette denne nyheten?',
  'component.news.editnews': 'Rediger nyheter',
};
