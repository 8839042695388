import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    photocheck: {
        position: 'absolute',

        zIndex: 1,
        justifyContent: 'center',
        marginTop: ThemeUtils.relativeWidth(3.5),
        marginLeft: ThemeUtils.relativeWidth(1)
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },
    titleCont: {
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
        marginTop: ThemeUtils.relativeRealHeight(1.5),
    },
    GridImage: {
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        height: ThemeUtils.responsiveScale(210),
        width: ThemeUtils.responsiveScale(210),
        maxHeight: 210,
        maxWidth: 210,
        marginTop: ThemeUtils.relativeWidth(2.8),
        borderWidth: 0.5,
        borderColor: Color.PRIMARY_LIGHTER
    },

    SubViewIcon: {
        backgroundColor: Color.PRIMARY,
        height: ThemeUtils.responsiveScale(210),
        width: ThemeUtils.responsiveScale(210),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    SubView: {
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2,
        alignItems: 'flex-start'
    },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",
        borderWidth: 0.5,

    },
    DetailsView: {marginTop: 30},
    MainView: {
        flexDirection: "row",
        marginTop: 10,
        flex: 1,
    },

    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom: 20
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    labelTopView: {
        alignItems: 'flex-start',
        flex: 1,
        flexDirection: 'row',
        marginTop: 15,
        alignContent: 'center',
        justifyContent: 'space-between'
    },

    energyradio: {
        marginBottom: 15,
        fontSize: 12,
        alignContent: 'center',

    },
    fieldView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
    uploadView:
        {
            flexDirection: 'row',
            width: '125px',
            height: '48px',
            alignItems: 'center',
            borderWidth: 2,
            borderColor: Color.INPUT_BORDER,
            justifyContent: 'center',
            alignContent: 'center',
            marginBottom: 15,
            borderRadius: 6
        },
    modalContent: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,

        justifyContent: 'center',
        paddingHorizontal: 20
    },
    inptCont: {
        backgroundColor: Color.WHITE,
        borderRadius: 8
    },
    btnCont: {
        flexDirection: 'row',
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom: 10
    },
    divider: {
        width: 1,
        backgroundColor: Color.PRIMARY_LIGHT
    },
    btnModal: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        paddingVertical: 10
    },
    loader: {
        marginTop: 15
    },
    propertySelectionCont: {
        flexDirection: 'row',
        marginTop: 20,
    },
    IconsView: {
        backgroundColor: Color.GRAY,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: 10,
        marginEnd: 10,
        borderRadius: 4
    },
    bottomButtonContainer: {
        flexDirection: 'row',
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: 10
    }

});
export {styles};
