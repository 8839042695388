import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Color.SECONDARY_BG_COLOR,

    },
    contentCont: {
        marginVertical: 20,
        width: ThemeUtils.relativeWidth(28),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },

    img: {
        marginTop: 40,
        marginBottom: 20,
        marginStart: 30,
        height: ThemeUtils.responsiveScale(82),
        width: ThemeUtils.responsiveScale(277),
        maxWidth: 277,
        maxHeight: 82,
        backgroundColor: Color.GRAY
    },
    schemeCont: {
        flexDirection: "row",
        alignItems: 'center',
        marginTop: 5,
    },
    lblCont: {
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    },
    accordionContent: {
        paddingVertical: 0,
        paddingHorizontal: 15,
        backgroundColor: Color.WHITE
    },
    accordionHeader: {
        marginTop: 10,
        paddingStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.WHITE
    },
    testimonialCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        alignItems: 'center'
    },
    pager: {
        height: ThemeUtils.relativeRealHeight(15)
    },
    mediaImgCont: {
        width: ThemeUtils.responsiveScale(220),
        marginBottom: 20,
        marginEnd: 20,
    },
    mediaImage: {
        height: ThemeUtils.responsiveScale(220),
        width: ThemeUtils.responsiveScale(220),
    },
    divider: {
        backgroundColor: Color.PRIMARY_LIGHTER,
        width: 1,
    },
    singleLineLabel: {
        marginTop: ThemeUtils.relativeRealHeight(0.5),
        marginBottom: ThemeUtils.relativeRealHeight(0.5)
    },
    title: {
        fontFamily: 'Maax-Medium',
        color:Color.PRIMARY_TEXT_COLOR
    },
    v2Cont:{
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    }
})
