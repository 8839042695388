import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Animated, Image, SafeAreaView, StyleSheet, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import {CustomButton, HTMLView, Label, Modal, Ripple} from 'components/src/components';
import {Color, CommonStyle, Constants, Icon, Strings, ThemeUtils} from '../../../utils';

// import ButtonStyle from '@ant-design/react-native/lib/button/style/index';


const AddServiceView = (props) => {

    const [visible, setVisible] = useState(false)
    const scrollY = new Animated.Value(ThemeUtils.relativeRealHeight(50))
    const onPress = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    const getStyleArray = () => {
        let stylesArray = [];

        stylesArray.push({
            borderWidth: props.borderWidth,
            borderRadius: props.borderRadius,
            borderColor: props.borderColor,

            marginTop: props.mt,
            marginBottom: props.mb,
            marginLeft: props.ml,
            marginRight: props.mr,

            width: '100%',
            backgroundColor: Color.TRANSPARENT
        });

        return stylesArray;
    }

    const getTextColor = () => props.isSelected ? Color.TERNARY_TEXT_COLOR : Color.PRIMARY_DARK;
    const getIconColor = () => props.isSelected ? Color.TERNARY_TEXT_COLOR : Color.PRIMARY;

    useEffect(() => {
        if (visible) {
            Animated.timing(scrollY, {
                toValue: 0,
                duration: 150
            }).start();
        }
    }, [visible]);

    const closeAnimation = () => {
        Animated.timing(scrollY, {
            toValue: ThemeUtils.relativeRealHeight(50),
            duration: 150
        }).start(() => {
            setVisible(false)
        })
    };

    const renderDetailsModal = () => (
        <Modal transparent
               onRequestClose={() => closeAnimation()}
               visible={visible}>
            <SafeAreaView style={CommonStyle.flex}>
                <View style={CommonStyle.flex}>
                    <TouchableOpacity activeOpacity={1}
                                      onPress={() => closeAnimation()}
                                      style={{
                                          flex: 1,
                                          zIndex: 10,
                                          backgroundColor: Color.PRIMARY_LIGHT,
                                      }}>
                        <Animated.View style={[styles.modalCont, {
                            transform: [{translateY: scrollY}]
                        }]}>

                            <TouchableOpacity activeOpacity={1}>
                                <Label font_medium
                                       xlarge
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mt={15}>
                                    {props.title}
                                </Label>
                                <Label mt={10}
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                    {props.price}
                                </Label>
                                {props.isHtml ? (
                                    <View style={{
                                        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
                                        marginTop: 10,
                                        marginBottom: 15
                                    }}>
                                        <HTMLView value={props.desc}/>
                                    </View>
                                ) : (<Label small
                                            font_medium={props.service}
                                            mt={10}
                                            mb={15}
                                            ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                            mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                    {props.desc}
                                </Label>)}
                                {props.service ? props.service.map(item => (
                                    <Label small
                                           mb={10}
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                        {item.name}
                                    </Label>
                                )) : null}
                                <CustomButton title={Strings.okay}
                                              maxWidth={ThemeUtils.relativeWidth(140)}
                                              onPress={() => closeAnimation()}
                                              style={{borderRadius: 0}}/>
                            </TouchableOpacity>

                        </Animated.View>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        </Modal>
    );

    return (
        <>
            {renderDetailsModal()}
            <TouchableOpacity activeOpacity={0.5}
                              style={[getStyleArray(),
                                  styles.mainContainer,
                                  {
                                      backgroundColor:
                                          props.isSelected ? Color.PRIMARY_DARK : Color.TRANSPARENT
                                  }]}
                              onPress={props.onPress}>
                <View style={styles.imageContainer}>
                    <Image style={styles.image}
                           source={typeof props.image === "number" ? props.image : {uri: `${Constants.APIConfig.STORAGE_URL}${props.image}`}}
                    />
                    {/* <CustomFadeImage
                            style={styles.image}
                            source={{uri: props.image}}
                            isImageURLAvailable={true}
                            duration={300}
                            resizeMode={'contain'}/> */}
                </View>
                <View style={[styles.mainViewLabelIcon]}>
                    <View style={styles.detailsContainer}>
                        <Label
                            color={getTextColor()}
                            font_medium
                            small
                            ml={11}
                            mb={3}>
                            {props.title}
                        </Label>
                        <Label
                            color={getTextColor()}
                            small
                            font_regular
                            align={'left'}
                            ml={11}
                            mt={3}>{props.price}</Label>
                        {props.extraNote ?
                            (<Ripple style={[styles.extraNote, props.isSelected ? styles.selectedExtraNote : null]}
                                     onPress={props.onPressExtraNote}
                                     rippleContainerBorderRadius={4}>
                                <Label xsmall
                                       font_medium
                                       color={props.isSelected ? Color.PRIMARY : Color.WHITE}>
                                    {props.extraNote}
                                </Label>
                            </Ripple>) : null}
                    </View>

                    {props.loading ? (
                        <ActivityIndicator color={props.isSelected ? Color.SECONDARY : Color.PRIMARY}
                                           size={ThemeUtils.fontSmall}
                                           style={styles.PhotoIcon}/>
                    ) : (<TouchableOpacity activeOpacity={0.5}
                                           onPress={() => setVisible(true)}
                                           style={styles.PhotoIcon}>
                        <Icon color={getIconColor()}
                              size={ThemeUtils.fontSmall}
                              name={'faq'}/>
                    </TouchableOpacity>)}
                </View>
            </TouchableOpacity>
        </>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'row',
        padding: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(1) : 8,
    },
    imageContainer: {
        borderWidth: 0,
        borderRadius: 5,

        borderColor: Color.INPUT_BORDER,
    },
    image: {
        height: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(10) : 60,
        width: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(10) : 60,
        aspectRatio: 1,
        borderRadius: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(1) : 5,
    },
    detailsContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    extraNote: {
        alignSelf: 'flex-start',
        marginStart: 10,
        marginTop: 3,
        borderRadius: ThemeUtils.relativeRealWidth(2),
        backgroundColor: Color.PRIMARY,
        paddingHorizontal: 5,
        paddingVertical: 2.5
    },
    selectedExtraNote: {
        backgroundColor: Color.PRIMARY_BG_COLOR,
    },
    PhotoIcon: {
        justifyContent: 'center',
        alignSelf: 'center',
        padding: ThemeUtils.relativeWidth(2),

    },
    mainViewLabelIcon: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    modalCont: {
        backgroundColor: Color.WHITE,
        position: 'absolute',
        start: 0,
        end: 0,
        bottom: 0
    }
});

AddServiceView.defaultProps = {
    borderColor: Color.INPUT_BORDER,
    borderWidth: 0.5,
    borderRadius: 5,

    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
};

AddServiceView.propTypes = {
    borderColor: PropTypes.string,
    desc: PropTypes.string,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    onPressExtraNote: PropTypes.func,
    onPressDesc: PropTypes.func,
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
};

export default AddServiceView;
