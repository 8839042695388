import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

export default StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,
    },
    contentCont: {
        marginHorizontal: containerHorizontalMargin,
    },
    titleCont: {

        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    orderCont: {
        marginHorizontal: containerHorizontalMargin,
        marginTop: 15,
    },

    lineStyle: {
        backgroundColor: Color.LIGHT_GRAY,
        marginHorizontal: ThemeUtils.relativeRealWidth(3),
        marginVertical: 10
    },
    btnOkay: {
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginVertical: 10
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        // alignContent: 'center'
    },

    SubViewIcon: {
        backgroundColor: Color.PRIMARY,
        height: ThemeUtils.responsiveScale(210),
        width: ThemeUtils.responsiveScale(210),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    propertySelectionCont: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10
    },
    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: 20
    },
    GridImage: {
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        maxHeight: 200,
        maxWidth: 200,
        marginTop: ThemeUtils.relativeWidth(2.8),
        borderWidth: 0.5,
        borderColor: Color.PRIMARY_LIGHTER
    },
    selectedImage: {
        padding: 10,
        position: 'absolute',
        top: ThemeUtils.relativeWidth(2.8),
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        backgroundColor: Color.PRIMARY_LIGHT
    },

    modalCont: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: ThemeUtils.relativeWidth(10),
        backgroundColor: Color.PRIMARY_TRANSPARENT
    },
    dialogCont: {
        backgroundColor: Color.WHITE,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingTop: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 8,
        maxHeight: 800
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 20
    },
    btnCont: {
        flexDirection: 'row',
        paddingBottom: 10,
        marginTop: 15,
        justifyContent: 'flex-end'
    },
    divider: {
        width: 1,
        backgroundColor: Color.DARK_GRAY
    },
    pickerCont: {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    imagePicker: {
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(110),
        width: ThemeUtils.responsiveScale(110),
        maxHeight: 210,
        maxWidth: 210,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    halfImgCont: {
        position: 'absolute',
        overflow: 'hidden',
        top: ThemeUtils.relativeWidth(2.8),
        width: ThemeUtils.responsiveScale(ThemeUtils.isTablet() ? 80 : 100),
    },
    halfImage: {
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        maxHeight: 200,
        maxWidth: 200,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
    }
})
