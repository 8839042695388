import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {connect} from "react-redux";
import styles from "../../../../../../../../components/ui/RadioGroup/styles";
import {Color, CommonStyle, Icon, ThemeUtils} from "../../../../../../../../utils";


const OPTIONS_1 = [
    {name: 'Hva var det som var bra?', value: 1},
    {name: 'Var det noe dere synes ikke gikk bra?', value: 2},
];

const OPTIONS_3 = [
    {name: 'Hva var det som var bra?', value: 1},
    {name: 'Var det noe dere synes ikke gikk bra?', value: 2},
];
const OPTIONS_2 = [
    {name: 'Hva var det som var bra?', value: 3},
    {name: 'Hva har dere tenkt å gjøre annerledes nå slik at det ikke skjerigjen?', value: 4},
    {name: 'Hvordan kan dere være sikre på at megleren dere velger ikke gjør det samme denne gang?', value: 5},
];

function SecondQuestion(props) {
    const {onChangeAnswer, fromPrev, setForm, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answerThird, setAnswertThird] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers])

    /*useEffect(() => {
        props.onAnswer(answersSec)
    }, [answersSec])*/

    const getQuestion = () => {
        switch (fromPrev) {
            case 1:
                return "Det gikk bra";
            case 2:
                return "Nei";
        }
    }
    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {getQuestion()}
            </Label>

            {fromPrev === 1 ? (
                <>
                    <RadioGroup options={OPTIONS_1}
                                style={{marginVertical: 10}}
                                textStyle={CommonStyle.flex}
                                labelProps={{
                                    large: true
                                }}
                                onSelectOption={setAnswerFirst}/>
                    {answers === 2 ? (
                        <>
                            <TouchableOpacity activeOpacity={1}
                                              style={{
                                                  marginVertical: 10,
                                                  marginStart: ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)
                                              }}
                                              onPress={() => setAnswertThird('Yes')}>
                                <View style={[styles.container]}>
                                    <Icon name={answerThird === 'Yes' ? 'radio-select' : 'radio-normal'}
                                          size={ThemeUtils.fontNormal}
                                          color={answerThird === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                                    <Label large
                                           style={[styles.rightText]}>
                                        {'Ja'}
                                    </Label>
                                </View>
                            </TouchableOpacity>

                            {answerThird === 'Yes' ? (
                                <Label small
                                       ml={ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}>
                                    {'Hva var det som ikke gikk bra'}
                                </Label>) : null}

                            <TouchableOpacity activeOpacity={1}
                                              style={{
                                                  marginVertical: 10,
                                                  marginStart: ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)
                                              }}
                                              onPress={() => setAnswertThird('No')}>
                                <View style={[styles.container]}>
                                    <Icon name={answerThird === 'No' ? 'radio-select' : 'radio-normal'}
                                          size={ThemeUtils.fontNormal}
                                          color={answerThird === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                                    <Label large
                                           style={[styles.rightText]}>
                                        {'Nei, alt gikk bra'}
                                    </Label>
                                </View>
                            </TouchableOpacity>

                            {answerThird === 'No' ? (
                                <Label small
                                       ml={ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}>
                                    {"Så bra! Men hva er det som gjør at dere da " +
                                    "ønsker å høre med andre meglere når dere nå " +
                                    "skal selge? Grunnen til at jeg spør er fordi alle " +
                                    "jeg har solgt for kommer tilbake til meg neste " +
                                    "gang de skal selge, med mindre det er i en " +
                                    "annen by."}
                                </Label>) : null}
                        </>
                    ) : null}

                </>
            ) : null}

            {fromPrev === 2 ? (
                <>
                    <TouchableOpacity activeOpacity={1}
                                      style={{
                                          marginVertical: 10,
                                      }}
                                      onPress={() => setAnswerFirst(1)}>
                        <View style={[styles.container]}>
                            <Icon name={answers === 1 ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={answers === 1 ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Hva var det som ikke gikk bra?'}
                            </Label>
                        </View>
                    </TouchableOpacity>

                    {answers === 1 ? (
                        <>
                            <Label large
                                   mt={10}
                                   ml={ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)}>
                                {'Hva kan konsekvensen av det ha vært for dere?'}
                            </Label>
                            <Label large
                                   mt={10}
                                   ml={ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}>
                                {'- Kan det ha medført at dere ikke fikk en så høy ' +
                                'pris som dere kunne fått om alt hadde vært ' +
                                'gjort 100%?'}
                            </Label>
                            <Label large
                                   mt={10}
                                   ml={ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)}>
                                {'- Hvor mye kan det i verste fall ha utgjort i' +
                                'forskjell for dere?'}
                            </Label>
                        </>) : null}

                    <TouchableOpacity activeOpacity={1}
                                      style={{
                                          marginVertical: 10,
                                      }}
                                      onPress={() => setAnswerFirst(2)}>
                        <View style={[styles.container]}>
                            <Icon name={answers === 2 ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={answers === 2 ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Hva har dere tenkt å gjøre annerledes nå slik at det ikke skjer ' +
                                'igjen?'}
                            </Label>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity activeOpacity={1}
                                      style={{
                                          marginVertical: 10,
                                      }}
                                      onPress={() => setAnswerFirst(3)}>
                        <View style={[styles.container]}>
                            <Icon name={answers === 3 ? 'radio-select' : 'radio-normal'}
                                  size={ThemeUtils.fontNormal}
                                  color={answers === 3 ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                            <Label large
                                   style={[styles.rightText]}>
                                {'Hvordan kan dere være sikre på at megleren dere velger ikke ' +
                                'gjør det samme denne gang?'}
                            </Label>
                        </View>
                    </TouchableOpacity>


                </>
            ) : null}
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(SecondQuestion)
