export default {
  'component.inspection.title': 'Befaringsapplikasjon',
  'component.inspection.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
  'component.inspection.addinspection': 'Legg til befaringer',
  'component.inspection.editinspection': 'Rediger befaringer',
  'component.inspection.tablesearch':
    'Søk etter navn, e-post, telefon, eiendomsadresse og postnummer',
  'component.inspection.deletemodal.title': 'Slett Inspeksjon',
  'component.inspection.deletemodal.text': 'Er du sikker på at du vil slette denne Inspeksjonen?',
};
