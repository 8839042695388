import {StyleSheet} from 'react-native';
import {ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        marginTop:ThemeUtils.relativeRealHeight(1.5),
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    newsCont:{
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    imgCont:{
        flexDirection:'row',
        alignItems:'center'
    },
    imgNews:{
        height:ThemeUtils.relativeRealWidth(20),
        width:ThemeUtils.relativeRealWidth(20),
        borderRadius:ThemeUtils.relativeRealWidth(3)
    }
})
