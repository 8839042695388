import React, {useEffect, useState} from 'react';
import {Animated, Image, View} from 'react-native'
//Components
import {CustomButton, FilePicker, FileType, Header, InputField, Label} from "components/src/components";
//Third party
//Network
import {useMutation} from "@apollo/react-hooks";
import {UPLOAD_PHOTO} from "components/src/api/mutation";
import {PHOTOS} from "components/src/api/query";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';


function UploadPhoto(props) {
    const {history, navigation} = props;

    const id = IS_WEB ? history.location.state.id : navigation.getParams('id');
    const propertyId = IS_WEB ? history.location.state.propertyId : navigation.getParams('propertyId');
    if (!id || !propertyId) {
        IS_WEB ? history.goBack() : navigation.goBack()

    }

    const [fileName, setFileName] = useState('');
    const [comment, setComment] = useState('');
    const [files, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        if (files[0]) {
            setFileUrl(IS_WEB?URL.createObjectURL(files[0]):files[0]?.uri)
        }
    }, [files])

    const [uploadPhoto, {loading, data, error}] = useMutation(UPLOAD_PHOTO, {
        refetchQueries: [{
            query: PHOTOS,
            variables: {
                propertyId: propertyId
            },
        }]
    })
    const scrollY = new Animated.Value(0)

    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.uploadNewPhoto}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <View style={CommonStyle.flex}>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.scrollCont}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View style={styles.contentCont}>

                        <Label small
                               font_medium
                               mt={18}
                               mb={15}>
                            {Strings.upload}
                        </Label>
                        <FilePicker type={FileType.IMAGE}
                                    onFilePicked={(data, file) => {
                                        setFile(file)
                                    }}
                                    style={{alignSelf: 'flex-start'}}>
                            {files.length ? (
                                <Image source={IS_WEB ? fileUrl : {uri:fileUrl}}
                                       style={styles.img}/>
                            ) : (
                                <View style={styles.SubViewIcon}>
                                    <Icon name={'add'}
                                          size={ThemeUtils.relativeWidth(10)}
                                          color={Color.INPUT_BORDER}/>
                                </View>)}
                        </FilePicker>

                        <InputField labelText={Strings.photoTitle}
                                    mt={15}
                                    type={'text'}
                                    placeholder={Strings.photoTitle}
                                    value={fileName}
                                    onChange={setFileName}
                                    labelProps={{
                                        small: true,
                                        font_medium: true
                                    }}/>
                        <InputField labelText={Strings.comment}
                                    textArea={'textArea'}
                                    placeholder={Strings.commentPlaceholder}
                                    value={comment}
                                    onChange={setComment}
                                    labelProps={{
                                        small: true,
                                        font_medium: true
                                    }}/>
                    </View>

                </Animated.ScrollView>
            </View>

            <CustomButton title={Strings.save}
                          ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                          mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                          mt={10}
                          loading={loading}
                          mb={10}
                          onPress={() => uploadPhoto({
                              variables: {
                                  photo: files,
                                  photoOrderId: id
                              },
                          }).then(() => {
                              IS_WEB ? history.goBack() : navigation.goBack()
                          }).catch(() => {

                          })}/>


        </View>
    )
}

export default UploadPhoto;
