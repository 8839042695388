export default {
  'component.inspectionapplications.title': 'Inspeksjonssøknad',
  'component.inspectionapplications.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.inspectionapplications.editinscpectionapplication': 'Edit inscpectionapplication',
  'component.inspectionapplications.tablesearch': 'Søk etter Opprdrag nr. Eiendomsadresse',
  'component.inspectionapplications.inspectionreport': 'Inspeksjonsrapport',
  'component.inspectionapplications.sellersinterview': 'Selgers intervju',
  'component.inspectionapplications.addvertiseextrarooms': 'Annonser ekstra rom',
  'component.inspectionapplications.livingroom': 'Stue',
  'component.inspectionapplications.windscreenormaincourse': 'Frontrute / hovedrett',
  'component.inspectionapplications.threadorintermediate': 'Tråd / mellomliggende',
  'component.inspectionapplications.kitchen': 'Kjøkken',
  'component.inspectionapplications.kitchentype': 'Kjøkken Type',
  'component.inspectionapplications.kitchen.placeholder': 'Angi Kjøkkentype',
  'component.inspectionapplications.kitchentype.placeholder': 'Angi Kjøkkentype',
  'component.inspectionapplications.bedroom1': 'Soverom 1',
  'component.inspectionapplications.bedroom2': 'Soverom 2',
  'component.inspectionapplications.bedroom3': 'Soverom 3',
  'component.inspectionapplications.livingordiningorbasement':
    'Stue 2 / Spisestue / Kjeller / Loft',
  'component.inspectionapplications.bathroom1': 'Baderom 1',
  'component.inspectionapplications.bathroom2': 'Baderom 2',
  'component.inspectionapplications.generalimpression': 'Generelt inntrykk av eiendommen',
  'component.inspectionapplications.generalimpression.placeholder': 'Skriv her...',
  'component.inspectionapplications.othercomment': 'Annen kommentar',
  'component.inspectionapplications.othercomment.placeholder': 'Skriv Din Kommentar Her ...',
  'component.inspectionapplications.sendreport': 'Send Rapport',
  'component.inspectionapplications.sellersinterview.description':
    'Dette er spørsmål som kan være viktige for potensielle kjøpere. Ikke alle spørsmål vil være så relevante for alle typer boliger, men svarene vil være så omfattende som mulig.',
  'component.inspectionapplications.ownershiporproperty': 'Eierskap / eiendom',
  'component.inspectionapplications.next': 'Neste',
  'component.inspectionapplications.floor': 'Gulv',
  'component.inspectionapplications.parquet': 'Parkett',
  'component.inspectionapplications.laminate': 'Laminatet',
  'component.inspectionapplications.ceramictile': 'Keramisk flis',
  'component.inspectionapplications.slate': 'Skifer',
  'component.inspectionapplications.linoleum': 'Linoleum',
  'component.inspectionapplications.other': 'Annet',
  'component.inspectionapplications.floortype.placeholder': 'Angi gulvtype',
  'component.inspectionapplications.walls': 'Vegger',
  'component.inspectionapplications.paintedplate': 'Malt plate',
  'component.inspectionapplications.theloss': 'Tapet',
  'component.inspectionapplications.panel': 'Panel',
  'component.inspectionapplications.tiles': 'Fliser',
  'component.inspectionapplications.walltype.placeholder': 'Angi veggtype',
  'component.inspectionapplications.ceiling': 'Tak',
  'component.inspectionapplications.takess': 'Takess',
  'component.inspectionapplications.importantdetailsorcomments': 'Viktige detaljer / kommentar',
  'component.inspectionapplications.importantdetailsorcomments.placeholder':
    'Skriv viktige detaljer / kommentar her ...',
  'component.inspectionapplications.worktop': 'Benkeplate',
  'component.inspectionapplications.worktoptype.placeholder': 'Angi Benkeplate Type',
  'component.inspectionapplications.three': 'Tre',
  'component.inspectionapplications.concrete': 'Betong',
  'component.inspectionapplications.marble': 'Marmor',
  'component.inspectionapplications.composite': 'Sammensatte',
  'component.inspectionapplications.pottery': 'Keramikk',
  'component.inspectionapplications.applianceshandled': 'Hvitevarer inkludert',
  'component.inspectionapplications.refrigerator': 'Kjøleskap',
  'component.inspectionapplications.oven': 'Stekeovn',
  'component.inspectionapplications.microwaveoven': 'Mikrobølgeovn',
  'component.inspectionapplications.dishwasher': 'Oppvaskmaskin',
  'component.inspectionapplications.roomaname': 'Romnavn',
  'component.inspectionapplications.hamling': 'Hamling',
  'component.inspectionapplications.hamlingtype': 'Hamling Type',
  'component.inspectionapplications.hamlingtype.placeholder': 'Tast inn Hamling Type',
  'component.inspectionapplications.success.text': 'Befaringsrapport sendt!',
};
