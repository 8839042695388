import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, View} from 'react-native';
import {Dashboard, Header, LogoForHeader} from "components/src/components";
//Third party
//Utils
import {Color, CommonStyle, IS_WEB, Routes, Strings} from "components/src/utils";
import styles from './styles';
import energy_labeling from "../../../../assets/images/Photo.png";
import Marketing from "../../../../assets/images/Marketing.png";
import photo from "../../../../assets/images/Seller.jpg";
import digitalMarketing from "../../../../assets/images/Video_Thumbnail.png";
import marketingMaterial from "../../../../assets/images/InfofromSeller.png";
import {useQuery} from "@apollo/react-hooks";
import {SEND_OFFER_DETAILS} from "../../../../api/query";
import {connect} from "react-redux";


const DASHBOARD = [{
    name: Strings.Befaringsbekreftelse,
    icon: 'profile',
    bg: photo,
    route: Routes.GetStartedBefaringsbekreftelse,
    id: 'befaringsbekreftelse'
}, {
    name: Strings.aboutW,
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
    route: Routes.GetStartedAboutW,

}, {
    name: Strings.preparation,
    icon: 'preparations',
    bg: marketingMaterial,
    route: Routes.GetStartedPreparation,

}, {
    name: Strings.questionToAsk,
    icon: 'faq',
    bg: Marketing,
    route: Routes.GetStartedQuestion,

}, {
    name: Strings.productGallery,
    icon: 'product-gallery',
    bg: energy_labeling,
    route: Routes.GetStartedProductGallery,
    id: 'product_gallery',

}];

function WBefaringDashboard(props) {
    const {navigation, user, history} = props;

    const [officeDetails, setData] = useState({})
    const {loading, data, error} = useQuery(SEND_OFFER_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            id: 1
        }
    });

    useEffect(() => {
        if (data?.office) {
            setData(data?.office)
        }
    }, [data])

    const handleDashboardItemPress = (route, id) => {
        let details
        switch (route) {
            case Routes.GetStartedBefaringsbekreftelse:
                details = data?.send_offer_detail?.office?.befaringsbekreftelse
                break;
            case Routes.GetStartedAboutW:
                details = data?.send_offer_detail?.office?.about_w
                break;
            case Routes.GetStartedPreparation:
                details = data?.send_offer_detail?.office?.preparation
                break;
            case Routes.GetStartedQuestion:
                details = data?.send_offer_detail?.office?.question_to_ask
                break;
            case Routes.GetStartedProductGallery:
                details = data?.send_offer_detail?.office?.product_gallery
                break;
        }
        if (IS_WEB) {
            props.history.push(`${route}/${btoa(JSON.stringify(details))}`)
        } else {
            props.navigation.navigate(route, {
                details: data
            })
        }
    };

    return (
        <View style={styles.container}>
            <Header renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>

            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <Dashboard dashboardData={DASHBOARD}
                           onPressItem={(route, id) => handleDashboardItemPress(route, id)}/>
            )}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(WBefaringDashboard);
