import React, {useEffect, useState} from 'react'
import {ActivityIndicator, ScrollView, View} from 'react-native'
import {Dashboard, Header, Label, LogoForHeader} from "components/src/components";
import {Color, CommonStyle, decryptId, encryptId, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';

import photo from 'components/src/assets/images/Photo.png';
import ds_assignment from 'components/src/assets/images/Digital_marketing.jpg';
import marketing_material from 'components/src/assets/images/Print_Marketing.jpg';
import display from 'components/src/assets/images/Broker.jpg';
import Financing from 'components/src/assets/images/MissionCost.jpg';
import Insurance from 'components/src/assets/images/Insurance.png';
import Calendar from 'components/src/assets/images/Calendar.png';
import {connect} from "react-redux";
import Notes from 'components/src/components/ui/Notes'
import marketingPackage from "components/src/assets/images/MarketingPackage.jpg";
import {useQuery} from "@apollo/react-hooks";
import {AGENT_INSPECTION_PROPERTY} from "components/src/api/query";
import property_image_gallery from "components/src/assets/images/property_image_gallery.png";

const DASHBOARD = [{
    name: Strings.marketingPackages,
    icon: 'marketing-package',
    bg: marketingPackage,
    route: IS_WEB ? Routes.MarketingPackages : Routes.InspectionMarketingPackages
}, {
    name: Strings.propertyImageGallery,
    icon: 'ds-property',
    bg: property_image_gallery,
    route: Routes.InspectionPropertyImageList
}, {
    name: `${Strings.photo}/${Strings.styling}`,
    icon: 'photo',
    bg: photo,
    route: Routes.InspectionPhoto
}, {
    name: Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: ds_assignment,
    route: Routes.InspectionDigitalMarketing

}, {
    name: Strings.printMarketing,
    icon: 'disclaimer',
    bg: marketing_material,
    route: Routes.InspectionPrintingMarketing,
}, {
    name: Strings.broker,
    icon: 'profile',
    bg: display,
    route: Routes.InspectionBroker

},/* {
    name: Strings.propertyValue,
    icon: 'disclaimer',
    bg: see_finn_ad,
    disabled: true
},*/  {
    name: Strings.boligselgerforsikring,
    icon: 'offers-on-insurance',
    bg: Insurance,
    route: Routes.Boligselgerforsikring
}, {
    name: Strings.calendar,
    icon: 'calendar',
    bg: Calendar,
    route: Routes.InspectionCalendar
}, {
    name: Strings.missionCost,
    icon: 'rate',
    bg: Financing,
    route: Routes.MissionCost

}];

function StartInspection(props) {
    const {history, navigation} = props;

    const [inspection, setInspection] = useState(props.inspection)

    const [visible, setVisible] = useState(false)
    const [notes, setNotes] = useState("")

    const {loading, data, error} = useQuery(AGENT_INSPECTION_PROPERTY, {
        skip: inspection,
        variables: {
            id: IS_WEB ? parseInt(decryptId(props.match.params.id)) : inspection?.property_id
        }
    })

    useEffect(() => {
        if (data?.property) {
            setInspection({
                ...inspection,
                property: data?.property
            })
        }
    }, [data])

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   value={notes}
                   id={inspection?.id}
                   onDashboardPress={() => {
                       // IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }

    let primarySeller = inspection?.property?.property_seller.find(seller => seller?.is_primary_seller)
    return (
        <View style={styles.container}>
            <Header renderTitle={() => <LogoForHeader/>}

                    tintColor={Color.SECONDARY}
                    headerRightSecond={'edit'}
                    headerRightFirst={'faq'}
                    onPressRightFirst={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    onPressRightSecond={() => setVisible(true)}
                    navigation={IS_WEB ? history : navigation}
            />
            {renderNoteModal()}

            {loading ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <ScrollView contentContainerStyle={{
                    paddingBottom: 10,
                    paddingHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(2) : 0
                }}>
                    <View>
                        <Label xlarge
                               font_medium
                               mt={20}
                               ml={ThemeUtils.relativeRealWidth(3)}>
                            {`${primarySeller?.firstName ?? ""} ${primarySeller?.lastName ?? ""}`}
                        </Label>
                        <Label small
                               font_medium
                               mt={5}
                               mb={10}
                               ml={ThemeUtils.relativeRealWidth(3)}>
                            {`${inspection?.property?.street_adress},${inspection?.property?.zipcode} ${inspection?.property?.city}`}
                        </Label>


                        {/*<View style={styles.dashBoardCont}>
                        {DASHBOARD.map(renderDashboardItem)}
                    </View>*/}
                        <Dashboard dashboardData={DASHBOARD}
                                   onPressItem={(route) => {
                                       if (route) {
                                           IS_WEB ? history.push(`${encryptId(inspection?.property_id)}/${route}`) : navigation.navigate(route)
                                       }
                                   }}/>
                    </View>
                </ScrollView>
            )}

        </View>

    )
}

const mapStateToProps = (state) => {
    console.log(state.inspection)
    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(StartInspection)
