import React, {useEffect, useState} from 'react'
import {CustomButton, Hr, Label, RadioGroup} from "components/src/components";
import {ActivityIndicator, Image, ScrollView, Text, View} from 'react-native'
import styles from './styles';
import {Color, CommonStyle, Constants, Icon, openInNewTab, Strings, ThemeUtils} from "components/src/utils";
import anticimexLogo from '../../../../../../../assets/images/anticimex.png'

const INSURANCE = [{
    title: 'Alle typer boliger med andelsnr./aksjenr.',
    extra: 'Premien beregnes av salgssummen, og det beregnes ikke premie av fellesgjelden.',
    scheme: [{
        name: 'Premiesats ved bruk av verdi- og lånetakst',
        interest: "3.37 %"
    }, {
        name: 'Premiesats ved bruk av tilstandsrapport/boligsalgsrapport',
        interest: "2.86 %"
    }, {
        name: 'Minimumspremie',
        interest: "3.400"
    }, {
        name: 'Maksimumspremie',
        interest: "25.000"
    }]
}, {
    title: 'Alle typer boliger med seksjonsnummer',
    scheme: [{
        name: 'Premiesats ved bruk av verdi- og lånetakst',
        interest: "4.08 %"
    }, {
        name: 'Premiesats ved bruk av tilstandsrapport/boligsalgsrapport',
        interest: "3.98 %"
    }, {
        name: 'Minimumspremie',
        interest: "5.300"
    }, {
        name: 'Maksimumspremie',
        interest: "30.000"
    }]
}, {
    title: 'Alle typer boliger/tomter med eget gnr. & bnr.',
    extra: 'Tomter : Eneboligtomter følger eneboligpris.',
    scheme: [{
        name: 'Premiesats ved bruk av verdi- og lånetakst',
        interest: "6.12 %"
    }, {
        name: 'Premiesats ved bruk av tilstandsrapport/boligsalgsrapport',
        interest: "5.10 %"
    }, {
        name: 'Minimumspremie',
        interest: "10.000"
    }, {
        name: 'Maksimumspremie',
        interest: "50.000"
    }]
}, {
    title: 'All types of holiday homes / cottages (cottage, apartment etc.)',
    extra: 'Fritidsleilighet med seksjons-/andelsnummer, prises som leiligheter.',
    scheme: [{
        name: 'Premiesats ved bruk av verdi- og lånetakst',
        interest: "6.63 %"
    }, {
        name: 'Premiesats ved bruk av tilstandsrapport/boligsalgsrapport',
        interest: "5.61 %"
    }, {
        name: 'Minimumspremie',
        interest: "7.750"
    }, {
        name: 'Maksimumspremie',
        interest: "50.000"
    }]
}]

const INSURANCE_FOR_ANTICIMEX = [{
    title: 'ALLE TYPER BOLIGER MED ANDELS- / AKSJENUMMER:',
    extra: 'Premien beregnes av salgssummen, og det beregnes ikke premie av fellesgjelden.',
    scheme: [{
        name: 'Premiesats',
        interest: "2,15 %"
    }, {
        name: 'Minimumspremie',
        interest: "4.000"
    }, {
        name: 'Maksimumspremie',
        interest: "25.0000"
    }]
}, {
    title: 'ALLE TYPER BOLIGER MED SEKSJONSNUMMER*:',
    extra: '* Enebolig med seksjonsnummer prises som boligtype med eget GNR & BNR.',
    scheme: [{
        name: 'Premiesats',
        interest: "3,68 %"
    }, {
        name: 'Minimumspremie',
        interest: "6.000"
    }, {
        name: 'Maksimumspremie',
        interest: "30.000"
    }]
}, {
    title: 'ALLE TYPER BOLIGER MED EGET GNR & BNR:',
    extraTitle: "Tomter: ",
    extra: 'Prises som bolig med eget GNR & BNR',
    scheme: [{
        name: 'Premiesats',
        interest: "4,59 %"
    }, {
        name: 'Minimumspremie',
        interest: "10.000"
    }, {
        name: 'Maksimumspremie',
        interest: "50.000"
    }]
}, {
    title: 'FRITIDSBOLIGER:',
    extra: '*Fritidsleilighet med seksjons-/andelsnummer, prises som øvrige boligtyper med denne eierform.',
    scheme: [{
        name: 'Premiesats',
        interest: "5,10 %"
    }, {
        name: 'Minimumspremie',
        interest: "8.000"
    }, {
        name: 'Maksimumspremie',
        interest: "50.000"
    }]
}]

const INSURANCE_TEXT_FOR_ANTICIMEX = [{
    title: "Fritidstomter",
    text: "Følger fritidsboligpris"
}, {
    title: "Tilstandsrapport/Boligsalgsrapport/Eierskifterapport:",
    text: "Det er krav til tilstandsrapport ved tegning av Boligselgerforsikring. Rapporten skal under ingen omstendighet være eldre enn 6 mnd. på̊det tidspunkt eiendommen markedsføres. Det er også̊krav til at tilstandsrapporten skal være utført av takstmann som er medlem av takstforbund som NT/BMTF."

}, {
    title: "Egenerklæringsskjema:",
    text: "Skal fylles ut og være en del av salgsprospektet. Du vil motta skjema fra din eiendomsmegler."
}, {
    title: "Egenandel:",
    text: "Ingen egenandel, men krav/erstatning under kr 5 000,- dekkes ikke av forsikringen."
}, {
    title: "«As is»::",
    text: "Satsene er forbeholdt alle typer eiendommer som selges «as is». For eiendommer som IKKE selges «as is», beregnes det dobbel premie."
}]

const INSURANCE_TEXT_ANICIMEX_V2 = [{
    title: "ANTICIMEX BOLIGSELGERFORSIKRING",
    text: "Beskytter deg mot krav fra kjøper av din bolig. Ifølge avhendingsloven er du ansvarlig for skjulte feil og mangler i 5 år etter at du har overlevert boligen til kjøper. Forsikringen dekker prisavslag, erstatning og eventuelt heving av kjøpet inntil boligens salgssum, begrenset oppad til kr 10.000.000,- Din Boligselgerforsikring leveres av forsikringsselskapet Anticimex Forsikring AB, norsk avdeling av utenlandsk foretak, gjennom forsikringsagenten Buysure AS."
}, {
    title: "ANTICIMEX BOLIGSALGSRAPPORT",
    text: "Boligsalgsrapporten utføres av en bygningsinspektør fra Anticimex og utgjør en integrert del av Boligselgerforsikringen. Rapporten vil også kunne danne grunnlag for utstedelse av ett eller flere våtromsbevis. Den bygningssakkyndige gjennomfører en grundig sjekk av boligen og utarbeider en detaljert rapport med tilstandsgrader på ulike bygningsdeler og plantegninger. Rapporten baserer seg på Norsk Standard 3600 som sikrer en god oversikt over boligens tekniske tilstand, og således bidrar til å trygge bolighandelen ytterligere. Anticimex har lang erfaring med bygningsinspeksjoner og gjennomfører årlig kontroll av over 14 000 boliger.",
}, {
    title: "VÅTROMSBEVIS",
    text: "Inspektøren fra Anticimex kan utstede ett eller flere våtromsbevis på bad/våtrom i boligen som skal selges, dersom våtrommene tilfredsstiller visse tekniske krav. Våtromsbeviset har en gyldighet på 4 år og vil ligge sammen med Boligsalgsrapporten i salgsoppgaven. Dokumentert tilstand på våtrom har en stor verdi både for boligselger og boligkjøper. Anticimex vil informere deg om hvilke krav som legges til grunn for å kunne få våtromsbevis.",
}, {
    title: "RABATTKUPONG PÅ KR 2 000,- HOS IF SKADEFORSIKRING",
    text: "Du mottar en rabattkupong på kr 2 000,- som du kan bruke hos If når du skal forsikre din nye bolig. If er Nordens ledende forsikringsselskap og vil kontakte deg for å følge opp ditt forsikringsbehov.",
}]

const INSURANCE_FOR_ANTICIMEX_V2 = [{
    title: 'FOR ALLE BOLIGER MED ANDELS- / AKSJENUMMER:',
    extra: 'Totalkostnad Boligselgerpakken = Grunnpremie + promillesats av salgssum',
    scheme: [{
        name: 'Grunnpremie (Boligsalgsrapport, rabattkupong evt. våtromsbevis)',
        interest: "6.600"
    }, {
        name: 'Premiesats (med tillegg av grunnpremie)',
        interest: "1.94%"
    }, {
        name: 'Minimumspremie',
        interest: "10.600"
    }, {
        name: 'Maksimumspremie',
        interest: "31.600"
    }]
}, {
    title: 'FOR ALLE BOLIGER MED SEKSJONSNUMMER*::',
    extra: 'Totalkostnad Boligselgerpakken = Grunnpremie + promillesats av salgssum \n*Enebolig med seksjonsnummer prises som boliger med eget Gnr & BNR.',
    scheme: [{
        name: 'Grunnpremie (Boligsalgsrapport, rabattkupong evt. våtromsbevis)',
        interest: "6.600"
    }, {
        name: 'Premiesats (med tillegg av grunnpremie)',
        interest: "3.31%"
    }, {
        name: 'Minimumspremie',
        interest: "12.600"
    }, {
        name: 'Maksimumspremie',
        interest: "36.600"
    }]
}, {
    title: 'FOR ALLE BOLIGER MED EGET GNR & BNR:',
    extra: 'Totalkostnad Boligselgerpakken = Grunnpremie + promillesats av salgssum \n*For rekkehus med eget GNR & BNR prises grunnpremie til kr 6 600,-',
    scheme: [{
        name: 'Grunnpremie (Boligsalgsrapport, rabattkupong evt. våtromsbevis)',
        interest: "12.100*"
    }, {
        name: 'Premiesats (med tillegg av grunnpremie)',
        interest: "4.13%"
    }, {
        name: 'Minimumspremie',
        interest: "22.100"
    }, {
        name: 'Maksimumspremie',
        interest: "62.100"
    }]
}, {
    title: 'FRITIDSBOLIGER:',
    extra: '*Fritidsleilighet med seksjons-/andelsnummer, prises som øvrige boligtyper med slik eierform.',
    scheme: [{
        name: 'Grunnpremie (Boligsalgsrapport, rabattkupong evt. våtromsbevis)',
        interest: "12.100"
    }, {
        name: 'Premiesats (med tillegg av grunnpremie)',
        interest: "4.59%"
    }, {
        name: 'Minimumspremie',
        interest: "20.100"
    }, {
        name: 'Maksimumspremie',
        interest: "62.100"
    }]
}]

function Boliselgerforsikring(props) {

    const {loading, onChangeOptions, data} = props
    const [insuranceType, setInsuranceType] = useState();
    const [defaultVersion, setDefaultVersion] = useState(1);

    useEffect(() => {
        if (data?.length) {
            let selectedInsuranceType = data?.find(item => item.selected_in_property)?.id;
            if (selectedInsuranceType) {
                setInsuranceType(selectedInsuranceType)
            }
            setDefaultVersion(data[0]?.insurance_type === Constants.InsuranceType.UTEN ? 1 : 2)
        }
    }, [data])

    useEffect(() => {
        if (insuranceType) {
            onChangeOptions && onChangeOptions({
                type: defaultVersion === 1 ? Constants.InsuranceType.UTEN : Constants.InsuranceType.MED,
                id: insuranceType
            })
        }
    }, [insuranceType, defaultVersion])


    const renderScheme = (item, index) => {
        return (
            <View style={styles.schemeCont}
                  key={index.toString()}>
                <Label xsmall
                       mb={ThemeUtils.relativeRealHeight(1)}
                       style={CommonStyle.flex}>
                    {item.name}
                </Label>
                <Label xsmall>
                    {item.interest}
                </Label>
            </View>
        )
    }

    const renderLabel = (item, index) => {
        return (
            <View>
                <View key={index.toString()}
                      style={styles.lblCont}>
                    <Label small
                           mb={ThemeUtils.relativeRealHeight(1)}
                           font_medium>
                        {item.title}
                    </Label>
                    {item.scheme.map(renderScheme)}
                    {item.extra ? (
                        <Label xsmall
                               font_medium
                               mt={ThemeUtils.relativeRealHeight(1)}
                               mb={ThemeUtils.relativeRealHeight(1)}
                               color={Color.SECONDARY_TEXT_COLOR}>
                            <Text style={styles.title}>
                                {item.extraTitle ? `${item.extraTitle}` : ""}
                            </Text>
                            {item.extra}
                        </Label>
                    ) : null}
                </View>
                <Hr/>
            </View>
        )
    };

    const renderSingleLineLabel = (item, index) => {
        return (
            <View style={styles.singleLineLabel}
                  key={index.toString()}>
                <Label xsmall
                       font_medium
                       color={Color.SECONDARY_TEXT_COLOR}>
                    <Text style={styles.title}>
                        {`${item.title}: `}
                    </Text>
                    {item.text}
                </Label>
            </View>
        )
    }

    const renderDoubleLineLabel = (item, index) => {
        return (
            <View style={styles.singleLineLabel}
                  key={index.toString()}>
                <Label small
                       font_medium
                       mb={ThemeUtils.relativeRealHeight(0.5)}>
                    {`${item.title}: `}
                </Label>
                <Label xsmall
                       font_medium
                       color={Color.SECONDARY_TEXT_COLOR}>
                    {item.text}
                </Label>
            </View>
        )
    }
    return (
        <View style={[styles.container]}>
            <ScrollView>
                {/*<View>
                    <Image style={styles.img}
                           source={ins}/>
                    {INSURANCE.map(renderLabel)}
                </View>*/}

                {/*SERCOND COMPANY*/}
                <View>
                    <Image style={styles.img}
                           source={anticimexLogo}/>
                    {defaultVersion === 1? (
                        <>
                            {INSURANCE_FOR_ANTICIMEX.map(renderLabel)}
                            <View style={styles.lblCont}>
                                {INSURANCE_TEXT_FOR_ANTICIMEX.map(renderSingleLineLabel)}
                            </View>
                        </>
                    ) : (
                        <>
                            <View style={styles.v2Cont}>
                                <Label font_medium>
                                    {"Trygt boligsalg med Boligselgerpakken fra Anticimex"}
                                </Label>
                                <Label font_medium
                                       small
                                       mt={ThemeUtils.relativeRealHeight(2)}
                                       mb={ThemeUtils.relativeRealHeight(1)}>
                                    {"BOLIGSELGERPAKKEN INKLUDERER FØLGENDE:"}
                                </Label>
                                {INSURANCE_TEXT_ANICIMEX_V2.map(renderDoubleLineLabel)}

                            </View>
                            {INSURANCE_FOR_ANTICIMEX_V2.map(renderLabel)}
                            <Label xsmall
                                   font_medium
                                   color={Color.SECONDARY_TEXT_COLOR}
                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                   mt={ThemeUtils.relativeRealHeight(1)}>
                                {"Totalkostnad Boligselgerpakken = Grunnpremie + promillesats av salgssum. Som selger mottar du ingen faktura. Forsikringspremien for Boligselgerpakken trekkes i oppgjøret når boligen er solgt. Skulle du ikke lykkes med å selge din bolig, bortfaller kostnaden for Boligselgerpakken. Trekkes oppdraget i løpet av din avtaletid med eiendomsmegler skal Grunnpremien betales."}
                            </Label>
                            <View style={styles.lblCont}>
                                {INSURANCE_TEXT_FOR_ANTICIMEX.slice(2).map(renderSingleLineLabel)}
                            </View>

                        </>
                    )}

                </View>
            </ScrollView>
            <View style={styles.divider}/>
            <View>
                <View style={styles.contentCont}>
                    <Label xsmall
                           mb={ThemeUtils.relativeRealHeight(2)}>
                        {Strings.boligselgerforsikringInst}
                    </Label>


                    {loading ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (
                        <>

                            <RadioGroup onSelectOption={(val) => setDefaultVersion(val)}
                                        xsmall

                                        selectedOptions={data[0]?.insurance_type === Constants.InsuranceType.UTEN ? 1 : 2}
                                        small={false}
                                        textStyle={{flex: 1}}
                                        style={{marginBottom: ThemeUtils.relativeRealHeight(1)}}
                                        contentStyle={{flexDirection: 'row'}}
                                        options={[{
                                            name: Strings.utenAnticimex,
                                            value: 1
                                        }, {
                                            name: Strings.medAnticimex,
                                            value: 2
                                        }]}/>
                        <RadioGroup onSelectOption={setInsuranceType}
                                    xsmall
                                    small={false}
                                    selectedOptions={insuranceType}
                                    changeOnProp
                                    textStyle={{flex: 1}}
                                    style={{marginTop: ThemeUtils.relativeRealHeight(2)}}
                                    options={data}/>
                        </>
                    )}

                    <CustomButton mt={ThemeUtils.relativeRealHeight(2)}
                                  maxWidth={ThemeUtils.relativeRealWidth(40)}
                                  onPress={() => {
                                      openInNewTab(defaultVersion === 1? `https://cdn.weiendomsmegling.no/Insurance/brosjyre-boligselgerforsikring.pdf` : `https://cdn.weiendomsmegling.no/Insurance/brosjyre-boligselgerforsikring-og-boligsalgsrapport-fra-anticimex.pdf`)
                                  }}
                                  title={
                                      <>
                                          <Icon name={'open-externally'}
                                                size={ThemeUtils.fontLarge}/>
                                          {`  ${Strings.openPdf}`}
                                      </>}/>

                </View>
            </View>


        </View>
    )
}

export default Boliselgerforsikring
