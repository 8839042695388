import React, {useEffect, useState} from "react";
import {Animated, View} from "react-native";
import {Label, Ripple, VideoPlayer} from "components/src/components";
import {Color, CommonStyle, Constants, formatPrice, Icon, ThemeUtils,} from "components/src/utils";
import styles from "./styles";
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../../api/mutation";


function Wads(props) {

    const {data, isFocused, inspection, isCompanyTemplate, packages} = props;
    const [selectedService, setSelectedService] = useState(data?.purchased_office_services?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id));

    const [serviceInPackage, setServiceInPackage] = useState([]);

    useEffect(() => {
        let service = []
        packages.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [packages])

    const addOrRemoveItemFromArray = (item) => {
        let updatedService = selectedService.slice();
        if (updatedService.includes(item.id)) {
            updatedService.splice(updatedService.indexOf(item.id), 1)
            saveServices({
                variables: {
                    input: {
                        property_id: inspection?.property_id,
                        unselect_pro_service_ids: item.service_selected?.id,
                        broker_on_view: false
                    }
                }
            }).catch(() => {
            })
        } else {
            updatedService.push(item.id)
        }
        saveServices({
            variables: {
                input: {
                    property_id: inspection?.property_id,
                    service_ids: updatedService,
                    broker_on_view: false
                }
            }
        }).catch(() => {
        })
        setSelectedService(updatedService)
    }
    const [isPlaying, setPlaying] = useState(false);
    const IMAGES = isCompanyTemplate ? data?.office_service_media ?? [] : data?.agent_service_media ?? []

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {
            props.refetch()
        }
    });

    useEffect(() => {
        setPlaying(isFocused)
    }, [isFocused])

    const renderService = (item, index, self) => {
        let isSelected = selectedService.includes(item.id) || serviceInPackage.includes(item.id)
        return (
            <Ripple
                style={[styles.serviceCont, isSelected ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => serviceInPackage.includes(item.id) ? null : addOrRemoveItemFromArray(item)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };

    return (
        <View style={styles.container}>
            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        {IMAGES[0]?.media_url && !isPlaying ? (
                            <Ripple style={styles.icCont}
                                    onPress={() => setPlaying(true)}>
                                <Icon name={'video'}
                                      size={ThemeUtils.fontXXLarge}
                                      color={Color.WHITE}/>
                            </Ripple>) : null}
                        <VideoPlayer width={ThemeUtils.relativeWidth(100)}

                                     playing={isPlaying}
                                     controls={isPlaying}
                                     height={ThemeUtils.relativeRealHeight(30)}
                                     url={`${Constants.APIConfig.STORAGE_URL}${IMAGES[0]?.media_url}`}/>
                    </View>

                    <View style={styles.lblCont}>
                        <Label large font_medium>
                            {data?.headline}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR} mt={10} mb={10}>
                            {data?.description}
                        </Label>
                        {data?.purchased_office_services?.map(renderService)}
                    </View>
                </View>
            </Animated.ScrollView>
        </View>
    );
}

export default Wads;
