import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    chatCont: {
        flex: 1,
        paddingStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN/2,
        paddingEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: ThemeUtils.relativeRealHeight(2)
    },
    chatImage: {
        height: ThemeUtils.responsiveScale(60),
        width: ThemeUtils.responsiveScale(60),
        borderRadius: ThemeUtils.responsiveScale(30),
        backgroundColor: Color.GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        justifyContent: 'space-between'
    },
    dot: {
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN/2,
        borderRadius: 4,
        height: 8,
        width: 8
    }
})
