export default {
  'component.form.email': 'Epost',
  'component.form.sendemail': 'Send epost',
  'component.form.email.placeholder': 'Skriv inn epost',
  'component.form.password': 'Passord',
  'component.form.username': 'Navn',
  'component.form.telephone': 'Telefon',
  'component.form.name': 'Navn',
  'component.form.comment': 'Kommentar',
  'component.form.reservationsandcomment': 'Reservasjoner / Kommentar',
  'component.form.submit': 'Lagre',
  'component.form.send': 'Send',
  'component.form.gotstarted': 'Kom i gang',
  'component.form.ourproperties': 'Våre eiendommer',
  'component.form.phone': 'Telefon',
  'component.form.phone.placeholder': 'Skriv inn telefon',
  'component.form.date': 'Dato',
  'component.form.earlieststarttime': 'Start tidspunkt',
  'component.form.lateststarttime': 'Slutt tidspunkt',
  'component.form.commentbox.placeholder': 'Skriv dine kommentarer her ...',
  'component.form.cancel': 'Avbryt',
  'component.form.order': 'Bestill',
  'component.form.save': 'Lagre',
  'component.form.upload': 'Last opp',
  'component.form.additionalservices': 'Tilleggstjenester',
  'component.form.approve': 'Godkjenn',
  'component.form.comments': 'Kommentarer',
  'component.form.givefeedback': 'Gi tilbakemelding',
  'component.form.givefeedback.placeholder': 'Skriv din tilbakemelding her ...',
  'component.form.back': 'Tilbake',
  'component.form.add': 'Lagre',
  'component.form.changepassword': 'Bytt passord',
  'component.form.editprofile': 'Rediger profil',
  'component.form.currentpassword': 'Nåværende passord',
  'component.form.currentpassword.placeholder': 'Skriv inn nåværende passord',
  'component.form.newpassword': 'Nytt Passord',
  'component.form.newpassword.placeholder': 'Skriv inn nytt passord',
  'component.form.confirmpassword': 'Bekreft passord',
  'component.form.confirmpassword.placeholder': 'Skriv inn bekreft passord',
  'component.form.update': 'Lagre',
  'component.form.profilepicture': 'Profilbilde',
  'component.form.firstname': 'Fornavn',
  'component.form.firstname.placeholder': 'Skriv inn fornavn',
  'component.form.lastname': 'Etternavn',
  'component.form.lastname.placeholder': 'Skriv inn etternavn',
  'component.form.address': 'Adresse',
  'component.form.address.placeholder': 'Skriv inn adresse',
  'component.form.city': 'Poststed',
  'component.form.city.placeholder': 'Skriv inn poststed',
  'component.form.zipcode': 'Postnummer',
  'component.form.zipcode.placeholder': 'Skriv inn postnummer',
  'component.form.assignedzipcode': 'Tildelt postnummer',
  'component.form.assignedzipcode.placeholder': 'Skriv inn tildelt postnummer',
  'component.form.mobileno': 'Mobilnr',
  'component.form.propertyaddress': 'Eiendommens adresse',
  'component.form.postcode': 'Postnummer',
  'component.form.attach': 'Lagre',
  'component.form.time': 'Tid',
  'component.form.okay': 'Greit',
  'component.form.education': 'Utdanning',
  'component.form.summary': 'Oppsummering om megler',
  'component.form.uploadcv': 'Last opp CV',
  'component.form.uploadotherdoc': 'Last opp andre dokumenter',
  'component.form.title': 'Tittel',
  'component.form.delete': 'Slett',
  'component.form.yes': 'Ja',
  'component.form.no': 'Nei',
  'component.form.location': 'Plassering',
  'component.form.location.placeholder': 'Angi plassering',
  'component.form.next': 'Neste',
  'component.form.replace': 'Erstatte',
  'component.form.confirm': 'Bekrefte',
  'component.form.phototitle': 'Bildetittel',
  'component.form.phototitle.placeholder': 'Skriv inn bildetittel',
  'component.form.attachlink': 'Legg ved lenke',
  'component.form.saveandlogin': 'Lagre og logg inn',
  'component.form.commentsfromseller': 'Kommentarer fra selger',
  'component.form.commentplaceholder': 'Skriv kommentarer her ...',
  'component.form.reschedule': 'Planlegge på nytt',
  'component.form.select': 'Velg',
  'component.form.headline': 'Overskrift',
  'component.form.image': 'Bilder',
  'component.form.description': 'Beskrivelse',
  'component.form.contractor': 'Oppdragstaker',
  'component.form.settlementcompany': 'Oppgjørsforetak',
  'component.form.financialsettlement.madeby': 'Det økonomiske oppgjøret foretas av',
  'component.form.settlementemploy': 'Oppgi timesats',
  'component.form.hourlyrate': 'Timesatsen',
  'component.form.totaltimespent': 'Totalt antall timer',
  'component.form.hourly.remuneration': 'Timebasert vederlag',
  'component.form.other.remuneration': 'Øvrige vederlag',
  // 'component.form.sales.warranty': 'Salgsgaranti',
  'component.form.sales.warranty_yes': 'Salgsgaranti (Ja)',
  'component.form.sales.warranty_no': 'Salgsgaranti (Nei)',
  'component.form.intro.text': 'Intro tekst',
  'component.form.standard.photo.package': 'Standard fotopakke',
  'component.form.evening.photo': 'Kveldsfoto',
  'component.form.upload.photos': 'Last opp bildene dine',
  'component.form.finn': 'Finn',
  'component.form.flim': 'Flim',
  'component.form.see.pictures': 'Se bilder',
  'component.form.housing.video': 'Boligvideo',
  'component.form.somefilm': 'Noe film',
  'component.form.upload.video': 'Last opp film',
  'component.form.finnno': 'Finn.No',
  'component.form.insert.iframe.link': 'Sett inn iFrame link',
  'component.form.insert.iframe.link.placeholder': 'Sett inn kobling her ...',
  'component.form.insert.finnno.link': 'Sett inn finn.no link',
  'component.form.insert.finnno.link.placeholder': 'Sett inn kobling her ...',
  'component.form.lookbook': 'Prospekt',
  'component.form.brokerwords': 'Noen ord fra megler før befaring',
  'component.form.redirectionalurl': 'Link til artikkel',
  'component.form.officelogo': 'Kontorlogo',
  'component.form.officeimage': 'Kontorbilde',
  'component.form.bannerimage1': 'Bannerbilde1',
  'component.form.bannerimage2': 'Bannerbilde2',
  'component.form.smallheadline': 'Liten overskrift',
  'component.form.bigheadline': 'Stor overskrift',
  'component.form.headlinetextsection': 'Seksjon for overskriftstekst',
  'component.form.textsection': 'Tekst seksjon',
  'component.form.ingress': 'Ingress',
  'component.form.vitecusername': 'Vitec brukernavn',
  'component.form.installationid': 'Installasjons-id',
  'component.form.writehere': 'Skriv her...',
  'component.form.agents': 'Megler',
  'component.form.office': 'Kontor',
  'component.form.officeceo': 'Kontorsjef',
  'component.form.officecontroller': 'Kontorkontroller',
  'component.form.callus': 'Ring oss',
  'component.form.register': 'Registrer',
  'component.form.download': 'Nedlasting',
  'component.form.viewdetails': 'Vis detaljer',
  'component.form.uploadcovervideo': 'Last opp film',
  'component.form.pagename': 'Side navn',
  'component.form.pagetype': 'Side type',
  'component.form.pagetitle': 'Side tittel',
  'component.form.isadmin': 'Admin kontor',
  'component.form.officeurl': 'Kontor url',
};
