import React, {useEffect, useState} from 'react'
import {ActivityIndicator, Image, ScrollView, View} from 'react-native'
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    encryptId,
    formatPrice,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {CheckBox, CustomButton, Header, Hr, HTMLView, Label, LogoForHeader} from "components/src/components";
import styles from './styles'
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    AGREEMENT_DETAILS,
    BOLIG_SELGER_INSURANCE,
    PROPERTY_DETAILS,
    SELECTED_PROPERTY_SERVICE
} from "../../../../../../api/query";
import {OWNER_SIGN_AGREEMENT} from "../../../../../../api/mutation";
import moment from "moment";
import Logo from "../../../../../../assets/images/W_Logo_Only.png";
import {getDecryptedId} from "web/src/utils/utils";

// const getDecryptedId=()=>{
//
// }
const OPTIONS = [
    {name: 'None', value: 'None'},
    {name: 'Other Brokerage Firm', value: 'other'},
]

const QUESTION = [{
    question: "Taushetsplikt",
    answer: "Oppdragstaker har taushetsplikt jf. eiendomsmeglingsloven § 3-6."
}, {
    question: "Energimerking",
    answer: "Oppdragsgiver er ansvarlig for å energimerke boligen i forbindelse med salg og oversende energiattest " +
        "til megler. Hvis ikke energiattest er oversendt megler når boligen legges ut for salg vil boligen " +
        "automatisk bli tildelt dårligste energimerking. Selger er klar over at kjøper har, etter forskrift om " +
        "energimerking, anledning til å bestille energiattest for selgers regning, hvis dette ikke foreligger på " +
        "aksepttidspunktet."
}, {
    question: "Generell orientering om skatt",
    answer: "Gevinst ved salg av eiendom er normalt skattefri når oppdragsgiver har eid eiendommen i minst 1 år og " +
        "selv bebodd eiendommen i minst 1 av de 2 siste årene. Ved salg av fritidsbolig er gevinsten skattefri når " +
        "oppdragsgiver har eid eiendommen i minst 5 år og brukt eiendommen i minst fem av de siste 8 årene. " +
        "Gevinst ved salg av tomt er skattepliktig. Der tomten til en bebygget eiendom anses å være større enn " +
        "bebyggelsen krever, vil deler av gevinsten kunne bli ansett som skattepliktig. Oppdragsgiver er selv " +
        "ansvarlig for å undersøke skattemessige konsekvenser av salget."
}, {
    question: "Mottagelse av bud utenom megler",
    answer: "Bestemmelser om budgivning gitt i medhold av eiendomsmeglingsloven § 6-10 kommer til anvendelse " +
        "på utførelsen av oppdraget. Oppdragstaker er i henhold til lovverket avskåret fra å formidle bud til " +
        "oppdragsgiver dersom budet har kortere akseptfrist enn kl. 12 første virkedag etter siste annonserte " +
        "visning. Oppdragsgiver kan derfor oppleve at interessenter ønsker å gi bud direkte til oppdragsgiver og " +
        "med krav om raskt svar. Oppdragsgiver frarådes til å ta i mot slike bud fra budgiver, men heller henvise " +
        "budgiveren til oppdragstaker. årsaken til dette er at oppdragstaker i slike tilfeller vil være avskåret fra å " +
        "informere andre interessenter om budet, samt at det kan oppstå uklarheter rundt hvilke vilkår partene er " +
        "blitt enige om. Oppdragsgiver gjøres for øvrig oppmerksom på at oppdragstaker også i disse tilfellene vil " +
        "ha krav på fullt vederlag."
}, {
    question: "Off-market",
    answer: "Eiendommer som omsettes utenom markedet har større risiko for ikke å oppnå markedspris. På " +
        "generelt grunnlag fraråder vi salg av eiendom uten at den er markedsført i det åpne markedet. Med flere " +
        "interessenter på visning vil man kunne få en budrunde som kan medføre at prisen på boligen kan bli " +
        "høyere enn meglers prisvurdering."
}, {
    question: "Prisantydning",
    answer: "Oppdragsgiver bekrefter å ha blitt informert om at prisantydning på eiendommen ikke bevisst må settes " +
        "lavere enn det oppdragsgiver kan være villig til å akseptere."
}, {
    question: "Samtykke til bruk av elektronisk kommunikasjon",
    answer: "Oppdragsgiver samtykker ved underskrift på denne avtalen til at oppdragstaker kan benytte elektronisk " +
        "kommunikasjon når oppdragstaker skal gi meldinger, varsler informasjon, formidle dokumenter etc. til " +
        "oppdragsgiver. Oppdragsgiver tillater også at oppdragstaker kan benytte bilder og informasjon om " +
        "salget i sin markedsføring. Partene vil selvfølgelig bli anonymisert."
}, {
    question: "Pantedokument med urådighet",
    answer: "Oppdragsgiver aksepterer at pantedokumentet med urådighet som tinglyses i forbindelse med " +
        "salgsoppdraget også sikrer oppdragstakers krav på vederlag og utlegg." +
        "Oppdragsgiver skal sammen med denne kontrakt signere et pantedokument med urådighet til Megler. Pantedokumentet skal sikre rettmessig oppfyllelse ovenfor kjøper samt Meglers tilgodehavende. Megler foretar."
}, {
    question: "Ved flere enn én oppdragsgiver",
    answer: "Ved flere enn én oppdragsgiver gir disse hverandre gjensidig fullmakt til å godkjenne alt salgsmateriell " +
        "samt avgi budaksept og avtale tidspunkt for overtagelse. Dette slik at meddelelser til og fra én av " +
        "oppdragsgiverne også er bindende for samtlige oppdragsgivere."

}, {
    question: "Rapporteringsplikt i henhold til hvitvaskingsregelverket",
    answer: "Eiendomsmeglerforetak er underlagt hvitvaskingslovgivningen. Kjøper og selger er forpliktet til å bidra til at oppdragstaker kan gjennomføre tilfredsstillende " +
        "kundekontroll og må derfor bl.a. fremlegge gyldig legitimasjon. Dersom oppdragstaker ikke får utført slik " +
        "kontroll har oppdragstaker rett til å avstå fra å gjennomføre oppdraget og kreve et rimelig vederlag for " +
        "utført arbeid. Oppdragstaker fraskriver seg ansvar for eventuelle økonomiske krav som følge av " +
        "ovennevnte forhold."
}, {
    question: "Behandling av personvernopplysninger / GDPR",
    answer: "I forbindelse med gjennomføringen av oppdraget, vil Megler behandle en rekke personopplysninger om " +
        "oppdragsgiver, herunder blant annet: navn, e-postadresse, telefonnummer, personnummer, " +
        "bankkontonummer, boligopplysninger, kjøpshistorikk, overtagelsesprotokoll, og kontrakt. Behandling av " +
        "personopplysninger er underlagt personopplysningsloven og personvernforordningen (GDPR). " +
        "Formålene for behandlingen av personopplysninger er blant annet å oppfylle oppdragsavtalen, " +
        "etterlevelse av lovpålagt krav, herunder krav i eiendomsmeglingsloven og hvitvaskingsloven, " +
        " " +
        "markedsføringsformål, analyse og tjenesteutvikling, mv. Megler er i henhold til " +
        "eiendomsmeglingsforskriften § 3-7 pålagt å oppbevare kontrakter og dokumenter tilknyttet oppdraget i " +
        "minst 10 år. Etter dette vil opplysningene bli slettet, alternativt anonymisert. Megler vil dele opplysninger " +
        "med blant annet kommunen, forretningsfører, Oppdragsgivers bank/kreditor, Kartverket, Skatteetaten, " +
        "finn.no, samt leverandører av blant annet meglersystemet og annet elektroniske system som er " +
        "nødvendig for gjennomføringen av meglers arbeid. Megler er ikke behandlingsansvarlig for behandling " +
        "av personopplysninger som foretas av takstmann, boligstylist, fotograf mv. For mer informasjon om vår behandling av personopplysninger og informasjon om dine rettigheter, se vår personvernerklæring; weiendomsmegling.no/personvern."
}, {
    question: "Bakgrunnsrett, lovvalg og tvisteløsning",
    answer: "Denne avtalen er underlagt norsk rett, og eiendomsmeglingsloven kommer utfyllende til anvendelse for forhold som ikke er regulert i denne oppdragsavtalen. Tvister som måtte oppstå med tilknytning til denne avtale, avgjøres ved ordinær rettergang ved Eiendommens \n\n" + "Oppdragsgiver har rett til å forelegge eventuelle tvistespørsmål som måtte oppstå mellom partene for " +
        "Reklamasjonsnemda for Eiendomsmeglingstjenester, jf. eiendomsmeglingsloven § 8-8." +
        "Denne avtalen er underlagt norsk rett, og eiendomsmeglingsloven kommer utfyllende til anvendelse for forhold som ikke er regulert i denne oppdragsavtalen. Tvister som måtte oppstå med tilknytning til denne avtale, avgjøres ved ordinær rettergang ved Eiendommens."
}, {
    question: "Løsøre og tilbehør",
    answer: "Oppdragsgiver bekrefter å ha blitt informert om at løsøre og tilbehør som fremkommer i liste utarbeidet " +
        "av bransjeorganisasjonene, legges til grunn for salget. Dersom oppdragsgiver ønsker å gjøre unntak fra " +
        "denne listen eller det som følger av avhendingslovens bestemmelser om løsøre og tilbehør må dette " +
        "opplyses om i salgsoppgaven."
}, {
    question: "Fullmakt",
    answer: "Megler skal innhente og fremlegge informasjon om Eiendommen som en kjøper kan regne med å få, og som kan ha betydning for handelen, jf. eiendomsmeglingsloven § 6-7 første ledd. Megler skal alltid innhente slike opplysninger som fremgår av eiendomsmeglingsloven § 6-7 annet til sjette ledd, og ved signatur på denne kontrakt gis samtykke til innhenting av informasjonen på vegne av Oppdragsgiver som eier av Eiendommen. Ved underskrift av denne avtalen gis Megler fullmakt til å innhente formuesverdi fra offentlig etat, alternativt fra forretningsfører for eiendommen.\n\n" +
        "Megler er ikke gitt fullmakt til å gjøre disposisjoner eller ta beslutninger på vegne av Oppdragsgiver, utover fullmakten til å innhente nødvendige opplysninger etter eiendomsmeglingslovens regler."
}, {
    question: "Oppgjør",
    answer: "Oppdraget omfatter gjennomføring av de oppgaver som er beskrevet i eiendomsmeglingsloven § 6-9 første ledd nr. 1 til 4. Det tar normalt 2-3 uker fra overtakelse til utbetaling av oppgjør. Dette er grunnet behandlingstid hos Statens Kartverk, postgang og transaksjonstid banker imellom."
}]

function SignAgreement(props) {
    const {history, inspection, navigation} = props
    const [firm, setFirm] = useState('')
    const [firmName, setFirmName] = useState([])
    const [isMarried, setMarried] = useState('No')
    const [isBothSign, setBothSign] = useState('Yes')
    const [serviceInPackage, setServiceInPackage] = useState([])

    const [sign, signMutattion] = useMutation(OWNER_SIGN_AGREEMENT)


    let propertyId = IS_WEB ? parseInt(decryptId(props.match.params?.id)) : inspection?.property_id

    const insuranceQuery = useQuery(BOLIG_SELGER_INSURANCE, {
        variables: {
            propertyId: parseInt(propertyId)
        }
    });

    const psQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.PhotoStyling,
            propertyId: inspection?.property_id ?? propertyId
        }
    });


    const dmQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.DigitalMarketing,
            propertyId: inspection?.property_id ?? propertyId
        }
    });

    const pmQuery = useQuery(SELECTED_PROPERTY_SERVICE, {
        fetchPolicy: 'network-only',
        variables: {
            status: true,
            category: Constants.ServiceCategory.PrintMarketing,
            propertyId: inspection?.property_id ?? propertyId
        }
    });

    const propertyDetails = useQuery(PROPERTY_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            id: inspection?.property_id ?? propertyId
        }
    })

    const {loading, data, error} = useQuery(AGREEMENT_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            propertyId: inspection?.property_id ?? propertyId,
            ownerNo: 1
        }
    })

    useEffect(() => {
        let selectedPackage = psQuery?.data?.selected_property_services?.marketing_package ? psQuery?.data?.selected_property_services?.marketing_package.filter(item => item.property_package) : []
        let service = []
        selectedPackage.map(packageItem => {
            service = [...service, ...packageItem?.marketing_package_service?.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [psQuery.data]);


    const getPropertyType = (type) => {
        switch (type) {
            case 0:
                return "Selveier"
            case 1:
                return "Andelsleilighet"
            case 2:
                return "Aksjeleilighet"
            case 3:
                return "Obligasjonsleilighet"
            case 4:
                return "Eierseksjon"
        }
    }

    const getPropertyTypePrefix = (type) => {
        switch (type) {
            case 0:
            case 3:
            case 4:
                return `Matrikkel: Gnr. ${propertyDetails?.data?.property?.gnr ?? ""}, Bnr. ${propertyDetails?.data?.property?.bnr ?? ""}${propertyDetails?.data?.property?.fnr ? ", Fnr. " + propertyDetails?.data?.property?.fnr ?? "" : ""}${propertyDetails?.data?.property?.fnr ? ", Snr. " + propertyDetails?.data?.property?.snr ?? "" : ""} i ${propertyDetails?.data?.property?.municipality} kommune`
            case 1:
                return `Andelsnr. ${propertyDetails?.data?.property?.partNumber ?? ""} i ${propertyDetails?.data?.property?.partName ?? ""}, org.nr ${propertyDetails?.data?.property?.partOrgNumber ?? ""} i ${propertyDetails?.data?.property?.municipality} kommune`
            case 2:
                return `Aksjenr. ${propertyDetails?.data?.property?.estateHousingCooperativeStockNumber ?? ""} i ${propertyDetails?.data?.property?.partNumber ?? ""}  i ${propertyDetails?.data?.property?.municipality} kommune`
        }
    }

    const renderService = (item, index) => (
        <View style={{flexDirection: 'row', maxWidth: 520}}
              key={index.toString()}>
            <Label font_medium
                   small
                   style={CommonStyle.flex}>
                {item.name}
            </Label>
            <Label font_medium
                   small
                   align={'right'}>
                {item?.is_free ? item?.free_text : `Kr ${formatPrice(item?.service_selected?.price ?? item?.property_package?.price ?? item?.usableServiceClass?.price)},-`}
            </Label>
        </View>
    )

    const renderPackageService = (item, index) => (
        <View>
            <View style={{flexDirection: 'row', maxWidth: 520}}
                  key={index.toString()}>
                <View style={CommonStyle.flex}>
                    <Label font_medium
                           small>
                        {item.name}
                    </Label>
                    <Label small
                           mt={5}
                           mb={5}
                           mr={15}
                           style={CommonStyle.flex}>
                        {item.marketing_package_service.map(service => service?.office_service?.name).join(", ")}
                    </Label>
                </View>
                <Label font_medium
                       small
                       align={'right'}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item?.service_selected?.price ?? item?.property_package?.price ?? item?.usableServiceClass?.price)},-`}
                </Label>
            </View>
        </View>
    )


    const renderBillSection = (name, data) => {
        let isRenderTitle = false
        let services = []
        data?.map(service => {
            let subService = service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id))
            if (subService?.length) {
                isRenderTitle = true
                services = [...services, ...subService]
            }
        })
        console.log(isRenderTitle)
        if (!isRenderTitle) {
            return;
        }
        return (
            <>
                <Label font_medium
                       small
                       mt={15}
                       mb={5}
                       color={Color.TERNARY_TEXT_COLOR}>
                    {name}
                </Label>
                {services.map(renderService)}
            </>
        )
    }

    const renderPhotoBillSection = (name, data) => {

        let services = []

        data?.map((service, index) => {
            let vendorServices = []
            if (index === 0) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[0];
            }
            if (index === 1) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[1];
            }
            if (index === 3) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[2];
            }
            if (index === 4) {
                vendorServices = psQuery.data?.selected_property_services?.service_types[3];
            }
            if (vendorServices?.vendor_services?.length) {
                let subVendorServices = vendorServices?.vendor_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))))
                services = [...services, ...subVendorServices]
            } else {
                let subService = service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id))
                if (subService?.length) {
                    services = [...services, ...subService]
                }
            }
        })

        if (!services?.length) {
            return;
        }
        return (
            <>
                <Label font_medium
                       small
                       mt={15}
                       mb={5}
                       color={Color.TERNARY_TEXT_COLOR}>
                    {name}
                </Label>
                {services.map(renderService)}
            </>
        )
    }

    const renderAgreement = () => {

        let totalPrice = 0;
        let totalPre = 0;
        let totalSum = 0;
        let uttleggSum = 0;

        let purchasedUttlegg = psQuery?.data?.selected_property_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.UTLEGG && item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId, 10))).sort((a, b) => b.is_default - a.is_default);
        let purchasedVederlegg = psQuery?.data?.selected_property_services?.property_utlegg?.filter(item => item.price_type === Constants.PriceType.VEDERLEGG && item.usableServiceClass && item.usableServiceClass.property_id === (inspection?.property_id ?? parseInt(propertyId, 10))).sort((a, b) => b.is_default - a.is_default);

        let purchasedPackages = psQuery?.data?.selected_property_services?.marketing_package?.filter(item => item.property_package && item.property_package.property_id === (inspection?.property_id ?? propertyId));

        let allPurchasedServices = [];
        if (pmQuery.data && psQuery.data && dmQuery.data) {

            psQuery?.data?.selected_property_services?.services?.map((service, index) => {

                let vendorServices = []
                if (index === 0) {
                    vendorServices = psQuery.data?.selected_property_services?.service_types[0];

                }
                if (index === 1) {
                    vendorServices = psQuery.data?.selected_property_services?.service_types[1];
                }
                if (index === 3) {
                    vendorServices = psQuery.data?.selected_property_services?.service_types[2];
                }
                if (index === 4) {
                    vendorServices = psQuery.data?.selected_property_services?.service_types[3];
                }
                if (vendorServices?.vendor_services?.length) {
                    vendorServices?.vendor_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId)))).map(item => {
                        allPurchasedServices.push(item)
                    })
                    return;
                }
                service?.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                    allPurchasedServices.push(item)
                })
            });

            pmQuery?.data?.selected_property_services?.services?.map(service => {
                service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                    allPurchasedServices.push(item)
                })
            })
            dmQuery?.data?.selected_property_services?.services?.map(service => {
                service.purchased_office_template?.purchased_office_services?.filter(item => (item.service_selected && item.service_selected.property_id === (inspection?.property_id ?? parseInt(propertyId))) && !serviceInPackage.includes(item.id)).map(item => {
                    allPurchasedServices.push(item)
                })
            })
            totalSum = allPurchasedServices
                .map((item) => parseInt(item.service_selected ? item.service_selected.price : item?.property_package ? item?.property_package.price : (item.price * (item?.hours ? parseInt(item.hours, 10) : 1))))
                .reduce((a, b) => a + b, 0);
        }
        /*if (data?.property_agreement_details?.property_service) {
            totalSum = data?.property_agreement_details?.property_service?.map((item) => parseInt(item.price))
                .reduce((a, b) => a + b, 0)
        }*/
        if (purchasedVederlegg) {
            totalSum += purchasedVederlegg.map((item) => parseInt(item.price))
                .reduce((a, b) => a + b, 0);
        }
        if (purchasedPackages) {
            totalSum += purchasedPackages.map((item) => parseInt(item?.property_package ? item?.property_package.price : item.price))
                .reduce((a, b) => a + b, 0);
        }
        if (purchasedUttlegg) {
            uttleggSum = purchasedUttlegg.map((item) => parseInt(item.price))
                .reduce((a, b) => a + b, 0);
        }

        if (data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === 'FIX') {
            totalPrice = parseInt(data.property_agreement_details.property_remuneration.price)
        } else if (data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === 'HOURLY') {
            totalPrice = data?.property_agreement_details?.property_remuneration?.price && data?.property_agreement_details?.property_remuneration?.usableService?.hours ? parseInt(data.property_agreement_details.property_remuneration.price * data?.property_agreement_details?.property_remuneration?.usableService?.hours) : 0
        }

        /* totalPrice = parseInt(data?.property_agreement_details?.property_remuneration.price) +
             data?.property_agreement_details?.property_service
                 ?.map(item => parseInt(item.price))
                 .reduce((a, b) => a + b, 0)*/
        if (propertyDetails?.data?.property?.property_preference?.percentage) {
            totalPre = propertyDetails?.data?.property?.property_preference?.preferable_price * (propertyDetails?.data?.property?.property_preference?.percentage / 100);
        }

        if (loading && !data) {
            return <ActivityIndicator color={Color.PRIMARY}/>
        }
        let allAgents = [];
        if (propertyDetails?.data?.property?.property_agent) {
            allAgents = propertyDetails?.data?.property?.property_agent?.sort((a, b) => a.brokerRole - b.brokerRole);
        }
        let firstAgent = allAgents[0];
        let primarySeller = data?.property_agreement_details?.property_seller.find(item => item.is_primary_seller)
        let secondSeller = data?.property_agreement_details?.property_seller.find(item => !item.is_primary_seller)
        return (
            <View>
                <Label large
                       font_medium
                       mb={10}
                       align={'center'}>
                    {firstAgent?.name}
                </Label>
                <Label large
                       font_medium
                       mb={10}
                       align={'center'}>
                    {propertyDetails.data?.property?.agent?.title}
                </Label>

                <Label mb={10}
                       align={'center'}>
                    {`${Strings.phone}: ${firstAgent?.mobilePhone ?? ""}`}
                </Label>
                <Label mb={10}
                       align={'center'}>
                    {`${Strings.email}: ${firstAgent?.email ?? ""}`}
                </Label>
                <Label small
                       mt={10}>
                    {"I medhold av lov om eiendomsmegling av 29. juni 2007 nr. 73, er det inngått oppdragsavtale mellom:\n"}
                </Label>
                <Label font_medium
                       mt={20}>
                    {`1. ${"Avtaleparter"}`}
                </Label>
                <Label font_medium
                       mt={15}>
                    {"Oppdragsgiver :"}
                </Label>
                <View style={CommonStyle.flexRow}>
                    <View style={styles.nameCont}>
                        <Label small
                               font_medium
                               mt={10}>
                            {`${Strings.name} :`}
                        </Label>
                        <Label small
                               mt={10}
                               font_medium>
                            {Strings.birthNumber}
                        </Label>

                        <Label small
                               mt={10}
                               font_medium>
                            {`${Strings.address} :`}
                        </Label>

                        <Label small
                               mt={10}
                               font_medium>
                            {`${Strings.email} :`}

                        </Label>

                        <Label small
                               mt={10}
                               font_medium>
                            {`${Strings.phone} :`}

                        </Label>
                    </View>
                    <View style={CommonStyle.flex}>
                        <Label small
                               mt={10}>
                            {`${primarySeller?.firstName ?? ""} ${primarySeller?.lastName ?? ""}`}
                        </Label>

                        <Label small
                               mt={10}>
                            {getDecryptedId(primarySeller?.socialSecurity) ?? "-"}
                        </Label>
                        <Label small
                               mt={10}>
                            {`${primarySeller?.postalAddress ?? ""} ${primarySeller?.postalCode ?? ""} ${primarySeller?.city ?? ""}`}
                        </Label>
                        <Label small
                               mt={10}>
                            {primarySeller?.email ?? "-"}
                        </Label>
                        <Label small
                               mt={10}>
                            {primarySeller?.mobilePhone ?? "-"}
                        </Label>
                    </View>
                </View>

                {secondSeller ? (
                    <View style={CommonStyle.flexRow}>
                        <View style={styles.nameCont}>
                            <Label small
                                   font_medium
                                   mt={10}>
                                {`${Strings.name} :`}
                            </Label>
                            <Label small
                                   mt={10}
                                   font_medium>
                                {Strings.birthNumber}
                            </Label>

                            <Label small
                                   mt={10}
                                   font_medium>
                                {`${Strings.address} :`}
                            </Label>

                            <Label small
                                   mt={10}
                                   font_medium>
                                {`${Strings.email} :`}

                            </Label>

                            <Label small
                                   mt={10}
                                   font_medium>
                                {`${Strings.phone} :`}

                            </Label>
                        </View>
                        <View style={CommonStyle.flex}>
                            <Label small
                                   mt={10}>
                                {`${secondSeller?.firstName ?? ""} ${secondSeller?.lastName ?? ""}`}
                            </Label>

                            <Label small
                                   mt={10}>
                                {getDecryptedId(secondSeller?.socialSecurity) ?? "-"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {`${secondSeller?.postalAddress ?? ""} ${secondSeller?.postalCode ?? ""} ${secondSeller?.city ?? ""}`}
                            </Label>
                            <Label small
                                   mt={10}>
                                {secondSeller?.email ?? "-"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {secondSeller?.mobilePhone ?? "-"}
                            </Label>
                        </View>
                    </View>
                ) : null}


                <Label font_medium
                       mt={20}>
                    {"Oppdragstaker:"}
                </Label>
                {/*<Label small
                       style={CommonStyle.flex}
                       mt={5}>
                    {data?.property_agreement_details?.office_opprag_agreement?.oppdrag_staker}
                </Label>*/}
                <HTMLView value={data?.property_agreement_details?.office_opprag_agreement?.oppdrag_staker??""}/>
                {allAgents?.length > 1 ? (
                    <View style={styles.lblCont}>
                        <Label small
                               font_medium>
                            {Strings.responsibleBroker}
                        </Label>
                        <Label small
                               align={'left'}
                               ml={20}
                               style={CommonStyle.flex}>
                            {`${allAgents[1]?.name ?? ""}`}
                        </Label>
                    </View>) : null}

                {/*<View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {Strings.realEstateAgent}
                    </Label>
                    <Label small
                           ml={20}
                           style={CommonStyle.flex}>
                        {`${firstAgent?.name ?? ""}`}
                    </Label>
                </View>*/}
                <Label font_medium
                       mt={20}>
                    {Strings.settlementEnterprise}
                </Label>
                <Label small
                       style={CommonStyle.flex}
                       mt={5}>
                    {data?.property_agreement_details?.office_opprag_agreement?.Oppgjor_sforetak}
                </Label>

                {/*------Scope of assignment---*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`2. ${"Oppdragets omfang"}`}
                </Label>
                <Label small>
                    {`Oppdragstaker skal arbeide med salg av: ${getPropertyType(propertyDetails?.data?.property?.ownership)}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}
                       font_medium
                       mt={10}>
                    {`${Strings.address}:`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {`${inspection?.property?.street_adress},${inspection?.property?.zipcode} ${inspection?.property?.city}\n\n ${getPropertyTypePrefix(propertyDetails?.data?.property?.ownership)}`}
                </Label>

                {/*-------3 - Mission duration----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`3. ${"Oppdragets varighet"}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {"Oppdraget gjelder inntil det blir tilbakekalt, dog ikke utover en tid av 6 måneder fra underskrift av" +
                    "oppdragsavtalen. Oppdraget kan fornyes for inntil seks måneder av gangen. Fornyelse skal være" +
                    "skriftlig. Oppdraget kan sies opp skriftlig uten varsel av begge partene i oppdragsperioden."}
                </Label>

                {/*-------4 - Contractor's remuneration----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`4. ${"Oppdragstakers vederlag"}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {"Oppdragsgiver har anledning til å inngå avtale om enten provisjonsbasert vederlag eller timebasert" +
                    " vederlag. Denne avtale gir en oversikt over begge vederlagsformer. Samtlige beløp i avtalen er inkludert" +
                    " merverdiavgift. Vederlag under punkt 4.3 og 4.4 kommer i tillegg uavhengig av vederlagsform."}
                </Label>

                <Label small
                       style={CommonStyle.flex}>
                    {"Vederlaget forfaller når handel er kommet i stand, og vil bli fakturert i forbindelse med oppgjøret." +
                    " Oppdragstakeren kan bare dekke sitt krav på vederlag i klientmidler som tilhører oppdragsgiveren, dersom" +
                    " oppdragsgiveren har samtykket til dette etter at handel er kommet i stand."}
                </Label>

                <Label small
                       font_medium
                       mt={10}>
                    {"Oppdragsgiver velger følgende vederlagsform:"}
                </Label>
                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall}
                          name={'tickmark'}
                          color={data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "HOURLY" ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "HOURLY" ? Color.SECONDARY : Color.DARK_GRAY}>
                        {"Timebasert vederlag (Punkt 4.1)"}
                    </Label>
                </View>
                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "FIX" ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "FIX" ? Color.SECONDARY : Color.DARK_GRAY}>
                        {"Provisjonsbasert vederlag (Punkt 4.2)"}
                    </Label>
                </View>


                {/*-------4.1 - Hourly remuneration----*/}
                <View
                    style={{opacity: data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "HOURLY" ? 1 : 0.4}}>
                    <Label font_medium
                           mt={20}
                           mb={10}>
                        {`4.1 ${"Timebasert vederlag"}`}
                    </Label>
                    <Label small
                           style={CommonStyle.flex}
                           mb={10}>
                        {`Ved timebasert vederlag utgjør timesatsen Kr ${formatPrice(data?.property_agreement_details?.office_opprag_agreement?.hourly_rate??"")},- timer. Det anslås et tidsforbruk for oppdraget på totalt ${data?.property_agreement_details?.office_opprag_agreement?.time_spent ?? ""} timer. Oppdraget deles inn i følgende faser, anslått i timer:`}
                    </Label>
                    <HTMLView value={data?.property_agreement_details?.office_opprag_agreement?.hourly_remuneration??""}/>
                    <Label small
                           font_medium
                           mt={10}>
                        {`Sum vederlag basert på avtalt timesats Kr ${formatPrice(parseInt(data?.property_agreement_details?.office_opprag_agreement?.hourly_rate) * parseInt(data?.property_agreement_details?.office_opprag_agreement?.time_spent))},-`}
                    </Label>
                    <Label small
                           style={CommonStyle.flex}
                           mt={15}>
                        {"Tilbud om timesbasert vederlag er et estimat på forventet tidsbruk og vil variere. Timetallet er derfor ikke " +
                        "bindende for megler og endelig timetall kan avvike fra tilbudet som er gitt. Oppdragstaker plikter å varsle " +
                        "oppdragsgiveren dersom medgått tid forventes å vesentlig overstige det som er angitt i ovennevnte " +
                        "overslag. Ved valg av timesbasert vederlag inngår kostnader til grunnpakke markedsføring og oppgjør. " +
                        "Eventuelle kostnader til ytterligere markedsføring påløper i henhold til aktuelle priser. Visningshonorar " +
                        "faktureres i henhold til medgått tid."}
                    </Label>
                </View>

                {/*-------4.2 - Commission-based Remuneration----*/}
                <View
                    style={{opacity: data?.property_agreement_details?.property_remuneration?.usableService?.cost_type === "FIX" ? 1 : 0.4}}>
                    <Label font_medium
                           mt={20}
                           mb={10}>
                        {`4.2 ${"Provisjonsbasert vederlag"}`}
                    </Label>
                    {/*og evt. fellesgjeld: ${(propertyDetails?.data?.property?.property_preference?.percentage / 100) * propertyDetails?.data?.property?.property_preference?.preferable_price}*/}
                    <Label small
                           style={CommonStyle.flex}>
                        {`Megler har krav på vederlag når handel er kommet i stand (budaksept).`}
                    </Label>
                    {propertyDetails?.data?.property?.property_preference?.fix_price ? null :
                        (<View>
                            <Label small
                                   mt={15}
                                   style={CommonStyle.flex}>
                                {/*{`Eiendomsmegleren har krav på ${propertyDetails?.data?.property?.property_preference?.percentage ?? 0} % provisjon med utgangspunkt i prisantydning kr. ${formatPrice(propertyDetails?.data?.property?.property_preference?.preferable_price ?? 0)},-`}*/}
                                {`Det er avtalt ${propertyDetails?.data?.property?.property_preference?.percentage ?? 0} % provisjon med utgangspunkt i prisantydning kr. ${formatPrice(propertyDetails?.data?.property?.property_preference?.preferable_price ?? 0)},-`}
                            </Label>
                            <Label small
                                   mt={5}
                                   style={CommonStyle.flex}>
                                {`Beregnet provisjon basert på prisantydning Kr ${formatPrice(totalPre)},-`}
                            </Label>
                        </View>)}
                    {propertyDetails?.data?.property?.property_preference?.fix_price ? (<Label small
                                                                                               mt={15}
                                                                                               style={[CommonStyle.flex]}>
                        {`Fastpris: Eiendomsmegleren har krav på en fast provisjon kr. ${formatPrice(propertyDetails?.data?.property?.property_preference?.fix_price ?? 0)},-`}
                    </Label>) : null}
                </View>

                {/*-------4.3 - Other remuneration----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`4.3 ${"Øvrige vederlag"}`}
                </Label>
                <Label small
                       mb={5}
                       style={CommonStyle.flex}>
                    {"I tillegg til vederlag etter punkt 4.1 eller 4.2 betaler oppdragsgiver i tillegg følgende øvrige vederlag:"}
                </Label>
                {/*  <Label small
                       mb={5}
                       style={CommonStyle.flex}>
                    {"I tillegg til vederlag etter punkt 4.2 betaler oppdragsgiver:"}
                </Label>*/}

                {purchasedPackages?.length ? (
                    <Label font_medium
                           small
                           mt={15}
                           mb={5}
                           color={Color.TERNARY_TEXT_COLOR}>
                        {Strings.marketingPackages}
                    </Label>) : null}
                {purchasedPackages?.map(renderPackageService)}
                {renderPhotoBillSection(`${Strings.PHOTO}/${Strings.STYLING}`, psQuery?.data?.selected_property_services?.services)}
                {renderBillSection(Strings.digitalMarketing, dmQuery?.data?.selected_property_services?.services)}
                {renderBillSection(Strings.printMarketing, pmQuery?.data?.selected_property_services?.services)}

                {purchasedVederlegg?.length ? (
                    <Label font_medium
                           small
                           mt={15}
                           mb={5}
                           color={Color.TERNARY_TEXT_COLOR}>
                        {`Andre kostnader\n${Strings.vederlag}`}
                    </Label>) : null}
                {purchasedVederlegg?.map(renderService)}

                {propertyDetails?.data?.property?.property_preference?.rebate_amount ? (
                    <View style={{flexDirection: 'row', marginTop: 20, maxWidth: 520}}>
                        <Label font_medium
                               small
                               style={CommonStyle.flex}>
                            {"Avslag på totalprisen\n"}
                        </Label>
                        <Label font_medium
                               small
                               align={'right'}>
                            {`Kr ${formatPrice(propertyDetails?.data?.property?.property_preference?.rebate_amount)},-`}
                        </Label>
                    </View>) : null}
                <Hr lineStyle={{marginVertical: 10, maxWidth: 520}}/>
                <View style={{flexDirection: 'row', maxWidth: 520}}>
                    <Label font_medium
                           small
                           style={CommonStyle.flex}>
                        {"Sum"}
                    </Label>
                    <Label font_medium
                           small>
                        {`Kr ${formatPrice(totalSum - (propertyDetails?.data?.property?.property_preference?.rebate_amount ?? 0))},-`}
                    </Label>
                </View>


                <Label font_medium
                       mt={20}
                       mb={15}>
                    {`4.4 ${"Utlegg"}`}
                </Label>
                {/*{purchasedUttlegg?.length ? (
                    <Label font_medium
                           small
                           mt={15}
                           mb={5}
                           color={Color.TERNARY_TEXT_COLOR}>
                        {`Andre kostnader\n${Strings.utlegg}`}
                    </Label>) : null}*/}
                {purchasedUttlegg?.map(renderService)}
                <Hr lineStyle={{marginVertical: 10, maxWidth: 520}}/>
                <View style={{flexDirection: 'row', maxWidth: 520}}>
                    <Label font_medium
                           small
                           style={CommonStyle.flex}>
                        {"Sum"}
                    </Label>
                    <Label font_medium
                           small>
                        {`Kr ${formatPrice(uttleggSum)},-`}
                    </Label>
                </View>

                {data?.property_agreement_details?.office_opprag_agreement?.other_remuneration ? (
                    <View style={{marginTop: 20}}>
                        <HTMLView
                            value={data?.property_agreement_details?.office_opprag_agreement?.other_remuneration ?? ""}/>
                    </View>) : null}
                <View style={styles.totalLblCont}>
                    <Label font_medium
                           style={CommonStyle.flex}>
                        {`Totalt vederlag og utlegg`}
                    </Label>
                    <Label font_medium>
                        {`kr. ${formatPrice(uttleggSum + totalSum + totalPrice - (propertyDetails?.data?.property?.property_preference?.rebate_amount ?? 0))},-`}
                    </Label>
                </View>
                {/*-------4.4 - Other remuneration----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`4.5 ${"Betaling av meglers"}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {`Meglers vederlag og eventuelt øvrige kostnader som ikke faktureres oppdragsgiver direkte, blir trukket fra oppdragsgivers oppgjør på oppgjørstidspunktet. Oppdragsgiver kan velge å motta faktura ved budaksept og fellesgjeld kr. 0. med 14 dagers forfall.\n
Ved valg av faktura: Betalingsutsettelsegebyret bortfaller. Dersom beløpet ikke er innbetalt i sin helhet innen oppgjørstidspunktet påløper likevel betalingsutsettelsesgebyret og oppdragsgiver aksepterer at meglers vederlag, inkludert utlegg og eventuelle forsinkelsesrenter trekkes fra oppgjøret.`}
                </Label>


                {/*-------5 - Boligselgerforsikring ----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`5. ${"Boligselgerforsikring"}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}
                       mb={10}>
                    {"Vi anbefaler boligselgerforsikring gjennom BuySure. Prisen på forsikringen vil variere " +
                    "avhengig av hvilken type eiendom oppdraget gjelder og oppnådd salgssum. Dersom oppdragsgiver " +
                    "ønsker boligselgerforsikring trekkes kostnadene til dette fra oppgjøret. Kostnad til boligselgerforsikring " +
                    "kommer i tillegg til vederlag. Boligselgerforsikring må tegnes før boligen markedsføres.\n\n" +
                    "Avtale om boligselgerforsikring gjøres ved utfylling av egenerklæringsskjema, hvor en også vil få " +
                    "informasjon om vilkår og prisliste."}
                </Label>
                {insuranceQuery?.data?.boligselger_Insurance_list?.map((item, index) => (
                    <View style={styles.lblCont}
                          key={`${index}`}>
                        <Icon size={ThemeUtils.fontXSmall}
                              name={'tickmark'}
                              color={item.selected_in_property === 0 ? Color.DARK_GRAY : Color.SECONDARY}/>
                        <Label small
                               ml={5}
                               font_medium
                               color={item.selected_in_property === 0 ? Color.DARK_GRAY : Color.SECONDARY}>
                            {index === 0 && item.selected_in_property === 1 ? `${item.name} - ${item.insurance_type === Constants.InsuranceType.UTEN ? Strings.utenAnticimex : Strings.medAnticimex}` : item.name}
                        </Label>
                    </View>
                ))}

                {/*-------6 - - Oppdragstakers krav på dekning av vederlag ----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`6. ${"- Oppdragstakers krav på dekning av vederlag"}`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {"Oppdragstaker har krav på vederlag og dekning av påløpte kostnader dersom handelen kommer i stand " +
                    "i oppdragstiden, selv om dette ikke skyldes oppdragstakerens innsats, jf. emgl. § 7-3, 1. ledd."}
                </Label>

                <Label small
                       style={CommonStyle.flex}
                       mt={10}>
                    {data?.property_agreement_details?.property?.sales_warranty_status === 1 ? data?.property_agreement_details?.office_opprag_agreement?.sales_warranty_yes : data?.property_agreement_details?.office_opprag_agreement?.sales_warranty_no}
                </Label>

                <Label small
                       mt={10}>
                    {"Dersom en av oppdragsgiverne kjøper ut den andre/en av de andre oppdragsgiverne har oppdragstaker " +
                    "krav på fullt vederlag i henhold til punkt 4. Det samme gjelder dersom oppdragsgiver selger " +
                    "eiendommen til fraflyttet ektefelle."}
                </Label>
                <Label small
                       mt={10}>
                    {"Hvis boligen har vært felles bolig for ektefeller, er boet mellom ektefellene oppgjort?"}
                </Label>
                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_claim ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={propertyDetails?.data?.property?.client_claim ? Color.SECONDARY : Color.DARK_GRAY}>
                        {Strings.yes}
                    </Label>
                </View>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_claim ? Color.DARK_GRAY : Color.SECONDARY}/>
                    <Label small
                           ml={5}
                           font_medium
                           color={propertyDetails?.data?.property?.client_claim ? Color.DARK_GRAY : Color.SECONDARY}>
                        {Strings.no}
                    </Label>
                </View>
                <Label small
                       mt={10}>
                    {"Oppdragstaker har også krav på vederlag dersom handelen kommer i stand innen 3 måneder etter at " +
                    "oppdragstiden er ute, med noen som oppdragstaker har forhandlet med eller som etter forespørsel har " +
                    "fått opplysninger fra oppdragstaker om eiendommen i oppdragstiden, jf. emgl. § 7-3, 2.ledd. Dette " +
                    "omfatter også situasjoner hvor kjøperen har lastet ned opplysninger fra oppdragstaker på internett.\n\n" +
                    "Megler er gitt i eksklusivt oppdrag å gjennomføre salg av Eiendommen. Oppdragsgiver bekrefter at " +
                    "ingen andre meglere har arbeidet med salg av samme eiendom.\n\n" +
                    "Følgende megler(e) har arbeidet med salg av samme eiendomsoppdraget de siste 3 måneder før " +
                    "signatur av oppdraget:"}
                </Label>

                <Label font_medium
                       small
                       mt={10}
                       color={Color.SECONDARY}>
                    {propertyDetails?.data?.property?.same_contract_assignment === 1 ? "Annet meglerforetak: " + propertyDetails?.data?.property?.name_of_brokerage : "Ingen"}
                </Label>

                {/*-------7 - Særlige bestemmelser ----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`7. ${"Særlige bestemmelser"}`}
                </Label>
                {/*<Label small
                       mt={15}
                       font_medium>
                    {"Ansvarlig megler bistås av"}
                </Label>
                <Label small>
                    {"ansvarlig meglers fravær aksepteres det at meglerkontorets øvrige ansatte kan bistå i oppdraget. " +
                    "Ved lengre fravær skal dette varsles skriftlig."}
                </Label>
                <Label small
                       mt={15}
                       font_medium>
                    {"Ansvarlig megler bistås av"}
                </Label>*/}

                {/* {`Det økonomiske oppgjøret foretas av ${data?.property_agreement_details?.office_opprag_agreement?.financial_settlement} Oppgjørsmedarbeidere er ${data?.property_agreement_details?.office_opprag_agreement?.settlement_employees} Det tar normalt 2-3 uker fra overtakelse
til utbetaling av oppgjør. Dette er grunnet behandlingstid hos Statens Kartverk, postgang og`}*/}

                <HTMLView value={data?.property_agreement_details?.office_opprag_agreement?.financial_settlement??""}/>

                {QUESTION.map(item => (
                    <View key={item.question}>
                        <Label small
                               mt={15}
                               font_medium>
                            {item.question}
                        </Label>
                        <Label small>
                            {item.answer}
                        </Label>
                    </View>
                ))}
                <View>
                    <Label small
                           mt={15}
                           font_medium>
                        {"Angrett"}
                    </Label>
                    <View style={styles.lblCont}>
                        <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                              color={Color.SECONDARY}/>
                        <Label small
                               font_medium
                               ml={5}
                               style={CommonStyle.flex}
                               color={Color.SECONDARY}>
                            {"Jeg ønsker at eiendomsmeglingsforetaket skal sette i gang arbeidet i henhold til oppdragsavtalen, herunder starte levering av tilknyttede avtaler, før angrefristen på 14 dager har utløpt, og erkjenner at angreretten har gått tapt når tjenesten er levert."}
                        </Label>
                    </View>

                    <View style={styles.lblCont}>
                        <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                              color={Color.DARK_GRAY}/>
                        <Label small
                               ml={5}
                               font_medium
                               style={CommonStyle.flex}
                               color={Color.DARK_GRAY}>
                            {"Jeg ønsker IKKE at eiendomsmeglingsforetaket skal sette i gang arbeidet i henhold til oppdragsavtalen, herunder starte levering av tilknyttede avtaler, før angrefristen på 14 dager har utløpt." +
                            "Oppdragsgiver utøver angrerett gjennom å sende skriftlig beskjed om dette til megler innen 14 dager fra dagen etter oppdragsavtalen ble underskrevet av begge parter. Oppdragsgiver som benytter seg av angreretten etter å uttrykkelig ha anmodet eiendomsmeglingsforetaket om å begynne på oppdraget før angrefristen er gått ut, må betale eiendomsmegler et rimelig vederlag for den delen av tjenesten som er levert frem til tidspunkt for bruk av angreretten"}
                        </Label>
                    </View>
                </View>

                {/*-------8 - Politisk eksponert person (PEP) ----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`8. ${"Politisk eksponert person (PEP)"}`}
                </Label>
                <Label small
                       mt={15}
                       font_medium>
                    {"En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt, et høytstående offentlig " +
                    "verv eller stilling. Er oppdragsgiver en politisk eksponert person (PEP)?"}
                </Label>
                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.political_person ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={propertyDetails?.data?.property?.political_person ? Color.SECONDARY : Color.DARK_GRAY}>
                        {Strings.yes}
                    </Label>
                </View>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.political_person ? Color.DARK_GRAY : Color.SECONDARY}/>
                    <Label small
                           ml={5}
                           font_medium
                           color={propertyDetails?.data?.property?.political_person ? Color.DARK_GRAY : Color.SECONDARY}>
                        {Strings.no}
                    </Label>
                </View>
                <Label small
                       mt={10}>
                    {"Hvorfor spør vi om dette?\n" +
                    "Personer i fremtredende stillinger kan være mer utsatt for forsøk på hvitvasking av penger. Det samme\n" +
                    "gjelder om du er ett nært familiemedlem eller kjent medarbeider til en som er, eller tidligere har vært en\n" +
                    "politisk eksponert person.\n\n" +
                    "Hva menes med offentlig verv eller stilling?\n" +
                    "- Statsoverhode, regjeringssjef, minister eller assisterende minister\n" +
                    "- Medlem av nasjonalforsamling\n" +
                    "- Medlem av styrende organ i politisk parti\n" +
                    "- Medlem av høyere rettsinstans som treffer beslutning som ikke eller bare unntaksvis kan ankes\n" +
                    "- Medlem av styre i riksrevisjon, revisjons-domstol eller sentralbank\n" +
                    "- Ambassadør, chargé d’affaires eller militær offiser av høyere rang\n" +
                    "- Medlem av administrative, ledende eller kontrollerende organ i statlig foretak\n" +
                    "- Direktør, styremedlem eller annen person i øverste ledelse av internasjonal organisasjon\n" +
                    "- Nært familiemedlem og kjent medarbeider til PEP regnes også som en politisk eksponert person.\n\n" +
                    "Nært familiemedlem er: foreldre, ektefelle/registrert partner/samboer, barn, samt barns " +
                    "ektefelle/registrert partner/samboer\n\n" +
                    "Kjent medarbeider er en fysisk person som er kjent for å:\n" +
                    "1. Være reell rettighetshaver i juridisk person, sammenslutning eller utenlandsk juridisk arrangement i\n" +
                    "fellesskap med politisk eksponert person\n" +
                    "2. Ha nær forretningsforbindelse til politisk eksponert person\n" +
                    "3. Være eneste reelle rettighetshaver i juridisk person, sammenslutning eller utenlandsk juridisk\n" +
                    "arrangement som i realiteten er etablert for å begunstige politisk eksponert person"}
                </Label>

                {/*-------9 - Fullmakt ----*/}
                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`9. ${"Erklæring om sivilstand"}`}
                </Label>
                <Label small>
                    {"1. Er oppdragsgiver gift/registrert partner?"}
                </Label>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_married_partner ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={propertyDetails?.data?.property?.client_married_partner ? Color.SECONDARY : Color.DARK_GRAY}>
                        {Strings.yes}
                    </Label>
                </View>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_married_partner ? Color.DARK_GRAY : Color.SECONDARY}/>
                    <Label small
                           ml={5}
                           font_medium
                           color={propertyDetails?.data?.property?.client_married_partner ? Color.DARK_GRAY : Color.SECONDARY}>
                        {Strings.no}
                    </Label>
                </View>


                <Label small
                       mt={10}>
                    {"2. Er oppdragsgiver gift/registrert partner med hverandre og begge underskriver som oppdragsgiver?"}
                </Label>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_partner_both_sign ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={propertyDetails?.data?.property?.client_partner_both_sign ? Color.SECONDARY : Color.DARK_GRAY}>
                        {Strings.yes}
                    </Label>
                </View>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.client_partner_both_sign ? Color.DARK_GRAY : Color.SECONDARY}/>
                    <Label small
                           ml={5}
                           font_medium
                           color={propertyDetails?.data?.property?.client_partner_both_sign ? Color.DARK_GRAY : Color.SECONDARY}>
                        {Strings.no}
                    </Label>
                </View>

                <Label small
                       mt={10}>
                    {"3. Gjelder oppdraget bolig som oppdragsgiver og dennes ektefelle/registrerte partner bruker som felles\n" +
                    "bolig?"}
                </Label>


                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.contract_apply_housing ? Color.SECONDARY : Color.DARK_GRAY}/>
                    <Label small
                           font_medium
                           ml={5}
                           color={propertyDetails?.data?.property?.contract_apply_housing ? Color.SECONDARY : Color.DARK_GRAY}>
                        {Strings.yes}
                    </Label>
                </View>

                <View style={styles.lblCont}>
                    <Icon size={ThemeUtils.fontXSmall} name={'tickmark'}
                          color={propertyDetails?.data?.property?.contract_apply_housing ? Color.DARK_GRAY : Color.SECONDARY}/>
                    <Label small
                           ml={5}
                           font_medium
                           color={propertyDetails?.data?.property?.contract_apply_housing ? Color.DARK_GRAY : Color.SECONDARY}>
                        {Strings.no}
                    </Label>
                </View>


                {/*------------10. Signaturer-----------*/}

                <Label font_medium
                       mt={20}
                       mb={10}>
                    {`10. ${"Signaturer"}`}
                </Label>
                <Label small>
                    {"Oppdragsgiver signerer for at samtlige sider er lest og forstått. Oppdragsavtalen er tilgjengeliggjort i sin " +
                    "helhet og kan signeres elektronisk etter eiendomsmeglingslovens § 3-7 (2)."}
                </Label>

                <Label small
                       mt={20}>
                    {`${moment().format(DateUtils.dd_mm_yyyy)}`}
                </Label>

                <View style={[styles.lblCont, {marginVertical: 15}]}>
                    <View style={[CommonStyle.flex]}>
                        <Label small
                               font_medium>
                            {"Oppdragsgiver:"}
                        </Label>
                        <Label small>
                            {`${primarySeller?.firstName ?? ""} ${primarySeller?.lastName ?? ""}`}
                        </Label>
                    </View>
                    <View style={[CommonStyle.flex, {justifyContent: 'flex-end'}]}>
                        <Label small
                               font_medium>
                            {"Oppdragstaker v/ansvarlig megler:"}
                        </Label>
                        <Label small>
                            {allAgents[1]?.name}
                        </Label>
                    </View>
                </View>
                {secondSeller ? (<View style={[styles.lblCont, {marginVertical: 15}]}>
                    <View style={[CommonStyle.flex]}>
                        <Label small
                               font_medium>
                            {"Oppdragsgiver:"}
                        </Label>
                        <Label small>
                            {`${secondSeller?.firstName ?? ""} ${secondSeller?.lastName ?? ""}`}
                        </Label>
                    </View>
                    <View style={[CommonStyle.flex, {justifyContent: 'flex-end'}]}>
                        <Label small
                               font_medium>
                            {"Oppdragstaker v/ansvarlig megler:"}
                        </Label>
                        <Label small>
                            {allAgents[1]?.name}
                        </Label>
                    </View>
                </View>) : null}
            </View>)
    };

    return (
        <View style={CommonStyle.flex}>
            <Header renderTitle={() => <LogoForHeader/>}
                    headerRightSecond={'dashboard'}
                    headerRightFirst={'close'}
                    onPressRightFirst={() => {
                        if (IS_WEB) {
                            history.replace(`/${Routes.InspectionApplication}`)
                        } else {
                            navigation.pop(7)
                        }
                    }}
                    onPressRightSecond={() => {
                        if (IS_WEB) {
                            history.replace(`/${Routes.StartInspection}/${encryptId(inspection.property_id)}`, {
                                property: inspection.property
                            })
                        } else {
                            navigation.pop(3)
                        }
                    }}

                    tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>
            <ScrollView style={styles.cont}>
                <View style={styles.contentCont}>

                    <Image source={Logo}
                           resizeMode={'center'}
                           style={styles.logo}/>
                    <Label xlarge
                           font_medium
                           align={'center'}
                           color={Color.SECONDARY}>
                        {`${Strings.agreementOnSaleOf}\n`}
                        {`${inspection?.property?.street_adress},${inspection?.property?.zipcode} ${inspection?.property?.city}`}
                    </Label>

                    <Label large
                           font_medium
                           mt={20}
                           mb={10}
                           align={'center'}>
                        {Strings.yourContactPerson}
                    </Label>


                    {renderAgreement()}
                    <CheckBox onCheckChanged={setFirmName}
                              rightTextStyle={CommonStyle.flex}
                              options={[{
                                  name: Strings.agrementConfirm,
                                  value: 1
                              }]}/>
                    <CustomButton title={Strings.gotoAssignment}
                                  loading={signMutattion.loading}
                                  disabled={!firmName.includes(1)}
                                  mt={20}
                                  onPress={() => {
                                      sign({
                                          variables: {
                                              input: {
                                                  property_id: inspection?.property_id,
                                                  redirect_url: data?.property_agreement_details?.total_owners >= 2 ?
                                                      `${Constants.APIConfig.DOMAIN}megler/${Routes.StartInspection}/${encryptId(JSON.stringify({id: inspection?.property_id}))}/${Routes.CoOwnerSignAgreement}` :
                                                      `${Constants.APIConfig.DOMAIN}megler/${Routes.SignSuccess}/${encryptId(JSON.stringify({
                                                          total_owners: 1,
                                                          is_email: 0,
                                                          skip_co_owner: 1,
                                                          property_id: inspection?.property_id
                                                      }))}`,
                                                  cancel_url: `${Constants.APIConfig.DOMAIN}${Routes.StartInspection}/${IS_WEB ? props.match.params.id : encryptId(inspection?.property_id)}/${Routes.SignAgreement}`,
                                                  owner_number: 1
                                              }
                                          }
                                      }).then((data) => {
                                          let response = JSON.parse(data?.data.owner_sign_agreement.ownerAgreement_sign)
                                          if (IS_WEB) {
                                              window.open(response?.owner_1.AuthenticationUrl)
                                          } else {
                                              props.navigation.navigate(Routes.WebViewScreen, {
                                                  redirect_url: data?.property_agreement_details?.total_owners >= 2 ?
                                                      `${Constants.APIConfig.DOMAIN}megler/${Routes.StartInspection}/${encryptId(JSON.stringify({id: inspection?.property_id}))}/${Routes.CoOwnerSignAgreement}` :
                                                      `${Constants.APIConfig.DOMAIN}megler/${Routes.SignSuccess}/${encryptId(JSON.stringify({
                                                          total_owners: 1,
                                                          is_email: 0,
                                                          skip_co_owner: 1,
                                                          property_id: inspection?.property_id
                                                      }))}`,
                                                  cancel_url: `${Constants.APIConfig.DOMAIN}${Routes.StartInspection}/${IS_WEB ? props.match.params.id : encryptId(inspection?.property_id)}/${Routes.SignAgreement}`,
                                                  redirectRoute: data?.property_agreement_details?.total_owners >= 2 ? Routes.CoOwnerSignAgreement : Routes.SignSuccess,
                                                  params: {
                                                      params: {
                                                          total_owners: 1,
                                                          is_email: 0,
                                                          skip_co_owner: 1,
                                                          property_id: inspection?.property_id
                                                      }
                                                  },
                                                  url: response?.owner_1.AuthenticationUrl
                                              })
                                          }
                                      })

                                  }}
                                  mb={20}/>
                </View>
            </ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(SignAgreement)
