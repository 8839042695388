import {StyleSheet} from 'react-native';
import {Color,ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        marginTop:ThemeUtils.relativeRealHeight(2),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    inptCont:{
        flexDirection:'row'
    },
    icCont:{
        backgroundColor:Color.DARK_GRAY,
        padding:ThemeUtils.relativeRealWidth(3),
        marginHorizontal:ThemeUtils.relativeRealWidth(2),
        borderRadius:4,
        alignSelf:'flex-end'
    },
    lblAprovedDate:{
        backgroundColor:Color.DARK_GRAY,
        paddingVertical:ThemeUtils.relativeRealHeight(2),
        borderRadius: 4,
        marginVertical:ThemeUtils.relativeRealWidth(2),
        marginHorizontal:ThemeUtils.relativeRealWidth(20)
    }


})
