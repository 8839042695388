import React, {useEffect, useState} from 'react'
//Components
import {ScrollView, TouchableOpacity, View} from 'react-native'
import {CustomButton, InputField, Label, LogoForHeader} from "components/src/components";
//Third party
//Network
import {useMutation} from "@apollo/react-hooks";
import {FORGOT_PASSWORD} from "components/src/api/mutation";
//redux
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";

function ForgotPassword(props) {

    const {history, navigation} = props;

    const [email, setEmail] = useState('');
    const [errEmail, setEmailErr] = useState(null);

    useEffect(() => {
        setEmailErr(null)
    }, [email]);

    const [sendResetLink, forgotPasswordMutation] = useMutation(FORGOT_PASSWORD);

    const sendLink = () => {
        let errEmail = validation('email', email.trim());
        if (errEmail) {
            setEmailErr(errEmail)
        } else {
            sendResetLink({
                variables: {
                    email: email.trim(),
                    userTypeId: Constants.UserType.Agent
                }
            }).catch(() => {

            })
        }
    };

    const renderHeaderTitle = () => {
        return (
            <View style={styles.headerTitle}>
                <LogoForHeader size={ThemeUtils.responsiveScale(120)}/>

            </View>
        )
    };
    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={styles.scrollContentContainer}>

                <View style={styles.topContainer}>
                {renderHeaderTitle()}
                    <View style={CommonStyle.content_center}>
                        <Label xxlarge
                               font_medium
                               mt={ThemeUtils.relativeRealHeight(2)}
                               color={Color.WHITE}
                               align={'center'}>
                            {Strings.forgotYourPassword}
                        </Label>

                        <Label small
                               mt={20}
                               mb={20}
                               color={Color.WHITE}
                               textStyle={styles.inst}
                               align={'center'}>
                            {Strings.forgotPasswordIns}
                        </Label>
                    </View>
                    <View style={styles.fieldContainer}>
                        <InputField labelProps={{
                            color: Color.WHITE
                        }}
                                    style={{
                                        borderColor: Color.WHITE
                                    }}
                                    color={Color.WHITE}
                                    type="email-address"
                                    onChange={setEmail}
                                    onFocus={() => setEmailErr(null)}
                                    error={errEmail}
                                    value={email}
                                    labelText={Strings.email}
                                    placeholder={Strings.email}/>
                    </View>
                    <View style={styles.btnCont}>

                        <CustomButton font_medium
                                      loading={forgotPasswordMutation.loading}
                                      onPress={sendLink}
                                      title={Strings.send}/>

                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => {
                                              if (IS_WEB) {
                                                  history.replace(Routes.SignIn)

                                              } else {
                                                  navigation.goBack()
                                              }
                                          }}>
                            <Label small
                                   font_medium
                                   mt={25}
                                   mb={25}
                                   align={'center'}
                                   color={Color.TERNARY_TEXT_COLOR}>
                                {Strings.signIn}
                            </Label>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </View>)
}


const mapStateToProps = (state) => {
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
