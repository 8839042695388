import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    topContainer: {
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: 'center'
    },

    centercontainer: {
        marginTop: 15,
        // marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    imageProfile:
        {
            backgroundColor: Color.GRAY,
            width: 175,
            height: 175,
            borderRadius: 12,
            marginBottom: 10
        },

    fieldView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
    uploadView: {
        alignSelf: 'flex-start',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: Color.INPUT_BORDER,
        justifyContent: 'center',
        alignContent: 'center',
        // marginBottom: 15,
        borderRadius: 6,
        marginBottom: 5
    },

    UploadedMainView: {
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        justifyContent: 'space-between',
        backgroundColor: Color.UPLOAD_BG_COLOR,
        borderRadius: 6,
        marginTop: 10
    },
    titleCont: {
        marginTop: 5,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    titleConts: {
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    IconView:
        {
            marginLeft: 2,
            flexDirection: 'row',
            alignItems: 'center',


        },
    IconViews:
        {
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
    DeleteIcon: {marginLeft: 7},
    DownloadIcon: {marginRight: 7},
    btnCont: {
        flexDirection: 'row',
        flex: 1,
        marginBottom: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    feedBackCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: 10
    },
    feedBackLblCont: {
        flexDirection: 'row',
        marginBottom: 8
    },
    ratingContainer: {
        marginTop: ThemeUtils.relativeRealHeight(6)
    },
    icEdit: {
        alignSelf: 'flex-start'
    },
    SubViewIcon: {
        backgroundColor: Color.DARK_GRAY,
        height: ThemeUtils.responsiveScale(150),
        width: ThemeUtils.responsiveScale(150),
        borderRadius: ThemeUtils.responsiveScale(4),
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start'
    },
    img: {
        height: ThemeUtils.responsiveScale(150),
        width: ThemeUtils.responsiveScale(150),
        borderRadius: 4,
        borderWidth: 0.8,
        borderColor: Color.PRIMARY_LIGHT

    },
    mediaImg: {
        height: ThemeUtils.responsiveScale(100),
        width: ThemeUtils.responsiveScale(100),
        borderRadius: ThemeUtils.responsiveScale(8),
    },
    mediaCont: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    }

});
export {styles};
