export default {
  'component.rewards.title': 'W Rewards',

  'component.rewards.li1': 'Introduksjon',
  'component.rewards.li2': 'Personvern:',
  'component.rewards.li3': 'Beskrivelse av tjenesten:',
  'component.rewards.li4': 'Bruk av copyright / materiale / varemerker',
  'component.rewards.li5': 'Hyperkoblinger til dette nettstedet',
  'component.rewards.li6': 'Brukerkontoer, passord og sikkerhet',
  'component.rewards.li7':
    ' Innhold som overlates til Webmegging for bruk eller distribusjon gjennom Webmegging',
  'component.rewards.li8': 'Generell praksis for bruk av nettmegling:',
  'component.rewards.li9': 'Endring av tjeneste:',
  'component.rewards.li10': 'Avslutning:',
  'component.rewards.li11': 'Ansvarsfraskrivelse:',
  'component.rewards.li12': 'Ulovlig eller ulovlig bruk',
  'component.rewards.li13': 'Annonsering og lenker til tredjeparts nettsteder:',
  'component.rewards.li14': 'Gjeldende lovgivning:',
  'component.rewards.Privacy.li1': 'Introduksjon',
  'component.rewards.Privacy.li2': 'Ansvarlig for behandlingen',
  'component.rewards.Privacy.li3': 'Processing of personal data',
  'component.rewards.Privacy.li4': 'Behandling av personopplysninger',
  'component.rewards.Privacy.li4.1': 'Det er nødvendig å oppfylle en avtale med deg',
  'component.rewards.Privacy.li4.2': 'Vi har en legitim interesse i behandlingen',
  'component.rewards.Privacy.li4.3': 'Det er nødvendig å overholde lovpålagte forpliktelser',
  'component.rewards.Privacy.li4.4': 'Ditt samtykke',
  'component.rewards.Privacy.li.5': 'Sikring av personopplysninger',
  'component.rewards.Privacy.li.6': 'Utlevering av personopplysninger til andre',
  'component.rewards.Privacy.li.7': 'Informasjonskapsler / informasjonskapsler',
  'component.rewards.Privacy.li.8': 'Lagringstid',
  'component.rewards.Privacy.li.9': 'Dine rettigheter',
  'component.rewards.Privacy.li.10': 'Endringer',
  'component.rewards.Footer.heading1': 'kjeks',
};
