import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rightText: {
        marginLeft: 10,
        fontFamily: 'Maax-Regular',
    },
});

export default styles;
