import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.WHITE
    },
    img: {
        height: ThemeUtils.relativeRealHeight(30)
    },
    lblCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    icClose: {
        position: 'absolute',
        zIndex:100,
        top: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        start: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    }
})

