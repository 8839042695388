import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    cont:{
        marginTop:5
    },
    lblCont:{
        marginTop:10
    },
    bidCont:{
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical:10
    },
    bidLblCont:{
        flexDirection:'row',
        marginVertical:ThemeUtils.relativeRealHeight(0.5)
    },

});

export default style;
