export default {
  'component.allagents.title': 'Alle meglere',
  'component.allagents.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.allagents.addallagents': 'Legg til megler',
  'component.allagents.tablesearch': 'Søk etter navn, e-post, telefon og tittel',
  'component.allagents.tab1': 'Meglerdetaljer',
  'component.allagents.tab2': 'Tilbakemeldinger fra kunder',
  'component.allagents.tab3': 'Presse',
  'component.allagents.tab1.title':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa',
  'component.allagents.ratings': 'Rangeringer',
  'component.allagents.feedback': 'Tilbakemelding',
  'component.allagents.feedback.placeholder': 'Skriv tilbakemelding her ...',
  'component.allagents.feedbackgivenby': 'Tilbakemelding gitt av',
  'component.allagents.feedbackgivenby.placeholder': 'Skriv inn navn',
  'component.allagents.allfeedback': 'Alle tilbakemeldinger',
  'component.allagents.deletemodal.title': 'Slett megler',
  'component.allagents.deletemodal.text': 'Er du sikker på du vil slette denne megleren?',
  'component.allagents.officename': 'Kontornavn',
  'component.allagents.addAgent': 'Legg til megler',
};
