import React from 'react'
import {ActivityIndicator, View} from 'react-native';
import {Hr, Label, Modal, Ripple} from "components/src/components";
import styles from './styles.js'
import PropTypes from 'prop-types';
import {Color, CommonStyle, IS_WEB, Strings, ThemeUtils} from "components/src/utils";

export default function Dialog(props) {
    const {visible, loading, onNegativePress, onPositivePress, title, desc, buttonTitleFirst, buttonTitleSecond} = props;
    return (
        <Modal transparent
               animated
               animationType={'fade'}
               visible={visible}
               hardwareAccelerated>
            <View style={styles.container}>
                <View style={styles.dialogCont}>
                    <Label ml={10}
                           mt={10}
                           mr={10}
                           font_medium
                           align={'center'}
                           style={styles.lblTitle}>
                        {title}
                    </Label>
                    <Label ml={10}
                           mr={10}
                           mb={15}
                           align={'center'}
                           style={styles.lblTitle}>
                        {desc}
                    </Label>
                    <Hr/>
                    <View style={styles.btnCont}>
                        <Ripple onPress={onNegativePress}
                                style={CommonStyle.flex}>
                            <Label mt={15}
                                   mb={15}
                                   align={'center'}>
                                {buttonTitleFirst}
                            </Label>
                        </Ripple>
                        <View style={styles.divider}/>
                        <Ripple onPress={onPositivePress}
                                style={CommonStyle.flex}>
                            <Label mt={15}
                                   mb={15}
                                   color={props.color}
                                   align={'center'}>
                                {loading ? (
                                    <ActivityIndicator style={IS_WEB ? null : CommonStyle.loaderSize}
                                                       color={Color.PRIMARY}/>
                                ) : buttonTitleSecond}
                            </Label>
                        </Ripple>
                    </View>
                </View>
            </View>
        </Modal>
    )
}
Dialog.defaultProps = {
    buttonTitleFirst: Strings.cancel,
    buttonTitleSecond: Strings.okay,
    color: Color.RED
}
Dialog.propTypes = {
    visible: PropTypes.bool,
    onPositivePress: PropTypes.func,
    onNegativePress: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
    buttonTitleFirst: PropTypes.string,
    buttonTitleSecond: PropTypes.string
}
