import React, {useEffect, useState} from 'react'
import {CustomButton, Hr, Label, Modal, Ripple} from "components/src/components";
import {ActivityIndicator, Image, SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
import styles from './styles';
import {Color, CommonStyle, Constants, formatPrice, Icon, Strings, ThemeUtils} from "components/src/utils";
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../api/mutation";
import debounce from "lodash.debounce";

function InspectionMarketingPackage(props) {

    const {loading, packageData, inspection, loadMore, fetchMore, onChangeOptions, data} = props

    const [selectedService, setSelectedService] = useState([]);
    //data?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id)
    const IMAGES = packageData?.MarketingPackageImages ?? []

    const [selectedImage, setSelectedImage] = useState(0);

    const [addPackagesVisible, setPackageVisible] = useState(false);

    const [packageForView, setPackageForView] = useState();

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {
            props.refetch()
        }
    });

    useEffect(() => {
        setPackageVisible(!!packageForView)
    }, [packageForView])

    const saveServiceWithDebounce = React.useCallback(debounce((variables) => {
        saveServices({
            variables: {
                input: {
                    ...variables,
                    broker_on_view: false
                }
            }
        }).then(() => {
            props.refetch()
        }).catch(() => {
        })
    }, 2000), [])

    useEffect(() => {
        if (data) {
            setSelectedService(data?.filter(item => item?.property_package).map(item => item.id))
        }
    }, [data]);


    const next = () => {
        let image = selectedImage;
        if (selectedImage + 1 < IMAGES.length) {
            setSelectedImage(++image)
        }
    };

    const prev = () => {
        let image = selectedImage;
        if (selectedImage > 0) {
            setSelectedImage(--image)
        }
    };

    const renderBottomImages = (item, index) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => setSelectedImage(index)}>
                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}
                       style={styles.imgBottom}/>
            </TouchableOpacity>

        )
    };

    const renderPackage = (item, index) => {

        return (
            <Ripple style={[styles.modalLblCont]}
                    onPress={() => {
                    }}
                    rippleContainerBorderRadius={4}
                    key={index.toString()}>
                <Label small
                       color={Color.SECONDARY}>
                    {item?.office_service?.name}
                </Label>
            </Ripple>
        )


    };

    const renderAddPackagesModal = () => {
        return (
            <Modal visible={addPackagesVisible}
                   transparent>

                <SafeAreaView style={CommonStyle.flex}>
                    <View style={styles.modalCont}>
                        <View style={styles.dialogCont}>
                            <View style={styles.header}>
                                <Label large
                                       style={CommonStyle.flex}
                                       font_medium>
                                    {Strings.services}
                                </Label>
                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={() => {
                                                      setPackageForView(null)
                                                  }}>
                                    <Icon name={'close'}
                                          size={ThemeUtils.fontLarge}
                                          color={Color.PRIMARY}/>
                                </TouchableOpacity>
                            </View>
                            <Hr marginBottom={20}/>
                            <ScrollView contentContainerStyle={styles.pkgCont}>
                                {loading && !data ? (
                                    <ActivityIndicator color={Color.PRIMARY}
                                                       style={CommonStyle.loader}/>
                                ) : packageForView?.marketing_package_service?.map(renderPackage)}
                            </ScrollView>
                            <Hr/>
                            <View style={styles.btnCont}>

                                <CustomButton title={Strings.okay}
                                              onPress={() => {
                                                  setPackageForView(null)
                                              }}
                                              bgColor={Color.SECONDARY}
                                              style={{width: ThemeUtils.relativeWidth(20)}}
                                              textColor={Color.WHITE}
                                              borderColor={Color.SECONDARY}
                                              borderWidth={1}
                                              ml={10}/>

                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    const renderService = (item, index, self) => {
        // const data = self.filter(item => item.service_selected).map(item => item.service_selected.id)
        return (
            <View style={styles.icDetailsCont}>
                <Ripple
                    style={[styles.serviceCont, selectedService.includes(item.id) ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                    onPress={() => {
                        // addOrRemoveItemFromArray(item)
                        if (!loading) {
                            let previousSelected = data?.filter(item => item?.property_package).map(filteredItem => filteredItem?.property_package?.id)

                            let variables = {
                                property_id: inspection?.property_id ?? propertyId,
                                marketing_package: [item.id],
                                broker_on_view: false
                            }
                            if (!previousSelected.includes(item?.property_package?.id)) {
                                variables = {
                                    ...variables,
                                    unselect_marketing_package: previousSelected
                                }
                            }
                            if (!selectedService.includes(item.id)) {
                                saveServiceWithDebounce(variables)
                                setSelectedService([item.id])

                            } else if (item.property_package?.id) {
                                variables = {
                                    property_id: inspection?.property_id ?? propertyId,
                                    unselect_marketing_package: [item.property_package?.id]
                                }
                                saveServiceWithDebounce(variables)
                                setSelectedService([])
                            }
                        }
                    }}
                    key={index.toString()}>
                    <Label small
                           font_medium
                           color={selectedService.includes(item.id) ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                        {item.name}
                    </Label>
                    <Label small
                           font_medium
                           color={selectedService.includes(item.id) ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                    </Label>
                    <Label xsmall
                           mt={10}
                           style={CommonStyle.flex}>
                        {item.description}
                    </Label>
                </Ripple>
                <TouchableOpacity activeOpacity={0.5}
                                  onPress={() => setPackageForView(item)}
                                  style={styles.icInfoCont}>
                    <Icon name={'disclaimer'}
                          size={ThemeUtils.fontNormal}
                          color={Color.SECONDARY}/>
                </TouchableOpacity>
            </View>
        )
    };

    return (
        <View style={styles.container}>
            {renderAddPackagesModal()}
            <View style={styles.displayCont}>
                <View style={CommonStyle.flex}>
                    <View style={styles.imgCont}>
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={prev}
                                          style={styles.icCont}>
                            <Icon name={'arrow-l'}
                                  size={ThemeUtils.fontXLarge}
                                  color={Color.WHITE}/>
                        </TouchableOpacity>
                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${IMAGES[selectedImage]?.image}`}}
                               resizeMode={'contain'}
                               style={[CommonStyle.flex, {backgroundColor: Color.GRAY}]}/>
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={next}
                                          style={styles.icContNext}>
                            <Icon name={'arrow-r'}
                                  size={ThemeUtils.fontXLarge}
                                  color={Color.WHITE}/>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.scrollCont}>
                        <ScrollView horizontal>
                            {IMAGES.map(renderBottomImages)}
                        </ScrollView>
                    </View>
                </View>
                <View style={styles.lblCont}>
                    <ScrollView>
                        <>
                            <Label large
                                   font_medium>
                                {packageData?.heading}
                            </Label>
                            <Label small
                                   color={Color.SECONDARY_TEXT_COLOR}
                                   mt={10}
                                   mb={10}>
                                {packageData?.description}
                            </Label>

                            {data?.map(renderService)}
                        </>
                    </ScrollView>
                </View>
            </View>
        </View>
    )
}

export default InspectionMarketingPackage
