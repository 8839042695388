export default {
  'component.managevendors.title': 'Administrer leverandører',
  'component.managevendors.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.managevendors.addmanagevendors': 'Legg til leverandører',
  'component.managevendors.addmanagevendors.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
  'component.managevendors.editmanagevendors': 'Rediger leverandører',
  'component.managevendors.service': 'Tjeneste',
  'component.managevendors.service.placeholder': 'Velg tjeneste',
  'component.managevendors.editmanagevendors.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
  'component.managevendors.assignedzipcodes': 'Tildelte postnumre',
  'component.managevendors.assignedzipcodes.placeholder': 'Velg postnummer',
  'component.managevendors.companyname': 'Selskapsnavn',
  'component.managevendors.companytype': 'Firma type',
  'component.managevendors.tablesearch':
    'Søk etter navn, e-post, telefon, eiendomsadresse og postnummer',
  'component.managevendors.modal.title': 'Suksessfult gjennomført !',
  'component.managevendors.modal.text': 'Leverandør lagt til Vellykket!',
  'component.managevendors.deletemodal.title': 'Slett leverandør',
  'component.managevendors.deletemodal.text':
    'Er du sikker på at du vil slette denne leverandøren?',
};
