import React from 'react'
import {Animated, View} from 'react-native'
import {Button} from "@ant-design/react-native/lib/button";
import Provider from "@ant-design/react-native/lib/provider";

import {connect} from "react-redux";
import {Header, IndicatorViewPager, Label, LogoForHeader, SquareButton} from "components/src/components";

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';

import styles from './styles.js';
import {Color, CommonStyle, IS_WEB, Strings} from "components/src/utils";
import FirstStep from "./FirstStep";
import SecondStreamStart from "./SecondStream/First";
import SecondStreamFirst from "./SecondStream/FirstQuestion";
import SecondStreamSecond from "./SecondStream/SecondQuestion";
import SecondStreamThird from "./SecondStream/ThirdQuestion";
import ThirdStreamStart from "./ThirdStream/First";
import ThirdStreamFirst from "./ThirdStream/FirstQuestion";
import ThirdStreamSecond from "./ThirdStream/SecondQuestion";
import FirstStreamStart from "./FirstStream/First";
import FirstQuestionFirst from "./FirstStream/FirstQuestion";
import FirstStreamSecond from "./FirstStream/SecondQuestion";
import FirstStreamThird from "./FirstStream/ThirdQuestion";
//Network
/*import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SeventhStep from "./SeventhStep";
import EighthStep from "./EighthStep";
import NinethStep from "./NinethStep";*/

// import {Button, InputItem} from "@ant-design/react-native";


// import { DatePickerView } from 'antd-mobile';
const STEPS = [
    `${Strings.ownerShip}/${Strings.housing}`,
    Strings.location,
    Strings.standard,
    `${Strings.sammie}/ ${Strings.skinAssociation} / ${Strings.veilag} etc`,
    Strings.rights,
    Strings.vvs,
    Strings.technicalInstallations,
    Strings.appliances,
    Strings.other
];


class SellerInterview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStep: 0,
            answers: {},
            index: 0,
            selectedQuestion: 1,
            firstStreamFirstStep: 1,
            firstStreamSecondStep: 1,
            thirdStreamFirstQuestion: 1,
            thirdStartQuestion: 1,
            secondStreamFirstQuestion: 1,
            secondStreamSecondQuestion: 1,
            firstStartQuestion: 1,
            secondStartQuestion: 1,
            firstStreamSecondQuestion: 1,
            firstStreamFirstQuestion: 1,
            pages: 2,
        };
        this.scrollY = new Animated.Value(0)
    }


    handleChangeText = (id, text) => {
        let answers = {...this.state.answers};
        answers[id] = text;
        this.setState({answers})
    };

    nextStep = (updateAnswer) => {
        this.pager._goToNextPage()

    };
    prevStep = () => {
        this.pager._goToPrevPage()

    };

    setCurrentChild = () => {
        this.setState({
            pages: this.pager.getChildrenCount()
        })
    };

    render() {
        let {thirdStreamFirstQuestion, thirdStartQuestion, secondStreamFirstQuestion, secondStreamSecondQuestion, firstStartQuestion, secondStartQuestion, firstStreamSecondQuestion, firstStreamFirstQuestion, selectedQuestion} = this.state;


        return (
            <Provider locale={enUS}>
                <Header tintColor={Color.WHITE}
                        renderTitle={() => <LogoForHeader/>}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>

                <View style={styles.container}>

                    <IndicatorViewPager ref={(ref) => this.pager = ref}
                                        onPageSelected={(e) => {
                                            this.setState({
                                                index: e.position
                                            })
                                        }}
                                        scrollEnabled={false}
                                        style={CommonStyle.flex}>
                        <View>
                            <View style={styles.topContainer}>
                                <FirstStep onSelectQuestion={(qNo) => {
                                    this.setState({
                                        selectedQuestion: qNo
                                    }, this.setCurrentChild)
                                }}/>
                            </View>
                        </View>

                        {selectedQuestion === 1 ? (<View>
                            <View style={styles.topContainer}>
                                <FirstStreamStart onAnswer={(qNo) => {
                                    this.setState({
                                        firstStartQuestion: qNo
                                    }, this.setCurrentChild)
                                }}/>

                            </View>
                        </View>) : null}

                        {/*-----After First question answer---*/}
                        {selectedQuestion === 1 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <FirstQuestionFirst fromStart={firstStartQuestion}
                                                        onAnswer={(qNo) => {
                                                            this.setState({
                                                                firstStreamFirstQuestion: qNo
                                                            }, this.setCurrentChild)
                                                        }}/>
                                </View>
                            </View>
                        ) : null}


                        {console.log(firstStreamFirstQuestion)}
                        {selectedQuestion === 1 && firstStreamFirstQuestion !== 3 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <FirstStreamSecond fromPrev={firstStreamFirstQuestion}
                                                       onAnswer={(qNo) => {
                                                           this.setState({
                                                               firstStreamSecondQuestion: qNo
                                                           }, this.setCurrentChild)
                                                       }}/>
                                </View>
                            </View>
                        ) : null}

                        {selectedQuestion === 1 &&
                        firstStreamFirstQuestion !== 3 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <FirstStreamThird fromPrev={firstStreamSecondQuestion}
                                                      onAnswer={(qNo) => {
                                                          this.setState({
                                                              firstStreamThirdQuestion: qNo
                                                          }, this.setCurrentChild)
                                                      }}/>
                                </View>
                            </View>
                        ) : null}


                        {/*----------Second Stream =============*/}
                        {selectedQuestion === 2 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <SecondStreamStart onAnswer={(qNo) => {
                                        this.setState({
                                            secondStartQuestion: qNo
                                        }, this.setCurrentChild)
                                    }}/>

                                </View>
                            </View>
                        ) : null}

                        {selectedQuestion === 2 && secondStartQuestion !== 0 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <SecondStreamFirst fromStart={secondStartQuestion}
                                                       onAnswer={(qNo) => {
                                                           this.setState({
                                                               secondStreamFirstQuestion: qNo
                                                           }, this.setCurrentChild)
                                                       }}/>
                                </View>
                            </View>

                        ) : null}

                        {selectedQuestion === 2 && secondStartQuestion !== 0 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <SecondStreamSecond fromPrev={secondStreamFirstQuestion}
                                                        onAnswer={(qNo) => {
                                                            this.setState({
                                                                secondStreamSecondQuestion: qNo
                                                            }, this.setCurrentChild)
                                                        }}/>
                                </View>
                            </View>
                        ) : null}

                        {selectedQuestion === 2 &&
                        secondStartQuestion !== 0 &&
                        secondStreamSecondQuestion === 3 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <SecondStreamThird fromPrev={secondStreamSecondQuestion}
                                                       onAnswer={(qNo) => {
                                                           this.setState({
                                                               secondStreamThirdQuestion: qNo
                                                           }, this.setCurrentChild)
                                                       }}/>
                                </View>
                            </View>
                        ) : null}

                        {/*=========Third Stream============*/}
                        {selectedQuestion === 3 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <ThirdStreamStart onAnswer={(qNo) => {
                                        this.setState({
                                            thirdStartQuestion: qNo
                                        }, this.setCurrentChild)
                                    }}/>

                                </View>
                            </View>
                        ) : null}


                        {selectedQuestion === 3 ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <ThirdStreamFirst fromStart={thirdStartQuestion}
                                                      onAnswer={(qNo) => {
                                                          this.setState({
                                                              thirdStreamFirstQuestion: qNo
                                                          }, this.setCurrentChild)
                                                      }}/>
                                </View>
                            </View>

                        ) : null}

                        {selectedQuestion === 3 && (thirdStartQuestion === 1 || thirdStartQuestion === 2) ? (
                            <View>
                                <View style={styles.topContainer}>
                                    <ThirdStreamSecond fromPrev={thirdStreamFirstQuestion}
                                                       onAnswer={(qNo) => {
                                                           this.setState({
                                                               thirdStreamSecondQuestion: qNo
                                                           }, this.setCurrentChild)
                                                       }}/>
                                </View>
                            </View>
                        ) : null}
                    </IndicatorViewPager>


                </View>

                <View style={styles.bottomNavCont}>
                    <SquareButton borderColor={Color.LIGHT_GRAY}
                                  onPress={this.prevStep}
                                  textColor={Color.LIGHT_GRAY}
                                  backgroundColor={Color.TRANSPARENT}>
                        {Strings.prev}
                    </SquareButton>
                    <Label small
                           font_medium
                           align={'center'}
                           style={CommonStyle.flex}
                           color={Color.WHITE}>

                    </Label>
                    <View  style={{
                        position:'absolute',
                        alignItems:"center",
                        start:60,
                        end:50
                    }}>
                    <SquareButton borderColor={Color.LIGHT_GRAY}
                                  textColor={Color.TERNARY_TEXT_COLOR}
                                  backgroundColor={Color.TRANSPARENT}

                                  onPress={() => {
                                      this.pager.setPage(0)
                                  }}>
                        {Strings.home}
                    </SquareButton>
                    </View>

                    <View style={CommonStyle.flex}/>

                    {this.state.index + 1 === this.state.pages ? (
                            <View style={{flex: 0.5}}/>
                        )
                        : (<SquareButton borderColor={Color.LIGHT_GRAY}
                                         onPress={() => this.nextStep()}
                                         textColor={Color.TERNARY_TEXT_COLOR}
                                         backgroundColor={Color.TRANSPARENT}>
                                {Strings.next}
                            </SquareButton>
                        )}


                </View>
            </Provider>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        property: state.property,
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        //
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(SellerInterview);
