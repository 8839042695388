export default {
  'component.about.title.line1': 'Hva er Webmegling?',
  'component.about.title.line2': 'Det unike med Webmegling',
  'component.about.title.line3': 'Fast lav pris med tilvalg',
  'component.about.title.line4': 'Eiendommer over hele landet',
  'component.about.description.line1':
    'Webmegling er din eiendomsmegler på nett. Vi er en fullverdig eiendomsmegler som gjør salgsforberedelsene, markedsføringen, budrunden og selvsagt kontrakt og oppgjør. Vi kombinerer markedsledende teknologi med erfarne meglere, og kan derfor gi deg et kvalitetsprodukt til en fast lav pris. Salgsprosessen foregår via vår digitale plattform hvor du har kontroll på alle ledd i salgsprosessen sammen med din Webmegling megler.',
  'component.about.description.line2':
    'Det unike med Webmegling er ikke vår digitale plattform og en fast lav pris. Det unike er at vi er et fullverdig eiendomsmeglerforetak, men også et teknologiselskap. Webmegling skal være ledende innen bruk og utvikling av automatiserte prosesser, maskinlæring, kunstig intelligens og annen teknologi for å øke kvaliteten på, og effektivisere, eiendomsmeglertjenestene.',
  'component.about.description.line3':
    'Når du bruker Webmegling kan du velge å selge med en fast lav pris på kroner 35.000,-. Men du har også mulighet til å utvide med ytterligere tjenester som mer markedsføring eller megler på visning. Vi tror på å gi deg en grunnpakke til en lav pris, men samtidig gi deg muligheten til å tilpasse tjenestene til ditt behov.',
  'component.about.description.line4':
    'Når du bruker Webmegling kan du velge å selge med en fast lav pris på kroner 35.000,-. Men du har også mulighet til å utvide med ytterligere tjenester som mer markedsføring eller megler på visning. Vi tror på å gi deg en grunnpakke til en lav pris, men samtidig gi deg muligheten til å tilpasse tjenestene til ditt behov.',
};
