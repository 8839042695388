import {StyleSheet} from 'react-native';
import {Color, IS_WEB, ThemeUtils} from 'components/src/utils';


export default StyleSheet.create({
    searchMainView: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginTop: ThemeUtils.relativeRealHeight(3),
    },
    textInput: {
        flexDirection:'row',
        alignItems:"center",
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        paddingVertical: ThemeUtils.relativeRealHeight(IS_WEB?1.5:0.5),
        borderWidth: 1,
        borderColor: Color.INPUT_BORDER,
        borderRadius: 5,
    },
    itemContainer: {
        width:"100%",
        maxHeight: ThemeUtils.relativeRealHeight(40),
        backgroundColor: Color.WHITE,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    searchItem: {
        paddingVertical: ThemeUtils.relativeRealHeight(1.5),
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        borderRadius: 5,
    }

});


