import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, View} from 'react-native';
import {Dashboard, Header, Hr, IndicatorViewPager, Label, PagerTabIndicator, Ripple} from "components/src/components";
//Third party
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import energy_labeling from "../../../../assets/images/MissionCost.jpg";
import photo from "../../../../assets/images/Photo.png";
import digitalMarketing from "../../../../assets/images/Digital_marketing.jpg";
import marketingMaterial from "../../../../assets/images/Print_Marketing.jpg";
import marketingPackage from "../../../../assets/images/MarketingPackage.jpg";
import otherService from "../../../../assets/images/Other_Services.jpg";


const DASHBOARD = [{
    name: `${Strings.photo} / ${Strings.styling}`,
    icon: 'photo',
    bg: photo,
    route: Routes.B2CPhotoStyling
}, {
    name: Strings.digitalMarketing,
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
    route: Routes.B2CDigitalMarketing
}, {
    name: Strings.printMarketing,
    icon: 'print-marketing',
    bg: marketingMaterial,
    route: Routes.B2CPrintMarketing
}, {
    name: Strings.variableUttleg,
    icon: 'variable-utlegg',
    bg: energy_labeling,
    route: Routes.B2CUttleggServices
},{
    name: Strings.marketingPackages,
    icon: 'marketing-package',
    bg: marketingPackage,
    route: Routes.MarketingPackages
}/*,{
    name: Strings.newOtherService,
    icon: 'b2b-and-b2c',
    bg: otherService,
    route: Routes.NewOtherServices
}*/];

export default function B2CService(props) {
    const {navigation, history} = props;

    const handleDashboardItemPress = route => {
        if (IS_WEB) {
            props.history.push(`/${Routes.B2CServices}/${route}`)
        } else {
            props.navigation.navigate(route)
        }
    };

    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}
            />

            <View style={styles.cont}>

                <Icon name={'b2b-and-b2c'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY_TEXT_COLOR}/>

                <Label xlarge
                       font_medium
                       ml={10}
                       mb={20}>
                    {Strings.B2CServices}
                </Label>
            </View>
            <Dashboard dashboardData={DASHBOARD}
                       onPressItem={handleDashboardItemPress}/>
        </View>
    )
}
