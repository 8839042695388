import React from 'react'
//Components
import {ScrollView,Keyboard, TouchableOpacity, View} from 'react-native'
import {CustomButton, InputField, Label, LogoForHeader} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Network
import {Mutation} from "@apollo/react-components";
import {LOGIN} from "components/src/api/mutation";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {
    Color,
    Constants,
    getUserType,
    IS_WEB,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    UserType,
    validation
} from "components/src/utils";
// import {Button, InputItem} from "@ant-design/react-native";
// import { DatePickerView } from 'antd-mobile';
import Cookie from 'js-cookie'
import {withFirebase} from "../../../HOC/Firebase";
/*let Cookie;
let id='js-cookie'
if(IS_WEB){`
    Cookie=require(`${id}`).default
}*/


const USER_TYPE = {
    ADMIN: 'Admin',
    SELLER: 'Seller',
    AGENT: 'Agent',
    PHOTO: 'Photographer',
    TAKST: 'Takst',
    STYLIST: 'Stylish',
    BOOK_INSPECTION: 'BookInspection',
    BUYER: 'Buyer',
    SUPERUSER: 'Superuser',
};

class SignIn extends React.Component {

    constructor(props) {
        super(props);

        if (IS_WEB) {
            this.userType = getUserType(props.location.pathname.toLowerCase().split('/')[1])
        } else {
            this.userType = UserType.Seller
        }

        this.state = {
            email: '',
            errEmail: null,

            password: '',
            errPassword: null
        }
    }


    getCurrentUserType = () => {
        const currentPath = this.props.location.pathname.split('/')[0];
        switch (currentPath) {
            case 'selger':
                return USER_TYPE.SELLER;
            case 'fotos':
                return USER_TYPE.PHOTO;
            case 'admin':
                return USER_TYPE.ADMIN;
            case 'undersøkelse':
                return USER_TYPE.BOOK_INSPECTION;
            case 'megler':
                return USER_TYPE.AGENT;
            case 'takst':
                return USER_TYPE.TAKST;
            case 'stylist':
                return USER_TYPE.STYLIST;
            case 'kjøper':
                return USER_TYPE.BUYER;
            case 'superbruker':
                return USER_TYPE.SUPERUSER;
            default:
                return USER_TYPE.SELLER;
        }
    };


    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.EMAIL:
                this.setState({
                    email: text,
                    errEmail: null
                });
                break;
            case Constants.FieldId.PASSWORD:
                this.setState({
                    password: text,
                    errPassword: null
                });
                break;
        }
    };

    doSignIn = (doSignIn) => {
        // this.props.start()
        const {email, password} = this.state;
        if(!IS_WEB){
            Keyboard.dismiss()
        }
        this.setState({
            errEmail: validation('email', email.trim()),
            errPassword: validation('passwordBlank', password),
        }, () => {
            if (!this.state.errEmail && !this.state.errPassword) {
                this.setState({
                    loading: true
                })
                doSignIn({
                    variables: {
                        input: {
                            email: email.trim(),
                            password: password,
                            user_type_id: 3, //Agent and super user are same only access level are change
                        },
                    },
                })
            }
        })
    };


    renderHeaderTitle = () => {
        return (
            <View style={styles.headerTitle}>
                <LogoForHeader size={ThemeUtils.responsiveScale(120)}/>
            </View>
        )
    };

    render() {

        return (
            <Provider locale={enUS}>
                <View style={styles.container}>

                    <ScrollView contentContainerStyle={{flexGrow: 1}}
                                keyboardShouldPersistTaps={'always'}>
                        <View style={styles.topContainer}>
                            <View>
                            {this.renderHeaderTitle()}
                                <Label xxlarge
                                       font_medium
                                       mb={20}
                                       mt={ThemeUtils.relativeRealHeight(2)}
                                       color={Color.WHITE}
                                       align={'center'}>
                                    {Strings.signIn}
                                </Label>

                            </View>
                            <View style={styles.fieldContainer}>
                                <InputField color={Color.WHITE}
                                            labelProps={{
                                                color: Color.WHITE
                                            }}
                                            style={{
                                                borderColor: Color.WHITE
                                            }}
                                            type="email-address"
                                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.EMAIL)}
                                            onFocus={() => this.setState({errEmail: null})}
                                            error={this.state.errEmail}
                                            value={this.state.email}
                                            labelText={Strings.email}
                                            placeholder={Strings.email}/>
                                <InputField color={Color.WHITE}
                                            labelProps={{
                                                color: Color.WHITE
                                            }}
                                            style={{
                                                borderColor: Color.WHITE
                                            }}
                                            type="password"
                                            onChange={(text) => this.handleChangeText(text, Constants.FieldId.PASSWORD)}
                                            onFocus={() => this.setState({errPassword: null})}
                                            error={this.state.errPassword}
                                            value={this.state.password}
                                            labelText={Strings.password}
                                            placeholder={Strings.password}/>
                            </View>
                            <View style={{alignItems: 'center'}}>

                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={() => {
                                                      if (IS_WEB) {
                                                          this.props.history.push(Routes.ForgotPassword)

                                                      } else {
                                                          this.props.navigation.navigate(Routes.ForgotPassword)
                                                      }
                                                  }}>
                                    <Label small
                                           color={Color.TERNARY_TEXT_COLOR}>
                                        {Strings.forgotYourPassword}
                                    </Label>
                                </TouchableOpacity>

                                <View style={styles.btnCont}>
                                    <Mutation mutation={LOGIN}
                                              onCompleted={(data) => {
                                                  if (data.login && data.login.firebase_token) {
                                                      this.props.auth().signInWithCustomToken(data.login.firebase_token).then(() => {
                                                          this.setState({
                                                              loading: false
                                                          })
                                                          this.props.setUser(data.login.user);
                                                          this.props.setToken(data.login.token);
                                                          if (IS_WEB) {
                                                              Cookie.set('token', data.login.token.access_token);
                                                              Cookie.set(`Agent_token`, data.login.token.access_token);

                                                              const userData = {
                                                                  user: data.login.user,
                                                                  userType: "Agent",
                                                              };
                                                              Cookie.set(`Agent_user`, userData);
                                                              console.log(this.props.history.location?.state?.route)
                                                              if (this.props.history.location?.state?.route) {
                                                                  this.props.history.replace(`/${this.props.history.location?.state?.route}`)
                                                              } else {
                                                                  this.props.history.push(Routes.Super_User_Dashboard)
                                                              }

                                                          } else {
                                                              this.props.messaging().subscribeToTopic(`W_CHAT_${data.login.user?.id}`)
                                                              const id = 'react-navigation'
                                                              let {NavigationActions, StackActions} = require(`${id}`);
                                                              const resetToAuth = StackActions.reset({
                                                                  index: 0,
                                                                  actions: [
                                                                      NavigationActions.navigate({routeName: Routes.Authenticated}),
                                                                  ],
                                                              });
                                                              this.props.navigation.dispatch(resetToAuth);
                                                          }

                                                      }).catch((e) => {
                                                          this.setState({
                                                              loading: false
                                                          })
                                                          console.log(e, "ERRR")
                                                          showMessage("Midlertidig server feil. Vennligst prøv igjen.", Constants.MessageType.FAILED)
                                                      })
                                                  }
                                              }}
                                              onError={(e) => {
                                                  console.log(e,"ER")
                                                  this.setState({
                                                      loading: false
                                                  })
                                              }}>
                                        {(doSignIn, {loading, error, data}) => (
                                            <CustomButton font_medium
                                                          onPress={() => this.doSignIn(doSignIn)}
                                                          loading={this.state.loading}
                                                          title={Strings.signIn}/>
                                        )}

                                    </Mutation>

                                </View>

                            </View>
                        </View>
                    </ScrollView>

                </View>
            </Provider>)
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(SignIn));
