import React, {Component} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import {Color, CommonStyle, Icon, IS_WEB, ThemeUtils} from 'components/src/utils';

import {Label} from "components/src/components";

export default class CheckBox extends Component {

    static propTypes = {
        size: PropTypes.number,
        defaultChecked: PropTypes.array,
        onCheckChanged: PropTypes.func.isRequired,
        checkBoxColor: PropTypes.string,
        options: PropTypes.array.isRequired
    };

    static defaultProps = {
        checkBoxColor: Color.SECONDARY,
        size: ThemeUtils.fontNormal,
        defaultChecked: []
    };


    constructor(props) {
        super(props);
        this.state = {
            checkList: props.defaultChecked,
        }
    }


    _renderImage(item) {
        return (
            <Icon name={this.state.checkList.includes(item) ? 'tickmark' : 'radio-normal'}
                  size={this.props.size}
                  style={styles.chk}
                  color={this.state.checkList.includes(item) ? this.props.checkBoxColor : Color.PRIMARY_LIGHT}/>
        );
    }


    handleCheckChange = (item) => {
        const updatedCheckList = this.state.checkList.slice();
        if (updatedCheckList.includes(item)) {
            updatedCheckList.splice(updatedCheckList.indexOf(item), 1)
        } else {
            updatedCheckList.push(item)
        }
        this.setState({
            checkList: updatedCheckList.slice()
        }, () => {
            this.props.onCheckChanged && this.props.onCheckChanged(this.state.checkList)
        })
    };

    render() {
        const {options} = this.props;
        return (
            <View style={[{
                alignSelf: 'stretch',
                opacity: this.props.disabled ? 0.5 : 1
            }]}>
                {options.map((item, index) => (
                        <TouchableOpacity activeOpacity={0.8}
                                          disabled={this.props.disabled}
                                          key={index.toString()}
                                          style={[this.props.style]}
                                          onPress={() => this.handleCheckChange(item.id ? item.id : item.value)}>
                            <View style={[styles.container, this.props.contentStyle]}>

                                {this._renderImage(item.id ? item.id : item.value)}

                                <Label small
                                       {...this.props.labelProps}
                                       style={[styles.rightText, this.props.rightTextStyle,IS_WEB?CommonStyle.flex:null]}>
                                    {item.name}
                                </Label>

                            </View>
                        </TouchableOpacity>
                    )
                )}
            </View>)

    };

}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',

    },
    leftText: {
        fontSize: 12,
    },
    rightText: {

        marginLeft: 10,
    }
});
