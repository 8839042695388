export default {
  'component.officecontroller.title': 'Fagansvarlig',
  'component.officecontroller.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officecontroller.addofficecontroller': 'Legg til fagansvarlig',
  'component.officecontroller.addofficecontroller.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officecontroller.editofficecontroller': 'Rediger fagansvarlig',
  'component.officecontroller.editofficecontroller.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.officecontroller.tablesearch': 'Søk etter navn, e-post, telefon og tittel',
  'component.officecontroller.deletemodal.title': 'Slett fagansvarlig',
  'component.officecontroller.deletemodal.text':
    'Er du sikker på at du vil slette denne fagansvarlig?',
};
