import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Clipboard, Image, TouchableOpacity, View} from 'react-native'
import {
    CustomButton,
    Header,
    Hr,
    InputDialog,
    InputField,
    Label,
    PagerTabIndicator,
    Ripple
} from "components/src/components";
//Utils
import styles from './styles';

import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PERSONAL_TEMPLATE_DETAILS} from "components/src/api/query";
import {
    DELETE_PERSONAL_SERVICE_MEDIA,
    PERSONAL_TEMPLATE_PHOTO_360_ATTACH_LINK,
    SAVE_PERSONAL_TEMPLATE_SERVICE
} from "components/src/api/mutation";

const scrollY = new Animated.Value(0);

function FullView(props) {

    const {history,user, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.PhotoStyling) : navigation.goBack();
    }
    const [service, setService] = useState([]);

    const [headLines, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);

    const [introText, setIntroText] = useState("");
    const [errIntroText, setErrIntro] = useState(null);

    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const [link, setLink] = useState('');
    const [errLink, setErrLink] = useState(null);


    const [servicePrice, setServicePrice] = useState([]);
    const [errServicePrice, setErrServicePrice] = useState({});

    const [uploadImagesVisible, setUploadVisible] = useState(false);

    const [saveServiceDetails, saveDetailsMutation] = useMutation(SAVE_PERSONAL_TEMPLATE_SERVICE);

    const [attachLink, attachLinkMutation] = useMutation(PERSONAL_TEMPLATE_PHOTO_360_ATTACH_LINK, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_PERSONAL_SERVICE_MEDIA, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const {loading, data, error} = useQuery(PERSONAL_TEMPLATE_DETAILS, {
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
            status: 1,
            agentId: user?.id
        }
    });

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);

    useEffect(() => {
        if (data?.personal_template_details) {
            console.log(data?.personal_template_details)
            if(data?.personal_template_details?.length){
                setLink(data?.personal_template_details[0]?.media_url)
            }

        }
    }, [data]);



    useEffect(() => {
        setErrLink(null)
    }, [link])

    const validateLink = () => {
        let error = validation('link', link)
        if (error) {
            setErrLink(error)
        } else {
            setErrLink(null)

            attachLink({
                variables: {
                    url: link,
                    templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                }
            }).then(()=>{

            }).catch(() => {

            })

        }
    };


    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };









    const renderPhotoGallery = () => {
        return (
            <>

                <Label small font_medium mt={10} mb={15}>
                    {Strings.attachViewLink}
                </Label>
                <View style={styles.inptCont}>
                    <View style={CommonStyle.flex}>
                        <InputField align={'center'}
                                    mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                    type={'text'}
                                    error={errLink}
                                    onFocus={props.onFocus}
                                    placeholder={props.placeholder}
                                    value={link}
                                    onChange={setLink}

                        />
                    </View>

                    <Ripple style={styles.IconsView}
                            rippleContainerBorderRadius={4}
                            onPress={() => Clipboard.setString(link)}>
                        <Icon name={'copy'}
                              size={ThemeUtils.fontXLarge}
                              color={Color.PRIMARY}/>
                    </Ripple>
                </View>
                <CustomButton loading={attachLinkMutation.loading}
                              title={Strings.attachViewLink}
                              onPress={() => validateLink()}
                />

                {/*<Ripple style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}
                        onPress={() => setUploadVisible(true)}>
                    {attachLinkMutation.loading ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (<Icon name={'add'}
                               size={ThemeUtils.responsiveScale(60)}
                               color={Color.PRIMARY_LIGHT}/>)}

                </Ripple>*/}

               {/* <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  deleteImages({
                                                      variables: {
                                                          ids: selectedImages,
                                                          templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                                      }
                                                  }).then(() => {
                                                      setSelectedImages([])
                                                  }).catch(() => {

                                                  })

                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : renderImageGrid(service ?? [])}*/}
            </>
        );
    };

    const renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                        onPress={() => selectionEnable ? addOrRemoveImage(item.id) : null}
                        activeOpacity={0.7}>

                        {selectionEnable && selectedImages.includes(item.id) ? (<View style={[styles.selectedImage]}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.TERNARY_TEXT_COLOR}/>
                        </View>) : null}

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}
                               style={styles.GridImage}/>
                    </TouchableOpacity>

                )
            })}
        </View>
    };
    return (
        <View style={styles.cont}>
            <Header animatedTitle={serviceFromPage?.name}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedValue={scrollY}
                    navigation={IS_WEB ? history : navigation}/>


            <View>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.flexGrow}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View>
                        <View style={styles.orderCont}>
                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(6)}
                                   mb={10}>
                                {/*{serviceFromPage?.office_template?.description}*/}
                            </Label>
                            {renderPhotoGallery()}
                        </View>
                    </View>
                </Animated.ScrollView>
            </View>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user:state.user
    }
};
export default connect(mapStateToProps)(FullView);
