export default {
  'component.settlementinformation.title': 'Oppgjørsinformasjon',
  'component.settlementinformation.description':
    'For at vi skal fullføre oppgjøret, trenger vi litt informasjon fra deg.',
  'component.settlementinformation.newaddress': 'Ny adresse',
  'component.settlementinformation.address': 'adresse',
  'component.settlementinformation.address.placeholder': 'Skriv inn adresse',
  'component.settlementinformation.loan': 'Låne',
  'component.settlementinformation.denomination': 'Valør Kr',
  'component.settlementinformation.denomination.placeholder': 'Angi valørbeløp',
  'component.settlementinformation.residualdebt': 'Restgjeld Kr',
  'component.settlementinformation.residualdebt.placeholder': 'Legg inn restgjeld Kr',
  'component.settlementinformation.reminder': 'Påminnelse om dato',
  'component.settlementinformation.reminder.placeholder': 'Velg påminnelse på dato',
  'component.settlementinformation.bank': 'Bank',
  'component.settlementinformation.contact': 'Kontakt',
  'component.settlementinformation.contact.placeholder': 'Skriv inn kontakt',
  'component.settlementinformation.phone': 'Telefon',
  'component.settlementinformation.phone.placeholder': 'Skriv inn telefon',
  'component.settlementinformation.email': 'E-post',
  'component.settlementinformation.email.placeholder': 'Skriv inn E-post',
  'component.settlementinformation.loanno': 'Lån Nr.',
  'component.settlementinformation.loanno.placeholder': 'Legg inn lån nr.',
  'component.settlementinformation.loanrepay': 'Bør lånet tilbakebetales?',
  'component.settlementinformation.loanrepay.yes': 'Ja',
  'component.settlementinformation.loanrepay.no': 'Nei',
  'component.settlementinformation.addmoreloans': 'Legg til flere lån',
  'component.settlementinformation.receivables': 'fordringer',
  'component.settlementinformation.accounntnoforreceivables':
    'Kontonr. For overføring av fordringer',
  'component.settlementinformation.accounntnoforreceivables.placeholder': 'Skriv inn kontonr.',
  'component.settlementinformation.additionalionalloan1': 'Tilleggslån 1',
  'component.settlementinformation.addmorereceivables': 'Legg til flere fordringer',
  'component.settlementinformation.additionalreceivables': 'Ytterligere fordringer',
  'component.settlementinformation.additionalloan': 'Ekstra lån',
  'component.settlementinformation.city': 'Poststed',
  'component.settlementinformation.bankname': 'Bank navn',
  'component.settlementinformation.bankname.placeholder': 'Skriv inn banknavn',
  'component.settlementinformation.percentage': 'Prosentdel',
  'component.settlementinformation.percentage.placeholder': 'Skriv inn prosentdel',
  'component.settlementinformation.postcode': 'Postnummer',
};
