import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, SafeAreaView, View} from 'react-native';
import {Header, Hr, Label, Modal, Ripple} from "components/src/components";
//Third party
//Network
import {useQuery} from "@apollo/react-hooks";
import {PROPERTTY_LIST_MOOVIN} from "components/src/api/query";
//Utils
import {Color, CommonStyle, encryptId, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import Search from "./Search";


export default function PropertyList(props) {
    const {navigation, history} = props;
    const scrollY = new Animated.Value(0)

    const [isSearchVisible, setSearchVisible] = useState(false)


    const {loading, error, data, fetchMore} = useQuery(PROPERTTY_LIST_MOOVIN, {
        fetchPolicy:'network-only',
        variables: {
            status: 1,
            first: 50,
            page: 1
        }
    });

    const navigate = (item) => {
        if (IS_WEB) {
            history.push(`${Routes.PropertyDashboard}/${item.street_adress} ${item.city} ${item.zipcode}/${encryptId(
                item.id,
            )}/${encryptId(item.oppdrags_nummer)}`, {
                propertyData: item
            })
        } else {
            navigation.navigate(Routes.PropertyDashboard, {
                propertyData: item
            })
        }
    };

    const renderPropertyItem = ({item, index}) => {
        return (
            <>
                <Hr/>
                <Ripple style={styles.ProCont}
                        onPress={() => navigate(item)}>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View style={styles.content}>
                            <Label small
                                   font_medium
                                   numberOfLines={3}>
                                {`${item.street_adress} ${item.city} ${item.zipcode}`}
                            </Label>
                            {item.oppdrags_nummer ?
                                (<Label small
                                        mt={10}
                                        font_regular
                                        color={Color.SECONDARY_TEXT_COLOR}
                                        numberOfLines={2}>
                                    {`${Strings.oppDragNo} ${item.oppdrags_nummer}`}
                                </Label>) : null}
                        </View>
                        <View style={{alignSelf: 'center'}}>
                            <Icon name={'drop-normal'} color={Color.SECONDARY_TEXT_COLOR}/>
                        </View>
                    </View>


                </Ripple>
            </>
        )
    };

    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Search onClose={() => setSearchVisible(false)}
                                renderItem={renderPropertyItem}/>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.properties}
                    navigation={IS_WEB ? history : navigation}
                    onPressRightFirst={() => setSearchVisible(true)}
                    headerRightFirst={'search'}
                    animatedValue={scrollY}/>
            {renderSearchModal()}
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <View style={styles.cont}>

                        <Icon name={'ds-property'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.PRIMARY}/>

                    </View>

                    <View style={{marginTop: 20}}>
                        {loading && !data ?
                            (
                                <ActivityIndicator color={Color.PRIMARY}/>
                            ) : (
                                <FlatList data={data?.moovin_properties?.data ?? []}
                                          renderItem={renderPropertyItem}
                                          onEndReached={(info) => {
                                              if (data?.moovin_properties?.paginatorInfo?.hasMorePages && !loading) {
                                                  fetchMore({
                                                      variables: {
                                                          page: (data?.moovin_properties?.paginatorInfo?.currentPage ?? 0) + 1,
                                                      },
                                                      updateQuery: (prev, {fetchMoreResult}) => {
                                                          if (!fetchMoreResult) {
                                                              return prev;
                                                          } else {
                                                              let finalResult = Object.assign({}, fetchMoreResult);
                                                              finalResult.moovin_properties.data = [...prev.moovin_properties.data, ...fetchMoreResult.moovin_properties.data];
                                                              return finalResult;
                                                          }
                                                      },
                                                  });
                                              }
                                          }}
                                          onEndReachedThreshold={0.2}
                                          keyExtractor={item => item.id.toString()}/>
                            )}

                    </View>
                </View>
            </Animated.ScrollView>
        </View>

    )
}
