import React from 'react'
import {Animated, View} from 'react-native'
import PropTypes from 'prop-types'
import styles from './styles'
import {Label} from "components/src/components";
import {Color, DateUtils, IS_WEB, Strings} from "components/src/utils";
import moment from "moment";


export default function ChatBubble(props) {
    const {children, message, section, left} = props;
    let animatedValue = new Animated.Value(0)
    const {
        content,
        messageType,
        receiverId,
        senderId,
        timestamp
    } = message

    let diff = moment(moment(timestamp).format(DateUtils.dd_mm_yyyy), DateUtils.dd_mm_yyyy).diff(moment(), "d")
    let isToday = diff === 0
    let isYesterday = diff === 1 || diff === -1

    return (
        <View>
            {section ? (
                <Label align={'center'}
                       mt={10}
                       mb={10}
                       small
                       color={Color.PRIMARY_LIGHT}>
                    {isToday ? Strings.today : isYesterday ? Strings.yesterday : moment(timestamp).format(DateUtils.MMM_dd_mm_yyyy)}
                </Label>
            ) : null}
            {left ? (
                <View style={styles.bubbleContLeft}>

                    <View style={styles.lblContLeft}>
                        {messageType === "TEXT" ? (
                            <Label small
                                   mt={IS_WEB?3:0}
                                   color={Color.WHITE}>
                                {content}
                            </Label>) : null}
                    </View>
                    <Label xsmall
                           mt={5}
                           color={Color.PRIMARY_LIGHT}>
                        {moment(timestamp).format(DateUtils.hh_mm_a)}
                    </Label>
                </View>
            ) : (
                <View style={styles.bubbleContRight}>
                    <View style={styles.lblContRight}>
                        {messageType === "TEXT" ? (
                            <Label small
                                   mt={IS_WEB?3:0}
                                   color={Color.WHITE}>
                                {content.trim()}
                            </Label>) : null}
                    </View>
                    <Label xsmall
                           mt={5}
                           align={'right'}
                           color={Color.PRIMARY_LIGHT}>
                        {moment(timestamp).format(DateUtils.hh_mm_a)}
                    </Label>
                </View>)}
        </View>
    )
}
ChatBubble.propTypes = {
    message: PropTypes.object,
    left: PropTypes.boolean,

}
