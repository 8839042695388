import {StyleSheet} from 'react-native';

const containerHorizontalMargin = 30;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
});

export {styles};
