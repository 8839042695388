import React, {useEffect, useState} from 'react'
import {
    CustomButton,
    DatePicker,
    Header,
    Hr,
    Label,
    Modal,
    PopOver,
    RadioGroup,
    Ripple,
    Toast
} from "components/src/components";
import {ActivityIndicator, Animated, SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
import styles from './styles';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CALENDAR_TIMELINE_QUERY, PROPERTY_ORDER_DETAILS, SELLER_STYLING_TYPES} from "../../../../../../../api/query";
import {connect} from "react-redux";
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle, Constants,
    DateUtils,
    decryptId,
    Icon,
    MessageUtils, showMessage,
    Strings,
    ThemeUtils
} from "../../../../../../../utils";
import moment from "moment";
import {BOOK_CALENDAR, DELETE_CALENDAR_BOOK_DATE} from "../../../../../../../api/mutation";
import {Query} from "@apollo/react-components";


function Calendar(props) {
    const {inspection} = props;
    let scrollY = new Animated.Value(0)
    let toast;
    const [timelineId, setTimelineId] = useState();
    const [isOrderVisible, setOrderVisible] = useState(false);


    const [bookDateVisible, setBookDateVisible] = useState(false);
    const [bookItem, setBookItem] = useState();

    const [orderDate, setOrderDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const [selectedFashion, setSelectedFashion] = useState();

    const [desc, setDesc] = useState();
    const [fromView, setFromView] = useState();
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    const propertyId = props?.match?.params?.id ? decryptId(props.match.params.id) : inspection?.property_id

    const [bookDate, bookDateMutation] = useMutation(BOOK_CALENDAR, {
        refetchQueries: [{
            query: CALENDAR_TIMELINE_QUERY,
            variables: {
                status: 1,
                propertyId: propertyId
            }
        }]
    });

    const [deleteCal, deleteCalMutation] = useMutation(DELETE_CALENDAR_BOOK_DATE, {
        refetchQueries: [{
            query: CALENDAR_TIMELINE_QUERY,
            variables: {
                status: 1,
                propertyId: propertyId
            }
        }]
    });

    const {loading, data, error} = useQuery(CALENDAR_TIMELINE_QUERY, {
        variables: {
            status: 1,
            propertyId: propertyId
        }
    });

    const orderQuery = useQuery(PROPERTY_ORDER_DETAILS, {
        variables: {
            id: parseInt(propertyId)
        }
    });


    const getOrderDetails = () => {
        switch (bookItem?.service_type) {
            case 4:
                return orderQuery?.data?.property?.order_photo
            case 5:
                return orderQuery?.data?.property?.orderrate
            case 6:
                return orderQuery?.data?.property?.orderstyling
        }
    }
    useEffect(() => {
        if (bookItem) {
            let orderDetails = getOrderDetails()
            setOrderDate(bookItem?.completed_date ? new Date(moment(bookItem?.completed_date, DateUtils.yyyy_mm_dd)) : undefined)
            setStartTime(orderDetails?.earliest_start_time ? new Date(moment(orderDetails?.earliest_start_time, DateUtils.hh_mm_ss)) : undefined)
            setEndTime(orderDetails?.latest_start_time ? new Date(moment(orderDetails?.latest_start_time, DateUtils.hh_mm_ss)) : undefined)
            setSelectedFashion(orderDetails?.styling_type_id)
            // setOrderVisible(!!orderDetails)
            setOrderVisible(false)
        }
    }, [bookItem])


    const addDate = () => {

        if (orderDate) {
            if (isOrderVisible) {
                if (startTime || !bookItem?.service_type) {
                    if (endTime || !bookItem?.service_type) {
                        if (selectedFashion || bookItem?.service_type !== 6) {
                            if (moment(endTime).diff(moment(startTime), 'h') >= 1 || !bookItem?.service_type) {
                                let variables = {
                                    propertyId: parseInt(propertyId),
                                    timelineId: bookItem.timeline_id,
                                    date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                                };
                                if (bookItem?.service_type) {
                                    variables = {
                                        ...variables,
                                        ...{
                                            book_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                            book_end_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                        }
                                    };
                                    if (bookItem?.service_type === 6) {
                                        variables = {
                                            ...variables,
                                            styling_type_id: selectedFashion
                                        }
                                    }
                                }
                                bookDate({
                                    variables: variables
                                }).then((data) => {
                                    showMessage(data?.data?.calendar_book_date?.meta?.message, Constants.MessageType.SUCCESS)
                                    setBookDateVisible(false)
                                }).catch(() => {

                                })
                            } else {
                                showMessage(MessageUtils.Errors.errTimeGapOneHr, Constants.MessageType.FAILED)
                            }
                        } else {
                            showMessage(MessageUtils.Errors.fashion, Constants.MessageType.FAILED)
                        }
                    } else {
                        showMessage(MessageUtils.Errors.endTime, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.startTime, Constants.MessageType.FAILED)
                }
            } else {
                bookDate({
                    variables: {
                        propertyId: parseInt(propertyId),
                        timelineId: bookItem.timeline_id,
                        date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                    }
                }).then((data) => {
                    showMessage(data?.data?.calendar_book_date?.meta?.message, Constants.MessageType.FAILED)
                    setBookDateVisible(false)
                }).catch(() => {

                })
            }
        } else {

            showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
        }
    };

    const addIcon = (title) => {
        let touchable;
        return (
            <View>
                <TouchableOpacity activeOpacity={0.5}
                                  ref={(ref) => touchable = ref}
                                  onPress={() => {
                                      setIsPopUpVisible(true)
                                      setFromView(touchable)
                                      setDesc(title)
                                  }}>
                    <Icon name={'faq'}/>
                </TouchableOpacity>
            </View>
        )
    }

    const renderBookDateModal = () => {
        return (
            <Provider locale={enUS}
                      style={{zIndex: 100}}>
                <Modal visible={bookDateVisible}
                       animated
                       hardwareAccelerated
                       onRequestClose={() => setBookDateVisible(false)}>
                    <Toast ref={r => toast = r}/>
                    <PopOver isVisible={isPopUpVisible}
                             fromView={fromView}
                             placement={'top'}
                             onRequestClose={() => setIsPopUpVisible(false)}>
                        <View style={{paddingHorizontal: 10}}>

                            <Label small mb={10} mt={10}>{desc}</Label>
                        </View>

                    </PopOver>

                    <SafeAreaView style={CommonStyle.safeArea}>
                        <View style={CommonStyle.container}>
                            <Header onPressLeft={() => setBookDateVisible(false)}
                                    animatedValue={scrollY}
                                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                    animatedTitle={bookItem?.timeline?.name}/>
                            <Animated.ScrollView
                                scrollEventThrottle={1}
                                onScroll={Animated.event(
                                    [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                    {useNativeDriver: true}
                                )}>
                                <View style={styles.modalCont}>
                                    <DatePicker min={new Date(moment().add(1, 'd'))}
                                                title={Strings.date}
                                                selectedDate={orderDate}
                                                onSelectDate={(date) => setOrderDate(date)}
                                                placeholder={Strings.selectDate}/>

                                    {/*<TouchableOpacity activeOpacity={0.5}
                                                  onPress={() => setOrderVisible(prevVal => !prevVal)}
                                                  style={styles.orderConfirm}>

                                    <View style={CommonStyle.flex}>
                                        <Hr/>
                                    </View>
                                    <Icon name={isOrderVisible ? 'tickmark' : 'radio-normal'}
                                          size={ThemeUtils.fontNormal}
                                          style={[styles.chk]}
                                          color={isOrderVisible ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>

                                </TouchableOpacity>*/}
                                    {/*<CheckBox onCheckChanged={(val) => setOrderVisible(!!val[0])}

                                          style={{marginTop:ThemeUtils.relativeRealHeight(2)}}
                                          options={[{
                                              name: "Book tid nå",
                                              value: true
                                          }]}/>*/}
                                    {bookItem?.service_type ? (


                                        <TouchableOpacity activeOpacity={0.5}
                                                          onPress={() => setOrderVisible(prevVal => !prevVal)}
                                                          style={styles.orderConfirm}>
                                            <Icon name={isOrderVisible ? 'tickmark' : 'radio-normal'}
                                                  size={ThemeUtils.fontNormal}
                                                  color={isOrderVisible ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                                            <Label small
                                                   ml={10}>
                                                {"Book tid nå"}
                                            </Label>
                                        </TouchableOpacity>) : null}
                                    {bookItem?.service_type && isOrderVisible ? (
                                        <>
                                            <DatePicker onSelectDate={(date) => {
                                                setStartTime(date)
                                                setEndTime(checkDiff(date,1) ? add1Day() : add4Hours(date,1))
                                            }}
                                                        title={Strings.startTimeCalendar}
                                                        minTime={new Date(moment().add(1, 'd').set({
                                                            hours: 0,
                                                            minutes: 0,
                                                            seconds: 0
                                                        }))}
                                                        selectedDate={startTime}
                                                        maxTime={add1Day()}
                                                        placeholder={Strings.selectTime}
                                                        round={true}
                                                        mode={'time'}/>
                                            <DatePicker onSelectDate={(date) => setEndTime(endTime)}
                                                        selectedDate={startTime && endTime ? checkDiff(startTime,1) ? add1Day() : add4Hours(startTime,1) : endTime ? new Date(moment(endTime)) : null}
                                                        minTime={checkDiff(startTime,1) ? add1Day() : add4Hours(startTime,1)}
                                                        maxTime={add1Day()}
                                                        title={Strings.endTimeCalendar}
                                                        style={{marginTop: 20}}
                                                        placeholder={Strings.selectTime}
                                                        mode={'time'}/>
                                        </>
                                    ) : null}

                                    {bookItem?.service_type === 6 && isOrderVisible ? (
                                        <View>
                                            <Query query={SELLER_STYLING_TYPES}
                                                   fetchPolicy={'cache-and-network'}
                                                   onCompleted={(data) => data?.styling_types.length && setSelectedFashion(data?.styling_types[0]?.id)
                                                   }>
                                                {({data, error, loading}) => {
                                                    if (loading && !data) {
                                                        return <ActivityIndicator color={Color.PRIMARY}
                                                                                  style={CommonStyle.loader}/>
                                                    }
                                                    const options = data?.styling_types.map(item => ({
                                                        name: item.name,
                                                        icon: addIcon(item.name),
                                                        value: item.id
                                                    }));
                                                    return (<RadioGroup options={options}
                                                                        style={styles.energyradio}
                                                                        contentStyle={styles.energyradio}
                                                                        small
                                                                        selectedOptions={selectedFashion ?? 1}
                                                                        font_regular
                                                                        onSelectOption={setSelectedFashion}/>)
                                                }}
                                            </Query>
                                        </View>
                                    ) : null}

                                    <View style={styles.btnCont}>


                                        <CustomButton title={Strings.okay}
                                                      loading={bookDateMutation.loading}
                                                      mr={ThemeUtils.relativeRealWidth(1)}
                                                      onPress={addDate}
                                                      style={{flex: 1}}/>
                                        <CustomButton title={Strings.cancel}
                                                      onPress={() => {
                                                          setBookDateVisible(false)
                                                      }}
                                                      bgColor={Color.WHITE}
                                                      style={{flex: 1}}
                                                      ml={ThemeUtils.relativeRealWidth(1)}

                                                      textColor={Color.PRIMARY_TEXT_COLOR}
                                                      borderColor={Color.PRIMARY_TEXT_COLOR}
                                                      borderWidth={1}/>
                                    </View>
                                </View>
                            </Animated.ScrollView>
                        </View>
                    </SafeAreaView>
                </Modal>
            </Provider>
        )
    }

    const renderDatePicker = (item, index) => {
        return (
            <>
                <Ripple onPress={() => {
                    setBookDateVisible(true)
                    setBookItem(item)
                }}
                        style={styles.dateCont}>

                    <View style={styles.datePickerCont}>
                        <Label small
                               font_medium>
                            {item?.timeline.name}
                        </Label>
                        <Label small>
                            {item?.completed_date ? moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy) : 'Ingen data'}
                        </Label>
                    </View>
                    {item?.completed_date ? (
                        <View>
                            {deleteCalMutation?.loading && item?.timeline_id === timelineId ? (
                                <ActivityIndicator color={Color.PRIMARY}/>
                            ) : (
                                <Ripple onPress={() => {
                                    setTimelineId(item.timeline_id)
                                    deleteCal({
                                        variables: {
                                            propertyId: propertyId,
                                            id: item?.timeline_id
                                        }
                                    }).then(() => {

                                    }).catch(() => {

                                    })
                                }}>
                                    <Icon name={'delete'}
                                          color={Color.PRIMARY}
                                          size={ThemeUtils.fontLarge}/>
                                </Ripple>)}
                        </View>) : null}
                </Ripple>
                <Hr/>
            </>
        )
    }

    return (

        <View style={[styles.container]}>
            {renderBookDateModal()}
            <ScrollView>
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : data?.inspection_timeline_list?.sort((a, b) => a?.timeline?.priority - b?.timeline?.priority)?.map(renderDatePicker)}
            </ScrollView>

        </View>

    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(Calendar)
