import React, {useState} from 'react'
//Components
import {ActivityIndicator, Animated, SafeAreaView, TouchableOpacity, View} from 'react-native'
import {
    Attachment,
    CheckBox,
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    InputField,
    Label,
    Modal
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    Icon,
    IS_WEB,
    MessageUtils,
    openInNewTab,
    showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {styles} from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {DOC_LIST} from "../../../../../../api/query";
import {DELETE_DOC, UPLOAD_DOC} from "../../../../../../api/mutation";


// import {Button, InputItem} from "@ant-design/react-native";
// import { PickerView } from '@ant-design/react-native';
// import { DatePickerView } from 'antd-mobile';

export default function Documents(props) {
    const {history, navigation} = props
    const scrollY = new Animated.Value(0)
    const scrollYModal = new Animated.Value(0)
    const propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const [visible, setDeletDialogVisible] = useState(false)
    const [uploadDocVisible, setUploadDocVisible] = useState(false)
    const [docForDelete, setDocForDelete] = useState(null)
    const [docTitle, setDocTitle] = useState("")
    const [errDocTitle, setErrDocTitle] = useState(null)
    const [file, setFile] = useState([])
    const [docFor, setDocFor] = useState([])
    const {loading, data, error} = useQuery(DOC_LIST, {
        variables: {
            propertyId: propertyId,
            status: 1
        },
    });

    const [removeDoc, removeDocMutation] = useMutation(DELETE_DOC, {
        onCompleted:(data)=>{
          showMessage(data?.delete_property_document?.meta?.message,Constants.MessageType.SUCCESS)
        },
        refetchQueries: [{
            query: DOC_LIST,
            variables: {
                propertyId: propertyId,
                status: 1
            }
        }]
    })

    const [uploadDoc, uploadDocMutation] = useMutation(UPLOAD_DOC, {
        refetchQueries: [{
            query: DOC_LIST,
            variables: {
                propertyId: propertyId,
                status: 1
            }
        }]
    })


    const validate = () => {
        let errTitle = validation('docTitle', docTitle)

        if (errTitle) {
            setErrDocTitle(errTitle)
        } else {
            if (file.length) {
                if (docFor.length) {
                    uploadDoc({
                        variables: {
                            file: file,
                            title: docTitle,
                            propertyId: propertyId,
                            docFor: docFor
                        }
                    }).then(() => {
                        setUploadDocVisible(false)
                    }).catch(() => {

                    })
                } else {
                    showMessage(MessageUtils.Errors.docForRequired, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.docRequired, Constants.MessageType.FAILED)
            }
        }


    };

    const deleteDoc = () => {
        removeDoc({
            variables: {
                id: docForDelete?.id
            }
        }).then(() => {
            setDeletDialogVisible(false)
        }).catch(() => {

        })
    }

    const list = () => {
        if (loading && !data) {
            return <ActivityIndicator color={Color.PRIMARY}/>
        }
        return data?.property_documents?.map((item, index) => {
            return (
                <TouchableOpacity activeOpacity={0.6}
                                  key={index.toString()}
                                  onPress={() => {
                                      openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item?.doc_url}`)
                                  }}>
                    {index === 0 ? (<Hr/>) : null}
                    <View style={styles.topContainer}>
                        <View style={styles.documentcontainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'marketing-material-print'}
                                color={Color.PRIMARY_DARK}/>
                            <View style={CommonStyle.flex}>
                                <Label small
                                       ml={ThemeUtils.relativeRealWidth(2)}

                                       font_medium
                                       align={'left'}>{item.title}
                                </Label>
                                <Label small
                                       ml={ThemeUtils.relativeRealWidth(2)}
                                       style={CommonStyle.flex}
                                       align={'left'}>
                                    {item.is_buyer_seller_doc === Constants.UserType.SELLER ? Strings.uploadedForSeller : Strings.uploadedForBuyer}
                                </Label>
                            </View>
                            <TouchableOpacity style={styles.iconView}
                                              onPress={() => {
                                                  setDocForDelete(item)
                                                  setDeletDialogVisible(true)
                                              }}>
                                <Icon
                                    size={ThemeUtils.fontLarge}
                                    name={'delete'}
                                    color={Color.RED}/>

                            </TouchableOpacity>
                        </View>

                    </View>
                    <Hr/>


                </TouchableOpacity>
            );
        })
    };

    const getTitle = () => {
        return (<>
                <Icon name={'upload'}
                      size={ThemeUtils.fontSmall}/>
                {` ${Strings.uploadDoc}`}
            </>
        )
    }

    const renderUploadDocModal = () => {
        return (
            <Modal visible={uploadDocVisible}
                   onRequestClose={() => setUploadDocVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setUploadDocVisible(false)}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                animatedTitle={Strings.documents}
                                animatedValue={scrollYModal}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             contentContainerStyle={{flexGrow: 1}}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollYModal}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.modalCont}>
                                <InputField type={'text'}
                                            error={errDocTitle}
                                            mt={ThemeUtils.relativeRealHeight(6)}
                                            placeholder={Strings.docTitle}
                                            labelProps={{
                                                small: true,
                                                font_medium: true
                                            }}
                                            labelText={Strings.docTitle}
                                            onChange={setDocTitle}
                                            onFocus={() => setErrDocTitle(null)}
                                            value={docTitle}/>
                                <Label small
                                       font_medium
                                       mb={10}>
                                    {Strings.uploadDoc}
                                </Label>
                                <FilePicker type={FileType.PDF}
                                            onFilePicked={(data, file) => setFile(file)}/>
                                {file.map(item => {
                                    return (
                                        <Attachment name={item.name}
                                                    style={styles.attachment}
                                                    onPressDelete={() => setFile([])}
                                                    isDeletable/>
                                    )
                                })}
                                <Label small
                                       font_medium
                                       mt={20}
                                       mb={5}>
                                    {Strings.thisDocIsFor}
                                </Label>
                                <CheckBox onCheckChanged={setDocFor}
                                          style={styles.chk}
                                          options={[{
                                              name: Strings.seller,
                                              value: "SELLER"
                                          }, {
                                              name: Strings.buyer,
                                              value: "BUYER"
                                          }]}/>
                            </View>
                        </Animated.ScrollView>
                        <View style={styles.btnCont}>
                            <CustomButton title={Strings.save}
                                          loading={uploadDocMutation.loading}
                                          onPress={() => {
                                              validate()
                                          }}
                                          mr={5}
                                          style={{flex: 1}}/>
                            <CustomButton onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}
                                          title={Strings.cancel}
                                          bgColor={Color.WHITE}
                                          textColor={Color.PRIMARY}
                                          borderColor={Color.PRIMARY}
                                          borderWidth={1}
                                          ml={5}
                                          style={{flex: 1}}/>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>)
    }

    return (
        <Provider locale={enUS}>
            <Header animatedTitle={Strings.documents}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            {renderUploadDocModal()}
            <Dialog visible={visible}
                    loading={removeDocMutation.loading}
                    color={Color.RED}
                    desc={Strings.deleteConfirmation}
                    buttonTitleFirst={Strings.cancel}
                    buttonTitleSecond={Strings.okay}
                    onPositivePress={() => deleteDoc()}
                    onNegativePress={() => setDeletDialogVisible(false)}
                    title={Strings.deleteDoc}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.labeliconContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'documents'}
                                color={Color.PRIMARY_DARK}/>

                            {/*<Label xlarge
                                       ml={9}
                                       font_medium
                                       align={'left'}>
                                    {Strings.documenttitle}
                                    </Label>*/}
                        </View>

                        <Label
                            mt={10}
                            mb={20}
                            font_regular
                            small
                            align={'left'}>
                            {"Last opp dokumenter til kjøper eller selger her. Klikk på last opp dokument og angi et dokument navn og hvem dokumentet skal sendes til."}
                        </Label>


                    </View>
                    <View>{list()}</View>

                </View>
            </Animated.ScrollView>
            <CustomButton title={getTitle()}
                          onPress={() => setUploadDocVisible(true)}
                          mb={ThemeUtils.isTablet() ? 40 : 0}
                          style={!ThemeUtils.isTablet() ? {
                              height: 50,
                              borderRadius: 0
                          } : null}
                          textColor={Color.PRIMARY_TEXT_COLOR}
            />
        </Provider>
    );
}

