import React from 'react'
import {ActivityIndicator, Animated, FlatList, TouchableOpacity, View} from 'react-native'

import {withFirebase} from "components/src/HOC/Firebase";
import {connect} from "react-redux";
import moment from 'moment'

import {
    Color,
    CommonStyle,
    encryptId,
    getDateTimeForNotification,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
    NOTIFICATION_TYPE,
} from "components/src/utils";
import {Header, Hr, IndicatorViewPager, Label, PagerTabIndicator} from "components/src/components";
import styles from './styles'
import 'moment/locale/nb'

moment.locale('nb');


const getRouteForNotification = item => {
    switch (item.notificationType) {
        case NOTIFICATION_TYPE.ADMIN_ADDED_FEGLING:
            return `/${Routes.Professional}/${Routes.ProfessionalDetails}/${encryptId(item.referenceId)}`;
        case NOTIFICATION_TYPE.ADMIN_ADDED_NEWS:
            return `/${Routes.News}/${Routes.NewsDetails}/${encryptId(item.referenceId)}`;
        case NOTIFICATION_TYPE.SELLER_PHOTO_ORDER:
        case NOTIFICATION_TYPE.SELLER_PHOTO_COMMENT:
        case NOTIFICATION_TYPE.AGENT_PHOTO_DELIVER:
            return `/${Routes.Photo}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.SELLER_RATE_ORDER:
        case NOTIFICATION_TYPE.RATE_DETAILS_ADDED:
        case NOTIFICATION_TYPE.SELLER_RATE_DETAILS_COMMENT:
        case NOTIFICATION_TYPE.AGENT_TAKST_DELIVER:
            return `/${Routes.Rate}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.SELLER_STYLING_ORDER:
            return `/${Routes.Styling}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.SELLER_ENERGY_LABELING:
        case NOTIFICATION_TYPE.SELLER_INTERVIEW:
        case NOTIFICATION_TYPE.SELLER_SUBMIT_SETTLEMENT_INFO:
            return `/${Routes.InfoFromSeller}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.SELLER_APPROVED_FINN_NO:
        case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_FINN_NO:
        case NOTIFICATION_TYPE.SELLER_APPROVED_DS:
        case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_DS:
        case NOTIFICATION_TYPE.SELLER_APPROVED_MARKETING_MATERIAL:
        case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_MARKETING_MATERIAL:
            return `/${Routes.Marketing}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.SELLER_ADDED_VIEW:
            return `/${Routes.Views}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.BUYER_REGISTER:
            return `/${Routes.Buyer}/${encryptId(item.propertyId)}`;
        case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_SELLER:
        case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_BUYER:
            return `/${Routes.Document}/${encryptId(item.propertyId)}`;
        default:
            return "#";
    }
};


class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.database = props.database().ref('v1');
        this.state = {
            notifications: [],
            messages: [],
            generalNotificationCount: 0,
            chatNotificationCount: 0,
            generalNotificationLoading: true,
            chatNotificationLoading: true,
            selectedPage: 0
        }
    }


    getRouteForNotificationMobile = item => {
        switch (item.notificationType) {
            case NOTIFICATION_TYPE.ADMIN_ADDED_FEGLING:
                this.props.navigation.navigate(Routes.ProfessionalDetails, {
                    faglig: {
                        id: parseInt(item.referenceId, 10)
                    }
                });
                break;
            case NOTIFICATION_TYPE.ADMIN_ADDED_NEWS:
                this.props.navigation.navigate(Routes.NewsDetails, {
                    news: {
                        id: parseInt(item.referenceId, 10)
                    }
                });
                break;
            case NOTIFICATION_TYPE.SELLER_PHOTO_ORDER:
            case NOTIFICATION_TYPE.SELLER_PHOTO_COMMENT:
            case NOTIFICATION_TYPE.AGENT_PHOTO_DELIVER:
                this.prop.navigation.navigate(Routes.Photo, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.SELLER_RATE_ORDER:
            case NOTIFICATION_TYPE.RATE_DETAILS_ADDED:
            case NOTIFICATION_TYPE.SELLER_RATE_DETAILS_COMMENT:
            case NOTIFICATION_TYPE.AGENT_TAKST_DELIVER:

                this.prop.navigation.navigate(Routes.Rate, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.SELLER_STYLING_ORDER:
                this.prop.navigation.navigate(Routes.Styling, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.SELLER_ENERGY_LABELING:
            case NOTIFICATION_TYPE.SELLER_INTERVIEW:
            case NOTIFICATION_TYPE.SELLER_SUBMIT_SETTLEMENT_INFO:
                this.prop.navigation.navigate(Routes.InfoFromSeller, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.SELLER_APPROVED_FINN_NO:
            case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_FINN_NO:
            case NOTIFICATION_TYPE.SELLER_APPROVED_DS:
            case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_DS:
            case NOTIFICATION_TYPE.SELLER_APPROVED_MARKETING_MATERIAL:
            case NOTIFICATION_TYPE.SELLER_ADDED_COMMENT_MARKETING_MATERIAL:
                this.prop.navigation.navigate(Routes.Marketing, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.SELLER_ADDED_VIEW:
                this.prop.navigation.navigate(Routes.Views, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.BUYER_REGISTER:
                this.prop.navigation.navigate(Routes.Buyer, {
                    id: parseInt(item.propertyId)
                });
                break;
            case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_SELLER:
            case NOTIFICATION_TYPE.AGENT_UPLOADED_DOC_BUYER:
                this.prop.navigation.navigate(Routes.Document, {
                    id: parseInt(item.propertyId)
                });
                break;
            default:
                return "#";
        }
    };

    openRoute = (item) => {
        if (item.notificationType) {
            if (!item.isRead) {
                this.database.child('generalNotifications').child(`${this.props.user.id}`).child(item.key).update({isRead: true})
            }
            IS_WEB ? this.props.history.push(getRouteForNotification(item)) : this.getRouteForNotificationMobile(item)
        } else {
            this.database.child('notificationList').child(`${this.props.user.id}`).child(item.key).update({isRead: true});
            if (IS_WEB) {
                this.props.history.push(`${Routes.ChatDetails}/${encryptId(item.propertyId)}/${encryptId(item.senderId)}`)
            } else {
                this.props.navigation.navigate(Routes.ChatDetails, {
                    receiverId: item.receiverId
                })
            }
        }

        /* switch (notificationId) {
             case NOTIFICATIONS.SellerEnergyLabeling:
                 return Routes.EnergyLabeling
         }*/
    };

    componentDidMount() {
        this.database.once('value', (e) => {
            console.log(e.val())
        });
        this.generalMessageRef = this.database.child('generalNotifications').child(`${this.props.user.id}`).orderByChild("timestamp");
        this.chatMessageRef = this.database.child('notificationList').child(`${this.props.user.id}`).orderByChild("timestamp");
        this.generalMessageRef.on('value', (e) => {
            let notifications = [];
            e.forEach((child) => {

                notifications.push({
                    ...child.val(),
                    key: child.key
                })
            });
            this.setState({
                notifications: notifications.slice().reverse(),
                generalNotificationLoading: false,
                generalNotificationCount: notifications.filter(item => !item.isRead).length
            })
        }).catch(() => {
            this.setState({
                generalNotificationLoading: false,
            })
        });
        this.chatMessageRef.on('value', (e) => {
            let notifications = [];
            e.forEach((child) => {
                notifications.push({
                    ...child.val(),
                    key: child.key
                })
            });
            this.setState({
                messages: notifications.slice().reverse(),
                chatNotificationLoading: false,
                chatNotificationCount: notifications.filter(item => !item.isRead).length
            })
        }).catch(() => {
            this.setState({
                generalNotificationLoading: false,
            })
        })
    }

    componentWillUnmount() {
        this.generalMessageRef && this.generalMessageRef.off();
        this.chatMessageRef && this.chatMessageRef.off()
    }

    clearAll = () => {
        this.database.child('generalNotifications').child(`${this.props.user.id}`).remove()
    };

    renderNotification = ({item, index}) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              key={item.key}
                              onPress={() => this.openRoute(item)}>
                <View style={[styles.notiCont]}
                      key={index.toString()}>
                    <View style={[styles.dot, {backgroundColor: item.isRead ? Color.TRANSPARENT : Color.RED}]}/>
                    <View style={CommonStyle.flex}>
                        <Label small
                               style={CommonStyle.flex}>
                            {item.title}
                        </Label>
                        <Label small
                               mt={5}
                               color={Color.DARK_GRAY}>
                            {/*{moment(item.timestamp).diff(moment(), 'd') > 2 ? moment(item.timestamp).format(DateUtils.MMM_dd_mm_yyyy) : moment(item.timestamp).fromNow(true)}*/}
                            {getDateTimeForNotification(item.timestamp)}
                        </Label>
                    </View>
                </View>
                <Hr/>
            </TouchableOpacity>
        )
    };

    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={[{
            text: `${Strings.notification} ${this.state.generalNotificationCount ? '( ' + this.state.generalNotificationCount + ' )' : ""}`,
        }, {
            text: `${Strings.messages} ${this.state.chatNotificationCount ? '( ' + this.state.chatNotificationCount + ' )' : ""}`,
        }]}/>;
    };

    renderClearAll = () => {
        if (this.state.selectedPage) {
            return null
        }
        return (
            <Label color={Color.TERNARY_TEXT_COLOR}>
                {Strings.clearAll}
            </Label>
        )
    };

    renderEmptyNotification = () => {
        return (
            <Label small
                   font_medium
                   mt={20}
                   align={'center'}>
                {'Ingen data'}
            </Label>
        )
    };

    render() {
        const {messages, notifications} = this.state;
        return (
            <View style={CommonStyle.flex}>
                <Header navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedTitle={Strings.notification}
                        animatedValue={new Animated.Value(0)}
                        onPressRightFirst={this.clearAll}
                        renderHeaderFirst={() => this.renderClearAll()}/>
                <View style={styles.icCont}>
                    <Icon size={ThemeUtils.fontXXLarge}
                          name={'notifications'}
                          color={Color.PRIMARY_DARK}/>
                </View>
                <IndicatorViewPager indicator={this._renderTabIndicator()}
                                    onPageSelected={(e) => this.setState({selectedPage: e.position})}
                                    style={CommonStyle.flex}>
                    <View>
                        {this.state.generalNotificationLoading ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <FlatList data={notifications}
                                      ListEmptyComponent={this.renderEmptyNotification}
                                      renderItem={this.renderNotification}/>
                        )}

                    </View>
                    <View>
                        {this.state.chatNotificationLoading ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <FlatList data={messages}
                                      ListEmptyComponent={this.renderEmptyNotification}
                                      renderItem={this.renderNotification}
                                      keyExtrator={item => item.key}/>
                        )}
                    </View>
                </IndicatorViewPager>

            </View>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
};
export default connect(mapStateToProps)(withFirebase(Notifications))
