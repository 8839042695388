import React from 'react';
import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,

    },
    scrollCont: {
        paddingTop: 55,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
})
