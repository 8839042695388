import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 30;

const styles = StyleSheet.create({
    inst: {
        lineHeight: 21
    },
    scrollContentContainer:{
        flexGrow:1
    },
    fieldContainer: {
        alignContent: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_BG_COLOR
    },
    headerTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:40
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    topContainer: {
        marginTop: 40,
        marginHorizontal: containerHorizontalMargin,
        justifyContent: 'center',
    },
    btnCont: {
        alignSelf: 'stretch',
        marginTop: 10
    }
});

export {styles};
