import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, SafeAreaView, View} from 'react-native'
import {
    Accordion,
    DropDown,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    Modal,
    PagerTabIndicator,
    Ripple,
    Tag
} from "components/src/components";
//Third Party
import moment from "moment";
//Network
import {useQuery} from "@apollo/react-hooks";
import {STACK_HOLDERS_FROM_DS, STACK_HOLDERS_SIGNED_IN_VIEW, UPCOMING_VIEWS} from "components/src/api/query";
//Utils
import styles from './styles';
import {
    Color,
    CommonStyle,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import Search from "./Search";

const TABS = [{
    text: Strings.upcomingViews
}, {
    text: Strings.completedViews
}, {
    text: Strings.stakeholders
}];
const selectDrop = [
    {
        name: Strings.stackHolderDS,
        value: 1,
    },
    {
        name: Strings.signedInView,
        value: 2,
    },
]


function Views(props) {

    const {history, navigation} = props;
    const [stackholderType, setValue] = useState(selectDrop[0]?.value);

    const [currentScreen, setScreen] = useState(0)
    const [isSearchVisible, setSearchVisible] = useState(false)

    const propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const upcomingQuery = useQuery(UPCOMING_VIEWS, {
        variables: {

            property_id: propertyId,
        }
    });

    /*const completedQuery = useQuery(COMPLETED_VIEWS, {
        variables: {
            input: {
                property_id: propertyId,
                status: 1
            }
        }
    });*/

    const stackHolderFromDsQuery = useQuery(stackholderType === 1 ? STACK_HOLDERS_FROM_DS : STACK_HOLDERS_SIGNED_IN_VIEW, {
        variables: {
            first: 30,
            page: 1,
            propertyId: propertyId,
            status: 1
        }
    });

    const getStatusColor = (is_broker_on_view) => {
        return Color.SECONDARY
        if (is_broker_on_view === 'Broker on View') {
            return Color.TEXT_COLOR_BLUE;
        }
        return Color.TEXT_COLOR_ORANGE;
    };


    const getStatusText = (is_broker_on_view) => {
        if (is_broker_on_view === "Broker on View") {
            return Strings.brokeronView;
        }
        return Strings.selleronView;
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}
                                  scrollEnabled={!ThemeUtils.isTablet()}/>;
    };

    const renderDisplayContent = (item) => {
        return (
            <Label small
                   font_regular
                   ml={5}
            >
                {item.comment}
            </Label>
        )
    };
    const renderDisplayHeader = (item) => {
        return (
            <View style={styles.displayTimeCont}>

                <View style={CommonStyle.flex}>
                    <Tag name={getStatusText(item.is_broker_on_view)}
                         small
                         mt={10}
                         color={getStatusColor(item.is_broker_on_view)}/>
                    <Label small
                           font_medium
                           mt={10}>
                        {moment(item.display_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                    </Label>
                    <Label small
                           font_medium
                           mt={10}>
                        {`${moment(item.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} to ${moment(item.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                    </Label>


                </View>
            </View>
        )
    };


    const renderDisplay = ({item, index}) => {
        return (
            <>

                <Accordion isExpanded
                           iconColor={Color.DARK_GRAY}
                           headerContainerStyle={styles.accordionHeader}
                           contentContainerStyle={styles.accordionContent}
                           tintColor={Color.PRIMARY}
                           renderContent={() => renderDisplayContent(item)}
                           renderHeader={() => renderDisplayHeader(item)}
                />
                <Hr/>
            </>
        )
    };

    const _renderStack = () => {
        let data = stackholderType === 1 ? stackHolderFromDsQuery?.data?.stackholders_from_ds : stackHolderFromDsQuery?.data?.signed_in_viewing_ds
        return (
            <>
                <View style={styles.contentCont}>
                    <View style={{marginVertical: ThemeUtils.relativeRealHeight(2)}}>
                        <DropDown closeOnSelect
                                  options={selectDrop}
                                  selectedOption={0}
                                  placeTitle={Strings.selectOption}
                                  small
                                  placeholder={Strings.selectOption}
                                  style={{boderColor: Color.INPUT_BORDER}}
                                  onSelectOptions={setValue}/>
                    </View>
                </View>
                {stackHolderFromDsQuery.loading ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : (
                    <FlatList data={data?.data ?? []}
                              renderItem={_renderData}
                              ListEmptyComponent={
                                  <Label mt={20}
                                         font_medium
                                         align={'center'}>
                                      {"Ingen data"}
                                  </Label>
                              }
                              onEndReached={(info) => {
                                  if (data?.paginatorInfo?.hasMorePages && !loading) {
                                      stackHolderFromDsQuery.fetchMore({
                                          variables: {
                                              page: (data.paginatorInfo?.currentPage ?? 0) + 1,
                                          },
                                          updateQuery: (prev, {fetchMoreResult}) => {
                                              if (!fetchMoreResult) {
                                                  return prev;
                                              } else {
                                                  let finalResult = Object.assign({}, fetchMoreResult);
                                                  if (stackholderType === 1) {
                                                      finalResult.stackholders_from_ds.data = [...prev.stackholders_from_ds.data, ...fetchMoreResult.stackholders_from_ds.data];
                                                  } else {
                                                      finalResult.signed_in_viewing_ds.data = [...prev.signed_in_viewing_ds.data, ...fetchMoreResult.signed_in_viewing_ds.data];
                                                  }

                                                  return finalResult;
                                              }
                                          },
                                      });
                                  }
                              }}
                              onEndReachedThreshold={0.2}
                              keyExtractor={item => item.id.toString()}/>
                )}


            </>
        )
    }

    const navigate = (item) => {
        if (IS_WEB) {
            history.push(Routes.UserDetails, {
                user: item
            })
        } else {
            navigation.navigate(Routes.UserDetails, {
                user: item
            })
        }
    };


    const _renderData = ({item, index}) => {
        return (
            <Ripple onPress={() => navigate(item)}>
                <View style={styles.contentCont}>
                    <Label small
                           font_medium
                           mt={16}>
                        {`${item.first_name} ${item.last_name}`}
                    </Label>
                    <View style={styles.dataMainView}>
                        <View style={styles.dataSubView}>
                            <Label font_regular
                                   small
                                   mb={3}>
                                {item.email}
                            </Label>
                        </View>
                        <View>
                            <Icon name={'drop-normal'}
                                  color={Color.PRIMARY}
                                  size={ThemeUtils.fontXSmall}/>
                        </View>

                    </View>
                    <Label small
                           font_regular
                           mb={16}>
                        {item.phone}
                    </Label>

                </View>
                <Hr/>
            </Ripple>
        )

    }

    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Search onClose={() => setSearchVisible(false)}
                                query={stackholderType === 1 ? STACK_HOLDERS_FROM_DS : STACK_HOLDERS_SIGNED_IN_VIEW}
                                variables={{
                                    first: 30,
                                    page: 1,
                                    propertyId: propertyId,
                                    status: 1
                                }}
                                renderItem={_renderData}/>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }
    return (
        <View style={styles.cont}>
            {renderSearchModal()}
            <Header tintColor={Color.PRIMARY}
                    style={CommonStyle.blankHeader}
                    headerRightFirst={currentScreen === 2 ? 'search' : null}
                    onPressRightFirst={() => setSearchVisible(true)}
                    navigation={IS_WEB ? history : navigation}/>

            <View>
                <View style={styles.contentCont}>
                    <View style={styles.titleCont}>
                        <Icon name={'book-inspection'}
                              size={ThemeUtils.fontXXLarge}/>
                        <Label xlarge
                               font_medium
                               mt={5}
                               ml={10}>
                            {Strings.views}
                        </Label>
                    </View>
                </View>

            </View>
            <IndicatorViewPager indicator={_renderTabIndicator()}
                                onPageSelected={(e) => setScreen(e.position)}
                                style={{flex: 1, backgroundColor: 'white'}}>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}>
                        <View>
                            {upcomingQuery.loading ? (
                                    <ActivityIndicator style={CommonStyle.loader}
                                                       color={Color.PRIMARY}/>
                                ) :
                                (
                                    <FlatList data={upcomingQuery.data?.property_display.filter(item => {
                                        return moment(`${item.display_date} ${item.latest_start_time}`, DateUtils.yyyy_mm_dd_hh_mm_ss).diff(moment(), 's') > 0
                                    })}
                                              ListEmptyComponent={
                                                  <Label mt={20}
                                                         font_medium
                                                         align={'center'}>
                                                      {"Ingen data"}
                                                  </Label>
                                              }
                                              renderItem={renderDisplay}/>
                                )}

                        </View>
                    </Animated.ScrollView>
                </View>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}>
                        <View>
                            {upcomingQuery.loading ? (
                                    <ActivityIndicator style={CommonStyle.loader}
                                                       color={Color.PRIMARY}/>
                                ) :
                                (
                                    <FlatList data={upcomingQuery.data?.property_display.filter(item => {

                                        return moment(`${item.display_date} ${item.latest_start_time}`, DateUtils.yyyy_mm_dd_hh_mm_ss).diff(moment(), 's') < 0
                                    })}
                                              ListEmptyComponent={
                                                  <Label mt={20}
                                                         font_medium
                                                         align={'center'}>
                                                      {"Ingen data"}
                                                  </Label>
                                              }
                                              renderItem={renderDisplay}/>
                                )}
                        </View>
                    </Animated.ScrollView>

                </View>
                <View>

                    {/*<Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>*/}
                    {_renderStack()}
                    {/* </View>
                    </Animated.ScrollView>*/}
                </View>

            </IndicatorViewPager>
        </View>
    )
}

export default Views;

