import React, {useEffect, useState} from 'react';
import {Animated, View} from 'react-native'
//Components
import {CustomButton, FilePicker, FileType, Header, Label, Ripple} from "components/src/components";
//Third party
import moment from "moment-timezone";
//Network
import {useMutation, useQuery} from '@apollo/react-hooks';
import {MATERIAL_DETAILS} from 'components/src/api/query';
//Utils
import {Color,Constants, DateUtils, Icon, IS_WEB, openInNewTab, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {REPLACE_MATERIAL} from "components/src/api/mutation";


function MarketingMaterialDetails(props) {
    const {history, navigation} = props;

    const [material, setMaterial] = useState(IS_WEB ? history.location.state.material : navigation.getParam('material', {}));
    const [comments, setComments] = useState('');
    const [files, setFile] = useState([]);
    const scrollY = new Animated.Value(0)

    const {loading, data, error} = useQuery(MATERIAL_DETAILS, {
        fetchPolicy:'network-only',
        variables: {
            id: material.id,
            status: 1
        }
    })

    const [replaceDoc, replaceDocMutation] = useMutation(REPLACE_MATERIAL)

    useEffect(() => {
        if (data?.fetch_materials_detials) {
            setMaterial(data?.fetch_materials_detials)
        }
    }, [data]);

    return (
        <View style={styles.cont}>
            <Header animatedTitle={Strings.marketingMaterials}
                    navigation={IS_WEB ? history : navigation}
                    // headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.replace(Routes.PropertyDashboard) : navigation.popToTop()}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.scrollCont}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.contentCont}>
                    <Icon name={'marketing-materials'}
                          style={styles.icMaterial}
                          size={ThemeUtils.fontXXLarge}/>


                    {!material.is_approved ? (<>
                            <Label small
                                   font_medium
                                   mb={10}>
                                {Strings.uploadDoc}
                            </Label>
                            <FilePicker type={FileType.PDF}
                                        onFilePicked={(data, file) => setFile(file)}/>

                        </>
                    ) : null}

                    <View style={styles.materialCont}>
                        <Icon name={'marketing-material-print'}
                              color={Color.PRIMARY}
                              size={16}/>
                        <View style={styles.lblCont}>
                            <Label font_medium
                                   small>
                                {files.length ? files[0].name : material?.document?.title}
                            </Label>
                            {/*<Label xsmall>
                                {status ? `${Strings.approvedOn} ${moment(parseInt(time)).format('HH:MM, DD-MM-YYYY')} ` : Strings.notApprovedYet}
                            </Label>*/}
                        </View>
                        <Ripple rippleContainerBorderRadius={14}
                                onPress={()=>{openInNewTab(`${Constants.APIConfig.STORAGE_URL}${material?.document?.doc_url}`)}}
                                style={styles.icOpenExtCont}>
                            <Icon name={'open-externally'}
                                  color={Color.PRIMARY_LIGHT}
                                  size={14}/>
                        </Ripple>
                    </View>


                    {!material.is_approved ?
                        files.length ? (<CustomButton title={Strings.save}
                                                      loading={replaceDocMutation.loading}
                                                      style={{
                                                          alignSelf: 'flex-start',
                                                          paddingHorizontal: 20,
                                                          marginVertical: 20
                                                      }}
                                                      onPress={() => {
                                                          replaceDoc({
                                                              variables: {
                                                                  file: files,
                                                                  id: material.id
                                                              }
                                                          }).then(()=>{
                                                              IS_WEB ? history.goBack() : navigation.goBack()
                                                          }).catch(()=>{

                                                          })
                                                      }}/>) : null
                        : (
                            <Label small
                                   color={Color.PRIMARY}
                                   align={'center'}
                                   style={styles.lblAprovedDate}>
                                {`${Strings.approvedOn} ${moment.utc(material.approved_date).local().format(DateUtils.hh_mm_dd_mm_yyy)}`}
                            </Label>
                        )}


                    {material?.materialComment?.length ? (
                            <>
                                <Label small
                                       font_medium
                                       mt={15}
                                       color={Color.PRIMARY}>
                                    {Strings.commentsFromSeller}
                                </Label>
                                {material.materialComment.map(item => (
                                    <>

                                        <Label small
                                               mt={15}
                                               color={Color.PRIMARY}>
                                            {item.comment}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={10}
                                               color={Color.DARK_GRAY}>
                                            {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                        </Label>
                                    </>
                                ))}
                            </>)

                        : null}

                </View>
            </Animated.ScrollView>
        </View>
    )
}

export default MarketingMaterialDetails;
