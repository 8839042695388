import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native';
import {CustomButton, Header, Label} from "components/src/components";
//Network
import {useQuery} from "@apollo/react-hooks";
import {DS_DETAILS} from "components/src/api/query";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    encryptId,
    formatPrice,
    IS_WEB,
    openInNewTab,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import moment from "moment";


export default function AssignmentDetails(props) {
    const {navigation, history} = props;

    const scrollY = new Animated.Value(0);

    const [assignment, setAssignment] = useState(IS_WEB ? history.location.state?.assignment ?? {} : navigation.getParam('assignment', {}));
    const [oppDragError, setOppDragError] = useState(null);
    const [oppDragNo, setOppDragNo] = useState('');
    const [newOppDragNo, setNewOppDragNo] = useState('');
    const [oppDragVisible, setOppDragVisible] = useState(false);


    const {loading, data, error, refetch} = useQuery(DS_DETAILS, {
        variables: {
            id: assignment.oppdragsnummer
        }
    })

    useEffect(() => {
        setOppDragError(null)
    }, [oppDragNo]);


    useEffect(() => {
        if (data?.ds_property_detail) {
            setAssignment({...assignment, ...data?.ds_property_detail})
        }
    }, [data]);


    const getStatusLabel = (status) => {

        switch (status) {
            case 'Unknown':
                return 'Ukjent';
            case 'Stopped':
                return 'Stoppet';
            case 'Expired':
                return 'Utløpt';
            case 'Live':
                return 'Aktiv';
            case 'Sold':
                return 'Solgt';
            default:
                return 'Ukjent';

        }
    };

    return (
        <View style={CommonStyle.flex}>
            {/*{renderAddOpdragModal()}*/}
            <Header initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.assignmentDetails}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.scrollCont}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={[CommonStyle.loader, {marginTop: 55}]}/>
                ) : (
                    <>
                        <View style={CommonStyle.flex}>
                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(9)}
                                   mb={ThemeUtils.relativeRealHeight(1)}
                                   font_medium>
                                {Strings.status}
                            </Label>
                            <Label small>
                                {getStatusLabel(assignment?.vitecPropertyAds?.ads?.adStatus_text)}
                            </Label>
                            <Label small
                                   font_medium
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   mb={ThemeUtils.relativeRealHeight(1)}>
                                {Strings.oppDragNo}
                            </Label>
                            {assignment.oppdragsnummer ? (
                                <Label small>
                                    {assignment.oppdragsnummer}
                                </Label>) : null}

                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   mb={ThemeUtils.relativeRealHeight(1)}
                                   font_medium>
                                {Strings.propertyAddress}
                            </Label>
                            <Label small>
                                {`${assignment.streetAdress},${assignment.city},${assignment.zipCode}`}
                            </Label>
                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   mb={ThemeUtils.relativeRealHeight(1)}
                                   font_medium>
                                {Strings.city}
                            </Label>
                            <Label small>
                                {assignment?.city}
                            </Label>

                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   mb={ThemeUtils.relativeRealHeight(1)}
                                   font_medium>
                                {"Sist oppdatert"}
                            </Label>
                            <Label small>
                                {moment(assignment.lastUpdatedDate, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                            </Label>

                            {/*<Label small
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   font_medium>
                                {"Område"}
                            </Label>
                            <Label small
                                   mt={10}>
                                {assignment?.vitecPropertyPlot?.size}
                            </Label>*/}

                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(3)}
                                   mb={ThemeUtils.relativeRealHeight(1)}
                                   font_medium>
                                {"Prisantydning"}
                            </Label>
                            <Label small>
                                {`Kr ${formatPrice(assignment?.vitecPropertyEstatePrice?.priceSuggestion)},-`}
                            </Label>
                            {/*
                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.seller1}
                    </Label>
                    <Label small
                           mt={10}>
                        {`${assignment?.seller?.first_name} ${assignment?.seller?.last_name}`}
                    </Label>

                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.seller2}
                    </Label>
                    <Label small
                           mt={10}>
                        {`${assignment?.coOwnerDetails?.first_name} ${assignment?.coOwnerDetails?.last_name}`}
                    </Label>*/}
                        </View>


                        <CustomButton title={Strings.viewHistory}
                                      onPress={() => IS_WEB ? history.push(`/${Routes.DSHistory}/${encryptId(assignment?.id)}`) : navigation.navigate(Routes.DSHistory, {
                                          id: assignment.id
                                      })}
                                      mt={10}/>

                        <CustomButton title={Strings.gotoDs}
                                      onPress={() => {
                                          if (assignment?.vitecPropertyAds?.ads &&
                                              moment(assignment.vitecPropertyAds?.ads?.publishStart).diff(moment(), 's') < 0) {
                                              if (assignment.vitecPropertyAds.ads.adStatus_text !== 'Stopped') {
                                                  openInNewTab(`${Constants.APIConfig.FRONT}eiendom/${assignment?.oppdragsnummer}`)
                                              } else {
                                                  openInNewTab(`${Constants.APIConfig.FRONT}eiendom/godkjenning/${assignment?.oppdragsnummer}`)
                                              }
                                          } else {
                                              openInNewTab(`${Constants.APIConfig.FRONT}eiendom/godkjenning/${assignment?.oppdragsnummer}`)
                                          }
                                      }}
                                      mt={10}
                                      mb={20}/>
                    </>
                )}


            </Animated.ScrollView>
        </View>
    )
}
