import React from 'react'
import {View} from 'react-native'
import withDrawer from "components/src/HOC/Drawer";
import { Strings} from "components/src/utils";
import Routes from '../../../../../mobile/src/router/routes';
// import Profile from '../Authenticated/MyAccount/Profile';
import Home from "./Home";
import Accordion from "../../../components/ui/Accordion";
// import Moving from './Moovin/PropertyListing';
// import Financing from './Financing'

const TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    route: Routes.AgentDashboard,
    screen: Home
}, {
    id: 2,
    name: Strings.tasks,
    icon: 'tasks-or-checklist',
    route: Routes.Tasks
}, {
    id: 3,
    name: Strings.profile,
    icon: 'profile',
    route: Routes.Profile,
    // screen: Profile
}];


const DRAWER_ITEMS = [{
    name: Strings.dashBoard,
    icon: 'dashboard',
    // route: Routes.Dashboard,
},
    /* {
         name: Strings.calendar,
         icon: 'calendar'
     },*/
    {
        name: Strings.news,
        icon: 'news',
        route: Routes.News
    },
    {
        name: Strings.digitalSalesAssignment,
        icon: 'digital-sales-assignment',
        route: Routes.DigitalSalesAssignment
    },
    {
        name: Strings.properties,
        icon: 'ds-property',
        route: Routes.PropertyListing
    },
    {
        name: Strings.signatureAssignment,
        icon: 'sign-everywhere',
        section: true,
        route: Routes.SignatureAssignment
    },
    {
        name: Strings.financing,
        icon: 'financing',
        route: Routes.Financing
    },
    {
        name: Strings.inspectionApplications,
        icon: 'inspection-application',
        route: Routes.InspectionApplication
    },
    {
        name: Strings.professional,
        icon: 'experienced-real-estate-agent',
        // route: Routes.Professional
    }, {
        name: Strings.logout,
        icon: 'logout',
    }];


const getParams = (props) => {
    return {
        tabs: TABS,
        drawerItems: DRAWER_ITEMS
    }
};
export default withDrawer(getParams)
