import React from 'react'
//Components
import {Animated, View, Image, ActivityIndicator} from 'react-native'
import { Hr, Header, Ripple, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Utils
import {
    Color, CommonStyle, Constants, encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import styles from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {SERVICES} from "../../../../../api/query";

const scrollY = new Animated.Value(0);

const OfficeDigitalMarketing = props => {


    const {loading, data, error} = useQuery(SERVICES, {
        variables: {
            status: true,
            category: Constants.ServiceCategory.PrintMarketing
        }
    });

    const navigateToRoute = (service) => {
        let route = Routes.B2CServiceDetails

        if (IS_WEB) {
            props.history.push(`${Routes.B2CPrintMarketing}/${service.name.toLowerCase()}/${encryptId(service?.office_template?.id)}`,{
                service:service
            })
        } else {
            props.navigation.navigate(route, {
                serviceId: service?.office_template?.id,
                service:service
            })
        }
    };

    const renderOptions = options => {
        return (
            <View>
                {options?.map((item, index) => {
                    return (
                        <Ripple key={index} onPress={() => {
                            navigateToRoute(item, index);
                        }}>
                            <Hr/>

                            <View style={styles.optionContainer}>

                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.image}`}}
                                       style={styles.imgThumb}/>
                                <View style={CommonStyle.flex}>
                                    <Label small
                                           ml={20}
                                           font_medium>
                                        {item.name}
                                    </Label>
                                   {/* <Label small
                                           ml={20}

                                           numberOfLines={2}>
                                        {item?.office_template?.description}
                                    </Label>*/}
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'drop-normal'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                            {index === options.length - 1 ? <Hr/> : null}
                        </Ripple>
                    )
                })}
            </View>
        );
    }


    return (
        <Provider locale={enUS}>

            <Header animatedTitle={Strings.printMarketing}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'print-marketing'}
                                color={Color.PRIMARY_DARK}/>
                        </View>

                        {/*<Label
                            mt={16}
                            small
                            font_regular
                            align={'left'}>
                            {""}
                        </Label>*/}
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : renderOptions(data?.services)}
                </View>
            </Animated.ScrollView>
        </Provider>
    )

}

export default OfficeDigitalMarketing;
