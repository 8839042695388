import {StyleSheet} from "react-native";
import {Color, ThemeUtils} from "components/src/utils";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },

    topContainer: {
        flex: 1,
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: "center",
    },

    viewicon: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    buttonContainer: {
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        marginVertical: 10,
        flexDirection: "row",
    },
    SubViewIcon: {
        backgroundColor: Color.GRAY,
        height: ThemeUtils.responsiveScale(210),
        width: ThemeUtils.responsiveScale(210),
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,
    },
    contentCont: {
        flex: 1,
        marginHorizontal: 20,
    },
    titleCont: {
        marginTop: 5,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    lineStyle: {
        backgroundColor: Color.LIGHT_GRAY,
        marginHorizontal: ThemeUtils.relativeRealWidth(3),
        marginVertical: 10
    },
    btnOkay: {
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginVertical: 10
    },

    propertySelectionCont: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },

    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',

    },
    GridImage: {
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        height: ThemeUtils.responsiveScale(200),
        // width: ThemeUtils.responsiveScale(200),
        borderWidth: 0.5,
        borderColor: Color.PRIMARY_LIGHTER
    },

    selectedImage: {
        padding: 10,
        position: 'absolute',
        start: 0,
        end: 0,
        height: ThemeUtils.responsiveScale(200),
        // width: ThemeUtils.responsiveScale(200),
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        backgroundColor: Color.PRIMARY_LIGHT
    },
});
export {styles};
