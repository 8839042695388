import React, {useEffect, useState} from 'react'
import {ActivityIndicator, Image, SafeAreaView, View} from 'react-native';
import {FilePicker, Hr, Label, Modal, Ripple} from "components/src/components";
import styles from './styles.js'
import PropTypes from 'prop-types';
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";

export default function InputDialog(props) {
    const {loading, oldPhotoRequired, visible, onNegativePress, onPositivePress, title, desc,} = props;
    const [link, setLink] = useState('');
    const [errLink, setErrLink] = useState(null);

    const [oldImage, setOldImage] = useState(null)
    const [newImage, setNewImage] = useState(null)
    useEffect(() => {
        setErrLink(null)
    }, [link])

    const validateLink = () => {
        onPositivePress && onPositivePress(newImage, oldImage)
    };

    return (
        <Modal transparent
               animated
               animationType={'fade'}
               hardwareAccelerated
               visible={visible}>
            <View style={styles.container}>
                <View style={styles.dialogCont}>
                    <Label ml={10}
                           mt={10}
                           mr={10}
                           font_medium
                           align={'center'}
                           style={styles.lblTitle}>
                        {title}
                    </Label>
                    <View style={styles.MainView}>
                        <View style={styles.pickerCont}>
                            {oldPhotoRequired ? (<View>
                                <Label small
                                       font_medium
                                       ml={ThemeUtils.relativeRealWidth(2)}
                                       mb={ThemeUtils.relativeRealHeight(1)}>
                                    {Strings.uploadOldImage}
                                </Label>
                                <FilePicker onFilePicked={(data, files) => setOldImage(files[0])}>
                                    {oldImage ? (
                                        <Image source={IS_WEB ? URL.createObjectURL(oldImage) : {uri: oldImage.uri}}
                                               style={styles.imagePicker}/>
                                    ) : (<View style={[styles.imagePicker]}>
                                        <Icon name={'add'}
                                              size={ThemeUtils.responsiveScale(30)}
                                              color={Color.PRIMARY_LIGHT}/>
                                    </View>)}

                                </FilePicker>
                            </View>) : null}
                            <View>
                                <Label small
                                       font_medium
                                       mb={ThemeUtils.relativeRealHeight(1)}
                                       ml={ThemeUtils.relativeRealWidth(2)}>
                                    {Strings.uploadNewPhoto}
                                </Label>
                                <FilePicker onFilePicked={(data, files) => setNewImage(files[0])}>
                                    {newImage ? (
                                        <Image source={IS_WEB ? URL.createObjectURL(newImage) : {uri: newImage.uri}}
                                               style={styles.imagePicker}/>
                                    ) : (
                                        <View style={[styles.imagePicker]}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(30)}
                                                  color={Color.PRIMARY_LIGHT}/>
                                        </View>)}

                                </FilePicker>
                            </View>
                        </View>
                    </View>
                    <Hr/>
                    <View style={styles.btnCont}>
                        <Ripple onPress={onNegativePress}
                                style={styles.btn}>
                            <Label mt={15}
                                   mb={15}
                                   align={'center'}>
                                {Strings.cancel}
                            </Label>
                        </Ripple>
                        <View style={styles.divider}/>
                        <Ripple onPress={() => validateLink()}
                                style={styles.btn}>
                            <Label color={Color.TERNARY_TEXT_COLOR}
                                   align={'center'}>
                                {loading ? (
                                    <ActivityIndicator style={IS_WEB ? null : CommonStyle.loaderSize}
                                                       color={Color.PRIMARY}/>
                                ) : Strings.upload}

                            </Label>
                        </Ripple>
                    </View>
                </View>
            </View>
        </Modal>
    )
}
InputDialog.propTypes = {
    visible: PropTypes.bool,
    onPositivePress: PropTypes.func,
    onNegativePress: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
    type: PropTypes.string,

    onFocus: PropTypes.func,
    editable: PropTypes.bool,
    oldPhotoRequired: PropTypes.bool,


}
