import React from 'react'
import {Accordion, Hr, HTMLView, IndicatorViewPager, Label, StarRating} from "components/src/components";
import {ActivityIndicator, Image, ScrollView, TouchableOpacity, View} from 'react-native'
import styles from './styles';
import {Color, CommonStyle, Constants, openInNewTab, Strings} from "components/src/utils";
import PagerDotIndicator from "components/src/components/ui/viewpager/indicator/PagerDotIndicator";
import {connect} from "react-redux";
import {useQuery} from "@apollo/react-hooks";
import {PROPERTY_DETAILS} from "../../../../../../../api/query";


function Broker(props) {
    const {inspection} = props;
    const {property} = inspection


    const {loading, data, error} = useQuery(PROPERTY_DETAILS, {
        variables: {
            id: property?.id
        }
    })

    const renderTestimonials = (item, index) => {
        console.log(item.rating)
        return (
            <View style={styles.testimonialCont}
                  key={index.toString()}>
                <StarRating rating={item.rating}/>
                <Label small
                       color={Color.TERNARY_TEXT_COLOR}
                       align={'center'}>
                    {item?.feedback}
                </Label>
                <Label small
                       font_medium
                       align={'center'}>
                    {item?.given_by}
                </Label>
            </View>
        )
    };

    const renderIndicator = () => {
        return (
            <PagerDotIndicator pageCount={10}/>
        )
    };
    const renderMedia = (item, index, self) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => {
                                  openInNewTab(item?.redirect_url)
                              }}
                              style={[styles.mediaImgCont, index === self.length - 1 ? {marginEnd: 20} : null]}
                              key={index.toString()}>
                <Image style={styles.mediaImage}
                       source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}/>
                <Label small
                       mt={10}
                       numberOfLines={2}>
                    {item?.title}
                </Label>
            </TouchableOpacity>
        )
    }

    return (
        <View style={styles.container}>
            {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) :
                (<ScrollView>
                    <View>
                        <View style={styles.contentCont}>
                            <Image style={styles.img}
                                   source={{uri: `${Constants.APIConfig.STORAGE_URL}${data?.property?.agent?.profile_image}`}}/>
                            <Label xlarge
                                   font_medium
                                   mt={20}>
                                {`${data?.property?.agent?.first_name ?? ""} ${data?.property?.agent?.last_name ?? ""}`}
                            </Label>
                            <Label
                                color={Color.TERNARY_TEXT_COLOR}
                                mt={10}>
                                {data?.property?.agent?.title}
                            </Label>
                        </View>
                        <Hr/>
                        <View style={styles.contentCont}>
                            <Label small
                                   font_medium>
                                {Strings.title}
                            </Label>
                            <Label small
                                   mb={15}>
                                {data?.property?.agent?.title}
                            </Label>
                            <Label small
                                   font_medium>
                                {Strings.education}
                            </Label>
                            <Label small
                                   mb={15}>
                                {data?.property?.agent?.user_detail?.education}
                            </Label>
                            <Label small
                                   font_medium>
                                {Strings.office}
                            </Label>
                            <Label small
                                   mb={15}>
                                {data?.property?.agent?.office?.name}
                            </Label>
                        </View>
                        <Hr/>
                        <Accordion headerText={Strings.summary}
                                   tintColor={Color.PRIMARY_TEXT_COLOR}
                                   headerContainerStyle={styles.accordionHeader}
                                   contentContainerStyle={styles.accordionContent}
                                   renderContent={() => {
                                       console.log(data?.property?.agent?.user_detail?.about)
                                       return (
                                           <HTMLView value={data?.property?.agent?.user_detail?.about}/>
                                       )
                                   }}/>
                        <Hr margin/>
                        <View style={styles.contentCont}>
                            <Label small
                                   font_medium>
                                {Strings.testimonial}
                            </Label>
                        </View>
                        {data?.property.agent?.feedback.length ? (
                            <IndicatorViewPager style={styles.pager}>
                                {data?.property.agent?.feedback.map(renderTestimonials)}
                            </IndicatorViewPager>) : null}
                        <Hr/>
                        {data?.property?.agent?.user_media?.length ? (
                            <View style={styles.contentCont}>
                                <Label small
                                       font_medium>
                                    {Strings.media}
                                </Label>
                            </View>) : null}
                        <ScrollView horizontal>
                            {data?.property?.agent?.user_media.map(renderMedia)}
                        </ScrollView>
                    </View>
                </ScrollView>)}
        </View>
    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(Broker)

