import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, Keyboard, View} from 'react-native'
import {
    Attachment,
    CustomButton,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Utils
import {
    Color,
    CommonStyle,
    Constants, decryptId,
    IS_WEB,
    openInNewTab,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {OFFICE_SERVICE_DETAILS} from "../../../../../../api/query";
import {SAVE_OFFICE_TEMPLATE_SERVICE} from "../../../../../../api/mutation";

const scrollY = new Animated.Value(0);

const OfficeIntroDetails = props => {

    const {history, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.OfficePhotoStyling) : navigation.goBack();
    }
    const [service, setService] = useState(serviceFromPage?.office_template ?? {});

    const [headLines, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);

    const [introText, setIntroText] = useState("");
    const [errIntroText, setErrIntro] = useState(null);

    const [servicePrice, setServicePrice] = useState([]);
    const [errServicePrice, setErrServicePrice] = useState({});

    const [video, setVideoFile] = useState([]);

    const [saveServiceDetails, saveDetailsMutation] = useMutation(SAVE_OFFICE_TEMPLATE_SERVICE);

    const {loading, data, error} = useQuery(OFFICE_SERVICE_DETAILS, {
        fetchPolicy:'network-only',
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
        }
    });

    useEffect(() => {
        if (data?.office_template_details) {
            setService(data?.office_template_details);
            setHeadLines(data?.office_template_details?.headline);
            setIntroText(data?.office_template_details?.description);
            let price = data?.office_template_details?.office_services?.map(item => ({
                id: item.id,
                is_free: item.is_free,
                price: parseInt(item.price),
                free_text: item?.free_text ?? ""
            }));
            setServicePrice(price)
        }
    }, [data]);



    const validate = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('headline', headLines);
        let errIntro = validation('intro', introText);
        let errPrice = {};
        let isError = false;
        servicePrice.map(item => {
            errPrice[item.id] = validation('servicePrice', item.price);
            if (errPrice[item.id]) {
                isError = true
            }
        });
        if (!errHead && !isError && !errIntro) {
            saveServiceDetails({
                variables: {
                    input: {
                        office_template_id: service?.id,
                        headline: headLines,
                        intro_text: introText,
                        video: video[0],
                        servicesPrice: servicePrice.map(item => ({
                            ...item,
                            is_free: item.is_free ? 1 : 0,
                            price: parseInt(item.price)
                        }))
                    }
                }
            }).then((data) => {
                IS_WEB?history.goBack():navigation.goBack()
            }).catch(() => {

            })
        } else {
            setErrHeadLines(errHead);
            setErrIntro(errIntro);
            setErrServicePrice(errPrice)
        }
    };

    const handleChangeText = (text, id) => {
        let updatedService = servicePrice.slice();
        if (updatedService[id].is_free) {
            updatedService[id].free_text = text;
        } else {
            updatedService[id].price = text;
        }
        setServicePrice(updatedService)
    };

    const renderInput = (item, index) => {
        return (
            <View key={index.toString()}>
                <Label font_medium>
                    {item?.name}
                </Label>
                <Label small mt={ThemeUtils.relativeRealHeight(2)}>
                    {item?.description}
                </Label>
                <InputField mt={20}
                            error={errServicePrice[item.id]}
                            onFocus={() => setErrServicePrice({...errServicePrice, [item.id]: null})}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}
                            type={'text'}
                            value={item?.is_free ? servicePrice[index]?.free_text : servicePrice[index]?.price}
                            onChange={(text) => handleChangeText(text, index)}>

                    {item.is_free ? null : (<View style={CommonStyle.inputExtra}>
                        <Label font_medium>
                            {'Kr.'}
                        </Label>
                    </View>)}
                </InputField>
            </View>
        )
    };

    const renderIntroDetails = () => {
        return (
            <>
                <Label font_medium>
                    {Strings.headLine}
                </Label>
                <InputField disabled
                    mt={10}
                    align={'center'}
                    type={'text'}
                    value={headLines}
                    error={errHeadline}
                    onChange={setHeadLines}
                />
                <Label font_medium>
                    {Strings.introText}
                </Label>
                <InputField
                    mt={10}
                    align={'center'}
                    type={'text'}
                    textArea
                    error={errIntroText}
                    value={introText}
                    onChange={setIntroText}
                />
                <Label small
                       font_medium
                       mt={10}
                       mb={10}>
                    {Strings.uploadVideo}
                </Label>

                <FilePicker type={FileType.VIDEO}
                            style={{marginBottom:20}}
                            onFilePicked={(data, file) => {
                                if (file.length) {
                                    setVideoFile(file)
                                }
                            }}/>

                {video?.map(item => (
                    <Attachment style={{marginBottom: 10}}
                                name={item.name}
                                textColor={Color.PRIMARY}

                                onPressDelete={() => {
                                    setVideoFile([])
                                }}
                                isDeletable/>
                ))}

                {!video.length && service?.office_service_media?.map(item => (
                    <Attachment style={{marginBottom: 10}}
                                isDownloadable
                                name={serviceFromPage.id===8?"W Ads film lastet opp":"SoMe film lastet opp"}
                                textColor={Color.PRIMARY}
                                onPressDelete={() => {
                                    console.log("Attachment Delete Pressed");
                                }}
                                onPressDownload={() => {
                                    openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.media_url}`)
                                }}
                                isDeletable/>
                ))}
                {service?.office_services?.length ? (
                    <>
                        <Hr/>
                        <Label mt={ThemeUtils.relativeRealHeight(2)}
                               mb={ThemeUtils.relativeRealHeight(2)}
                               font_medium
                               large>
                            {`${Strings.services}:`}
                        </Label>
                        {service?.office_services?.map(renderInput)}
                    </>
                ) : null}

                <View style={styles.bottomButtonContainer}>
                    <CustomButton loading={saveDetailsMutation.loading}
                                  style={{flex: 0.45}}
                                  title={Strings.save}
                                  onPress={validate}/>
                    <CustomButton style={{flex: 0.45}}
                                  bgColor={Color.WHITE}
                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                  borderColor={Color.PRIMARY}
                                  borderWidth={1}
                                  title={Strings.cancel}
                                  onPress={() => {
                                      if (!IS_WEB) {
                                          Keyboard.dismiss()
                                      }
                                      IS_WEB?history.goBack():navigation.goBack()
                                  }}/>
                </View>
            </>
        );
    };

    return (
        <Provider locale={enUS}>

            <Header animatedTitle={serviceFromPage?.name}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}

                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>

                        <Label
                            mt={ThemeUtils.relativeRealHeight(6)}
                            small
                            align={'left'}>
                            {/*{service?.description}*/}
                        </Label>

                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <View style={styles.orderCont}>
                            {renderIntroDetails()}
                        </View>)}
                </View>
            </Animated.ScrollView>
        </Provider>
    )

}

export default OfficeIntroDetails;
