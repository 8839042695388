import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Image, Keyboard, TouchableOpacity, View} from 'react-native'
import {
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator
} from "components/src/components";
//Utils
import styles from './styles';

import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    Icon,
    IS_WEB,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {OFFICE_SERVICE_DETAILS} from "../../../../../../api/query";
import {
    DELETE_OFFICE_SERVICE_MEDIA,
    OFFICE_REPLACE_3D_PHOTO_IMAGE,
    OFFICE_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES,
    SAVE_OFFICE_TEMPLATE_SERVICE
} from "../../../../../../api/mutation";
import PhotoDetails from "../../../../../../components/view/PhotoDetails";
const scrollY = new Animated.Value(0);

function OfficePhoto(props) {

    const {history, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    /*if (!serviceFromPage) {
        IS_WEB ? history.replace(`/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}`) : navigation.goBack();
    }*/
    const [service, setService] = useState(serviceFromPage?.office_template ?? {});

    const [headLines, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);

    const [introText, setIntroText] = useState("");
    const [errIntroText, setErrIntro] = useState(null);

    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const [servicePrice, setServicePrice] = useState([]);
    const [errServicePrice, setErrServicePrice] = useState({});

    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);

    const [deleteVisible, setDeleteVisible] = useState(false)

    const [imageForPreview, setImageForPreview] = useState({})

    const [saveServiceDetails, saveDetailsMutation] = useMutation(SAVE_OFFICE_TEMPLATE_SERVICE);


    const [replaceImage, replaceImageMutation] = useMutation(OFFICE_REPLACE_3D_PHOTO_IMAGE, {
        refetchQueries: [{
            query: OFFICE_SERVICE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
            }
        }]
    });

    const [uploadImages, uploadImagesMutation] = useMutation(OFFICE_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES, {
        refetchQueries: [{
            query: OFFICE_SERVICE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
            }
        }]
    });

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_OFFICE_SERVICE_MEDIA, {
        refetchQueries: [{
            query: OFFICE_SERVICE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
            }
        }]
    });

    const {loading, data, error} = useQuery(OFFICE_SERVICE_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
        }
    });

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);

    useEffect(() => {
        if (data?.office_template_details) {
            setService(data?.office_template_details);
            setHeadLines(data?.office_template_details?.headline);
            setIntroText(data?.office_template_details?.description);
            let price = data?.office_template_details?.office_services?.map(item => ({
                id: item.id,
                is_free: item.is_free,
                price: parseInt(item.price),
                free_text: item?.free_text ?? ""
            }));
            setServicePrice(price)
        }
    }, [data]);

    const validate = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('headline', headLines);
        let errIntro = validation('intro', introText);
        let errPrice = {};
        let isError = false;
        servicePrice.map(item => {
            errPrice[item.id] = validation('servicePrice', item.price);
            if (errPrice[item.id]) {
                isError = true
            }
        });
        if (!errHead && !isError && !errIntro) {
            saveServiceDetails({
                variables: {
                    input: {
                        office_template_id: service?.id,
                        headline: headLines,
                        intro_text: introText,
                        /*    video:
                        iframeLink: String*/
                        servicesPrice: servicePrice.map(item => ({
                            ...item,
                            is_free: item.is_free ? 1 : 0,
                            price: parseInt(item.price)
                        }))
                    }
                }
            }).then((data) => {
                IS_WEB ? history.goBack() : navigation.goBack()
            }).catch(() => {

            })
        } else {
            setErrHeadLines(errHead);
            setErrIntro(errIntro);
            setErrServicePrice(errPrice)
        }
    };

    const handleChangeText = (text, id) => {
        let updatedService = servicePrice.slice();
        updatedService[id].price = text;
        setServicePrice(updatedService)
    };

    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={[
            {
                text: `${serviceFromPage?.name} ${Strings.details}`
            }, {
                text: Strings.imageGallery
            }]}/>;
    };

    const renderInput = (item, index) => {
        return (
            <View key={index.toString()}>
                <Label font_medium>
                    {item?.name}
                </Label>
                <Label small mt={ThemeUtils.relativeRealHeight(2)}>
                    {item?.description}
                </Label>
                <InputField mt={20}
                            error={errServicePrice[item.id]}
                            onFocus={() => setErrServicePrice({...errServicePrice, [item.id]: null})}
                            labelProps={{
                                small: true,
                                font_medium: true
                            }}
                            type={'text'}
                            value={item?.is_free ? servicePrice[index]?.free_text : servicePrice[index]?.price}
                            onChange={(text) => handleChangeText(text, index)}>

                    {item.is_free ? null : (<View style={CommonStyle.inputExtra}>
                        <Label font_medium>
                            {'Kr.'}
                        </Label>
                    </View>)}
                </InputField>
            </View>
        )
    };

    const renderPhotoDetails = () => {
        return (
            <>
                <Label font_medium>
                    {Strings.headLine}
                </Label>
                <InputField disabled
                    mt={10}
                    align={'center'}
                    type={'text'}
                    error={errHeadline}
                    onFocus={() => setErrHeadLines(null)}
                    value={headLines}
                    onChange={setHeadLines}
                />
                <Label font_medium>
                    {Strings.introText}
                </Label>
                <InputField
                    error={errIntroText}
                    onFocus={() => setErrIntro(null)}
                    mt={10}
                    align={'center'}
                    type={'text'}
                    textArea
                    value={introText}
                    onChange={setIntroText}
                />


                {service?.office_services?.length ? (
                    <>
                        <Hr/>
                        <Label mt={ThemeUtils.relativeRealHeight(2)}
                               mb={ThemeUtils.relativeRealHeight(2)}
                               font_medium
                               large>
                            {`${Strings.services}:`}
                        </Label>
                        {service?.office_services?.map(renderInput)}
                    </>
                ) : null}


            </>
        );
    };

    const renderPhotoGallery = () => {
        return (
            <>
                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>

                <FilePicker multiple
                            style={{alignSelf: 'flex-start'}}
                            type={FileType.IMAGE}
                            onFilePicked={(data, files) => {
                                console.log(files)
                                if (files.length) {
                                    uploadImages({
                                        variables: {
                                            templateId: service?.id,
                                            images: files,
                                            type: service.service_type_id === 1 ? Constants.UploadMediaType.PHOTO : Constants.UploadMediaType.THREE_D
                                        }
                                    }).catch(() => {

                                    })
                                }
                            }}>
                    <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                        {uploadImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<Icon name={'add'}
                                   size={ThemeUtils.responsiveScale(60)}
                                   color={Color.PRIMARY_LIGHT}/>)}

                    </View>
                </FilePicker>
                <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={deleteVisible}
                                onNegativePress={() => setDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteImages({
                                        variables: {
                                            ids: selectedImages,
                                            templateId: service?.id
                                        }
                                    }).then(() => {
                                        setSelectedImages([])
                                    }).catch(() => {

                                    })
                                    setDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}
                {renderImageGrid(service?.office_service_media ?? [])}
            </>
        );
    };

    const renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                        onPress={() => {
                            if (selectionEnable) {
                                addOrRemoveImage(item.id)
                            } else {
                                setImageForPreview(item)
                                setPhotoDetailVisible(true)
                            }
                        }}
                        activeOpacity={0.7}>

                        {selectionEnable && selectedImages.includes(item.id) ? (<View style={[styles.selectedImage]}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.TERNARY_TEXT_COLOR}/>
                        </View>) : null}

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}

                               style={styles.GridImage}/>
                    </TouchableOpacity>

                )
            })}
        </View>
    };

    return (
        <View style={styles.cont}>
            <Header tintColor={Color.PRIMARY}
                    style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>

            <View>
                <View style={styles.contentCont}>
                    <View style={styles.titleCont}>
                        <Label xlarge
                               font_medium
                               mt={5}
                               ml={10}>
                            {serviceFromPage?.name}
                        </Label>
                    </View>
                </View>

            </View>
            <IndicatorViewPager
                indicator={_renderTabIndicator()}
                keyboardShouldPersistTaps={'always'}
                style={{flex: 1, backgroundColor: 'white'}}>
                <View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <>
                            <Animated.ScrollView scrollEventThrottle={1}
                                                 keyboardShouldPersistTaps={'always'}
                                                 contentContainerStyle={styles.flexGrow}
                                                 onScroll={Animated.event(
                                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                     {useNativeDriver: true})}>
                                <View>
                                    <View style={styles.orderCont}>
                                        {renderPhotoDetails()}
                                    </View>
                                </View>
                            </Animated.ScrollView>
                            <View style={styles.bottomButtonContainer}>
                                <CustomButton loading={saveDetailsMutation.loading}
                                              style={{flex: 0.45}}
                                              title={Strings.save}
                                              onPress={validate}/>
                                <CustomButton
                                    style={{flex: 0.45}}
                                    title={Strings.cancel}
                                    bgColor={Color.WHITE}
                                    textColor={Color.PRIMARY_TEXT_COLOR}
                                    borderColor={Color.PRIMARY}
                                    borderWidth={1}
                                    onPress={() => {
                                        if (!IS_WEB) {
                                            Keyboard.dismiss()
                                        }
                                        IS_WEB ? history.goBack() : navigation.goBack()
                                    }}/>
                            </View>

                        </>
                    )}

                </View>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            <View style={styles.orderCont}>
                                <Modal visible={isPhotoDetailVisible}
                                       onRequestClose={() => setPhotoDetailVisible(false)}>
                                    <PhotoDetails onClose={() => setPhotoDetailVisible(false)}
                                                  loading={replaceImageMutation.loading}
                                                  onReplaceImage={replaceImage}
                                                  replaceVariables={{
                                                      id: imageForPreview?.id,
                                                      type: service.service_type_id === 1 ? Constants.UploadMediaType.PHOTO : Constants.UploadMediaType.THREE_D,
                                                      templateId: service?.id
                                                  }}
                                                  variables={{
                                                      ids: [imageForPreview?.id],
                                                      templateId: service?.id
                                                  }}
                                                  imageForPreview={imageForPreview}
                                    />
                                </Modal>
                                {renderPhotoGallery()}
                            </View>
                        </View>
                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {}
};
export default connect(mapStateToProps)(OfficePhoto);
