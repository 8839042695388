import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
    },
    contentCont:{
        flexGrow:1,
        marginHorizontal: ThemeUtils.relativeRealWidth(6),
    },
    scrollCont: {
        flexGrow: 1,
        backgroundColor:Color.WHITE
    },
    icMaterial: {
        marginVertical: 20,
    },
    materialCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingStart: 15,
        paddingEnd:10,
        paddingVertical: 6,
        marginBottom: 8,
        marginTop:15,
        borderRadius: 4,
        backgroundColor: Color.DARK_GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 10,
        justifyContent: 'space-between'
    },
    icFiles:{
        padding:8,
    },
    lblAprovedDate:{
        backgroundColor:Color.DARK_GRAY,
        paddingVertical:8,
        borderRadius: 4,
        marginVertical:15
    },
    btnCont:{
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom:25,
        flexDirection: 'row'
    },
    uploadView: {
        alignSelf: 'flex-start',
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: Color.INPUT_BORDER,
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: 6
    },

    UploadedMainView: {
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        justifyContent: 'space-between',
        backgroundColor: Color.UPLOAD_BG_COLOR,
        borderRadius: 6,
        marginTop:25
    },
    fieldContainer: {
        alignContent: 'center',
    },
    labeliconView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },

    energyradio: {
        marginVertical: 10,
    },
    IconView:
        {
            marginLeft: 2,
            flexDirection: 'row',
            alignItems: 'center',


        },
    IconViews:
        {
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around'
        },
    DeleteIcon: {marginLeft: 7},
    DownloadIcon: {marginRight: 7},
    checkbox:{alignItems:'flex-start',flex:1,flexDirection:'column',marginTop:15,alignContent:'center',},
    Financingradio:{marginBottom:20}

})
