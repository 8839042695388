export default {
  'component.settlement.title.line1': 'Kontrakt og Oppgjørsoppdrag Fastpris kr. 12.500,-',
  'component.settlement.title.line2': 'Hva er inkludert?',
  'component.settlement.title.line3': 'Jeg vil bli kontaktet om eiendomsoppgjør!',
  'component.settlement.description.line1':
    'Har du funnet kjøper til bolig din, men trenger et eiendomsoppgjør? Vi hjelper deg gjerne og sikrer deg en trygg prosess',
  'component.settlement.description.line2':
    'Vi bistår med utforming av kjøpekontrakt og sletting av heftelser som er nødvendig for oppgjøret ditt. Våre meglere vil også tinglyse obligasjoner for kjøpers transaksjon og foretar avregning mellom partene før utbetaling av sluttoppgjøret av din bolig.',
  'component.settlement.features.line1':
    'Kontroll av heftelser i grunnboken og sletting av pengeheftelser',
  'component.settlement.features.line2':
    'Sjekker restanser, legalpant, felleskostnader, festeavgift og kommunale avgifter',
  'component.settlement.features.line3': 'Kontroll av konsesjonsforhold',
  'component.settlement.features.line4': 'Eierskiftemelding til evt. forretningsfører',
  'component.settlement.features.line5': 'Utarbeidelse av kjøpekontrakt og skjøte',
  'component.settlement.features.line6': 'Avholder kontraktsmøtet',
  'component.settlement.features.line7': 'Tilbyr selger eierskifteforsikring',
  'component.settlement.features.line8':
    'Sikre at kjøper betaler inn kjøpesum og omkostninger før overtagelse',
  'component.settlement.features.line9': 'Tinglysning av skjøtet og evt. kjøpers pantobligasjon',
  'component.settlement.features.line10':
    'Oppgjør av kjøpesummen og evt. inneståelseserklæring til kjøpers bank',
  'component.settlement.features.line11': 'Innfrielse og sletting av selgers lån',
  'component.settlement.features.line12': 'Utbetaling av kjøpesum',
  'component.settlement.form.checkbox':
    'JA TAKK, send meg informasjon og nyheter om boligmarkedet. Jeg godtar også Webmegling sine vilkår for behandling av persondata. Mer info i personvernerklæringen her.',
  'component.form.newsletter': 'Nyhetsbrev',
};
