import React from 'react';
import {StyleSheet, TouchableHighlight, TouchableOpacity, View} from 'react-native';
import {Label} from '../../../components'

import PropTypes from 'prop-types';
import {Color} from '../../../utils/Color';

class SquareButton extends React.Component {
    onClick = () => {
        if (this.props.onPress) {
            this.props.onPress();
        }
    };

    render() {
        let borderColor = this.props.borderColor
            ? this.props.borderColor
            : this.props.backgroundColor;
        return (
            <View style={[this.props.style]}>
                <TouchableOpacity activeOpacity={0.7}
                                  style={[
                                      styles.squareButton_Container,
                                      {
                                          backgroundColor: this.props.backgroundColor,
                                          borderColor: borderColor,
                                      }, this.props.containerStyle
                                  ]}
                                  rippleContainerBorderRadius={5}
                                  rippleColor={this.props.rippleColor}
                                  onPress={this.onClick}>
                    <Label small
                           font_medium
                           ml={10}
                           mr={10}
                           style={styles.squareButton_Text}
                           color={this.props.textColor}>
                        {this.props.children}
                    </Label>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    squareButton_Container: {
        alignItems: 'center',
        paddingVertical: 4,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 5,
        borderWidth: 1,
    },
    squareButton_Text: {
        padding: 5,
    },
});


SquareButton.defaultProps = {
    ...TouchableHighlight.defaultProps,
    backgroundColor: Color.PRIMARY,
    rippleColor: Color.COLOR_ACCENT,
    textColor: Color.PRIMARY_TEXT_COLOR,
};
SquareButton.propTypes = {
    ...TouchableHighlight.propTypes,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
};
export default SquareButton;
