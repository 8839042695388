import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop: ThemeUtils.relativeRealHeight(1.5),
    },
    titleContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {

        marginHorizontal: containerHorizontalMargin,
        marginBottom: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    optionContainer: {
        marginVertical: containerHorizontalMargin / 2,
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    iconView: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dividerLine: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },

    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    radio: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 10
    },
    SubViewIcon: {
        backgroundColor: Color.DARK_GRAY,
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        maxHeight: 200,
        maxWidth: 200,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        borderWidth: 1,
        borderColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {
        height: ThemeUtils.responsiveScale(120),
        width: ThemeUtils.responsiveScale(120),
        maxHeight: 120,
        maxWidth: 120,
        backgroundColor: Color.GRAY,
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    introTextCont: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: containerHorizontalMargin,
    },
    orderCont: {
        marginHorizontal: containerHorizontalMargin,
        marginTop: 15,
    },
    ImageGridView: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: 20
    },
    GridImage: {
        marginEnd: ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2,
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        maxHeight: 200,
        maxWidth: 200,
        marginTop: ThemeUtils.relativeWidth(2.8),
        borderWidth: 0.5,
        borderColor: Color.PRIMARY_LIGHTER
    },
    selectedImage: {
        padding: 10,
        position: 'absolute',
        top: ThemeUtils.relativeWidth(2.8),
        height: ThemeUtils.responsiveScale(200),
        width: ThemeUtils.responsiveScale(200),
        borderRadius: ThemeUtils.relativeRealWidth(2.5),
        backgroundColor: Color.PRIMARY_LIGHT
    },
    propertySelectionCont: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
    },
});

export default styles;
