import React, {useState} from "react";
//Components
import {Animated, Image, SafeAreaView, ScrollView, TouchableOpacity, View} from "react-native";
import {Header, Label, LogoForHeader, Modal, ZoomView} from "components/src/components";
//Third party
//redux
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, Strings, ThemeUtils,} from "components/src/utils";
import {styles} from "./styles.js";
import FlatGrid from "../../../../components/view/Dashboard/FlatGrid";

const TABS = [{
    text: Strings.details
}, {
    text: Strings.imageGallery
}];

export default function ProductGallery(props) {
    const {navigation, history} = props;
    const scrollY = new Animated.Value(0);
    let details = IS_WEB ? props.match?.params?.details ?? {} : navigation.getParam('details', {})
    details = JSON.parse(atob(details))

    const [selectedImage, setSelectedImages] = useState(0);

    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);


    const [images, setImages] = useState(details?.ProductGalleryImages ?? []);
    console.log(details?.ProductGalleryImages, "DETAILS")
    const [imageForPreview, setImageForPreview] = useState({});


    const next = () => {
        let image = selectedImage;
        if (selectedImage < images.length) {
            setSelectedImages(++image)
        }
    };

    const prev = () => {
        let image = selectedImage;
        if (selectedImage > 0) {
            setSelectedImages(--image)
        }
    };

    const renderImageGrid = (item, index) => {
        return (
            <TouchableOpacity
                key={item.id}

                onPress={() => {
                    setImageForPreview(item);
                    setPhotoDetailVisible(true)
                }}
                activeOpacity={0.7}>

                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.url}`}}
                       style={styles.GridImage}/>
            </TouchableOpacity>
        )

    };

    const renderPhtoDetailsModal = () => {
        return (
            <Modal visible={isPhotoDetailVisible}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header renderTitle={() => <LogoForHeader/>}
                                headerLeft={'close'}
                                tintColor={Color.SECONDARY}
                                onPressLeft={() => {
                                    setPhotoDetailVisible(false)
                                }}
                        />
                        <ZoomView>
                            <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${imageForPreview?.url}`}}
                                   resizeMode={'contain'}
                                   style={CommonStyle.flex}/>
                        </ZoomView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    const renderBottomImages = (item, index) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => setSelectedImages(index)}>
                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.url}`}}
                       style={styles.imgBottom}/>
            </TouchableOpacity>

        )
    };

    const renderPhotoGallery = () => {
        return (
            <>
                <Label font_medium
                       mt={15}
                       mb={5}
                       ml={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(12) : ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {details?.headline}
                </Label>
                {ThemeUtils.isTablet() ? (
                    <View>
                        <View style={CommonStyle.flex}>
                            <View style={styles.imgCont}>
                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={prev}
                                                  style={styles.icCont}>
                                    <Icon name={'arrow-l'}
                                          size={ThemeUtils.fontXLarge}
                                          color={Color.WHITE}/>
                                </TouchableOpacity>
                                {console.log(`${Constants.APIConfig.STORAGE_URL}${images[selectedImage]?.url}`)}
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${images[selectedImage]?.url}`}}
                                       style={{height: 200, flex: 1}}/>
                                <TouchableOpacity activeOpacity={0.5}
                                                  onPress={next}
                                                  style={styles.icContNext}>
                                    <Icon name={'arrow-r'}
                                          size={ThemeUtils.fontXLarge}
                                          color={Color.WHITE}/>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.scrollCont}>
                                <ScrollView horizontal>
                                    {images.map(renderBottomImages)}
                                </ScrollView>
                            </View>
                        </View>

                    </View>
                ) : (<FlatGrid itemDimension={ThemeUtils.responsiveScale(200)}
                               data={images}
                               renderItem={({item, index}) => renderImageGrid(item, index)}
                />)}
                {/*{renderImageGrid(images)}*/}
            </>
        );
    };

    return (
        <View style={CommonStyle.flex}>
            {renderPhtoDetailsModal()}
            <Header renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>


            {renderPhotoGallery()}

        </View>
    );
}
