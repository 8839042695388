import React, {useEffect, useState} from 'react'
import {Animated, Image, View} from 'react-native'
import {CustomButton, IndicatorViewPager, Label, Ripple} from "components/src/components";
import {
    Color,
    CommonStyle,
    Constants,
    formatPrice,
    Icon,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {useMutation} from "@apollo/react-hooks";
import styles from './styles';
import {PROPERTY_SERVICES} from "../../../../../../../../api/mutation";

function Photography(props) {

    const {data, inspection, isCompanyTemplate, packages} = props;

    const LINKS = isCompanyTemplate ? data?.office_service_media ?? [] : data?.agent_service_media ?? []
    const IMAGES = LINKS?.filter(item => item?.media_type !== "iframe")

    const [selectedService, setSelectedService] = useState(data?.purchased_office_services?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id));

    const [serviceInPackage, setServiceInPackage] = useState([]);

    useEffect(() => {
        let service = []
        packages.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [packages])

    const addOrRemoveItemFromArray = (item) => {
        let updatedService = selectedService.slice();
        if (updatedService.includes(item.id)) {
            updatedService.splice(updatedService.indexOf(item.id), 1)
            saveServices({
                variables: {
                    input: {
                        property_id: inspection?.property_id,
                        unselect_pro_service_ids: item.service_selected?.id,
                        broker_on_view:false
                    }
                }
            }).catch(() => {
            })
        } else {
            updatedService.push(item.id)
        }
        saveServices({
            variables: {
                input: {
                    property_id: inspection?.property_id,
                    service_ids: updatedService,
                    broker_on_view:false
                }
            }
        }).catch(() => {
        })
        setSelectedService(updatedService)
    }

    const [selectedImage, setSelectedImage] = useState(1);

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {
            props.refetch()
        }
    });

    const addIcon = (title) => {
        return (
            <>
                <Icon
                    name={"open-externally"}
                    size={ThemeUtils.fontSmall}
                    color={Color.WHITE}
                    style={styles.iconOpenFullScreen}
                />
                {` ${Strings.openFullScreen} `}
            </>
        );
    };

    const renderService = (item, index, self) => {
        let isSelected = selectedService.includes(item.id) || serviceInPackage.includes(item.id)
        return (
            <Ripple
                style={[styles.serviceCont, isSelected ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => serviceInPackage.includes(item.id) ? null : addOrRemoveItemFromArray(item)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };


    return (
        <View style={styles.container}>

            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        <View style={styles.imgCountCont}>
                            <Label small
                                   color={Color.WHITE}>
                                {` ${selectedImage} / ${IMAGES.length} `}
                            </Label>
                        </View>
                        <IndicatorViewPager style={CommonStyle.flex}
                                            onPageSelected={(e) => setSelectedImage(e.position + 1)}>
                            {IMAGES.map(item => (
                                <View>
                                    <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}
                                           style={CommonStyle.flex}/>
                                </View>
                            ))}
                        </IndicatorViewPager>

                    </View>


                    <View style={styles.lblCont}>
                        <Label large
                               font_medium>
                            {data?.headline}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR}
                               mt={10}
                               mb={10}>
                            {data?.description}
                        </Label>
                        {data?.purchased_office_services?.map(renderService)}
                        <CustomButton onPress={() => {
                            let link = LINKS?.find(item => item?.media_type === "iframe")
                            link && openInNewTab(link?.media_url)
                        }}
                                      title={addIcon()} mt={15} font_regular small
                                      bgColor={Color.SECONDARY}/>
                    </View>

                </View>
            </Animated.ScrollView>
        </View>

    )
}

export default Photography
