/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import {FRONT_URL,InsuranceType} from '../../../utils/constant';
import {decryptId, encryptId, formatCurrency, getDecryptedId, getWmeglingInnerLogo} from '../../../utils/utils';
import {Button, Checkbox, Col, notification, Row, Typography} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
// import {SELLER_SUBMIT_AGREEMENT} from '../../../../mutation/SellerMutation';\
import {Mutation, Query, useQuery} from 'react-apollo';
import {OWNERSIGN_AGREEMENT} from '../../../../mutation/AgentMutation';
import {FIRST_AGREEMENT, INSURANCE_DETAILS, PROPERTY_DETAIL, SELECTED_SERVICE} from '../../../../query/AgentQuery';
import Strings from '../../../../locales/nb-NO';
import BefaringLayout from "../../../layouts/BefaringLayout";
import {useHistory} from "react-router";

const formatMessage = ({id}) => {
    return Strings[id]
}
const {Title} = Typography;

const options = [
    {
        label: 'Jeg bekrefter jeg har lest og forstått innholdet i oppdragsavtalen.',
        value: 'confirm',
    },
];

const AssignmentAgreement1 = props => {
    let router = useHistory();
    const psServices = [];
    const dmServices = [];
    const pmServices = [];

    const [checkBoxValue, setCheckBoxValue] = useState(false);
    // order of mission cost
    const [allPsServices, setAllPsServices] = useState([]);
    const [allDmServices, setAllDmServices] = useState([]);
    const [allPmServices, setAllPmServices] = useState([]);
    const [allSelectedUtleggServices, setAllSelectedUtleggServices] = useState([]);
    const [allSelectedVederlagServices, setAllSelectedVederlagServices] = useState([]);
    const [allMarketingPackages, setAllMarketingPackages] = useState([]);
    const [marketingPackagesTotal, setMarketingPackagesTotal] = useState(parseInt(0));
    const [insuranceList, setInsuranceList] = useState([]);

    const [renumeration, setRenumeration] = useState([]);
    const [psTotal, setPsTotal] = useState(parseInt(0));
    const [dmTotal, setDmTotal] = useState(parseInt(0));
    const [pmTotal, setPmTotal] = useState(parseInt(0));
    const [utleggTotal, setUtleggTotal] = useState(parseInt(0));
    const [vederlagTotal, setVederlagTotal] = useState(parseInt(0));
    const {match} = props;
    const {params} = match;

    const getPropertyType = type => {
        switch (type) {
            case 0:
                return 'Selveier';
            case 1:
                return 'Andelsleilighet';
            case 2:
                return 'Aksjeleilighet';
            case 3:
                return 'Obligasjonsleilighet';
            case 4:
                return 'Eierseksjon';
        }
    };

    const propertyDetails = useQuery(PROPERTY_DETAIL, {
        variables: {
            id: decryptId(params.propertyId),
        },
    });

    const insuranceDetailsQuery = useQuery(INSURANCE_DETAILS, {
        variables: {
            propertyId: decryptId(params.propertyId)
        }
    });

    React.useEffect(() => {
        if (insuranceDetailsQuery.data && !insuranceDetailsQuery.loading) {
            console.log("Insurance data==>", insuranceDetailsQuery?.data?.boligselger_Insurance_list);
            setInsuranceList(insuranceDetailsQuery?.data?.boligselger_Insurance_list ?? []);
        }
    }, [insuranceDetailsQuery.data])

    const getPropertyTypePrefix = type => {
        switch (type) {
            case 0:
            case 3:
            case 4:
                return `Gnr. ${propertyDetails?.data?.property?.gnr ?? ''}, Bnr. ${propertyDetails?.data
                    ?.property?.bnr ?? ''}${
                    propertyDetails?.data?.property?.fnr
                        ? `, Fnr. ${propertyDetails?.data?.property?.fnr}` ?? ''
                        : ''
                    }${
                    propertyDetails?.data?.property?.fnr
                        ? `, Snr. ${propertyDetails?.data?.property?.snr}` ?? ''
                        : ''
                    } | ${propertyDetails?.data?.property?.municipality} kommune`;
            case 1:
                return `Andelsnr. ${propertyDetails?.data?.property?.partNumber ?? ''} | ${propertyDetails
                    ?.data?.property?.partName ?? ''}, org.nr ${propertyDetails?.data?.property
                    ?.partOrgNumber ?? ''} | ${propertyDetails?.data?.property?.municipality} kommune`;
            case 2:
                return `Aksjenr. ${propertyDetails?.data?.property?.estateHousingCooperativeStockNumber ??
                ''} | ${propertyDetails?.data?.property?.partNumber ?? ''}  | ${
                    propertyDetails?.data?.property?.municipality
                    } kommune`;
        }
    };

    const CallAgreement = (CallAgreement, owner) => {
        console.log(owner);
        const {match, location} = props;
        const {params} = match;
        if (checkBoxValue) {
            let cancelEndPoint = location.pathname.substring(1);
            CallAgreement({
                variables: {
                    input: {
                        property_id: +decryptId(params.propertyId),
                        redirect_url:
                            owner >= 2
                                ? `${FRONT_URL}befaring/${encryptId(
                                JSON.stringify({id: +decryptId(params.propertyId)}),
                                )}/oppdragsavtale2`
                                : `${FRONT_URL}befaring/gratulerer/${encryptId(
                                JSON.stringify({
                                    total_owners: owner,
                                    is_email: 0,
                                    skip_co_owner: 0,
                                    property_id: +decryptId(params.propertyId),
                                }),
                                )}`,
                        cancel_url: `${FRONT_URL}${cancelEndPoint}`,
                        owner_number: 1,
                    },
                },
            }).then(data => {
                if (data) {
                    const response = JSON.parse(data?.data.owner_sign_agreement.ownerAgreement_sign);
                    window.open(response?.owner_1?.AuthenticationUrl, '_blank');
                    /* router.push(owner >= 2
                         ? `/befaring/${encryptId(
                             JSON.stringify({ id: +decryptId(params.propertyId) }),
                         )}/oppdragsavtale2`
                         : `/befaring/gratulerer/${encryptId(
                             JSON.stringify({
                                 total_owners: owner,
                                 is_email: 0,
                                 skip_co_owner: 0,
                                 property_id: +decryptId(params.propertyId),
                             }),
                         )}`);*/

                }
            });
        } else {
            notification.error({
                message: 'Du må bekrefte at du har lest og forstått innholdet i oppdragsavtalen',
                description: '',
                duration: 2,
            });
        }
    };

    const setPhotoServices = data => {
        let total = 0;
        const selectedPackage = data?.selected_property_services?.marketing_package
            ? data?.selected_property_services?.marketing_package
                ?.find(item => item.property_package)
                ?.marketing_package_service?.map(item => item.office_service.id)
            : [];
        /*data?.selected_property_services?.services?.map(service => {
          const tempPSServices = service.purchased_office_template?.purchased_office_services?.filter(
            item =>
              item.service_selected &&
              !selectedPackage?.includes(item.id) &&
              item.service_selected.property_id === +decryptId(params.propertyId),
          );
          tempPSServices?.map(i => psServices.push(i));
        });*/
        data?.selected_property_services?.services?.map((service, index) => {
            let vendorServices = [];
            if (index === 0) {
                vendorServices = data?.selected_property_services?.service_types[0];
            }
            if (index === 1) {
                vendorServices = data?.selected_property_services?.service_types[1];
            }
            if (index === 3) {
                vendorServices = data?.selected_property_services?.service_types[2];
            }
            if (index === 4) {
                vendorServices = data?.selected_property_services?.service_types[3];
            }
            if (vendorServices?.vendor_services?.length) {
                vendorServices?.vendor_services
                    ?.filter(
                        item =>
                            item.service_selected &&
                            item.service_selected.property_id === +decryptId(params.propertyId),
                    )
                    .map(item => {
                        psServices.push(item);
                    });
            } else {
                service.purchased_office_template?.purchased_office_services
                    ?.filter(
                        item =>
                            item.service_selected &&
                            !selectedPackage?.includes(item.id) &&
                            item.service_selected.property_id === +decryptId(params.propertyId),
                    )
                    .map(item => {
                        psServices.push(item);
                    });
            }
        });
        setAllPsServices(psServices);
        total += psServices.reduce((a, {price}) => a + parseInt(price), 0);
        setPsTotal(total);
        console.log('ps final total', psTotal);
    };

    const setDigitalServices = data => {
        let total = 0;
        const selectedPackage = data?.selected_property_services?.marketing_package
            ? data?.selected_property_services?.marketing_package
                ?.find(item => item.property_package)
                ?.marketing_package_service?.map(item => item.office_service.id)
            : [];
        data?.selected_property_services?.services?.map(service => {
            const tempDMServices = service.purchased_office_template?.purchased_office_services?.filter(
                item =>
                    item.service_selected &&
                    !selectedPackage?.includes(item.id) &&
                    item.service_selected.property_id === +decryptId(params.propertyId),
            );
            tempDMServices?.map(i => dmServices.push(i));
        });
        setAllDmServices(dmServices);
        total += dmServices.reduce((a, {price}) => a + parseInt(price), 0);
        setDmTotal(total);
        console.log('dm final total', dmTotal);
    };

    const setPrintServices = data => {
        let total = 0;
        const selectedPackage = data?.selected_property_services?.marketing_package
            ? data?.selected_property_services?.marketing_package
                .find(item => item.property_package)
                ?.marketing_package_service?.map(item => item.office_service.id)
            : [];
        data?.selected_property_services?.services?.map(service => {
            const tempPMServices = service.purchased_office_template?.purchased_office_services?.filter(
                item =>
                    item.service_selected &&
                    !selectedPackage?.includes(item.id) &&
                    item.service_selected.property_id === +decryptId(params.propertyId),
            );
            tempPMServices?.map(i => pmServices.push(i));
        });
        setAllPmServices(pmServices);
        total += pmServices.reduce((a, {price}) => a + parseInt(price), 0);
        setPmTotal(total);
        console.log('pm final total', pmTotal);
    };

    const setPropertyUtlegg = data => {
        let utleggTotal = 0;
        let vederlagTotal = 0;
        const tempUtleggServices = data?.selected_property_services?.property_utlegg
            ?.filter(
                item =>
                    item.usableServiceClass &&
                    item.usableServiceClass.property_id === +decryptId(params.propertyId),
            )
            .sort((a, b) => b.is_default - a.is_default);
        setAllSelectedUtleggServices(tempUtleggServices.filter(item => item.price_type === 'Utlegg'));
        setAllSelectedVederlagServices(
            tempUtleggServices.filter(item => item.price_type === 'Vederlagg'),
        );
        utleggTotal += tempUtleggServices
            .filter(item => item.price_type === 'Utlegg')
            .reduce((a, {price}) => a + parseInt(price), 0);
        vederlagTotal += tempUtleggServices
            .filter(item => item.price_type === 'Vederlagg')
            .reduce((a, {price}) => a + parseInt(price), 0);
        setUtleggTotal(utleggTotal);
        setVederlagTotal(vederlagTotal);
    };

    const setMarketingPackage = data => {
        let packageTotal = 0;
        const selectedMarketingPackageList = data?.selected_property_services?.marketing_package?.filter(
            item =>
                item.property_package &&
                item.property_package.property_id === +decryptId(params.propertyId),
        );
        packageTotal += selectedMarketingPackageList.reduce(
            (a, item) => a + parseInt(item?.property_package?.price),
            0,
        );
        setAllMarketingPackages(selectedMarketingPackageList);
        setMarketingPackagesTotal(packageTotal);
    };

    // const setRemunerations = data => {
    //   const tempRenumeration = data?.selected_property_services?.remunerations?.filter(item => item.usableServiceClass &&
    //     item.usableServiceClass.property_id === +decryptId(params.propertyId))
    //   setRenumeration(tempRenumeration);
    // }

    return (
        <BefaringLayout>
            <div className="px-3">
                <Query
                    query={FIRST_AGREEMENT}
                    fetchPolicy="network-only"
                    variables={{
                        propertyId: +decryptId(params.propertyId),
                        ownerNo: 1,
                    }}
                >
                    {({ data, error, loading, fetchMore, refetch }) => {
                        let totalRenumeration = 0;
                        let totalPre = 0;
                        const firstAgent = data?.property_agreement_details?.property?.property_agent.find(
                            item => item?.brokerRole === 1,
                        );
                        const secondAgent = data?.property_agreement_details?.property?.property_agent.find(
                            item => item?.brokerRole === 2,
                        );
                        const thirdAgent = data?.property_agreement_details?.property?.property_agent.find(
                            item => item?.brokerRole === 3,
                        );
                        const primarySeller = data?.property_agreement_details?.property_seller.find(
                            item => item.is_primary_seller,
                        );
                        const secondSeller = data?.property_agreement_details?.property_seller.find(
                            item => !item.is_primary_seller,
                        );

                        if (
                            data?.property_agreement_details?.property_remuneration?.usableService?.cost_type ===
                            'FIX'
                        ) {
                            totalRenumeration = parseInt(
                                data.property_agreement_details.property_remuneration.price,
                            );
                        } else if (
                            data?.property_agreement_details?.property_remuneration?.usableService?.cost_type ===
                            'HOURLY'
                        ) {
                            totalRenumeration =
                                data?.property_agreement_details?.property_remuneration?.price &&
                                data?.property_agreement_details?.property_remuneration?.usableService?.hours
                                    ? parseInt(
                                    data.property_agreement_details.property_remuneration.price *
                                    data?.property_agreement_details?.property_remuneration?.usableService?.hours,
                                    )
                                    : 0;
                        }

                        // totalRenumeration += data?.property_agreement_details?.property_service
                        //   ?.map(item => parseInt(item.price))
                        //   .reduce((a, b) => a + b, 0)
                        if (
                            data?.property_agreement_details?.property?.property_preference?.percentage !== null
                        ) {
                            totalPre =
                                +data?.property_agreement_details?.property?.property_preference?.preferable_price *
                                (+data?.property_agreement_details?.property?.property_preference?.percentage / 100);
                        }

                        const greyOutSection =
                            data?.property_agreement_details?.property_remuneration?.usableService?.cost_type ===
                            'HOURLY';

                        const fixPriceGreyColor =
                            data?.property_agreement_details?.property_remuneration?.usableService?.cost_type ===
                            'FIX';
                        const totalData = data?.property_agreement_details?.property_service
                            ?.map(item => parseInt(item.price))
                            .reduce((a, b) => a + b, 0);

                        return (
                            <Row className="min-vh100 getstarted-subpages">
                                <Col xs={24} lg={{ span: 8, offset: 8 }}>
                                    <div className="get-started-subpages-wrapper mt-30">
                                        <div className="text-center">
                                            <img
                                                src={getWmeglingInnerLogo()}
                                                alt="logo"
                                                className="img-fluid mb-8"
                                                style={{ maxWidth: '19%' }}
                                            />
                                            <Title className="text-secondary " level={2}>
                                                Avtale om salg av{' '}
                                                {`${data?.property_agreement_details?.property?.street_adress ??
                                                ''}, ${propertyDetails?.data?.property?.zipcode ?? ''} ${data
                                                    ?.property_agreement_details?.property?.city ?? ''}`}
                                            </Title>
                                            <p className="f-500 text-large">Din kontaktperson: </p>
                                            <p>{firstAgent?.name ?? ''}</p>
                                            <p>{firstAgent?.title ?? ''}</p>
                                            <p>Mobil:{firstAgent?.mobilePhone ?? ''}</p>
                                            <p>E-post:{firstAgent?.email ?? ''}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <p className="mt-50">
                                        I medhold av lov om eiendomsmegling av 29. juni 2007 nr. 73, er det inngått
                                        oppdragsavtale mellom:
                                    </p>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary  mb-20 title">
                                                1 - Avtaleparter
                                            </Title>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary title">
                                                Oppdragsgiver:
                                            </Title>
                                        </Col>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Navn :</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p> {`${primarySeller?.firstName ?? ''} ${primarySeller?.lastName ?? ''}`}</p>
                                        </Col>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Fødselsnr./Org.nr.:</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p>{getDecryptedId(primarySeller?.socialSecurity) ?? ''} </p>
                                        </Col>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Adresse:</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p>
                                                {`${primarySeller?.postalAddress ?? ''} ${primarySeller?.postalCode ??
                                                ''} ${primarySeller?.city ?? ''} `}
                                            </p>
                                        </Col>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Epost:</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p> {primarySeller?.email}</p>
                                        </Col>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Mobil:</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p>{primarySeller?.mobilePhone}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                {secondSeller ? (
                                    <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                        <p className="mt-3">
                                            Dersom avtalen ikke signeres med BankID, må følgende fylles ut:
                                        </p>
                                        <Row gutter={8}>
                                            <Col xs={12} lg={6} xl={6} xxl={4}>
                                                <h4 className="text-bold text-primary text-large">Navn :</h4>
                                            </Col>
                                            <Col xs={12} lg={18} xl={18} xxl={20}>
                                                <p> {`${secondSeller?.firstName ?? ''} ${secondSeller?.lastName ?? ''}`}</p>
                                            </Col>
                                            <Col xs={12} lg={6} xl={6} xxl={4}>
                                                <h4 className="text-bold text-primary text-large">Epost:</h4>
                                            </Col>
                                            <Col xs={12} lg={18} xl={18} xxl={20}>
                                                <p> {secondSeller?.email}</p>
                                            </Col>
                                            <Col xs={12} lg={6} xl={6} xxl={4}>
                                                <h4 className="text-bold text-primary text-large">Adresse:</h4>
                                            </Col>
                                            <Col xs={12} lg={18} xl={18} xxl={20}>
                                                <p>
                                                    {' '}
                                                    {`${secondSeller?.postalAddress ?? ''} ${secondSeller?.postalCode ??
                                                    ''} ${secondSeller?.city ?? ''}`}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : null}
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Col xs={24} lg={24}>
                                        <Title level={4} className="text-primary  mb-20 title">
                                            Oppdragstaker:
                                        </Title>
                                    </Col>
                                    <p className="mt-3" dangerouslySetInnerHTML={{
                                        __html:
                                        data?.property_agreement_details?.office_opprag_agreement?.oppdrag_staker,
                                    }}/>
                                    <Row gutter={8}>
                                        {data?.property_agreement_details?.property?.property_agent?.length > 1 ? (
                                            <>
                                                <Col xs={12} lg={6} xl={6} xxl={4}>
                                                    <h4 className="text-bold text-primary text-large">Ansvarlig megler:</h4>
                                                </Col>
                                                <Col xs={12} lg={18} xl={18} xxl={20}>
                                                    {secondAgent?.name}
                                                </Col>
                                            </>
                                        ) : null}
                                        {/* {data?.property_agreement_details?.property?.property_agent?.length > 2 ? ( */}
                                        {/*<>
                    <Col xs={12} lg={6} xl={6} xxl={4}>
                      <h4 className="text-bold text-primary text-large">
                        Eiendomsmeglerfullmektig:
                      </h4>
                    </Col>
                    <Col xs={12} lg={18} xl={18} xxl={20}>
                      {firstAgent?.name}
                    </Col>
                  </>*/}
                                        {/* ) : null} */}
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Oppgjørsforetak:</h4>
                                            <p>
                                                {' '}
                                                {data?.property_agreement_details?.office_opprag_agreement?.Oppgjor_sforetak}
                                            </p>
                                        </Col>
                                        {/* <Col xs={12} lg={18} xl={18} xxl={20}>
                    <p>
                      {' '}
                      {data?.property_agreement_details?.office_opprag_agreement?.Oppgjor_sforetak}
                    </p>
                  </Col> */}
                                    </Row>
                                </Col>

                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Col xs={24} lg={24}>
                                        <Title level={4} className="text-primary mb-20 title">
                                            2 - Oppdragets omfang
                                        </Title>
                                    </Col>
                                    <p className="mt-3">
                                        {' '}
                                        {`Oppdragstaker skal arbeide med salg av: ${
                                            propertyDetails?.data?.property?.ownership !== null
                                                ? getPropertyType(propertyDetails?.data?.property?.ownership)
                                                : ''
                                            }`}
                                    </p>
                                    <Row gutter={8}>
                                        <Col xs={12} lg={6} xl={6} xxl={4}>
                                            <h4 className="text-bold text-primary text-large">Adresse:</h4>
                                        </Col>
                                        <Col xs={12} lg={18} xl={18} xxl={20}>
                                            <p>{`${propertyDetails?.data?.property?.street_adress ?? ''}, ${propertyDetails
                                                ?.data?.property?.zipcode ?? ''} ${propertyDetails?.data?.property?.city ??
                                            ''}`}</p>
                                        </Col>
                                        <Col xs={24} lg={20}>
                                            <p>
                                                Matrikkel{' '}
                                                {propertyDetails?.data?.property?.ownership !== null
                                                    ? getPropertyTypePrefix(propertyDetails?.data?.property?.ownership)
                                                    : ''}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Col xs={24} lg={24}>
                                        <Title level={4} className="text-primary  mb-20 title">
                                            3 - Oppdragets varighet
                                        </Title>
                                    </Col>
                                    <p className="mt-3">
                                        Oppdraget gjelder inntil det blir tilbakekalt, dog ikke utover en tid av 6 måneder
                                        fra underskrift av oppdragsavtalen. Oppdraget kan fornyes for inntil seks måneder
                                        av gangen. Fornyelse skal være skriftlig. Oppdraget kan sies opp skriftlig uten
                                        varsel av begge partene i oppdragsperioden.
                                    </p>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Col xs={24} lg={24}>
                                        <Title level={4} className="text-primary mb-20 title">
                                            4 - Oppdragstakers vederlag
                                        </Title>
                                    </Col>
                                    <p className="mt-3">
                                        Oppdragsgiver har anledning til å inngå avtale om enten provisjonsbasert vederlag
                                        eller timebasert vederlag. Denne avtale gir en oversikt over begge
                                        vederlagsformer. Samtlige beløp i avtalen er inkludert merverdiavgift. Vederlag
                                        under punkt 4.3 og 4.4 kommer i tillegg uavhengig av vederlagsform.
                                    </p>
                                    <p className="mt-3">
                                        Vederlaget forfaller når handel er kommet i stand, og vil bli fakturert i
                                        forbindelse med oppgjøret.
                                        Oppdragstakeren kan bare dekke sitt krav på vederlag i klientmidler som tilhører
                                        oppdragsgiveren, dersom oppdragsgiveren har samtykket til dette etter at handel er
                                        kommet i stand.
                                    </p>
                                    <p className="mt-3">Oppdragsgiver velger følgende vederlagsform:</p>

                                    <p className="mt-3">
                                        <Checkbox
                                            className="agreement_chkbox"
                                            disabled
                                            checked={
                                                data?.property_agreement_details?.property_remuneration?.usableService
                                                    ?.cost_type === 'HOURLY'
                                            }
                                        >
                                            Timebasert vederlag (Punkt 4.1)
                                        </Checkbox>
                                    </p>
                                    <p className="mt-3 text-secondary">
                                        <Checkbox
                                            className="agreement_chkbox"
                                            disabled
                                            checked={
                                                data?.property_agreement_details?.property_remuneration?.usableService
                                                    ?.cost_type === 'FIX'
                                            }
                                        >
                                            Provisjonsbasert vederlag (Punkt 4.2)
                                        </Checkbox>
                                    </p>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title
                                                level={4}
                                                className={`${
                                                    !greyOutSection
                                                        ? 'opacity-3 text-primary mb-20 title'
                                                        : 'text-primary mb-20 title'
                                                    }`}
                                            >
                                                4.1 - Timebasert vederlag
                                            </Title>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <p className={`${!greyOutSection ? 'opacity-3' : ''}`}>
                                                {`Ved timebasert vederlag utgjør timesatsen Kr ${
                                                    data?.property_agreement_details?.office_opprag_agreement?.hourly_rate
                                                    },-. Det anslås et tidsforbruk for oppdraget på totalt ${data
                                                    ?.property_agreement_details?.office_opprag_agreement?.time_spent ??
                                                ''} timer. Oppdraget deles inn i følgende faser, anslått i timer:`}
                                            </p>
                                        </Col>
                                        {data?.property_agreement_details?.office_opprag_agreement
                                            ?.hourly_remuneration !== null ? (
                                            <Col xs={24} lg={24} className={`${!greyOutSection ? 'opacity-3' : ''}`}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                        data?.property_agreement_details?.office_opprag_agreement
                                                            ?.hourly_remuneration,
                                                    }}
                                                />
                                            </Col>
                                        ) : null}

                                        <Col xs={24} lg={12} className={`${!greyOutSection ? 'opacity-3' : ''}`}>
                                            {data?.property_agreement_details?.office_opprag_agreement?.hourly_rate !==
                                            null &&
                                            data?.property_agreement_details?.office_opprag_agreement?.time_spent !==
                                            null ? (
                                                <p>
                                                    {`Sum vederlag basert på avtalt timesats Kr ${formatCurrency(
                                                        parseInt(
                                                            data?.property_agreement_details?.office_opprag_agreement?.hourly_rate,
                                                        ) *
                                                        parseInt(
                                                            data?.property_agreement_details?.office_opprag_agreement?.time_spent,
                                                        ),
                                                    )}, -`}
                                                </p>
                                            ) : null}
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <p className={`${!greyOutSection ? 'opacity-3' : ''}`}>
                                                Tilbud om timesbasert vederlag er et estimat på forventet tidsbruk og vil
                                                variere. Timetallet er derfor ikke bindende for megler og endelig timetall kan
                                                avvike fra tilbudet som er gitt. Oppdragstaker plikter å varsle
                                                oppdragsgiveren dersom medgått tid forventes å vesentlig overstige det som er
                                                angitt i ovennevnte overslag. Ved valg av timesbasert vederlag inngår
                                                kostnader til grunnpakke markedsføring og oppgjør. Eventuelle kostnader til
                                                ytterligere markedsføring påløper i henhold til aktuelle priser.
                                                Visningshonorar faktureres i henhold til medgått tid.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24} className={!fixPriceGreyColor ? 'opacity-3' : ''}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                4.2 - Provisjonsbasert vederlag
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className={!fixPriceGreyColor ? 'opacity-3 mt-3' : 'mt-3'}>
                                        Megler har krav på vederlag når handel er kommet i stand (budaksept).
                                    </p>
                                    {!propertyDetails?.data?.property?.property_preference?.fix_price && (
                                        <>
                                            <p className={!fixPriceGreyColor ? 'opacity-3 mt-3' : 'mt-3'}>
                                                {propertyDetails?.data?.property?.property_preference?.percentage ?? 0} %
                                                provisjon med utgangspunkt i prisantydning kr.{' '}
                                                {formatCurrency(
                                                    propertyDetails?.data?.property?.property_preference?.preferable_price ?? 0,
                                                )}
                                            </p>
                                            <p className={!fixPriceGreyColor ? 'opacity-3 mt-3' : 'mt-3'}>
                                                Beregnet provisjon basert på prisantydning kr.{formatCurrency(totalPre)},-
                                                {/* {data?.property_agreement_details?.property_remuneration?.usableService.cost_type === "FIX" ? formatCurrency(data?.property_agreement_details?.property_remuneration?.price) : 0},- */}
                                            </p>
                                        </>
                                    )}
                                    {propertyDetails?.data?.property?.property_preference?.fix_price && (
                                        <p className={!fixPriceGreyColor ? 'opacity-3 mt-3' : 'mt-3'}>
                                            Fastpris: Eiendomsmegleren har krav på en fast provisjon kr.
                                            {formatCurrency(
                                                propertyDetails?.data?.property?.property_preference?.fix_price,
                                            ) ?? 0}
                                            ,-
                                        </p>
                                    )}
                                    {/* <p className="mt-3 text-secondary">Provisjonsbasert vederlag (Punkt 4.2)</p> */}
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                4.3 - Øvrige vederlag
                                            </Title>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <p>I tillegg til vederlag etter punkt 4.1 eller 4.2 betaler oppdragsgiver i
                                                tillegg følgende øvrige vederlag:</p>
                                        </Col>

                                        <Query
                                            query={SELECTED_SERVICE}
                                            fetchPolicy="network-only"
                                            variables={{
                                                status: 1,
                                                category: 'PS',
                                                propertyId: +decryptId(params.propertyId),
                                            }}
                                            onCompleted={response => {
                                                setPhotoServices(response);
                                                setPropertyUtlegg(response);
                                                setMarketingPackage(response);
                                                // setRemunerations(response);
                                            }}
                                        >
                                            {({ data }) => (
                                                <>
                                                    {/* {renumeration?.map(item => (
                            <>
                              <Col xs={12} lg={12}>
                                <h4 className="text-bold text-primary text-large">
                                  {item.name}
                                </h4>
                              </Col>
                              <Col xs={12} lg={12}>
                                <p className="price-text">
                                  {' '}
                                  {item?.is_free
                                    ? item?.free_text
                                    : `Kr ${formatCurrency(item.price)},-`}
                                </p>
                              </Col>
                            </>
                          ))} */}

                                                    {allMarketingPackages?.length > 0 && (
                                                        <Col xs={24} lg={24}>
                                                            <p className="text-primary text-bold mt-2 title-service no-margin">
                                                                Markedspakker
                                                            </p>
                                                        </Col>
                                                    )}

                                                    {allMarketingPackages?.map(item => (
                                                        <>
                                                            <Col xs={12} lg={8} xl={6}>
                                                                <h4 className="text-bold text-primary text-large no-margin">
                                                                    {`${item.name} (${item.marketing_package_service?.map(item => {
                                                                        return item?.office_service?.name
                                                                    }).toString()})`}
                                                                </h4>
                                                            </Col>
                                                            <Col xs={12} lg={16} xl={18}>
                                                                <p className="price-text mb-0">
                                                                    {' '}
                                                                    {item?.property_package
                                                                        ? `Kr ${formatCurrency(item?.property_package?.price)},-`
                                                                        : `Kr ${formatCurrency(item.price)},-`}
                                                                </p>
                                                            </Col>
                                                        </>
                                                    ))}

                                                    {allPsServices?.length > 0 && (
                                                        <Col xs={24} lg={24}>
                                                            <p className="text-primary text-bold mt-2 title-service no-margin">
                                                                Boligfoto/Styling
                                                            </p>
                                                        </Col>
                                                    )}
                                                    {allPsServices?.map(item => (
                                                        <>
                                                            <Col xs={12} lg={8} xl={6}>
                                                                <h4 className="text-bold text-primary text-large no-margin">
                                                                    {item.name}
                                                                </h4>
                                                            </Col>
                                                            <Col xs={12} lg={16} xl={18}>
                                                                <p className="price-text mb-0">
                                                                    {' '}
                                                                    {item?.is_free
                                                                        ? item?.free_text
                                                                        : `Kr ${formatCurrency(item.price)},-`}
                                                                </p>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </Query>

                                        <Query
                                            query={SELECTED_SERVICE}
                                            fetchPolicy="network-only"
                                            variables={{
                                                status: 1,
                                                category: 'DM',
                                                propertyId: +decryptId(params.propertyId),
                                            }}
                                            onCompleted={response => {
                                                setDigitalServices(response);
                                            }}
                                        >
                                            {({ data }) => (
                                                <>
                                                    {allDmServices?.length > 0 && (
                                                        <Col xs={24} lg={24}>
                                                            <p className="text-primary text-bold mt-2 title-service no-margin">
                                                                Digital markedsføring
                                                            </p>
                                                        </Col>
                                                    )}
                                                    {allDmServices?.map(item => (
                                                        <>
                                                            <Col xs={12} lg={8} xl={6}>
                                                                <h4 className="text-bold text-primary text-large no-margin">
                                                                    {item.name}
                                                                </h4>
                                                            </Col>
                                                            <Col xs={12} lg={16} xl={18}>
                                                                <p className="price-text mb-0">
                                                                    {' '}
                                                                    {item?.is_free
                                                                        ? item?.free_text
                                                                        : `Kr ${formatCurrency(item.price)},-`}
                                                                </p>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </Query>

                                        <Query
                                            query={SELECTED_SERVICE}
                                            fetchPolicy="network-only"
                                            variables={{
                                                status: 1,
                                                category: 'PM',
                                                propertyId: +decryptId(params.propertyId),
                                            }}
                                            onCompleted={response => {
                                                setPrintServices(response);
                                            }}
                                        >
                                            {({ data }) => (
                                                <>
                                                    {allPmServices?.length > 0 && (
                                                        <Col xs={24} lg={24}>
                                                            <p className="text-primary text-bold mt-2 title-service no-margin">
                                                                Print markedsføring
                                                            </p>
                                                        </Col>
                                                    )}
                                                    {allPmServices?.map(item => (
                                                        <>
                                                            <Col xs={12} lg={8} xl={6}>
                                                                <h4 className="text-bold text-primary text-large no-margin">
                                                                    {item.name}
                                                                </h4>
                                                            </Col>
                                                            <Col xs={12} lg={16} xl={18}>
                                                                <p className="price-text mb-0">
                                                                    {' '}
                                                                    {item?.is_free
                                                                        ? item?.free_text
                                                                        : `Kr ${formatCurrency(item.price)},-`}
                                                                </p>
                                                            </Col>
                                                        </>
                                                    ))}
                                                    {allSelectedVederlagServices?.length > 0 && (
                                                        <Col xs={24} lg={24}>
                                                            <p className="text-primary text-bold mt-2 title-service no-margin">
                                                                Andre kostnader
                                                            </p>
                                                            <p className="text-primary mt-2 title-service no-margin text-bold">
                                                                Vederlag
                                                            </p>
                                                        </Col>
                                                    )}
                                                    {allSelectedVederlagServices?.map(item => (
                                                        <>
                                                            <Col xs={12} lg={8} xl={6}>
                                                                <h4 className="text-bold text-primary text-large no-margin">
                                                                    {item.name}
                                                                </h4>
                                                            </Col>
                                                            <Col xs={12} lg={16} xl={18}>
                                                                <p className="price-text no-margin">
                                                                    {' '}
                                                                    {item?.is_free
                                                                        ? item?.free_text
                                                                        : `Kr ${formatCurrency(item.price)},-`}
                                                                </p>
                                                            </Col>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </Query>

                                        {data?.property_agreement_details?.property?.property_preference
                                            ?.rebate_amount ? (
                                            <>
                                                <Col xs={12} lg={8} xl={6}>
                                                    <h4 className="text-bold text-primary text-large mt-2 mb-0">
                                                        Avslag på totalprisen
                                                    </h4>
                                                </Col>
                                                <Col xs={12} lg={16} xl={18}>
                                                    <p className="price-text mt-2 mb-0">
                                                        Kr{' '}
                                                        {formatCurrency(
                                                            data?.property_agreement_details?.property?.property_preference
                                                                ?.rebate_amount ?? 0,
                                                        )}
                                                    </p>
                                                </Col>
                                            </>
                                        ) : null}

                                        <Col xs={24} lg={{ span: 14 }}>
                                            <hr style={{ borderTop: '1px solid #191919', borderBottom: '0' }} />
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col xs={16} lg={8} xl={6}>
                                            <Title
                                                level={4}
                                                className="text-primary mb-20 title"
                                                style={{ marginTop: '10px' }}
                                            >
                                                Sum
                                            </Title>
                                        </Col>
                                        <Col xs={8} lg={16} xl={18}>
                                            <Title
                                                level={4}
                                                className="text-primary mb-20 title mbl-right"
                                                style={{ marginTop: '10px' }}
                                            >
                                                {/* {data?.property_agreement_details?.property_service && (
                        <>
                          {`Kr ${
                            data?.property_agreement_details?.property?.property_preference
                              ?.rebate_amount
                              ? formatCurrency(
                                  totalData +
                                    marketingPackagesTotal -
                                    data?.property_agreement_details?.property?.property_preference
                                      ?.rebate_amount,
                                )
                              : marketingPackagesTotal > 0
                              ? formatCurrency(totalData + marketingPackagesTotal)
                              : formatCurrency(totalData)
                          },-`}
                        </>
                      )} */}
                                                {`Kr ${
                                                    data?.property_agreement_details?.property?.property_preference
                                                        ?.rebate_amount
                                                        ? formatCurrency(
                                                        psTotal +
                                                        dmTotal +
                                                        pmTotal +
                                                        vederlagTotal +
                                                        marketingPackagesTotal -
                                                        data?.property_agreement_details?.property?.property_preference
                                                            ?.rebate_amount,
                                                        )
                                                        : formatCurrency(
                                                        psTotal + dmTotal + pmTotal + vederlagTotal + marketingPackagesTotal,
                                                        )
                                                    },-`}
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={24}>
                                            <p>
                                                Tilrettelegging inkluderer grunnhonorar, utarbeidelse av markedsmateriell og
                                                systemer.
                                            </p>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <p>
                                                Ved fornyelse/løfting av annonse på Finn.no påløper i tillegg kr 1.750,- inkl.
                                                mva. Ved bestilling av tinglyste erklæringer påløper i tillegg kr 207,- pr.
                                                erklæring dersom dette ikke er medtatt i oppstillingen over. Dersom det
                                                foreligger utleggsforretning(er)/tvangspant, tilkommer kr 2.500,-/time for
                                                arbeid med kreditorer.
                                            </p>
                                        </Col>
                                        <Col xs={24} lg={24}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                    data?.office_opprag_agreement?.office_opprag_agreement
                                                        ?.other_remuneration,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                4.4 - Utlegg
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        {allSelectedUtleggServices?.length > 0 && (
                                            <Col xs={24} lg={24}>
                                                {/* <p className="text-primary text-bold mt-2 title-service no-margin">
                        Utlegg
                      </p> */}
                                            </Col>
                                        )}
                                        {allSelectedUtleggServices?.map(item => (
                                            <>
                                                <Col xs={12} lg={8} lg={6}>
                                                    <h4 className="text-bold text-primary text-large no-margin">{item.name}</h4>
                                                </Col>
                                                <Col xs={12} lg={16} lg={18}>
                                                    <p className="price-text no-margin">
                                                        {' '}
                                                        {item?.is_free ? item?.free_text : `Kr ${formatCurrency(item.price)},-`}
                                                    </p>
                                                </Col>
                                            </>
                                        ))}
                                        <Col xs={24} lg={14}>
                                            <hr style={{ borderTop: '1px solid #191919', borderBottom: '0' }} />
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col xs={16} lg={8} xl={6}>
                                            <Title
                                                level={4}
                                                className="text-primary mb-20 title"
                                                style={{ marginTop: '10px' }}
                                            >
                                                Sum
                                            </Title>
                                        </Col>
                                        <Col xs={8} lg={16} xl={18}>
                                            <Title
                                                level={4}
                                                className="text-primary mb-20 title mbl-right"
                                                style={{ marginTop: '10px' }}
                                            >
                                                Kr {formatCurrency(utleggTotal)},-
                                            </Title>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                            data?.property_agreement_details?.office_opprag_agreement?.other_remuneration,
                                        }}
                                    />
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row gutter={8}>
                                        <Col xs={24} lg={{ span: 24 }}>
                                            <Title
                                                level={4}
                                                className="text-primary title"
                                            >
                                                Totalt vederlag og utlegg kr.
                                                {data?.property_agreement_details?.property?.property_preference
                                                    ?.rebate_amount
                                                    ? formatCurrency(
                                                        psTotal +
                                                        dmTotal +
                                                        pmTotal +
                                                        vederlagTotal +
                                                        utleggTotal +
                                                        marketingPackagesTotal +
                                                        totalRenumeration -
                                                        data?.property_agreement_details?.property?.property_preference
                                                            ?.rebate_amount,
                                                    )
                                                    : formatCurrency(
                                                        psTotal +
                                                        dmTotal +
                                                        pmTotal +
                                                        vederlagTotal +
                                                        utleggTotal +
                                                        marketingPackagesTotal +
                                                        totalRenumeration,
                                                    )}
                                                ,-
                                            </Title>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                4.5 - Betaling av meglers vederlag
                                            </Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <p className="">
                                                Meglers vederlag og eventuelt øvrige kostnader som ikke faktureres
                                                oppdragsgiver direkte, blir trukket fra oppdragsgivers oppgjør på
                                                oppgjørstidspunktet. Oppdragsgiver kan velge å motta faktura ved budaksept med
                                                14 dagers forfall.
                                            </p>
                                            <p className="">
                                                Ved valg av faktura: Betalingsutsettelsegebyret bortfaller. Dersom beløpet
                                                ikke er innbetalt i sin helhet innen oppgjørstidspunktet påløper likevel
                                                betalingsutsettelsesgebyret og oppdragsgiver aksepterer at meglers vederlag,
                                                inkludert utlegg og eventuelle forsinkelsesrenter trekkes fra oppgjøret.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                5 - Boligselgerforsikring
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className="">
                                        Vi anbefaler boligselgerforsikring gjennom BuySure. Prisen på
                                        forsikringen vil variere avhengig av hvilken type eiendom oppdraget gjelder og
                                        oppnådd salgssum. Dersom oppdragsgiver ønsker boligselgerforsikring trekkes
                                        kostnadene til dette fra oppgjøret. Kostnad til boligselgerforsikring kommer i
                                        tillegg til vederlag. Boligselgerforsikring må tegnes
                                        før boligen markedsføres.{' '}
                                    </p>
                                    <p className="">
                                        Avtale om boligselgerforsikring gjøres ved utfylling av egenerklæringsskjema, hvor
                                        en også vil få informasjon om vilkår og prisliste.
                                    </p>
                                    {insuranceList?.map((insuranceItem, index) => {
                                        return (
                                            <>
                                                <Checkbox
                                                    className="agreement_chkbox"
                                                    disabled
                                                    checked={insuranceItem?.selected_in_property === 1}
                                                >
                                                    {index === 0 && insuranceItem.selected_in_property === 1 ? `${insuranceItem.name} - ${insuranceItem.insurance_type === InsuranceType.UTEN ? "Uten Anticimex" : "Med Anticimex"}` : insuranceItem.name}
                                                </Checkbox>
                                                <br/>
                                            </>
                                        )
                                    })}
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                6 - Oppdragstakers krav på dekning av vederlag
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className="">
                                        Oppdragstaker har krav på vederlag og dekning av påløpte kostnader dersom handelen
                                        kommer i stand i oppdragstiden, selv om dette ikke skyldes oppdragstakerens
                                        innsats, jf. emgl. § 7-3, 1. ledd.{' '}
                                    </p>
                                    <p className="">
                                        {data?.property_agreement_details?.property?.sales_warranty_status === 1
                                            ? data?.property_agreement_details?.office_opprag_agreement?.sales_warranty_yes
                                            : data?.property_agreement_details?.office_opprag_agreement?.sales_warranty_no}
                                    </p>
                                    <p className="">
                                        Dersom en av oppdragsgiverne kjøper ut den andre/en av de andre oppdragsgiverne
                                        har oppdragstaker krav på fullt vederlag i henhold til punkt 4. Det samme gjelder
                                        dersom oppdragsgiver selger eiendommen til fraflyttet ektefelle.
                                    </p>
                                    <p className="">
                                        Hvis boligen har vært felles bolig for ektefeller, er boet mellom ektefellene
                                        oppgjort?
                                        <br />
                                        <span className="text-primary f-500">
                    <Checkbox
                        className="agreement_chkbox"
                        checked={data?.property_agreement_details?.property?.client_claim === 1}
                        disabled
                    >
                      Ja
                    </Checkbox>
                  </span>
                                        <br />
                                        <span className="text-primary f-500">
                    <Checkbox
                        checked={data?.property_agreement_details?.property?.client_claim === 0}
                        disabled
                        className="agreement_chkbox"
                    >
                      Nei
                    </Checkbox>
                  </span>
                                        <br />
                                    </p>
                                    <p className="">
                                        Oppdragstaker har også krav på vederlag dersom handelen kommer i stand innen 3
                                        måneder etter at oppdragstiden er ute, med noen som oppdragstaker har forhandlet
                                        med eller som etter forespørsel har fått opplysninger fra oppdragstaker om
                                        eiendommen i oppdragstiden, jf. emgl. § 7-3, 2.ledd. Dette omfatter også
                                        situasjoner hvor kjøperen har lastet ned opplysninger fra oppdragstaker på
                                        internett.
                                    </p>
                                    <p className="">
                                        Megler er gitt i eksklusivt oppdrag å gjennomføre salg av Eiendommen.
                                        Oppdragsgiver bekrefter at ingen andre meglere har arbeidet med salg av samme
                                        eiendom.
                                    </p>
                                    <p className="">
                                        Følgende megler(e) har arbeidet med salg av samme eiendomsoppdraget de siste 3
                                        måneder før signatur av oppdraget:
                                    </p>
                                    <span className="text-secondary">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={data?.property_agreement_details?.property?.contract_assignment === 0}
                  >
                    Ingen
                  </Checkbox>
                </span>
                                    <br />
                                    <span className="text-primary f-500">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={data?.property_agreement_details?.property?.contract_assignment === 1}
                  >
                    Annet meglerforetak
                  </Checkbox>
                </span>
                                    <br />
                                    {data?.property_agreement_details?.property?.contract_assignment === 1 &&
                                    <span>
                {data?.property_agreement_details?.property?.name_of_brokerage}
                </span> }
                                    <p>
                                        Oppdragsgiver er selv ansvarlig for å avslutte ev. oppdrag med andre oppdragstakere.
                                        Oppdragsgiver svarer selv for ev. krav som måtte oppstå dersom tidligere
                                        oppdragstakere krever vederlag og dekning av utlegg.
                                    </p>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mt-3 mb-20 title">
                                                7 - Særlige bestemmelser
                                            </Title>
                                        </Col>
                                    </Row>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                            data?.property_agreement_details?.office_opprag_agreement
                                                ?.financial_settlement,
                                        }}
                                    />
                                    {/* <p className="mb-0 f-500">Ansvarlig megler bistås av </p>
                <p>
                  I ansvarlig meglers fravær aksepteres det at meglerkontorets øvrige ansatte kan
                  bistå i oppdraget. Ved lengre fravær skal dette varsles skriftlig.
                </p>
                <p className="mb-0 f-500">Det økonomiske oppgjøret</p>
                <p>
                  {`Det økonomiske oppgjøret foretas av ${data?.property_agreement_details?.office_opprag_agreement?.financial_settlement} Oppgjørsmedarbeidere er ${data?.property_agreement_details?.office_opprag_agreement?.settlement_employees} Det tar normalt 2-3 uker fra overtakelse
til utbetaling av oppgjør. Dette er grunnet behandlingstid hos Statens Kartverk, postgang og`}
                </p>
                 */}
                                    <p className="mb-0 f-500">Taushetsplikt</p>
                                    <p>Oppdragstaker har taushetsplikt jf. eiendomsmeglingsloven § 3-6.</p>
                                    <p className="mb-0 f-500">Energimerking </p>
                                    <p>
                                        Oppdragsgiver er ansvarlig for å energimerke boligen i forbindelse med salg og
                                        oversende energiattest til megler. Hvis ikke energiattest er oversendt megler når
                                        boligen legges ut for salg vil boligen automatisk bli tildelt dårligste
                                        energimerking. Selger er klar over at kjøper har, etter forskrift om
                                        energimerking, anledning til å bestille energiattest for selgers regning, hvis
                                        dette ikke foreligger på aksepttidspunktet.
                                    </p>
                                    <p className="mb-0 f-500">Generell orientering om skatt</p>
                                    <p>
                                        Gevinst ved salg av eiendom er normalt skattefri når oppdragsgiver har eid
                                        eiendommen i minst 1 år og selv bebodd eiendommen i minst 1 av de 2 siste årene.
                                        Ved salg av fritidsbolig er gevinsten skattefri når oppdragsgiver har eid
                                        eiendommen i minst 5 år og brukt eiendommen i minst fem av de siste 8 årene.
                                        Gevinst ved salg av tomt er skattepliktig. Der tomten til en bebygget eiendom
                                        anses å være større enn bebyggelsen krever, vil deler av gevinsten kunne bli
                                        ansett som skattepliktig. Oppdragsgiver er selv ansvarlig for å undersøke
                                        skattemessige konsekvenser av salget.
                                    </p>
                                    <p className="mb-0 f-500">Mottagelse av bud utenom megler</p>
                                    <p>
                                        Bestemmelser om budgivning gitt i medhold av eiendomsmeglingsloven § 6-10 kommer
                                        til anvendelse på utførelsen av oppdraget. Oppdragstaker er i henhold til
                                        lovverket avskåret fra å formidle bud til oppdragsgiver dersom budet har kortere
                                        akseptfrist enn kl. 12 første virkedag etter siste annonserte visning.
                                        Oppdragsgiver kan derfor oppleve at interessenter ønsker å gi bud direkte til
                                        oppdragsgiver og med krav om raskt svar. Oppdragsgiver frarådes til å ta i mot
                                        slike bud fra budgiver, men heller henvise budgiveren til oppdragstaker. årsaken
                                        til dette er at oppdragstaker i slike tilfeller vil være avskåret fra å informere
                                        andre interessenter om budet, samt at det kan oppstå uklarheter rundt hvilke
                                        vilkår partene er blitt enige om. Oppdragsgiver gjøres for øvrig oppmerksom på at
                                        oppdragstaker også i disse tilfellene vil ha krav på fullt vederlag.
                                    </p>
                                    <p className="mb-0 f-500">Off-market</p>
                                    <p>
                                        Eiendommer som omsettes utenom markedet har større risiko for ikke å oppnå
                                        markedspris. På generelt grunnlag fraråder vi salg av eiendom uten at den er
                                        markedsført i det åpne markedet. Med flere interessenter på visning vil man kunne
                                        få en budrunde som kan medføre at prisen på boligen kan bli høyere enn meglers
                                        prisvurdering.
                                    </p>
                                    <p className="mb-0 f-500">Prisantydning</p>
                                    <p>
                                        Oppdragsgiver bekrefter å ha blitt informert om at prisantydning på eiendommen
                                        ikke bevisst må settes lavere enn det oppdragsgiver kan være villig til å
                                        akseptere.
                                    </p>
                                    <p className="mb-0 f-500">Samtykke til bruk av elektronisk kommunikasjon</p>
                                    <p>
                                        Oppdragsgiver samtykker ved underskrift på denne avtalen til at oppdragstaker kan
                                        benytte elektronisk kommunikasjon når oppdragstaker skal gi meldinger, varsler
                                        informasjon, formidle dokumenter etc. til oppdragsgiver. Oppdragsgiver tillater
                                        også at oppdragstaker kan benytte bilder og informasjon om salget i sin
                                        markedsføring. Partene vil selvfølgelig bli anonymisert.
                                    </p>
                                    <p className="mb-0 f-500">Pantedokument med urådighet</p>
                                    <p>
                                        Oppdragsgiver aksepterer at pantedokumentet med urådighet som tinglyses i
                                        forbindelse med salgsoppdraget også sikrer oppdragstakers krav på vederlag og
                                        utlegg.
                                    </p>
                                    <p className="mb-0 f-500"> Ved flere enn én oppdragsgiver</p>
                                    <p>
                                        Ved flere enn én oppdragsgiver gir disse hverandre gjensidig fullmakt til å
                                        godkjenne alt salgsmateriell samt avgi budaksept og avtale tidspunkt for
                                        overtagelse. Dette slik at meddelelser til og fra én av oppdragsgiverne også er
                                        bindende for samtlige oppdragsgivere.
                                    </p>
                                    <p className="mb-0 f-500">
                                        Rapporteringsplikt i henhold til hvitvaskingsregelverket
                                    </p>
                                    <p>
                                        Eiendomsmeglerforetak er underlagt hvitvaskingslovgivningen. Kjøper og selger er forpliktet til å bidra til at oppdragstaker kan gjennomføre
                                        tilfredsstillende kundekontroll og må derfor bl.a. fremlegge gyldig legitimasjon.
                                        Dersom oppdragstaker ikke får utført slik kontroll har oppdragstaker rett til å
                                        avstå fra å gjennomføre oppdraget og kreve et rimelig vederlag for utført arbeid.
                                        Oppdragstaker fraskriver seg ansvar for eventuelle økonomiske krav som følge av
                                        ovennevnte forhold.
                                    </p>
                                    <p className="mb-0 f-500">Behandling av personvernopplysninger / GDPR</p>
                                    <p>
                                        I forbindelse med gjennomføringen av oppdraget, vil Megler behandle en rekke
                                        personopplysninger om oppdragsgiver, herunder blant annet: navn, e-postadresse,
                                        telefonnummer, personnummer, bankkontonummer, boligopplysninger, kjøpshistorikk,
                                        overtagelsesprotokoll, og kontrakt. Behandling av personopplysninger er underlagt
                                        personopplysningsloven og personvernforordningen (GDPR). Formålene for
                                        behandlingen av personopplysninger er blant annet å oppfylle oppdragsavtalen,
                                        etterlevelse av lovpålagt krav, herunder krav i eiendomsmeglingsloven og
                                        hvitvaskingsloven, markedsføringsformål, analyse og tjenesteutvikling, mv. Megler
                                        er i henhold til eiendomsmeglingsforskriften § 3-7 pålagt å oppbevare kontrakter
                                        og dokumenter tilknyttet oppdraget i minst 10 år. Etter dette vil opplysningene
                                        bli slettet, alternativt anonymisert. Megler vil dele opplysninger med blant annet
                                        kommunen, forretningsfører, Oppdragsgivers bank/kreditor, Kartverket,
                                        Skatteetaten, finn.no, samt leverandører av blant annet meglersystemet og annet
                                        elektroniske system som er nødvendig for gjennomføringen av meglers arbeid. Megler
                                        er ikke behandlingsansvarlig for behandling av personopplysninger som foretas av
                                        takstmann, boligstylist, fotograf mv. For mer informasjon om vår behandling av
                                        personopplysninger og informasjon om dine rettigheter, se vår personvernerklæring;{' '}
                                        <a href="www.weiendomsmegling.no/personvern" target="_blank">
                                            weiendomsmegling.no/personvern
                                        </a>
                                        .
                                    </p>
                                    <p className="mb-0 f-500">Bakgrunnsrett, lovvalg og tvisteløsning</p>
                                    <p>
                                        Oppdragsgiver har rett til å forelegge eventuelle tvistespørsmål som måtte oppstå
                                        mellom partene for Reklamasjonsnemda for Eiendomsmeglingstjenester, jf.
                                        eiendomsmeglingsloven § 8-8. Denne avtalen er underlagt norsk rett, og
                                        eiendomsmeglingsloven kommer utfyllende til anvendelse for forhold som ikke er
                                        regulert i denne oppdragsavtalen. Tvister som måtte oppstå med tilknytning til denne
                                        avtale, avgjøres ved ordinær rettergang ved Eiendommens verneting som eksklusivt
                                        verneting.
                                    </p>
                                    <p className="mb-0 f-500">Løsøre og tilbehør</p>
                                    <p>
                                        Oppdragsgiver bekrefter å ha blitt informert om at løsøre og tilbehør som
                                        fremkommer i liste utarbeidet av bransjeorganisasjonene, legges til grunn for
                                        salget. Dersom oppdragsgiver ønsker å gjøre unntak fra denne listen eller det som
                                        følger av avhendingslovens bestemmelser om løsøre og tilbehør må dette opplyses om
                                        i salgsoppgaven.
                                    </p>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                8 - Politisk eksponert person (PEP)
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className="">
                                        En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt,
                                        et høytstående offentlig verv eller stilling. Er oppdragsgiver en politisk
                                        eksponert person (PEP)?{' '}
                                    </p>
                                    <span className="f-500 mb-3">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={data?.property_agreement_details?.property?.political_person === 1}
                  >
                    Ja
                  </Checkbox>
                </span>
                                    <span className="text-secondary">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={data?.property_agreement_details?.property?.political_person === 0}
                  >
                    Nei
                  </Checkbox>
                </span>
                                    <p className="mt-3">
                                        Hvorfor spør vi om dette? Personer i fremtredende stillinger kan være mer utsatt
                                        for forsøk på hvitvasking av penger. Det samme gjelder om du er ett nært
                                        familiemedlem eller kjent medarbeider til en som er, eller tidligere har vært en
                                        politisk eksponert person.
                                    </p>
                                    <p className="">Hva menes med offentlig verv eller stilling?</p>
                                    <p className="mb-0">
                                        - Statsoverhode, regjeringssjef, minister eller assisterende minister
                                    </p>
                                    <p className="mb-0">- Medlem av nasjonalforsamling </p>
                                    <p className="mb-0">- Medlem av styrende organ i politisk parti</p>
                                    <p className="mb-0">
                                        {' '}
                                        Medlem av høyere rettsinstans som treffer beslutning som ikke eller bare
                                        unntaksvis kan ankes{' '}
                                    </p>
                                    <p className="mb-0">
                                        - Medlem av styre i riksrevisjon, revisjons-domstol eller sentralbank
                                    </p>
                                    <p className="mb-0">
                                        - Ambassadør, chargé d’affaires eller militær offiser av høyere rang
                                    </p>
                                    <p className="mb-0">
                                        - Medlem av administrative, ledende eller kontrollerende organ i statlig foretak
                                    </p>
                                    <p className="mb-0">
                                        - Direktør, styremedlem eller annen person i øverste ledelse av internasjonal
                                        organisasjon
                                    </p>
                                    <p className="mb-0">
                                        - Nært familiemedlem og kjent medarbeider til PEP regnes også som en politisk
                                        eksponert person.
                                    </p>
                                    <p className="mb-0">
                                        - Medlem av administrative, ledende eller kontrollerende organ i statlig foretak
                                    </p>
                                    <p className="mt-3">
                                        Nært familiemedlem er: foreldre, ektefelle/registrert partner/samboer, barn, samt
                                        barns ektefelle/registrert partner/samboer
                                    </p>
                                    <p className="mt-3">Kjent medarbeider er en fysisk person som er kjent for å:</p>
                                    <p className="mt-3">
                                        Være reell rettighetshaver i juridisk person, sammenslutning eller utenlandsk
                                        juridisk arrangement i fellesskap med politisk eksponert person
                                    </p>
                                    <p className="mt-3">Ha nær forretningsforbindelse til politisk eksponert person</p>
                                    <p className="mt-3">
                                        Være eneste reelle rettighetshaver i juridisk person, sammenslutning eller
                                        utenlandsk juridisk arrangement som i realiteten er etablert for å begunstige
                                        politisk eksponert person
                                    </p>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title">
                                                {/* 9 - Fullmakt */}9 - Erklæring om sivilstand
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className="">
                                        Ved signering av denne avtale gir oppdragsgiver herved oppdragstaker og dets
                                        oppgjørsselskap fullmakt til å innhente nødvendige opplysninger om eiendommen,
                                        herunder formuesverdi, energiattest, kommunal eiendomsoppgave, restanser på
                                        kommunale avgifter, opplysninger vedrørende saldo, innfrielsesbeløp og eventuelt
                                        andre betingelser for lån med pant i eiendommen.
                                    </p>
                                    <p className="mt-3">Er oppdragsgiver gift/registrert partner?</p>
                                    <span className="f-500 text-secondary d-block">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.client_married_partner === 1
                      }
                  >
                    Ja
                  </Checkbox>
                </span>
                                    <span className="mt-2 d-block f-500">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.client_married_partner !== 1
                      }
                  >
                    Nei
                  </Checkbox>
                </span>
                                    <p className="mt-3">
                                        {' '}
                                        Er oppdragsgiver gift/registrert partner med hverandre og begge underskriver som
                                        oppdragsgiver?
                                    </p>
                                    <span className="f-500 text-secondary d-block">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.client_partner_both_sign === 1
                      }
                  >
                    Ja
                  </Checkbox>
                </span>
                                    <span className="mt-2 d-block f-500">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.client_partner_both_sign !== 1
                      }
                  >
                    Nei
                  </Checkbox>
                </span>

                                    <p className="mt-3">
                                        {' '}
                                        Gjelder oppdraget bolig som oppdragsgiver og dennes ektefelle/registrerte partner
                                        bruker som felles bolig?
                                    </p>
                                    <span className="f-500 text-secondary d-block">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.contract_apply_housing === 1
                      }
                  >
                    Ja
                  </Checkbox>
                </span>
                                    <span className="mt-2 d-block f-500">
                  <Checkbox
                      className="agreement_chkbox"
                      disabled
                      checked={
                          data?.property_agreement_details?.property?.contract_apply_housing !== 1
                      }
                  >
                    Nei
                  </Checkbox>
                </span>
                                </Col>
                                <Col xs={24} lg={{ span: 18, offset: 3 }}>
                                    <Row>
                                        <Col xs={24} lg={24}>
                                            <Title level={4} className="text-primary mb-20 title mt-3">
                                                10 - Signaturer
                                            </Title>
                                        </Col>
                                    </Row>
                                    <p className="">
                                        Oppdragsgiver signerer for at samtlige sider er lest og forstått. Oppdragsavtalen
                                        er tilgjengeliggjort i sin helhet og kan signeres elektronisk etter
                                        eiendomsmeglingslovens § 3-7 (2).
                                    </p>

                                    <Row gutter={8}>
                                        <Col xs={12} lg={15}>
                                            {/* <h4 className="text-bold text-primary text-large">Kokstad:</h4> */}
                                            <p>{moment().format('DD/MM/YYYY')}</p>
                                            {/* <h4 className="text-bold text-primary text-large">Oppdragsgiver:</h4>
                    <p> {`${primarySeller?.firstName ?? ''} ${primarySeller?.lastName ?? ''}`}</p>

                    {secondSeller ? (
                      <>
                        <h4 className="text-bold text-primary text-large">Oppdragsgiver:</h4>
                        <p> {`${secondSeller?.firstName ?? ''} ${secondSeller?.lastName ?? ''}`}</p>
                      </>
                    ) : null} */}
                                        </Col>
                                        {/* <Col xs={12} lg={18}>
              <p>den 01.04.2020</p>
            </Col>
            <Col xs={12} lg={6}>
              <h4 className="text-bold text-primary text-large">Oppdragstaker v/ansvarlig megler:</h4>
            </Col> */}
                                        {/* <Col xs={12} lg={9}>
                    <h4 className="text-bold text-primary text-large">
                      Oppdragstaker v/ansvarlig megler:
                    </h4>
                    <p> {firstAgent?.name}</p>
                  </Col> */}
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={9}>
                                            <h4 className="text-bold text-primary text-large">Oppdragsgiver:</h4>
                                            <p> {`${primarySeller?.firstName ?? ''} ${primarySeller?.lastName ?? ''}`}</p>

                                            {secondSeller ? (
                                                <>
                                                    <h4 className="text-bold text-primary text-large">Oppdragsgiver:</h4>
                                                    <p> {`${secondSeller?.firstName ?? ''} ${secondSeller?.lastName ?? ''}`}</p>
                                                </>
                                            ) : null}
                                        </Col>
                                        <Col xs={24} lg={9}>
                                            <h4 className="text-bold text-primary text-large">
                                                Oppdragstaker v/ansvarlig megler:
                                            </h4>
                                            <p> {firstAgent?.name}</p>
                                        </Col>
                                    </Row>
                                    <Col xs={24} lg={24}>
                                        <Checkbox.Group
                                            options={options}
                                            className="f-500"
                                            onChange={e => (e[0] === 'confirm' ? setCheckBoxValue(true) : '')}
                                        />
                                    </Col>
                                    <Col xs={24} lg={24} className="mb-50 mt-3">
                                        <Mutation mutation={OWNERSIGN_AGREEMENT}>
                                            {Agreement => (
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={() =>
                                                        CallAgreement(Agreement, data?.property_agreement_details?.total_owners)
                                                    }
                                                    className="btn-secondary buttons buttons-large admins-form-button mt-3"
                                                >
                                                    Signer med BankID
                                                </Button>
                                            )}
                                        </Mutation>
                                        <Button
                                            size="large"
                                            className="buttons buttons-large admins-form-button back-button mt-3"
                                            style={{ background: 'none' }}
                                            onClick={() => router.goBack()}
                                        >
                                            {formatMessage({
                                                id: 'component.form.cancel',
                                            })}
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        );
                    }}
                </Query>
                                        </div>
        </BefaringLayout>
    );
};
export default AssignmentAgreement1;
