import React, {useEffect, useState} from 'react'
import {Animated, Image, View} from 'react-native'
import {IndicatorViewPager, Label, Ripple} from "components/src/components";
import {Color,Constants, CommonStyle, formatPrice, Strings} from "components/src/utils";
import styles from './styles';


import lookBook from 'components/src/assets/images/LookBook.jpg';
import newsPaper from 'components/src/assets/images/Newspaperadvertising.jpg';
import dm from 'components/src/assets/images/DM.jpg';
import rom from 'components/src/assets/images/Romplakat.jpg';
import forSale from 'components/src/assets/images/ForSalePoster.jpg';
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../../api/mutation";
import {SELECTED_PROPERTY_SERVICE} from "../../../../../../../../api/query";
// import Financing from 'components/src/assets/images/rate.png';
// import Insurance from 'components/src/assets/images/Insurance.png';

const IMAGES = [lookBook,forSale, newsPaper, dm, rom, ];
const MOCK_SERVICES = [
    {
      id: "1",
      name: "Lookbook",
      price: "Kr 2.000,-",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo.",
    },
  ];

function LookBook(props) {

    const {data, inspection, isCompanyTemplate, packages} = props;
    const IMAGES = isCompanyTemplate ? data?.office_service_media ?? [] : data?.agent_service_media ?? []


    const [selectedService, setSelectedService] = useState(data?.purchased_office_services?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id));

    const [serviceInPackage, setServiceInPackage] = useState([]);

    useEffect(() => {
        let service = []
        packages.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [packages])

    const addOrRemoveItemFromArray = (item) => {
        let updatedService = selectedService.slice();
        if (updatedService.includes(item.id)) {
            updatedService.splice(updatedService.indexOf(item.id), 1)
            saveServices({
                variables: {
                    input: {
                        property_id: inspection?.property_id,
                        unselect_pro_service_ids: item.service_selected?.id,
                        broker_on_view:false
                    }
                }
            }).catch(() => {
            })
        } else {
            updatedService.push(item.id)
        }
        saveServices({
            variables: {
                input: {
                    property_id: inspection?.property_id,
                    service_ids: updatedService,
                    broker_on_view:false
                }
            }
        }).catch(() => {
        })
        setSelectedService(updatedService)
    }
    const [selectedImage, setSelectedImage] = useState(1);

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES,{
        onCompleted: () => {
            props.refetch()
        }
    });


    const renderService = (item, index, self) => {
        let isSelected = selectedService.includes(item.id) || serviceInPackage.includes(item.id)
        return (
            <Ripple
                style={[styles.serviceCont, isSelected ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => serviceInPackage.includes(item.id) ? null : addOrRemoveItemFromArray(item)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                </Label>
                <Label small
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };

    return (
        <View style={styles.container}>

            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        <View style={styles.imgCountCont}>
                            <Label small
                                   color={Color.WHITE}>
                                {` ${selectedImage} / ${IMAGES.length} `}
                            </Label>
                        </View>
                        <IndicatorViewPager style={CommonStyle.flex}
                                            onPageSelected={(e) => setSelectedImage(e.position + 1)}>
                            {IMAGES.map(item => (
                                <View>
                                    <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}
                                           style={CommonStyle.flex}/>
                                </View>
                            ))}
                        </IndicatorViewPager>

                    </View>


                    <View style={styles.lblCont}>
                        <Label large
                               font_medium>
                            {data?.headline}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR}
                               mt={10}
                               mb={10}>
                            {data?.description}
                        </Label>
                        {data?.purchased_office_services?.map(renderService)}
                    </View>

                </View>
            </Animated.ScrollView>
        </View>

    )
}

export default LookBook
