import React, {useEffect, useState} from 'react'
import {View} from 'react-native'
import {Label, Ripple, VideoPlayer} from "components/src/components";
import {Color, CommonStyle, Constants, formatPrice, Icon, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../../api/mutation";


function Video(props) {

    const {data,vendorData, isFocused, inspection, isCompanyTemplate, packages} = props;
    const IMAGES = isCompanyTemplate ? data?.office_service_media ?? [] : data?.agent_service_media ?? []


    const [selectedService, setSelectedService] = useState(vendorData?.length ? vendorData.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id) : data?.purchased_office_services?.filter(item => item.service_selected && item?.service_selected?.property_id === inspection?.property_id)?.map(item => item.id));

    const [serviceInPackage, setServiceInPackage] = useState([]);

    useEffect(() => {
        let service = []
        packages.map(packageItem => {
            service = [...service, ...packageItem.marketing_package_service.map(item => item.office_service.id)]
        })
        setServiceInPackage(service)
    }, [packages])

    const addOrRemoveItemFromArray = (item) => {
        let updatedService = selectedService.slice();
        let variables = {
            property_id: inspection?.property_id,
            broker_on_view: false
        }
        if (updatedService.includes(item.id)) {
            updatedService.splice(updatedService.indexOf(item.id), 1)
            if (vendorData?.length) {
                variables.unselect_vendor_service_ids = item.service_selected?.id
            } else {
                variables.unselect_pro_service_ids = item.service_selected?.id
            }
            saveServices({
                variables: {
                    input: variables
                }
            }).catch(() => {
            })
        } else {
            updatedService.push(item.id)
        }
        //out side of else cause single selected properties
        if (vendorData?.length) {
            variables.vendor_service_ids = updatedService
        } else {
            variables.service_ids = updatedService
        }
        saveServices({
            variables: {
                input: variables
            }
        }).catch(() => {
        })
        setSelectedService(updatedService)
    }
    const [isPlaying, setPlaying] = useState(false);

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {
            props.refetch()
        }
    });

    useEffect(() => {
        if (isFocused) {
            setPlaying(true)
        } else {
            setPlaying(false)
        }
    }, [isFocused])

    const renderService = (item, index, self) => {
        // const data = self.filter(item => item.service_selected).map(item => item.service_selected.id)
        let isSelected = vendorData?.length ? selectedService.includes(item.id) : (selectedService.includes(item.id) || serviceInPackage.includes(item.id))
        return (
            <Ripple
                style={[styles.serviceCont, isSelected ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                onPress={() => serviceInPackage.includes(item.id) && !vendorData.length ? null : addOrRemoveItemFromArray(item)}
                key={index.toString()}>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item.name}
                </Label>
                <Label small
                       font_medium
                       color={isSelected ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                    {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                </Label>
                <Label xsmall
                       mt={10}
                       style={CommonStyle.flex}>
                    {item.description}
                </Label>
            </Ripple>
        )
    };

    return (
        <View style={styles.container}>
            <View style={styles.displayCont}>
                <View style={CommonStyle.flex}>
                    <View style={styles.imgCont}>
                        {!isPlaying ? (
                            <Ripple style={styles.icCont}
                                    onPress={() => setPlaying(true)}>
                                <Icon name={'video'}
                                      size={ThemeUtils.fontXXLarge}
                                      color={Color.WHITE}/>
                            </Ripple>) : null}
                        <VideoPlayer style={{flex: 1, minHeight: ThemeUtils.relativeRealHeight(55)}}

                                     playing={isPlaying}
                                     controls={isPlaying}
                                     url={`${Constants.APIConfig.STORAGE_URL}${IMAGES[0]?.media_url}`}/>
                    </View>
                </View>
                <View style={styles.lblCont}>
                    <Label large
                           font_medium>
                        {data?.headline}
                    </Label>
                    <Label small color={Color.SECONDARY_TEXT_COLOR}
                           mt={10}
                           mb={10}>
                        {data?.description}
                    </Label>
                                               {vendorData?.length ? vendorData.map(renderService) : data?.purchased_office_services?.map(renderService)}
                </View>
            </View>
        </View>

    )
}

export default Video
