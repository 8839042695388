import React from 'react';
import { Animated, Image, View } from 'react-native';

import {Color,
    IS_ANDROID} from '../../../utils';

class CustomFadeImage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
             imageOpacity: new Animated.Value(0),
             placeholderOpacity: new Animated.Value(1)
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.source !== this.props.source) {

            // Animated.timing(this.state.imageOpacity,{
                
            //     toValue: 0,
            //     duration: this.props.duration || 500,
            //     useNativeDriver: true

            // }).start(() => {

            //     this.setState({
            //         placeholderOpacity: new Animated.Value(1),
            //     })

            // })
        }
    };

    onLoadImage(){

        if(this.props.isImageURLAvailable){

            Animated.timing(this.state.placeholderOpacity,{
                toValue: 0,
                duration: 300,
                useNativeDriver: IS_ANDROID ? false : true
            }).start(() => {

                if(this.props.onLoadImageFromURL !== undefined){
                    this.props.onLoadImageFromURL();
                }
                
                Animated.timing(this.state.imageOpacity,{
                    toValue: 1,
                    duration: this.props.duration || 500,
                    useNativeDriver: IS_ANDROID ? false : true
                }).start()
                
            });

            // this.setState({
            //    // imageOpacity: new Animated.Value(0)
            // }, () => {

                

            // });
        }
    }
    render(){
        return (
            <View
                width={this.props.style.width}
                height={this.props.style.height}
                backgroundColor={'#ffffff00'}>

                <Animated.Image
                    resizeMode = {this.props.resizeMode}
                    style = {[
                        {
                            position: 'absolute',
                            opacity: this.props.isImageURLAvailable ? this.state.imageOpacity : 1
                        },
                        this.props.style
                    ]}
                    source = {this.props.source}
                    onLoad = {(event)=> this.onLoadImage(event)}
                    onLoadImageFromURL = {encodeURI(this.props.onLoadImageFromURL)}
                />

                { 
                    this.props.isImageURLAvailable ?
                        <Animated.Image
                            resizeMode={this.props.resizeMode}
                            style={[
                                {
                                    opacity: this.state.placeholderOpacity
                                },
                                this.props.style
                            ]}
                            source={this.props.placeholder}
                        /> : undefined
                }

            </View>
        )
    }
}

CustomFadeImage.defaultProps = {
    onLoadImageFromURL: () => {}
};

export default CustomFadeImage;