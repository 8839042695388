import React from 'react'
import {ActivityIndicator, Animated, Keyboard, View} from 'react-native'
import {Color, CommonStyle, Constants, Icon, IS_WEB, Strings, ThemeUtils, validation,} from "components/src/utils";


import Provider from "@ant-design/react-native/lib/provider";
//Network
import {Mutation, Query} from '@apollo/react-components';
import {ADD_FINANCING} from 'components/src/api/mutation';
import {FINANCE_TYPE} from 'components/src/api/query';
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
import {CheckBox, CustomButton, Header, InputField, Label} from "components/src/components";

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import {styles} from './styles.js';

const option = [{
    index: 1,
    name: Strings.agreefinancing,
    value: 1
}]

class Financing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FName: '',
            errorFName: null,
            LName: '',
            errorLName: null,
            coFirstName: '',
            errorCoFirstName: null,
            coLastName: '',
            errorCoLastName: null,
            Email: '',
            errorEmail: null,
            Phone: '',
            errorPhone: null,
            LoanAmount: '',
            errorLoanAmount: null,
            Comment: '',
            errorComment: null,
            isAgree: [],
            selectedFinancingType: []

        }
        this.scrollY = new Animated.Value(0)

    }

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.FIRST_NAME:
                this.setState({
                    coFirstName: text,
                    errorCoFirstName: null
                });
                break;
            case Constants.FieldId.LAST_NAME:
                this.setState({
                    coLastName: text,
                    errorCoLastName: null
                });
                break;
            case Constants.FieldId.FNAME:
                this.setState({
                    FName: text,
                    errorFName: null
                });
                break;
            case Constants.FieldId.LNAME:
                this.setState({
                    LName: text,
                    errorLName: null
                });
                break;
            case Constants.FieldId.EMAIL:
                this.setState({
                    Email: text,
                    errorEmail: null
                });
                break;
            case Constants.FieldId.PHONE:
                this.setState({
                    Phone: text,
                    errorPhone: null
                });
                break;

            case Constants.FieldId.LOANAMOUNT:
                this.setState({
                    LoanAmount: text,
                    errorLoanAmount: null
                });
                break;

            case Constants.FieldId.COMMENT:
                this.setState({
                    Comment: text,
                    errorComment: null
                });
                break;
        }
    };

    sendfinancingbutton = (addDetails) => {
        if (!IS_WEB) {
            Keyboard.dismiss();
        }
        const {selectedFinancingType, isAgree, FName, LName, Email, Phone, LoanAmount, coFirstName, coLastName, Comment} = this.state;
        this.setState({
            errorFName: validation('firstName', FName.trim()),
            // errorCoFirstName: validation('firstName', coFirstName.trim()),
            errorLName: validation('lastName', LName.trim()),
            // errorCoLastName: validation('lastName', coLastName.trim()),
            errorEmail: validation('email', Email),
            errorPhone: validation('phoneNo', Phone),
            errorLoanAmount: validation('LoanAmount', LoanAmount),
            // errorComment: validation('comment', Comment),
        }, () => {

            if (!this.state.errorFName && !this.state.errorLName && !this.state.errorEmail && !this.state.errorPhone && !this.state.errorLoanAmount) {
                let variables = {
                    first_name: FName.trim(),
                    last_name: LName.trim(),
                    email: Email,
                    phone: Phone,
                    loan_amount: LoanAmount,
                    comments: Comment,
                    financing_type: selectedFinancingType,
                    is_agree: isAgree.includes(1),
                    coAplicantField: {
                        first_name: coFirstName,
                        last_name: coLastName,
                    }
                }
                if (!(coFirstName && coFirstName)) {
                    delete variables.coAplicantField
                }
                if (Comment) {
                    variables.comments = Comment
                }
                addDetails({
                    variables: {
                        input: variables
                    }
                }).catch(() => {

                })
            }
        })
    };

    onChange = (val) => {
        console.log(val);
    }

    render() {
        return (
            <Provider locale={enUS}>

                <Header animatedTitle={Strings.financing}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                <Animated.ScrollView scrollEventThrottle={1}
                                     keyboardShouldPersistTaps={'always'}
                                     contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                         {useNativeDriver: true})}>

                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Icon
                                    size={ThemeUtils.fontXXLarge}
                                    name={'rate'}
                                    color={Color.PRIMARY_DARK}/>
                                {/*<Label xlarge
                                    ml={9}
                                    font_medium
                                    align={'left'}>
                                    {Strings.Settlementinformation}
                                </Label>*/}


                            </View>
                            <Label
                                mt={20}
                                small
                                font_regular
                                align={'left'}>{Strings.financingInfo}</Label>


                            <Label
                                mb={25}
                                mt={25}
                                large
                                font_medium
                                align={'left'}>{Strings.applicantDetails}</Label>


                            <InputField
                                type={"text"}
                                placeholder={Strings.PFname}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.FNAME)}
                                onFocus={() => this.setState({errorFName: null})}
                                error={this.state.errorFName}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.FName}
                                labelText={Strings.Fname}
                            />
                            <InputField

                                type="text"
                                placeholder={Strings.PLname}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.LNAME)}
                                onFocus={() => this.setState({errorLName: null})}
                                error={this.state.errorLName}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.LName}
                                labelText={Strings.Lname}
                            />
                            <InputField

                                type="email"
                                placeholder={Strings.Pemails}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.EMAIL)}
                                onFocus={() => this.setState({errorEmail: null})}
                                error={this.state.errorEmail}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.Email}
                                labelText={Strings.emails}
                            />
                            <InputField

                                type="number"
                                placeholder={Strings.PPhone}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.PHONE)}
                                onFocus={() => this.setState({errorPhone: null})}
                                error={this.state.errorPhone}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.Phone}
                                labelText={Strings.phones}
                            />
                            <InputField

                                type="number"
                                placeholder={Strings.PLoanAmount}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.LOANAMOUNT)}
                                onFocus={() => this.setState({errorLoanAmount: null})}
                                error={this.state.errorLoanAmount}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.LoanAmount}
                                labelText={Strings.LoanAmount}
                            />
                            <InputField
                                type={'text'}
                                textArea={true}
                                placeholder={Strings.PComments}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.COMMENT)}
                                onFocus={() => this.setState({errorComment: null})}
                                error={this.state.errorComment}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.Comment}
                                labelText={Strings.Comments}
                            />

                            <Label

                                mb={25}
                                large
                                font_medium
                                align={'left'}>{Strings.coApplicantDetails}</Label>


                            <InputField

                                type="text"
                                placeholder={Strings.PFname}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.FIRST_NAME)}
                                onFocus={() => this.setState({errorCoFirstName: null})}
                                error={this.state.errorCoFirstName}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.coFirstName}
                                labelText={Strings.Fname}
                            />
                            <InputField

                                type="text"
                                placeholder={Strings.PLname}
                                onChange={(text) => this.handleChangeText(text, Constants.FieldId.LAST_NAME)}
                                onFocus={() => this.setState({errorCoLastName: null})}
                                error={this.state.errorCoLastName}
                                labelProps={{
                                    font_medium: true,
                                    small: true
                                }}
                                value={this.state.coLastName}
                                labelText={Strings.Lname}
                            />


                            <View style={styles.checkbox}>
                                <Query query={FINANCE_TYPE}
                                       variables={{
                                           status: true
                                       }}>
                                    {({loading, data, error}) => {
                                        if (loading && !data) {
                                            return <ActivityIndicator color={Color.PRIMARY}/>
                                        }
                                        return (
                                            <CheckBox options={data?.financing_type ?? []}
                                                      size={ThemeUtils.fontXLarge}

                                                      style={styles.energyradio}
                                                      onCheckChanged={(val) => this.setState({selectedFinancingType: val})}/>)
                                    }}
                                </Query>

                            </View>

                            <CheckBox options={option}
                                      size={ThemeUtils.fontXLarge}
                                      labelProps={{
                                          font_medium: true,
                                          small: true
                                      }}
                                      contentStyle={[CommonStyle.flex, {marginRight: ThemeUtils.COMMON_HORIZONTAL_MARGIN}]}
                                      style={styles.energyradio}
                                      bold
                                      onCheckChanged={(val) => this.setState({isAgree: val})}
                            />

                            <Mutation mutation={ADD_FINANCING}
                                      onCompleted={() => {
                                          if (IS_WEB) {
                                              this.props.history.goBack();
                                          } else {
                                              this.props.navigation.goBack();
                                          }
                                      }}>
                                {(addDetails, {loading}) => (
                                    <CustomButton disabled={!this.state.isAgree.includes(1)}
                                                  mt={35}
                                                  mb={30}
                                                  loading={loading}
                                                  title={Strings.sendButton}
                                                  onPress={() => this.sendfinancingbutton(addDetails)}/>
                                )}
                            </Mutation>

                        </View>
                    </View>


                </Animated.ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Financing);
