import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    cont:{
        marginTop:5
    },
    lblCont:{
        marginTop:10
    },
    insuranceCont:{
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical:20
    },
    container:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
});

export default style;
