export default {
    en: {
        web: "web",
        megling: "megling",

        /*-----Sign in----------*/

        signIn: "Sign In",
        email: "Email",
        password: "Password",
        forgotYourPassword: "Forgot Your Password?",

        /*------Forgot Password--------------*/

        forgotPasswordIns:
            "Please submit the email address for the account and we will send you an email to reset your password",
        send: "Send",

        /*-----Dashboard------*/

        home: "Home",
        tasks: "Tasks",
        profile: "Profile",
        viewAllNews: "View All News",

        /*------------Feedback--------------*/
        addFeedBack: "Add Feedback",
        allFeedback: "All Feedback",
        /*------ Drawer------*/

        dashBoard: "Dashboard",
        calendar: "Calendar",
        digitalSalesAssignment: "Digital Sales Assignment",
        properties: "Properties",
        financing: "Financing",
        professional: "Professional",
        logout: "Log Out",
        inspectionApplications: "Inspection Applications",
        officeTemplate: "Office Template",
        B2BServices: "B2B Services",
        B2CServices: "B2C Services",
        WBefaring: "W Befaring",

        /*---------News------------*/

        news: "News",

        /*----Photo-------*/

        attach: "Attach",
        deletePhoto: "Delete Photo",
        photoDesc: "Are you sure you want to delete this Photo?",
        deleteButton: "Delete",
        upload: "Upload",
        photoTitle: "Photo Title",
        commentPlaceholder: "Write Your Comment Here...",

        /*-------Profile--------*/

        profilePicture: "Profile picture",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone",
        title: "Title",
        education: "Education",
        summaryAboutAgent: "Summary about Agent",
        uploadcv: "Upload CV",
        danielN_CVpdf: "DanielN_CV.pdf",
        uploadOtherDocuments: "Upload Other Documents",
        document_1pdf: "Document_1.pdf",
        document_2pdf: "Document_2.pdf",
        saveButton: "Save",
        cancleButton: "Cancel",
        enterFirstName: "Enter First Name",
        enterLastName: "Enter Last Name",
        enterEmail: "Enter Email",
        enterPhone: "Enter Phone",
        enterTitle: "Enter Title",
        enterEducation: "Enter Education",
        enterSummaryAboutAgent: "Enter Summary About Agent",
        changePasswordButton: "Change Password",

        /*----Tag----*/

        agentDetailsTag: "Agent Details",
        feedbackTag: "Feedback",

        /*------ChangePassword------*/

        changePassword: "Change Password",
        currentPassword: "Current Password",
        enterCurrentPassword: "Enter CurrentPassword",
        newPassword: "New Password",
        enterNewPassword: "Enter New Password",
        confirmPassword: "Confrim Password",
        enterConfirmPassword: "Enter ConfirmPassword",
        updateButton: "Update",

        /*------PropertyListing------*/

        moving: "Moving",
        search: "Search",

        /*----------Signature Assignment-------------*/

        signatureAssignment: "Signature Assignment",
        seller2Pending: "Seller 2 Pending",
        seller2Signed: "Seller 2 Signed",
        seller1Signed: "Seller 1 Signed",
        seller1Pending: "Seller 1 Pending",
        bothSellerSigned: "Both Seller Signed",
        oppDragNoAssigned: "Oppdrag No. Assigned",
        completed: "Completed",
        filters: "Filters",
        status: "Status :",
        apply: "Apply",
        reset: "Reset",
        phSearchAssignment: "Oppdrag No., Sales Name & Property Address",

        /*--------------Assignment Details---------------*/

        assignmentDetails: "Assignment Details",
        oppDragNo: "Oppdrag No. :",
        addOppDragNo: "Add Oppdrag No.",
        propertyAddress: "Property Address :",
        seller1: "Seller 1:",
        seller2: "Seller 2:",
        date: "Date :",
        signInWithBankId: "Sign in with Bank ID",
        viewPdf: "View PDF",
        cancel: "Cancel",
        save: "Save",

        /*-------------------Congratulation-------------------*/

        congratulations: "Congratulations !",
        youSuccessfullySignedTheAgreement:
            "You've successfully signed the agreement.",
        okay: "Okay",

        /*---------------------Rate---------------------------*/

        rate: "Rate",
        rateData:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        taxDate: "Tax Date :",
        valueRate: "Value Rate :",
        loanRate: "Loan Rate :",
        bta: "BTA :",
        bra: "BRA :",
        pRom: "P-ROM :",
        constructionYear: "Construction Year :",
        downloadPDFButton: "Download PDF",

        /*---------------Seller----------------*/

        seller: "Seller",

        /*------------------Inspection-------------------------*/

        setYourPreference: "Set your preference",
        inspectionDetails: "Inspection Details",
        inspectioninfo:
            "When we sell your home, it is required by law that one of our brokers comes to your home and maintains your home. You book time for this on this page. The broker will then contact you to confirm the time.",

        printMarketing: 'Print Marketing',
        variableUttleg: 'Variable uttleg',
        broker: 'Broker',
        propertyValue: 'Property value',
        boligselgerforsikring: "Boligselgerforsikring",
        boligselgerforsikringInst: "Boligselgerforsikring beskytter deg mot krav fra kjøperen av din bolig. I følge avhendingsloven er du ansvarlig for skjulte feil og mangler i fem år. \n\nNår du tegner boligselgerforsikring er det profesjonelle saksbehandlere, jurister og advokater som behandler alle krav fra kjøper. Med boligselgerforsikring er du dekket opp til kr 10 mill., mot prisavslag, erstatnings-kostnader og heving av kjøpet.",
        premietabell: 'Premietabell',
        missionCost: 'Missions Cost',
        addPackages: 'ADD PACKAGES',
        selectPackage: 'Select Package:',
        propertyDetails: 'Property Details',
        wBefaring: 'W Befaring',
        inspectionReport: 'inspection Report',
        digitalMarketing: 'Digital Marketing',
        details: 'Details',
        clientName: 'Client\'s Name :',
        clientEmail: 'Client\'s Email :',
        clientPhone: 'Client\'s Phone :',
        agentName: 'Agent Name :',
        inspectionDetailsInst: "The confirmation email will be sent with Date & Time and Agent's information.",
        sendInspectionConfirmation: 'Send Inspection Confirmation',
        chooseInpectionTemplate: "Choose inspection template",
        chooseVariableUtlegg: "Choose variable utlegg",
        company: 'Company',
        personal: 'Personal',
        sendOffer: 'Send offer',
        startInspection: 'Start inspection',
        summaryFromBefaring: 'Summart from befaring',
        editService: "Edit Service",
        serviceName: 'Service name',
        servicePrice: "Service price",
        serviceType: "Service type",
        CGI: '3D/CGI',
        PHOTO: 'PHOTO',
        STYLING: 'STYLING',
        FULL_VIEW: '360° VIEW',
        DRONE: 'DRONE',
        VIDEO: 'VIDEO',
        PROPERTY_PHOTO: 'PROPERTY_PHOTO',
        HOUSING_STYLE: 'HOUSING_STYLE',
        PICTURE_ILLUSTRATION: '3D PICTURES / ILLUSTRATION',
        DRONE_MOVIES: 'DRONE MOVIES / PICTURES',
        office: "Office:",
        testimonial: 'Testimonial:',
        media: "Media:",
        summary: 'Summary:',
        selectTime: 'Select Time',
        yourPreferablePrice: 'Your preferable price',
        setPercentage: 'Set percentage',
        fixedPrice: 'Fixed price',
        setPreference: 'Set preference',
        chooseTypeOfRemuneration: 'CHOOSE TYPE OF REMUNERATION',
        loadMore: "Load more",
        Sellersinterview: `Seller's interview`,
        propertyList: "PROPERTY LIST",
        personalTemplate: "PERSONAL TEMPLATE",

        INTROFILM: "INTRO FILM",
        WADS: "W ADS",
        FINNNO: "FINN.NO",
        DIGITALSALESASSIGNMENT: "DIGITAL SALES ASSIGNMENT",
        SOMEFILM: "SOME FILM",
        openExternally: "Open Externally",
        openFullScreen: "Open In Fullscreen",
        LOOKBOOK: "LOOKBOOK",
        NEWSPAPERADVERTISING: "NEWSPAPER ADVERTISING",
        DM: "DM",
        ROMPLAKATER: "ROMPLAKATER",
        FORSALEPOSTER: "FOR SALE POSTER",
        lookbookSalesTask: 'Lookbook / Sales Task',
        newsPaperAdvertising: 'Newspaper Advertising',
        forSalePoster: 'FOR SALE POSTER',
        /*------------------------Professional--------------------------*/

        /*--------------------------Property Dashboard--------------*/

        agent: "Agent",
        subAgent: "Sub-Agents",
        inspection: "Inspection",
        photo: "Photo",
        styling: "Styling",
        infoFromSeller: "Info From Seller",
        marketing: "Marketing",
        views: "Views",
        bids: "Bids",
        statistics: "Statistics",
        notification: "Notifications",
        buyer: "Buyer",
        documents: "Documents",
        sellerOrder: "Seller's Orders",
        timeline: "Timeline",

        /*------------------------Marketing-------------------------*/

        attachLink: "Attach Link",
        commentsFromSeller: "Comments From Seller",
        seeFinnAd: "See Finn Ad",
        dsAssignment: "DS Assignment",
        marketingMaterials: "Marketing Materials",

        /*------------------Marketing materials------------------*/

        marketingMaterialsInst:
            "Here you upload marketing material to the customer who is not finn.no or DS. For example, Lookbook, Sales statement in PDF, newspaper advertisement etc.",
        uploadDoc: "Upload Document",
        approvedOn: "Approved On",
        notApprovedYet: "Not Approved Yet",
        docTitle: "Document Title",

        /*------------------------Status And CheckPoints-----------------------*/

        bookInspection: "Book Inspection",
        energyLabeling: "Energy Labeling",
        sellersInterview: "Seller's Interview",
        takst: "Takst",
        approveDS: "Approve DS",
        finnNo: "Finn.no",
        settlementInfo: "Settlement Info",
        sendNotiAfter48HrsLogin: "Send Notification after 48hrs of First Log In",
        sendNotiAfter24HrsPosting: "Send Notification after 48hrs of First Log In",
        otherReminder: "Other Reminders",
        pushNotificationDescription: "Push Notification Description",
        writeNotiHere: "Write Push Notification Description Here...",
        sendNotiTo: "Send Notification to?",
        pushNotifications: "Push Notification",
        pushNotificationDesc: "Push Notification Description",

        /*-----Styling------*/

        stylingInfo:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        styleType: "Styling Type :",
        inspectionAgent: "Inspection Agent :",

        /*-----Sellerinformation-----*/

        sellerInformation: "Seller Information",
        uploadPhoto: "Upload Photos",

        additionalervicesOrdered: "Additional Services Ordered :",
        extPhotoPackage: "Extended Photo Package",
        dronePhoto: "Drone Photo",
        sellerName: "Seller Name :",
        Email: "Email :",
        Phone: "Phone :",
        Date: "Date :",
        Time: "Time :",
        comment: "Comment :",
        commentInfo:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
        photoSelected: "Photo Selected",
        uploadintroduction:
            "Upload a photo by pressing the button below. We ask that you do not upload images of a larger size than the maximum required for printing images in A5 format.",
        uploadNewPhoto: "Upload New Photo",
        attachVideoLink: "Attach Video Link",
        attachViewLink: "Attach 360 View Link",
        Photos: "Photos",

        /*-------------------------Notification Success-------------------------*/

        notificationSent: "Notification Sent",
        msgSentSuccess: "Message Sent Successfully.",
        no: "No",
        yes: "Yes",
        rememberTo: "Remember To",
        areYouSureYouWantToSend:
            "Are you sure you want to send notification about Book Inspection?",

        /*----------------Timeline-------------------------------*/

        siteVisit: "Site Visit",
        surveyor: "Surveyor",
        preparation: "Preparation",
        bidRound: "Bid Round",
        sold: "Sold",
        contract: "Contract",
        sellerTimeline: "Seller Timeline",
        buyerTimeline: "Buyer Timeline",

        eSigningOfContractDocuments: "E Signing of Contract Documents",
        signOfJoint: "Sign of Joint (Self-Way, Owner's Section)",
        mortgageOffers: "Mortgage Offers",
        offersOnInsurance: "Offers on Insurance",
        prepareForRelocation: "Prepare for Relocation",
        settlementClearlyPaidForAcquisition:
            "Settlement Clearly Paid for Acquisition",
        takeover: "Takeover",
        shotOffTheLust: "Shot Off the Lust",
        moreMortageOffer: "Click for Mortgage Info",
        clickForInsuranceInfo: "Click for Insuarance Info",
        goToMovingGuide: "Go to Moving Guide",

        /*--------------------Info From Seller----------------------------*/

        letterCode: "Letter Code",
        colorCode: "Color Code",
        howIsHouseHeated: "How is the house heated?",
        energyCerti: "Energy Certificate",
        address: "Address",
        city: "City",
        zipCode: "Zip Code",
        newAddress: "New Address",
        loan: "Loan",
        bank: "Bank",
        denominationKr: "Denomination Kr :",
        residualDebtKr: "Residual Debt Kr :",
        reminderOnDate: "Reminder on Date :",
        contact: "Contact :",
        loanNo: "Loan No",
        shouldTheLoanBeRepaid: "Should the loan be repaid? :",
        receivables: "Receivables",
        accountNoForTransferOfReceivable:
            "Account No. for Transfer of Receivable :",
        additionalLoan: "Additional Loan",
        additionalBank: "Additional bank",
        additionalReceivables: "Additional Receivables",
        percentage: "Percentage",
        firstStep: {
            q1: "What is the reason for you to sell the property?",
            q2: "What do you like best about the home?",
        },
        secondStep: {
            q1: "What facilities apply to your home?",
            q2: "What do you like best about the location of the home?",
            q3:
                "Are there any walking areas / activities that should be highlighted?",
            q4:
                'Briefly describe who your closest neighbors are, for example: "in the apartment on the right there lives a nice couple in their mid-30s"',
            q5: "Do many families with children live in the area?",
            q6: "How are the sun conditions",
            airConditioning: "Air Conditioning",
            alarm: "Alarm",
            resorts: "Resorts",
            balconyTerrace: "Balcony/ Terrace",
            kidFriendly: "Kid Friendly",
            broadband: "Broadband",
            batplass: "Batplass",
            communalWashing: "Communal washing",
            fishing: "Fishing",
            garage: "Garage",
            parkingPlace: "Parking place",
            golfCourse: "Golf course",
            garden: "Garden",
            elevator: "Elevator",
            petsAllowed: "Pets allowed",
            noResidents: "No residents",
            cableTV: "Cable TV",
            offWaterAndWasteWater: "Off. water and wastewater",
            parquet: "Parquet",
            fireplace: "Fireplace",
            hearth: "hearth",
            receptionQuietCentral: "Reception quiet central",
            shoreline: "Shoreline",
            roof: "Roof",
            hiking: "Hiking",
            view: "View",
            expandability: "Expandability",
            janitorial: "Janitorial",
            securityService: "security service",
            guestParking: "Guest parking",
            midSummer: "Mid Summer :",
            midWinter: "Mid Winter :",
        },
        thirdStep: {
            q1: "Describe what, when and by whom?",
            q2:
                "Are there any other qualities of the home you would like to highlight?",
            q3:
                "Have any upgrades / maintenance been done that should be highlighted?",
        },
        fourthStep: {
            q1: "Is there a compulsory membership in welfare society?",
            q2: "Contact information for the leader of the welfare society",
            q3: "Contact info for chairman",
            q4: "How much do you pay in joint costs?",
            q5: "What do the common costs include?",
            q6: "What do the common costs include?",
            perMonth: "Per Month",
            perYear: "Per Year",
            perQuarter: "Per Quarter",
        },
        fifthStep: {
            q1:
                "Are there any agreements, rights or obligations associated with the property?",
            q2: "Is there a garage / parking space attached to the home?",
            q3: "If so, can this be documented?",
            q4:
                "Is it associated with common areas / real estate (playground, ball court etc.)?",
            q5:
                "Is it associated with common areas / real estate (playground, ball court etc.)?",
        },
        sixthStep: {
            q1: "What are the heat sources in the home?",
            q2: "What are the heat sources in the home?",
            q3: "Are pipes in the home changed?",
            q4: "Where is it slated and when?",
            q5: "Where is the hot water tank located?",
            heatOptions: "Heating cables",
            radiator: "Radiator",
            heatPump: "Heat pump",
            balancedVentilation: "Balanced ventilation with heat recovery",
            electricRadiators: "Electric radiators",
            woodBurning: "Woodburning",
            oilHeating: "Oil Heating",
        },
        seventhStep: {
            q1: "Who is the provider of the internet?",
            q2: "What kind of internet is it?",
            q3: "Who is the provider of TV?",
            q4: "How are the TV signals delivered?",
            q5: "How high is the annual average power consumption?",
            q6: "Where is the home's security cabinet located?",
            altiBox: "AltiBox",
            nextGenTel: "NextGenTel",
            telenor: "Telenor",
            broadnet: "Broadnet",
            iceNet: "Ice.net",
            breibandNo: "Breiband.no",
            homen: "Homen",
            telio: "Telio",
            eidsivaBroadband: "Eidsiva Broadband",
            globalConnect: "Global Connect",
            viaBroadband: "Via Broadband",
            other: "Other",
            broadband: "Broadband",
            fiber: "Fiber",
            canalDigital: "Canal Digital",
            viasat: "Viasat",
            riksTv: "RiksTV",
            cableTv: "Cable TV",
            satelliteDish: "Satellite Dish",
            igniter: "Igniter",
        },
        eighthStep: {
            q1: "Are there any appliances that should not be included in the trade?",
            q2: "Where is it slated and when?",
        },
        ninethStep: {
            q1:
                "Norges Eiendomsmeglerforbund has compiled a list of movable property and contents that will accompany the property on sale: https://www.nef.no/losore-og-tilbehor/ Is there anything on this list that should not be included when you are going to sell?",
            q2:
                "Is there any other information you think is relevant to informing a real estate agent and buying about?",
            q3: "When do you want to take over the property?",
        },
        ownerShip: "Ownership",
        housing: "Housing",
        property: "Property",
        location: "Location",
        standard: "Standard",
        sammie: "Sameie",
        skinAssociation: "Skin Association",
        veilag: "Veilag",
        rights: "Rights",
        vvs: "VVS",
        technicalInstallations: "Technical installations",
        appliances: "Appliances",
        other: "Other",
        name: "Name",
        enterName: "Enter Name",
        doNotKnow: "do Not Know",
        next: "Next",
        prev: "Prev",

        /*----------------------------------Bids-----------------------------------------*/

        bidJournal: "Bid Journal",
        active: "Active",
        history: "History",
        accepted: "Accepted",
        expired: "Expired",
        bid: "Bid",
        amount: "Amount",
        dateTime: "Date & Time",
        expiredOn: "Expired On",
        rejected: "Rejected",

        /*----------------Buyer-------------------------------*/

        addBuyer: "Add Buyer",
        infoFromBuyer: "Info from Buyer",
        buyerInfo: "Buyer's Info",
        coBuyerInfo: "Co-Buyers Info",
        propertyInfo: "Property Info",
        enterZipCode: "Enter Zip Code",
        purchase: "Purchase",
        enterAmount: "Enter Amount",
        commune: "Commune",
        enterCommune: "Enter Commune",
        GNR: "GNR",
        enterGNR: "Enter GNR",
        BNR: "BNR",
        enterBNR: "Enter BNR",
        FNR: "FNR",
        enterFNR: "Enter FNR",
        orgNr: "ORG.NR",
        enterOrgNr: "Enter ORG.NR",
        snrAndelsnr: "SNR / ANDELSNR",
        enterSNR: "Enter SNR / ANDELSNR",
        takeoverDate: "Takeover Date",
        enterTakeoverDate: "Enter Takeover Date",
        uploadContractDraft: "Upload Contract Draft",
        registerBuyer: "Register Buyer",
        done: "Done",
        contactInfoBank: "Contact Info Bank",
        bankName: "Bank Name",
        bankContactPerson: "Bank Contact Person :",
        phoneEmailOfContactPerson: "Phone / Email of Contact Person :",
        accountNo: "Account No. :",
        nameOfBuyer1: "Name of Buyer",
        interestInUse: "Interest in Use :",
        nameOfBuyer2: "Name of Buyer 2 :",
        homeOwnersInsurance: "Home Owners Insurance",
        iWantInsurance: "Yes, I want Insurance.",
        contractDocuments: "Contract Documents :",

        /*-----FINANCING------*/

        financingInfo: "Her kan du søke om finansiering på vegne av dine kunder. Det er viktig at du på forhånd har innhentet samtykke til dette av kunden.",
        applicantDetails: "Applicant Details",
        Fname: "First Name",
        PFname: "Enter First Name",
        Lname: "Last Name",
        PLname: "Enter Last Name",
        emails: "Email",
        Pemails: "Enter Email",
        phones: "Phone",
        PPhone: "Enter Phone",
        LoanAmount: "Loan Amount",
        PLoanAmount: "Enter Loan Amount",
        Comments: "Comment",
        PComments: "Write Your Comments Here...",
        coApplicantDetails: "Co-Applicant Details",
        sendButton: "Send",
        agreefinancing:
            "I agree with this to be contacted by mortgage advisors for mortgage information.",

        /*------------Views-----------*/
        upcomingViews: "Upcoming Views",
        completedViews: "Completed Views",
        stakeholders: "Stakeholders",
        selectOption: "Select Option",
        viewName: "Name :",
        viewemail: "Email :",
        viewPhone: "Phone :",
        viewPropertyInformation: "Property Information :",
        viewBidSMSNotification: "Bid SMS Notification :",
        viewUpdatesonSimilarHomes: "Updates on Similar Homes :",
        privateViewing: "Private viewing :",
        communityViews: "Plan for community shows :",
        notifyOnBid: "Notify on bid & property info :",

        /*------seller's Order-------*/
        sellerOrders: `Seller's Orders`,
        sellerInfo:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
        brokeronView: "Broker on View",
        fullView: "360 view",
        totalCosting: "Total Costing",
        extendedView: "Extended Photo Package",
        socialMediaView: "Social Media Viewing Films",

        /*-----------------_Documents----------------*/
        deleteDoc: "Delete Document",
        deleteConfirmation: "Are you sure you want to delete?",
        uploadedForBuyer: "Uploaded For Buyer",
        uploadedForSeller: "Uploaded For Seller",
        thisDocIsFor: "This document is for?",
        /*-------SuperUser--------*/
        Befaringsbekreftelse: "Befaringsbekreftelse",
        noenordframeglerførbefaring: "Noen ord fra megler før befaring",
        pBrokerBeforInspection: "Write a few Words ",

        /*------About W-------*/
        headLine: "Headline",
        pWEiendomsmegling: "W Eiendomsmegling",
        discription: "Description",
        aboutW: "About W",
        uploadPhotoTitle: "Upload Photo",

        /*----B2BServices-----*/

        purchasedService: "Purchased Services",
        additionalService: "Additional Services",
        purchaseBtn: "Purchase",
        /*--------Inspection Application------------*/
        uploadnewphoto: 'Upload New Photo',
        photoselected: ' Photo Selected',
        uploadVideo: 'Upload Video',
        introFilm: 'Intro Film',
        insertIframeLink: 'Insert iframe Link',
        insertLinkHere: 'Insert Link Here',
        surveyConfirmation: 'Befaringsbekreftelse',
        productGallery: 'Product Gallery',
        wordsFromBroker: 'Noen ord fra megler før befaring',

        /*Office template*/
        photoDetails: 'Photo Details',
        imageGallery: 'Image Gallery',
        introText: 'Intro Text',
        services: 'Services',
        standardPhotoPackage: 'Standard Photo Package',
        eveningPhoto: 'Evening Photo',
        film: 'Film',
        setPicture: 'Set Picture',
        oppDragAgreement: 'Oppdrag Agreement',
        oppdragAgreementEdits: 'Oppdrag Agreement Edits',
        uploadLink: 'Upload link',
        replacePhoto: "Replace photo",
        uploadOldImage: "upload old photo",
        addIFrameLink: "Add iFrame link",

        /*--------W befaring----------*/
        questionToAsk: "Spørsmål-å-stille",

        /*-----------------B2c Service---------*/
        addService: "Add service",
        price: "Price",
        add: "Add",
        deleteService: "Delete service",
        fixed: 'Fixed',
        variable: 'Variable',
        openPdf: 'Open PDF',
        gotoAssignment: "Go to Assignment Agreement",
        saveOffer: 'Save Offer',
        nameOfBrokerage: 'Name Of Brokerage',

        yourContactPerson: 'Your contact person',
        agreementOnSaleOf: 'Agreement on sale of',
        agrementConfirm: "I confirm having read and understood the contents of the assignment agreement.",
        coOwnerSign: "Sign Assignment Agreement with BankID",
        gotoAgent: "Goto Agent",
        submit: 'Submit',
        contractSigned: 'The contract agreement has now been signed.',
        meglerSign: "Megler sign",
        contractPerson: "Contracting person",
        birthNumber: "Birth number / org.no :",
        typeID: "Type ID :",
        idNumber: "ID Number :",
        contracter: "Contractor :",
        responsibleBroker: "Responsible broker :",
        realEstateAgent: "Real estate agent :",
        settlementEnterprise: "Settlement Enterprises :",
        scopeOfAssignment: 'Scope of assignment',
        missionDuration: "mission duration",
        contractorRemuneration: "Contractor remuneration",
        gotoDs: "Go to DS",

    },
    no: {

        time: "Tid",

        web: 'web',
        midSummer: "Midtsommers",
        midWinter: "Vinterstid",
        megling: 'megling',
        signIn: 'Logg inn',
        email: 'Epost',
        password: 'Passord',
        forgotYourPassword: 'Glemt passord?',
        forgotPasswordIns: 'Send e-postadressen for kontoen, så sender vi deg en e-post for å tilbakestille passordet ditt',
        send: 'Send',
        home: 'Hjem',
        tasks: 'oppgaver',
        profile: 'Profil',
        viewAllNews: "Vis alle nyheter",
        addFeedBack: "Legg til tilbakemelding",
        editFeedBack: "Rediger tilbakemelding",
        allFeedback: "Alle tilbakemeldinger",
        dashBoard: 'Dashboard',
        calendar: 'Kalender',
        digitalSalesAssignment: 'Digital salgsoppgave',
        properties: 'Eiendommer',
        financing: 'Finansiering',
        professional: 'Faglig',
        resale: "Brukt bolig",
        newDevelopment: "Nybygg",
        logout: 'Logg ut',
        news: "Nyheter",
        attach: 'Lagre',

        photoDesc: 'Er du sikker på at du vil slette dette bildet?',

        upload: 'Last opp',


        commentPlaceholder: 'Skrive dine kommentarer her ...',
        profilePicture: 'Profilbilde',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        phone: 'Telefon',
        title: 'Tittel',
        education: 'Utdanning',
        summaryAboutAgent: "Tekst om megler",
        uploadcv: 'Last opp CV',
        uploadOtherDocuments: 'Last opp andre dokumenter',
        saveButton: 'Lagre',
        cancleButton: 'Avbryt',
        enterFirstName: 'Fornavn',
        enterLastName: 'Etternavn',
        enterEmail: 'Skriv inn e-post',
        enterPhone: 'Skriv inn Telegon',
        enterTitle: "Tittel",
        enterEducation: "Oppgi din utdanning",
        enterSummaryAboutAgent: "Skriv en tekst om megler",
        changePasswordButton: 'Bytt passord',
        agentDetailsTag: 'Meglerdetaljer',
        feedbackTag: "Kunderefferanser",
        changePassword: 'Bytt passord',
        currentPassword: 'Nåværende passord',
        enterCurrentPassword: 'Skriv inn nåværende passord',
        newPassword: 'Nytt passord',
        enterNewPassword: 'Skriv inn nytt passord',
        confirmPassword: 'Bekreft passord',
        enterConfirmPassword: 'Skriv inn bekreft passord',
        updateButton: 'Legg til',
        moving: 'Moving',
        search: 'Søk',
        signatureAssignment: "Signer oppdrag",
        seller2Pending: "Avventer selger 2",
        seller2Signed: "Signert av selger 2",
        seller1Signed: "Signert av selger 1",
        seller1Pending: "Avventer selger 1",
        bothSellerSigned: "Begge selgere signert",
        oppDragNoAssigned: "Oppdragsnr. lagt til",
        completed: "Gjennomført",
        filters: "Filter",
        status: 'Status',
        apply: 'Filtrer',
        reset: 'Tilbakestill',
        phSearchAssignment: 'Søk etter oppdragsnr., addresse eller navn på selger',

        oppDragNo: 'Oppdragsnr. :',


        propertyAddress: 'Eiendommens adresse :',
        seller1: 'Selger 1:',
        seller2: 'Selger 2:',
        date: 'Dato :',

        viewPdf: 'Se PDF',
        cancel: 'Avbryt',
        save: 'Lagre',

        okay: 'Greit',
        rate: 'Takst',
        rateData: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        taxDate: 'Skattedato :',
        valueRate: 'Verdiskurs :',
        loanRate: 'Lånesats :',
        bta: 'BTA :',
        bra: 'BRA :',
        pRom: 'P-ROM :',
        constructionYear: 'Byggeår :',
        downloadPDFButton: 'Last ned PDF',
        seller: 'Selger',
        inspectionDetails: 'Befaring',
        inspectioninfo: 'Når vi selger hjemmet ditt, er det lovpålagt at en av våre meglere kommer til ditt hjem og vedlikeholder hjemmet ditt. Du bestiller tid for dette på denne siden. Megleren vil da kontakte deg for å bekrefte klokkeslettet.',
        agent: 'Megler',
        subAgent: 'Medhjelpere',
        inspection: 'Befaring',
        photo: 'Bilder',
        photos: "Boligfoto",
        styling: 'Styling',
        infoFromSeller: 'Info fra selger',
        marketing: 'Markedsføring',
        views: 'Visninger',
        bids: 'Bud',

        notification: 'Varsler',
        buyer: 'Kjøper',
        documents: 'Dokumenter',
        sellerOrder: 'Selgers bestillinger',
        timeline: 'Tidslinje',
        attachLink: 'Legg ved lenke',
        commentsFromSeller: 'Kommentarer fra selger',
        seeFinnAd: 'Finn.no',
        dsAssignment: 'Digital salgsoppgave',
        marketingMaterials: 'Markedsmateriell',
        marketingMaterialsInst: 'Her laster du opp markedsføringsmateriell til kunden som ikke er finn annonsen eller digital salgsoppgave. For eksempel prospekt, salgsoppgave i PDF, avisannonse, DM osv.',
        uploadDoc: 'Last opp Dokument',
        approvedOn: 'Godkjent',
        notApprovedYet: 'Ikke godkjent ennå',
        docTitle: 'Dokument tittel',
        bookInspection: 'Book befaring',
        energyLabeling: 'Energimerking',
        sellersInterview: 'Selgers intervju',
        takst: 'Takst',

        finnNo: 'Finn.no',
        settlementInfo: 'Oppgjørsinformasjon',

        stylingInfo: 'Her finner du informasjon om selger sine valg vedrørende styling og hva somer avtalt med stylist.',
        styleType: 'Styling type :',
        inspectionAgent: 'Inspeksjonsagent :',
        sellerInformation: 'Selgerinformasjon',
        uploadPhoto: 'Last opp bilder',
        additionalervicesOrdered: 'Tilleggstjenester bestilt :',
        sellerName: 'Selgernavn :',
        Email: 'Epost :',
        Phone: 'Telefon :',
        Date: 'Dato :',
        Time: 'Tid :',
        comment: 'Kommentar :',
        commentInfo: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
        photoSelected: 'Photo selected',
        uploadintroduction: 'Last opp alle bildene ved å trykke på knappen nedenfor. Vi ber om at du ikke laster opp bilder i større størrelse en det som maksimalt er nødvendig for print av bilder i A5 format. Merk også at det kan ta litt tid å laste opp alle bildene dersom du laster opp mange på en gang.',
        uploadNewPhoto: 'Last opp egne bilder',
        attachVideoLink: ' Sett inn link her',
        attachViewLink: 'Legg inn link for 360 visning',
        Photos: 'Bilder',

        no: 'Nei',
        yes: 'Ja',
        rememberTo: 'Påminnelse om',


        surveyor: 'Surveyor',
        preparation: 'Forberedelser til befaring',


        sold: 'Sold',
        contract: 'Kontrakt',
        sellerTimeline: 'Selgers Tidslinje',
        buyerTimeline: 'Kjøpere Tidslinje',
        takeover: 'Overtagelse',


        letterCode: 'Bokstavkode',
        colorCode: 'Fargekode',
        howIsHouseHeated: 'Hvordan er boligen varmet opp?',
        address: 'Adresse',
        city: 'Poststed',
        zipCode: 'Postnummer',
        newAddress: 'Ny addresse :',
        loan: 'Lån',
        bank: 'Bank',
        denominationKr: 'Pålydende kr.',
        residualDebtKr: 'Restgjeld kr.',
        reminderOnDate: 'Saldo restgjeld pr.',
        contact: 'Kontakt',
        contactPerson: 'Kontaktperson',
        loanNo: 'Lånenummer.',
        shouldTheLoanBeRepaid: 'Skal lånet innfris?',
        receivables: 'Tilgodehavende',
        accountNoForTransferOfReceivable: 'Kontonummer',
        additionalLoan: 'Ekstra lån',
        additionalReceivables: 'Additional Receivables',
        percentage: 'Percentage',
        ownerShip: 'Eierforhold',
        housing: 'Boligen',
        property: 'eiendom',
        location: 'Location',
        standard: 'Standard',
        sammie: 'Sameie',
        skinAssociation: 'Velforening',
        veilag: 'Veilag',
        rights: 'Rights',
        vvs: 'VVS',
        technicalInstallations: 'Tekniske installasjoner',
        appliances: 'Hvitevarer',
        other: 'Annet',
        name: 'Navn',
        enterName: 'Skriv inn navn',
        doNotKnow: 'do Not Know',
        next: 'Neste',
        prev: 'Forrige',
        bidJournal: 'Budjournal',
        active: "Aktiv",
        accepted: "Akseptert",
        activeBids: "Budoversikt",
        history: 'Historie',
        aaccepted: "Akseptert",
        expired: "Utgått",
        bid: 'Bud',
        amount: 'Beløp',
        dateTime: 'Dato & tid',


        addBuyer: 'Legg til kjøper',
        infoFromBuyer: 'Info fra kjøper',
        buyerInfo: 'Kjøpers info',
        coBuyerInfo: 'Medkjøpers info',
        propertyInfo: 'Eiendomsinfo',
        enterZipCode: 'Legg inn Postnummer',
        enterCity: 'Skriv inn poststed',
        enterAddress: 'Skriv inn Adresse',
        purchase: 'Kjøp',
        purchasingContract: "Utkast kjøpekontrakt",
        enterAmount: 'Tast inn beløp',
        commune: 'Kommune',
        enterCommune: 'Gå inn i kommune',
        GNR: 'Gnr',
        enterGNR: 'Angi GNR',
        BNR: 'Bnr',
        enterBNR: 'Angi BNR',
        FNR: 'Fnr',
        enterFNR: 'Angi FNR',
        orgNr: 'Org.Nr',
        enterOrgNr: 'Angi ORG.NR',
        snrAndelsnr: 'Snr / Andelsnr',
        enterSNR: 'Angi Snr / Andelsnr',
        takeoverDate: 'Overtagelsesdato',
        enterTakeoverDate: 'Enter Takeover Date',
        uploadContractDraft: 'Last opp kontraktutkast',
        registerBuyer: 'Registrer kjøper',


        contactInfoBank: 'Kontaktinfo Bankk',
        bankName: 'Bank navn :',
        bankContactPerson: 'Kontaktperson for banken :',
        phoneEmailOfContactPerson: 'Telefon / e-post til kontaktperson :',
        accountNo: 'Kontonr. :',
        noBids: "Det er ingen bud på eiendommen enda. Kom tilbake senere.",
        nameOfBuyer1: 'Navn på kjøper ',
        interestInUse: 'Interesse for bruk :',
        nameOfBuyer2: 'Navn på kjøper 2 :',
        homeOwnersInsurance: 'Huseiere forsikring :',
        iWantInsurance: 'Ja, jeg vil ha forsikring.',
        contractDocuments: 'Kommentar:',
        financingInfo: 'Søk om boliglån på vegne av kundene dine. Husk at du alltid må få aksept fra kunden før du sender inn forespørsel om tilbud på boliglån.',
        applicantDetails: 'Søkers detaljer',
        Fname: 'Fornavn',
        PFname: 'Skriv inn fornavn',
        Lname: 'Etternavn',
        PLname: 'Skriv inn etternavn',
        emails: 'Epost',
        Pemails: 'Skriv inn epost',
        phones: 'Telefon',
        PPhone: 'Skriv inn telefon',
        LoanAmount: 'Lånebeløp',
        PLoanAmount: 'Angi lånebeløp',
        Comments: 'Kommentarer',
        PComments: 'Skriv kommentarer her...',
        coApplicantDetails: 'Informasjon om medsøker',
        sendButton: 'Send',
        agreefinancing: 'Jeg bekrefter at jeg har fått kundens samtykke til å sende inn forespørsel om finansiering. Samtykke skal lagres på sak.',
        upcomingViews: 'Kommende visninger',
        completedViews: 'Fullførte visninger',
        stakeholders: 'interessenter',
        stackHolderDS: 'Interessenter fra DS',
        signedInView: 'Fra visningsliste',
        selectOption: "Velg alternativ",
        viewName: 'Navn :',
        viewemail: 'Epost :',
        viewPhone: 'Telefon :',


        sellerInfo: 'Her finner du en oversikt over de tjenester/produkter selger har bestilt.',
        fullView: '360 ​​visning',


        submit: 'Send inn',
        feedbackGivenBy: "Tilbakemelding gitt av",
        feedback: "Tilbakemelding",
        inspectionApplications: "Befaringsapplikasjon",
        /*------------------------------------------------------------*/

        officeTemplate: "Selskapsmal",
        B2BServices: "W Flex tjenester",
        B2CServices: "Tjenester / Utlegg",
        WBefaring: "Befaringsbekreftelse",
        setYourPreference: "Oppdragskostnader",
        printMarketing: 'Print markedsføring',
        variableUttleg: 'Eiendomsavhengig utlegg',
        broker: 'Megler',
        propertyValue: 'Eiendomsverdi',
        boligselgerforsikring: "Boligselgerforsikring",
        boligselgerforsikringInst: "Boligselgerforsikring beskytter deg mot krav fra kjøperen av din bolig. I følge avhendingsloven er du ansvarlig for skjulte feil og mangler i fem år. \n\nNår du tegner boligselgerforsikring er det profesjonelle saksbehandlere, jurister og advokater som behandler alle krav fra kjøper. Med boligselgerforsikring er du dekket opp til kr 10 mill., mot prisavslag, erstatnings-kostnader og heving av kjøpet.",
        premietabell: 'Premietabell',
        missionCost: 'Oppdragskostnader',
        addPackages: 'Legg til pakke',
        selectPackage: ' Velg pakke:',
        propertyDetails: 'Eiendomsdetaljer',
        wBefaring: 'Befaringsbekreftelse',
        inspectionReport: 'Befaringsrapport',
        digitalMarketing: 'Digital markedsføring',
        details: 'detaljer',
        detail: 'Detaljer',
        clientName: 'Navn:',
        clientEmail: 'Epost :',
        clientPhone: 'Telefon :',
        agentName: 'Megler :',
        inspectionDetailsInst: "Klikk nedenfor for å sende befaringsbekreftelse på epost til kunden.",
        sendInspectionConfirmation: 'Send befaringsbekreftelse',
        chooseInpectionTemplate: "Velg befaringsmal",
        chooseVariableUtlegg: "Velg variable utlegg",
        company: 'Selskapsmal',
        personal: 'Personlig',
        uttleggName: "Utlegg navn",
        uttleggPrice: "Utlegg kostnad",
        sendOffer: 'Send tilbud',
        startInspection: 'Start befaring',
        summaryFromBefaring: 'Oppsummering fra befaring',
        editService: "Rediger tjenester",
        serviceName: 'Tjeneste-/produkt navn',
        servicePrice: "Tjenestepris",
        serviceType: "Utleggstype",
        DRONE_MOVIES: 'Dronefilm / bilder',
        CGI: '3d / Illustrasjoner ',
        PHOTO: 'Boligfoto',
        STYLING: 'Styling',
        FULL_VIEW: '360 Visning',
        DRONE: 'Drone',
        VIDEO: 'Film',
        PROPERTY_PHOTO: 'Boligfoto',
        HOUSING_STYLE: 'Styling',
        PICTURE_ILLUSTRATION: '3D PICTURES / ILLUSTRATION',

        office: "Selskap",
        testimonial: 'Fornøyde kunder',
        media: "Presse",
        allMedia: "Alle pressesaker",
        addMedia: 'Legg til pressesak',
        editMedia: "Rediger pressesak",
        summary: 'Kort om',
        selectTime: 'Velg tid',
        selectDate: 'Velg dato',
        startTime: "Tidspunkt",
        StartTime: "Start tidspunkt",
        endTime: "Slutt tidspunkt",
        startTimeCalendar: "Tidligste start tidspunkt",
        endTimeCalendar: "Seneste slutt tispunkt",
        yourPreferablePrice: 'Prisantydning',
        setPercentage: 'Meglerprovisjon',
        rebate: "Avslag på totalprisen",
        fixedPrice: 'Fastpris',
        setPreference: 'Velg dine prefferanser',
        remuneration: "Vederlag",
        chooseTypeOfRemuneration: 'Velg vederlagstype',
        loadMore: "Se flere",
        Sellersinterview: `Selgers intervju`,
        propertyList: "Eiendomsoversikt",
        personalTemplate: "Rediger personlig mal",

        INTROFILM: "SoMe film",
        WADS: "W Ads",
        FINNNO: "Finn.no",
        DIGITALSALESASSIGNMENT: "Digital salgsoppgave",
        SOMEFILM: "SoMe film",
        openExternally: "Åpne i fullskjerm",
        openFullScreen: "Åpne i fullskjerm",
        LOOKBOOK: "Prospekt",
        NEWSPAPERADVERTISING: "Avisannonsering",
        DM: "DM",
        ROMPLAKATER: "Romplakater",
        FORSALEPOSTER: "Til salgs plakat",
        lookbookSalesTask: 'Prospekt',
        newsPaperAdvertising: 'Newspaper advertising',
        forSalePoster: 'FOR SALE POSTER',

        extPhotoPackage: "Extended Photo Package",
        dronePhoto: "Drone Photo",
        eSigningOfContractDocuments: "BankID signering av kontraktsdokumente",
        signOfJoint: "Sign of Joint (Self-Way, Owner's Section)",
        mortgageOffers: "Tilbud på boliglån",
        offersOnInsurance: "Tilbud på forsikring",
        prepareForRelocation: "Tilbud på forsikring",
        settlementClearlyPaidForAcquisition:
            "Settlement Clearly Paid for Acquisition",

        shotOffTheLust: "Shot Off the Lust",
        energyCerti: "Energiattest",


        privateViewing: "Privatvisning :",
        communityViews: "Planlagte fellesvisninger :",
        notifyOnBid: "Notify on bid & property info :",

        /*------seller's Order-------*/

        brokeronView: "Megler på visning",
        selleronView: "Selger på visning",

        extendedView: "Extended Photo Package",
        socialMediaView: "Social Media Viewing Films",

        /*-----------------_Documents----------------*/

        /*-------SuperUser--------*/
        Befaringsbekreftelse: "Befaringsbekreftelse",
        noenordframegler: "Introduksjon",
        noenordframeglerførbefaring: "Noen ord fra megler før befaring",
        pBrokerBeforInspection: "Skriv en tekst til selger før befaring",

        /*------About W-------*/
        headLine: "Overskrift",
        pWEiendomsmegling: "W Eiendomsmegling",
        discription: "Beskrivelse",
        aboutW: "Om W",
        uploadPhotoTitle: "Last opp bilde",

        /*----B2BServices-----*/

        purchasedService: "Bestilte tjenester",
        additionalService: "Utlegg og andre tjenester",
        wFlexAdditionalService: "Tilleggstjenester",
        purchaseBtn: "Bestill",
        /*--------Inspection Application------------*/
        uploadnewphoto: ' Last opp bilde',
        photoselected: 'Bilde valgt',
        uploadVideo: 'Last opp film',
        introFilm: 'Intro Film',
        insertIframeLink: 'Legg til link',
        insertLinkHere: 'Legg til link',
        surveyConfirmation: 'Befaringsbekreftelse',
        productGallery: 'Produkt galleri',
        wordsFromBroker: 'Noen ord fra megler før befaring',

        /*Office template*/
        photoDetails: 'Detaljer',
        imageGallery: 'Bildegalleri',
        introText: 'Introtekst',
        services: 'Tjenester/produkter',
        standardPhotoPackage: 'Standard Photo Package',
        eveningPhoto: 'Evening Photo',
        film: 'Film',
        setPicture: 'Velg bilde',
        oppDragAgreement: 'Rediger oppdragsavtalen',
        oppdragAgreementEdits: 'Rediger oppdragsavtale',
        uploadLink: 'Legg til link',
        replacePhoto: "Endre bilde",
        uploadOldImage: "Last opp før bilde",
        addIFrameLink: "Legg til link",

        /*--------W befaring----------*/
        questionToAsk: "Tips å spørre megler om",

        /*-----------------B2c Service---------*/
        addService: "Legg til tjeneste/produkt",
        price: "Pris",
        add: "Legg til",
        deleteService: "Slett",
        fixed: 'Fast',
        variable: 'Variabel',
        openPdf: 'Åpne PDF',
        gotoAssignment: "Gå til oppdragsavtale",
        saveOffer: 'Lagre tilbud',
        nameOfBrokerage: 'Navn på meglerforetak',

        yourContactPerson: 'Din kontaktperson',
        agreementOnSaleOf: 'Avtale om salg av',
        agrementConfirm: "Jeg bekrefter jeg har lest og forstått innholdet i oppdragsavtalen",
        coOwnerSign: "Signer med BankID",
        gotoAgent: "Gå til megler",

        contractSigned: 'Oppdragsavtalen er nå signert',
        meglerSign: "Signering av megler",
        contractPerson: "Contracting person",
        birthNumber: "Fødselsnr. / Org.nr. :",
        typeID: "Type ID :",
        idNumber: "ID Number :",
        contracter: "Contractor :",
        responsibleBroker: "Ansvarlig megler :",
        realEstateAgent: "Eiendomsmeglerfullmektig :",
        settlementEnterprise: "Oppgjørsforetak :",
        scopeOfAssignment: 'Scope of assignment',
        missionDuration: "Oppdragets varighet",
        contractorRemuneration: "Contractor remuneration",
        gotoDs: "Åpne DS",


        notificationSent: "Melding/påminnelse sendt",
        approveDS: "Godkjenn digital salgsoppgave",
        statistics: "Statistikk",
        signInWithBankId: "Signer med BankID",
        assignmentDetails: 'Oppdragsdetaljer',
        photoTitle: "Bildetittel",
        deleteButton: "Slett",
        deletePhoto: "Slett bilde",
        addOppDragNo: "Legg til oppdragsnr.",
        congratulations: "Gratulerer!",
        youSuccessfullySignedTheAgreement: "Du har nå signert oppdragsavtalen med BankID.",
        otherReminder: "Andre påminnelser/meldinger",
        writeNotiHere: "Skriv melding til kunden her",
        sendNotiAfter48HrsLogin: "Send varsel etter 48 timer med den første logge.",
        sendNotiAfter24HrsPosting: "Send varsel etter 24 timer etter utleggelse.",
        sendNotiTo: "Hvem skal du sende til? " +
            "Send varsel etter 48 timer med den første logge - Sendes automatisk 48t etter første innlogging, trykk for å sende manuelt. " +
            "Send varsel etter 24 timer etter utleggelse - Sendes automatisk 24t etter gjort tilgjengelig for kunden, trykk for å sende manuelt",
        pushNotifications: "Pushmelding",
        pushNotificationDesc: "Tekst for varsel til kunden",
        msgSentSuccess: "Melding sendt",
        areYouSureYouWantToSend: "Er du sikker du vil sende påminnelse vedrørende befaring?",
        siteVisit: 'Site visit',
        bidRound: "Budrunde",
        moreMortageOffer: "Klikk for finansieringinformasjon",
        clickForInsuranceInfo: "Klikk for forsikringinformasjon",
        goToMovingGuide: "Gå til Flytteguiden",
        expiredOn: "Akseptfrist",
        rejected: "Avslått",
        rejectedDate: "Avslått dato",
        done: 'Lagre',
        bidder: "Budgiver",
        viewPropertyInformation: "Eiendomsinformasjon :",
        viewBidSMSNotification: "Bud oppdatering på SMS :",
        viewUpdatesonSimilarHomes: "Informasjon om tilsvarende boliger :",
        sellerOrders: `Selgers bestillinger`,

        deleteDoc: "Slett dokument",
        deleteConfirmation: "Er du sikker på at du vil slette dette?",
        deleteInst: "Er du sikker du vil slette?",
        uploadedForBuyer: " Lastet opp til kjøper",
        uploadedForSeller: "Lastet opp til selger",
        thisDocIsFor: " Hvem skal dette dokumentet sendes til?",
        selectOptions: "Velg alternativ",
        totalCosting: 'Totalpris',
        redirectionUrl: "Du blir videresendt",
        welcomeToBefaring: " Velkommen til befaring med W Eiendomsmegling",
        getStarted: "Kom i gang",
        andel: "Andel",
        eierseksjon: "Eierseksjon",
        aksje: "Aksje",
        selveier: "Selveier",
        all: "Alle",
        propertyType: "Eierform",
        marketingPackages: "Markedspakker",
        marketingPackage: "Markedspakke",
        marketingPackagesGallery: "Markedsføring galleri",
        addPackage: "Legg til markedspakker",
        editPackage: "Rediger markedspakker",
        deleteEventDesc: "Er du sikker du vil slette denne aktiviteten?",
        serviceImage: "Bilder",
        addEvent: "Legg til aktivitet",
        editEvent: "Rediger aktivitet",
        eventTitle: "Aktivitetsnavn",
        enterEventTitle: "Legg til aktivitetsnavn",
        clickToAddEvent: "Legg til aktivitet",
        addNewEvent: "Legg til aktivitet",
        selectProperty: "Velg oppdrag",
        userType: "User type",
        today: "I dag",
        yesterday: "I går",
        chatPlaceholder: "Skriv en melding",
        additionalBank: "Tilleggsbank",
        defaultValue: "Obligatorisk",
        deleteEvent: "Delete event",
        messages: "Meldingers",
        clearAll: "Slett alle",
        eventDetails: "Aktivitetsdetaljer",
        dsHistory: "Digital salgsoppgave historikk",
        viewHistory: "Se historikk",
        vederlag: 'Vederlag',
        utlegg: 'Utlegg',
        servicePriceType: "Service price type",
        newOtherService: "Other services",
        importDS: "Importer Digital salgsoppgave",
        importDsInst: "Er du sikker du vil importere salgsoppgaven på nytt?",
        permissionRequired: "Permission required",
        allowForPermission: "Please allow for permission",
        fileSavedSuccess: "File saved successfully",
        selectProfilePic: 'Select avatar',
        photographer: "Fotograf",
        stylist: "Stylist",
        propertyImageGallery: "Våre unike presentasjoner",
        utenAnticimex: "Uten Anticimex",
        medAnticimex: "Med Anticimex",
        coverImage: "Hovedbilde",
        addNewShowcase: "Legg til eiendom",
        order: "Bestill",

        photoServiceInstruction1:
            "Standard fotopakke er inkludert i den faste prisen. Her kan du bestille tid for fotograf, og også bestille utvidede fototjenester.",
        photoServiceInstruction2:
            "Angi ønsket tidsvindu for når du vil at fotografen skal besøke deg. Minimum tidsvindu er 1 timer. Fotograf vil bekrefte nøyaktig tidspunkt etter bestilling.",
        writeYourComment: "Skriv dine kommentarer her...",
        rateInstruction1:
            "Vi anbefaler alle å selge hjem med en pris- eller tilstandsrapport. Hvis du ordner takstmannen ypurself, sjekk dette i skjemaet nedenfor, og du vil gi et nytt skjema for å legge inn nøkkelinformasjonen og løsningen for å laste opp takstdokumentet. Hvis du vil at vi skal kommunisere med takstmann, velger du tid og bestiller her. Bestillingen er uforpliktende og din bil vil bli kontaktet av og takstmann for ytterligere avklaring av de forskjellige takstproduktene og bekreftelse av tid.",
        rateInstruction2:
            "Angi ønsket tidsvindu for når du vil at takstmann skal besøke deg. Minimum tidsvindu er 1 timer. Takstmann vil bekrefte nøyaktig tidspunkt etter bestilling.",
        chooseYourStyling: "Velg din styling",
        instructionStyling:
            "Å style boligen før salg gir statistisk bedre resultater ved salg. Alle boliger er forksjellige, og en del av prosessen er å finne den løsningen som er best for din bolig. Ta kontakt nedenfor så vil stylist ta kontakt med deg for en uforpliktende prat.",
        instructionStyling2:
            "Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimum tidsvindu er 1 timer.",
        projectName: "Addresse / Navn på prosjekt",
        searchProjectName: "Søk etter navn på prosjekt",
        boligPhoto: "Boligfoto",
        additionalService2: "Bestill ytterligere tjenester"
    }

}
