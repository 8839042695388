import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        marginTop: 20,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    newsCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: 10
    },
    imgNews: {
        height: ThemeUtils.relativeRealWidth(20),
        width: ThemeUtils.relativeRealWidth(20),
        borderRadius: ThemeUtils.relativeRealWidth(3)
    },
    statePrimary: {
        backgroundColor: Color.PRIMARY,
    },
    stateGray: {
        backgroundColor: Color.DARK_GRAY,
    },
    stateSecondary: {
        backgroundColor: Color.SECONDARY,
    },
    taskCont: {
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN/2,
        flexDirection: 'row'
    },
    flexRow:{
        flexDirection:'row'
    },
    statsSingle: {
        flex: 1,
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom:10,
        marginTop:5,
        borderRadius: 8,
    },
    stateMargin:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    statTabMargin:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN/4,
    },
    stateTab:{
        flexDirection:'row',
        flex:1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN*(3/4),
    },
    stats: {
        flex: 1,
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN/2,
        borderRadius: 8
    }
})
