import React, {useEffect, useState} from "react";
//Components
import {ActivityIndicator, Animated, RefreshControl, View} from "react-native";
import {
    AddServiceView,
    CostingAddServiceView,
    CustomButton,
    Dialog,
    Header,
    IndicatorViewPager,
    Label,
    PagerTabIndicator,
} from "components/src/components";
//Network
import {useMutation, useQuery} from "@apollo/react-hooks";
//Utils
import styles from "./styles";

import {
    Color,
    CommonStyle,
    Constants,
    formatPrice,
    Icon,
    IS_WEB,
    showMessage,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import {connect} from "react-redux";
import {PURCHASED_B2B_SERVICES} from "../../../../api/query";
import {PURCHASE_B2B_SERVICE, UN_SUBSCRIBE_SERVICE} from "../../../../api/mutation";

const TABS = [{
    text: Strings.purchasedService,
}, {
    text: Strings.wFlexAdditionalService,
}];

function MyOrders(props) {
    const {history, navigation} = props;

    const scrollY = new Animated.Value(0);

    const [deleteDialogVisible, setDeleteVisible] = useState(false);

    const [serviceForDelete, setServiceForDelete] = useState(null);

    const [selectedServices, setSelectedServices] = useState([]);

    const [purchasedServices, setPurchasedServices] = useState([]);

    const [additionalServices, setAdditionalServices] = useState([]);

    const [unsubService, unSubServiceMutation] = useMutation(UN_SUBSCRIBE_SERVICE, {
        refetchQueries: [{
            query: PURCHASED_B2B_SERVICES,
            variables: {
                status: 1
            }
        }]
    })
    const [purchaseService, purchaseServiceMutation] = useMutation(PURCHASE_B2B_SERVICE, {
        refetchQueries: [{
            query: PURCHASED_B2B_SERVICES,
            variables: {
                status: 1
            }
        }]
    })

    const {refetch, loading, data, error} = useQuery(PURCHASED_B2B_SERVICES, {
        variables: {
            status: 1
        }
    });

    useEffect(() => {
        if (data?.purchased_b2b_services) {
            setPurchasedServices(data.purchased_b2b_services?.filter(item => item?.office_service))
            setAdditionalServices(data.purchased_b2b_services?.filter(item => !item?.office_service))
        }
    }, [data])


    const toggleSelectedService = (id) => {
        // item.isSelected = !item.isSelected;
        let updatedServices = selectedServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1);
        } else {
            updatedServices.push(id);
        }
        setSelectedServices(updatedServices);
    };

    const unSubService = () => {
        let updateService = purchasedServices.slice()
        updateService = updateService.filter(service => service.id !== serviceForDelete.id)
        setPurchasedServices(updateService)
        setDeleteVisible(false)
        unsubService({
            variables: {
                id: serviceForDelete.id,
                office_id: props.user?.office_id
            }
        }).then((data) => {
            setServiceForDelete(null)
            showMessage(data?.data?.remove_b2b_service?.meta?.message, Constants.MessageType.SUCCESS)
        }).catch(() => {
        })
    }

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    const renderMyOrderItem = (item) => {
        return (
            <AddServiceView
                title={item.name}
                price={
                    parseInt(item.price)
                        ? `Kr ${formatPrice(item.price)},-`
                        : item.free_text
                }
                image={item.image}
                name={"disclaimer"}
                desc={item.description}
                mb={9}
            />
        );
    };

    const renderAdditionalServiceItem = (item) => {
        return (
            <AddServiceView
                title={item.name}
                price={
                    parseInt(item.price)
                        ? `Kr ${formatPrice(item.price)},-`
                        : item.free_text
                }
                image={item.image}
                name={"disclaimer"}
                extraNote={item.external_url}
                mb={9}
                desc={item.description}
                isSelected={selectedServices.includes(parseInt(item.id))}
                onPress={() => toggleSelectedService(parseInt(item.id))}
            />
        );
    };

    const renderBillList = (item) => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    let updateService = selectedServices.slice()
                    updateService.splice(selectedServices.indexOf(item.id), 1)
                    setSelectedServices(updateService)
                }}
                                       isDeletable
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.price)},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    };

    const renderPurchasedBillList = (item) => {
        return (
            <View style={styles.billListCont}>
                <CostingAddServiceView onDelete={() => {
                    setDeleteVisible(true)
                    setServiceForDelete(item)
                }}
                                       isDeletable={!item?.office_service || !item.is_default}
                                       title={item.name}
                                       color={Color.TERNARY_TEXT_COLOR}
                                       iconColor={Color.DARK_GRAY}
                                       price={
                                           parseInt(item.price)
                                               ? `Kr ${formatPrice(item.price)},-`
                                               : item.free_text
                                       }
                />
            </View>
        );
    };

    const total = purchasedServices
        .map((item) => parseInt(item.price))
        .reduce((a, b) => a + b, 0);

    const additionalTotal = additionalServices.filter(item => selectedServices.includes(item.id))
        .map((item) => parseInt(item.price))
        .reduce((a, b) => a + b, 0);

    return (
        <View style={styles.cont}>
            <Dialog visible={deleteDialogVisible}
                    title={Strings.deleteService}
                    desc={Strings.deleteInst}
                    onNegativePress={() => setDeleteVisible(false)}
                    onPositivePress={() => unSubService()}/>
            <Header
                tintColor={Color.PRIMARY}
                style={CommonStyle.blankHeader}
                navigation={IS_WEB ? history : navigation}
            />

            <View>
                <View style={styles.contentCont}>
                    <View style={styles.titleCont}>
                        <Icon name={"b2b-and-b2c"} size={ThemeUtils.fontXXLarge}/>
                        <Label xlarge font_medium mt={5} ml={10}>
                            {Strings.B2BServices}
                        </Label>
                    </View>
                </View>
            </View>
            <IndicatorViewPager
                indicator={_renderTabIndicator()}
                style={{flex: 1, backgroundColor: "white"}}
            >
                <View>
                    <Animated.ScrollView
                        refreshControl={<RefreshControl onRefresh={() => refetch()}
                                                        refreshing={loading && data}/>}
                        scrollEventThrottle={1}
                        contentContainerStyle={styles.flexGrow}
                        onScroll={Animated.event(
                            [{nativeEvent: {contentOffset: {y: scrollY}}}],
                            {useNativeDriver: true}
                        )}
                    >
                        {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <View>
                                <View style={styles.orderCont}>
                                    {purchasedServices.map(renderMyOrderItem)}
                                </View>
                                <View style={styles.billCont}>
                                    {purchasedServices.map(renderPurchasedBillList)}
                                    {/*<Hr lineStyle={styles.lineStyle}/>
                                <CostingAddServiceView
                                    title={Strings.totalCosting}
                                    isTotalAmount
                                    mt={5}
                                    mb={5}
                                    color={Color.WHITE}
                                    price={`Kr ${formatPrice(total)},-`}
                                />*/}
                                </View>
                            </View>
                        )}
                    </Animated.ScrollView>
                </View>
                <View>
                    <Animated.ScrollView
                        scrollEventThrottle={1}
                        refreshControl={<RefreshControl onRefresh={() => refetch()}
                                                        refreshing={loading && data}/>}
                        contentContainerStyle={styles.flexGrow}
                        onScroll={Animated.event(
                            [{nativeEvent: {contentOffset: {y: scrollY}}}],
                            {useNativeDriver: true}
                        )}
                    >
                        {loading ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <View>
                                <View style={styles.orderCont}>
                                    {additionalServices.map(renderAdditionalServiceItem)}

                                </View>
                                {selectedServices.length ? (
                                    <>
                                        <View style={styles.billCont}>
                                            {additionalServices.filter(item => selectedServices.includes(item.id)).map(renderBillList)}
                                            {/*<Hr lineStyle={styles.lineStyle}/>
                                            <CostingAddServiceView
                                                title={Strings.totalCosting}
                                                isTotalAmount
                                                mt={5}
                                                mb={5}
                                                color={Color.WHITE}
                                                price={`Kr ${formatPrice(additionalTotal)},-`}
                                            />*/}
                                        </View>
                                        <View style={styles.buttonContainer}>
                                            <CustomButton loading={purchaseServiceMutation.loading}
                                                          font_medium
                                                          onPress={() => {

                                                              purchaseService({
                                                                  variables: {
                                                                      ids: selectedServices.map(item => parseInt(item)),
                                                                      office_id: parseInt(props.user?.office_id)
                                                                  }
                                                              }).then(() => {
                                                                  setSelectedServices([])
                                                              }).catch(() => {
                                                              })
                                                          }}
                                                          mr={ThemeUtils.relativeWidth(1.5)}
                                                          style={{flex: 1}} // Do not remove inline will not work in web
                                                          title={Strings.purchaseBtn}
                                            />

                                            <CustomButton
                                                ml={ThemeUtils.relativeWidth(1.5)}
                                                font_medium
                                                onPress={() => {
                                                    setSelectedServices([])
                                                }}
                                                style={{
                                                    flex: 1,
                                                    borderWidth: 1,
                                                    borderColor: Color.DARK_GRAY,
                                                }} // Do not remove inline will not work in web
                                                textColor={Color.PRIMARY}
                                                bgColor={Color.WHITE}
                                                title={Strings.cancel}
                                            />
                                        </View>
                                    </>
                                ) : null}
                            </View>
                        )}

                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>
        </View>
    );
}

const mapStateToProps = (state) => {
    return {
        property: state.property,
        user: state.user
    };
};
export default connect(mapStateToProps)(MyOrders);
