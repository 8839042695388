import React from 'react'
//Component
import {Image, ScrollView, TouchableOpacity, View} from "react-native";
import {Label, LinearGradient, Ripple} from "components/src/components";
//Third Party
import PropTypes from 'prop-types'
//Utils
import FlatGrid from './FlatGrid'
import {Color, Constants, Icon, openInNewTab, ThemeUtils} from 'components/src/utils'
import styles from './styles'

// import {ADS} from "../../../api/query";

function Dashboard(props) {
    const {dashboardData, place, style, onPressItem} = props;

    // const {data} = useQuery(ADS, {
    //     variables: {
    //         status: 1,
    //         place: place
    //     }
    // });
    const renderDashboardItem = (item, index) => {
        return (
            <Ripple style={[styles.dashboardItem, item.disabled ? {opacity: 0.5} : null]}
                    disabled={item.disabled}
                    key={item.name}
                    onPress={() => onPressItem && onPressItem(item.route, item.id, item)}
                    rippleContainerBorderRadius={ThemeUtils.relativeRealWidth(2.5)}>


                <Image source={item.imageUri ? {uri: `${Constants.APIConfig.STORAGE_URL}${item.imageUri}`} : item.bg}
                       style={styles.imgServices}/>

                <LinearGradient
                    colors={[Color.TRANSPARENT, Color.PRIMARY]}
                    style={[styles.imgServicesOverlay]}>
                    <View style={styles.lblCont}>
                        <Icon name={item.icon}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.TERNARY_TEXT_COLOR}/>
                        <Label small
                               font_medium
                               mt={10}
                               color={Color.WHITE}>
                            {item.name}
                        </Label>
                    </View>
                </LinearGradient>
            </Ripple>


        )
    };

    const renderAds = (item, index) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => {
                                  let url = item.redirect_url;
                                  if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
                                      url = `https://${url}`;
                                  }
                                  openInNewTab(url)
                              }}>
                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.url}`}}
                       style={styles.singleBanner}/>
            </TouchableOpacity>
        )
    };

    return (
        <ScrollView>
            <View>
                <FlatGrid spacing={ThemeUtils.isTablet() ? 20 : undefined}
                    // ListFooterComponent={props?.ads?.map(renderAds) ?? null}
                          itemDimension={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45)}
                          data={dashboardData}
                          renderItem={({item, index}) => renderDashboardItem(item, index)}
                />

                {/*{data?.ads_popup_details.map(renderAds)}*/}
                {/*{dashboardData.map(renderDashboardItem)}*/}
            </View>
        </ScrollView>
    )
}

Dashboard.propTypes = {
    dashboardData: PropTypes.array,
    onPressItem: PropTypes.func
};
export default Dashboard;
