import React, {useState} from 'react'
import {ActivityIndicator, View} from 'react-native'
import {Header,LightBox, IndicatorViewPager, LogoForHeader, Notes, PagerTabIndicator} from "components/src/components";
import {Color, CommonStyle, Constants, decryptId, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import PhotographyTab from "./Tab/Photography";
import PhotographyMobile from "./Mobile/Photography";
import StylingMobile from "./Mobile/Styling";
import StylingTab from "./Tab/Styling";
import CGIMobile from "./Mobile/CGI";
import CGITab from "./Tab/CGI";
import FullViewMobile from "./Mobile/FullView";
import FullViewTab from "./Tab/FullView";
import DroneMobile from "./Mobile/Drone";
import DroneTab from "./Tab/Drone";
import VideoMobile from "./Mobile/Video";
import VideoTab from "./Tab/Video";
import {useQuery} from "@apollo/react-hooks";
import {SELECTED_PROPERTY_SERVICE} from "../../../../../../api/query";
import {connect} from "react-redux";

const TABS = [{
    text: Strings.PHOTO,
}, {
    text: Strings.STYLING,
}, {
    text: Strings.CGI,
}, {
    text: Strings.FULL_VIEW,
}, {
    text: Strings.DRONE,
}, {
    text: Strings.VIDEO,
}];

function Photo(props) {
    const {history, inspection, navigation} = props;
    let viewpager;
    const [visible, setVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    let propertyId = IS_WEB ? decryptId(props.match.params.id) : inspection?.property_id;
    const {loading, data, refetch, error} = useQuery(SELECTED_PROPERTY_SERVICE, {
        variables: {
            status: true,
            propertyId: inspection?.property_id ?? propertyId,
            category: Constants.ServiceCategory.PhotoStyling
        }
    });

    let photoData = data?.selected_property_services?.services ? data?.selected_property_services?.services[0]?.purchased_office_template : {};
    let stylingData = data?.selected_property_services?.services ? data?.selected_property_services?.services[1]?.purchased_office_template : {};
    let cgiData = data?.selected_property_services?.services ? data?.selected_property_services?.services[2]?.purchased_office_template : {};
    let fullViewData = data?.selected_property_services?.services ? data?.selected_property_services?.services[3]?.purchased_office_template : {};
    let dronData = data?.selected_property_services?.services ? data?.selected_property_services?.services[4]?.purchased_office_template : {};
    let videoData = data?.selected_property_services?.services ? data?.selected_property_services?.services[5]?.purchased_office_template : {};

    let photoVendorData = data?.selected_property_services?.service_types[0]?.vendor_services ?? [];
    let stylingVendorData = data?.selected_property_services?.service_types[1]?.vendor_services ?? [];
    let fullViewVendorData = data?.selected_property_services?.service_types[2]?.vendor_services ?? [];
    let droneVendorData = data?.selected_property_services?.service_types[3]?.vendor_services ?? [];

    /*--------For Future changes--------------------*/
    /*let cgiVendorData = data?.selected_property_services?.service_types[2]?.vendor_services ?? []
    let videoVendorData = data?.selected_property_services?.service_types[5]?.vendor_services ?? []*/
    let cgiVendorData = [];
    let videoVendorData = [];

    let isCompanyTemplate = inspection.template_type !== "PERSONAL";

    let selectedPackage = data?.selected_property_services?.marketing_package ? data?.selected_property_services?.marketing_package.filter(item => item.property_package) : [];
    // let selectedPackage=[]
    const renderTabIndicator = () => {
        return (
            <PagerTabIndicator tabs={TABS}
                               scrollEnabled={!ThemeUtils.isTablet()}/>
        )
    };

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack();
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    };

    return (
        <View style={styles.container}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>


            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <IndicatorViewPager ref={(r) => viewpager = r}
                                    indicator={renderTabIndicator()}
                                    scrollEnabled={false}
                                    onPageSelected={(e) => setCurrentPage(e.position)}
                                    style={CommonStyle.flex}>

                    <View>
                        {ThemeUtils.isTablet() ?
                            <PhotographyTab data={photoData}
                                            vendorData={photoVendorData}
                                            isCompanyTemplate={isCompanyTemplate}
                                            packages={selectedPackage}
                                            refetch={refetch}
                                            {...props}/> :
                            <PhotographyMobile isCompanyTemplate={isCompanyTemplate}
                                               packages={selectedPackage}
                                               vendorData={photoVendorData}
                                               data={photoData}
                                               refetch={refetch}
                                               {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <StylingTab isCompanyTemplate={isCompanyTemplate}
                                        packages={selectedPackage}
                                        data={stylingData}
                                        refetch={refetch}
                                        {...props}/> :
                            <StylingMobile isCompanyTemplate={isCompanyTemplate}
                                           packages={selectedPackage}
                                           data={stylingData}
                                           vendorData={stylingVendorData}
                                           refetch={refetch}
                                           {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <CGITab isCompanyTemplate={isCompanyTemplate}
                                    packages={selectedPackage}
                                    data={cgiData}
                                    refetch={refetch}
                                    {...props}/> :
                            <CGIMobile isCompanyTemplate={isCompanyTemplate}
                                       packages={selectedPackage}
                                       data={cgiData}
                                       refetch={refetch}
                                       vendorData={cgiVendorData}

                                       {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <FullViewTab isCompanyTemplate={isCompanyTemplate}
                                         packages={selectedPackage}
                                         data={fullViewData}
                                         refetch={refetch}
                                         {...props}/> :
                            <FullViewMobile isCompanyTemplate={isCompanyTemplate}
                                            packages={selectedPackage}
                                            data={fullViewData}
                                            vendorData={fullViewVendorData}
                                            refetch={refetch}
                                            {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <DroneTab isCompanyTemplate={isCompanyTemplate}
                                      packages={selectedPackage}
                                      data={dronData}
                                      isFocused={currentPage === 4}
                                      refetch={refetch}
                                      {...props}/> :
                            <DroneMobile isCompanyTemplate={isCompanyTemplate}
                                         packages={selectedPackage}
                                         data={dronData}
                                         isFocused={currentPage === 4}
                                         vendorData={droneVendorData}
                                         refetch={refetch}
                                         {...props}/>}
                    </View>
                    <View>
                        {ThemeUtils.isTablet() ?
                            <VideoTab isCompanyTemplate={isCompanyTemplate}
                                      packages={selectedPackage}

                                      data={videoData}
                                      isFocused={currentPage === 5}
                                      refetch={refetch}
                                      {...props}/> :
                            <VideoMobile isCompanyTemplate={isCompanyTemplate}
                                         packages={selectedPackage}
                                         data={videoData}
                                         vendorData={videoVendorData}
                                         isFocused={currentPage === 5}
                                         refetch={refetch}
                                         {...props}/>}
                    </View>
                </IndicatorViewPager>
            )}

        </View>

    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(Photo)
