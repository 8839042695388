import React, {useEffect, useState} from 'react';

import {TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';

import {styles} from './styles';
import {Color, Icon, ThemeUtils} from 'components/src/utils';


function StarRatingInput(props) {

    let rating = props.rating - 1;

    const [fractionRate, setFractionRate] = useState(rating % 1);
    const [ratingSize, setRatingSize] = useState(rating - rating % 1);

    useEffect(() => {
        if (props.rating) {
            let rating = props.rating - 1;
            setFractionRate(rating % 1);
            setRatingSize(rating - rating % 1)
        }
    }, [props.rating]);

    const onPress = (index) => {
        if (!props.disabled && props.onStarSelected) {
            let rate = ratingSize + 1;
            let fraction = 1;
            if (rate === index) {
                if (fractionRate !== 0.5) {
                    fraction = 0.5;
                }
            }
            setFractionRate(fraction);
            setRatingSize(index - 1);
            props.onStarSelected(index + 1 - (fraction % 1));
        }
    };

    const renderStar = (item, index) => {

        let iconName = 'rating';
        let colorName = Color.PRIMARY;
        if (index <= ratingSize) {
            iconName = 'rating';
        } else if (index === ratingSize + 1) {
            if (fractionRate === 0.5) {
                iconName = 'star-half-fill';
            } else if (fractionRate > 0.5) {
                iconName = 'rating';
            } else {
                colorName = Color.PRIMARY_LIGHT;
            }
        } else {
            colorName = Color.PRIMARY_LIGHT;
        }
        return (
            <TouchableOpacity activeOpacity={0.7}
                              style={[{marginEnd: 5}]}
                              onPress={() => onPress(index)}>
                <Icon name={iconName}
                      key={index.toString()}
                      color={colorName}
                      size={props.iconSize}/>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.containerMain}>
            {new Array(5).fill(false).map(renderStar)}
        </View>
    );

}


StarRatingInput.defaultProps = {
    rating: 0,
    iconSize: ThemeUtils.fontXXLarge,
};

StarRatingInput.propTypes = {
    rating: PropTypes.number,
    iconSize: PropTypes.number,
    onStarSelected: PropTypes.func,
    disabled: PropTypes.bool,
};
export default StarRatingInput;
