import React, {useState} from 'react';
//Components
import {Animated, View} from 'react-native';
import {Dashboard, Header} from "components/src/components";
//utils
import {CommonStyle, decryptId, encryptId, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';

import dsAssignment from 'components/src/assets/images/Digital_Sales_Assignment.jpg';
import marketingMaterial from 'components/src/assets/images/MarketingMaterials.jpg';
import seeFinnAd from 'components/src/assets/images/SeeFinnAd.png';


const DASHBOARD = [{
    name: Strings.seeFinnAd,
    icon: 'seller',
    bg: seeFinnAd,
    route: Routes.SeeFinnAd
}, {
    name: Strings.dsAssignment,
    icon: 'digital-sales-assignment',
    bg: dsAssignment,
    route: Routes.DSAssignment
}, {
    name: Strings.marketingMaterials,
    icon: 'marketing-materials',
    bg: marketingMaterial,
    route: Routes.MarketingMaterials
}];


export default function PropertyDashboard(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0);
    const [selectedAgent, setAgent] = useState();

    const propertyId = IS_WEB ? props.history.location?.state?.id ??parseInt(decryptId(props.match.params.id),10) : props.navigation.getParam('id');

    const handleItemPress = (route) => {
        if (route) {
            if (IS_WEB) {
                history.push(`/${route}/${encryptId(propertyId)}`, {
                    id: propertyId
                })
            } else {
                navigation.navigate(route, {
                    id: propertyId
                })
            }
        }
    }

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.marketing}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{flexGrow: 1}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.cont}>
                    <Icon name={'digital-sales-assignment'}
                          style={styles.icTitle}
                          size={ThemeUtils.fontXXLarge}/>
                    <View style={styles.dashboard}>
                        <Dashboard dashboardData={DASHBOARD}
                                   listCont={styles.dashBoard}
                                   onPressItem={handleItemPress}/>
                    </View>
                </View>
            </Animated.ScrollView>

        </View>
    )
}
