import React from 'react';
import {ActivityIndicator, Animated, View} from 'react-native'
//Components
import {CustomButton, Header, Hr, Label, Ripple} from "components/src/components";
//Utils
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {useQuery} from "@apollo/react-hooks";
import {PROPERTY_MARKETING_MATERIAL} from "components/src/api/query";

const MATERIALS = [{
    name: 'Sales Task',
    status: 0,
}, {
    name: 'DM',
    time: 1587037508,
    status: 1,
}];

function MarketingMaterial(props) {

    const scrollY = new Animated.Value(0)
    const {history, navigation} = props;
    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');

    const {data, loading, error} = useQuery(PROPERTY_MARKETING_MATERIAL, {
        fetchPolicy:'cache-and-network',
        variables: {
            id: propertyId,
            status: 1
        }
    });

    const renderMaterials = (item, index) => {
        return (
            <Ripple
                onPress={() => IS_WEB ? history.push(`/${Routes.MarketingMaterialDetails}`, {material: item}) : navigation.navigate(Routes.MarketingMaterialDetails, {material: item})}>
                <View style={styles.materialCont}>
                    <Icon name={'marketing-material-print'}
                          color={Color.PRIMARY}
                          size={ThemeUtils.responsiveScale(28)}/>
                    <View style={styles.lblCont}>
                        <Label font_medium
                               mb={5}>
                            {item?.document?.title}
                        </Label>
                        <Label small>
                            {item.is_approved ? `${Strings.approvedOn} ${moment.utc(item.approved_date).local().format(DateUtils.hh_mm_dd_mm_yyy)}` : Strings.notApprovedYet}
                        </Label>
                    </View>
                    <Icon name={'drop-normal'}
                          color={Color.PRIMARY_LIGHTER}
                          size={16}/>
                </View>
                <Hr/>
            </Ripple>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.marketingMaterials}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <View style={CommonStyle.flex}>

                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View style={styles.cont}>
                        <Icon name={'marketing-materials'}
                              style={styles.icMaterial}
                              size={ThemeUtils.fontXXLarge}/>
                        <Label small
                               mt={10}
                               mb={15}
                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                            {Strings.marketingMaterialsInst}
                        </Label>
                        <Hr/>
                        {loading ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : data?.fetch_market_materials?.length?
                            data?.fetch_market_materials?.map(renderMaterials) : (
                                <Label font_medium
                                       style={CommonStyle.loader}
                                       align={'center'}>
                                    {'Ingen data'}
                                </Label>
                            )
                        }

                    </View>
                </Animated.ScrollView>


            </View>
            <CustomButton title={(
                <>
                    <Icon name={'upload'}
                          size={ThemeUtils.fontSmall}/>
                    {`  ${Strings.uploadDoc}`}
                </>
            )}
                          onPress={() => IS_WEB ? history.push(`/${Routes.UploadDoc}`, {
                              id: propertyId
                          }) : navigation.navigate(Routes.UploadDoc, {
                              id: propertyId
                          })}
                          textColor={Color.PRIMARY_TEXT_COLOR}
                          mb={ThemeUtils.isTablet() ? 40 : 0}
                          style={!ThemeUtils.isTablet() ? {
                              height: 50,
                              borderRadius: 0
                          } : null}/>
        </View>
    )
}

export default MarketingMaterial;
