import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, Image, Keyboard, SafeAreaView, TouchableOpacity, View} from 'react-native'
import {
    AddServiceView,
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator,
    Ripple,
    Toast
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MARKETING_PACKAGE_DETAILS, MARKETING_PACKAGE_LIST, SERVICES} from "../../../../../api/query";
import {
    ADD_MARKETING_PACKAGE_IMAGES,
    CREATE_PACKAGE,
    DELETE_MARKETING_PACKAGE,
    DELETE_PACKAGE_IMAGE,
    REPLACE_PACKAGE_IMAGE,
    SAVE_MARKETING_PACKAGE_DATA,
    UPDATE_PACKAGE
} from "../../../../../api/mutation";
import {connect} from "react-redux";
import PhotoDetails from "components/src/components/view/PhotoDetails";

const TEXT_OPTIONS = [{
    name: Strings.price,
    value: 0
}, {
    name: Strings.other,
    value: 1
}];

let toast;
const scrollY = new Animated.Value(0);
const scrollYModal = new Animated.Value(0);
const MarketingPackages = props => {


    const {history, user, navigation} = props;

    const [headLines, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);

    const [introText, setIntroText] = useState("");
    const [errIntroText, setErrIntro] = useState(null);

    const [image, setImage] = useState();
    const [imageForPreview, setImageForPreview] = useState();

    const [loadMore, setFetchMore] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [errName, setErrName] = useState(null);

    const [description, setDescription] = useState("");
    const [errDescription, setErrDescription] = useState(null);

    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);

    const [addServiceVisible, setAddServiceVisible] = useState(false);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const [serviceForEdit, setServiceForEdit] = useState(null);

    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false)

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);

    const [selectedService, setSelectedServices] = useState([]);
    const [serviceTextType, setServiceTextType] = useState(TEXT_OPTIONS[0].value);

    useEffect(() => {
        if (image) {
            setImageForPreview(URL.createObjectURL(image))
        }
    }, [image])

    const [createPackage, createPackageMutation] = useMutation(CREATE_PACKAGE, {
        refetchQueries: [{
            query: MARKETING_PACKAGE_LIST,
            variables: {
                input: {
                    first: 30,
                    page: 1,
                    status: true,
                }
            }
        }]
    });

    const [updatePackage, updatePackageMutation] = useMutation(UPDATE_PACKAGE, {
        refetchQueries: [{
            query: MARKETING_PACKAGE_LIST,
            variables: {
                input: {
                    first: 30,
                    page: 1,
                    status: true,
                }
            }
        }]
    });

    const [deletePackage, deletePackageMutation] = useMutation(DELETE_MARKETING_PACKAGE, {
        refetchQueries: [{
            query: MARKETING_PACKAGE_LIST,
            variables: {
                input: {
                    first: 30,
                    page: 1,
                    status: true,
                }
            }
        }]
    });

    const packageDetailsQuery = useQuery(MARKETING_PACKAGE_DETAILS)

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_PACKAGE_IMAGE, {
        onCompleted: () => {

        }
    });

    const [replaceImage, replaceImageMutation] = useMutation(REPLACE_PACKAGE_IMAGE, {
        onCompleted: () => {

        }
    });

    const [savePackageDetails, savePackageDetailsMutation] = useMutation(SAVE_MARKETING_PACKAGE_DATA, {
        onCompleted: () => {

        }
    });

    const [uploadImages, uploadImagesMutation] = useMutation(ADD_MARKETING_PACKAGE_IMAGES, {
        onCompleted: (data) => {
            packageDetailsQuery.refetch();
        }
    });


    useEffect(() => {
        if (packageDetailsQuery?.data?.marketing_package_detail) {
            setHeadLines(packageDetailsQuery?.data?.marketing_package_detail?.heading);
            setIntroText(packageDetailsQuery?.data?.marketing_package_detail?.description)
        }
    }, [packageDetailsQuery.data])

    const psQuery = useQuery(SERVICES, {

        variables: {
            status: true,
            category: Constants.ServiceCategory.PhotoStyling
        }
    })

    const dsQuery = useQuery(SERVICES, {

        variables: {
            status: true,
            category: Constants.ServiceCategory.DigitalMarketing
        }
    })

    const pmQuery = useQuery(SERVICES, {
        variables: {
            status: true,
            category: Constants.ServiceCategory.PrintMarketing
        }
    })

    const {loading, data, error, fetchMore} = useQuery(MARKETING_PACKAGE_LIST, {
        fetchPolicy: 'network-only',
        variables: {
            input: {
                first: 30,
                page: 1,
                status: true,
            }
        }
    });


    useEffect(() => {
        setErrName(null);
        setErrServicePrice(null)
        setErrDescription(null)
        if (!addServiceVisible) {
            setServiceForEdit(null)
            setServiceName("");
            setDescription("");
            setServicePrice("");
            setImage(null)
            setSelectedServices([])
        }
    }, [addServiceVisible]);


    useEffect(() => {
        if (serviceForEdit) {
            setServiceName(serviceForEdit?.name);
            setSelectedServices(serviceForEdit?.marketing_package_service?.map(item => item?.office_service_id))
            setDescription(serviceForEdit?.description)
            setServicePrice(serviceForEdit?.is_free ? serviceForEdit?.free_text : parseFloat(serviceForEdit?.price).toString())
        } else {
            setServiceName("");
            setDescription("");
            setServicePrice("");
        }
    }, [serviceForEdit]);


    const validate = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('name', serviceName);
        let errPrice = validation('servicePrice', servicePrice);
        let errDesc = validation('description', description);

        if (!errHead && !errPrice && !errDesc) {

            if (selectedService.length) {
                let variables = {
                    name: serviceName,
                    price: parseInt(servicePrice),
                    description: description,
                    image: image,
                    services: selectedService
                }
                if (serviceForEdit) {
                    updatePackage({
                        variables: {
                            id: serviceForEdit?.id,
                            ...variables
                        }
                    }).then((data) => {
                        setAddServiceVisible(false);
                        showMessage(data?.data?.edit_marketing_package?.meta?.message, Constants.MessageType.SUCCESS)
                    }).catch(() => {

                    })
                } else if (image) {
                    createPackage({
                        variables: variables
                    }).then((data) => {
                        setAddServiceVisible(false);
                        showMessage(data?.data?.create_marketing_package?.meta?.message, Constants.MessageType.SUCCESS)
                    }).catch(() => {

                    })
                } else {
                    showMessage(MessageUtils.Errors.image, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.services, Constants.MessageType.FAILED)
            }


        } else {
            setErrName(errHead);
            setErrServicePrice(errPrice)
            setErrDescription(errDesc)
        }
    };

    const onDelete = () => {
        deletePackage({
            variables: {
                id: serviceForEdit?.id
            }
        }).then((data) => {
            setDeleteDialogVisible(false)
            setAddServiceVisible(false)
            showMessage(data?.data?.delete_marketing_package?.meta?.message, Constants.MessageType.SUCCESS)
        }).catch(() => {

        })
    };

    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };

    const addOrRemoveItemFromArray = (item) => {
        let updatedServices = selectedService.slice();
        if (updatedServices.includes(item.id)) {
            updatedServices.splice(updatedServices.indexOf(item.id), 1)
        } else {
            updatedServices.push(item.id)
        }
        setSelectedServices(updatedServices)
    }

    const savePackageData = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('headline', headLines);
        let errIntro = validation('intro', introText);

        if (!errHead && !errIntro) {
            savePackageDetails({
                variables: {
                    heading: headLines,
                    desc: introText
                }
            })
        } else {
            setErrHeadLines(errHead);
            setErrIntro(errIntro);
        }
    }

    const renderServiceDetails = (item, index, self) => {

        return (
            <AddServiceView key={index.toString()}
                            onPress={() => addOrRemoveItemFromArray(item)}
                            isSelected={selectedService.includes(item.id)}
                            mb={15}
                            image={item.image}
                            title={item.name}
                            price={item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                            desc={item.description}
                            name={'disclaimer'}/>
        )
    };

    const renderService = (item, index) => {
        if (!item?.office_template?.office_services.length) {
            return;
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       small
                       mt={10}
                       mb={10}>
                    {`${item.name} :`}
                </Label>
                {item?.office_template?.office_services?.map(renderServiceDetails)}
            </View>
        )
    };

    const renderAddEditModal = () => {
        return (
            <Modal visible={addServiceVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setAddServiceVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Toast ref={r => toast = r}/>
                        <Dialog title={Strings.deleteService}
                                visible={deleteDialogVisible}
                                loading={deletePackageMutation.loading}
                                desc={Strings.deleteConfirmation}
                                onNegativePress={() => setDeleteDialogVisible(false)}
                                onPositivePress={() => onDelete()}/>
                        <Header animatedTitle={Strings.marketingPackages}
                                headerRightFirst={serviceForEdit ? 'delete' : null}
                                onPressRightFirst={() => setDeleteDialogVisible(true)}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                onPressLeft={() => setAddServiceVisible(false)}
                                animatedValue={scrollYModal}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             keyboardShouldPersistTaps={'always'}
                                             contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollYModal}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.topContainer}>


                                <FilePicker multiple
                                            style={{
                                                marginTop: ThemeUtils.relativeRealHeight(6),
                                                alignSelf: 'flex-start'
                                            }}
                                            type={FileType.IMAGE}
                                            onFilePicked={(data, files) => {
                                                if (files.length) {
                                                    setImage(files[0])
                                                }
                                            }}>
                                    {image ? (
                                        <Image style={styles.SubViewIcon}
                                               source={imageForPreview}/>
                                    ) : serviceForEdit?.image_url ? (
                                        <Image style={styles.SubViewIcon}
                                               source={{uri: `${Constants.APIConfig.STORAGE_URL}${serviceForEdit?.image_url}`}}/>
                                    ) : (
                                        <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.PRIMARY_LIGHT}/>
                                        </View>)}
                                </FilePicker>

                                <InputField onFocus={() => setErrName(null)}
                                            mt={20}
                                            error={errName}
                                            type={'text'}
                                            value={serviceName}
                                            onChange={setServiceName}
                                            labelText={Strings.marketingPackages}/>

                                <InputField type={serviceTextType === 1 ? 'text' : 'number'}
                                            onFocus={() => setErrServicePrice(null)}

                                            error={errServicePrice}
                                            value={servicePrice}
                                            onChange={setServicePrice}>
                                    {serviceTextType === 1 ? null : (<View style={CommonStyle.inputExtra}>
                                        <Label font_medium>
                                            {'Kr.'}
                                        </Label>
                                    </View>)}
                                </InputField>

                                <InputField textArea
                                            onFocus={() => setErrDescription(null)}
                                            error={errDescription}
                                            type={'text'}
                                            value={description}
                                            onChange={setDescription}
                                            labelText={Strings.discription}/>

                                <Label font_medium
                                       mt={10}
                                       mb={10}>
                                    {`${Strings.PHOTO}/${Strings.STYLING}`}
                                </Label>
                                {psQuery?.data?.services?.map(renderService)}
                                {dsQuery?.data?.services?.filter(item => item?.office_template?.office_services.length)?.length ? (
                                    <Label font_medium
                                           mt={10}
                                           mb={10}>
                                        {Strings.digitalMarketing}
                                    </Label>) : null}
                                {dsQuery?.data?.services?.map(renderService)}
                                {dsQuery?.data?.services?.filter(item => item?.office_template?.office_services.length)?.length ? (
                                    <Label font_medium
                                           mt={10}
                                           mb={10}>
                                        {Strings.printMarketing}
                                    </Label>) : null}
                                {pmQuery?.data?.services?.map(renderService)}
                            </View>
                        </Animated.ScrollView>
                        <View style={styles.bottomButtonContainer}>
                            <CustomButton
                                loading={serviceForEdit ? updatePackageMutation.loading : createPackageMutation.loading}
                                style={{flex: 0.45}}
                                title={serviceForEdit ? Strings.save : Strings.add}
                                onPress={validate}/>
                            <CustomButton bgColor={Color.WHITE}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          borderColor={Color.PRIMARY}
                                          borderWidth={1}
                                          style={{flex: 0.45}}
                                          title={Strings.cancel}
                                          onPress={() => {
                                              if (!IS_WEB) {
                                                  Keyboard.dismiss()
                                              }
                                              setAddServiceVisible(false)
                                          }}/>
                        </View>
                    </View>
                </SafeAreaView>

            </Modal>
        )
    };

    const renderPackageDetails = () => {
        return (
            <View style={styles.introTextCont}>
                <Label font_medium>
                    {Strings.headLine}
                </Label>
                <InputField
                    mt={10}
                    align={'center'}
                    type={'text'}
                    error={errHeadline}
                    onFocus={() => setErrHeadLines(null)}
                    value={headLines}
                    onChange={setHeadLines}
                />
                <Label font_medium>
                    {Strings.introText}
                </Label>
                <InputField
                    error={errIntroText}
                    onFocus={() => setErrIntro(null)}
                    mt={10}
                    align={'center'}
                    type={'text'}
                    textArea
                    value={introText}
                    onChange={setIntroText}
                />

                <CustomButton loading={savePackageDetailsMutation.loading}
                              onPress={savePackageData}
                              title={Strings.save}/>

            </View>
        );
    };

    const renderIntroDetails = options => {
        return (
            <View>
                {renderPackageDetails()}
                {renderAddEditModal()}
                {options?.map((item, index) => {
                    return (
                        <Ripple key={index}
                                onPress={() => {
                                    setServiceForEdit(item);
                                    setAddServiceVisible(true)
                                }}>
                            <Hr/>

                            <View style={styles.optionContainer}>
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.image_url}`}}
                                       style={styles.img}/>
                                <View style={CommonStyle.flex}>
                                    <Label font_medium
                                           mb={5}>
                                        {item.name}
                                    </Label>
                                    <Label small
                                           mb={5}
                                           numberOfLines={2}>
                                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                                    </Label>
                                    <Label small
                                           color={Color.DARK_GRAY}
                                           numberOfLines={2}>
                                        {item?.description}
                                    </Label>
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'drop-normal'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                            {index === options.length - 1 ? <Hr/> : null}
                        </Ripple>
                    )
                })}
            </View>
        );
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={[
            {
                text: `${Strings?.marketingPackage}`
            }, {
                text: Strings.imageGallery
            }]}/>;
    };

    const renderImageGrid = (data, isDeletable) => {
        console.log(data)
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                        onPress={() => {
                            if (selectionEnable) {
                                addOrRemoveImage(item.id)
                            } else {
                                setImageForPreview(item)
                                setPhotoDetailVisible(true)
                            }
                        }}

                        activeOpacity={0.7}>

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}

                               style={styles.GridImage}/>
                        {selectionEnable && selectedImages.includes(item.id) ? (<View style={[styles.selectedImage]}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.TERNARY_TEXT_COLOR}/>
                        </View>) : null}


                    </TouchableOpacity>

                )
            })}
        </View>
    };

    const renderPhotoGallery = () => {
        return (
            <>
                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>

                <FilePicker multiple
                            style={{alignSelf: 'flex-start'}}
                            type={FileType.IMAGE}
                            onFilePicked={(data, files) => {
                                if (files.length) {
                                    uploadImages({
                                        variables: {
                                            photos: files,
                                            id: packageDetailsQuery?.data?.marketing_package_detail?.id
                                        }
                                    }).catch()
                                }
                            }}>
                    <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                        {uploadImages?.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<Icon name={'add'}
                                   size={ThemeUtils.responsiveScale(60)}
                                   color={Color.PRIMARY_LIGHT}/>)}

                    </View>
                </FilePicker>
                <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={deleteVisible}
                                onNegativePress={() => setDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteImages({
                                        variables: {
                                            ids: selectedImages,
                                            detailsId: packageDetailsQuery?.data?.marketing_package_detail?.id
                                        }
                                    }).then(() => {
                                        setSelectedImages([])
                                        packageDetailsQuery.refetch()
                                    }).catch(() => {

                                    })
                                    setDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}
                {/*{renderImageGrid(service?.office_service_media ?? [])}*/}
                {console.log(packageDetailsQuery?.data?.marketing_package_detail?.MarketingPackageImages, "DATa")}
                {renderImageGrid(packageDetailsQuery?.data?.marketing_package_detail?.MarketingPackageImages ?? [])}
            </>
        );
    };

    return (
        <View style={CommonStyle.flex}>

            <Header animatedTitle={Strings.marketingPackages}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>

            <View style={styles.titleContainer}>

                <Icon
                    size={ThemeUtils.fontXXLarge}
                    name={'marketing-package'}
                    color={Color.PRIMARY_DARK}/>
            </View>
            <IndicatorViewPager keyboardShouldPersistTaps={'always'}

                                indicator={renderTabIndicator()}
                                style={{flex: 1, backgroundColor: 'white'}}>

                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         keyboardShouldPersistTaps={'always'}
                                         contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}>
                        <View style={styles.container}>
                            {loading && !data ? (
                                <ActivityIndicator color={Color.PRIMARY}/>
                            ) : renderIntroDetails(data?.marketing_packages?.data ?? [])}

                            {data?.marketing_packages?.paginatorInfo?.hasMorePages ?
                                (
                                    <CustomButton title={Strings.loadMore}
                                                  loading={loadMore}
                                                  mt={10}
                                                  mb={10}
                                                  style={{
                                                      width: ThemeUtils.relativeWidth(40),
                                                      alignSelf: 'center'
                                                  }}
                                                  onPress={() => {
                                                      setFetchMore(true)
                                                      if (data?.marketing_packages?.paginatorInfo?.hasMorePages && !loading) {
                                                          fetchMore({
                                                              variables: {
                                                                  input: {
                                                                      first: 30,
                                                                      page: (data?.marketing_packages?.paginatorInfo?.currentPage ?? 0) + 1,
                                                                      status: 1,
                                                                      key: "",
                                                                      sorting: {field: "id", order: "DESC"},

                                                                  },
                                                              },
                                                              updateQuery: (prev, {fetchMoreResult}) => {
                                                                  setFetchMore(false)
                                                                  if (!fetchMoreResult) {
                                                                      return prev;
                                                                  } else {
                                                                      let finalResult = Object.assign({}, fetchMoreResult);
                                                                      finalResult.marketing_packages.data = [...prev.marketing_packages.data, ...fetchMoreResult.marketing_packages.data];
                                                                      return finalResult;
                                                                  }
                                                              },
                                                          });
                                                      }
                                                  }}/>

                                ) : null}
                        </View>
                    </Animated.ScrollView>
                    <CustomButton borderWidth={0}
                                  onPress={() => {
                                      setServiceForEdit(null);
                                      setAddServiceVisible(true)
                                  }}
                                  title={
                                      <>
                                          <Icon name={'add'}/>
                                          {` ${Strings.addPackage}`}
                                      </>}
                                  borderRadius={0}
                                  mb={ThemeUtils.isTablet() ? 40 : 0}
                                  style={!ThemeUtils.isTablet() ? {
                                      height: 50,
                                      borderRadius: 0
                                  } : null}/>
                </View>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         keyboardShouldPersistTaps={'always'}
                                         contentContainerStyle={styles.flexGrow}>

                        <View style={styles.orderCont}>
                            <Modal visible={isPhotoDetailVisible}
                                   onRequestClose={() => setPhotoDetailVisible(false)}>
                                <PhotoDetails onClose={(isDelete) => {
                                    setPhotoDetailVisible(false)
                                }}
                                              refetchQuery={{
                                                  refetchQueries: [{
                                                      query: MARKETING_PACKAGE_DETAILS
                                                  }]
                                              }}
                                              loading={replaceImageMutation.loading}
                                              onReplaceImage={replaceImage}
                                              deleteMutation={DELETE_PACKAGE_IMAGE}
                                              replaceVariables={{
                                                  id: imageForPreview?.id,
                                                  detailsId: packageDetailsQuery?.data?.marketing_package_detail?.id
                                              }}
                                              variables={{
                                                  ids: [imageForPreview?.id],
                                                  detailsId: packageDetailsQuery?.data?.marketing_package_detail?.id
                                              }}
                                              imageForPreview={imageForPreview}
                                />
                            </Modal>
                            {renderPhotoGallery()}
                        </View>

                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>
        </View>
    )

};

const mapStateToProps = (state) => {
    return {
        property: state.property,
        user: state.user
    };
};
export default connect(mapStateToProps)(MarketingPackages);
