import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,

    },
    drawerHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20
    },
    drawerItemCont: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal: ThemeUtils.relativeWidth(8)
    },
    drawerPadding:{
        paddingVertical:0,
        paddingBottom: ThemeUtils.relativeRealHeight(2),
        paddingHorizontal: ThemeUtils.relativeWidth(8)
    },
    accordionHeaderCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    accordionContainer: {
        backgroundColor: Color.WHITE,
        paddingVertical: 0,
        paddingHorizontal: 0,
    },
    accordionItem: {
        paddingBottom: ThemeUtils.relativeRealHeight(2)
    },
    selectedAccordionItem: {
        backgroundColor: Color.GRAY
    },
    accordionHeader: {
        borderRadius: 0,
        backgroundColor: Color.WHITE,
        paddingHorizontal: 0,
        paddingEnd: ThemeUtils.relativeWidth(8),
        paddingVertical: 0,
    },
    txtRegular: {
        fontFamily: 'Maax'
    },
    logo:{
        alignSelf:'center',
        height:ThemeUtils.responsiveScale(42),
        width:ThemeUtils.responsiveScale(82),
    },
    logoHeader: {
        alignSelf: 'center',
        height: ThemeUtils.responsiveScale(36),
        width: ThemeUtils.responsiveScale(70),
    }
})
