export default {
  'component.offices.title': 'Kontorer',
  'component.offices.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.offices.addoffices': 'Legg til Kontorer',
  'component.offices.officedetails': 'Kontordetaljer',
  'component.offices.servicestosubscribe': 'W Flex tjenester',
  'component.offices.addoffices.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.offices.editoffices': 'Rediger Kontorer',
  'component.offices.feedback': 'Tilbakemelding',
  'component.offices.media': 'Presse',
  'component.offices.editoffices.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.offices.tablesearch': 'Søk etter navn, e-post, telefon og tittel',
  'component.offices.deletemodal.title': 'Slett Kontorer',
  'component.offices.deletemodal.text': 'Er du sikker på at du vil inaktivt denne Kontorer?',
};
