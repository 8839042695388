import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop:ThemeUtils.relativeRealHeight(2)
    },
    displayCont: {
        flexDirection: 'row',
        flex: 1,
    },
    lblCont: {
        flex: 0.4,
        marginEnd:ThemeUtils.relativeRealWidth(3)
    },
    serviceCont: {
        borderWidth: 1,
        borderRadius: ThemeUtils.relativeRealWidth(0.8),
        borderColor: Color.PRIMARY_LIGHT,
        padding:ThemeUtils.relativeRealWidth(2),
        marginVertical: ThemeUtils.relativeRealWidth(1)
    },
    imgCont: {
        flex: 1,
        flexDirection:'row',
        marginHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom:ThemeUtils.relativeRealHeight(2)
    },
    scrollCont: {
        paddingHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom: ThemeUtils.relativeRealHeight(2)
    },
    imgBottom: {
        backgroundColor:Color.GRAY,
        height: ThemeUtils.responsiveScale(100),
        width: ThemeUtils.responsiveScale(178),
        marginEnd:ThemeUtils.relativeRealWidth(3),

    },
    icCont: {
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center',
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: ThemeUtils.relativeRealWidth(3),
        backgroundColor:Color.TRANSPARENT_BLACK,
        marginHorizontal:20,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge
    },
    icContNext: {
        backgroundColor:Color.TRANSPARENT_BLACK,
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: ThemeUtils.relativeRealWidth(3),
        marginHorizontal: 20,
        zIndex: 100,
        alignSelf:'center',
        position: 'absolute',
        end: 0,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge,
        justifyContent: 'center',
        alignItems:'center'
    }
})
