import React, {useEffect, useState} from 'react';
//components
import {ActivityIndicator, Animated, FlatList, SafeAreaView, TextInput, TouchableOpacity, View} from 'react-native';
import {CheckBox, CustomButton, Dialog, Header, Hr, Label, Modal, Ripple, Tag, Toast} from "components/src/components";
//utils
import {
    Color,
    CommonStyle, Constants,
    DateUtils,
    Icon,
    IS_WEB,
    Routes,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {DS_PROPERTY_LISTING} from "components/src/api/query";
import {IMPORT_DS} from "../../../../api/mutation";

const FILTERS = [
    // { name: 'Ukjent', value: '0' },
    {name: 'Aktiv', value: '2'},
    {name: 'Solgt', value: '3'},
    {name: 'Stoppet', value: '4'},
    {name: 'Utløpt', value: '5'},
];

let toast;
export default function SignatureAssignment(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0);
    const scrollYSearch = new Animated.Value(0);

    const [filterVisible, setFilterVisible] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [isImportVisible, setImportVisible] = useState(false);
    const [oppdragNo, setOppdragNo] = useState();
    const [filters, setFilters] = useState([]);
    const [filterParams, setFilterParams] = useState("");

    const [searchText, setSearchText] = useState('');
    const [isFetchMore, setFetchMore] = useState(false);

    const [importDS, importDsMutation] = useMutation(IMPORT_DS, {
        onCompleted: (data) => {
            if (data?.import_ds) {
                showMessage(data?.import_ds?.meta?.message, Constants.MessageType.SUCCESS)
                setOppdragNo(null)
            }
        }
    })

    const {loading, data, error, fetchMore} = useQuery(DS_PROPERTY_LISTING, {
        fetchPolicy:'network-only',
        variables: {
            input: {
                sorting: {
                    field: 'id',
                    order: 'DESC',
                },
                key: searchVisible ? searchText : "",
                status: filterParams,
                first: 30,
                page: 1
            }
        },
        onError: () => {

        }
    });


    useEffect(() => {
        let filterParams = {};

        if (!filterVisible) {
            setFilterParams(filters.toString())
        }
    }, [filterVisible]);


    const navigate = (item) => {
        if (IS_WEB) {
            history.push(Routes.DigitalSalesAssignmentDetails, {
                assignment: item
            })
        } else {
            navigation.navigate(Routes.DigitalSalesAssignmentDetails, {
                assignment: item
            })
        }
    };

    const getSearchBarMarginTop = () => {
        return scrollYSearch.interpolate({
            inputRange: [0, 62],
            outputRange: [110, ThemeUtils.APPBAR_HEIGHT],
            extrapolateRight: 'clamp'
        })
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'Unknown':
                return '#0589CC';
            case 'Stopped':
                return '#E02832';
            case 'Expired':
                return '#E02832';
            case 'Live':
                return '#25CC57';
            case 'Sold':
                return '#E0881B';
            default:
                return '#3A173C';

        }
    };

    const getStatusLabel = (status) => {

        switch (status) {
            case 'Unknown':
                return 'Ukjent';
            case 'Stopped':
                return 'Stoppet';
            case 'Expired':
                return 'Utløpt';
            case 'Live':
                return 'Aktiv';
            case 'Sold':
                return 'Solgt';
            default:
                return 'Ukjent';

        }
    };
    const renderAssignments = ({item, index}) => {
        return (
            <View key={index.toString()}>
                <TouchableOpacity activeOpacity={0.5}
                                  style={styles.assignmentCont}
                                  onPress={() => navigate(item)}>
                    <View style={styles.importCont}>
                        <Tag name={getStatusLabel(item?.vitecPropertyAds?.ads?.adStatus_text)}
                             color={getStatusColor(item?.vitecPropertyAds?.ads?.adStatus_text)}/>
                        {importDsMutation.loading && oppdragNo === item.oppdragsnummer ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<TouchableOpacity style={{padding: 2}}
                                               onPress={() => {
                                                   setOppdragNo(item.oppdragsnummer)
                                                   setImportVisible(true)
                                               }}>
                            <Icon name={'download'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.PRIMARY}/>
                        </TouchableOpacity>)}
                    </View>
                    <Label small
                           mt={15}
                           mb={5}
                           font_medium>
                        {`${item.streetAdress},${item.city},${item.zipCode}`}
                    </Label>
                    <Label small
                           mb={5}>
                        {item.oppdragsnummer}
                    </Label>
                    <Label small
                           color={Color.SECONDARY_TEXT_COLOR}>
                        {moment(item.lastUpdatedDate, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                    </Label>
                </TouchableOpacity>
                <Hr/>
            </View>
        )
    };

    const renderFilterModal = () => {
        return (
            <Modal visible={filterVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setFilterVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setFilterVisible(false)}
                                style={CommonStyle.blankHeader}/>
                        <Animated.ScrollView contentContainerStyle={styles.scrollCont}>
                            <View style={[CommonStyle.flex]}>
                                <View style={styles.filterCont}>
                                    <Label xlarge
                                           font_medium
                                           mb={20}>
                                        {Strings.filters}
                                    </Label>
                                    <Label small
                                           font_medium
                                           mb={10}>
                                        {Strings.status}
                                    </Label>
                                    <CheckBox options={FILTERS}
                                              defaultChecked={filters}
                                              size={ThemeUtils.fontXLarge}
                                              style={styles.chk}
                                              onCheckChanged={setFilters}/>

                                </View>
                                <View style={styles.btnCont}>
                                    <View style={styles.btn}>
                                        <CustomButton title={Strings.apply}
                                                      onPress={() => setFilterVisible(false)}/>
                                    </View>
                                    <View style={styles.btn}>
                                        <CustomButton title={Strings.reset}
                                                      onPress={() => {
                                                          setFilters([]);
                                                          setFilterVisible(false)
                                                      }}
                                                      textColor={Color.PRIMARY}
                                                      borderColor={Color.PRIMARY_LIGHTER}
                                                      borderWidth={1}
                                                      bgColor={Color.WHITE}/>
                                    </View>
                                </View>
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderSearchModal = () => {
        const searchBarMarginTop = getSearchBarMarginTop()
        return (
            <Modal visible={searchVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setSearchVisible(false)}
                                initialMarginLeft={20}
                                animatedTitle={Strings.search}
                                animatedValue={scrollYSearch}/>

                        <Animated.View style={[styles.inptSearchCont, {
                            transform: [{translateY: searchBarMarginTop}],
                        }]}>
                            <Icon name={'search'}
                                  color={Color.PRIMARY_LIGHT}
                                  size={ThemeUtils.fontLarge}/>
                            <TextInput style={[styles.inptSearch, IS_WEB ? {outline: 'none'} : null]}
                                       placeholder={"Søk etter oppdragsnummer, navn på selger, addresse"}
                                       value={searchText}
                                       onChangeText={setSearchText}/>
                            {searchText ? (
                                <Ripple style={styles.icClear}
                                        onPress={() => setSearchText('')}
                                        rippleContainerBorderRadius={ThemeUtils.fontNormal / 2}>
                                    <Icon name={'close'}
                                          color={Color.WHITE}
                                          size={ThemeUtils.fontXXSmall}/>
                                </Ripple>) : null}
                        </Animated.View>

                        <Animated.ScrollView scrollEventThrottle={1}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollYSearch}}}],
                                                 {useNativeDriver: true})}
                                             contentContainerStyle={[styles.scrollCont]}>
                            <View style={styles.searchCont}>

                                {searchText ?
                                    <FlatList
                                        data={data?.ds_property_list?.data}
                                        renderItem={renderAssignments}
                                        keyExtractor={item => item.id.toString()}/> : null}
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Toast ref={r => toast = r}/>
            <Dialog title={Strings.importDS}
                    desc={Strings.importDsInst}
                    visible={isImportVisible}
                    onNegativePress={() => setImportVisible(false)}
                    onPositivePress={() => {
                        importDS({variables: {oppdragNo: oppdragNo}})
                        setImportVisible(null)
                    }}/>
            <Header navigation={IS_WEB ? history : navigation}
                    headerRightFirst={'search'}
                    onPressRightSecond={() => setFilterVisible(true)}
                    onPressRightFirst={() => setSearchVisible(true)}
                    headerRightSecond={'filter'}
                    animatedValue={scrollY}
                    animatedTitle={Strings.digitalSalesAssignment}/>
            {renderFilterModal()}
            {renderSearchModal()}
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <View style={styles.icCont}>
                        <Icon name={'digital-sales-assignment'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.PRIMARY}/>
                    </View>
                </View>
                <Hr/>
                {loading ? (
                    <ActivityIndicator style={styles.icLoader}
                                       color={Color.PRIMARY}/>
                ) : (
                    <FlatList data={data?.ds_property_list?.data ?? []}
                              renderItem={renderAssignments}
                              ListEmptyComponent={
                                  <Label mt={20}
                                         font_medium
                                         align={'center'}>
                                      {"Ingen data"}
                                  </Label>
                              }
                              extraData={data}
                              onEndReachedThreshold={0.2}
                              keyExtractor={item => item.id.toString()}
                    />
                )}

                {data?.ds_property_list?.paginatorInfo?.hasMorePages && !loading ? (
                    <CustomButton title={Strings.loadMore}
                                  ml={ThemeUtils.relativeRealWidth(20)}
                                  mr={ThemeUtils.relativeRealWidth(20)}
                                  mt={ThemeUtils.relativeRealHeight(2)}
                                  mb={ThemeUtils.relativeRealHeight(2)}
                                  loading={isFetchMore}
                                  onPress={() => {
                                      if (data?.ds_property_list?.paginatorInfo?.hasMorePages && !loading) {
                                          setFetchMore(true)
                                          fetchMore({
                                              variables: {
                                                  input: {
                                                      first: 30,
                                                      page: (data?.ds_property_list?.paginatorInfo?.currentPage ?? 0) + 1,
                                                      sorting: {
                                                          field: 'id',
                                                          order: 'DESC',
                                                      },
                                                      key: searchVisible ? searchText : "",
                                                      status: filterParams,
                                                  }
                                              },
                                              updateQuery: (prev, {fetchMoreResult}) => {
                                                  setFetchMore(false)
                                                  if (!fetchMoreResult) {
                                                      return prev;
                                                  } else {
                                                      let finalResult = Object.assign({}, fetchMoreResult);
                                                      finalResult.ds_property_list.data = [...prev.ds_property_list.data, ...fetchMoreResult.ds_property_list.data];
                                                      return finalResult;
                                                  }
                                              },
                                          });
                                      }
                                  }}/>) : null}

            </Animated.ScrollView>
        </View>
    )
}
