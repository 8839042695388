import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import {
    AboutW,
    AddVideo,
    AssignmentDetails,
    B2BServices,
    B2CDigitalMarketing,
    B2CPhotoStyling,
    B2CPrintMarketing,
    B2CServiceDetails,
    B2CServices,
    B2CUttleggServices,
    Befaringsbekreftelse,
    Bids,
    Boligselgerforsikring,
    Broker,
    Buyer,
    Calendar,
    ChangePassword,
    ChatDetails,
    ChatList,
    ClientQuestion,
    CoOwnerSignAgreement,
    DegreeVideo,
    DigitalMarketing,
    DigitalSalesAssignment,
    DigitalSalesAssignmentDetails,
    Document,
    DSAssignment,
    DSHistory,
    Financing,
    FinnAdd,
    ForgotPassword,
    GetStarted,
    InfoFromSeller,
    InspectionApplications,
    InspectionCalendar,
    InspectionDigitalMarketing,
    InspectionMarketingPackages,
    InspectionPhoto,
    InspectionPrintingMarketing,
    InspectionPropertyDetails,
    InspectionPropertyImageList, InspectionPropertyImages,
    IntroFilm,
    Marketing,
    MarketingMaterialDetails,
    MarketingMaterials,
    MarketingPackages,
    MissionCost,
    NewOtherService,
    News,
    NewsDetails,
    Notifications,
    NotificationSuccess,
    Office360View,
    OfficeDigitalMarketing,
    OfficeDrone,
    OfficeFinnAdd,
    OfficeIntroFilm,
    OfficeOppdragAgreement,
    OfficePhoto,
    OfficePhotoStyling,
    OfficePrintMarketing,
    OfficePrintMarketingServiceDetails,
    OfficeStyling,
    OfficeTemplate, OrderPhoto, OrderRate, OrderStyling,
    Photo,
    Photographers,
    PhotoGraphers,
    PhotographerServices,
    PhotoGrapherServices,
    PhotoGrapherServicesDetails,
    PhotoStyling,
    PhotoTemplate,
    Preparation,
    PrintMarketingServiceDetails,
    PrintTemplate,
    ProductGallery,
    Professional,
    ProfessionalDetails,
    Profile,
    PropertyDashboard,
    PropertyImageDetails,
    PropertyImageGallery,
    PropertyListing,
    Questions,
    QuestionToAsk,
    Rate,
    Search,
    SeeFinnAd,
    Seller,
    SellerOrder,
    SignAgreement,
    SignAssignmentSuccess,
    SignatureAssignment,
    SignIn,
    SignSuccess,
    StartInspection,
    StatusAndCheckpoint,
    Styling,
    StylingTemplate,
    Stylists,
    StylistServices,
    Super_User_Dashboard,
    Takst,
    TakstServices,
    Timeline,
    UploadDoc,
    UploadPhoto,
    UserDetails,
    VendorServicesDetails,
    Views,
    WBefaring,
    WBefaringDashboard
} from "components/src/screens";

import {Routes} from "components/src/utils";

import Welcome from '../pages/befaring/before-befaring';
import BefaringQuestion from '../pages/befaring/question-for-agent';
import GetStartedStartInspection from '../pages/befaring/start-inspection';
import OmAgent from '../pages/befaring/about-agent';
import OfferNew from '../pages/befaring/offer-new';
import BefaringAgreement from '../pages/befaring/befaring-assignment-agreement-1';
import BefaringAgreement2 from '../pages/befaring/befaring-assignment-agreement-2';
import Congratulations from '../pages/befaring/congratulations';


function createSwitchNavigator() {
    return (
        <Router basename={'megler'}>

            <Route exact
                   key={"Stylist"}
                   path={`/${Routes.Stylist}`}
                   component={Stylists}/>
            <Route exact
                   key={"StylistServices"}
                   path={`/${Routes.Stylist}/:vendorId`}
                   component={StylistServices}/>
            <Route exact
                   key={"takst"}
                   path={`/${Routes.Takst}`}
                   component={Takst}/>

            <Route exact
                   key={"Photographer"}
                   path={`/${Routes.Photographer}`}
                   component={Photographers}/>

            <Route exact
                   key={"PhotographerServices"}
                   path={`/${Routes.Photographer}/:vendorId`}
                   component={PhotographerServices}/>

            <Route exact
                   key={"VendorServicesDetails"}
                   path={[
                       `/${Routes.Photographer}/:name/:vendorId/:serviceTypeId`,
                       `/${Routes.Stylist}/:name/:vendorId/:serviceTypeId`,
                   ]}
                   component={VendorServicesDetails}/>

            <Route
                exact
                key={"GetStarted"}
                path={`/${Routes.GetStarted}`}
                component={GetStarted}
            />
            <Route
                exact
                key={"ChangePassword"}
                path={`/${Routes.ChangePassword}`}
                component={ChangePassword}
            />
            <Route
                exact
                key={"Profile"}
                path={`/${Routes.Profile}`}
                component={Profile}
            />

            {/*--------------INspection Application--------------*/}
            <Route
                exact
                key={"ClientQuestion"}
                path={`/${Routes.ClientQuestion}`}
                component={ClientQuestion}
            />
            <Route
                exact
                key={"InspectionApplications"}
                path={`/${Routes.InspectionApplication}`}
                component={InspectionApplications}
            />
            <Route
                exact
                key={"InspectionPropertyDetails"}
                path={`/${Routes.InspectionPropertyDetails}/:id`}
                component={InspectionPropertyDetails}
            />

            <Route
                exact
                key={"StartInspection"}
                path={`/${Routes.StartInspection}/:id`}
                component={StartInspection}
            />

            <Route
                exact
                key={"PropertyImageList"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionPropertyImageList}`}
                component={InspectionPropertyImageList}
            />

            <Route
                exact
                key={"InspectionPropertyImageDetails"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionPropertyImageList}/:propertyId`}
                component={InspectionPropertyImages}
            />

            <Route
                exact
                key={"InspectionPhoto"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionPhoto}`}
                component={InspectionPhoto}
            />

            <Route
                exact
                key={"InspectionBroker"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionBroker}`}
                component={Broker}
            />
            <Route
                exact
                key={"InspectionDigitalMarketing"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionDigitalMarketing}`}
                component={InspectionDigitalMarketing}
            />
            <Route
                exact
                key={"InspectionPrintingMarketing"}
                path={`/${Routes.StartInspection}/:id/${Routes.InspectionPrintingMarketing}`}
                component={InspectionPrintingMarketing}
            />

            <Route exact
                   key={'MissionCost'}
                   path={`/${Routes.StartInspection}/:id/${Routes.MissionCost}`}
                   component={MissionCost}
            />

            <Route exact
                   key={'Boligselgerforsikring'}
                   path={`/${Routes.StartInspection}/:id/${Routes.Boligselgerforsikring}`}
                   component={Boligselgerforsikring}
            />
            <Route exact
                   key={'InspectionCalendar'}
                   path={`/${Routes.StartInspection}/:id/${Routes.InspectionCalendar}`}
                   component={InspectionCalendar}
            />


            <Route exact
                   key={'PhotoStyling'}
                   path={`/${Routes.InspectionApplication}/${Routes.PhotoStyling}`}
                   component={PhotoStyling}
            />

            <Route exact
                   key={'PhotoTemplate'}
                   path={`/${Routes.InspectionApplication}/${Routes.PhotoTemplate}/:id`}
                   component={PhotoTemplate}
            />
            <Route exact
                   key={'PrintMarketing'}
                   path={`/${Routes.InspectionApplication}/${Routes.PrintMarketing}`}
                   component={PrintTemplate}
            />
            <Route exact
                   key={'PrintMarketingServiceDetails'}

                   path={`/${Routes.InspectionApplication}/${Routes.PrintMarketingServiceDetails}/:serviceId`}
                   component={PrintMarketingServiceDetails}
            />
            <Route exact
                   key={'StylingTemplate'}
                   path={`/${Routes.InspectionApplication}/${Routes.StylingTemplate}/:id`}
                   component={StylingTemplate}
            />

            <Route exact
                   key={'360DegreeVideo'}
                   path={`/${Routes.InspectionApplication}/${Routes.DegreeVideo}/:id`}
                   component={DegreeVideo}
            />
            <Route exact
                   key={'AddVideo'}
                   path={[`/${Routes.InspectionApplication}/${Routes.AddVideo}/:id`]}
                   component={AddVideo}
            />

            <Route exact
                   key={'AddVideo'}
                   path={[`/${Routes.InspectionApplication}/${Routes.IntroFilm}/:id`]}
                   component={IntroFilm}
            />
            <Route exact
                   key={'DigitalMarketing'}
                   path={`/${Routes.InspectionApplication}/${Routes.DigitalMarketing}`}
                   component={DigitalMarketing}
            />
            {/*  <Route exact
                   key={'IntroFilms'}
                   path={`/${Routes.InspectionApplication}/${Routes.IntroFilm}/:id`}
                   component={IntroFilm}
            />*/}
            <Route exact
                   key={'FinnAd'}
                   path={`/${Routes.InspectionApplication}/${Routes.FinnAd}/:id`}
                   component={FinnAdd}
            />

            <Route key={'W-Befaring'}
                   path={`/${Routes.InspectionApplication}/${Routes.WBefaring}`}
                   exact={false}
                   component={WBefaring}
            />


            {/*-=----------------signature assignement------------*/}
            <Route
                key={Routes.SignatureAssignment}
                path={`/${Routes.SignatureAssignment}`}
                exact={true}
                component={SignatureAssignment}
            />
            <Route
                key={Routes.AssignmentDetails}
                path={`/${Routes.AssignmentDetails}`}
                exact={true}
                component={AssignmentDetails}
            />
            <Route
                key={Routes.SignAssignmentSuccess}
                path={`/${Routes.SignAssignmentSuccess}/:id`}
                exact={true}
                component={SignAssignmentSuccess}
            />

            <Route
                key={Routes.DigitalSalesAssignment}
                path={`/${Routes.DigitalSalesAssignment}`}
                exact={true}
                component={DigitalSalesAssignment}
            />

            <Route
                key={Routes.DigitalSalesAssignmentDetails}
                path={`/${Routes.DigitalSalesAssignmentDetails}`}
                exact={true}
                component={DigitalSalesAssignmentDetails}
            />
            <Route
                key={Routes.Questions}
                path={`/${Routes.StartInspection}/:id/${Routes.Questions}`}
                exact={true}
                component={Questions}
            />
            <Route
                key={Routes.SignAgreement}
                path={`/${Routes.StartInspection}/:id/${Routes.SignAgreement}`}
                exact={true}
                component={SignAgreement}
            />
            <Route key={'Marketing Packages'}
                   path={`/${Routes.StartInspection}/:id/${Routes.MarketingPackages}`}
                   exact={true}
                   component={InspectionMarketingPackages}
            />


            <Route
                key={Routes.SignSuccess}
                path={`/${Routes.SignSuccess}/:totalOwner`}
                exact={true}
                component={SignSuccess}
            />
            <Route
                key={Routes.CoOwnerSignAgreement}
                path={`/${Routes.StartInspection}/:id/${Routes.CoOwnerSignAgreement}`}
                component={CoOwnerSignAgreement}
            />
            {/*--------------INspection Application--------------*/}
            <Route key={"SignIn"}
                   path={`/${Routes.SignIn}`}
                   exact={true}
                   component={SignIn}/>
            <Route
                key={Routes.ForgotPassword}
                path={`/${Routes.ForgotPassword}`}
                exact={true}
                component={ForgotPassword}
            />
            <Route
                key={Routes.News}
                path={`/${Routes.News}`}
                exact={true}
                component={News}
            />
            <Route
                key={Routes.Document}
                path={`/${Routes.Document}/:id`}
                exact={true}
                component={Document}
            />

            <Route
                key={Routes.PropertyListing}
                path={`/${Routes.PropertyListing}`}
                exact={true}
                component={PropertyListing}
            />
            <Route
                key={Routes.Search}
                path={`/${Routes.Search}`}
                exact={true}
                component={Search}
            />
            <Route
                key={Routes.NewsDetails}
                path={`/${Routes.News}/${Routes.NewsDetails}/:news`}
                exact={true}
                component={NewsDetails}
            />
            {/*<Route
                key={Routes.AgentDashboard}
                path={[
                    `/${Routes.AgentDashboard}`,
                ]}
                exact={true}
                component={Super_User_Dashboard}
            />*/}

            <Route
                key={Routes.Rate}
                path={`/${Routes.Rate}/:id`}
                exact={true}
                component={Rate}
            />
            <Route
                key={Routes.Seller}
                path={`/${Routes.Seller}`}

                component={Seller}
            />
            <Route
                key={Routes.Styling}
                path={`/${Routes.Styling}/:id`}
                exact={true}
                component={Styling}
            />
            <Route
                key={Routes.Photo}
                path={`/${Routes.Photo}/:id`}
                exact={true}
                component={Photo}
            />
            <Route
                key={Routes.Professional}
                exact={true}
                path={`/${Routes.Professional}`}

                component={Professional}
            />

            <Route
                key={Routes.ProfessionalDetails}
                path={`/${Routes.Professional}/${Routes.ProfessionalDetails}/:id`}
                component={ProfessionalDetails}
            />

            <Route
                exact
                key={Routes.PropertyDashboard}
                path={`/${Routes.PropertyDashboard}/:address/:id/:oppdrag`}
                component={PropertyDashboard}
            />

            <Route
                key={Routes.OrderPhoto}
                path={`/${Routes.OrderPhoto}/:id`}
                exact={true}
                component={OrderPhoto}
            />

            <Route
                key={Routes.OrderRate}
                path={`/${Routes.OrderRate}/:id`}
                exact={true}
                component={OrderRate}
            />
            <Route
                key={Routes.OrderStyling}
                path={`/${Routes.OrderStyling}/:id`}
                exact={true}
                component={OrderStyling}
            />
            <Route
                key={Routes.Marketing}
                path={`/${Routes.Marketing}/:id`}
                exact={true}
                component={Marketing}
            />
            <Route
                key={Routes.SeeFinnAd}
                path={`/${Routes.SeeFinnAd}`}
                component={SeeFinnAd}
            />

            <Route
                key={Routes.DSAssignment}
                path={`/${Routes.DSAssignment}`}
                component={DSAssignment}
            />
            <Route
                key={Routes.MarketingMaterials}
                path={`/${Routes.MarketingMaterials}`}
                component={MarketingMaterials}
            />
            <Route
                key={Routes.MarketingMaterialDetails}
                path={`/${Routes.MarketingMaterialDetails}`}
                component={MarketingMaterialDetails}
            />
            <Route
                key={Routes.UploadDoc}
                path={`/${Routes.UploadDoc}`}
                component={UploadDoc}
            />
            <Route
                key={Routes.notifications}
                path={`/${Routes.notifications}`}
                component={StatusAndCheckpoint}
            />
            <Route
                key={Routes.NotificationSuccess}
                path={`/${Routes.NotificationSuccess}`}
                component={NotificationSuccess}
            />
            <Route exact
                   key={Routes.Timeline}
                   path={`/${Routes.Timeline}/:id`}
                   component={Timeline}
            />
            <Route
                key={Routes.InfoFromSeller}
                path={`/${Routes.InfoFromSeller}/:id`}
                exact={true}
                component={InfoFromSeller}
            />

            <Route
                key={Routes.Bids}
                path={`/${Routes.Bids}`}
                component={Bids}
            />
            <Route
                key={Routes.Buyer}
                path={`/${Routes.Buyer}/:id`}
                component={Buyer}
            />
            <Route
                key={Routes.UploadPhoto}
                path={`/${Routes.UploadPhoto}`}
                component={UploadPhoto}
            />
            <Route
                key={Routes.Financing}
                path={`/${Routes.Financing}`}
                component={Financing}
            />
            <Route
                key={Routes.Views}
                path={`/${Routes.Views}/:id`}
                exact={true}
                component={Views}
            />
            <Route
                key={Routes.UserDetails}
                path={`/${Routes.UserDetails}`}
                component={UserDetails}
            />
            <Route exact
                   key={Routes.SellerOrder}
                   path={`/${Routes.SellerOrder}/:id`}
                   component={SellerOrder}
            />

            {/*-------------------------------Super User-----------------------------------*/}
            <Route
                key={Routes.Super_User_Dashboard}
                path={[
                    `/${Routes.Super_User_Dashboard}`,
                    `/${Routes.AgentDashboard}`,

                ]}
                exact={true}
                component={Super_User_Dashboard}
            />
            <Route exact={true}
                   key={Routes.Befaringsbekreftelse}
                   path={`/${Routes.WBefaringDashboard}/${Routes.Befaringsbekreftelse}`}
                   component={Befaringsbekreftelse}
            />

            <Route exact={true}
                   key={Routes.AboutW}
                   path={`/${Routes.WBefaringDashboard}/${Routes.AboutW}`}
                   component={AboutW}
            />

            <Route exact={true}
                   key={Routes.Preparation}
                   path={`/${Routes.WBefaringDashboard}/${Routes.Preparation}`}
                   component={Preparation}
            />
            <Route exact={true}
                   key={Routes.ProductGallery}
                   path={`/${Routes.WBefaringDashboard}/${Routes.ProductGallery}`}
                   component={ProductGallery}
            />
            <Route exact={true}
                   key={Routes.QuestionToAsk}
                   path={`/${Routes.WBefaringDashboard}/${Routes.QuestionToAsk}`}
                   component={QuestionToAsk}
            />
            <Route exact
                   key={Routes.B2BServices}
                   path={`/${Routes.B2BServices}`}
                   component={B2BServices}
            />


            <Route exact
                   key={Routes.B2CUttleggServices}
                   path={`/${Routes.B2CServices}/${Routes.B2CUttleggServices}`}
                   component={B2CUttleggServices}
            />

            <Route exact
                   key={Routes.B2CPrintMarketing}
                   path={`/${Routes.B2CServices}/${Routes.B2CPrintMarketing}`}
                   component={B2CPrintMarketing}
            />
            <Route exact
                   key={Routes.B2CDigitalMarketing}
                   path={`/${Routes.B2CServices}/${Routes.B2CDigitalMarketing}`}
                   component={B2CDigitalMarketing}
            />

            <Route exact
                   key={Routes.B2CPhotoStyling}
                   path={`/${Routes.B2CServices}/${Routes.B2CPhotoStyling}`}
                   component={B2CPhotoStyling}
            />
            <Route exact={true}
                   key={Routes.B2CServices}
                   path={`/${Routes.B2CServices}`}
                   component={B2CServices}
            />

            <Route exact={true}
                   key={Routes.B2CServiceDetails}
                   path={[
                       `/${Routes.B2CServices}/${Routes.B2CPhotoStyling}/:name/:id`,
                       `/${Routes.B2CServices}/${Routes.B2CDigitalMarketing}/:name/:id`,
                       `/${Routes.B2CServices}/${Routes.B2CPrintMarketing}/:name/:id`
                   ]}
                   component={B2CServiceDetails}/>

            <Route exact={true}
                   key={Routes.MarketingPackages}
                   path={[
                       `/${Routes.B2CServices}/${Routes.MarketingPackages}`,
                   ]}
                   component={MarketingPackages}/>


            <Route key={Routes.NewOtherServices}
                   path={`/${Routes.B2CServices}/${Routes.NewOtherServices}`}
                   exact={true}
                   component={NewOtherService}
            />

            <Route
                key={Routes.OfficeTemplate}
                path={`/${Routes.OfficeTemplate}`}
                exact={true}
                component={OfficeTemplate}
            />
            <Route exact={true}
                   key={Routes.OfficePhotoStyling}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}`}
                   component={OfficePhotoStyling}
            />
            <Route exact
                   key={Routes.OfficePhoto}
                   path={[
                       `/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.OfficePhoto}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.Office3D}/:id`,
                   ]}

                   component={OfficePhoto}
            />
            <Route exact
                   key={Routes.OfficeDrone}
                   path={[`/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.OfficeDrone}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.OfficeVideo}/:id`
                   ]}

                   component={OfficeDrone}
            />
            <Route exact
                   key={Routes.OfficeStyling}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.OfficeStyling}/:id`}

                   component={OfficeStyling}
            />
            <Route exact
                   key={Routes.Office360View}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePhotoStyling}/${Routes.Office360View}/:id`}
                   component={Office360View}
            />
            <Route exact
                   key={Routes.OfficeDigitalMarketing}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficeDigitalMarketing}`}
                   component={OfficeDigitalMarketing}
            />
            <Route exact
                   key={Routes.OfficeFinnAd}
                   path={[
                       `/${Routes.OfficeTemplate}/${Routes.OfficeDigitalMarketing}/${Routes.OfficeDS}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficeDigitalMarketing}/${Routes.OfficeFinnAd}/:id`
                   ]}
                   component={OfficeFinnAdd}
            />
            <Route exact
                   key={Routes.OfficeIntroFilm}
                   path={[
                       `/${Routes.OfficeTemplate}/${Routes.OfficeDigitalMarketing}/${Routes.OfficeIntroFilm}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficeDigitalMarketing}/${Routes.OfficeWAds}/:id`
                   ]}
                   component={OfficeIntroFilm}
            />
            <Route exact
                   key={Routes.OfficePrintMarketing}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}`}
                   component={OfficePrintMarketing}
            />
            <Route exact
                   key={Routes.OfficePrintMarketingServiceDetails}
                   path={[
                       `/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}/${Routes.OfficePrintMarketingServiceDetails}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}/${Routes.OfficeAvisan}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}/${Routes.OfficeDM}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}/${Routes.OfficeRomplakater}/:id`,
                       `/${Routes.OfficeTemplate}/${Routes.OfficePrintMarketing}/${Routes.OfficeTilsalgs}/:id`,
                   ]}
                   component={OfficePrintMarketingServiceDetails}
            />

            <Route exact
                   key={Routes.OfficePropertyImageGallery}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePropertyImageGallery}`}
                   component={PropertyImageGallery}
            />

            <Route exact
                   key={Routes.OfficePropertyImageGallery}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficePropertyImageGallery}/:propertyId`}
                   component={PropertyImageDetails}
            />

            <Route exact
                   key={Routes.OfficeOppdragAgreement}
                   path={`/${Routes.OfficeTemplate}/${Routes.OfficeOppdragAgreement}`}
                   component={OfficeOppdragAgreement}
            />

            {/*----------W befaring--------------*/}
            <Route
                key={Routes.WBefaringDashboard}
                exact={true}
                path={`/${Routes.WBefaringDashboard}`}
                component={WBefaringDashboard}

            />{/*--------------superm user end0----------------*/}

            <Route
                key={"Velkommen"}
                exact={true}
                path={`/Velkommen/:propertyId`}
                component={Welcome}

            />

            <Route
                key={"question"}
                exact={true}
                path={`/befaring/:propertyId/questions-for-agent`}
                component={BefaringQuestion}

            />

            <Route
                key={"befaringsbekreftelse"}
                exact={true}
                path={`/befaring/start-inspection`}
                component={GetStartedStartInspection}

            />
            <Route
                key={"om-agent"}
                exact={true}
                path={`/befaring/om-agent/:propertyId`}
                component={OmAgent}

            />

            <Route
                key={"bypå"}
                exact={true}
                path={`/befaring/bypå/:propertyId`}
                component={OfferNew}
            />
            <Route
                key={"oppdragsavtale1"}
                exact={true}
                path={`/befaring/:propertyId/oppdragsavtale1`}
                component={BefaringAgreement}
            />
            <Route
                key={"oppdragsavtale2"}
                exact={true}
                path={`/befaring/:propertyId/oppdragsavtale2`}
                component={BefaringAgreement2}
            />

            <Route
                key={"congratulations"}
                exact={true}
                path={`/befaring/gratulerer/:propertyId`}
                component={Congratulations}
            />

            <Route
                exact
                key={Routes.ChatDetails}
                path={[
                    `/${Routes.ChatDetails}/:propertyId/:receiverId`,
                ]}
                component={ChatDetails}
            />

            <Route
                exact
                key={Routes.Chat}
                path={[`/${Routes.Chat}/:propertyId`]}
                component={ChatList}
            />

            <Route
                exact
                key={Routes.Calendar}
                path={`/${Routes.Calendar}`}
                component={Calendar}
            />
            <Route
                exact
                key={Routes.Notifications}
                path={`/${Routes.Notifications}`}
                component={Notifications}
            />

            <Route
                exact
                key={Routes.DSHistory}
                path={`/${Routes.DSHistory}/:id`}
                component={DSHistory}
            />

        </Router>
    );
}

export default createSwitchNavigator();
