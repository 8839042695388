import React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

import Button from "@ant-design/react-native/lib/button";

import ButtonStyle from '@ant-design/react-native/lib/button/style/index';

import {Color, IS_WEB, ThemeUtils} from 'components/src/utils';

const CustomButton = (props) => {

    const onPress = () => {
        if (props.onPress) {
            props.onPress();
        }
    }

    const getFont = () => {
        if (props.font_regular) {
            return 'Maax'
        } else if (props.font_medium) {
            return 'Maax-Medium'
        } else if (props.font_italic) {
            return 'Maax-Italic'
        } else if (props.font_bold) {
            return 'Maax-Bold'
        } else {
            return 'Maax-Medium'
        }

    }
    const getStyles = () => {
        return StyleSheet.create({
            ...ButtonStyle,
            defaultRawText: {
                color: props.textColor,
                fontSize: ThemeUtils.fontSmall,
                fontFamily: getFont(),
                textAlign: props.align
            },
            smallRawText: {
                color: props.textColor,
            },
            smallRaw: {
                height: ThemeUtils.relativeWidth(13),
                maxHeight: 60,
                width: ThemeUtils.isTablet() ? props.maxWidth : undefined,
                alignSelf: ThemeUtils.isTablet() ? 'center' : 'stretch'
            },
            defaultRaw: {
                borderRadius: props.borderRadius,
                borderWidth: props.borderWidth,
                backgroundColor: props.bgColor,
                borderColor: props.borderColor,
                marginTop: props.mt,
                marginBottom: props.mb,
                marginLeft: props.ml,
                marginRight: props.mr,
                ...props.style,
            },
            defaultHighlight: {
                backgroundColor: Color.COLOR_ACCENT,
                borderColor: Color.COLOR_ACCENT,
                color: Color.PRIMARY
            },
            defaultHighlightText: {
                color: Color.PRIMARY
            },
        });
    }
    return (
        <Button styles={getStyles()}
                size={'small'}
                disabled={props.disabled}
                onPress={onPress}>
            {props.loading ? (
                <ActivityIndicator style={IS_WEB ? null : {height: ThemeUtils.fontSmall, width: ThemeUtils.fontSmall}}
                                   color={Color.PRIMARY}/>
            ) : props.title}
        </Button>
    );
};

CustomButton.defaultProps = {
    textColor: Color.WHITE,
    maxWidth: 420,
    color: Color.SECONDARY,
    bgColor: Color.SECONDARY,
    rubik_regular: false,
    rubik_medium: false,
    rubik_bold: false,
    badge: false,
    badgeCount: 0,
    borderColor: Color.PRIMARY,
    borderRadius: 5,
    borderWidth: 0,
    align: 'left',
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    singleLine: false,
    loading: false,

};

CustomButton.propTypes = {

    loading: PropTypes.bool,
    font_medium: PropTypes.bool,
    font_regular: PropTypes.bool,
    font_bold: PropTypes.bool,
    font_italic: PropTypes.bool,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    rubik_medium: PropTypes.bool,
    rubik_regular: PropTypes.bool,
    rubik_bold: PropTypes.bool,
    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
    align: PropTypes.string,
    onPress: PropTypes.func


};
export default CustomButton;
