import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {connect} from "react-redux";
import {CommonStyle} from "../../../../../../../utils";


const QUESTIONS = [{
    value: 1,
    name: "Kan dere fortelle meg litt om situasjonen deres?"
}, {
    value: 2,
    name: "Har dere solgt bolig tidligere?"
}, {
    value: 3,
    name: "Hva er det viktigste for deg nå når du skal selge din bolig?"
},];

function FirstStep(props) {
    const {onChangeAnswer, setForm, interViewForm} = props;

    const [answers, setAnswer] = useState({});

    useEffect(() => {

    }, [answers]);

    const handleChangeText = (text, id) => {
        let updatedAnswer = {...answers};
        updatedAnswer[id] = text;
        setAnswer(updatedAnswer)
    };



    return (
        <View>
            <RadioGroup options={QUESTIONS}
                        selectedOptions={1}
                        style={{marginVertical: 10}}
                        textStyle={CommonStyle.flex}
                        labelProps={{
                            font_medium: true,
                            xlarge: true
                        }}
                        onSelectOption={props.onSelectQuestion}/>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
