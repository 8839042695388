import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    lbl: {
        marginVertical: ThemeUtils.relativeRealHeight(2)
    },
    radio: {
        flexDirection: 'row',
        marginBottom: ThemeUtils.relativeRealHeight(2),
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    }
});

export default styles;
