import {SET_INITIAL_SESSION, SET_INSPECTION_DETAILS, SET_TOKEN, SET_USER,} from './action-types';
import {REHYDRATE} from 'redux-persist/src/constants';

let initial = {
    user: null,
    token: null
};

const reducer = (state = initial, action) => {
    switch (action.type) {
        case SET_USER:
            return Object.assign({}, state, {user: action.user});
        case SET_TOKEN:
            // console.log('TOKEN----------------', action.token);
            return Object.assign({}, state, {token: action.token});
        case SET_INSPECTION_DETAILS:
            return {...state, ...{inspection: action.inspection}}
        case SET_INITIAL_SESSION:
            console.log(action.session)
            return {...state, ...{session: action.session}}
        case REHYDRATE:
            return {...state, ...action.payload};
        default:
            return {...state};
    }
};

export default reducer;
