export default {
  'component.admindashboard.title': 'Salgsstatistikk',
  'component.admindashboard.companyname': 'Selskapsnavn',
  'component.admindashboard.inspection': 'Befaringsapplikasjon',
  'component.admindashboard.sale': 'Salg',
  'component.admindashboard.newassignment': 'Oppdrag',
  'component.admindashboard.omsetning': 'Omsetning (nr. i tusen)',
  'component.admindashboard.commisions': 'Kommisjoner (Nei i tusenvis)',
  'component.admindashboard.settlementincome': 'Oppgjørsinntekt',
  'component.admindashboard.flexlicenserevenue': 'Flex lisensinntekter',
  'component.admindashboard.mortgages': 'Boliglån',
  'component.admindashboard.insurances': 'Forsikring',
  'component.admindashboard.movingservice': 'Flytteservice',
  'component.admindashboard.housingalarm': 'Boligalarm',
  'component.admindashboard.washout': 'Vask ut',
  'component.admindashboard.onlinestore': 'Online-butikk',
};
