import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop:ThemeUtils.relativeRealHeight(2)
    },
    displayCont: {
        flexDirection: 'row',
        flex: 1,
    },
    lblCont: {
        flex: 0.4,
        marginEnd:ThemeUtils.relativeRealWidth(3)
    },
    serviceCont: {
        flex:1,
        borderWidth: 1,
        borderColor: Color.PRIMARY_LIGHT,
        borderRadius: ThemeUtils.relativeRealWidth(0.8),
        padding:ThemeUtils.relativeRealWidth(2),
        marginVertical: ThemeUtils.relativeRealWidth(1)
    },
    imgCont: {
        flex: 1,
        flexDirection:'row',
        marginHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom:ThemeUtils.relativeRealHeight(2)
    },
    scrollCont: {
        paddingHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom: ThemeUtils.relativeRealHeight(2)
    },
    imgBottom: {
        backgroundColor:Color.GRAY,
        height: ThemeUtils.responsiveScale(100),
        width: ThemeUtils.responsiveScale(178),
        marginEnd:ThemeUtils.relativeRealWidth(3),

    },
    icCont: {
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center',
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: ThemeUtils.relativeRealWidth(3),
        backgroundColor:Color.PRIMARY_LIGHT,
        marginHorizontal:20,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge
    },
    icContNext: {
        backgroundColor:Color.TRANSPARENT_BLACK,
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: ThemeUtils.relativeRealWidth(3),
        marginHorizontal: 20,
        zIndex: 100,
        alignSelf:'center',
        position: 'absolute',
        end: 0,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge,
        justifyContent: 'center',
        alignItems:'center'
    },
    modalLblCont: {
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        borderColor: Color.SECONDARY,
        borderWidth: 1,
        borderRadius: 4,
        marginEnd: ThemeUtils.relativeRealWidth(2),
        marginBottom: ThemeUtils.relativeRealHeight(1)
    },
    modalCont: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: ThemeUtils.relativeWidth(10),
        backgroundColor: Color.PRIMARY_TRANSPARENT
    },
    dialogCont: {
        backgroundColor: Color.WHITE,
        padding: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 8,
        maxHeight: ThemeUtils.relativeRealHeight(90)
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 20
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 20
    },
    pkgCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10
    },
    icDetailsCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icInfoCont: {
        marginStart: 10
    },
})
