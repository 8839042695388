import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {setCurrentInspectionDetails} from "components/src/redux/action";
import {connect} from "react-redux";
//Utils
import {
    Color,
    CommonStyle, Constants,
    DateUtils,
    decryptId,
    encryptId,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    UserType,
    validation
} from "components/src/utils";
import {styles} from './styles';
import {
    CustomButton,
    DatePicker,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator,
    RadioGroup,
    RichTextEditor,
    RichToolbar,
    SearchableDropDown,
    Toast
} from "components/src/components";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AGENT_INSPECTION_PROPERTY, PROPERTY_UTLEGG_LIST, VENDORS_LIST} from "../../../../../api/query";
import moment from "moment";
import {
    ASSIGN_VENDOR_TO_PROPERTY,
    SAVE_UTLEGG,
    SEND_INSPECTION_OFFER,
    START_INSPECTION,
    UPDATE_PROPERTY_UTLEGG
} from "../../../../../api/mutation";


const TABS = [{
    text: Strings.detail
}, {
    text: Strings.inspection
}];


const options = [{
    name: Strings.personal,
    value: "PERSONAL"
}, {
    name: Strings.company,
    value: "COMPANY"
}];


const salgskrantiOptions = [{
    name: Strings.yes,
    id: 1
}, {
    name: Strings.no,
    id: 0
}];

let items = [
    {
        id: 1,
        name: 'JavaScript',
    },
    {
        id: 2,
        name: 'Java',
    },
    {
        id: 3,
        name: 'Ruby',
    },
    {
        id: 4,
        name: 'React Native',
    },
    {
        id: 5,
        name: 'PHP',
    },
    {
        id: 6,
        name: 'Python',
    },
    {
        id: 7,
        name: 'Go',
    },
    {
        id: 8,
        name: 'Swift',
    },
];
let scrollY = new Animated.Value(0);
function PropertyInspectionDetails(props) {
    let richtext;

    const {history, navigation} = props;
    const [toast, setToast] = useState();

    const [selectedPhotographer, setSelectedPhotographer] = useState();
    const [selectedStylist, setSelectedStylist] = useState();
    const [selectedTakst, setSelectedTakst] = useState();

    const [property, setProperty] = useState(IS_WEB ? history.location?.state?.property ?? {} : navigation.getParam('property', {}));
    const [date, setDate] = useState(property?.property_inspection?.start_date ? new Date(moment(property?.property_inspection?.start_date, DateUtils.yyyy_mm_dd)) : property?.propertyActivity?.start_date ? new Date(moment(property?.propertyActivity?.start_date, DateUtils.yyyy_mm_dd_hh_mm_ss)) : null);
    const [time, setTime] = useState(property?.property_inspection?.start_time ? new Date(moment(property?.property_inspection?.start_time, DateUtils.hh_mm_ss)) : property?.propertyActivity?.start_date ? new Date(moment(property?.propertyActivity?.start_date, DateUtils.yyyy_mm_dd_hh_mm_ss)) : null);
    const [template, setTemplate] = useState("PERSONAL");
    const [salesWarrenty, setSalesWarrenty] = useState(0);

    const [selectedService, setSelectedService] = useState([]);

    const [editServiceVisible, setEditServiceVisible] = useState(false);
    const [sendOfferVisible, setSendOfferVisible] = useState(false);

    const [serviceForEdit, setServiceForEdit] = useState("");

    const [serviceName, setServiceName] = useState("");
    const [errServiceName, setErrService] = useState(null);
    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);
    const [summary, setSummary] = useState("Oppsummering fra befaring");
    const [errSummary, setErrSummary] = useState(null);
    const [comment, setComment] = useState("Hei,<br><br>" +
        "Takker så mye for hyggelig befaring i dag!<br><br>" +
        "Dere har en flott bolig, og jeg håper det skinte igjennom at vi ønsker å hjelpe dere!<br><br>" +
        "Nedenfor på denne siden finner dere tilbud på salget. Gi en lyd dersom det er noe dere lurer på.<br><br>" +
        "Ser frem til å høre fra dere.");

    let propertyId;
    if (IS_WEB) {
        propertyId = decryptId(props.match.params.id);
    }

    const vendorQuery = useQuery(VENDORS_LIST);

    const [assignVendor, assignVendorMutation] = useMutation(ASSIGN_VENDOR_TO_PROPERTY);

    const [sendOffer, sendOfferMutation] = useMutation(SEND_INSPECTION_OFFER);

    const [startInspection, startInspectionMutation] = useMutation(START_INSPECTION);

    const [updateService, updateServiceMutation] = useMutation(UPDATE_PROPERTY_UTLEGG, {
        refetchQueries: [{
            query: PROPERTY_UTLEGG_LIST,
            variables: {
                status: 1,
                property_id: property?.id ?? propertyId,
            }
        }]
    });

    const [saveUttlegg] = useMutation(SAVE_UTLEGG);

    const {loading, data, error} = useQuery(AGENT_INSPECTION_PROPERTY, {
        fetchPolicy: 'network-only',
        variables: {
            id: property?.id ?? propertyId
        }
    })


    let photoFromApi = data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Photographer)
    let takstFromApi = data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Takst)
    let stylistFromApi = data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Stylist)

    const uttlegList = useQuery(PROPERTY_UTLEGG_LIST, {
        fetchPolicy: 'network-only',
        variables: {
            status: 1,
            property_id: property?.id ?? propertyId,
        }
    });

    /*  useEffect(() => {
          if (selectedPhotographer) {
              assignVendor({
                  variables: {
                      vendorId: selectedPhotographer?.id,
                      typeId: selectedPhotographer?.user_type_id,
                      propertyId: propertyId
                  }
              }).then(() => {

              })
          }
      }, [selectedPhotographer]);

      useEffect(() => {
          if (selectedTakst) {

          }
      }, [selectedTakst]);

      useEffect(() => {
          if (selectedStylist) {
              assignVendor({
                  variables: {
                      vendorId: selectedStylist?.id,
                      typeId: selectedStylist?.user_type_id,
                      propertyId: propertyId
                  }
              }).then(() => {

              })
          }
      }, [selectedStylist]);*/

    useEffect(() => {
        if (uttlegList?.data?.property_utlegg_list) {
            setSelectedService(uttlegList?.data?.property_utlegg_list?.filter(item => item.usableServiceClass).map(item => item.id))
        }
    }, [uttlegList.data]);

    useEffect(() => {
        if (data?.property) {
            setProperty(data?.property);
            setSelectedPhotographer(data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Photographer))
            setSelectedTakst(data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Takst))
            setSelectedStylist(data?.property?.property_vendor?.find(item => item.user_type_id === UserType.Stylist))
            if (data?.property?.property_inspection?.template_type) {
                setTemplate(data?.property?.property_inspection?.template_type);
            }
            if (data?.property?.property_inspection?.sales_warranty_status) {
                setSalesWarrenty(data?.property?.property_inspection?.sales_warranty_status)
            }
        }
    }, [data]);

    const validateBeforeStartInspection = () => {

        console.log(selectedPhotographer, selectedStylist, selectedTakst)
        console.log(photoFromApi, stylistFromApi, takstFromApi)
        if (!selectedPhotographer || !selectedStylist || !selectedTakst) {
            showMessage("Please select Vendor", Constants.MessageType.FAILED);
            return;
        }
        let variables = {
            input: {
                property_id: property?.id ?? propertyId,
                template_type: template,
                sales_warranty_status: salesWarrenty
            }
        };
        if (date) {
            variables.input.start_date = moment(date).format(DateUtils.yyyy_mm_dd)
        }
        if (time) {
            variables.input.start_time = moment(time).format(DateUtils.hh_mm_ss)
        }
        startInspection({
            variables: variables
        }).then((data) => {
            props.setCurrentInspectionData({...data?.data?.start_inspection, property: property});
                IS_WEB ? history.push(`/${Routes.StartInspection}/${encryptId(property?.id ?? propertyId)}`) : navigation.navigate(Routes.StartInspection)
        }).catch((e) => {
            JSON.stringify(e)
            // showMessage(e?.errors[0]?.message)
        })

    };

    const validateSendConfirmation = () => {
        if (date) {
            if (time) {
                console.log(toast, "HERER");
                sendOffer({
                    variables: {
                        property_id: property?.id ?? propertyId,
                        start_date: moment(date).format(DateUtils.yyyy_mm_dd),
                        start_time: moment(time).format(DateUtils.hh_mm_ss),
                        is_confirmation_email: true
                    }
                }).then((data) => {
                    showMessage(data?.data?.send_inspection_offer?.meta?.message, Constants.MessageType.SUCCESS);
                }).catch((e) => {
                    console.log(e, "HEREREEEEEEE")

                })
            } else {
                showMessage(MessageUtils.Errors.startTime, Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
        }
    };

    const validateSendOffer = async (is_email_send) => {
        let errHeading = validation('heading', summary);
        if (errHeading) {
            setErrSummary(errHeading)
        } else {
            const content = await richtext.getContentHtml();
            const trueContent = content.toString();
            sendOffer({
                variables: {
                    property_id: property?.id ?? propertyId,
                    start_date: moment(date).format(DateUtils.yyyy_mm_dd),
                    start_time: moment(time).format(DateUtils.hh_mm_ss),
                    headline: summary,
                    description: trueContent,
                    is_confirmation_email: true
                }
            }).then((data) => {
                setSendOfferVisible(false);
                showMessage(data?.data?.send_inspection_offer?.meta?.message, Constants.MessageType.SUCCESS);
            }).catch(() => {

            })
        }
    };

    const validateEditService = () => {
        let errService = validation('serviceName', serviceName);
        let errPrice = validation('price', servicePrice);

        if (errPrice || errService) {
            setErrService(errService);
            setErrServicePrice(errPrice);

        } else {
            let variables = {
                id: serviceForEdit?.id,
                property_id: property?.id ?? propertyId,
                name: serviceName,
                price_or_other: serviceForEdit?.is_free ? 1 : 0
            };
            if (serviceForEdit.is_free) {
                variables.free_text = servicePrice
            } else {
                variables.price = servicePrice
            }
            updateService({
                variables: {
                    input: variables
                }
            }).then(() => {
                setEditServiceVisible(false)
            }).catch(() => {

            })
        }
    };

    const addOrRemoveService = (id) => {
        let updatedServices = selectedService.slice();
        let isDelete = false;
        if (updatedServices.includes(id)) {
            isDelete = true;
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }
        saveUttlegg({
            variables: {
                isDelete: isDelete,
                propertyId: property?.id ?? propertyId,
                serviceId: id
            }
        }).catch();
        setSelectedService(updatedServices)
    };

    const renderLabel = (item, index) => {
        let primarySeller = property?.property_seller?.find(seller => seller?.is_primary_seller);
        let secondSeller = property?.property_seller?.find(seller => !seller?.is_primary_seller);

        return (
            <View key={index.toString()}
                  style={styles.lblCont}>
                <Label small
                       font_medium
                       mb={ThemeUtils.relativeRealHeight(0.7)}>
                    {item.name}
                </Label>
                {Array.isArray(property[item.parentId]) ? (
                    <Label small>
                        {item.id.split(',').map(data => primarySeller ? primarySeller[data] : "").join(' ')}
                    </Label>
                ) : (
                    <Label small>
                        {item.id.split(',').map(data => item?.parentId ? (property[item.parentId] ? property[item.parentId][data] : "") : property[data]).join(' ')}
                    </Label>)}

            </View>
        )
    };

    const renderSendOfferModal = () => {
        return (
            <Modal visible={sendOfferVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setSendOfferVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setSendOfferVisible(false)}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                animatedTitle={Strings.summaryFromBefaring}
                                animatedValue={scrollY}/>
                        <View style={CommonStyle.flex}>
                            <Animated.ScrollView keyboardShouldPersistTaps={'always'}
                                                 onScroll={Animated.event(
                                [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                {useNativeDriver: true})}>
                                <View style={styles.sendOfferCont}>
                                    <Label xlarge
                                           font_medium
                                           mt={ThemeUtils.relativeRealHeight(6)}
                                           mb={20}>
                                    </Label>
                                    <InputField type={'text'}
                                                onChange={setSummary}
                                                value={summary}
                                                error={errSummary}
                                                onFocus={() => setErrSummary(null)}
                                                labelText={Strings.headLine}
                                                placeholder={Strings.headLine}/>


                                    <Label small
                                           font_medium>
                                        {Strings.comment}
                                    </Label>

                                    <View style={{
                                        borderWidth: 1,
                                        borderColor: Color.DARK_GRAY,
                                        marginVertical: 20,
                                        borderRadius: 4,
                                    }}>
                                        <RichTextEditor ref={(r) => richtext = r}
                                                        placeholder={Strings.discription}
                                                        initialContentHTML={comment}/>
                                        <RichToolbar getEditor={() => richtext}/>
                                    </View>

                                    {/*<InputField textArea
                                        type={'text'}
                                        onChange={setComment}
                                        value={comment}
                                        labelText={Strings.comments}
                                        placeholder={Strings.discription}/>*/}
                                </View>
                            </Animated.ScrollView>
                        </View>
                        <View style={styles.btnCont}>
                            <CustomButton title={Strings.submit}
                                          loading={sendOfferMutation.loading}
                                          onPress={validateSendOffer}
                                          style={{flex: 1}}
                                          mr={10}/>
                            <CustomButton title={Strings.cancel}
                                          onPress={() => setSendOfferVisible(false)}
                                          bgColor={Color.WHITE}
                                          style={{flex: 1}}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          borderColor={Color.PRIMARY_TEXT_COLOR}
                                          borderWidth={1}
                                          ml={10}/>

                        </View>
                    </View>
                </SafeAreaView>

            </Modal>
        )
    };
    const renderEditServiceModal = () => {
        return (
            <Modal visible={editServiceVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setEditServiceVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setEditServiceVisible(false)}
                                style={CommonStyle.blankHeader}/>
                        <View style={CommonStyle.flex}>
                            <ScrollView keyboardShouldPersistTaps={'always'}>
                                <View style={styles.sendOfferCont}>
                                    <Label xlarge
                                           font_medium
                                           mb={20}>
                                        {Strings.editService}
                                    </Label>
                                    <InputField type={'text'}
                                                onChange={setServiceName}
                                                value={serviceName}
                                                error={errServiceName}
                                                onFocus={() => setErrService(null)}
                                                labelText={Strings.uttleggName}
                                                placeholder={Strings.uttleggName}/>
                                    <InputField type={serviceForEdit?.is_free ? 'text' : 'number'}
                                                onChange={setServicePrice}
                                                value={servicePrice}
                                                error={errServicePrice}
                                                onFocus={() => setErrServicePrice(null)}
                                                labelText={Strings.uttleggPrice}
                                                placeholder={Strings.uttleggPrice}>
                                        {serviceForEdit?.is_free ? null : (
                                            <View style={CommonStyle.inputExtra}>
                                                <Label font_medium>
                                                    {'Kr.'}
                                                </Label>
                                            </View>)}
                                    </InputField>
                                </View>
                            </ScrollView>
                        </View>
                        <View style={styles.btnCont}>
                            <CustomButton title={Strings.submit}
                                          loading={updateServiceMutation.loading}
                                          onPress={validateEditService}
                                          style={{flex: 1}}
                                          mr={10}/>
                            <CustomButton title={Strings.cancel}
                                          onPress={() => setEditServiceVisible(false)}
                                          bgColor={Color.WHITE}
                                          style={{flex: 1}}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          borderColor={Color.PRIMARY_TEXT_COLOR}
                                          borderWidth={1}
                                          ml={10}/>

                        </View>
                    </View>
                </SafeAreaView>


            </Modal>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>
    };

    const renderServices = (item, index) => {
        return (
            <View key={index.toString()}>
                <View style={styles.utlegCont}>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => addOrRemoveService(item.id)}>
                        <Icon name={selectedService.includes(item.id) ? 'tickmark' : 'radio-normal'}
                              size={ThemeUtils.fontLarge}
                              color={selectedService.includes(item.id) ? Color.PRIMARY : Color.DARK_GRAY}/>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => {
                                          if (item?.type === "VARIABLE") {
                                              setServiceForEdit(item);
                                              setServiceName(item.name);
                                              setServicePrice(item?.is_free ? item.free_text : item.price);
                                              setEditServiceVisible(true)
                                          }
                                      }}
                                      style={styles.serviceLblCont}>
                        <Label small
                               font_medium
                               mb={ThemeUtils.relativeRealHeight(1)}>
                            {item.name}
                        </Label>
                        <Label small
                               mb={ThemeUtils.relativeRealHeight(1)}>
                            {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                        </Label>
                        <Label small>
                            {item?.property_type === "All" ? Strings.all : item.property_type}
                        </Label>
                    </TouchableOpacity>
                    <Icon name={'drop-normal'}
                          color={Color.DARK_GRAY}/>
                </View>
                <Hr/>
            </View>
        )
    };

    let primarySeller = property?.property_seller?.find(seller => seller?.is_primary_seller);
    let secondSeller = property?.property_seller?.find(seller => !seller?.is_primary_seller);


    return (
        <Provider locale={enUS}>
            <View style={styles.container}>
                {renderSendOfferModal()}
                {renderEditServiceModal()}
                <Header navigation={IS_WEB ? history : navigation}
                        style={CommonStyle.blankHeader}/>

                <Toast ref={r => setToast(r)}/>
                <Label xlarge
                       font_medium
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {Strings.propertyDetails}
                </Label>
                <IndicatorViewPager style={CommonStyle.flex}
                                    keyboardShouldPersistTaps={'always'}
                                    indicator={renderTabIndicator()}>

                    <View>
                        {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <ScrollView keyboardShouldPersistTaps={'always'}>
                                <View style={styles.propertyDetailsCont}>
                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.clientName}
                                        </Label>
                                        <Label small>
                                            {`${primarySeller?.firstName ?? ""} ${primarySeller?.lastName ?? ""}`}
                                        </Label>
                                    </View>

                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.clientEmail}
                                        </Label>
                                        <Label small>
                                            {primarySeller?.email}
                                        </Label>
                                    </View>

                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.clientPhone}
                                        </Label>
                                        <Label small>
                                            {primarySeller?.mobilePhone}
                                        </Label>
                                    </View>

                                    {secondSeller ? (<>
                                        <View style={styles.lblCont}>
                                            <Label small
                                                   font_medium
                                                   mt={20}
                                                   mb={ThemeUtils.relativeRealHeight(0.7)}>
                                                {Strings.clientName}
                                            </Label>
                                            <Label small>
                                                {`${secondSeller?.firstName ?? ""} ${secondSeller?.lastName ?? ""}`}
                                            </Label>
                                        </View>

                                        <View style={styles.lblCont}>
                                            <Label small
                                                   font_medium
                                                   mb={ThemeUtils.relativeRealHeight(0.7)}>
                                                {Strings.clientEmail}
                                            </Label>
                                            <Label small>
                                                {secondSeller?.email}
                                            </Label>
                                        </View>

                                        <View style={styles.lblCont}>
                                            <Label small
                                                   font_medium
                                                   mb={ThemeUtils.relativeRealHeight(0.7)}>
                                                {Strings.clientPhone}
                                            </Label>
                                            <Label small>
                                                {secondSeller?.mobilePhone}
                                            </Label>
                                        </View>
                                    </>) : null}
                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mt={20}
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.agentName}
                                        </Label>
                                        <Label small>
                                            {property?.property_agent?.find(item => item.brokerRole === 1)?.name}
                                        </Label>
                                    </View>

                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {"Adresse"}
                                        </Label>
                                        <Label small>
                                            {`${property?.street_adress}`}
                                        </Label>
                                    </View>
                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.city}
                                        </Label>
                                        <Label small>
                                            {`${property?.city}`}
                                        </Label>
                                    </View>
                                    <View style={styles.lblCont}>
                                        <Label small
                                               font_medium
                                               mb={ThemeUtils.relativeRealHeight(0.7)}>
                                            {Strings.zipCode}
                                        </Label>
                                        <Label small>
                                            {`${property?.zipcode}`}
                                        </Label>
                                    </View>
                                </View>
                            </ScrollView>
                        )}

                    </View>

                    <View>
                        {loading && !data ? (
                            <ActivityIndicator color={Color.PRIMARY}
                                               style={CommonStyle.loader}/>
                        ) : (
                            <ScrollView keyboardShouldPersistTaps={'always'}>
                                <View>
                                    <View style={styles.inspectionDetailsCont}>
                                        <DatePicker title={Strings.date}
                                                    min={new Date(moment().add(1, 'day'))}
                                                    selectedDate={date}
                                                    onSelectDate={setDate}/>

                                        <DatePicker title={Strings.startTime}
                                                    selectedDate={time}
                                                    onSelectDate={setTime}
                                                    mode={'time'}/>

                                        <Label small
                                               mt={ThemeUtils.relativeRealHeight(1)}>
                                            {Strings.inspectionDetailsInst}
                                        </Label>
                                        <CustomButton mt={ThemeUtils.relativeRealHeight(1)}
                                                      loading={sendOfferMutation.loading}
                                                      onPress={() => validateSendConfirmation(false)}
                                                      title={Strings.sendInspectionConfirmation}/>
                                        <Label small
                                               font_medium
                                               mt={ThemeUtils.relativeRealHeight(2)}
                                               mb={ThemeUtils.relativeRealHeight(1)}>
                                            {Strings.chooseInpectionTemplate}
                                        </Label>
                                        <RadioGroup changeOnProp
                                                    onSelectOption={setTemplate}
                                                    selectedOptions={template}
                                                    contentStyle={{flexDirection: 'row', marginBottom: 10}}
                                                    options={options}/>
                                        <Label small
                                               font_medium
                                               mt={ThemeUtils.relativeRealHeight(1)}
                                               mb={ThemeUtils.relativeRealHeight(1)}>
                                            {"Er det salgsgaranti?"}
                                        </Label>
                                        <RadioGroup changeOnProp
                                                    onSelectOption={setSalesWarrenty}
                                                    selectedOptions={salesWarrenty}
                                                    contentStyle={{flexDirection: 'row'}}
                                                    options={salgskrantiOptions}/>


                                    </View>


                                    <SearchableDropDown title={Strings.photographer}
                                                        placeholder={"Velg fotograf"}
                                                        onItemSelect={(photographer) => {
                                                            setSelectedPhotographer(photographer)
                                                            if (photographer) {
                                                                assignVendor({
                                                                    variables: {
                                                                        vendorId: photographer?.id,
                                                                        typeId: photographer?.user_type_id,
                                                                        propertyId: propertyId
                                                                    }
                                                                }).then(() => {

                                                                })
                                                            }
                                                        }}
                                                        selectedItem={selectedPhotographer}
                                                        value={`${photoFromApi?.user?.company_name ? photoFromApi?.user?.company_name + " - " : ""}${photoFromApi?.user?.first_name ?? ""}${photoFromApi?.user?.last_name ? " " + photoFromApi?.user?.last_name : ""}`}
                                                        results={vendorQuery?.data?.all_office_vendor?.filter(item => item.user_type_id === UserType.Photographer)?.map(item => ({name: `${item?.company_name ? item?.company_name + " - " : ""}${item.first_name} ${item.last_name}`, ...item})) ?? []}
                                    />
                                    <SearchableDropDown title={Strings.stylist}
                                                        placeholder={"Velg stylist"}
                                                        onItemSelect={(stylist) => {
                                                            setSelectedStylist(stylist)
                                                            if (stylist) {
                                                                assignVendor({
                                                                    variables: {
                                                                        vendorId: stylist?.id,
                                                                        typeId: stylist?.user_type_id,
                                                                        propertyId: propertyId
                                                                    }
                                                                }).then(() => {

                                                                })
                                                            }
                                                        }}
                                                        value={`${stylistFromApi?.user?.company_name ? photoFromApi?.user?.company_name + " - " : ""}${stylistFromApi?.user?.first_name ?? ""}${stylistFromApi?.user?.last_name ? " " + stylistFromApi?.user?.last_name : ""}`}
                                                        results={vendorQuery?.data?.all_office_vendor?.filter(item => item.user_type_id === UserType.Stylist)?.map(item => ({name: `${item?.company_name ? item?.company_name + " - " : ""}${item.first_name} ${item.last_name}`, ...item})) ?? []}

                                    />

                                    <SearchableDropDown title={Strings.takst}
                                                        placeholder={"Velg takst"}
                                                        onItemSelect={(takst) => {
                                                            setSelectedTakst(takst)
                                                            if (takst) {
                                                                assignVendor({
                                                                    variables: {
                                                                        vendorId: takst?.id,
                                                                        typeId: takst?.user_type_id,
                                                                        propertyId: propertyId
                                                                    }
                                                                }).then(() => {

                                                                })
                                                            }
                                                        }}
                                                        value={`${takstFromApi?.user?.company_name ? takstFromApi?.user?.company_name + " - " : ""}${takstFromApi?.user?.first_name ?? ""}${takstFromApi?.user?.last_name ? " " + takstFromApi?.user?.last_name : ""}`}
                                                        results={vendorQuery?.data?.all_office_vendor?.filter(item => item.user_type_id === UserType.Takst)?.map(item => ({name: `${item?.company_name ? item?.company_name + " - " : ""}${item.first_name} ${item.last_name}`, ...item})) ?? []}
                                    />
                                    <Hr marginTop={ThemeUtils.relativeRealHeight(2)}/>
                                    {uttlegList.loading && !uttlegList.data ? (
                                        <ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>
                                    ) : (<View>
                                        {uttlegList.data?.property_utlegg_list?.length ? (
                                            <Label small
                                                   font_medium
                                                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                   mt={ThemeUtils.relativeRealHeight(2)}
                                                   mb={ThemeUtils.relativeRealHeight(1)}>
                                                {Strings.chooseVariableUtlegg}
                                            </Label>) : null}
                                        {uttlegList.data?.property_utlegg_list.map(renderServices)}
                                        {/*{uttlegList.data?.property_utlegg_list?.paginatorInfo?.hasMorePages ?
                                        isFetchMore ? (
                                            <ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>
                                        ) : (<TouchableOpacity activeOpacity={0.5}
                                                               onPress={() => {
                                                                   if (!uttlegList.loading) {
                                                                       setFetchMore(true);
                                                                       uttlegList?.fetchMore({
                                                                           variables: {
                                                                               page: parseInt(uttlegList.data?.property_utlegg_list?.paginatorInfo?.currentPage, 10) + 1,
                                                                           },
                                                                           updateQuery: (prev, {fetchMoreResult}) => {

                                                                               setFetchMore(false);
                                                                               if (!fetchMoreResult) {
                                                                                   return prev;
                                                                               } else {
                                                                                   let finalResult = Object.assign({}, fetchMoreResult);
                                                                                   finalResult.property_utlegg_list.data = [...prev.property_utlegg_list.data, ...fetchMoreResult.property_utlegg_list.data];
                                                                                   return finalResult;
                                                                               }
                                                                           },
                                                                       });
                                                                   }
                                                               }}>
                                            <Label small
                                                   align={'center'}
                                                   mt={ThemeUtils.relativeRealHeight(2)}
                                                   mb={ThemeUtils.relativeRealHeight(1)}>
                                                {Strings.loadMore}
                                            </Label>
                                        </TouchableOpacity>) : null}*/}
                                    </View>)}
                                    <View style={styles.btnCont}>
                                        <CustomButton title={Strings.startInspection}
                                                      loading={startInspectionMutation.loading}
                                                      maxWidth={220}
                                                      onPress={() => validateBeforeStartInspection()}
                                                      style={ThemeUtils.isTablet() ? null : {flex: 1}}
                                                      mr={10}/>
                                        <CustomButton title={Strings.sendOffer}
                                                      onPress={() => setSendOfferVisible(true)}
                                                      bgColor={Color.WHITE}
                                                      maxWidth={220}
                                                      style={ThemeUtils.isTablet() ? null : {flex: 1}}
                                                      textColor={Color.PRIMARY_TEXT_COLOR}
                                                      borderColor={Color.PRIMARY_TEXT_COLOR}
                                                      borderWidth={1}
                                                      ml={10}/>

                                    </View>
                                </View>
                            </ScrollView>
                        )}
                    </View>
                </IndicatorViewPager>
            </View>
        </Provider>
    )
}


const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentInspectionData: (inspection) => dispatch(setCurrentInspectionDetails(inspection))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertyInspectionDetails);
