import React from 'react'
import ReactPlayer from '../../../../../web/src/component/player'

export default function VideoPlayer(props) {
    return (
        <ReactPlayer {...props}/>
    )

}




