import gql from 'graphql-tag';


export const APP_UPDATE = gql`
query appVersion($type: AppType!, $appType: UserAppType!, $version: String!) {
  app_version(app_type: $type, user_app_type: $appType, version: $version) {
    is_force_update
    recommended_update
  }
}`;

export const SIGNATURE_PROPERTY_LIST = gql`
query signatureListProperty($input: SignaturePropertyInput!) {
  signature_property(input: $input) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastPage
      total
      perPage
    }
    data {
      id
      oppdrags_nummer
      street_adress
      city 
      zipcode
      signed_both_seller
      total_owner
      created_date
      signed_by_agent
      seller_completed
      seller_sign_date
      property_seller{
        firstName
        lastName
      }
    }
  }
}`;


export const DS_ASSIGNMENT_DETAILS = gql`
query dsAssignementDetails($propertyId: Int!, $status: Int!) {
  ds_assignment_detail(property_id: $propertyId, status: $status) {
    id
    property_id
    url
    is_approved
    approved_date
    status
    digital_sales_assi_comment {
      id
      digital_sales_assi_id
      comment
      status
      created_at
    }
  }
}`;

export const PROPERTTY_LIST_MOOVIN = gql`
query propertyListMoovin($status: Int!, $first: Int!, $page: Int!,$key:String,$sorting:OrderByClause) {
  moovin_properties(status: $status, first: $first, page: $page,key:$key,sorting:$sorting) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastPage
      total
      perPage
    }
    data {
      id
      street_adress
      city
      zipcode
      oppdrags_nummer
    }
  }
}`;

export const PROPERTY_AGENT_MOOVIN = gql`
query agentMoovin($propertyId: Int!, $status: Int!) {
  moovin_property_agent(property_id: $propertyId, status: $status) {
    property_id
    id
    agent_team_id
    agentTeam {
      id
      title
      status
      teamMembers {
        id
        team_id
        agent_id
        is_primary_agent
        status
        agentUser {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
}`;

export const INSPECTION_DETAILS = gql`
query inspectionOrder($id: ID!) {
  inspection_order(id: $id) {
    orderbookinspection {
      id
      property_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      status
      order_status
      inspection_id
      property {
        seller_id
        address
        oppdrags_nummer
      }
      inspection {
        
        first_name
        last_name
      }
    }
  }
}`;

export const PHOTO_SALES_INFO = gql`
  query sellerInfo($propertyId: Int!) {
  moovin_photo_seller_info(property_id: $propertyId) {
    id
    property_id
    order_date
    earliest_start_time
    latest_start_time
    comment
    order_status
    status
    property {
      street_adress
      city
      zipcode
      oppdrags_nummer
      seller {
        id
        
        first_name
        last_name
        email
        mobile
      }
    }
  }
}`;

export const PHOTOS = gql`
query photos($propertyId: Int!) {
  moovin_photo_photos(property_id: $propertyId) {
    id
    user_id
    order_photo_id
    property_id
    photo_url
    created_at
    updated_at
    comments {
      id
      order_photo_photos_id
      comment
      created_at
    }
  }
}`;

export const PROPERTY_RATE = gql`
query propertyRateDetails($propertyId: Int!) {
  moovin_property_rate(property_id: $propertyId) {
    id
    property_id
    order_rate_id
    tax_date
    construction_year
    value_rate
    bta
    loan_rate
    bra
    p_rom
    document {
      id
      property_id
      doc_url
    }
    comments {
      id
      comment
    }
  }
}`;

export const STYLING_DETAILS = gql`
query stylistDetails($propertyId: Int!) {
  moovin_stylist_details(property_id: $propertyId) {
    id
    property_id
    styling_type_id
    order_date
    earliest_start_time
    latest_start_time
    comment
    status
    order_status
    styling_type {
      id
      name
    }
    property {
      id
      street_adress
      city
      zipcode
      oppdrags_nummer
      seller {
        id

        first_name
        last_name
        mobile
        email
      }
    }
  }
}
`;

export const PURCHASED_SERVICES = gql`
query purchased_services(
  $status: Int!
  $property_id: Int!
  $service_category: ServiceCatrgory
) {
  purchased_services(
    status: $status
    service_category: $service_category
    property_id: $property_id
  ) {
    service_types {
      id
      name
      vendor_services {
        id
        vendor_id
        service_type_id
        name
        description
        free_text
        price
        is_free
        service_selected {
          id
          property_id
          usable_service_id
          usable_service_class
          price
          quantity
        }
      }
    }
    remunerations {
      id
      property_id
      name
      description
      price
      usableServiceClass {
        id
        price
        quantity
      }
      cost_type
      hours
    }
    property_utlegg {
      id
      property_id
      name
      price
      type
      status
      is_free
      free_text
      is_default
      price_type
      usableServiceClass {
        id
        price
        property_id
      }
    }
    purchased_services {
      id
      name
      purchased_office_template {
        id
        office_id
        service_type_id
        headline
        description
        purchased_office_services {
          id
          name
          image
          description
          price
          is_free
          free_text
          external_url
          service_selected {
            id
            price
            quantity
          }
        }
        office_service_media {
          id
          media_url
          media_new_url
        }
        agent_service_media {
          media_url
          media_new_url
        }
      }
    }
  }
}
`;

export const PROPERTY_SELLER_DETAILS = gql`
query propertySellerDetails($id: Int!) {
  property_seller_details(id: $id) {
    address
    oppdrags_nummer
    coOwnerDetails {
      id
      first_name
      last_name
      email
    }
    seller {
      id
      first_name
      last_name
      mobile
      email
    }
  }
}`;

export const MOOVIN_PROPERTY_DETAILS = gql`
query moovin_property_details($id: ID!) {
  moovin_property_detail(id: $id) {
    street_adress
    city
    zipcode
    orderstyling {
      id
      property_id
      styling_type_id
      styling_type {
        id
        name
      }
      order_date
      earliest_start_time
      latest_start_time
      comment
      status
      order_status
    }
    order_rate_detail {
      id
      property_id
      order_rate_id
      tax_date
      construction_year
      value_rate
      bta
      loan_rate
      bra
      p_rom
      comments {
        id
        comment
      }
      document {
        doc_url
      }
    }
    orderrate {
      id
      property_id
      takst_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
    }
    order_photo {
      id
      property_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
      status
    }
    agent {
      id
      first_name
      last_name
    }
    property_agent {
      id
      brokerRole
      name
    }
    property_seller {
      id
      firstName
      lastName
      mobilePhone
      email
      is_primary_seller
    }
  }
}`;

export const UPCOMING_VIEWS = gql`
query Views($property_id:Int!){
  property_display(property_id:$property_id){
    id
    display_date
    earliest_start_time
    latest_start_time
    comment
    is_completed
    is_broker_on_view
    created_at
  }
}`;

export const COMPLETED_VIEWS = gql`
query completedViews($input:upComingViewInput){
  completed_views(input:$input){
    id
    display_date
    earliest_start_time
    latest_start_time
    comment
    is_completed
    is_broker_on_view
    created_at
  }
}`;

export const SELLER_TIMELINE = gql`
query propertyTimeline($propertyId: ID!, $status: Int) {
  seller_timeline(property_id: $propertyId, status: $status) {
    id
    property_id
    timeline_id
    is_completed
    completed_date
    completed_time
    end_date
    timeline {
      id
      name
      description
      icon
    }
  }
}`;

export const BUYER_TIMELINE = gql`
query buyerTimeline($propertyId: ID!, $status: Int!) {
  buyer_property_timeline(property_id: $propertyId, status: $status) {
    id
    is_completed
    completed_date
    updated_at
    timeline {
      id
      name
      description
      icon
      link
    }
  }
}`;

export const ENERGY_LABELING = gql`
query energyLabeling($propertyId: Int!, $status: Int!) {
  moovin_energy_labeling(property_id: $propertyId, status: $status) {
    id
    property_id
    document_id
    letter_code
    color_code
    document {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
    }
    HouseHeatType {
      id
      name
    }
  }
}`;

export const SELLER_INTERVIEW = gql`
query sellerInterview($propertyId: Int!, $status: Int!) {
  moovin_seller_interviews(property_id: $propertyId, status: $status) {
    id
    seller_id
    property_id
    answer_json
    status
  }
}`;


export const PROPERTY_MARKETING_MATERIAL = gql`
query property_marketing_materials($id: Int!, $status: Int!) {
  fetch_market_materials(property_id: $id, status: $status) {
    id
    document_id
    is_approved 
    approved_date
    materialComment{
      id
      comment
      created_at
    }
    document {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property {
        id
        seller_id
        oppdrags_nummer
        seller {
          id
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
          access_level
        }
      }
    }
  }
}`;

export const FINN_DETAILS = gql`
query property_finn_ad_details($status:Int!,$propertyId: Int!) {
  finn_ad_detail(property_id: $propertyId,status:$status) {
    id
    property_id
    url
    is_approved
    approved_date
    finn_comments {
      id
      property_finn_ad_id
      comment
      created_at
      updated_at
    }
  }
}`;


export const DIGITAL_ASSIGNMENT_DETAILS = gql`
query digital_sales_assignment($status: Int!, $propertyId: Int!) {
  ds_assignment_detail(status: $status, property_id: $propertyId) {
    id
    property_id
    url
    is_approved
    approved_date
    digital_sales_assi_comment {
      id
      digital_sales_assi_id
      comment
      created_at
    }
  }
}`;

export const MATERIAL_DETAILS = gql`
query materialDetails($id: ID!, $status: Int!) {
  fetch_materials_detials(id: $id, status: $status) {
    id
    document_id
    is_approved
    approved_date
    materialComment{
      id
      comment
      created_at
    }
    document {
      id
      property_id
      title
      doc_url
      doc_type
      is_buyer_seller_doc
      status
      property {
        id
        seller_id
        oppdrags_nummer
        seller {
          id
          
          first_name
          last_name
          email
          address
          mobile
          profile_image
          city
          zipcode
          status
          access_level
        }
      }
    }
  }
}`;

export const FINANCE_TYPE = gql`
query financeType($status:Boolean!) {
  financing_type(status:$status){
    id
    name
    status
  }
}`;

export const NEWS_LIST = gql`
query newsList($status:Boolean!,$orderBy:[OrderByClause!] $first: Int!, $page: Int!) {
  news(status:$status,orderBy:$orderBy first: $first, page: $page) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      total
    }
    data {
      id
      title
      description
      cover_image_url
      video_url
      description
      created_at
    }
  }
}`;

export const NEWS_DETAILS = gql`
query newsDetails($id: Int!, $status: Int!) {
  news_detail(id: $id, status: $status) {
    id
    cover_image_url
    video_url
    title
    
    description
  
    status	
    created_at
    updated_at
  }
}`;


export const VITECH_BUYER_INFO_OLD = gql`
query viteccBuyerInfo($id:Int!){
  vitec_buyer_info(property_id:$id){
    firstName
    lastName
    email
    address
    city
    postalCode
    mobilePhone
  }
}`;

export const VITECH_BUYER_INFO = gql`
query vitecBuyerInfo($id: Int!) {
  vitec_buyer_info(property_id: $id) {
    contactId
    departmentId
    contactType
    companyName
    organisationNumber
    firstName
    lastName
    mobilePhone
    privatePhone
    workPhone
    email
    address
    postalAddress
    postalCode
    city
    changedDate
    customerReview
  }
}`;

export const BUYER_CONTRACT_DOC_QUERY = gql`
query buyerContractDoc(
  $propertyId: Int!
  $status: Int!
  $commentBy: commentBy
) {
  buyer_contract_doc(
    property_id: $propertyId
    status: $status
    comment_by: $commentBy
  ) {
    id
    property_id
    title
    doc_url
    doc_type
    status
    contractDocComment {
      id
      buyer_contract_doc_id
      comment
      comment_by
      status
      created_at
    }
  }
}`;

export const FAGLIG_LIST = gql`
 query professionals($first: Int!, $page: Int!, $order: [OrderByClause!]) {
  professionals(first: $first, page: $page,status:true, orderBy: $order) {
    data {
      id
      cover_image_url
      title
      description
      video_url
      created_at
    }
    paginatorInfo {
      count
      total
      currentPage
      hasMorePages
    }
  }
}
`;

export const FAGLIG_DETAILS = gql`
  query professional_detail($id: Int!, $status: Int!) {
    professional_detail(id: $id, status: $status) {
      cover_image_url
      title
      description
      video_url
      created_at
    }
  }`;

export const ASSIGNMENT_DETAILS = gql`
query assignmentPropertyDetails($id: ID!) {
  signature_property_details(id: $id) {
    id
    oppdrags_nummer
    created_date
    street_adress
    city
    zipcode
    signed_both_seller
    contract_assignment
    signed_by_agent
    agreement_pdf_path
    update_at
    total_owner
    seller_completed
    seller_sign_date
    property_seller{
      firstName
      lastName
    }
    vitecPropertyAds {
      vitec_prop_id
      ads {
        adStatus_text
        ads_id
        publishStart
        publishEnd
        lastChanged
       }
    }
  }
}`;

export const FEEDBACK = gql`
query feedbackList($id: Int!, $status: Int!, $first: Int!, $page: Int!) {
  feedback_lists(user_id: $id, status: $status, first: $first, page: $page) {
    paginatorInfo {
      currentPage
      hasMorePages
      total
    }
    data {
      id
      user_id
      rating
      feedback
      given_by
      status
    }
  }
}
`;

export const BID_QUERY = gql`
query propertyBids($propertyId: Int!) {
  property_bids(property_id: $propertyId) {
    bids {
      bidId
      type
      time
      amount
      partyid
      expires
      reservations
      accepted
      changedDate
      rejectedDate
    }
    color
  }
}`;

export const DOC_LIST = gql`
query propertyDocument($propertyId: Int!, $status: Int!) {
  property_documents(property_id: $propertyId, status: $status) {
    id
    property_id
    title
    doc_url
    doc_type
    is_buyer_seller_doc
    status
    property {
      id
    }
  }
}`;

export const SETTLEMENT_INFO = gql`
query propertySettlementInfo($propertyId: Int!) {
  property_settlement_information(property_id: $propertyId) {
    id
    property_id
    address
    city
    zipcode
    fra_date
    loan_status
    settlement_loans {
      id
      denomination_kr
      residual_debt_kr
      reminder_date
      bank_name
      bank_contact
      bank_phone
      bank_email
      loan_no
      is_repaid
    }
    settlement_receivable {
      id
      receivables_account_no
      percentage
    }
  }
}`;

export const INFO_FROM_BUYER = gql`
  query property_info_from_buyer($propertyId: Int!) {
    property_info_from_buyer(property_id:$propertyId) {
      id
      property_id
      first_name
      last_name
      email
      phone
      address
      city
      zipcode
      purchase_price
      take_over_date
      reservation_comment
      property {
        id
        seller_id
      }
      coBuyerInfo {
        id
        prop_buyer_info_id
        first_name
        last_name
        email
        phone
        address
        city
        zipcode
      }
      buyerPropertyInfo {
        gnr
        fnr
        # knr
        bnr
        org_nr
        snr_andelsnr
      }
      buyerBankDetail {
        status
        buyerBankInfo {
          bank_name
          bank_contact
          bank_email
        }
      }
      buyerHomeInsurance {
        want_insurance
      }
      buyerHomeContract {
        comment
      }
      buyerOwnership {
        ownership_interest
      }
    }
  }`;


export const STACK_HOLDERS_FROM_DS = gql`
query stackholdersFromDs($propertyId:Int!,$status: Int!, $first: Int!, $page: Int!,$key:String,$sorting:OrderByClause) {
  stackholders_from_ds(property_id:$propertyId,status:$status, first: $first, page: $page,key:$key,sorting:$sorting) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastPage
      total
      perPage
    }
    data {
      id
      first_name
      last_name
      email
      phone
      send_info_about_property
      bid_notification
      get_update_similar_homes
      receive_our_newsletter
    }
  }  
}`;

export const STACK_HOLDERS_SIGNED_IN_VIEW = gql`
query stackholdersSignedInView($propertyId:Int!,$status: Int!, $first: Int!, $page: Int!,$key:String,$sorting:OrderByClause) {
  signed_in_viewing_ds(property_id:$propertyId,status:$status, first: $first, page: $page,key:$key,sorting:$sorting) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastPage
      total
      perPage
    }
    data {
      id
      first_name
      last_name
      email
      phone
      contact_me_private_viewing_time
      plan_set_community_shows
      is_notify_me
    }
  }  
}`;


/*------------------------NEW--------------------------------------*/
export const SERVICES = gql`
query services($status:Boolean!,$category:ServiceCatrgory){
  services(status:$status, service_category:$category){
    id
    name
    image
    office_template{
      id
      office_id
      service_type_id
      headline
      description
      office_services{
        id
        office_template_id
        name
        description
        image
        price
        is_use
        is_free
        free_text
      }
      agent_service_media{
        id
        office_template_id
        media_url
        media_new_url
        media_type
      }
      office_service_media{
        id
        office_template_id
        media_url
        media_new_url
        media_type
      }
    }
  }
}`;

export const OFFICE_SERVICE_DETAILS = gql`
query officeTemplateDetails($id: ID!) {
  office_template_details(id: $id) {
    id
    office_id
    service_type_id
    headline
    description
    office_services {
      id
      office_template_id
      name
      description
      image
      icon
      price
      is_use
      is_free
      free_text
    }
    office_service_media {
      id
      office_template_id
      media_url
      media_new_url
      media_type
    }
  }
}
`;

export const OFFICE_OPP_DRAG_AGREEMENT_DETAILS = gql`
query oppDragAgreement($status: Int!, $officeId: Int!) {
  office_oppdrag_agreement_content(status: $status, office_id: $officeId) {
    id
    office_id
    oppdrag_staker
    Oppgjor_sforetak
    financial_settlement
    settlement_employees
    hourly_rate
    time_spent
    hourly_remuneration
    other_remuneration
    sales_warranty_yes
    sales_warranty_no
    status
  }
}`;

export const AGENT_INSPECTION_PROPERTY_LIST = gql`
query properties($input: OfficePropInput!) {
  office_properties(input: $input) {
    data {
      id
      street_adress
      city
      zipcode
      ownership
      agent{
        first_name
        last_name
      }
      propertyActivity{
       id
       start_date
     }
      property_seller{
        id
        companyName
        firstName
        lastName
        mobilePhone
        privatePhone
        workPhone
        email
        postalAddress
        postalCode
        city
        is_primary_seller
      }
      property_agent{
        name
        brokerRole
      }
      property_inspection {
        id
        start_time
        start_date
      }
      seller {
        first_name
        last_name
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}`;

export const AGENT_INSPECTION_PROPERTY = gql`
  query property($id: Int!) {
    property(id: $id) {
      id
      seller_id
      office_id
      street_adress
      city
      zipcode
      estate_type
      sales_warranty_status
      property_agent{
        id
        name
        brokerRole
        email
      }
     property_vendor{
       id
       vendor_id
       user{
          id
          first_name
          last_name
          company_name
       }
       user_type_id
       property_id
     }
     propertyActivity {
       id
       start_date
     }
      property_seller {
          id
          companyName
          firstName
          lastName
          mobilePhone
          privatePhone
          workPhone
          email
          address
          postalAddress
          postalCode
          city
          is_primary_seller
          organisationNumber
      }
      property_inspection {
        id
        start_date
        start_time
        template_id
        template_type
      }
      agent {
        id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      title      
      user_detail{
        user_id
        id
        education
        about
        cv_url
        status
      }
      office{
        id
        name
      }
      user_document{
        id
        user_id
        doc_url
        doc_type
      }
      feedback{
        id
        user_id
        rating
        feedback
        given_by
      }
      }
      seller {
        id
        first_name
        last_name
        email
        address
        mobile
      }
    }
  }`;

export const PROPERTY_UTLEGG_LIST = gql`
query property_utlegg_list($property_id: Int!, $status: Int!) {
  property_utlegg_list(property_id: $property_id, status: $status) {
    id
    property_id
    name
    price
    type
    status
    is_default
    is_free
    free_text
    property_type
    usableServiceClass {
      id
      property_id
    }
  }
}`;

export const PURCHASED_B2B_SERVICES = gql`
query purchasedB2bServics($status: Int!) {
  purchased_b2b_services(status: $status) {
    id
    name
    description
    image
    icon
    price
    is_use
    is_free
    free_text
    status
    is_default
    office_service {
      id
      office_id
      service_subscribe_id
      price

      quantity
      status
    }
  }
}
`;
export const FETCH_OFFICE_DETAIL = gql`
 query fetchOfficeDetails($office_id: Int!) {
  office(id: $office_id) {
    befaringsbekreftelse {
      office_id
      description
    }
    about_w {
      headline
      image
      description
      office_id
    }
    preparation {
      headline
      image
      description
      office_id
    }
    question_to_ask {
      headline
      image
      description
      office_id
    }
    product_gallery {
      id
      office_id
      headline
      ProductGalleryImages {
        id
        product_gallery_id
        url
      }
      office_id
    }
  }
}

`;

export const B2C_SUB_SERVICES = gql`
query b2cSubServices($status: Int!, $id: Int!) {
  b2c_sub_services(office_template_id: $id, status: $status) {
    id
    office_template_id
    name
    description
    price
    image
    is_use
    is_free
    free_text
    external_url
  }
}
`;


export const UTLEGG_SERVICES = gql`
query utleggServices($input: UtleggServiceInput!) {
  utlegg_services_list(input: $input) {
    data {
      id
      name
      price
      is_default
      is_free
      free_text
      property_type
      type
      status
      price_type
      created_at
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}

`;

/*--------------Personal template----------------*/
export const PERSONAL_TEMPLATE_DETAILS = gql`
query personalTemplateDetails(
  $status: Int!
  $agentId: Int!
  $id: Int!
) {
  personal_template_details(
    status: $status
    agent_id: $agentId
    office_template_id: $id
  ) {
    id
    agent_id
    office_template_id
    media_url
    media_new_url
    media_type
  }
}`;

export const AGENT_BEFARING_DETAILS = gql`
query agentBefaringContent($status: Int!, $agentId: Int!) {
  agent_befaring_content(status: $status, agent_id: $agentId) {
    id
    agent_id
    description
  }
}`;


export const AGENT_BEFARING_MEDIA = gql`
query agentProductGallery($status: Int!, $agentId: Int!) {
  befaring_product_gallery(status: $status, agent_id: $agentId) {
    id
    agent_id
    headline
    AgentProductGalleryImages {
      id
      url
    }
  }
}`;

export const DS_DETAILS=gql`
query assignmentPropertyDetails($id: String!) {
  ds_property_detail(oppdragsnummer: $id, is_live_request: true) {
    id
    estateId
    oppdragsnummer
    streetAdress
    zipCode
    city
    status
    lastUpdatedDate
    vitecPropertyPlot {
      size
    }
    vitecPropertyEstatePrice {
      priceSuggestion
    }
    vitecPropertyAds {
      vitec_prop_id
      ads {
        adStatus_text
        ads_id
        publishStart
        publishEnd
        lastChanged
      }
    }
    vitecPropertyImages {
      id
      vitec_prop_id
      imageId
      imageUrl
      fileType
      imageSequence
    }
  }
}`;

export const DS_PROPERTY_LISTING = gql`
  query ds_property_list($input: DsInput!) {
    ds_property_list(input: $input) {
      data {
        id
        estateId
        oppdragsnummer
        streetAdress
        zipCode
        city
        status
        lastUpdatedDate
        vitecPropertyPlot {
          size
        }
        vitecPropertyEstatePrice {
          priceSuggestion
        }
        vitecPropertyAds {
          vitec_prop_id
          ads {
            adStatus_text
            ads_id
            publishStart
            publishEnd
            lastChanged
          }
        }
        vitecPropertyImages {
          id
          vitec_prop_id
          imageId
          imageUrl
          fileType
          imageSequence
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const PROPERTY_DETAILS = gql`
query property($id: Int!) {
  property(id: $id) {
    id
    seller_id
    agent_id
    office_id
    estate_id
    department_id
    construction_year
    plot_size
    apartment_no
    street_adress
    zipcode
    city
    knr
    gnr
    bnr
    fnr
    snr
    partName
    partNumber
    partOrgNumber
    estateHousingCooperativeStockNumber
    ownpart
    estate_type_id
    estate_type
    municipality
    longitude
    latitude
    created_date
    vitec_property_status
    primary_room_area
    gross_area
    usable_area
    client_claim
    client_married_partner
    client_partner_both_sign
    contract_apply_housing
    political_person
    contract_assignment
    name_of_brokerage
    ownership
    property_preference {
      id
      preferable_price
      percentage
      fix_price
      rebate_amount
    }
    property_agent {
      id
      title
      name
      mobilePhone
      email
      brokerRole
    }
    agent {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city

      office {
        id
        image_url
        city
        logo_url
        product_gallery {
          id
          office_id
          headline
          ProductGalleryImages {
            id
          }
        }
        name
      }
      zipcode
      status
      title
      user_media {
        id
        title
        redirect_url
        media_url
      }
      user_detail {
        user_id
        id
        education
        about
        cv_url
        status
      }
      office {
        name
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      feedback {
        id
        user_id
        rating
        feedback
        given_by
      }
    }
  }
}
`
export const AGENT_QUESTIONS = gql`
query property($id: Int!) {
  property(id: $id) {
    contract_assignment
    political_person
    client_claim
    client_married_partner
    name_of_brokerage
    client_partner_both_sign
    contract_apply_housing
  }
}
`;
export const AGREEMENT_DETAILS = gql`
query propertyAgreementDetails($propertyId: Int!, $ownerNo: Int!) {
  property_agreement_details(property_id: $propertyId, owner_number: $ownerNo) {
    property_remuneration {
      id
      price
      usableService {
        property_id
        name
        cost_type
        hours
      }
    }
    property{
        sales_warranty_status
    }
    property_service {
      id
      price
      quantity
      usableService {
        name
        is_free
        free_text
      }
    }
    property_seller {
      id
      socialSecurity
      companyName
      firstName
      lastName
      mobilePhone
      privatePhone
      workPhone
      email
      address
      postalAddress
      postalCode    
      city
      is_primary_seller
      organisationNumber
    }
    total_owners
    owner_number
    office_opprag_agreement {
      id
      office_id
      oppdrag_staker
      Oppgjor_sforetak
      financial_settlement
      settlement_employees
      hourly_rate
      time_spent
      hourly_remuneration
      other_remuneration
      sales_warranty_yes
      sales_warranty_no
      status
    }
  }
}
`


export const FINAL_SUBMIT_AGREEMENT = gql`
query finalSubmit($email_verify: Int!, $property_id: String!) {
  finalGetStartedSubmit(email_verify: $email_verify, propertyId: $property_id) {
    meta {
      status
      message
      code
    }
  }
}
`
export const FIRST_SELLER_SUBMIT = gql`
query firstSellerSubmit($input: sellerSignupInput) {
  first_seller_submit(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`

export const ASSIGN_UTTLEG_SERVICE = gql`
mutation assignUttlegService($input: assignUtleggService!) {
  assign_utlegg_service(input: $input) {
    meta {
      message
    }
  }
}
`

export const PROPERTY_PREFERENCE = gql`
query property($id: Int!) {
  property(id: $id) {
    id
    property_preference {
      id
      preferable_price
      percentage
      fix_price
      rebate_amount
    }
  }
}
`

export const CALENDAR_TIMELINE_QUERY = gql`
query inspectionTimeLineList($status: Int!, $propertyId: Int!) {
  inspection_timeline_list(status: $status, property_id: $propertyId) {
    id
    timeline_id
    completed_date
    service_type
    timeline {
      id
      name
      description
      icon
      link
      priority
    }
  }
}`

export const AGENT_SIGN = gql`
query agent_sign_contract($input: agentSignContractInput) {
  agent_sign_contract(input: $input) {
    meta {
      status
      message
      code
    }
  }
}`

export const SELECTED_PROPERTY_SERVICE = gql`
query selected_property_services(
  $status: Int!
  $category: ServiceCatrgory
  $propertyId: Int!
) {
  selected_property_services(
    property_id: $propertyId
    status: $status
    service_category: $category
  ) {
    service_types{
     id
     name
     vendor_services{
       id
       vendor_id
       service_type_id
       name
       image
       description
       free_text
       price
       is_free
       service_selected{
         id
         property_id
         usable_service_id
         usable_service_class
         price
         quantity
       }
     }
    }
    remunerations {
      id
      name
      description
      image
      hours
      icon
      price
      cost_type
      usableServiceClass {
        id
        price
        property_id
        quantity
      }
    }
    services {
      id
      name
      purchased_office_template {
        id
        office_id
        service_type_id
        headline
        description
        purchased_office_services {
          id
          office_template_id
          name
          description
          image
          price
          is_use
          is_free
          free_text
          service_selected {
            id
            price
            property_id
          }
        }
        agent_service_media {
          id
          office_template_id
          media_url
          media_new_url
          media_type
        }
        office_service_media {
          id
          office_template_id
          media_url
          media_new_url
          media_type
        }
      }
    }
    property_utlegg {
      id
      property_id
      name
      price
      type
      status
      is_free
      free_text
      is_default
      price_type
      usableServiceClass {
        id
        price
        property_id
      }
    }
    marketing_package {
      id
      office_id
      name
      price
      description
      image_url
      marketing_package_service {
        id
        office_service {
          id
          name
          description
          price
          image
          is_free
          free_text
        }
      }
      property_package {
        id
        property_id
        marketing_package_id
        price
      }
    }
  }
}`

export const AGENT_MEDIA = gql`
query agentMedia($id:Int!){
  agent_media_list(user_id:$id){
    id
    user_id
    title
    redirect_url
    media_url
    status
  }
}`


export const AGENT_DETAILS = gql`
query agentDetails($id: Int!) {
  agent_detail(id: $id) {
    id
    first_name
    last_name
    email
    address
    mobile
    profile_image
    city
    title
    zipcode
    office_id
    office{
      id
      name
    }
    user_detail {
      id
      user_id
      education
      about
      cv_url
    }
    feedback {
      id
      rating
      feedback
      given_by
    }
    user_document{
     id
     user_id
     doc_url
     doc_type
   }
    agentBefaringsbekreftelse {
        id
      description
    }
  }
}
`

export const SEND_OFFER_DETAILS = gql`
query sendOfferDetails($id: Int!) {
  send_offer_detail(id: $id) {
    agent {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      title
      user_media {
        id
        title
        redirect_url
        media_url
      }
      user_detail {
        user_id
        id
        education
        about
        cv_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      feedback {
        id
        user_id
        rating
        feedback
        given_by
      }
    }
    office {
      befaringsbekreftelse {
        office_id
        description
      }
      about_w {
        headline
        image
        description
        office_id
      }
      preparation {
        headline
        image
        description
        office_id
      }
      question_to_ask {
        headline
        image
        description
        office_id
      }
      product_gallery {
        id
        office_id
        headline
        ProductGalleryImages {
          id
          product_gallery_id
          url
        }
        office_id
      }
    }
  }
}`;

export const SELLER_PHOTO_SERVICES = gql`
query photo_type_services($propertyId: Int!, $status: Int!) {
  photo_type_services(status: $status, property_id: $propertyId) {
    id
    name
    purchased_office_template {
      id
      office_id
      service_type_id
      headline
      description
      purchased_office_services {
        id
        name
        image
        description
        price
        is_free
        free_text
        external_url
        service_selected {
          id
          price
          quantity
        }
      }
      office_service_media {
        media_url
        media_new_url
      }
      agent_service_media {
        media_url
        media_new_url
      }
    }
  }
}`;

export const BOLIG_SELGER_INSURANCE = gql`
query boligselger_Insurance_list($propertyId: Int!) {
  boligselger_Insurance_list(property_id: $propertyId) {
    id
    name
    selected_in_property
    insurance_type
  }
}
`
export const DASHBOARD_STATES = gql`
query dashboardStates {
  dashboard_stat {
    sale {
      agent {
        month
        quarter
        year
      }
      company {
        month
        quarter
        year
      }
    }
    inspection {
      agent {
        month
        quarter
        year
      }
      company {
        month
        quarter
        year
      }
    }
  }
}`;

export const AGENT_CALENDAR_EVENT = gql`
query agentCalendarEvent {
  agent_calendar_event {
    id
    property_id
    created_user_id
    title
    description
    start_datetime
    end_datetime
    event_type
    user_type
  }
}`;

export const AGENT_CALENDAR_PROPERTIES = gql`
query agentProperties {
  agent_proeprties {
    id
    oppdrags_nummer
    street_adress
    city
    zipcode
  }
}`;


export const USER_DETAILS_LIST = gql`
query userDetails($ids: [Int]) {
  users_detail_list(ids: $ids) {
    id
    first_name
    last_name
    user_type_id
    email
    profile_image
  }
}`;

export const MARKETING_PACKAGE_LIST = gql`
query marketingPackages($input: PackageInput!) {
  marketing_packages(input: $input) {
    data {
      id
      office_id
      name
      price
      description
      image_url
      marketing_package_service {
        id
        marketing_package_id
        office_template_id
        office_service_id
        office_service {
          id
          office_template_id
          name
          description
          image
          icon
          price
          is_free
          free_text
        }
      }
    }
    paginatorInfo {
      hasMorePages
      currentPage
    }
  }
}`;

export const BEFARING_PACKAGES = gql`
query befaringPackages($propertyId:Int!) {
  befaring_packages(property_id:$propertyId) {
    id
    office_id
    name
    price
    description
    image_url
    property_package {
      id
      property_id
      marketing_package_id
      price
    }
    marketing_package_service {
      id
      marketing_package_id
      office_template_id
      office_service_id
      office_service {
        id
        name
        description
        image
        icon
        price
        is_use
        is_free
        free_text
        external_url
      }
    }
  }
}`;

export const SELLER_STYLING_TYPES = gql`
  {
    styling_types(status: 1) {
      id
      name
    }
  }
`;

export const DS_HISTORY = gql`
query dsHistory($input:DsHistoryInput!){
  ds_history(input:$input){
    data{
      id
      vitec_prop_id
      oppdragsnummer
      pdf_url
      created_at
    }
    paginatorInfo{
      currentPage
      hasMorePages
    }
  }
}`;


export const BEFARING_NOTE = gql`
query befaringNotes($inspectionId: Int!) {
  befaring_note(property_inspection_id: $inspectionId) {
    id
    property_inspection_id
    image
  }
}`;

export const NEW_OTHER_SERVICES = gql`
query officeBrokerOnView($propertyId:Int) {
  office_broker_on_view_service (property_id:$propertyId){
    id
    office_id
    name
    description
    image
    price
    service_selected{
      id
      office_broker_view_id
      property_id
      quantity
      price
    }
  }
}`;


export const MARKETING_PACKAGE_DETAILS = gql`
  query marketingPackageDetail {
  marketing_package_detail {
    id
    office_id
    heading
    description
    MarketingPackageImages {
      id
      marketing_package_detail_id
      image
      priority
    }
  }
}`;


export const PROPERTY_ORDER_DETAILS = gql`
query property($id: Int!) {
  property(id: $id) {
    id
    orderrate {
       id
      property_id
      takst_id
      order_date
      earliest_start_time
      latest_start_time
      comment
      order_status
    }
    order_photo {
      id
      earliest_start_time
      latest_start_time
    }
    orderstyling {
      styling_type_id
      earliest_start_time
      latest_start_time
    }
  }
}
`

export const ORIGIN_EQUITY = gql`
query orginEquity($status:Int!){
  opprinnelse_egenkapital_option(status:$status){
    id
    name
    other_text
    status
  }
}`;

export const PURPOSE_OF_PURCHASE = gql`
query purposeOfPurchase($status:Int!){
  purpose_purchase_option(status:$status){
    id
    name
    other_text
    status
  }
}`

export const BEFARING_AFTER_PRODUCT_GALLERY = gql`
query befringAfterProductGalley($status:Int!,$agentId:Int!){
  befaring_after_product_gallery(status:$status,agent_id:$agentId){
    id
    agent_id
    headline
    AgentAfterGalleryMedia{
      id
      agent_product_gallery_id
      url
    }
  }
}`;


export const AGENT_VENDOR_LIST = gql`
query office_vendors($input: AgentVendorInput) {
  office_vendor(input: $input) {
    data {
      id
      first_name
      last_name
      company_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
      user_type_id
      office_id
      title
      assign_offices
      office_name
      is_in_admin_office
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
`;

export const VENDORS_SERVICES = gql`
query vendor_service_type($serviceCategory: ServiceCategory) {
  vendor_service_type(service_category: $serviceCategory) {
    id
    name
    image
    sub_category
  }
}
`;

export const VENDORS_SUB_SERVICES=gql`
query vendorService($vendorId: Int!, $typeId: Int!) {
  vendor_services(status:true,vendor_id: $vendorId, service_type_id: $typeId) {
    id
    vendor_id
    service_type_id
    name
    description
    image
    icon
    price
    is_use
    is_free
    free_text
    external_url
  }
}
`

export const VENDORS_LIST=gql`
query {
 all_office_vendor(status: true) {
   id
   first_name
   last_name
   company_name
   user_type_id
 }
}`

export const PROPERTY_SHOWCASE_LIST=gql`
query propertyShowCaseList($input: ShowCaseInput) {
  property_show_case_list(input: $input) {
    data {
      id
      office_id
      heading
      cover_image
      status
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}`;


export const PROPERTY_SHOW_CASE_DETAILS=gql`
query propertyShowCase($id: Int!) {
  property_show_case(id: $id) {
    id
    office_id
    heading
    cover_image
    status
    PropertyShowcaseImages {
      id
      property_showcase_id
      image
      priority
    }
  }
}`;

export const FETCH_PROPERTY_SHOW_CASE=gql`
query fetchPropertyShowCase {
  fetch_proeprty_showcase {
    id
    office_id
    heading
    cover_image
    status
  }
}`;

export const PROPERTY_SHOW_CASE_IMAGE=gql`
query proeprtyShowcaseImages($id: Int!) {
  proeprty_showcase_images(property_showcase_id: $id) {
    id
    property_showcase_id
    image
    priority
  }
}`;
