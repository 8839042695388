export default {
  'component.officetemplate.title': 'Selskapsmal',
  'component.officetemplate.photostyling.title': 'Bilder / Styling',
  'component.officetemplate.digitalmarketing.title': 'Digital markedsføring',
  'component.officetemplate.printmarketing.title': 'Print markedsføring',
  'component.officetemplate.oppdragagrement.title': 'Rediger oppdragsavtalen',

  // inner pages
  'component.officetemplate.special.decisions.title': 'Særlige bestemmelser',
  'component.officetemplate.services.title': 'Tjenester',
  'component.officetemplate.photo.details.title': 'Bildedetaljer',
  'component.officetemplate.styling.details.title': 'Stylingdetaljer',
  'component.officetemplate.3dcgi.details.title': '3d/Illustrasjoner',
  'component.officetemplate.image.gallery.title': 'Bildegalleri',
  'component.officetemplate.drone.title': 'Drone',
  'component.officetemplate.3dcgi.title': '3d/Illustrasjoner',
  'component.officetemplate.360deg.title': '360 Visning',
  'component.officetemplate.video.title': 'Film',
  'component.officetemplate.introfilm.title': 'SoMe film',
  'component.officetemplate.wads.title': 'W Ads',
  'component.officetemplate.finnno.title': 'Finn.no',
  'component.officetemplate.digitalsalesassi.title': 'Digital salgsoppgave',
  'component.officetemplate.dm.title': 'DM',
};
