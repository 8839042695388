export default {
  'component.befaring.title': 'Befaringsbekreftelse',
  'component.befaring.befaringsbekreftelse.title': 'Introduksjon',
  'component.befaring.aboutw.title': 'Om W',
  'component.befaring.preparation.title': 'Forberedelser til befaring',
  'component.befaring.ques.to.ask.title': 'Spørsmål å stille',
  'component.befaring.productgallery.title': 'Produkt galleri',
  'component.befaring.aboutagent.title': 'Om megler',
  'component.befaring.offer.title': 'Oppdragskostnader',
  'component.befaring.questiontoask.title': 'Tips å spørre megler om',
};
