import React, {useEffect, useState} from 'react'
//Components
import {
    ActivityIndicator,
    Animated,
    FlatList,
    Image,
    Keyboard,
    SafeAreaView,
    TouchableOpacity,
    View
} from 'react-native'
import {
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    InputField,
    Label,
    Modal
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PROPERTY_SHOWCASE_LIST} from "components/src/api/query";
import {ADD_PROPERTY_SHOWCASE, REMOVE_PROPERTY_SHOWCASE} from "components/src/api/mutation";
import Search from './PropertyImageGallerySearch';

const scrollY = new Animated.Value(0);

const PropertyImageGallery = props => {

    const [isFetchMore, setFetchMore] = useState(false);
    const [removingIndex, setRemovingIndex] = useState();
    const [isSearchVisible, setSearchVisible] = useState(false)
    const [isAddShowCaseVisible, setAddShowCaseVisible] = useState(false)
    const [heading, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);
    const [errCoverImage, setErrCoverImage] = useState(null);
    const [deleteVisible, setDeleteVisible] = useState(false)

    const [coverImage, setCoverImage] = useState([]);
    const [coverImageUrl, setCoverImageUrl] = useState([]);

    useEffect(() => {
        if (coverImage[0]) {
            setErrCoverImage(null)
            setCoverImageUrl(IS_WEB ? URL.createObjectURL(coverImage[0]) : coverImage[0]?.uri)
        }
    }, [coverImage])

    const [removePropertyFromShowcase, removePropertyShowCaseMutation] = useMutation(REMOVE_PROPERTY_SHOWCASE);

    const [saveServiceDetails, saveDetailsMutation] = useMutation(ADD_PROPERTY_SHOWCASE);

    const {loading, data, error, refetch, fetchMore} = useQuery(PROPERTY_SHOWCASE_LIST, {
        fetchPolicy: 'network-only',
        variables: {
            input: {
                first: 30,
                page: 1,
                status: true,
            }
        }
    });

    const navigateToRoute = (showcase) => {

        if (IS_WEB) {
            props.history.push(`/${Routes.OfficeTemplate}/${Routes.OfficePropertyImageGallery}/${encryptId(showcase?.id)}`, {
                showcase: showcase
            });
        } else {
            props.navigation.navigate(Routes.OfficePropertyImageGallery, {
                showcase: showcase
            })
        }
    };

    const validate = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('headline', heading);


        if (!errHead) {
            if (coverImage.length) {
                let variables = {
                    heading: heading,
                    coverImage: coverImage[0]
                };
                saveServiceDetails({
                    variables: variables
                }).then(({data}) => {
                    showMessage(data?.add_property_showcase?.meta?.message, Constants.MessageType.SUCCESS)
                    refetch();
                    setAddShowCaseVisible(false)
                }).catch(() => {

                })
            } else {

                setErrCoverImage("Vennligst velg hovedbilde.")
            }
        } else {
            setErrHeadLines(errHead);
        }
    };

    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Search onClose={() => setSearchVisible(false)}
                                renderItem={renderPropertyList}/>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderPropertyList = ({item, index}) => {

        return (
            <View key={index.toString()}>

                <TouchableOpacity style={styles.cont}
                                  activeOpacity={0.5}
                                  onPress={() => navigateToRoute(item)}>
                    <View style={styles.content}>
                        <View style={CommonStyle.flex}>
                            <Label small
                                   mt={10}
                                   mb={10}

                                   mr={10}
                                   font_medium
                                   numberOfLines={3}>
                                {item.heading}
                            </Label>
                        </View>
                        {removePropertyShowCaseMutation?.loading && index === removingIndex ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.5}
                                              style={CommonStyle.selfCenter}
                                              onPress={async () => {
                                                  setRemovingIndex(index)
                                                  setDeleteVisible(true)

                                              }}>

                                <Icon name={'delete'}
                                      color={Color.SECONDARY_TEXT_COLOR}/>
                            </TouchableOpacity>)}
                    </View>

                </TouchableOpacity>
                <Hr/>
            </View>
        )
    };

    const renderAddShowCaseModel = () => {
        return (
            <Modal visible={isAddShowCaseVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setAddShowCaseVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <Header tintColor={Color.PRIMARY}
                            onPressLeft={() => setAddShowCaseVisible(false)}

                            style={CommonStyle.blankHeader}
                            navigation={IS_WEB ? history : navigation}/>
                    <View style={styles.modalContainer}>
                        <Label xlarge
                               font_medium
                               color={Color.PRIMARY}
                               mb={ThemeUtils.relativeRealHeight(2)}>
                            {Strings.addNewShowcase}
                        </Label>
                        <Label font_medium>
                            {Strings.projectName}
                        </Label>
                        <InputField mt={10}
                                    align={'center'}
                                    type={'text'}
                                    error={errHeadline}
                                    onFocus={() => setErrHeadLines(null)}
                                    value={heading}
                                    onChange={setHeadLines}
                        />
                        <Label font_medium
                               mb={10}>
                            {Strings.coverImage}
                        </Label>
                        <FilePicker type={FileType.IMAGE}
                                    onFilePicked={(data, file) => setCoverImage(file)}/>

                        <Label small
                               mb={5}
                               mt={5}
                               style={{height: 30}}
                               color={Color.RED}>
                            {errCoverImage}
                        </Label>

                        {coverImage?.length ? (
                            <Image source={IS_WEB ? coverImageUrl : {uri: coverImageUrl}}
                                   style={styles.img}/>
                        ) : null}
                        <CustomButton
                            loading={saveDetailsMutation.loading}
                            onPress={validate}
                            title={Strings.addNewShowcase}
                            mt={40}
                        />
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    return (
        <SafeAreaView style={CommonStyle.flex}>
            {renderSearchModal()}
            {renderAddShowCaseModel()}
            <Dialog title={"Slette presentasjon"}
                    visible={deleteVisible}
                    buttonTitleFirst={Strings.no}
                    buttonTitleSecond={Strings.yes}
                    onNegativePress={() => setDeleteVisible(false)}
                    onPositivePress={() => {
                        removePropertyFromShowcase({
                            variables: {id: item.id}
                        }).then(() => {
                            setSearchVisible(false)
                            refetch();
                        }).catch(() => {

                        })
                        setDeleteVisible(false)
                    }}
                    desc={"Er du sikker på at du vil slette denne presentasjon?"}/>
            <Header animatedTitle={Strings.propertyImageGallery}
                    headerRightFirst={'search'}
                    onPressRightFirst={() => setSearchVisible(true)}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>

                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'ds-property'}
                                color={Color.PRIMARY_DARK}/>
                        </View>
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : <FlatList data={data?.property_show_case_list?.data ?? []}
                                  onRefresh={refetch}
                                  refreshing={loading}
                                  renderItem={renderPropertyList}
                                  ListEmptyComponent={
                                      <View style={CommonStyle.content_center}>
                                          <Label mt={20}>
                                              {"Ingen data"}
                                          </Label>
                                      </View>
                                  }
                                  ListFooterComponent={
                                      data?.property_show_case_list?.paginatorInfo?.hasMorePages ?
                                          (
                                              <CustomButton title={Strings.loadMore}
                                                            loading={isFetchMore}
                                                            mt={10}
                                                            mb={10}
                                                            style={{
                                                                width: ThemeUtils.relativeWidth(40),
                                                                alignSelf: 'center'
                                                            }}
                                                            onPress={() => {
                                                                setFetchMore(true)
                                                                fetchMore({
                                                                    variables: {
                                                                        input: {
                                                                            first: 30,
                                                                            status: true,
                                                                            page: parseInt(data?.property_show_case_list?.paginatorInfo?.currentPage, 10) + 1,
                                                                        }
                                                                    },
                                                                    updateQuery: (prev, {fetchMoreResult}) => {

                                                                        setFetchMore(false)
                                                                        if (!fetchMoreResult) {
                                                                            return prev;
                                                                        } else {
                                                                            let finalResult = Object.assign({}, fetchMoreResult);
                                                                            finalResult.property_show_case_list.data = [...prev.property_show_case_list.data, ...fetchMoreResult.property_show_case_list.data];
                                                                            return finalResult;
                                                                        }
                                                                    },
                                                                });
                                                            }}/>

                                          ) : null}/>}
                </View>
            </Animated.ScrollView>
            <CustomButton borderWidth={0}
                          onPress={() => setAddShowCaseVisible(true)}
                          title={
                              <>
                                  <Icon name={'add'}/>
                                  {` ${Strings.addNewShowcase}`}
                              </>}
                          borderRadius={0}
                          mb={ThemeUtils.isTablet() ? 40 : 0}
                          style={!ThemeUtils.isTablet() ? {
                              height: 50,
                              borderRadius: 0
                          } : null}/>
        </SafeAreaView>
    )

}

export default PropertyImageGallery;
