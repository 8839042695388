export default {
  'component.calenda.event.Modal.title': 'Legg til aktivitet',
  'component.calenda.event.Modal.ok.button': 'Lagre',
  'component.calenda.event.Modal.cancel.button': 'Avbryt',
  'component.calenda.event.Modal.save.button': 'Lagre',
  'component.calenda.event.DatePicker.label': 'Dato',
  // For calendar
  'component.addevent.title': 'Legg til aktivitet',
  'component.editevent.title': 'Rediger aktivitet',
  'component.event.title': 'Aktivitetsnavn',
  'component.event.title.placeholder': 'Legg til aktivitetsnavn',
  'component.calendar.property': 'Oppdrag',
  'component.calendar.usertype': 'User type',
  'component.calendar.property.placeholder': 'Velg oppdrag',
  'component.calendar.event.detail': 'Aktivitetsdetaljer',
  'component.calendar.event.delete.confirm': 'Er du sikker du vil slette denne aktiviteten?',
};
