import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },

    lblCont: {
        marginVertical:10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    imgCont:{
        marginVertical:10,
        height:ThemeUtils.relativeRealHeight(30)
    },
    serviceCont: {
        borderWidth: 1,
        borderRadius: 1,
        borderColor: Color.SECONDARY,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10
    },
    imgCountCont:{
        zIndex:100,
        position:'absolute',
        end:10,
        bottom:10,
        paddingHorizontal:5,
        paddingVertical:3,
        borderRadius:3,
        backgroundColor:Color.TRANSPARENT_BLACK
    }
})
