import React from 'react';
import {ScrollView, View} from 'react-native';
import PropTypes from 'prop-types';
import {Label} from '../../../components';
import TextareaItem from "@ant-design/react-native/lib/textarea-item";
import {Color, IS_WEB, ThemeUtils} from '../../../utils';
import InputItemStyle from "@ant-design/react-native/lib/textarea-item/style/index";

// import ButtonStyle from '@ant-design/react-native/lib/button/style/index';

const CustomTextArea = (props) => {

    const onPress = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    const getStyleArray = () => {
        let stylesArray = [];

        stylesArray.push({
            marginTop: props.mt,
            marginBottom: props.mb,
            marginLeft: props.ml,
            marginRight: props.mr,
            width: '100%',
        });

        return stylesArray;
    }

    return (
        <ScrollView>
            <View style={[getStyleArray()]}>
                {props.labelText &&
                <Label small font_medium align={'left'} mb={props.lblMb}>{props.labelText}</Label>}
                <TextareaItem
                    styles={{
                        ...InputItemStyle,
                        clear: null,
                        container: {
                            borderColor: Color.TRANSPARENT,
                        },
                        input: {
                            padding: 10,
                            color: Color.PRIMARY,
                        },
                        text: {
                            color: Color.PRIMARY,
                        }
                    }}
                    style={[{
                        borderColor: props.borderColor,
                        borderWidth: props.borderWidth,
                        borderRadius: props.borderRadius,
                        fontSize: ThemeUtils.fontSmall,
                        paddingVertical: 5
                    },
                        IS_WEB ?
                            {outline: 'none'}
                            : null]}
                    autoHeight={false}
                    value={props.value}
                    editable={props.editable}
                    disabled={props.disabled}
                    rows={props.heightInRow}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}/>
            </View>
        </ScrollView>
    );
};

CustomTextArea.defaultProps = {
    placeholder: "",
    borderColor: Color.INPUT_BORDER,
    borderWidth: 0.5,
    borderRadius: 4,
    heightInRow: 4,
    lblMb: 10,

    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
};

CustomTextArea.propTypes = {
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    borderColor: PropTypes.string,
    lblMb: PropTypes.number,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    heightInRow: PropTypes.number,

    mt: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    mr: PropTypes.number,
};

export default CustomTextArea;
