import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, Keyboard, SafeAreaView, View} from 'react-native'
import {
    CheckBox,
    CustomButton,
    Dialog,
    Header,
    Hr, IndicatorViewPager,
    InputField,
    Label,
    Modal,
    RadioGroup,
    Ripple
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    formatPrice,
    Icon,
    IS_WEB,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {UTLEGG_SERVICES} from "../../../../../api/query";
import {ADD_UTLEGG_SERVICE, DELETE_UTLEGG_SERVICE, UPDATE_UTLEGG_SERVICE} from "../../../../../api/mutation";

const TEXT_OPTIONS = [{
    name: Strings.price,
    value: 0
}, {
    name: Strings.other,
    value: 1
}];

const PRICE_OPTIONS = [{
    name: Strings.fixed,
    value: 'FIXED'
}, {
    name: Strings.variable,
    value: 'VARIABLE'
}];

const PRICE_TYPE = [{
    name: Strings.vederlag,
    value: Constants.PriceType.VEDERLEGG
}, {
    name: Strings.utlegg,
    value: Constants.PriceType.UTLEGG
}];

const PROPERTY_TYPE = [{
    name: Strings.all,
    value: 'All'
}, {
    name: "Andelsleilighet",
    value: 'Andelsleilighet'
}, {
    name: "Eierseksjon",
    value: 'Eierseksjon'
}, {
    name: "Aksjeleilighet",
    value: 'Aksjeleilighet'
}, {
    name: "Selveier",
    value: 'Selveier'
}, {
    name: "Obligasjonsleilighet",
    value: "Obligasjonsleilighet"
}];

const scrollY = new Animated.Value(0);
const scrollYModal = new Animated.Value(0);

const UttlegServiceDetails = props => {

    const [properties, setProperties] = useState(PROPERTY_TYPE);

    const {history, navigation} = props;
    const serviceId = IS_WEB ? props.match?.params?.id ?? null : navigation.getParam('serviceId', null);
    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);

    const [isDefault, setDefault] = useState([]);

    const [loadMore, setFetchMore] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [errName, setErrName] = useState(null);

    const [description, setDescription] = useState("");
    const [errDescription, setErrDescription] = useState(null);

    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);

    const [addServiceVisible, setAddServiceVisible] = useState(false);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const [serviceForEdit, setServiceForEdit] = useState(null);


    const [serviceType, setServiceType] = useState(PRICE_OPTIONS[0].value);

    const [servicePriceType, setServicePriceType] = useState(PRICE_TYPE[0].value)

    const [serviceTextType, setServiceTextType] = useState(TEXT_OPTIONS[0].value);
    const [propertyType, setPropertyType] = useState(PROPERTY_TYPE[0].value);

    const {loading, data, error,refetch, fetchMore} = useQuery(UTLEGG_SERVICES, {
        onCompleted: (data) => {
            console.log(data?.utlegg_services_list?.data?.filter(item => item.is_default))
        },
        fetchPolicy: 'network-only',
        variables: {
            input: {
                first: 50,
                key: "",
                page: 1,
                sorting: {field: "id", order: "DESC"},
                status: 1,
            }
        }
    });

    const [addServiceDetails, addDetailsMutation] = useMutation(ADD_UTLEGG_SERVICE, {
        onCompleted:()=>refetch(),
        refetchQueries: [{
            query: UTLEGG_SERVICES,
            variables: {
                input: {
                    first: 30,
                    key: "",
                    page: 1,
                    sorting: {field: "id", order: "DESC"},
                    status: 1,
                }
            }
        }]
    });

    const [saveServiceDetails, saveDetailsMutation] = useMutation(UPDATE_UTLEGG_SERVICE, {
        onCompleted:()=>refetch(),
        refetchQueries: [{
            query: UTLEGG_SERVICES,
            variables: {
                input: {
                    first: 30,
                    key: "",
                    page: 1,
                    sorting: {field: "id", order: "DESC"},
                    status: 1,
                }
            }
        }]
    });

    const [deleteService, deleteServiceMutation] = useMutation(DELETE_UTLEGG_SERVICE, {
        onCompleted:()=>refetch(),
        refetchQueries: [{
            query: UTLEGG_SERVICES,
            variables: {
                input: {
                    first: 30,
                    key: "",
                    page: 1,
                    sorting: {field: "id", order: "DESC"},
                    status: 1,
                }
            }
        }]
    });




    useEffect(() => {
        /*if (isDefault.includes(1)) {
            setProperties([PROPERTY_TYPE[0]])
            setPropertyType('All')
        } else {
            setProperties(PROPERTY_TYPE)
        }*/
    }, [isDefault])

    useEffect(() => {
        setErrName(null);
        setErrServicePrice(null)
        if (!addServiceVisible) {
            setServiceName("");
            setDescription("");
            setServicePrice("");
        }
    }, [addServiceVisible]);

    useEffect(() => {
        if (serviceForEdit) {
            setServiceName(serviceForEdit?.name);
            setServiceType(serviceForEdit?.type)
            setServicePriceType(serviceForEdit?.price_type)
            setDefault([serviceForEdit?.is_default ? 1 : 0])
            setPropertyType(serviceForEdit?.property_type)
            setServicePrice(serviceForEdit?.is_free ? serviceForEdit?.free_text : parseFloat(serviceForEdit?.price).toString())
            setServiceTextType(serviceForEdit?.is_free ? 1 : 0)

        } else {
            setServiceName("");
            setDescription("");
            setServicePrice("");
        }
    }, [serviceForEdit]);


    const validate = () => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        let errHead = validation('name', serviceName);
        let errPrice = validation('servicePrice', servicePrice);

        if (!errHead && !errPrice) {
            let variables = {
                name: serviceName,
                type: serviceType,
                price_or_other: serviceTextType,
                property_type: propertyType,
                is_default: isDefault.includes(1),
                price_type: servicePriceType
            };
            console.log(serviceTextType,"TYPE")
            if (serviceTextType === 1) {
                variables.free_text = servicePrice
            } else {
                variables.price = parseInt(servicePrice)
            }
            if (serviceForEdit) {
                saveServiceDetails({
                    variables: {
                        id: serviceForEdit?.id,
                        input: variables
                    }
                }).then((data) => {
                    setAddServiceVisible(false);
                    showMessage(data?.data?.update_utlegg_service?.meta?.message, Constants.MessageType.SUCCESS)
                }).catch(() => {

                })
            } else {
                addServiceDetails({
                    variables: {
                        input: variables
                    }
                }).then((data) => {
                    setAddServiceVisible(false);
                    showMessage(data?.data?.create_utlegg_service?.meta?.message, Constants.MessageType.SUCCESS)
                }).catch(() => {

                })
            }
        } else {
            setErrName(errHead);
            setErrServicePrice(errPrice)
        }
    };

    const onDelete = () => {
        deleteService({
            variables: {
                id: serviceForEdit?.id
            }
        }).then((data) => {
            setDeleteDialogVisible(false)
            setAddServiceVisible(false)
            showMessage(data?.data?.delete_utlegg_service?.meta?.message, Constants.MessageType.SUCCESS)
        }).catch(() => {

        })
    };

    const renderAddEditModal = () => {
        return (
            <Modal visible={addServiceVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setAddServiceVisible(false)}>

                <SafeAreaView style={CommonStyle.safeArea}>

                    <View style={CommonStyle.container}>
                        <Dialog title={Strings.deleteService}
                                visible={deleteDialogVisible}
                                loading={deleteServiceMutation.loading}
                                desc={Strings.deleteConfirmation}
                                onNegativePress={() => setDeleteDialogVisible(false)}
                                onPositivePress={() => onDelete()}/>
                        <Header animatedTitle={serviceForEdit ? Strings.editService : Strings.addService}
                                headerRightFirst={serviceForEdit ? 'delete' : null}
                                onPressRightFirst={() => setDeleteDialogVisible(true)}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                onPressLeft={() => setAddServiceVisible(false)}
                                animatedValue={scrollYModal}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             keyboardShouldPersistTaps={'always'}

                                             contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollYModal}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.topContainer}>
                                <CheckBox options={[{name: Strings.defaultValue, value: 1}]}
                                          disabled={serviceForEdit}
                                          onCheckChanged={setDefault}
                                          defaultChecked={isDefault}
                                          style={{marginTop: ThemeUtils.relativeRealHeight(8)}}/>
                                <InputField onFocus={() => setErrName(null)}
                                            mt={20}
                                            error={errName}
                                            type={'text'}
                                            value={serviceName}
                                            onChange={setServiceName}
                                            labelText={Strings.uttleggName}/>

                                <RadioGroup changeOnProp
                                            options={TEXT_OPTIONS}
                                    // disabled={serviceForEdit}
                                            selectedOptions={serviceTextType}
                                            onSelectOption={setServiceTextType}
                                            contentStyle={styles.radio}
                                />
                                <InputField type={serviceTextType === 1 ? 'text' : 'number'}
                                            onFocus={() => setErrServicePrice(null)}

                                            error={errServicePrice}
                                            value={servicePrice}
                                            onChange={setServicePrice}>
                                    {serviceTextType === 1 ? null : (<View style={CommonStyle.inputExtra}>
                                        <Label font_medium>
                                            {'Kr.'}
                                        </Label>
                                    </View>)}
                                </InputField>

                                <Label small
                                       font_medium>
                                    {Strings.serviceType}
                                </Label>
                                <RadioGroup changeOnProp
                                            options={PRICE_OPTIONS}
                                            selectedOptions={serviceType}
                                            onSelectOption={setServiceType}
                                            contentStyle={styles.radio}
                                />

                                <Label small
                                       font_medium
                                       mt={10}>
                                    {Strings.servicePriceType}
                                </Label>
                                <RadioGroup changeOnProp
                                            options={PRICE_TYPE}
                                            selectedOptions={servicePriceType}
                                            onSelectOption={setServicePriceType}
                                            contentStyle={styles.radio}
                                />

                                <Label small
                                       mt={10}
                                       font_medium>
                                    {Strings.propertyType}
                                </Label>
                                <RadioGroup changeOnProp
                                            options={properties}
                                    // disabled={serviceForEdit}
                                            selectedOptions={propertyType}
                                            onSelectOption={setPropertyType}
                                            style={{marginVertical: 5}}
                                            contentStyle={styles.radio}
                                />
                                <View style={styles.bottomButtonContainer}>
                                    <CustomButton
                                        loading={serviceForEdit ? saveDetailsMutation.loading : addDetailsMutation.loading}
                                        style={{flex: 0.45}}
                                        title={serviceForEdit ? Strings.save : Strings.add}
                                        onPress={validate}/>
                                    <CustomButton bgColor={Color.WHITE}
                                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                                  borderColor={Color.PRIMARY}
                                                  borderWidth={1}
                                                  style={{flex: 0.45}}
                                                  title={Strings.cancel}
                                                  onPress={() => {
                                                      if (!IS_WEB) {
                                                          Keyboard.dismiss()
                                                      }
                                                      setAddServiceVisible(false)
                                                  }}/>
                                </View>
                            </View>
                        </Animated.ScrollView>

                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderUttleggServices = options => {
        if(!options.length){
            return (
                <Label mt={20}
                       align={'center'}
                       font_medium>
                    {"Ingen data"}
                </Label>
            )
        }
        return (
            <View>
                {options?.map((item, index) => {
                    return (
                        <Ripple key={index}
                                onPress={() => {
                                    setServiceForEdit(item);
                                    setAddServiceVisible(true)
                                }}>
                            <Hr/>

                            <View style={styles.optionContainer}>
                                <View style={CommonStyle.flex}>
                                    <Label font_medium
                                           mb={5}>
                                        {item.name}
                                    </Label>
                                    <Label small
                                           mb={5}
                                           numberOfLines={2}>
                                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                                    </Label>
                                    <Label small
                                           color={Color.DARK_GRAY}
                                           numberOfLines={2}>
                                        {item.type === 'VARIABLE' ? Strings.variable : Strings.fixed}
                                    </Label>
                                    <Label small
                                           mt={5}
                                           color={Color.DARK_GRAY}
                                           numberOfLines={2}>
                                        {item?.property_type === "All" ? Strings.all : item.property_type}
                                    </Label>
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'drop-normal'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                            {index === options.length - 1 ? <Hr/> : null}
                        </Ripple>
                    )
                })}
            </View>
        );
    };

    return (
        <Provider locale={enUS}>

            <Header animatedTitle={Strings.variableUttleg}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}

                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'variable-utlegg'}
                                color={Color.PRIMARY_DARK}/>
                        </View>
                        {/*<Label small
                               mt={10}
                               align={'left'}>
                            {""}
                        </Label>*/}

                    </View>
                    {renderAddEditModal()}

                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : renderUttleggServices(data?.utlegg_services_list?.data ?? [])}

                    {data?.utlegg_services_list?.paginatorInfo?.hasMorePages ?
                        (
                            <CustomButton title={Strings.loadMore}
                                          loading={loadMore}
                                          mt={10}
                                          mb={10}
                                          style={{
                                              width: ThemeUtils.relativeWidth(40),
                                              alignSelf: 'center'
                                          }}
                                          onPress={() => {
                                              setFetchMore(true)
                                              if (data?.utlegg_services_list?.paginatorInfo?.hasMorePages && !loading) {
                                                  fetchMore({
                                                      variables: {
                                                          input: {
                                                              first: 30,
                                                              page: (data?.utlegg_services_list?.paginatorInfo?.currentPage ?? 0) + 1,
                                                              status: 1,
                                                              key: "",
                                                              sorting: {field: "id", order: "DESC"},

                                                          },
                                                      },
                                                      updateQuery: (prev, {fetchMoreResult}) => {
                                                          setFetchMore(false)
                                                          if (!fetchMoreResult) {
                                                              return prev;
                                                          } else {
                                                              let finalResult = Object.assign({}, fetchMoreResult);
                                                              finalResult.utlegg_services_list.data = [...prev.utlegg_services_list.data, ...fetchMoreResult.utlegg_services_list.data];
                                                              return finalResult;
                                                          }
                                                      },
                                                  });
                                              }
                                          }}/>

                        ) : null}
                </View>
            </Animated.ScrollView>
            <CustomButton borderWidth={0}
                          onPress={() => {
                              setServiceForEdit(null);
                              setAddServiceVisible(true)
                          }}
                          title={
                              <>
                                  <Icon name={'add'}/>
                                  {` ${Strings.addService}`}
                              </>}
                          borderRadius={0}
                          mb={ThemeUtils.isTablet() ? 40 : 0}
                          style={!ThemeUtils.isTablet() ? {
                              height: 50,
                              borderRadius: 0
                          } : null}/>
        </Provider>
    )

};

export default UttlegServiceDetails;
