import React from 'react'
import {ActivityIndicator, FlatList, Image, TouchableOpacity, View} from 'react-native'
import styles from './styles'
import {Header, Hr, Label} from "components/src/components";

import {withFirebase} from "components/src/HOC/Firebase";
import {connect} from "react-redux";
import {USER_DETAILS_LIST} from "components/src/api/query";

import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    encryptId,
    getUserString,
    Icon,
    IS_WEB,
    Routes,
    ThemeUtils
} from "components/src/utils";
import userPlaceholder from 'components/src/assets/images/user_placeholder.png'
import {Query} from "@apollo/react-components";

class ChatList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ids: [],
            chatList: [],
            loading: true
        }

    }

    componentDidMount() {
        const {user, database} = this.props;
        let propertyId
        if (IS_WEB) {
            propertyId = decryptId(this.props.match.params.propertyId)
        } else {
            propertyId = this.props.navigation.getParam('propertyId')
        }
        this.database = database().ref("v1")
            .child('users')
            .child(`${user?.id}`)
            .child(`${propertyId}`)

        this.database.orderByChild("lastMessageTimestamp").on('value', (e) => {
            let chats = []
            console.log(e.val())
            e.forEach((child) => {
                console.log(child.val())
                chats.push(child.val())
            });
            this.setState({
                chatList: chats,
                loading: false,
                ids: chats.map(item => item.receiverId)
            })
        }, () => this.setState({loading: false}))
    }

    componentWillUnmount() {
        console.log(this.database)
        this.database && this.database?.off()
    }

    navigateToChat = (receiver) => {
        const {history, location, navigation} = this.props;
        let propertyId
        if (IS_WEB) {
            propertyId = decryptId(this.props.match.params.propertyId)
        } else {
            propertyId = this.props.navigation.getParam('propertyId')
        }
        if (IS_WEB) {
            history.push(`/${Routes.ChatDetails}/${encryptId(propertyId)}/${encryptId(receiver.receiverId)}`, {
                user: receiver.user
            })
        } else {
            navigation.navigate(Routes.ChatDetails)
        }
    };


    renderChatItem = ({item, name}) => {
        return (
            <TouchableOpacity activeOpacity={0.5}
                              onPress={() => this.navigateToChat(item)}
                              style={styles.chatCont}>
                <View style={[styles.dot,{backgroundColor:item.isRead?Color.TRANSPARENT:Color.RED}]}/>
                <Image style={styles.chatImage}
                       source={item?.user?.profile_image ? {uri: `${Constants.APIConfig.STORAGE_URL}${item?.user?.profile_image}`} : userPlaceholder}/>
                <View style={styles.lblCont}>
                    <Label small
                           font_medium>
                        {`${item?.user?.first_name} ${item?.user?.last_name}`}
                    </Label>
                    <Label small
                           mt={5}>
                        {getUserString(parseInt(item?.user?.user_type_id))}
                    </Label>
                </View>
                <Icon name={'drop-normal'}
                      color={Color.PRIMARY_LIGHT}
                      size={ThemeUtils.fontNormal}/>
            </TouchableOpacity>
        )
    }

    render() {
        const {ids, chatList} = this.state
        return (
            <View>
                <Header navigation={IS_WEB ? this.props.history : this.props.navigation}
                        style={CommonStyle.blankHeader}/>
                <Query query={USER_DETAILS_LIST}
                       skip={!ids.length}
                       variables={{
                           ids: ids
                       }}>
                    {({loading, error, data}) => {
                        let updatedChatList = chatList.slice()
                        updatedChatList = updatedChatList.map(item => {
                            item.user = data?.users_detail_list?.find(user => parseInt(item.receiverId, 10) === parseInt(user.id, 10))
                            return item
                        })
                        return (
                            <>
                                {!data ?
                                    loading || this.state.loading ? (
                                        <ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>) : (
                                        <Label font_medium
                                               align={'center'}
                                               mt={20}>
                                            {"Ingen data"}
                                        </Label>
                                    ) : (
                                        <FlatList data={updatedChatList.slice().reverse()}
                                                  ItemSeparatorComponent={() => <Hr/>}
                                                  renderItem={this.renderChatItem}
                                                  keyExtractor={(item) => item.id?.toString()}
                                        />
                                    )}
                            </>)

                    }}
                </Query>

            </View>
        )

    }
}

/*function ChatList(props) {

    const [ids, setIds] = useState([])
    const [chatList, setChatList] = useState([])
    const [firebaseLoading, setLoading] = useState(true)

    const {loading, data, error} = useQuery(USER_DETAILS_LIST, {
        skip: !ids.length,
        variables: {
            ids: ids
        }
    })

    useEffect(() => {
        if (chatList?.length) {
            let ids = chatList.map(item => item.receiverId)
            setIds(ids)
        }
    }, [chatList])

    useEffect(() => {
        if (data?.users_detail_list?.length) {
            let updatedChatList = chatList.slice()
            updatedChatList = updatedChatList.map(item => {
                item.user = data?.users_detail_list?.find(user => item.receiverId === user.id)
                return item
            })
            setChatList(updatedChatList)
        }
    }, [data])

    useEffect(() => {


    }, []);

}*/

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}
export default connect(mapStateToProps)(withFirebase(ChatList))
