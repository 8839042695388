export default {
  'component.userFooter.find-us': 'hvor finner du oss',
  'component.userFooter.corporate-info': 'selskapsinformasjon',
  'component.userFooter.media-press': 'medie og presse',
  'component.userFooter.social-media': 'sosiale medier',
  'component.userFooter.footer-menulist1.item1': 'Webmegling AS',
  'component.userFooter.footer-menulist1.item2': 'Kokstadflaten 35',
  'component.userFooter.footer-menulist1.item3': '5257 KOKSTAD',
  'component.userFooter.footer-menulist2.item1': 'Kontaktinformasjon',
  'component.userFooter.footer-menulist2.item2': 'Karriere',
  'component.userFooter.footer-menulist3.item1': 'Kommer snart',
  'component.userFooter.privacy': 'Personvern',
  'component.userFooter.terms': 'Brukervilkår',
  'component.userFooter.cookie': 'Informasjonskapsler',
};
