import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Image, TouchableOpacity, View} from 'react-native'
import {Header, Hr, Label, Modal, Ripple, UploadImageDialog} from "components/src/components";
//Utils
import styles from './styles';

import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PERSONAL_TEMPLATE_DETAILS} from "components/src/api/query";
import {
    DELETE_PERSONAL_SERVICE_MEDIA,
    PERSONAL_REPLACE_STYLING_IMAGE,
    PERSONAL_TEMPLATE_STYLING_UPLOAD_IMAGES
} from "components/src/api/mutation";
import PhotoDetails from "components/src/components/view/PhotoDetails";

const TABS = [{
    text: Strings.photoDetails
}, {
    text: Strings.imageGallery
}];

const scrollY = new Animated.Value(0);

function Styling(props) {

    const {history, user, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.PhotoStyling) : navigation.goBack();
    }
    const [service, setService] = useState([]);

    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const [uploadImagesVisible, setUploadVisible] = useState(false);

    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);
    const [imageForPreview, setImageForPreview] = useState({});

    const [replaceImage, replaceImageMutation] = useMutation(PERSONAL_REPLACE_STYLING_IMAGE, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });


    const [uploadImages, uploadImagesMutation] = useMutation(PERSONAL_TEMPLATE_STYLING_UPLOAD_IMAGES, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_PERSONAL_SERVICE_MEDIA, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const {loading, data, error} = useQuery(PERSONAL_TEMPLATE_DETAILS, {
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
            status: 1,
            agentId: user?.id
        }
    });

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);

    useEffect(() => {
        if (data?.personal_template_details) {
            setService(data?.personal_template_details)
        }
    }, [data]);


    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };


    const renderUploadImageDialog = () => {
        return (
            <UploadImageDialog visible={uploadImagesVisible}
                               loading={uploadImagesMutation.loading}
                               oldPhotoRequired
                               onPositivePress={(newImage, oldImage) => {
                                   uploadImages({
                                       variables: {
                                           oldImage: oldImage,
                                           newImage: newImage,
                                           templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                       }

                                   }).then(() => {
                                       setUploadVisible(false)
                                   }).catch(() => {

                                   })
                               }}
                               onNegativePress={() => setUploadVisible(false)}
                               title={Strings.uploadPhoto}/>
        )
    };

    const renderPhotoGallery = () => {
        return (
            <>
                {renderUploadImageDialog()}
                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>


                <Ripple style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}
                        onPress={() => setUploadVisible(true)}>
                    {uploadImagesMutation.loading ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (<Icon name={'add'}
                               size={ThemeUtils.responsiveScale(60)}
                               color={Color.PRIMARY_LIGHT}/>)}

                </Ripple>

                <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  deleteImages({
                                                      variables: {
                                                          ids: selectedImages,
                                                          templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                                      }
                                                  }).then(() => {
                                                      setSelectedImages([])
                                                  }).catch(() => {

                                                  })

                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : renderImageGrid(service ?? [])}
            </>
        );
    };

    const renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity key={item.id}
                                      onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                                      onPress={() => {
                                          if (selectionEnable) {
                                              addOrRemoveImage(item.id)
                                          } else {
                                              setImageForPreview(item);
                                              setPhotoDetailVisible(true)
                                          }
                                      }}
                                      activeOpacity={0.7}>

                        {selectionEnable && selectedImages.includes(item.id) ? (<View style={[styles.selectedImage]}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.TERNARY_TEXT_COLOR}/>
                        </View>) : null}

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}
                               style={styles.GridImage}/>

                        {item?.media_new_url ? (
                            <View style={styles.halfImgCont}>
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_new_url}`}}
                                       style={styles.halfImage}/>
                            </View>
                        ) : null}

                    </TouchableOpacity>

                )
            })}
        </View>
    };
    return (
        <View style={styles.cont}>
            <Header animatedTitle={serviceFromPage?.name}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedValue={scrollY}
                    navigation={IS_WEB ? history : navigation}/>

            <View>
                <Animated.ScrollView scrollEventThrottle={1}
                                     contentContainerStyle={styles.flexGrow}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                         {useNativeDriver: true})}>
                    <View>
                        <View style={styles.orderCont}>
                            <Label small
                                   mt={ThemeUtils.relativeRealHeight(6)}
                                   mb={10}>
                                {/*{serviceFromPage?.office_template?.description}*/}
                            </Label>
                            <Modal visible={isPhotoDetailVisible}
                                   onRequestClose={() => setPhotoDetailVisible(false)}>
                                <PhotoDetails onClose={() => setPhotoDetailVisible(false)}
                                              loading={replaceImageMutation.loading}
                                              onReplaceImage={replaceImage}
                                              oldPhotoRequired
                                              replaceVariables={{
                                                  id: imageForPreview?.id,
                                                  templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                              }}
                                              variables={{
                                                  ids: [imageForPreview?.id],
                                                  templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                              }}
                                              deleteMutation={DELETE_PERSONAL_SERVICE_MEDIA}
                                              refetchQuery={{
                                                  refetchQueries: [{
                                                      query: PERSONAL_TEMPLATE_DETAILS,
                                                      variables: {
                                                          id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                                                          status: 1,
                                                          agentId: user?.id
                                                      }
                                                  }]
                                              }}
                                              imageForPreview={imageForPreview}
                                />
                            </Modal>
                            {renderPhotoGallery()}
                        </View>
                    </View>
                </Animated.ScrollView>
            </View>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(Styling);
