export default {
  'component.insurance.title': 'Forsikring',
  'component.insurance.exportascsv': 'Eksporter som CSV',
  'component.insurance.exportasexcel': 'Eksporter som Excel',
  'component.insurance.exportaspdf': 'Eksporter som PDF',
  'component.insurance.printall': 'Skriv ut alle',
  'component.insurance.tablesearch': 'Søk etter alternativ, navn, e-post, telefon og pantelån',
  'component.insurance.content': 'Få bedre innboforsikring',
  'component.insurance.option': 'Forsikringsalternativer',
};
