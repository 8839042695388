import React, {useState} from "react";
//Components
import {Animated, Keyboard, View} from "react-native";
import {CustomButton, Header, Label, RichTextEditor,} from "components/src/components";
//Third party
import RichToolbar from '../../../../../components/ui/RichText/src/RichToolbar'
//redux
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils,} from "components/src/utils";
import {styles} from "./styles.js";
import {useMutation} from "@apollo/react-hooks";
import {ADD_UPDATE_BEFARING} from "../../../../../api/mutation";
import {connect} from "react-redux";

const scrollY = new Animated.Value(0);

function Befaringsbekreftelse(props) {
    const {navigation, user, history} = props;


    const details = IS_WEB ? history.location?.state?.details ?? {} : navigation.getParam('details', {})

    let richtext;

    const [value, onChangeText] = useState(details?.description ?? "");

    const [updateDetails, updateDetailsMutation] = useMutation(ADD_UPDATE_BEFARING)

    const [errDesc, setErrDesc] = useState(null)


    return (
        <View style={CommonStyle.flex}>
            <Header
                // animatedTitle={Strings.noenordframegler}
                animatedTitle={"Avsnitt 01"}
                navigation={IS_WEB ? history : navigation}
                animatedValue={scrollY}
            />
            <Animated.ScrollView keyboardShouldPersistTaps={'always'}

                                 scrollEventThrottle={1}
                                 contentContainerStyle={{flexGrow: 1}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true}
                                 )}
            >
                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.viewicon}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={"profile"}
                                color={Color.PRIMARY_DARK}
                            />
                        </View>
                        <View>
                            <Label small
                                   font_medium
                                   mt={20}>
                                {Strings.noenordframeglerførbefaring}
                            </Label>
                            <View style={{
                                borderWidth: 1,
                                borderColor: Color.DARK_GRAY,
                                marginVertical: 20,
                                borderRadius: 4
                            }}>
                                <RichTextEditor ref={(r) => richtext = r}
                                                placeholder={Strings.noenordframeglerførbefaring}
                                                initialContentHTML={value}/>
                                <RichToolbar getEditor={() => richtext}/>
                            </View>
                            {/*<InputField
                                type={"text"}
                                mt={16}
                                error={errDesc}
                                textArea={true}
                                placeholder={Strings.noenordframeglerførbefaring}
                                onChange={(text) => onChangeText(text)}
                                labelProps={{
                                    font_medium: true,
                                    small: true,
                                }}
                                labelText={Strings.noenordframeglerførbefaring}
                                value={value}
                            />*/}
                        </View>

                        <View/>
                    </View>
                    <View style={styles.buttonContainer}>
                        <CustomButton loading={updateDetailsMutation.loading}
                                      font_medium
                                      onPress={async () => {
                                          if (!IS_WEB) {
                                              Keyboard.dismiss()
                                          }
                                          const content = await richtext.getContentHtml();
                                          const trueContent = content.toString();
                                          updateDetails({
                                              variables: {
                                                  input: {
                                                      description: trueContent,
                                                  }
                                              }
                                          }).then(() => {

                                          }).catch(() => {

                                          })
                                      }}
                                      mr={ThemeUtils.relativeWidth(1.5)}
                                      style={{flex: 1}} // Do not remove inline will not work in web
                                      title={Strings.save}
                        />

                        <CustomButton
                            ml={ThemeUtils.relativeWidth(1.5)}
                            font_medium
                            onPress={() => {
                                if (IS_WEB) {
                                    history.goBack();
                                } else {
                                    Keyboard.dismiss()
                                    navigation.goBack();
                                }
                            }}
                            style={{
                                flex: 1,
                                borderWidth: 1,
                                borderColor: Color.DARK_GRAY,
                            }} // Do not remove inline will not work in web
                            textColor={Color.PRIMARY}
                            bgColor={Color.WHITE}
                            title={Strings.cancel}
                        />

                    </View>
                </View>
            </Animated.ScrollView>
        </View>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Befaringsbekreftelse);
