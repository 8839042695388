import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, TouchableOpacity, View} from 'react-native';
import {CustomButton, Header, Hr, Label} from "components/src/components";
//Network
import {useQuery} from "@apollo/react-hooks";
import {DS_HISTORY} from "components/src/api/query";
//Third party
import moment from 'moment-timezone'
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";

import styles from './styles';

export default function DSHistory(props) {
    const {navigation, history} = props;

    const [isFetchMore, setFetchMore] = useState(false)
    let assignmentId = IS_WEB ? parseInt(decryptId(props.match.params.id)) : navigation.getParam("id");
    const scrollY = new Animated.Value(0);


    const {loading, data, fetchMore, error, refetch} = useQuery(DS_HISTORY, {
        variables: {
            input: {
                vitec_prop_id: assignmentId,
                first: 30,
                page: 1,
                status: true
            }
        }
    })

    const renderVersions = ({item, index}) => {
        return (
            <>
                <TouchableOpacity activeOpacity={0.5}
                                  onPress={() => openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.pdf_url}`)}
                                  style={styles.versionCont}>
                    <View style={CommonStyle.flex}>
                        <Label small
                               font_medium>
                            {item?.oppdragsnummer}
                        </Label>
                        <Label small
                               font_medium>
                            {moment.utc(item?.created_at).tz('Europe/Oslo').format(DateUtils.dd_mm_yyyy_hh_mm)}
                        </Label>
                    </View>
                    <Icon name={'open-externally'}
                          color={Color.SECONDARY}
                          size={ThemeUtils.fontNormal}/>
                </TouchableOpacity>
            </>
        )
    }
    return (
        <View>
            <Header navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    animatedTitle={Strings.dsHistory}/>

            <Hr marginTop={ThemeUtils.relativeRealHeight(7)}/>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <Animated.FlatList data={data?.ds_history?.data ?? []}
                                   onRefresh={refetch}
                                   refreshing={loading}
                                   onScroll={Animated.event(
                                       [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                       {useNativeDriver: true}
                                   )}
                                   ItemSeparatorComponent={() => <Hr/>}
                                   renderItem={renderVersions}
                                   ListEmptyComponent={

                                       <Label font_medium
                                              style={CommonStyle.loader}
                                              align={'center'}>
                                           {"Ingen data"}
                                       </Label>

                                   }
                                   ListFooterComponent={
                                       data?.ds_history?.paginatorInfo?.hasMorePages ?
                                           (
                                               <CustomButton title={Strings.loadMore}
                                                             loading={isFetchMore}
                                                             mt={10}
                                                             mb={10}
                                                             style={{
                                                                 width: ThemeUtils.relativeWidth(40),
                                                                 alignSelf: 'center'
                                                             }}
                                                             onPress={() => {
                                                                 setFetchMore(true)
                                                                 fetchMore({
                                                                     variables: {
                                                                         input: {
                                                                             vitec_prop_id: 1,
                                                                             first: 30,
                                                                             status: true,
                                                                             page: parseInt(data?.ds_history?.paginatorInfo?.currentPage, 10) + 1,
                                                                         }
                                                                     },
                                                                     updateQuery: (prev, {fetchMoreResult}) => {

                                                                         setFetchMore(false)
                                                                         if (!fetchMoreResult) {
                                                                             return prev;
                                                                         } else {
                                                                             let finalResult = Object.assign({}, fetchMoreResult);
                                                                             finalResult.ds_history.data = [...prev.ds_history.data, ...fetchMoreResult.ds_history.data];
                                                                             return finalResult;
                                                                         }
                                                                     },
                                                                 });
                                                             }}/>

                                           ) : null
                                   }/>
            )}

        </View>
    )
}
