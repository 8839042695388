export default {
  'component.adminstyling.title': 'Stylist',
  'component.adminstyling.description': 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  'component.adminstyling.addstyling': 'Legg til Stylist',
  'component.adminstyling.editstyling': 'Rediger Stylist',
  'component.adminstyling.tablesearch':
    'Søk etter navn, e-post, telefon, eiendomsadresse og postnummer',
  'component.adminstyling.deletemodal.title': 'Slett Stylist',
  'component.adminstyling.deletemodal.text': 'Er du sikker på at du vil slette denne Stylisten?',
};
