import React, {useEffect, useState} from 'react'
import {ActivityIndicator, ScrollView, View} from 'react-native'
import {Dashboard, Header, Label, LogoForHeader} from "components/src/components";
import {Color, CommonStyle, encryptId, IS_WEB, Routes, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";
import Notes from 'components/src/components/ui/Notes'
import {useQuery} from "@apollo/react-hooks";
import {FETCH_PROPERTY_SHOW_CASE} from "components/src/api/query";


function PropertyImageList(props) {
    const {history, navigation} = props;

    const [properties, setProperties] = useState()

    const [visible, setVisible] = useState(false)

    const {loading, data, error} = useQuery(FETCH_PROPERTY_SHOW_CASE)

    useEffect(() => {
        if (data?.fetch_proeprty_showcase) {
            setProperties(data?.fetch_proeprty_showcase?.map((item) => ({
                name: item.heading,
                imageUri: item.cover_image,
                route: item.id
            })))
        }
    }, [data])

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={properties?.id}
                   onDashboardPress={() => {
                       // IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }

    return (
        <View style={styles.container}>
            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>} tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>

            {renderNoteModal()}

            {loading ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : properties?.length?(
                <ScrollView contentContainerStyle={{
                    paddingBottom: 10,
                    paddingHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(2) : 0
                }}>
                    <Dashboard dashboardData={properties}
                               onPressItem={(route) => {
                                   //route will be id
                                   if (route) {
                                       IS_WEB ? history.push(`${Routes.InspectionPropertyImageList}/${encryptId(route)}`) : navigation.navigate(route)
                                   }
                               }}/>
                </ScrollView>
            ):(
                <Label font_medium
                       align={'center'}
                       mt={20}>
                    {"Ingen data"}
                </Label>
            )}

        </View>

    )
}

const mapStateToProps = (state) => {
    console.log(state.inspection)
    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(PropertyImageList)
