import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,
        justifyContent: 'center'
    },
    dialogCont: {
        marginHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(40) : ThemeUtils.relativeRealWidth(6),
        borderRadius: 8,
        backgroundColor: Color.WHITE
    },
    divider: {
        width: 1,
        backgroundColor: Color.PRIMARY_LIGHTER
    },
    btnCont: {
        flexDirection: 'row',
    },
    lblTitle: {
        paddingTop: 10
    },
    IconsView: {
        backgroundColor: Color.DARK_GRAY,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: 12,
        marginEnd: 10,
        borderRadius: 4
    },
    MainView: {

        marginTop: ThemeUtils.relativeRealHeight(3),
        paddingHorizontal:ThemeUtils.relativeRealWidth(2),
        flexDirection: 'row',

    },
    btn: {
        flex: 1,
        height: ThemeUtils.relativeRealHeight(6),
        alignItems: 'center',
        justifyContent: 'center'
    }

})
