import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, Keyboard, View} from 'react-native'

import {
    CustomButton,
    Header,
    Hr,
    InputField,
    Label,
    RichTextEditor,
    RichToolbar,
    Toast
} from "components/src/components";
//redux
import {connect} from "react-redux";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {styles} from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {OFFICE_OPP_DRAG_AGREEMENT_DETAILS} from "../../../../../api/query";
import {OFFICE_SAVE_OPPDRAG_AGREEMENT} from "../../../../../api/mutation";

const scrollY = new Animated.Value(0);

let richtext, rtOtherRemuneration, rtFinancialSettlement,rtStaker ,toast;
const OfficeOppdragAgreement = props => {
    const {history, navigation} = props

    const [settlement, setFinancialSettelment] = useState('');
    const [errSettlement, setErrFinancialSettelment] = useState('');


    const [employees, setEmployees] = useState('');
    const [errEmployees, setErrEmployees] = useState('');

    const [staker, setStaker] = useState("");
    const [errStaker, setErrStaker] = useState("");

    const [oppgjor, setOppgjor] = useState("");
    const [errOpgjor, setErrOppgjor] = useState("");

    const [hourlyRate, setHourlyRate] = useState("");
    const [errHourlyRate, setErrHourlyRate] = useState("");

    const [timeSpent, setTimeSpent] = useState("");
    const [errTimeSpent, setErrTimeSpent] = useState("");

    const [hourlyRemuneration, setHourlyRemuneration] = useState("");
    const [errHourlyRemuneration, setErrHourlyRemuneration] = useState("");

    const [otherRemuneration, setOtherRemuneration] = useState("");
    const [errOtherRemuneration, setErrOtherRemuneration] = useState("");

    const [warranty, setSalesWarranty] = useState("Ved oppsigelse, fornyelse eller utløp av oppdraget gjelder følgende salgsgaranti: Intet salg, ingen regning.Faktura fra takstmann, fotograf og eventuelt stylist faktureres oppdragsgiver direkte og må betales uavhengig. Oppdragstaker har krav på dekning av eventuelle tilleggsprodukter som ikke fremgår av punkt 4.3 og som bestilles etter oppdragsinngåelsen. Meglers krav på vederlag etter dette punkt forfaller 14 dager etter at oppdraget har utløpt eller er sagt opp.");
    const [errWarranty, setErrSalesWarranty] = useState("");

    const [warrantyNo, setSalesWarrantyNo] = useState("Ved oppsigelse, fornyelse eller utløp av oppdraget har oppdragstaker krav på dekning av tilrettelegging samt påløpte kostnader under pkt. 4.3. Kostnad for markedsføring påløper så snart eiendommen er annonsert eller når avisannonse er bestilt. Ved avtale om timebasert vederlag har oppdragstaker krav på dekning av utført arbeid under punkt 4.1, samt påløpte kostnader under punkt 4.3. Meglers krav på vederlag etter dette punkt forfaller 14 dager etter at oppdraget har utløpt eller er sagt opp.");
    const [errWarrantyNo, setErrSalesWarrantyNo] = useState("");

    const [saveAgreement, saveMutation] = useMutation(OFFICE_SAVE_OPPDRAG_AGREEMENT);

    useEffect(() => {
        // setFinancialSettelment(oppgjor)
    }, [oppgjor]);

    const {loading, data, error} = useQuery(OFFICE_OPP_DRAG_AGREEMENT_DETAILS, {
        variables: {
            status: 1,
            officeId: props.user?.office_id
        }
    });

    useEffect(() => {
        if (data?.office_oppdrag_agreement_content) {
            setFinancialSettelment(data?.office_oppdrag_agreement_content?.financial_settlement);
            // setEmployees(data?.office_oppdrag_agreement_content?.settlement_employees);
            setStaker(data?.office_oppdrag_agreement_content?.oppdrag_staker);
            setHourlyRemuneration(data?.office_oppdrag_agreement_content?.hourly_remuneration);
            setHourlyRate(data?.office_oppdrag_agreement_content?.hourly_rate);
            setOtherRemuneration(data?.office_oppdrag_agreement_content?.other_remuneration);
            setSalesWarranty(data?.office_oppdrag_agreement_content?.sales_warranty_yes ?? warranty);
            setSalesWarrantyNo(data?.office_oppdrag_agreement_content?.sales_warranty_no ?? warrantyNo);
            setTimeSpent(data?.office_oppdrag_agreement_content?.time_spent);
            setOppgjor(data?.office_oppdrag_agreement_content?.Oppgjor_sforetak)
        }
    }, [data]);

    const validate = async () => {
        if (!IS_WEB) {
            Keyboard.dismiss();
        }
        const content = await richtext.getContentHtml();
        const trueContent = await content.toString();

        const otherRemunerationContent = await rtOtherRemuneration.getContentHtml();
        const otherRemuneration = await otherRemunerationContent.toString();

        const settlementContent = await rtFinancialSettlement.getContentHtml();
        const settlement = await settlementContent.toString();

        const stackerContent = await rtStaker.getContentHtml();
        const stackerString = await stackerContent.toString();

        let errSta = validation('staker', stackerString)
        let errFin = validation('settlement', settlement)
        // let errEmp = validation('employees', employees)
        let errOpp = validation('oppgjor', oppgjor)
        let errRate = validation('hourlyRate', hourlyRate)
        let errTime = validation('timespent', timeSpent)
        let errHourly = validation('remuneration', trueContent)
        let errOther = validation('remuneration', otherRemuneration)
        let errWar = validation('warranty', warranty)
        if (!errSta &&
            !errOpp &&
            !errRate &&
            !errTime &&
            !errWar) {
            if (errHourly) {
                showMessage(errHourly, Constants.MessageType.FAILED)
            } else if (errOther) {
                showMessage(errOther, Constants.MessageType.FAILED)
            } else if (errFin) {
                showMessage(errFin, Constants.MessageType.FAILED)
            } else if(errSta){
                showMessage(errSta, Constants.MessageType.FAILED)
            } else {
                saveAgreement({
                    variables: {
                        input: {
                            oppdrag_staker: stackerString,
                            Oppgjor_sforetak: oppgjor,
                            financial_settlement: settlement,
                            // settlement_employees: employees,
                            hourly_rate: parseInt(hourlyRate, 10),
                            time_spent: parseInt(timeSpent),
                            hourly_remuneration: trueContent,
                            other_remuneration: otherRemuneration,
                            sales_warranty_yes: warranty,
                            sales_warranty_no: warrantyNo,
                        }
                    }
                }).catch(() => {

                })
            }
        } else {
            // setErrStaker(errSta);
            // setErrFinancialSettelment(errFin);
            // setErrEmployees(errEmp);
            setErrOppgjor(errOpp);
            setErrHourlyRate(errRate);
            setErrTimeSpent(errTime);
            setErrHourlyRemuneration(errHourly);
            setErrOtherRemuneration(errOther);
            setErrSalesWarranty(errWar);
        }
    };

    const renderAgreementInput = () => {
        return (
            <View style={CommonStyle.flex}>
                <View>
                    <View style={styles.topContainer}>


                        {/*<InputField textArea
                                    labelText={"Oppdragstaker"}
                                    placeholder={"Oppdragstaker"}
                                    mt={10}
                                    type={'text'}
                                    onFocus={() => setErrStaker(null)}
                                    error={errStaker}
                                    value={staker}
                                    onChange={setStaker}
                        />*/}


                        <Label small
                               font_medium
                               mb={10}
                               mt={10}>
                            {"Oppdragstaker"}
                        </Label>

                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            borderRadius: 4,
                            marginBottom: 20
                        }}>
                            <RichTextEditor ref={(r) => rtStaker = r}
                                            placeholder={"Oppdragstaker"}
                                            initialContentHTML={staker}/>
                            <RichToolbar getEditor={() => rtStaker}/>
                        </View>

                        <InputField labelText={"Oppgjørsforetak"}
                                    placeholder={"Oppgjørsforetak"}
                                    type={'text'}
                                    textArea
                                    onFocus={() => setErrOppgjor(null)}
                                    value={oppgjor}
                                    error={errOpgjor}
                                    onChange={setOppgjor}
                        />
                    </View>
                    <Hr/>
                    <View style={styles.topContainer}>
                        <Label large
                               mt={20}
                               mb={15}
                               font_medium>
                            {"Særlige bestemmelser"}
                        </Label>
                        {/*  <InputField labelText={"Det økonomiske oppgjøret foretas av"}
                                    textArea
                                    editable={false}
                                    type="text"
                                    onFocus={() => setErrFinancialSettelment(null)}
                                    onChange={(text) => setFinancialSettelment(text)}
                                    error={errSettlement}
                                    value={settlement}
                        />*/}

                        <Label small
                               font_medium
                               mb={10}>
                            {"Det økonomiske oppgjøret foretas av"}
                        </Label>

                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            borderRadius: 4,
                            marginBottom: 20
                        }}>
                            <RichTextEditor ref={(r) => rtFinancialSettlement = r}
                                            placeholder={"Det økonomiske oppgjøret foretas av"}
                                            initialContentHTML={settlement}/>
                            <RichToolbar getEditor={() => rtFinancialSettlement}/>
                        </View>

                        {/*<InputField labelText={"Oppgjørsmedarbeidere"}
                                    type="text"
                                    onFocus={() => setErrEmployees(null)}
                                    placeholder={"Oppgi hvem som er oppgjørsmedarbeidere"}
                                    onChange={(text) => setEmployees(text)}
                                    error={errEmployees}
                                    value={employees}
                        />*/}
                    </View>
                    <Hr/>
                    <View style={styles.topContainer}>
                        <InputField mt={20}
                                    labelText={"Timesatsen"}
                                    type={'number'}
                                    placeholder={"Oppgi timesats"}
                                    error={errHourlyRate}
                                    value={hourlyRate}
                                    onFocus={() => setErrHourlyRate(null)}
                                    onChange={setHourlyRate}>

                            <View style={CommonStyle.inputExtra}>
                                <Label font_medium>
                                    {'Kr.'}
                                </Label>
                            </View>
                        </InputField>
                        <InputField
                            labelText={"Totalt antall timer"}
                            type={'number'}
                            error={errTimeSpent}
                            onFocus={() => setErrTimeSpent(null)}
                            placeholder={"Oppgi antall timer"}
                            value={timeSpent}
                            onChange={setTimeSpent}/>

                        <Label small
                               font_medium
                               mb={10}>
                            {"Timebasert Vederlag"}
                        </Label>

                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            borderRadius: 4
                        }}>
                            <RichTextEditor ref={(r) => richtext = r}
                                            placeholder={"Timebasert Vederlag"}
                                            initialContentHTML={hourlyRemuneration}/>
                            <RichToolbar getEditor={() => richtext}/>
                        </View>
                        {/*<InputField textArea
                                    error={errHourlyRemuneration}
                                    labelText={"Timebasert Vederlag"}
                                    type={'text'}
                                    value={hourlyRemuneration}
                                    onChange={setHourlyRemuneration}/>*/}


                        <Label small
                               mt={20}
                               mb={10}
                               font_medium>
                            {"Øvrige vederlag"}
                        </Label>

                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            borderRadius: 4,
                            marginBottom: 20
                        }}>
                            <RichTextEditor ref={(r) => rtOtherRemuneration = r}
                                            placeholder={"Øvrige vederlag"}
                                            initialContentHTML={otherRemuneration}/>
                            <RichToolbar getEditor={() => rtOtherRemuneration}/>
                        </View>

                        {/*    <InputField textArea
                                    labelText={"Øvrige Vederlag"}
                                    type={'text'}
                                    onFocus={() => setErrOtherRemuneration(null)}
                                    error={errOtherRemuneration}
                                    value={otherRemuneration}
                                    onChange={setOtherRemuneration}/>*/}
                        <InputField textArea
                                    labelText={"Salgsgaranti (Ja)"}
                                    placeholder={"Salgsgaranti (Ja)"}
                                    type={'text'}
                                    onFocus={() => setErrSalesWarranty(null)}
                                    error={errWarranty}
                                    value={warranty}
                                    onChange={setSalesWarranty}/>

                        <InputField textArea
                                    labelText={"Salgsgaranti (Nei)"}
                                    placeholder={"Salgsgaranti (Nei)"}
                                    type={'text'}
                                    onFocus={() => setErrSalesWarrantyNo(null)}
                                    error={errWarrantyNo}
                                    value={warrantyNo}
                                    onChange={setSalesWarrantyNo}/>

                    </View>
                </View>
            </View>);
    };

    return (
        <View style={CommonStyle.flex}>
            <Toast ref={r => toast = r}/>
            <Header navigation={IS_WEB ? props.history : props.navigation}
                    animatedTitle={Strings.oppdragAgreementEdits}
                    animatedValue={scrollY}/>


            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.titleCont}>
                        <Icon name={'obtaining-checking-documentation'} size={ThemeUtils.fontXXLarge}/>
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : renderAgreementInput()}

                </View>
            </Animated.ScrollView>
            {loading && !data ? null : (
                <View style={styles.bottomButtonContainer}>
                    <CustomButton loading={saveMutation.loading}
                                  mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2}
                                  style={{flex: 1}}
                                  title={Strings.save}
                                  onPress={() => validate()}/>
                    <CustomButton
                        style={{flex: 1}}
                        bgColor={Color.WHITE}
                        borderColor={Color.PRIMARY}
                        borderWidth={1}
                        textColor={Color.PRIMARY}
                        ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN / 2}
                        title={Strings.cancel}
                        onPress={() => {
                            IS_WEB ? history.goBack() : navigation.goBack()
                        }}/>
                </View>)}

        </View>
    );
};
const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    console.log(state.user);
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(OfficeOppdragAgreement);
