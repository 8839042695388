import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:Color.PRIMARY_BG_COLOR,
        paddingBottom:10
    },
    contentCont:{
        flex:1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    imgCong:{
        alignSelf:'center',
        height:ThemeUtils.relativeRealWidth(80),
        width:ThemeUtils.relativeRealWidth(80)
    },
    signRemaining:{
        backgroundColor:Color.PRIMARY,
        alignSelf: 'center',
        alignItems:'center',
        justifyContent:'center',
        paddingHorizontal:ThemeUtils.relativeRealWidth(4),
        paddingVertical:4,
        marginVertical:2,
        borderRadius:4
    }
})
