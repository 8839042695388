// import ApolloClient,{InMemoryCache} from 'apollo-boost';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {ApolloLink, Observable} from 'apollo-link';
import {RetryLink} from 'apollo-link-retry';

import {createUploadLink} from 'apollo-upload-client';

import {store} from 'components/src/redux/store';
import {logout, setToken} from "../redux/action";
import apolloLogger from 'apollo-link-logger';

import {REFRESH_TOKEN} from "./mutation";
import {print} from "graphql";

import Cookie from 'js-cookie'
import moment from "moment";
import jwtDecode from 'jwt-decode'

import {Constants, IS_WEB, Routes, showMessage} from '../utils';

const retryLink = new RetryLink({
    delay: {
        initial: 5000,
        max: 10000,
    },
    attempts: {
        max: 10,
        retryIf: (error, _operation) => {
            if (error.message === 'Network request failed') {
                switch (_operation.operationName) {
                    case 'appversion':
                        return true;
                }
                console.log('RETRYING..............');
                return false;
            }
            return false;
        },
    },
});


const refreshToken = (uri, options) => {
    let refreshingPromise;
    // Grab the refresh token from the store
    let refresh_token = store.getState().token?.access_token ?? undefined;

    if (!refresh_token && IS_WEB) {
        refresh_token = Cookie.get('token')
    }
    // Execute the re-authorization request and set the promise returned to this.refreshingPromise
    const query = JSON.stringify({
        query: print(REFRESH_TOKEN)
    });
    refreshingPromise = fetch(uri, {
        headers: {
            'content-type': 'application/json',
            'authorization': refresh_token ? `Bearer ${refresh_token}` : "",
        },
        method: 'POST',
        body: query,
    })
    return refreshingPromise
}

const customFetch = (uri, options) => {

    let initialRequest = fetch(uri, options);

    return initialRequest.then((response) => {

        return (response.text())
    }).then((response) => {

        let json = JSON.parse(response)

        if (json && json.errors && json.errors[0] && json.errors[0].message === 'Din økt er logget ut. Vennligst logg inn om igjen.') {
            if (!IS_WEB) {
                return refreshToken(uri, options).then((response) => {
                    let newAccessToken=response.body()
                    store.dispatch(setToken(newAccessToken))
                    options.headers.authorization = `Bearer ${newAccessToken?.access_token}`;
                    return fetch(uri, options);
                })
            } else {
                let user = store.getState().user
                store.dispatch(logout());
                if (IS_WEB) {
                    Cookie.remove(`Agent_token`);
                    Cookie.remove(`Agent_user`);
                    localStorage.clear()
                    location && location.replace(`/${location.pathname.toLowerCase().split('/')[1]}/login`)
                } else {
                    const id = 'mobile/src/utils/NavigationUtils'
                    let NavigationUtils = require(`${id}`).default;
                    NavigationUtils.reset(Routes.UnAuthenticated)
                }
                return;
            }
        } else if (IS_WEB) {
            let refresh_token = store.getState().token?.access_token ?? undefined;
            if (!refresh_token) {
                refresh_token = Cookie.get('token')
            }
            if (refresh_token) {
                const {exp} = jwtDecode(refresh_token);
                const expirationTime = (exp * 1000) - 60000;
                if (moment(expirationTime).diff(moment(), 'm') <= 15) {
                    refreshToken(uri, options)
                        .then((newAccessToken) => newAccessToken.json())
                        .then((response) => {
                            console.log(e, "RESPONSE =-=-=-=-=-")
                            store.dispatch(setToken(response?.data?.refresh_token?.token))
                            if (IS_WEB) {
                                Cookie.set('token', response?.data?.refresh_token?.token?.access_token);
                                Cookie.set(`Agent_token`, response?.data?.refresh_token?.token?.access_token);
                            }
                        }).catch(() => {
                    })
                }
            }
        }
        return {
            ok: true,
            text: () => new Promise(function (resolve, reject) {
                resolve(response);
            })
        }
    })
};


const httpLink = createUploadLink({
    uri: Constants.APIConfig.BASE_URL,
    fetch: customFetch,
});

const request = async (operation) => {
    let token = store.getState().token?.access_token ?? undefined;
    // console.log('REQUESTING', operation.operationName, operation.variables);
    // console.log("TOKEN---------------------------------------", token);
    if (!token && IS_WEB) {
        token = Cookie.get('token')
    }
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : "",
        },
    });
};

const error = onError((error) => {
    const {graphQLErrors, networkError, operation} = error;

    if (graphQLErrors) {
        if (graphQLErrors[0]?.message === 'Innloggingen din er ikke lenger gyldig. Vennligst logg inn om igjen.') {
            let user = store.getState().user
            store.dispatch(logout());
            if (IS_WEB) {
                Cookie.remove(`Agent_token`);
                Cookie.remove(`Agent_user`);
                localStorage.clear()
                location &&location.replace(`/${location.pathname.toLowerCase().split('/')[1]}/login`)
            } else {
                //TODO:Mobile route
            }
        } else if (graphQLErrors && graphQLErrors[0]) {
            //Skip Messages for specific call
            console.log(graphQLErrors[0].message, "RRREEEE");

            if (graphQLErrors[0]?.message) {
                showMessage(graphQLErrors[0]?.message, Constants.MessageType.FAILED);
            } else {
                showMessage(graphQLErrors[0]?.debugMessage, Constants.MessageType.FAILED);
            }
        }
    } else if (networkError) {

        if (!networkError.message && store.getState().token) {
            showMessage('Noe gikk galt, vennligst prøv igjen.', Constants.MessageType.FAILED);
        } else if (networkError.message === 'Unexpected token < in JSON at position 0' ||
            networkError.message === 'JSON Parse error: Unexpected token: <' ||
            networkError.message === 'JSON Parse error: Unrecognized token \'<\'') {
            showMessage('Noe gikk galt, vennligst prøv igjen.', Constants.MessageType.FAILED);
        } else {
            showMessage(networkError.message, Constants.MessageType.FAILED);
        }
    }
});

const requestLink = new ApolloLink((operation, forward) => new Observable(observer => {
    let handle;

    Promise.resolve(operation)
        .then(opr => request(opr))
        .then(() => {
            handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));
    return () => {
        if (handle) {
            handle.unsubscribe();
        }
    };
}));


const link = ApolloLink.from([apolloLogger, error, requestLink, httpLink]);
// const link = ApolloLink.from([error, requestLink, httpLink]);
const cache = new InMemoryCache();

const client = new ApolloClient({
    link,
    cache,
    defaultOptions:{
        query:{
            fetchPolicy:"network-only"
        }
    }
});

/*const applyPersistCache = async () => {
    try {
        await persistCache({
            cache,
            storage: AsyncStorage,
        });
    } catch (err) {
        console.error('Error restoring Apollo cache', err);
    }
};

applyPersistCache().then(() => console.log('Cache Persisted'));*/

export default client;
