import React, {useEffect, useState} from 'react'
import {ActivityIndicator, Image, ScrollView, TouchableOpacity, View} from 'react-native'
import {FlatGrid, Header, IndicatorViewPager, Label, LogoForHeader, Modal} from "components/src/components";
import {Color, CommonStyle, Constants, decryptId, IS_WEB, Routes, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {connect} from "react-redux";
import Notes from 'components/src/components/ui/Notes'
import {useQuery} from "@apollo/react-hooks";
import {PROPERTY_SHOW_CASE_IMAGE} from "components/src/api/query";

let viewPagerRef;

function PropertyImages(props) {
    const {history, navigation} = props;


    const [propertyImageDetails, setPropertyImageDetails] = useState([])
    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);
    const [imageForPreview, setImageForPreview] = useState(1)

    const [visible, setVisible] = useState(false)
    const [notes, setNotes] = useState("")

    const {loading, data, error} = useQuery(PROPERTY_SHOW_CASE_IMAGE, {
        variables: {
            id: IS_WEB ? parseInt(decryptId(props.match.params.propertyId)) : propertyImageDetails?.property_id
        }
    })

    useEffect(() => {
        if (data?.proeprty_showcase_images) {
            setPropertyImageDetails(data?.proeprty_showcase_images)
        }
    }, [data])

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   value={notes}
                   id={propertyImageDetails?.id}
                   onDashboardPress={() => {
                       // IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)
                       setVisible(false)
                   }}/>

        )
    }

    const renderImageGrid = (data) => {
        if (!data.length) {
            return (
                <View style={CommonStyle.content_center}>
                    <Label mt={20}>
                        {"Ingen data"}
                    </Label>
                </View>
            )
        }
        return <View style={styles.ImageGridView}>
            <FlatGrid data={data}
                      spacing={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                      itemDimension={ThemeUtils.responsiveScale(150)}
                      renderItem={({item, index}) => {
                          return (
                              <TouchableOpacity
                                  key={item.id}
                                  onPress={() => {

                                      setImageForPreview(index)
                                      setPhotoDetailVisible(true)
                                  }}
                                  activeOpacity={0.7}>


                                  <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}

                                         style={styles.GridImage}/>
                              </TouchableOpacity>
                          )
                      }}/>
        </View>
    };

    return (
        <View style={styles.container}>
            <Modal visible={isPhotoDetailVisible}
                   onRequestClose={() => setPhotoDetailVisible(false)}>


                <Header onPressLeft={() => setPhotoDetailVisible(false)}
                        style={styles.header}
                        tintColor={Color.WHITE}/>
                <View style={styles.indicatorCont}>
                    <IndicatorViewPager style={CommonStyle.flex}
                                        onPageSelected={e => setImageForPreview(e.position)}
                                        initialPage={imageForPreview}
                                        ref={ref => viewPagerRef = ref}>


                        {propertyImageDetails.map(item => (
                            <View>
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}
                                       resizeMode={'contain'}
                                       style={CommonStyle.flex}/>
                            </View>
                        ))}

                    </IndicatorViewPager>
                </View>

            </Modal>
            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>} tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}/>

            {renderNoteModal()}

            {loading ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <ScrollView contentContainerStyle={{
                    paddingBottom: 10,
                    paddingHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(2) : 0
                }}>

                    {renderImageGrid(propertyImageDetails ?? [])}

                </ScrollView>
            )}

        </View>

    )
}

const mapStateToProps = (state) => {
    console.log(state.inspection)
    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(PropertyImages)
