export default {
  'component.homepage.title.line1': 'Erfarne meglere',
  'component.homepage.title.line2': 'kombinert med',
  'component.homepage.title.line3': 'ny teknologi',
  'component.homepage.subtitle':
    'Webmegling er en fullverdig eiendomsmegler hvor vi har utviklet ny teknologi for et tryggere, mer effektivt og rimeligere boligsalg.',
  'component.homepage.price-btn': 'Beregn pris her',
  'component.homepage.call-broker': 'Bli oppringt av megler',
  'component.homepage.section2.title': 'Fast pris fra kr. 35.000,-',
  'component.homepage.section2.subtitle.line1':
    'Erfarne eiendomsmeglere gjør hele jobben, men vår digitale plattform gir både kjøper og selger en digital opplevelse og bedre oversikt.',
  'component.homepage.section2.subtitle.line2': 'Følgende er inkludert:',
  'component.homepage.section2.iconList1': 'Erfaren eiendomsmegler',
  'component.homepage.section2.iconList2': 'Innhenting og kontroll av dokumentasjon',
  'component.homepage.section2.iconList3': 'Profesjonell fotograf',
  'component.homepage.section2.iconList4': 'Smart digital markedsføring',
  'component.homepage.section2.iconList5': 'Digital salgsoppgave og Finn-annonse',
  'component.homepage.section2.iconList6': 'Visningspakke',
  'component.homepage.section2.iconList7': 'Budrunde håndtert av megler',
  'component.homepage.section3.title': 'Slik gjør vi det:',
  'component.homepage.section3.step1.title': 'Start ditt oppdrag',
  'component.homepage.section3.step1.description':
    'Du setter enkelt sammen ditt oppdrag i vår oppdragsvelger på webmegling.no, og signerer med BankID. Eiendomsmegler tar så kontakt med deg, og det legges en fremdriftsplan. Offentlige dokumenter for boligen samles inn og kontrolleres.',
  'component.homepage.section3.step2.title': 'Besøk av megler',
  'component.homepage.section3.step2.description':
    'Eiendomsmegler kommer hjem på befaring og estimerer en prisantydning for eiendommen. Megler kartlegger også eiendommens muligheter, utfordringer og gir tips og råd om hvordan du kan øke dens verdi.',
  'component.homepage.section3.step3.title': 'Fotografering',
  'component.homepage.section3.step3.description':
    'Eiendomsmegler bestiller profesjonell fotograf som kommer på avtalt tidspunkt for å fotografere din eiendom.',
  'component.homepage.section3.step4.title': 'Salgsoppgave og markedsføring',
  'component.homepage.section3.step4.description':
    'Eiendomsmegler utarbeider salgsoppgave og Finn-annonse, som videre sendes til deg for gjennomgang og godkjenning før boligen legges ut. Annonse for boligen publiseres med vår smarte markedsføring i sosiale medier for økt spredning og rekkevidde.',
  'component.homepage.section3.step5.title': 'Visning og budrunde',
  'component.homepage.section3.step5.description':
    'Du holder visning av boligen din med hjelp av vår visningspakke. Eiendomsmegler tar seg av videre kontakt med kunder og budrunde. Ønsker du heller eiendomsmegler på visning, kan dette enkelt legges til som et tilvalg.',
  'component.homepage.section3.step6.title': 'Salg',
  'component.homepage.section3.step6.description':
    'Når boligen har en kjøper, signerer du kjøpekontrakt og all nødvendig dokumentasjon digitalt med BankID.',
  'component.homepage.section3.step7.title': 'Overtagelse og oppgjør',
  'component.homepage.section3.step7.description':
    'Vi gjennomfører oppgjør etter salget og sørger for at pengene er overført til rett tid. Du klargjør eiendommen til overtakelse med råd fra eiendomsmegler.',
  'component.homepage.section4.title': 'Abonner på vårt nyhetsbrev.',
  'component.homepage.section4.subtitle':
    'Bli oppdatert på nyheter fra Webmegling om alt fra våre tjenester, tilbud og kampanjer, samt nyttig informasjon for deg som skal kjøpe eller selge bolig.',
  'component.homepage.section4.checkbox':
    'JA TAKK, send meg informasjon og nyheter om boligmarkedet. Jeg godtar også Webmegling sine vilkår for behandling av persondata. Mer info i personvernerklæringen her.',
};
