import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, Image, SafeAreaView, View} from 'react-native'
import {
    CustomButton,
    FilePicker,
    FileType,
    Header,
    Hr,
    InputField,
    Label,
    Modal,
    Ripple,
    Toast
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils, showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {NEW_OTHER_SERVICES} from "../../../../../api/query";
import {UPDATE_BROKER_ON_VIEW} from "../../../../../api/mutation";

const PRICE_OPTIONS = [{
    name: Strings.price,
    value: 'Price'
}, {
    name: Strings.other,
    value: 'Other'
}];

let toast;
const scrollY = new Animated.Value(0);
const scrollYMain = new Animated.Value(0);
const ServiceDetails = props => {

    const {history, navigation} = props;


    const [serviceName, setServiceName] = useState("");
    const [errName, setErrName] = useState(null);

    const [description, setDescription] = useState("");
    const [errDescription, setErrDescription] = useState(null);

    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);

    const [addServiceVisible, setAddServiceVisible] = useState(false);

    const [serviceForEdit, setServiceForEdit] = useState({});


    const [serviceType, setServiceType] = useState(PRICE_OPTIONS[0].value);

    const [files, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        if (files[0]) {
            setFileUrl(IS_WEB ? URL.createObjectURL(files[0]) : files[0]?.uri)
        }
    }, [files])


    const [saveServiceDetails, saveDetailsMutation] = useMutation(UPDATE_BROKER_ON_VIEW, {
        refetchQueries: [{
            query: NEW_OTHER_SERVICES,
        }]
    });


    const {loading, data, error} = useQuery(NEW_OTHER_SERVICES);


    useEffect(() => {
        setErrDescription(null);
        setErrName(null);
        setErrServicePrice(null)
        if (!addServiceVisible) {
            setServiceName("");
            setDescription("");
            setServiceType("Price");
            setServicePrice("");
        }
    }, [addServiceVisible]);

    useEffect(() => {
        if (serviceForEdit) {
            setServiceName(serviceForEdit?.name);
            setDescription(serviceForEdit?.description);
            if (serviceForEdit?.is_free) {
                setServiceType('Other')
                setServicePrice(serviceForEdit?.free_text)
            } else {
                setServicePrice(parseFloat(serviceForEdit?.price).toString())
            }
        } else {
            setServiceName("");
            setDescription("");
            setServiceType("Price");
            setServicePrice("");

        }
    }, [serviceForEdit]);


    const validate = () => {
        let errHead = validation('name', serviceName);
        let errIntro = validation('description', description?.trim() ?? "");
        let errPrice = validation('servicePrice', servicePrice ? servicePrice : "");
        if (!files.length && !serviceForEdit?.image) {
            showMessage(MessageUtils.Errors.image, Constants.MessageType.FAILED)
        } else if (!errHead && !errPrice && !errIntro) {
            let variables = {
                id: serviceForEdit?.id,
                name: serviceName,
                description: description,
                price: servicePrice
            }
            if (files.length) {
                variables.image = files[0]
            }
            saveServiceDetails({
                variables: variables
            }).then((data) => {
                setAddServiceVisible(false);
                setServiceForEdit(null)
                showMessage(data?.data?.update_broker_view?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        } else {
            setErrName(errHead);
            setErrDescription(errIntro);
            setErrServicePrice(errPrice)
        }
    };


    const renderAddEditModal = () => {
        return (
            <Modal visible={addServiceVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setAddServiceVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Toast ref={t => toast = t}/>
                        <Header animatedTitle={serviceForEdit ? Strings.editService : Strings.addService}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                onPressLeft={() => {
                                    setAddServiceVisible(false)
                                    setServiceForEdit(null)
                                }}
                                animatedValue={scrollY}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.topContainer}>

                                <Label small
                                       font_medium
                                       mt={ThemeUtils.relativeRealHeight(7)}
                                       mb={10}>
                                    {Strings.serviceImage}
                                </Label>
                                <FilePicker type={FileType.IMAGE}
                                            onFilePicked={(data, file) => {
                                                setFile(file)
                                            }}
                                            style={{alignSelf: 'flex-start'}}>
                                    {files.length ? (
                                        <Image source={IS_WEB ? fileUrl : {uri: fileUrl}}
                                               style={styles.img}/>
                                    ) : serviceForEdit?.image && !files.length ? (
                                        <Image
                                            source={{uri: `${Constants.APIConfig.STORAGE_URL}${serviceForEdit?.image}`}}
                                            style={styles.img}/>
                                    ) : (
                                        <View style={styles.SubViewIcon}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.INPUT_BORDER}/>
                                        </View>)}
                                </FilePicker>
                                <InputField mt={ThemeUtils.relativeRealHeight(2)}
                                            onFocus={() => setErrName(null)}
                                            error={errName}
                                            type={'text'}
                                            value={serviceName}
                                            onChange={setServiceName}
                                            labelText={Strings.serviceName}/>

                                <InputField type={'number'}
                                            labelText={Strings.servicePrice}
                                            onFocus={() => setErrServicePrice(null)}

                                            error={errServicePrice}
                                            value={servicePrice}
                                            onChange={setServicePrice}>
                                    <View style={CommonStyle.inputExtra}>
                                        <Label font_medium>
                                            {'Kr.'}
                                        </Label>
                                    </View>
                                </InputField>
                                <InputField textArea
                                            type={'text'}
                                            onFocus={() => setErrDescription(null)}
                                            error={errDescription}
                                            value={description}
                                            onChange={setDescription}
                                            labelText={Strings.discription}/>
                            </View>
                        </Animated.ScrollView>
                        <View style={styles.bottomButtonContainer}>
                            <CustomButton loading={saveDetailsMutation.loading}
                                          style={{flex: 0.45}}
                                          title={serviceForEdit ? Strings.save : Strings.add}
                                          onPress={validate}/>
                            <CustomButton bgColor={Color.TRANSPARENT}
                                          borderColor={Color.PRIMARY}
                                          borderWidth={1}
                                          textColor={Color.PRIMARY}
                                          style={{flex: 0.45}}
                                          title={Strings.cancel}
                                          onPress={() => {
                                              setAddServiceVisible(false)
                                              setServiceForEdit(null)
                                          }}/>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderIntroDetails = services => {
        return (
            <View>
                {services?.map((item, index) => {
                    return (
                        <Ripple key={index}
                                onPress={() => {
                                    setServiceForEdit(item);
                                    setAddServiceVisible(true)
                                }}>
                            <Hr marginTop={ThemeUtils.relativeRealHeight(1.5)}/>

                            <View style={styles.optionContainer}>
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}
                                       style={styles.imgThumb}/>
                                <View style={CommonStyle.flex}>
                                    <Label font_medium
                                           ml={20}
                                           mb={5}>
                                        {item.name}
                                    </Label>
                                    <Label small
                                           ml={20}
                                           mr={10}
                                           mb={10}>
                                        {item.description}
                                    </Label>
                                    <Label small
                                           ml={20}>
                                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                                    </Label>
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'edit'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                        </Ripple>
                    )
                })}
            </View>
        );
    };

    return (
        <View style={CommonStyle.flex}>

            <Header animatedTitle={Strings.newOtherService}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollYMain}/>

            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollYMain}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>

                        <Label
                            mt={ThemeUtils.relativeRealHeight(1.5)}
                            small
                            align={'left'}>

                        </Label>

                    </View>

                    {renderAddEditModal()}
                    {loading && !data?.office_broker_on_view_service ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : data?.office_broker_on_view_service ? renderIntroDetails([data?.office_broker_on_view_service]) : (
                        <Label font_medium
                               small
                               align={'center'}>
                            {"Ingen data"}
                        </Label>
                    )}
                </View>
            </Animated.ScrollView>

        </View>
    )

};

export default ServiceDetails;
