import {StyleSheet} from 'react-native';
import {ThemeUtils,Color} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex:1,
        backgroundColor:Color.WHITE
    },
    icMaterial: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    materialCont: {
        flexDirection:'row',
        alignItems:'center',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:15,
    },
    lblCont:{
        flex:1,
        marginHorizontal:ThemeUtils.relativeRealWidth(2),
        justifyContent:'space-between'
    },

})
