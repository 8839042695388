export default {
  'component.orderinformation.title.tab1': 'Bestillingsinformasjon',
  'component.orderinformation.title.tab2': 'Avgift informasjon',
  'component.orderinformation.form.button1': 'Bekrefte',
  'component.orderinformation.form.button2': 'Avbryt',
  'component.orderinformation.oppdrag': 'Oppdragsnr. :',
  'component.orderinformation.sellername': 'Selger navn :',
  'component.orderinformation.email': 'E-post :',
  'component.orderinformation.phone': 'Telefon :',
  'component.orderinformation.date': 'Dato :',
  'component.orderinformation.time': 'Tid :',
  'component.orderinformation.propertyaddress': 'Eiendommens adresse :',
  'component.orderinformation.comment': 'Kommentar :',
  'component.orderinformation.uploaddoc': 'Lastet opp Tarrif-dokument :',
  'component.orderinformation.tax.description':
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  'component.orderinformation.tax.line1.heading1': 'Takstdato',
  'component.orderinformation.tax.line1.heading1.placeholder': 'Angi takstdato',
  'component.orderinformation.tax.line3.heading1': 'Byggeår',
  'component.orderinformation.tax.line3.heading1.placeholder': 'Angi byggeår',
  'component.orderinformation.tax.line1.heading2': 'Verditakst',
  'component.orderinformation.tax.line1.heading2.placeholder': 'Angi verditakst',
  'component.orderinformation.tax.line2.heading1': 'BTA',
  'component.orderinformation.tax.line2.heading1.placeholder': 'Angi BTA',
  'component.orderinformation.tax.line1.heading3': 'Lånetakst',
  'component.orderinformation.tax.line1.heading3.placeholder': 'Angi lånetakst',
  'component.orderinformation.tax.line2.heading2': 'BRA',
  'component.orderinformation.tax.line2.heading2.placeholder': 'Angi BRA',
  'component.orderinformation.tax.line2.heading3': 'P-rom',
  'component.orderinformation.tax.line2.heading3.placeholder': 'Angi P-rom',
  'component.orderinformation.tax.line4.heading1': 'Last opp takstdokument',
  'component.orderinformation.tax.line5.button': 'Last opp',
  'component..orderinformation.tax.line6.heading1': 'Kommentarer',
  'component.orderinformation.tax.line6.button1': 'Bestill',
  'component.orderinformation.tax.line6.button2': 'Avbryt',
};
