// local
// const DOMAIN = 'http://192.168.1.22:9000';
// dev
// const DOMAIN = 'https://devapi.weiendomsmegling.no';
// production
const DOMAIN = 'https://api.weiendomsmegling.no';

// dev
// const DS_CDN_URL = 'https://duqywbqe47ltg.cloudfront.net/';
// production
const DS_CDN_URL = 'https://cdn.weiendomsmegling.no/';

// local
// const FRONTDOMAIN = 'http://localhost:8001';
// dev
// const FRONTDOMAIN = 'https://dev.weiendomsmegling.no';
// production
const FRONTDOMAIN = 'https://weiendomsmegling.no';

const USER_TYPE = {
  ADMIN: 'Admin',
  SELLER: 'Seller',
  AGENT: 'Agent',
  PHOTO: 'Photographer',
  TAKST: 'Takst',
  STYLIST: 'Stylish',
  BOOK_INSPECTION: 'BookInspection',
  BUYER: 'Buyer',
  SUPERUSER: 'Superuser',
};

const USER_TYPE_SERVER = {
  ADMIN: 1,
  SELLER: 2,
  AGENT: 3,
  SUPERUSER: 3,
  PHOTO: 4,
  TAKST: 5,
  STYLIST: 6,
  BOOK_INSPECTION: 7,
  BUYER: 8,
};

const ORDER_STATUS = {
  ORDERED: 'Ordered',
  TIMEBOOKED: 'Time Booked',
  PHOTOTAKEN: 'Photo Taken',
  PHTOUPLOAD: 'Photo Uploaded',
  PROJECTCOMPLETE: 'Project Complete',
};

const TAKST_ORDER_STATUS = {
  ORDERED: 'Ordered',
  TIMEBOOKED: 'Time Booked',
  INFOADDED: 'Info Added',
  PROJECTCOMPLETE: 'Project Complete',
};

const TAKST_ORDER_STATUS_SERVER = {
  ORDERED: 1,
  TIMEBOOKED: 2,
  INFOADDED: 3,
  PROJECTCOMPLETE: 5,
};
const ORDER_STATUS_SERVER = {
  ORDERED: 1,
  TIMEBOOKED: 2,
  PHOTOTAKEN: 3,
  PHTOUPLOAD: 4,
  PROJECTCOMPLETE: 5,
};

const SERVICE_CATEGORY = {
  PhotoStyling: 'PS',
  DigitalMarketing: 'DM',
  PrintMarketing: 'PM',
};

const CHAT_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  LOCATION: 'LOCATION',
};

const CHAT_KEY = {
  CONTENT: 'content',
  SENDER_ID: 'senderId',
  RECEIVER_ID: 'receiverId',
  TIMESTAMP: 'timestamp',
  MESSAGE_TYPE: 'messageType',
  IS_READ: 'isRead',
  PROPERTY_ID: 'propertyId',
  CHAT_ID: 'chatId',
  LAST_MESSAGE: 'lastMessage',
  LAST_MESSAGE_TIMESTAMP: 'lastMessageTimestamp',
};

const InsuranceType = {
  UTEN: "UtenAnticimex",
  MED: "MedAnticimex",
}
module.exports = {
  GRAPHQL_URL: `${DOMAIN}/graphql`,
  // STORAGE_URL: `${DOMAIN}/storage/`,
  // dev
  STORAGE_URL: `${DS_CDN_URL}`,
  // production.
  // STORAGE_URL: 'https://cdn.weiendomsmegling.no/',
  FRONT_URL: `${FRONTDOMAIN}/`,
  USER_TYPE,
  USER_TYPE_SERVER,
  // LOCALSTORAGE_URL: 'https://betaapi.weiendomsmegling.no/storage/public/',
  // LOCALSTORAGE_URL: 'https://927c5f1e5ced.ngrok.io/storage',
  ORDER_STATUS,
  ORDER_STATUS_SERVER,
  TAKST_ORDER_STATUS,
  TAKST_ORDER_STATUS_SERVER,
  InsuranceType,
  SERVICE_CATEGORY,
  DS_CDN_URL,
  CHAT_KEY,
  CHAT_TYPE,
};
