import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,

    },
    lblCont: {
        marginVertical: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    imgCont: {
        marginVertical: 10,
        height: ThemeUtils.relativeRealHeight(30)
    },
    serviceCont: {
        flex: 1,
        borderWidth: 1,
        borderRadius: 1,
        borderColor: Color.SECONDARY,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10
    },
    imgCountCont: {
        zIndex: 100,
        position: 'absolute',
        end: 10,
        bottom: 10,
        paddingHorizontal: 5,
        paddingVertical: 3,
        borderRadius: 3,

        backgroundColor: Color.TRANSPARENT_BLACK
    },
    icDetailsCont: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icCont: {
        marginStart: 10
    },
    modalLblCont: {
        paddingHorizontal: ThemeUtils.relativeRealWidth(3),
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        borderColor: Color.SECONDARY,
        borderWidth: 1,
        borderRadius: 4,
        marginEnd: ThemeUtils.relativeRealWidth(2),
        marginBottom: ThemeUtils.relativeRealHeight(1)
    },
    modalCont: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: ThemeUtils.relativeWidth(6),
        backgroundColor: Color.PRIMARY_TRANSPARENT
    },
    dialogCont: {
        backgroundColor: Color.WHITE,
        padding: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 8,
        maxHeight: ThemeUtils.relativeRealHeight(90)
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 20
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 20
    },
    pkgCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10
    },
})
