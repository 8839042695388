import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.WHITE,
    },
    contentCont: {

        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    titleCont: {

        flexDirection: 'row',
        alignItems: 'center'
    },
    lblInst: {
        lineHeight: 18,
    },
    btnAddView: {
        paddingVertical: 15,
        backgroundColor: Color.PRIMARY
    },
    orderCont: {
        marginHorizontal: ThemeUtils.relativeRealWidth(4),
        marginTop: ThemeUtils.relativeRealHeight(2),
    },
    billCont: {
        backgroundColor: Color.PRIMARY,
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        marginVertical:ThemeUtils.relativeRealHeight(2)
    },
    lineStyle: {
        backgroundColor: Color.LIGHT_GRAY,
        marginHorizontal: ThemeUtils.relativeRealWidth(3),
        marginVertical: 10
    },
    btnOkay: {
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    billListCont: {
        marginVertical:5,
        backgroundColor:Color.PRIMARY
    },
    buttonContainer: {
        marginVertical: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        flexDirection: "row",
        marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN

    },
})
