import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, View} from 'react-native';
import {Dashboard, Header, Label} from "components/src/components";
//Third party
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import energy_labeling from "../../../../assets/images/Photo.png";
import Marketing from "../../../../assets/images/Marketing.png";
import photo from "../../../../assets/images/Seller.jpg";
import digitalMarketing from "../../../../assets/images/Video_Thumbnail.png";
import marketingMaterial from "../../../../assets/images/InfofromSeller.png";
import {useQuery} from "@apollo/react-hooks";
import {FETCH_OFFICE_DETAIL} from "../../../../api/query";
import {connect} from "react-redux";


const DASHBOARD = [{
    // name: Strings.noenordframegler,
    name: "Avsnitt 01",
    icon: 'profile',
    bg: photo,
    route: Routes.Befaringsbekreftelse,
    id: 'befaringsbekreftelse'
}, {
    // name: Strings.aboutW,
    name: "Avsnitt 02",
    icon: 'digital-sales-assignment',
    bg: digitalMarketing,
    route: Routes.AboutW,
    id: 'about_w'
}, {
    // name: Strings.preparation,
    name: "Avsnitt 04",
    icon: 'preparations',
    bg: marketingMaterial,
    route: Routes.Preparation,
    id: 'preparation'
}, {
    // name: Strings.questionToAsk,
    name: "Avsnitt 05",
    icon: 'faq',
    bg: Marketing,
    route: Routes.QuestionToAsk,
    id: 'question_to_ask'
}, {
    // name: Strings.productGallery,
    name: `${Strings.productGallery} - 06`,
    icon: 'product-gallery',
    bg: energy_labeling,
    route: Routes.ProductGallery,
    id: 'product_gallery',
    subId: 'after_product_gallery'
}];

function WBefaring(props) {
    const {navigation, user, history} = props;

    const [officeDetails, setData] = useState({})

    const {loading, data, error} = useQuery(FETCH_OFFICE_DETAIL, {
        fetchPolicy: 'network-only',
        variables: {
            office_id: user?.office_id
        }
    });

    useEffect(() => {
        if (data?.office) {
            setData(data?.office)
        }
    }, [data])

    const handleDashboardItemPress = (route, id, subId) => {
        console.log(data, id)
        if (IS_WEB) {
            props.history.push(`${Routes.WBefaringDashboard}/${route}`, {
                details: data?.office[id],
                subDetails: data?.office[subId]
            })
        } else {
            props.navigation.navigate(route, {
                details: data?.office[id],
                subDetails: data?.office[subId]
            })
        }
    };

    return (
        <View style={styles.container}>
            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>

            <View style={styles.cont}>

                <Icon name={'search'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY_TEXT_COLOR}/>

                <Label xlarge
                       font_medium
                       ml={10}>
                    {Strings.wBefaring}
                </Label>
            </View>
            {loading && !data ? (
                <ActivityIndicator color={Color.PRIMARY}
                                   style={CommonStyle.loader}/>
            ) : (
                <Dashboard dashboardData={DASHBOARD}
                           onPressItem={(route, id, item) => handleDashboardItemPress(route, id, item.subId)}/>
            )}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(WBefaring);
