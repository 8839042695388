import React, {useEffect, useState} from 'react'
//Components
import {Keyboard,ActivityIndicator, Animated, Image, SafeAreaView, View} from 'react-native'
import {
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    InputField,
    Label,
    Modal,
    RadioGroup,
    Ripple
} from "components/src/components";
//Third party
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import styles from './styles.js';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {VENDORS_SUB_SERVICES} from "../../../../../api/query";
import {ADD_VENDOR_SERVICE, DELETE_VENDOR_SERVICE, UPDATE_VENDOR_SERVICE} from "../../../../../api/mutation";

const PRICE_OPTIONS = [{
    name: Strings.price,
    value: 'Price'
}, {
    name: Strings.other,
    value: 'Other'
}];

const scrollY = new Animated.Value(0);


const ServiceDetails = props => {

    const {history, navigation} = props;
    const vendorId = IS_WEB ? decryptId(props.match?.params?.vendorId) ?? null : navigation.getParam('vendorId', null);
    const name = IS_WEB ? decryptId(props.match?.params?.name) ?? "" : navigation.getParam("name");
    const serviceTypeId = IS_WEB ? decryptId(props?.match?.params?.serviceTypeId) ?? null : navigation.getParam('serviceTypeId', null);

    const [service, setService] = useState({});

    const [serviceName, setServiceName] = useState("");
    const [errName, setErrName] = useState(null);

    const [description, setDescription] = useState("");
    const [errDescription, setErrDescription] = useState(null);

    const [servicePrice, setServicePrice] = useState("");
    const [errServicePrice, setErrServicePrice] = useState(null);

    const [addServiceVisible, setAddServiceVisible] = useState(false);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const [serviceForEdit, setServiceForEdit] = useState({});


    const [serviceType, setServiceType] = useState(PRICE_OPTIONS[0].value);

    const [files, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        if (files[0]) {
            setFileUrl(IS_WEB ? URL.createObjectURL(files[0]) : files[0]?.uri)
        }
    }, [files])
    const {loading, data, refetch, error} = useQuery(VENDORS_SUB_SERVICES, {
        variables: {
            vendorId: parseInt(vendorId),
            typeId: parseInt(serviceTypeId)
        }
    });

    const [addServiceDetails, addDetailsMutation] = useMutation(ADD_VENDOR_SERVICE, {
        onCompleted: () => {
            refetch()
        }
    });

    const [saveServiceDetails, saveDetailsMutation] = useMutation(UPDATE_VENDOR_SERVICE, {
        onCompleted: () => {
            refetch()
        }
    });

    const [deleteService, deleteServiceMutation] = useMutation(DELETE_VENDOR_SERVICE, {
        onCompleted: () => {
            refetch()
        }
    });


    useEffect(() => {
        setErrDescription(null);
        setErrName(null);
        setErrServicePrice(null)
        if (!addServiceVisible) {
            setServiceName("");
            setDescription("");
            setServiceType("Price");
            setServicePrice("");
            setServiceForEdit(null)
        }
    }, [addServiceVisible]);

    useEffect(() => {
        if (serviceForEdit) {
            setServiceName(serviceForEdit?.name);
            setDescription(serviceForEdit?.description);
            console.log(serviceForEdit)
            if (serviceForEdit?.is_free) {
                setServiceType('Other')
                setServicePrice(serviceForEdit?.free_text)
            } else {
                setServicePrice(parseFloat(serviceForEdit?.price).toString())
            }
        } else {
            setServiceName("");
            setDescription("");
            setServiceType("Price");
            setServicePrice("");

        }
    }, [serviceForEdit]);


    const validate = () => {
        if(!IS_WEB){
            Keyboard.dismiss();
        }
        let errHead = validation('name', serviceName);
        let errIntro = validation('description', description.trim());
        let errPrice = validation('servicePrice', servicePrice);
        if (!files.length && !serviceForEdit?.image) {
            showMessage(MessageUtils.Errors.image, Constants.MessageType.FAILED)
        } else if (!errHead && !errPrice && !errIntro) {
            let variables = {
                name: serviceName,
                price_or_other: serviceType,
                description: description
            }
            if (files.length) {
                variables.image = files[0]
            }
            if (serviceType === 'Other') {
                variables.other_text = servicePrice
            } else {
                variables.price = parseInt(servicePrice)
            }
            if (serviceForEdit) {
                delete variables.office_template_id
                saveServiceDetails({
                    variables: {
                        id: serviceForEdit?.id,
                        ...variables
                    }
                }).then((data) => {
                    setAddServiceVisible(false);
                    showMessage(data?.data?.update_vendor_service?.meta?.message, Constants.MessageType.SUCCESS)
                }).catch(() => {

                })
            } else {
                addServiceDetails({
                    variables: {
                        vendor_id: vendorId,
                        service_type_id: serviceTypeId,
                        ...variables
                    }
                }).then((data) => {
                    setAddServiceVisible(false);
                    showMessage(data?.data?.add_vendor_service?.meta?.message, Constants.MessageType.SUCCESS)
                }).catch(() => {

                })
            }
        } else {
            setErrName(errHead);
            setErrDescription(errIntro);
            setErrServicePrice(errPrice)
        }
    };

    const onDelete = () => {
        deleteService({
            variables: {
                id: serviceForEdit?.id
            }
        }).then((data) => {
            setDeleteDialogVisible(false)
            setAddServiceVisible(false)
            showMessage(data?.data?.delete_vendor_service?.meta?.message, Constants.MessageType.SUCCESS)
        }).catch(() => {

        })
    };

    const renderAddEditModal = () => {
        return (
            <Modal visible={addServiceVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setAddServiceVisible(false)}>

                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Dialog title={Strings.deleteService}
                                visible={deleteDialogVisible}
                                loading={deleteServiceMutation.loading}
                                desc={Strings.deleteConfirmation}
                                onNegativePress={() => setDeleteDialogVisible(false)}
                                onPositivePress={() => onDelete()}/>
                        <Header animatedTitle={serviceForEdit ? Strings.editService : Strings.addService}
                                headerRightFirst={serviceForEdit ? 'delete' : null}
                                onPressRightFirst={() => setDeleteDialogVisible(true)}
                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                onPressLeft={() => setAddServiceVisible(false)}
                                animatedValue={scrollY}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             keyboardShouldPersistTaps={'always'}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.topContainer}>

                                <Label small
                                       font_medium
                                       mt={ThemeUtils.relativeRealHeight(7)}
                                       mb={10}>
                                    {Strings.serviceImage}
                                </Label>
                                <FilePicker type={FileType.IMAGE}
                                            onFilePicked={(data, file) => {
                                                setFile(file)
                                            }}
                                            style={{alignSelf: 'flex-start'}}>
                                    {files.length ? (
                                        <Image source={IS_WEB ? fileUrl : {uri: fileUrl}}
                                               style={styles.img}/>
                                    ) : serviceForEdit?.image && !files.length ? (
                                        <Image
                                            source={{uri: `${Constants.APIConfig.STORAGE_URL}${serviceForEdit?.image}`}}
                                            style={styles.img}/>
                                    ) : (
                                        <View style={styles.SubViewIcon}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.INPUT_BORDER}/>
                                        </View>)}
                                </FilePicker>
                                <InputField mt={ThemeUtils.relativeRealHeight(2)}
                                            onFocus={() => setErrName(null)}
                                            error={errName}
                                            type={'text'}
                                            value={serviceName}
                                            onChange={setServiceName}
                                            labelText={Strings.serviceName}/>
                                <Label small
                                       font_medium
                                       mb={5}>
                                    {Strings.servicePrice}
                                </Label>
                                <RadioGroup options={PRICE_OPTIONS}
                                            selectedOptions={serviceType}
                                            onSelectOption={setServiceType}
                                            contentStyle={styles.radio}
                                />
                                <InputField type={serviceType === 'Other' ? 'text' : 'number'}
                                            onFocus={() => setErrServicePrice(null)}
                                            mt={10}
                                            error={errServicePrice}
                                            value={servicePrice}
                                            onChange={setServicePrice}>
                                    {serviceType === 'Other' ? null : (<View style={CommonStyle.inputExtra}>
                                        <Label font_medium>
                                            {'Kr.'}
                                        </Label>
                                    </View>)}
                                </InputField>
                                <InputField textArea
                                            type={'text'}
                                            onFocus={() => setErrDescription(null)}
                                            error={errDescription}
                                            value={description}
                                            onChange={setDescription}
                                            labelText={Strings.discription}/>
                            </View>
                        </Animated.ScrollView>
                        <View style={styles.bottomButtonContainer}>
                            <CustomButton
                                loading={serviceForEdit ? saveDetailsMutation.loading : addDetailsMutation.loading}
                                style={{flex: 0.45}}
                                title={serviceForEdit ? Strings.save : Strings.add}
                                onPress={validate}/>
                            <CustomButton bgColor={Color.WHITE}
                                          borderWidth={1}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          borderColor={Color.PRIMARY}
                                          style={{flex: 0.45}}
                                          title={Strings.cancel}
                                          onPress={() => {
                                              if(!IS_WEB){
                                                  Keyboard.dismiss();
                                              }
                                              setAddServiceVisible(false)
                                          }}/>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderIntroDetails = services => {
        return (
            <View>

                {services?.map((item, index) => {
                    return (
                        <Ripple key={index}
                                onPress={() => {
                                    setServiceForEdit(item);
                                    setAddServiceVisible(true)
                                }}>
                            <Hr/>

                            <View style={styles.optionContainer}>
                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.image}`}}
                                       style={styles.imgThumb}/>
                                <View style={CommonStyle.flex}>
                                    <Label font_medium
                                           ml={20}
                                           mb={10}>
                                        {item.name}
                                    </Label>
                                    <Label small
                                           ml={20}
                                           numberOfLines={2}>
                                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                                    </Label>
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'drop-normal'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                            {index === services.length - 1 ? <Hr/> : null}
                        </Ripple>
                    )
                })}
            </View>
        );
    };

    return (
        <View style={CommonStyle.flex}>

            <Header
                animatedTitle={name}
                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                navigation={IS_WEB ? props.history : props.navigation}
                animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 keyboardShouldPersistTaps={'always'}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>

                        <Label
                            mt={ThemeUtils.relativeRealHeight(6)}
                            small
                            align={'left'}>
                            {/*{serviceFromPage?.office_template?.description}*/}
                        </Label>

                    </View>
                    {renderAddEditModal()}
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : data?.vendor_services?.length ? renderIntroDetails(data?.vendor_services ?? []) : (
                        <Label align={'center'}
                               font_medium>
                            {"Ingen data"}
                        </Label>
                    )}
                </View>
            </Animated.ScrollView>
            <CustomButton borderWidth={0}
                          onPress={() => {
                              setServiceForEdit(null);
                              setAddServiceVisible(true)
                          }}
                          title={
                              <>
                                  <Icon name={'add'}/>
                                  {` ${Strings.addService}`}
                              </>}
                          mb={ThemeUtils.isTablet() ? 40 : 0}
                          style={!ThemeUtils.isTablet() ? {
                              height: 50,
                              borderRadius: 0
                          } : null}/>
        </View>
    )

};

export default ServiceDetails;
