import React from 'react';
//Components
import {Image, ScrollView, TouchableOpacity, View} from 'react-native'
import {Accordion, Header, Hr, Label, LogoForHeader, Ripple} from "components/src/components";
import Drawer from "@ant-design/react-native/lib/drawer";
import TabBar from "@ant-design/react-native/lib/tab-bar";
//Redux
import {store} from "components/src/redux/store";
import {logout} from "components/src/redux/action";
import styles from './styles';
import {Color, CommonStyle, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import Home from "../../screens/Authenticated/Agent/Home";
import {Mutation} from "@apollo/react-components";
import Cookie from 'js-cookie'
import {LOG_OUT} from "../../api/mutation";
import {withFirebase} from "../Firebase";
import Logo from 'components/src/assets/images/W_Logo_Only.png'

// import {LOG_OUT} from "components/src/api/mutation";
//Utils
//Screen

const users = [{
    id: 1,
    route: Routes.Photographer,
    name: Strings.photographer
}, {
    id: 2,
    route: Routes.Takst,
    name: Strings.takst
}, {
    id: 3,
    route: Routes.Stylist,
    name: Strings.stylist
}]

const withDrawer = (getParams, WrapperComponents) => {
    class DrawerNavigation extends React.Component {

        constructor(props) {
            super(props);
            this.user = store.getState().user;
            let selectedTab = null;
            let tabIndex = 0;
            this.extraParams = {...getParams(props)}
            const {tabs, parentRoute} = this.extraParams

            if (IS_WEB) {
                selectedTab = tabs.find(item => `/${parentRoute}/${item.route.toLowerCase()}` === props.location.pathname.toLowerCase())
            }

            this.state = {
                selectedTab: selectedTab ? selectedTab : tabs[0],

            }
        }

        componentDidMount() {
            this.props.database().ref('v1').once('value', (e) => {
                console.log(e.val(), "VALUES")
            })
            this.database = this.props.database().ref('v1/generalNotifications').child(`${this.user.id}`)
            this.chatDbRef = this.props.database().ref('v1/notificationList').child(`${this.user.id}`)

            this.database.on('value', (e) => {
                let notifcation = e.val();
                if (notifcation) {
                    this.setState({
                        isNewNotification: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                    })
                }
            })
            this.chatDbRef.on('value', (e) => {
                let notifcation = e.val();
                if (notifcation) {
                    this.setState({
                        isNewMessage: Object.values(notifcation).filter(item => !item.isRead)?.length !== 0
                    })
                }
            })
        }

        componentWillUnmount() {
            this.database && this.database.off()
            this.chatDbRef && this.chatDbRef.off()
        }

        openNotification = () => {
            IS_WEB ? this.props.history.push(`${Routes.Notifications}`) : this.props.navigation.navigate(Routes.Notifications);
        }

        onChangeTab = (tab) => {

            if (IS_WEB) {
                this.props.history.replace(tab.route)
            }
            this.setState({selectedTab: tab})
        };

        handleOnPressDrawerItem = (route, name, logOut) => {
            const {navigation, history} = this.props;
            console.log(route)
            if (name !== Strings.logout) {
                if (IS_WEB) {
                    history.push(route)
                } else if (route) {
                    navigation.navigate(route)
                }
            } else {
                store.dispatch(logout());
                if (IS_WEB) {
                    history.replace(Routes.SignIn)
                    Cookie.remove(`Agent_token`);
                    Cookie.remove(`Agent_user`);
                } else {
                    const id = 'react-navigation'
                    let {NavigationActions, StackActions} = require(`${id}`);
                    const resetToNonAuth = StackActions.reset({
                        index: 0,
                        actions: [
                            NavigationActions.navigate({routeName: Routes.UnAuthenticated}),
                        ],
                    });
                    this.props.navigation.dispatch(resetToNonAuth)
                }
                logOut().then(() => {

                }).catch(() => {
                });

            }
            this.drawer && this.drawer.drawer && this.drawer.drawer.closeDrawer();
        };

        //render Drawer item
        renderDrawerItems = () => {
            const {drawerItems} = this.extraParams
            return (
                <Mutation mutation={LOG_OUT}>
                    {(logOut, {loading, data}) => {

                        return drawerItems.map((item, index) => {
                            if (item.isAccordion) {
                                return (
                                    <Accordion iconColor={Color.PRIMARY_TEXT_COLOR}
                                               renderHeader={this.renderAccordionHeader}
                                               renderContent={this.renderAccordionContent}
                                               contentContainerStyle={styles.accordionContainer}
                                               headerContainerStyle={styles.accordionHeader}/>
                                )
                            }
                            return (
                                <>
                                    {item.section ? <Hr/> : null}
                                    <Ripple
                                        style={[styles.drawerItemCont, index === 0 ? {backgroundColor: Color.PRIMARY} : null, index === (drawerItems.length - 1) ? styles.drawerPadding : null]}
                                        onPress={() => this.handleOnPressDrawerItem(item.route, item.name, logOut)}
                                        key={item.name}>
                                        <Icon name={item.icon}
                                              size={ThemeUtils.fontLarge}
                                              color={index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY_TEXT_COLOR}/>
                                        <Label font_medium
                                               ml={10}
                                               color={index === 0 ? Color.COLOR_ACCENT : Color.PRIMARY_TEXT_COLOR}>
                                            {item.name}
                                        </Label>
                                    </Ripple>
                                    {item.section ? <Hr/> : null}
                                </>
                            )
                        })
                    }}
                </Mutation>
            )

        };

        renderAccordionContent = () => {
            return users.map((item, index) => {
                return (
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => IS_WEB ? this.props.history.push(item.route) : this.props.navigation.navigate(item.route)}
                                      style={[styles.accordionItem]}
                                      key={item.name}>
                        <Label font_medium
                               color={Color.PRIMARY_TEXT_COLOR}
                               ml={ThemeUtils.relativeWidth(15)}>
                            {item.name}
                        </Label>
                    </TouchableOpacity>
                )
            })
        };

        renderAccordionHeader = () => {
            return (
                <View style={[CommonStyle.flex, styles.drawerItemCont]}>
                    <Icon name={'vendors'}
                          size={ThemeUtils.fontXLarge}
                          color={Color.PRIMARY_TEXT_COLOR}/>
                    <Label font_medium
                           style={CommonStyle.flex}
                           color={Color.PRIMARY_TEXT_COLOR}
                           ml={10}>
                        {"Leverandører"}
                    </Label>
                </View>

            )
        };

        //render Drawer it self
        renderSideBar = () => {
            return (
                <ScrollView>
                    <View style={styles.cont}>
                        <View style={styles.drawerHeader}>
                            <Image source={Logo}
                                   style={styles.logo}/>
                            {/*<Icon name={'about-w'}
                                  size={ThemeUtils.responsiveScale(80)}
                                  color={Color.SECONDARY}/>*/}
                            {/*<Label xlarge
                                   font_medium
                                   ml={5}>
                                {Strings.web}
                                <Text style={styles.txtRegular}>
                                    {Strings.megling}
                                </Text>
                            </Label>*/}
                        </View>
                        {this.renderDrawerItems()}
                    </View>
                </ScrollView>
            )
        };

        //render bottom tabs icon
        renderTabs = (item) => {
            return (
                <TabBar.Item key={item.name}
                             title={item.name}
                             icon={<Icon name={item.icon}
                                         size={16}
                                         color={Color.SECONDARY_TEXT_COLOR}/>}
                             selectedIcon={<Icon name={item.icon}
                                                 size={18}
                                                 color={Color.TERNARY_TEXT_COLOR}/>}
                             selected={this.state.selectedTab.id === item.id}
                             onPress={() => this.onChangeTab(item)}>
                    {this.renderDrawerScreens()}
                </TabBar.Item>
            )
        };

        //render header icon
        renderHeaderTitle = () => {
            return (
                <LogoForHeader size={ThemeUtils.responsiveScale(60)}/>
            )
        };

        //render drawer screens
        renderDrawerScreens = () => {
            let Screen = this.state.selectedTab.screen
            if (this.state.selectedTab.screen) {
                return <Screen {...this.props}/>
            } else {
                return (
                    <View style={[CommonStyle.flex, CommonStyle.content_center]}>
                        <Label>
                            {`Under Development ${this.state.selectedTab.name}`}
                        </Label>
                    </View>
                )
            }

        };


        render() {
            const {tabs, onPressNotification} = this.extraParams
            return (

                <Drawer ref={ref => this.drawer = ref}
                        drawerWidth={ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(60) : undefined}
                        drawerBackgroundColor={Color.WHITE}
                        sidebar={this.renderSideBar()}>

                    <Header tintColor={Color.WHITE}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            onPressLeft={() => this.drawer.drawer.openDrawer()}
                            renderTitle={this.renderHeaderTitle}
                            headerLeft={'menu'}
                            badge={this.state.isNewNotification || this.state.isNewMessage}
                            titleStyle={{
                                left: 0,
                                right: 0,
                            }}
                            onPressRightSecond={() => {
                                IS_WEB ? this.props.history.push(Routes.Profile) : this.props.navigation.navigate(Routes.Profile)
                            }}
                        // headerRightFirst={'notifications'}
                            onPressRightFirst={this.openNotification}
                            headerRightSecond={'profile'}
                    />
                    <ScrollView>
                        <Home {...this.props}/>
                    </ScrollView>
                    {/*<TabBar unselectedTintColor={Color.SECONDARY}
                            tintColor={Color.SECONDARY}
                            barTintColor={Color.PRIMARY}>
                        {tabs.map(this.renderTabs)}
                    </TabBar>*/}
                </Drawer>

            )
        }
    }

    return withFirebase(DrawerNavigation);
}
/*const mapStateToProps = (state) => {
    return {
        property: state.property,
        user: state.user || {},
    };
};

export default connect(mapStateToProps)(withDrawer));*/
export default withDrawer;

