import React from 'react'
//Components
import {ActivityIndicator, Animated, Image, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
//Network
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import styles from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {SERVICES} from "../../../../../../api/query";

const options = [{
    title: 'Photo',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: Routes.OfficePhoto
}, {
    title: 'Styling',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: ''
}, {
    title: '3D/CGI',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: ''
}, {
    title: '360 Degree View',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: ''
}, {
    title: 'Drone   ',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: Routes.OfficeDrone
}, {
    title: 'Video',
    thumbUrl: 'https://www.w3schools.com/w3css/img_lights.jpg',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,...',
    routeName: ''
}
];

const scrollY = new Animated.Value(0);

const OfficePhotoStyling = props => {


    const {loading, data, error} = useQuery(SERVICES, {
        variables: {
            status: true,
            category: Constants.ServiceCategory.PhotoStyling
        }
    });

    const navigateToRoute = (service) => {
        let route = Routes.PhotoTemplate
        if (service?.office_template?.service_type_id === 5 ||
            service?.office_template?.service_type_id === 6) {
            route = Routes.AddVideo
        } else if (service?.office_template?.service_type_id === 2) {
            route = Routes.StylingTemplate
        } else if (service?.office_template?.service_type_id === 4) {
            route = Routes.DegreeVideo
        }

        if (IS_WEB) {
            props.history.push(`${route}/${encryptId(service?.office_template?.id)}`, {
                service: service
            })
        } else {
            props.navigation.navigate(route, {
                service: service
            })
        }
    };

    const renderOptions = options => {
        return (
            <View>
                {options?.map((item, index) => {
                    return (
                        <Ripple key={index.toString()}
                                onPress={() => {
                                    navigateToRoute(item, index);
                                }}>
                            <Hr/>

                            <View style={styles.optionContainer}>

                                <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.image}`}}
                                       style={styles.imgThumb}/>
                                <View style={CommonStyle.flex}>
                                    <Label small
                                           ml={20}
                                           font_medium>
                                        {item.name}
                                    </Label>
                                    {/* <Label small
                                           ml={20}

                                           numberOfLines={2}>
                                        {item?.office_template?.description}
                                    </Label>*/}
                                </View>
                                <View style={styles.iconView}>
                                    <Icon
                                        size={ThemeUtils.fontLarge}
                                        name={'drop-normal'}
                                        color={Color.ICON_BUTTON_ARROW}/>
                                </View>
                            </View>
                            {index === options.length - 1 ? <Hr/> : null}
                        </Ripple>
                    )
                })}
            </View>
        );
    }


    return (
        <Provider locale={enUS}>

            <Header animatedTitle={`${Strings.photo} / ${Strings.styling}`}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={{paddingBottom: ThemeUtils.isIphoneXOrAbove() ? 0 : ThemeUtils.APPBAR_HEIGHT / 2}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <View style={styles.topContainer}>

                        <Icon
                            style={styles.titleContainer}
                            size={ThemeUtils.fontXXLarge}
                            name={'photo'}
                            color={Color.PRIMARY_DARK}/>


                        {/*<Label
                            mt={10}
                            small
                            font_regular
                            align={'left'}>

                        </Label>*/}
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : renderOptions(data?.services)}

                </View>
            </Animated.ScrollView>
        </Provider>
    )

}

export default OfficePhotoStyling;
