import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginTop:ThemeUtils.relativeRealHeight(2)
    },
    displayCont: {
        flexDirection: 'row',
        flex: 1
    },
    lblCont: {
        flex: 0.4,
        marginEnd:ThemeUtils.relativeRealWidth(3)
    },
    serviceCont: {
        borderWidth: 1,
        borderColor: Color.PRIMARY_LIGHT,
        borderRadius: ThemeUtils.relativeRealWidth(0.8),
        padding:ThemeUtils.relativeRealWidth(2),
        marginVertical: ThemeUtils.relativeRealWidth(1)
    },
    imgCont: {
        flexDirection:'row',
        justifyContent:'center',
        marginHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom:ThemeUtils.relativeRealHeight(2)
    },
    scrollCont: {
        paddingHorizontal:ThemeUtils.relativeRealWidth(3),
        marginBottom: ThemeUtils.relativeRealHeight(2)
    },
    imgBottom: {
        backgroundColor:Color.GRAY,
        height: ThemeUtils.responsiveScale(100),
        width: ThemeUtils.responsiveScale(178),
        marginEnd:ThemeUtils.relativeRealWidth(3),
    },
    icCont: {
        zIndex:100,
        alignItems:'center',
        justifyContent:'center',
        position:'absolute',
        end:0,
        bottom:0,
        start:0,
        top:0,
        backgroundColor: Color.TRANSPARENT_BLACK
    },

})
