import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    modalContainer:{
        flex:1,
        paddingVertical: containerHorizontalMargin,
        paddingHorizontal: containerHorizontalMargin,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    optionContainer: {
        marginVertical: containerHorizontalMargin,
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    imgThumb: {
        height: ThemeUtils.responsiveScale(75),
        width: ThemeUtils.responsiveScale(75),
        backgroundColor: Color.GRAY,
        borderRadius: 10,
    },
    iconView: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dividerLine: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
    cont: {
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: 10

    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    img:{
        borderRadius:4,
        marginTop:ThemeUtils.relativeRealHeight(2),
        width:"100%",
        height:ThemeUtils.relativeRealHeight(20),
        backgroundColor:Color.GRAY
    }
});

export default styles;
