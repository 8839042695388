export default {
  'component.sidemenu.dashboard': 'Dashboard',
  'component.sidemenu.chat': 'Chat',
  'component.sidemenu.timeline': 'Din tidslinje',
  'component.sidemenu.yourproperty': 'Din eiendommer',
  'component.sidemenu.addproperty': 'Legg til nye egenskaper',
  'component.sidemenu.salesprocess': 'Din salgsprosess',
  'component.sidemenu.submenu.salespreparation': 'Salgsforberedelser',
  'component.sidemenu.submenu.marketingsales': 'Markedsføring & Salg',
  'component.sidemenu.submenu.contract': 'Kontrakt & oppgjør',
  'component.sidemenu.myorders': 'Mine bestillinger',
  'component.sidemenu.faq': 'Ofte stilte spørsmål',
  'component.sidemenu.instructional': 'Instruksjonsvideoer',
  'component.sidemenu.documents': 'Dokumenter',
  'component.sidemenu.disclaimer': 'Brukervilkår',
  'component.sidemenu.rewards': 'W Rewards',
  'component.sidemenu.calendar': 'Kalender',
  'component.sidemenu.moovin': 'Eiendommer',
  'component.sidemenu.signature': 'Signer oppdrag',
  'component.sidemenu.inspectionapplications': 'Befaringsapplikasjon',
  'component.sidemenu.news': 'Nyheter',
  'component.sidemenu.professional': 'Faglig',
  'component.sidemenu.agents': 'Meglere',
  'component.sidemenu.submenu.allagents': 'Alle Meglere',
  'component.sidemenu.submenu.team': 'Team',
  'component.sidemenu.superusers': 'Superbrukere',
  'component.sidemenu.submenu.officeceo': 'Daglig Leder',
  'component.sidemenu.submenu.officecontroller': 'Fagansvarlig',
  'component.sidemenu.vendors': 'Leverandører',
  'component.sidemenu.submenu.photographers': 'Fotografer',
  'component.sidemenu.submenu.takst': 'Takst',
  'component.sidemenu.submenu.stylist': 'Stylist',
  'component.sidemenu.submenu.inspectionagent': 'Inspeksjonsagent',
  'component.sidemenu.loanapplication': 'Lånesøknader',
  'component.sidemenu.jobs': 'Stilling ledig',
  'component.sidemenu.contentmanagement': 'Filbehandling',
  'component.sidemenu.movingguide': 'Flytteguiden',
  'component.sidemenu.submenu.managevendors': 'Administrer Leverandører',
  'component.sidemenu.submenu.managelinks': 'Administrer linker',
  'component.sidemenu.adspopups': 'Annonser / Popup-vinduer',
  'component.sidemenu.insurance': 'Forsikring',
  'component.sidemenu.officetemplate': 'Selskapsmal',
  'component.sidemenu.befaring': 'Befaringsbekreftelse',
  'component.sidemenu.b2c': 'Tjenester / Utlegg',
  'component.sidemenu.b2b': 'W Flex tjenester',
  'component.sidemenu.offices': 'Kontorer',
  'component.sidemenu.digitalsalesassignment': 'Digital salgsoppave',
  'component.sidemenu.websiteedits': 'Rediger nettsiden',
  'component.sidemenu.homesettings': 'Hjemmeside',
  'component.sidemenu.metalisting': 'Meta informasjon',
  'component.sidemenu.vitecmangement': 'Vitec Administrasjon',
};
