import React, {useEffect} from 'react';
//components
import {ActivityIndicator, ScrollView, View} from 'react-native';
import {CustomButton, Header, IndicatorViewPager, Label, PagerTabIndicator} from "components/src/components";
//Utils
import styles from './styles';
import {Color, CommonStyle, DateUtils, decryptId, Icon, IS_WEB, Strings, ThemeUtils} from 'components/src/utils';
import moment from "moment";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BUYER_TIMELINE, SELLER_TIMELINE} from "../../../../../../api/query";
import {UPDATE_BUYER_TIMELINE, UPDATE_PROPERTY_TIME_LINE} from "../../../../../../api/mutation";


const TABS = [{
    text: Strings.sellerTimeline
}, {
    text: Strings.buyerTimeline
}];

export default function TimeLine(props) {
    const {history, navigation} = props;

    let propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const [updateTimeline] = useMutation(UPDATE_PROPERTY_TIME_LINE)
    const [updateBuyerTimeline] = useMutation(UPDATE_BUYER_TIMELINE)

    const sellerTimeline = useQuery(SELLER_TIMELINE, {
        variables: {
            propertyId: propertyId,
            status: 1
        }
    })

    const buyerTimeline = useQuery(BUYER_TIMELINE, {
        variables: {
            propertyId: propertyId,
            status: 1
        }
    })


    useEffect(() => {
        updateBuyerTimeline({
            variables: {
                propertyId: propertyId,
                status: 1
            },
        }).then(() => {
            buyerTimeline.refetch && buyerTimeline.refetch()
        }).catch((e) => {
        })

        updateTimeline({
            variables: {
                propertyId: propertyId,
                status: 1
            },
        }).then(() => {
            sellerTimeline.refetch && sellerTimeline.refetch()
        }).catch((e) => {
        })
    }, [])

    const getPlaceholderText = (item) => {
        switch (item.id) {
            case 1:
            case 2:
            case 3:
            case 6:
            case 7:
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {`Booked : ${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 5:
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {`Scheduled : ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 8: //Contract
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {item.completed_date ? `Scheduled : ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}` : 'Etter avtale'}
                        </Label>
                    </View>
                )
            case 9: //Takover
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {item.completed_date ? `${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}` : "Etter avtale"}
                        </Label>
                    </View>
                )
            case 4:

                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {"Fra oppdragsignering til markedsføring"}
                        </Label>
                    </View>
                )

        }
    };

    const getBuyerPlaceholderText = (item) => {
        switch (item.id) {
            case "1":
            case "2":
            case "3":
            case "6":
            case "7":
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {`${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 5:
                return item.completed_date ? (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {`${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}`}
                        </Label>
                    </View>
                ) : null
            case 8: //Contract
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {item.completed_date ? `${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(2, "d").format(DateUtils.dd_mm_yyyy)} - ${moment(item.completed_date, DateUtils.yyyy_mm_dd).add(7, "d").format(DateUtils.dd_mm_yyyy)}` : 'Etter avtale'}
                        </Label>
                    </View>
                )
            case 9: //Takover
                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {item.completed_date ? `${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}` : "Etter avtale"}
                        </Label>
                    </View>
                )
            case 4:

                return (
                    <View style={styles.lblCompleted}>
                        <Label xsmall
                               color={Color.PRIMARY}>
                            {"Fra oppdragsignering til markedsføring"}
                        </Label>
                    </View>
                )

        }
    };

    const renderStepperCustom = (item, index, self) => {
        return (
            <View style={styles.stepperCont}
                  key={index.toString()}>
                <View style={styles.dotCont}>
                    <View style={styles.dot}/>
                    {index === self.length - 1 ? null : (<View style={styles.line}/>)}
                </View>
                <View style={styles.lblCont}>
                    <View style={styles.stepperTitleCont}>
                        <Icon name={item.timeline.icon?.replace("icon-", "")}
                              color={Color.SECONDARY}
                              size={ThemeUtils.fontXLarge}/>
                        <Label large
                               style={CommonStyle.flex}
                               ml={10}
                               font_medium>
                            {item.timeline.name}
                        </Label>
                    </View>
                    {item.is_completed ?
                        (<View style={styles.lblCompleted}>
                            <Label xsmall
                                   color={Color.WHITE}>
                                {`Completed : ${moment(item.completed_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}, ${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>) : null}
                    {/* <Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item.timeline.description}
                    </Label>*/}
                </View>
            </View>
        );
    };

    const renderStepperBuyerCustom = (item, index, isBuyer) => {
        return (
            <View style={styles.stepperCont}
                  key={index.toString()}>
                <View style={styles.dotCont}>
                    <View style={styles.dot}/>
                    <View style={styles.line}/>
                </View>
                <View style={styles.lblCont}>
                    <View style={styles.stepperTitleCont}>
                        <Icon name={item.timeline.icon?.replace("icon-", "")}
                              color={Color.SECONDARY}
                              size={ThemeUtils.fontXLarge}/>
                        <Label large
                               style={CommonStyle.flex}
                               ml={10}
                               font_medium>
                            {item.timeline.name}
                        </Label>
                    </View>
                    {item.completed_date ?
                        (<View style={styles.lblCompleted}>
                            <Label xsmall
                                   color={Color.SECONDARY}>
                                {`${moment(item.completed_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}`}
                            </Label>
                        </View>) : null}
                    {item.timeline?.link ? (
                        <CustomButton mt={ThemeUtils.relativeRealHeight(2)}
                                      mb={ThemeUtils.relativeRealHeight(1)}
                                      title={`Get ${item.timeline?.name}`}/>
                    ) : null}
                    {/*<Label small
                           style={CommonStyle.flex}
                           mt={15}
                           mb={20}>
                        {item.timeline.description}
                    </Label>*/}
                </View>
            </View>
        );
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    return (
        <View style={CommonStyle.flex}>

            <Header navigation={IS_WEB ? history : navigation}
                    style={CommonStyle.blankHeader}/>
            <View style={styles.icTimeline}>
                <Icon name={'timeline'}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY}/>
                <Label xlarge
                       ml={5}>
                    {`${Strings.timeline}`}
                </Label>
            </View>
            <Label small
                   mt={5}
                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                {'Her finner du tidslinjene til selger og kjøper'}
            </Label>
            <IndicatorViewPager indicator={renderTabIndicator()}
                                style={CommonStyle.flex}>
                <View style={styles.timelineCont}>
                    <ScrollView>
                        {sellerTimeline.loading || updateTimeline.loading ? (
                            <ActivityIndicator style={CommonStyle.loader}
                                               color={Color.PRIMARY}/>
                        ) : sellerTimeline?.data?.seller_timeline?.length ? sellerTimeline?.data?.seller_timeline?.map(renderStepperCustom) : (
                            <Label small
                                   font_medium
                                   align={'center'}>
                                {"Ingen data"}
                            </Label>
                        )}
                    </ScrollView>
                </View>
                <View style={styles.timelineCont}>
                    <ScrollView>
                        {buyerTimeline.loading || updateTimeline.loading ? (
                                <ActivityIndicator style={CommonStyle.loader}
                                                   color={Color.PRIMARY}/>
                            ) :
                            buyerTimeline?.data?.buyer_property_timeline?.length ? buyerTimeline?.data?.buyer_property_timeline?.map(renderStepperBuyerCustom) : (
                                <Label small
                                       font_medium
                                       align={'center'}
                                       style={CommonStyle.loader}>
                                    {"Ingen data"}
                                </Label>
                            )}
                    </ScrollView>
                </View>
            </IndicatorViewPager>


        </View>
    )

}


