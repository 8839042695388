import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    documentcontainer: {
        marginVertical: ThemeUtils.relativeRealHeight(2),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    iconView: {
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    imageProfile: {
            width: 150,
            height: 150,
            borderRadius: 12,
            marginBottom: 10
        },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
    fieldView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
    buttonContainer:
        {
            marginTop: '80%',
            flexDirection: 'row',
            justifyContent: 'space-between',

        }
    , modalCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: 10
    },
    attachment: {
        marginVertical: 10
    },
    chk: {
        marginVertical: 5
    },
    btnCont: {
        flexDirection: 'row',
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom: 10
    }
});
export {styles};
