import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {connect} from "react-redux";
import {CommonStyle} from "../../../../../../../../utils";


const QUESTIONS_2 = [{
    value: 1,
    name: "Pris – Den letteste metoden for å oppnå et raskt salg er å sette en lav " +
        "prisantydning som dere også må være villige til å annonsere. Jeg antar selvsagt at " +
        "dere også ønsker å oppnå den beste prisen?"
}, {
    value: 2,
    name: "Presentasjon – Tenker dere at hvordan boligen presenteres kan være " +
        "avgjørende?"
}, {
    value: 3,
    name: "Bilder – Tror dere at bildene kan påvirke resultatet?"
}, {
    value: 4,
    name: "Markedsføring – Hvis vi nå har den beste presentasjonen og de beste bildene, hva " +
        "må vi gjøre for at flest mulig skal få med seg at boligen er til salgs?"
}, {
    value: 5,
    name: "Megler – Tror dere megleren kan være avgjørende for hvor fort boligen deres blir " +
        "solgt?"
}];

const QUESTIONS_1 = [ {
    value: 2,
    name: "Presentasjon – Tenker dere at hvordan boligen presenteres kan være " +
        "avgjørende?"
}, {
    value: 3,
    name: "Bilder – Tror dere at bildene kan påvirke resultatet?"
}, {
    value: 4,
    name: "Markedsføring – Hvis vi nå har den beste presentasjonen og de beste bildene, hva " +
        "må vi gjøre for at flest mulig skal få med seg at boligen er til salgs?"
}, {
    value: 5,
    name: "Megler – Tror dere megleren kan være avgjørende for hvor fort boligen deres blir " +
        "solgt?"
}];

const OPTIONS_1 = [
    {name: 'Det gikk bra', value: 1},
    {name: 'Nei', value: 2},
];

function FirstQuestion(props) {
    const {onChangeAnswer, fromStart, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers]);



    const getQuestion = () => {
        switch (fromStart) {
            case 1:
                return "Hva skal til for å oppnå den høyeste prisen?";
            case 2:
                return "Hva skal til for å oppnå et raskt salg?";
            case 3:
                return "Ryddig prosess";
            case 4:
                return "Trygt oppgjør";

        }
    };

    const renderFirstStep = () => {
        return (
            <View>
                <RadioGroup options={QUESTIONS_1}
                            style={{marginVertical: 10}}
                            textStyle={CommonStyle.flex}
                            labelProps={{
                                large: true
                            }}
                            onSelectOption={setAnswerFirst}/>
            </View>
        )
    };

    const renderSecondStep = () => {
        return (
            <View>
                <RadioGroup options={QUESTIONS_2}
                            style={{marginVertical: 10}}
                            textStyle={CommonStyle.flex}
                            labelProps={{
                                large: true
                            }}
                            onSelectOption={setAnswerFirst}/>
            </View>
        )
    };

    const renderThirdStep = () => {
        return (
            <View>
                <Label large>
                    {"- Dere har full oversikt over hva som skjer og hvor vi er i prosessen til en hver tid.\n\n" +
                    "- Min fulle oppmerksomhet gjennom hele prosessen.\n\n" +
                    "- Tilgjengelig på telefon for både deg og interessenter til nærmest alle døgnets tider.\n\n" +
                    "- Jeg vil til enhver tid gi deg våre ærlige og beste råd, men det er alltid du som har siste ordet.\n\n" +
                    "- Jobben og våre kunder kommer først, og vi har stor respekt for tilliten vi får fra dere når vi går i gang.\n\n" +
                    "- Gir oss ikke før vi er absolutt sikker på at vi har fått den beste prisen for boligen!"}
                </Label>
            </View>
        )
    };

    const renderFourthStep = () => {
        return (
            <View>
                <Label large>
                    {"- Intern oppgjørsavdeling\n\n" +
                    "- Digitalt oppgjør (avhengig av kjøpers bank)"}
                </Label>
            </View>
        )
    };

    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}
                   mb={15}>
                {getQuestion(fromStart)}
            </Label>
            {fromStart === 1 ? renderFirstStep() : null}
            {fromStart === 2 ? renderSecondStep() : null}
            {fromStart === 3 ? renderThirdStep() : null}
            {fromStart === 4 ? renderFourthStep() : null}

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstQuestion)
