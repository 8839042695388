import React from 'react'
//Components
import {
    ActivityIndicator,
    Animated,
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    View
} from 'react-native'

import {
    AddServiceView,
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputDialog,
    Label,
    Modal,
    PagerTabIndicator
} from "components/src/components";
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {PHOTO_SALES_INFO, PHOTOS, PURCHASED_SERVICES} from "components/src/api/query";
import {ATTACH_360_LINK, ATTACH_VIDEO_LINK, DELETE_PHOTOS, UPLOAD_PHOTO} from "components/src/api/mutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    formatPrice,
    Icon,
    IS_WEB,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";
import PhotoDetails from "./PhotoDetails";


const TABS = [{
    text: Strings.sellerInformation
}, {
    text: Strings.uploadPhoto
}];

class Photo extends React.Component {
    constructor(props) {
        super(props)

        this.propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');


        this.state = {
            photoDetailsVisible: false,
            isPreviewVisible: false,
            uploadPhotoShow: false,
            inspectionDate: undefined,
            inspectionDateDisplay: '',
            selectedImages: [],
            earlierStartTime: undefined,
            earlierStartTimeDisplay: '',

            latestStartTime: undefined,
            latestStartTimeDisplay: '',

            comments: undefined,

            selectedAddServices: [],

            link: '',
            videoLinkVisible: false,
            viewLinkVisible: false,
            dailogVisible: false,


            totalAmount: 0,
            displayAmount: undefined,
            cheboxshow: false,
            count: 0,

            selectedImageForDelete: [],
            selectionEnable: false,

        }
        this.scrollY = new Animated.Value(0)
    }

    toggleImagePreviewModal = () => {
        this.setState(prevState => ({
            isPreviewVisible: !prevState.isPreviewVisible
        }))
    }

    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };
    handleAddOppDrag = () => {
    };

    toggleModelVideo = () => {
        this.setState({
            videoLinkVisible: !this.state.videoLinkVisible,

        })
    }
    toggleModelView = () => {
        this.setState({

            viewLinkVisible: !this.state.viewLinkVisible
        })
    }

    renderAdditionalServiceItem = (item, index, parentIndex) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            mb={9}
                            desc={item.description}/>)
    };

    toggleImageDetailsModal = (image) => {
        this.setState(prevState => ({
            photoDetailsVisible: !prevState.photoDetailsVisible,
            selectedImage: image,
        }))
    }

    renderAdditionalService = ({item, index}) => {
        let data = []
        if (item?.vendor_services?.length) {
            data = item?.vendor_services?.filter(item => item.service_selected)
        } else {
            data = item.purchased_office_template.purchased_office_services.filter(item => item.service_selected)
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, childIndex) => this.renderAdditionalServiceItem(item, childIndex, index))}
            </View>
        )
    }

    OrderingInformation = (loading, orderData) => {
        if (loading) {
            return this.renderLoader()
        }
        if (!orderData) {
            return (
                <Label mt={20}
                       align={'center'}
                       font_medium>
                    {"Ingen data"}
                </Label>
            )
        }
        return (
            <View>
                <View style={styles.topContainer}>
                    <View style={styles.DetailsView}>
                        {this.Details(orderData)}
                    </View>

                    {orderData?.comment ? (<View>
                        <Label
                            mt={5}
                            font_medium
                            small
                            align={'left'}>{Strings.comment}</Label>
                        <Label
                            mt={12}
                            font_regular
                            small
                            align={'left'}>
                            {orderData?.comment}
                        </Label>
                    </View>) : null}

                    <View>

                        <Label
                            mt={18}
                            mb={13}
                            small
                            font_medium
                            align={'left'}>
                            {Strings.additionalervicesOrdered}
                        </Label>

                        {/*<Query query={SELLER_PHOTO_SERVICES}
                               variables={{
                                   status: 1,
                                   propertyId: orderData.property_id,
                               }}>
                            {({data, loading, error}) => {
                                return (

                                )

                            }}

                        </Query>*/}

                        <Query query={PURCHASED_SERVICES}
                               variables={{
                                   status: 1,
                                   property_id: orderData.property_id,
                                   service_category: Constants.ServiceCategory.PhotoStyling
                               }}>
                            {({data, loading, error}) => {
                                if (loading && !data) {
                                    return (
                                        <ActivityIndicator color={Color.PRIMARY}/>
                                    )
                                }
                                let additioanlSServices = [];
                                data?.purchased_services?.purchased_services?.map((service, index) => {
                                    let vendorServices = [];
                                    if (index === 0) {

                                        vendorServices = data?.purchased_services?.service_types[0];
                                    }
                                    if (index === 1) {
                                        // vendorServices = vendorServicesQuery.data?.purchased_services?.service_types[1];
                                        return;
                                    }
                                    if (index === 3) {
                                        vendorServices = data?.purchased_services?.service_types[2];
                                    }
                                    if (index === 4) {
                                        vendorServices = data?.purchased_services?.service_types[3];
                                    }
                                    if (vendorServices?.vendor_services?.length) {
                                        if (vendorServices?.vendor_services?.filter(item => item.service_selected).length) {
                                            additioanlSServices.push(vendorServices)
                                        }
                                    } else if (service.purchased_office_template?.purchased_office_services?.filter(item => item.service_selected).length) {
                                        additioanlSServices.push(service)
                                    }
                                })
                                return (
                                    <FlatList data={additioanlSServices}
                                              extraData={this.state}
                                              keyExtractor={(item, index) => `${index}`}
                                              renderItem={this.renderAdditionalService}

                                    />
                                )
                            }}
                        </Query>


                    </View>
                </View>

            </View>
        );
    }

    renderLoader = () => {
        return <ActivityIndicator color={Color.PRIMARY}
                                  style={styles.loader}/>
    }

    renderUploadPhotoModal = (salesData) => {
        return (
            <Modal animated
                   hardwareAccelerated
                   visible={this.state.isPreviewVisible}
                   onRequestclose={this.toggleImagePreviewModal}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header style={CommonStyle.blankHeader}
                                headerLeft={'close'}
                                onPressLeft={this.toggleImagePreviewModal}
                        />
                        <View style={CommonStyle.flex}>
                            <Animated.ScrollView>
                                <View style={styles.ImageGridView}>
                                    {this.state.selectedImages.map(item => {
                                        return (
                                            <Image source={IS_WEB ? URL.createObjectURL(item) : {uri: item.uri}}
                                                   style={styles.GridImage}/>

                                        )
                                    })}
                                </View>
                            </Animated.ScrollView>
                        </View>
                        <View style={styles.btnCont}>
                            <Mutation mutation={UPLOAD_PHOTO}
                                      onCompleted={() => {
                                          this.refetch && this.refetch()
                                          this.toggleImagePreviewModal();
                                      }}>
                                {(uploadDoc, {loading}) => {
                                    return (<CustomButton title={Strings.save}
                                                          onPress={() => {
                                                              uploadDoc({
                                                                  variables: {
                                                                      photo: this.state.selectedImages,
                                                                      photoOrderId: salesData?.moovin_photo_seller_info?.id
                                                                  }
                                                              })
                                                          }}
                                                          loading={loading}
                                                          style={{flex: 1}}
                                                          mr={10}/>)
                                }}
                            </Mutation>

                            <CustomButton title={Strings.cancel}
                                          onPress={() => {
                                              this.setState({selectedImages: []}, this.toggleImagePreviewModal)
                                          }}
                                          style={{flex: 1}}
                                          textColor={Color.PRIMARY}
                                          borderWidth={1}
                                          borderColor={Color.PRIMARY_LIGHT}
                                          bgColor={Color.WHITE}
                                          ml={10}/>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    toggleDeleteDialog = () => {
        this.setState(prevState => ({
            dialogVisible: !prevState.dialogVisible
        }))
    };

    uploadphotos = (salesData) => {
        return (
            <Query query={PHOTOS}
                   variables={{
                       propertyId: this.propertyId
                   }}>
                {({data, loading, error, refetch}) => {
                    if (loading && !data) {
                        this.refetch = refetch
                        return this.renderLoader()
                    }

                    return (
                        <View>
                            {this.renderUploadPhotoModal(salesData)}
                            <View style={styles.topContainer}>

                                <View>
                                    <Label small font_regular mt={15} mb={15}>
                                        {Strings.uploadintroduction}
                                    </Label>
                                </View>


                                <Label small font_medium mt={15} mb={15}>
                                    {Strings.uploadNewPhoto}
                                </Label>

                                <FilePicker multiple
                                            style={{alignSelf: 'flex-start'}}
                                            type={FileType.IMAGE}
                                            onFilePicked={(data, files) => {
                                                if (files.length) {
                                                    this.setState({selectedImages: files}, () => {
                                                        this.toggleImagePreviewModal()
                                                    })
                                                }

                                            }}>
                                    <View style={styles.SubViewIcon}>
                                        <Icon name={'add'}
                                              size={ThemeUtils.responsiveScale(60)}
                                              color={Color.INPUT_BORDER}/>

                                    </View>

                                </FilePicker>
                                <View style={styles.MainView}>

                                    <View style={styles.SubView}>
                                        <Mutation mutation={ATTACH_360_LINK}
                                                  onError={() => {

                                                  }}
                                                  onCompleted={this.toggleModelView}>
                                            {(addLink, {loading, error}) => (
                                                <InputDialog visible={this.state.viewLinkVisible}
                                                             onNegativePress={this.toggleModelView}
                                                             loading={loading}
                                                             onPositivePress={(link) => {
                                                                 addLink({
                                                                     variables: {
                                                                         input: {
                                                                             photo_order_id: salesData?.moovin_photo_seller_info?.id,
                                                                             url: link
                                                                         }
                                                                     }
                                                                 })
                                                             }}
                                                             title={Strings.attachViewLink}
                                                             placeholder={Strings.attachViewLink}
                                                />
                                            )}

                                        </Mutation>
                                        <Mutation mutation={ATTACH_VIDEO_LINK}
                                                  onError={() => {

                                                  }}
                                                  onCompleted={this.toggleModelVideo}>
                                            {(addLink, {loading, error}) => (
                                                <InputDialog visible={this.state.videoLinkVisible}
                                                             onNegativePress={this.toggleModelVideo}
                                                             loading={loading}
                                                             onPositivePress={(link) => {
                                                                 addLink({
                                                                     variables: {
                                                                         input: {
                                                                             photo_order_id: salesData?.moovin_photo_seller_info?.id,
                                                                             url: link
                                                                         }
                                                                     }
                                                                 })
                                                             }}
                                                             title={Strings.attachVideoLink}
                                                             placeholder={Strings.attachVideoLink}
                                                />
                                            )}
                                        </Mutation>
                                        <Label small
                                               style={CommonStyle.flex}
                                               font_medium mt={15} mb={15}>
                                            {Strings.attachVideoLink}
                                        </Label>
                                        <TouchableOpacity onPress={this.toggleModelVideo}>

                                            <View style={styles.SubViewIcon}>
                                                <Icon name={'video'}
                                                      size={ThemeUtils.responsiveScale(60)}
                                                      color={Color.INPUT_BORDER}/>

                                            </View>
                                        </TouchableOpacity>
                                    </View>


                                    <View style={styles.SubView}>
                                        <Label small font_medium mt={15} mb={15}>
                                            {Strings.attachViewLink}
                                        </Label>
                                        <TouchableOpacity onPress={this.toggleModelView}>

                                            <View style={styles.SubViewIcon}>
                                                <Icon name={'360'}
                                                      size={ThemeUtils.responsiveScale(60)}
                                                      color={Color.INPUT_BORDER}/>
                                            </View>
                                        </TouchableOpacity>
                                    </View>


                                    <View>

                                    </View>

                                </View>
                            </View>


                            <View style={{marginTop: 20}}>
                                <Hr/>
                            </View>
                            {data?.moovin_photo_photos?.length ?
                                (<View>
                                    {this.state.selectionEnable ? (
                                        <View style={styles.propertySelectionCont}>

                                            <Icon name={'tickmark'}
                                                  size={ThemeUtils.fontNormal}
                                                  color={Color.SECONDARY}/>
                                            <Label small
                                                   font_medium
                                                   ml={15}
                                                   style={CommonStyle.flex}>
                                                {`${this.state.selectedImageForDelete.length} ${Strings.photoselected}`}
                                            </Label>


                                            <Mutation mutation={DELETE_PHOTOS}
                                                      refetchQueries={[{
                                                          query: PHOTOS,
                                                          variables: {
                                                              status: 1,
                                                              first: 30,
                                                              page: 1,
                                                              photoId: this.props.property?.order_photo?.id
                                                          }
                                                      }]}>
                                                {(deletePhoto, {loading}) => (
                                                    <>
                                                        <Dialog visible={this.state.dialogVisible}
                                                                transparent
                                                                onNegativePress={this.toggleDeleteDialog}
                                                                onPositivePress={() => {
                                                                    deletePhoto({
                                                                        variables: {
                                                                            ids: this.state.selectedImageForDelete
                                                                        }
                                                                    })
                                                                    this.setState({
                                                                        selectedImages: [],
                                                                        selectionEnable: false

                                                                    }, () => this.toggleDeleteDialog())

                                                                }}
                                                                title={`${Strings.deletePhoto}`}
                                                                buttonTitleFirst={Strings.cancel}
                                                                buttonTitleSecond={Strings.okay}
                                                                color={Color.RED}
                                                                desc={Strings.photoDesc}/>
                                                        {deletePhoto.loading ? (
                                                            <ActivityIndicator color={Color.PRIMARY}/>
                                                        ) : this.state.selectedImageForDelete.length ? (
                                                            <TouchableOpacity activeOpacity={0.6}
                                                                              onPress={this.toggleDeleteDialog}>
                                                                <Icon name={'delete'}
                                                                      size={ThemeUtils.fontNormal}
                                                                      color={Color.RED}/>
                                                            </TouchableOpacity>) : null}
                                                    </>
                                                )}

                                            </Mutation>

                                            <TouchableOpacity activeOpacity={0.6}
                                                              style={{marginStart: 20}}
                                                              onPress={this.toggleSelectionMode}>
                                                <Icon name={'close'}
                                                      size={ThemeUtils.fontNormal}
                                                      color={Color.PRIMARY}/>
                                            </TouchableOpacity>
                                        </View>
                                    ) : (<Label small
                                                font_medium
                                                mt={15}
                                                ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                                mb={5}>
                                        {Strings.photo}
                                    </Label>)}
                                    <Dialog visible={this.state.dailogVisible}
                                            transparent
                                            onNegativePress={this.DailogToggleModal}
                                            onPositivePress={this.DailogToggleModal}
                                            title={`${Strings.deletePhoto}`}
                                            buttonTitleFirst={Strings.cancel}
                                            buttonTitleSecond={Strings.deleteButton}
                                            color={Color.RED}
                                            desc={Strings.photoDesc}/>
                                    {this.PhotoGrid(data?.moovin_photo_photos ?? [])}
                                </View>) : null}

                        </View>
                    )
                }}
            </Query>

        );
    }

    DailogToggleModal = () => {
        this.setState({
            dailogVisible: !this.state.dailogVisible,

        })
    }


    toggleSelectionMode = (index, userId) => {
        this.setState(prevState => ({
            selectionEnable: !prevState.selectionEnable,
        }), () => {
            if (this.state.selectionEnable) {
                userId == this.props.user?.id ? this.addOrRemoveImage(index) : null
            } else {
                this.setState({
                    selectedImageForDelete: []
                })
            }
        })
    };

    addOrRemoveImage = (index) => {

        let selectedImages = this.state.selectedImageForDelete.slice();
        if (selectedImages.includes(index)) {
            selectedImages.splice(selectedImages.indexOf(index), 1)
        } else {
            selectedImages.push(index)
        }
        this.setState({
            selectedImageForDelete: selectedImages,
            selectionEnable: selectedImages.length !== 0
        })
    };

    PhotoGrid = (data) => {

        return <View style={styles.ImageGridView}>
            {data.map(obj => {
                return (
                    <TouchableOpacity
                        onLongPress={() => !this.state.selectionEnable ? this.toggleSelectionMode(obj.id, obj.user_id) : null}
                        onPress={() => this.state.selectionEnable ? obj.user_id == this.props.user?.id ? this.addOrRemoveImage(obj.id) : null : this.toggleImageDetailsModal(obj)}
                        activeOpacity={0.7}>

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${obj.photo_url}`}}
                               style={styles.GridImage}/>
                        {this.state.selectionEnable && obj.user_id == this.props.user?.id ?
                            (<View
                                style={[styles.GridImage, styles.icCheck, this.state.selectedImageForDelete.includes(obj.id) ? {backgroundColor: Color.PRIMARY_TRANSPARENT} : null]}>
                                <Icon name={'tickmark'}
                                      size={ThemeUtils.fontXLarge}
                                      color={this.state.selectedImageForDelete.includes(obj.id) ? Color.COLOR_ACCENT : Color.PRIMARY_LIGHTER}/>
                            </View>) : null}
                    </TouchableOpacity>

                )
            })}
        </View>
    }

    renderPhotoDetailsModal = () => {
        return (
            <Modal visible={this.state.photoDetailsVisible}>
                <PhotoDetails image={this.state.selectedImage}
                              user={this.props.user}
                              refetch={this.refetch}
                              onClose={this.toggleImageDetailsModal}/>
            </Modal>
        )
    }

    Details = (data) => {
        return (
            <View>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.oppDragNo}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>{data?.property?.oppdrags_nummer}</Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.sellerName}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {data?.property?.seller ?
                        `${data?.property?.seller?.first_name ?? ''} ${data?.property?.seller?.last_name ?? ''}` : null}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Email}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {data?.property?.seller?.email}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Phone}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {data?.property?.seller?.mobile}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Date}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>
                    {moment(data.order_date, DateUtils.yyyy_mm_dd).format(DateUtils.dd_mm_yyyy)}
                </Label>
                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.Time}</Label>
                {data?.order_status === "Ordered" ?
                    (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {`${moment(data?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)} - ${moment(data.latest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}`}
                        </Label>) : (
                        <Label small
                               mb={20}
                               font_regular
                               align={'left'}>
                            {moment(data?.earliest_start_time, DateUtils.hh_mm_ss).format(DateUtils.hh_mm)}
                        </Label>
                    )}

                <Label small
                       font_medium
                       mb={8}
                       align={'left'}>{Strings.propertyAddress}</Label>
                <Label small
                       mb={20}
                       font_regular
                       align={'left'}>

                    {`${data?.property?.street_adress} ${data?.property?.city ?? ""} ${data?.property?.zipcode ?? ""}`}

                </Label>
            </View>
        );
    };

    render() {
        return (
            <View style={CommonStyle.flex}>
                <Header style={CommonStyle.blankHeader}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}
                        animatedValue={this.scrollY}/>
                {this.renderPhotoDetailsModal()}
                <View style={styles.container}>
                    <View style={styles.titleCont}>
                        <Icon name={'photo'} size={ThemeUtils.fontXXLarge}/>
                        <Label
                            font_medium

                            ml={9}
                            xlarge
                            align={'left'}>
                            {Strings.photo}
                        </Label>


                    </View>


                    <Query query={PHOTO_SALES_INFO}
                           variables={{
                               propertyId: this.propertyId
                           }}>
                        {({data, loading, error}) => {
                            return (
                                <IndicatorViewPager indicator={this._renderTabIndicator()}
                                                    style={{flex: 1, backgroundColor: 'white'}}>

                                    <View>
                                        <ScrollView>

                                            {this.OrderingInformation(loading, data?.moovin_photo_seller_info ?? null)}
                                        </ScrollView>
                                    </View>


                                    <View>
                                        <ScrollView>
                                            {data?.order_status === "Ordered" ?
                                                this.uploadphotos(data) : (
                                                    <Label mt={20}
                                                           align={'center'}
                                                           font_medium>
                                                        {"Ingen bilder lastet opp"}
                                                    </Label>
                                                )}
                                        </ScrollView>
                                    </View>

                                </IndicatorViewPager>)
                        }}
                    </Query>

                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Photo);
