export default {
  'component.buyer.title': 'Kjøper',
  'component.buyer.addBuyer': 'Legg til kjøper',
  'component.buyer.infoBuyer': 'Info fra kjøper',
  'component.buyer.buyerInfo': 'Kjøpers info',
  'component.buyer.firstname': 'Fornavn',
  'component.buyer.lastname': 'Etternavn',
  'component.buyer.email.placeholder': 'Skriv inn e-post',
  'component.buyer.phone.placeholder': 'Skriv inn telefon',
  'component.buyer.address': 'Adresse',
  'component.buyer.address.placeholder': 'Angi adresse',
  'component.buyer.city': 'Poststed',
  'component.buyer.city.placeholder': 'Skriv inn poststed',
  'component.buyer.zipcode': 'Postnummer',
  'component.buyer.zipcode.placeholder': 'Legg inn postnummer',
  'component.buyer.cobuyer': 'Medkjøpers info',
  'component.buyer.property': 'Eiendomsinfo',
  'component.buyer.purchase': 'Kjøp',
  'component.buyer.purchase.placeholder': 'Tast inn beløp',
  'component.buyer.commune': 'Kommune',
  'component.buyer.commune.placeholder': 'Gå inn i kommune',
  'component.buyer.gnr': 'GNR',
  'component.buyer.gnr.placeholder': 'Oppgi GNR',
  'component.buyer.bnr': 'BNR',
  'component.buyer.bnr.placeholder': 'Oppgi BNR',
  'component.buyer.fnr': 'FNR',
  'component.buyer.fnr.placeholder': 'Oppgi FNR',
  'component.buyer.org': 'ORG.NR',
  'component.buyer.org.placeholder': 'Oppgi ORG.NR',
  'component.buyer.snr': 'SNR / ANDELSNR',
  'component.buyer.snr.placeholder': 'Oppgi SNR / ANDELSNR',
  'component.buyer.upload.contract': 'Last opp kontraktutkast',
  'component.buyer.register': 'Registrer kjøper',
  'component.buyer.contactbank': 'Kontaktinfo Bankk',
  'component.buyer.bankname': 'Bank navn :',
  'component.buyer.bankcontact': 'Kontaktperson for banken :',
  'component.buyer.emailcontact': 'Telefon / e-post til kontaktperson :',
  'component.buyer.accountno': '	Kontonr. :',
  'component.buyer.ownership': 'Eie',
  'component.buyer.interestuse': 'Interesse for bruk :',
  'component.buyer.namebuyer1': 'Navn på kjøper 1:',
  'component.buyer.namebuyer2': 'Navn på kjøper 2 :',
  'component.buyer.homeowner': 'Huseiere forsikring :',
  'component.buyer.insurance': 'Ja, jeg vil ha forsikring.',
  'component.buyer.contract': 'Kontrakt',
  'component.buyer.comment': 'Kommentar:',
  'component.buyer.addmorebank': 'Legg til mer bank',
  'component.buyer.noinsurance': 'Nei, jeg vil ikke ha forsikring',
  'component.buyer.amount': 'Beløp',
};
