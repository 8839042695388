import React from 'react'
import {ScrollView, View} from 'react-native'
import {CustomButton, Header, InputField, Label} from "components/src/components";

import Provider from "@ant-design/react-native/lib/provider";
import {logout, setToken, setUser} from "components/src//redux/action";
import {connect} from "react-redux";

import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//Network
import {Mutation} from "@apollo/react-components";
import {CHANGE_PASSWORD} from "components/src/api/mutation";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    IS_WEB,
    PasswordValidate,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import {styles} from './styles.js';
import Cookie from 'js-cookie'


class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            errCurrentPassword: null,

            newPassword: '',
            errNewPassword: null,

            confirmPassword: '',
            errConfirmPassword: null
        }
    }

    handleChangeText = (text, fieldId) => {
        switch (fieldId) {
            case Constants.FieldId.OLD_PASSWORD:
                this.setState({
                    currentPassword: text,
                    errCurrentPassword: null
                });
                break;
            case Constants.FieldId.NEW_PASSWORD:
                this.setState({
                    newPassword: text,
                    errNewPassword: null
                });
                break;
            case Constants.FieldId.CONFIRM_PASSWORD:
                this.setState({
                    confirmPassword: text,
                    errConfirmPassword: null
                });
                break;
        }
    };

    updatePassword = (changePassword) => {
        const {
            currentPassword,
            newPassword,
            confirmPassword
        } = this.state;

        this.setState((prevState) => ({
            errCurrentPassword: validation('passwordBlank', currentPassword),
            errNewPassword: validation('password', newPassword),
            errConfirmPassword: PasswordValidate(newPassword, confirmPassword),
        }), () => {
            if (!(this.state.errCurrentPassword || this.state.errNewPassword || this.state.errConfirmPassword)) {
                changePassword({
                    variables: {
                        input: {
                            current_password: currentPassword,
                            password: newPassword,
                            password_confirmation: confirmPassword
                        }
                    }
                }).catch(() => {

                })
            }
        });
    };


    render() {
        const {user} = this.props;

        return (
            <Provider locale={enUS}>
                <Header style={CommonStyle.blankHeader}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={styles.container}>
                        <View style={styles.topContainer}>
                            <View style={styles.labeliconContainer}>
                                <Label xlarge
                                       font_medium
                                       mt={15}
                                       mb={15}
                                       align={'left'}>
                                    {Strings.changePassword}
                                </Label>
                            </View>


                            <InputField labelText={Strings.CurrentPassword}
                                        type={"password"}
                                        onChange={(text) => this.handleChangeText(text, Constants.FieldId.OLD_PASSWORD)}
                                        onFocus={() => this.setState({errCurrentPassword: null})}
                                        error={this.state.errCurrentPassword}
                                        value={this.state.currentPassword}
                                        placeholder={Strings.enterCurrentPassword}/>

                            <InputField type={"password"}
                                        onChange={(text) => this.handleChangeText(text, Constants.FieldId.NEW_PASSWORD)}
                                        onFocus={() => this.setState({errNewPassword: null})}
                                        error={this.state.errNewPassword}
                                        value={this.state.newPassword}
                                        labelText={Strings.NewPassword}
                                        placeholder={Strings.enterNewPassword}/>

                            <InputField type={"password"}
                                        onChange={(text) => this.handleChangeText(text, Constants.FieldId.CONFIRM_PASSWORD)}
                                        onFocus={() => this.setState({errConfirmPassword: null})}
                                        error={this.state.errConfirmPassword}
                                        value={this.state.confirmPassword}
                                        labelText={Strings.ConfirmPassword}
                                        placeholder={Strings.enterConfirmPassword}/>


                        </View>
                        <View style={styles.buttonContainer}>
                            <Mutation mutation={CHANGE_PASSWORD}
                                      onCompleted={(data) => {
                                          let user = data.change_password.user;
                                          this.props.setUser(user);
                                          this.props.setToken(data.change_password.token);
                                          if (IS_WEB) {
                                              Cookie.set('token', data.change_password.token?.access_token);
                                              Cookie.set(`Agent_token`, data.change_password.token.access_token);
                                              const userData = {
                                                  user: user,
                                                  userType: "Agent",
                                              };
                                              Cookie.set(`Agent_user`, userData);
                                          }
                                          IS_WEB ? this.props.history.goBack() : this.props.navigation.goBack()
                                      }}>
                                {(changePassword, {loading}) => (
                                    <CustomButton font_medium
                                                  loading={loading}
                                                  onPress={() => this.updatePassword(changePassword)}
                                                  mr={ThemeUtils.relativeWidth(1.5)}
                                                  style={{flex: 1}}// Do not remove inline will not work in web
                                                  title={Strings.updateButton}/>

                                )}
                            </Mutation>

                            <CustomButton
                                ml={ThemeUtils.relativeWidth(1.5)}
                                font_medium
                                onPress={() => {
                                    if (IS_WEB) {
                                        this.props.history.goBack();
                                    } else {
                                        this.props.navigation.goBack();
                                    }
                                }}
                                style={{
                                    flex: 1,
                                    borderWidth: 1,
                                    borderColor: Color.DARK_GRAY
                                }}// Do not remove inline will not work in web
                                textColor={Color.PRIMARY}
                                bgColor={Color.WHITE}
                                title={Strings.cancel}/>
                        </View>
                    </View>
                </ScrollView>
            </Provider>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
