import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Color.SECONDARY_BG_COLOR,

    },
    contentCont: {
        flex:1,
        marginVertical: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    imgCont: {
        flex:1,
        width: ThemeUtils.responsiveScale(260)+30,
        maxWidth:260+30,
        marginVertical: 20,
        flexDirection: 'row',
    },
    lblCont:{
        flex:1,
        marginHorizontal:40,
    },
    img: {
        marginTop: 40,
        marginStart: 30,
        maxWidth:260,
        maxHeight:260,
        height: ThemeUtils.responsiveScale(260),
        width: ThemeUtils.responsiveScale(260),
    },
    accordionContent: {
        paddingVertical: 0,
        paddingHorizontal: 15,
        backgroundColor: Color.WHITE
    },
    accordionHeader: {
        marginTop: 10,
        paddingStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        backgroundColor: Color.WHITE
    },
    testimonialCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        alignItems: 'center',
        justifyContent:'center'
    },
    pager: {
        height: ThemeUtils.relativeRealHeight(15),

    },
    mediaImgCont: {
        marginBottom: 20,
        marginEnd:ThemeUtils.relativeRealWidth(1)
    },
    mediaImage: {
        height: ThemeUtils.responsiveScale(220),
        width: ThemeUtils.responsiveScale(220),
        maxWidth:200,
        maxHeight:200
    },
    divider: {
        backgroundColor: Color.PRIMARY_LIGHTER,
        width: 1,
    }
})
