import React, {useEffect, useState} from 'react'
import {CustomButton, Hr, IndicatorViewPager, Label, Modal, Ripple} from "components/src/components";
import {ActivityIndicator, Animated, Image, SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
import styles from './styles';
import {Color, CommonStyle, Constants, formatPrice, Icon, Strings, ThemeUtils} from "components/src/utils";
import {useMutation} from "@apollo/react-hooks";
import {PROPERTY_SERVICES} from "../../../../../../../api/mutation";
import debounce from 'lodash.debounce';

const TABS = [{
    text: Strings.marketingPackages,
}, {
    text: Strings.services,
}]


function InspectionMarketingPackage(props) {
    const {loading, packageData, propertyId, inspection, data} = props

    const [selectedService, setSelectedService] = useState([]);

    const IMAGES = packageData?.MarketingPackageImages ?? []

    const [selectedImage, setSelectedImage] = useState(0);

    const [packageForView, setPackageForView] = useState();

    const [saveServices, saveServicesMutation] = useMutation(PROPERTY_SERVICES, {
        onCompleted: () => {

        }
    });

    const saveServiceWithDebounce = React.useCallback(debounce((variables) => {
        saveServices({
            variables: {
                input: {
                    ...variables,
                    broker_on_view: false
                }
            }
        }).then(() => {
            props.refetch()
        }).catch(() => {
        })
    }, 2000), [])


    useEffect(() => {
        setSelectedImage(1)
    }, [IMAGES])

    useEffect(() => {
        if (data) {
            setSelectedService(data?.filter(item => item?.property_package).map(item => item.id))
        }
    }, [data]);

    const renderScheme = (item, index) => {
        return (
            <View key={index.toString()}>
                <Label small
                       font_medium
                       mt={10}
                       style={CommonStyle.flex}>
                    {item?.office_service?.name}
                </Label>
                <Label small>
                    {item?.office_service?.description}
                </Label>
            </View>
        )
    }

    const renderService = (item, index, self) => {
        // const data = self.filter(item => item.service_selected).map(item => item.service_selected.id)
        return (
            <View style={styles.icDetailsCont}>
                <Ripple
                    style={[styles.serviceCont, selectedService.includes(item.id) ? {backgroundColor: Color.TERNARY_TEXT_COLOR} : null]}
                    onPress={() => {
                        // addOrRemoveItemFromArray(item)
                        if (!loading) {
                            let previousSelected = data?.filter(item => item?.property_package).map(filteredItem => filteredItem?.property_package?.id)

                            let variables = {
                                property_id: inspection?.property_id ?? propertyId,
                                marketing_package: [item.id],
                                broker_on_view: false
                            }
                            if (!previousSelected.includes(item?.property_package?.id)) {
                                variables = {
                                    ...variables,
                                    unselect_marketing_package: previousSelected
                                }
                            }
                            if (!selectedService.includes(item.id)) {
                                saveServiceWithDebounce(variables)
                                setSelectedService([item.id])

                            } else if (item.property_package?.id) {
                                variables = {
                                    property_id: inspection?.property_id ?? propertyId,
                                    unselect_marketing_package: [item.property_package?.id]
                                }
                                saveServiceWithDebounce(variables)
                                setSelectedService([])
                            }
                        }
                    }}
                    key={index.toString()}>
                    <Label small
                           font_medium
                           color={selectedService.includes(item.id) ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                        {item.name}
                    </Label>
                    <Label small
                           font_medium
                           color={selectedService.includes(item.id) ? Color.WHITE : Color.TERNARY_TEXT_COLOR}>
                        {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                    </Label>
                    <Label xsmall
                           mt={10}
                           style={CommonStyle.flex}>
                        {item.description}
                    </Label>
                </Ripple>
                <TouchableOpacity activeOpacity={0.5}
                                  onPress={() => setPackageForView(item)}
                                  style={styles.icCont}>
                    <Icon name={'disclaimer'}
                          color={Color.SECONDARY}
                          size={ThemeUtils.fontNormal}/>
                </TouchableOpacity>
            </View>
        )
    };

    const renderPackage = (item, index) => {

        return (
            <Ripple style={[styles.modalLblCont]}
                    onPress={() => {
                    }}
                    rippleContainerBorderRadius={4}
                    key={index.toString()}>
                <Label small
                       color={Color.SECONDARY}>
                    {item?.office_service?.name}
                </Label>
            </Ripple>
        )


    };

    const renderAddPackagesModal = () => {
        return (
            <Modal visible={!!packageForView}
                   animated
                   hardwareAccelerated
                   animationType={'fade'}
                   transparent>
                <SafeAreaView style={CommonStyle.flex}>

                        <View style={styles.modalCont}>
                            <View style={styles.dialogCont}>
                                <View style={styles.header}>
                                    <Label large
                                           style={CommonStyle.flex}
                                           font_medium>
                                        {Strings.services}
                                    </Label>
                                    <TouchableOpacity activeOpacity={0.5}
                                                      onPress={() => {
                                                          setPackageForView(null)
                                                      }}>
                                        <Icon name={'close'}
                                              size={ThemeUtils.fontLarge}
                                              color={Color.PRIMARY}/>
                                    </TouchableOpacity>
                                </View>
                                <Hr marginBottom={20}/>
                                <ScrollView contentContainerStyle={styles.pkgCont}>
                                    {loading && !data ? (
                                        <ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>
                                    ) : packageForView?.marketing_package_service?.map(renderPackage)}
                                </ScrollView>
                                <Hr/>
                                <View style={styles.btnCont}>

                                    <CustomButton title={Strings.okay}
                                                  onPress={() => {
                                                      setPackageForView(null)
                                                  }}
                                                  bgColor={Color.SECONDARY}
                                                  style={{width: ThemeUtils.relativeWidth(20)}}
                                                  textColor={Color.WHITE}
                                                  borderColor={Color.SECONDARY}
                                                  borderWidth={1}
                                                  ml={10}/>

                                </View>
                            </View>
                        </View>
                </SafeAreaView>
            </Modal>
        )
    }

    return (
        <View style={styles.container}>
            {renderAddPackagesModal()}
            <Animated.ScrollView>
                <View>
                    <View style={styles.imgCont}>
                        <View style={styles.imgCountCont}>
                            <Label small
                                   color={Color.WHITE}>
                                {` ${selectedImage} / ${IMAGES.length} `}
                            </Label>
                        </View>
                        <IndicatorViewPager style={CommonStyle.flex}
                                            onPageSelected={(e) => setSelectedImage(e.position + 1)}>
                            {IMAGES.map(item => (
                                <View>
                                    <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.image}`}}
                                           style={CommonStyle.flex}/>
                                </View>
                            ))}
                        </IndicatorViewPager>

                    </View>


                    <View style={styles.lblCont}>
                        <Label large
                               font_medium>
                            {packageData?.heading}
                        </Label>
                        <Label small color={Color.SECONDARY_TEXT_COLOR}
                               mt={10}
                               mb={10}>
                            {packageData?.description}
                        </Label>
                        {data?.map(renderService)}
                    </View>

                </View>
            </Animated.ScrollView>
        </View>
    )
}

export default InspectionMarketingPackage
