import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.relativeWidth(6);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        flex:1,
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },
    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    field: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    fieldView: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    fieldViews: {

        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    buttonContainer: {
        marginHorizontal:containerHorizontalMargin,
        marginBottom:containerHorizontalMargin,
        flexDirection: 'row',
    },
    flex:{
        flex:1
    }

});
export {styles};
