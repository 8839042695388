import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {Color, CommonStyle, Icon, ThemeUtils} from "components/src/utils";
import {connect} from "react-redux";
import styles from "../../../../../../../../components/ui/RadioGroup/styles";


const OPTIONS_1 = [
    {name: 'Kan det ha medført at dere ikke fikk en så høy ' +
            'pris som dere kunne fått om alt hadde vært ' +
            'gjort 100%?', value: 0},
    {name: 'Hvor mye kan det i verste fall ha utgjort i ' +
            'forskjell for dere?', value: 1},
];


function SecondQuestion(props) {
    const {onChangeAnswer,fromPrev, setForm, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();
    const [detVar, setDetVar] = useState();

    useEffect(() => {
        props.onAnswer(answersSec)
    }, [answersSec])

    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {"Hva kan konsekvensen av det ha vært for dere?"}
            </Label>

            <RadioGroup options={OPTIONS_1}
                        style={{marginVertical: 10}}
                        textStyle={CommonStyle.flex}
                        labelProps={{
                            large: true
                        }}
                        onSelectOption={setAnswerFirst}/>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(SecondQuestion)
