import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, Image, Keyboard, SafeAreaView, ScrollView, View} from 'react-native'
import {
    Attachment,
    CustomButton,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator,
    RichTextEditor,
    RichToolbar,
    Ripple,
    StarRating,
    StarRatingInput
} from "components/src/components";
//Third party
//Network
import {Mutation, Query} from "@apollo/react-components";
import {AGENT_DETAILS, AGENT_MEDIA} from "components/src/api/query";
//redux
import {connect} from "react-redux";
//Utils
import {styles} from './styles.js';
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    openInNewTab,
    Routes,
    showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {
    ADD_AGENT_MEDIA,
    ADD_FEEDBACK,
    CHANGE_PROFILE_PIC,
    DELETE_AGENT_CV,
    DELETE_AGENT_DOCUMENT,
    DELETE_AGENT_MEDIA,
    DELETE_FEEDBACK,
    EDIT_AGENT_MEDIA,
    EDIT_FEEDBACK,
    UPDATE_AGENT_PROFILE
} from "components/src/api/mutation";

import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import userPlaceholder from 'components/src/assets/images/user_placeholder.png'
import {setUser} from "../../../../../redux/action";

const TABS = [{
    text: Strings.agentDetailsTag
}, {
    text: Strings.feedbackTag
}, {
    text: Strings.media
}];

const INPUTS = [{
    id: Constants.FieldId.FIRST_NAME,
    title: Strings.firstName,
    placeholder: Strings.enterFirstName,
    type: 'text',
    validationType: 'firstName'
}, {
    id: Constants.FieldId.LAST_NAME,
    title: Strings.lastName,
    placeholder: Strings.enterLastName,
    type: 'text',
    validationType: 'lastName'
}, {
    id: Constants.FieldId.EMAIL,
    title: Strings.email,
    placeholder: Strings.enterEmail,
    type: 'email',
    editable: false,
    validationType: 'email'
}, {
    id: Constants.FieldId.PHONE,
    title: Strings.phone,
    placeholder: Strings.enterPhone,
    type: 'number',
    validationType: 'phoneNoOnly'
}, {
    id: Constants.FieldId.TITLE,
    title: Strings.title,
    placeholder: Strings.enterTitle,
    type: 'text',
    validationType: 'title'
}, {
    id: 'office',
    title: Strings.office,
    editable: false,
    placeholder: Strings.office,
}, {
    id: Constants.FieldId.EDUCATION,
    title: Strings.education,
    placeholder: Strings.enterEducation,
    type: 'textArea',
},];


class Profile extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.user)
        this.state = {
            comments: undefined,
            rating: 0,
            addFeedbackVisible: false,
            addMediaVisible: false,
            cv: null,
            feedBack: "",
            givenBy: "",
            otherDoc: [],
            files: [],
            values: {
                [Constants.FieldId.FIRST_NAME]: props.user?.first_name ?? '',
                [Constants.FieldId.LAST_NAME]: props.user?.last_name ?? '',
                [Constants.FieldId.EMAIL]: props.user?.email ?? '',
                [Constants.FieldId.PHONE]: props.user?.mobile ?? '',
                [Constants.FieldId.TITLE]: props.user?.title ?? '',
                [Constants.FieldId.EDUCATION]: props.user?.user_detail?.education ?? '',
                [Constants.FieldId.SUMMARY_ABOUT_AGENT]: props.user?.user_detail?.about ?? '',
                "office": props.user?.office?.name ?? '',
            },
            errors: {}

        }
        this.scrollY = new Animated.Value(0)
    }


    toggleFeedbackModal = () => {
        this.setState(prevState => ({
            addFeedbackVisible: !prevState.addFeedbackVisible
        }), () => {
            if (!this.state.addFeedbackVisible) {
                this.setState({
                    feedBackForEdit: null,
                    feedBack: "",
                    givenBy: ""
                })
            }
        })
    };

    toggleMediaModal = () => {
        this.setState(prevState => ({
            addMediaVisible: !prevState.addMediaVisible
        }), () => {
            if (!this.state.addMediaVisible) {
                this.setState({
                    mediaForEdit: null,
                    title: "",
                    files: [],
                    redirectionUrl: "",
                })
            }
        })
    };

    handleChangeText = (text, fieldId) => {
        let values = {...this.state.values};
        values[fieldId] = text;
        this.setState({values})
    };
    _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };
    updateProfile = async (updateProfile) => {
        const {values} = this.state;
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        const errors = {...this.state.errors};
        let isError = false;
        for (let i = 0; i < INPUTS.length; i++) {
            let item = INPUTS[i];
            errors[item.id] = validation(item.validationType, values[item.id] ? values[item.id].trim() : '');
            if (errors[item.id]) {
                isError = true;
            }
        }
        const content = await this.richtext.getContentHtml();
        const trueContent = content.toString();
        console.log(content, "Content")
        // let err=validation('summaryAboutAgent', trueContent)
        if (isError) {
            this.setState({errors})
        } else {
            let variables = {
                id: this.props.user?.id,
                officeId: this.props.user?.office_id,
                first_name: values[Constants.FieldId.FIRST_NAME],
                last_name: values[Constants.FieldId.LAST_NAME],
                email: values[Constants.FieldId.EMAIL],
                title: values[Constants.FieldId.TITLE],
                is_in_admin_office: this.props.user?.is_in_admin_office
            }
            if (values[Constants.FieldId.PHONE]) {
                variables.mobile = values[Constants.FieldId.PHONE]
            }
            if (trueContent) {
                variables.summary = trueContent
            }
            if (values[Constants.FieldId.EDUCATION]) {
                variables.education = values[Constants.FieldId.EDUCATION]
            }
            if (this.state.cv) {
                variables.upload_cv = this.state.cv;
            }
            if (this.state.otherDoc.length) {
                variables.other_document = this.state.otherDoc
            }
            updateProfile({
                variables: variables
            })
        }
    };

    onFocus = (id) => {
        let errors = {...this.state.errors};
        errors[id] = null;
        this.setState({
            errors
        })
    };

    renderInput = (item, index) => {
        const {errors, values} = this.state;
        console.log(values[item.id], item.id)
        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            font_medium: true,
                        }}
                        editable={item.editable}
                        disabled={item.editable}
                        onChange={(text) => this.handleChangeText(text, item.id)}
                        onFocus={() => this.onFocus(item.id)}
                        error={errors[item.id]}
                        value={values[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    myProfilechangedpassword = () => {
        if (IS_WEB) {
            this.props.history.push(`${Routes.ChangePassword}`)
        } else {
            this.props.navigation.navigate("ChangePassword");
        }
    };
    _renderagentDetails = () => {
        return (
            <View>
                <View style={styles.topContainer}>

                    <View style={styles.labeliconContainer}>
                        <Label large
                               font_medium
                               mt={15}
                               mb={8}
                               align={'left'}>{Strings.profilePicture}</Label>
                        <Mutation mutation={CHANGE_PROFILE_PIC}
                                  onCompleted={(data) => {
                                      let user = {...this.props.user};
                                      user.profile_image = data.change_profile_pic;
                                      this.props.setUser(user)

                                  }}>
                            {(uploadImage, {loading}) => {
                                if (loading) {
                                    return (<ActivityIndicator color={Color.PRIMARY}
                                                               style={CommonStyle.loader}/>)
                                }
                                return (
                                    <>
                                        <FilePicker type={FileType.IMAGE}
                                                    isCameraOptionRequired
                                                    rippleContainerBorderRadius={20}
                                                    onFilePicked={(data, file) => {
                                                        if (file.length) {
                                                            uploadImage({
                                                                variables: {
                                                                    file: file[0]
                                                                }
                                                            }).catch(() => {
                                                            })
                                                        }
                                                    }}>

                                            <Icon size={ThemeUtils.fontLarge}
                                                  name={'edit'}
                                                  color={Color.EDIT_ICON}/>
                                        </FilePicker>
                                    </>
                                )
                            }}

                        </Mutation>


                    </View>
                    <View style={styles.labeliconContainer}>

                        <Image style={styles.imageProfile}
                               source={this.props.user?.profile_image ? {uri: `${Constants.APIConfig.STORAGE_URL}${this.props.user?.profile_image}`} : userPlaceholder}/>

                    </View>
                    <CustomButton
                        mt={20}
                        mb={18}
                        title={Strings.changePasswordButton}
                        onPress={() => {
                            this.myProfilechangedpassword()
                        }}/>
                </View>


                <Hr/>
                <View style={styles.topContainer}>
                    <View style={styles.centercontainer}>

                        {INPUTS.map(this.renderInput)}
                        {/* {
    id: Constants.FieldId.SUMMARY_ABOUT_AGENT,
    title: Strings.summaryAboutAgent,
    placeholder: Strings.enterSummaryAboutAgent,
    type: 'textArea',
    validationType: 'summaryAboutAgent'
}*/}
                        <View style={styles.titleCont}>
                            <Label small
                                   font_medium
                                   mb={10}
                                   align={'left'}>
                                {Strings.summaryAboutAgent}
                            </Label>

                        </View>
                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            marginVertical: 10,
                            borderRadius: 4
                        }}>
                            <RichTextEditor ref={(r) => this.richtext = r}
                                            placeholder={Strings.enterSummaryAboutAgent}
                                            initialContentHTML={this.state.values[Constants.FieldId.SUMMARY_ABOUT_AGENT]}/>
                            <RichToolbar getEditor={() => this.richtext}/>
                        </View>

                        <View style={styles.titleCont}>
                            <Label small
                                   font_medium
                                   mb={10}
                                   align={'left'}>
                                {Strings.uploadcv}
                            </Label>

                        </View>
                        <FilePicker type={FileType.PDF}
                                    onFilePicked={(data, file) => {
                                        this.setState({
                                            cv: file[0]
                                        })
                                    }}/>

                        {this.state.cv ? (
                            <Attachment name={this.state.cv?.name}
                                        isDeletable
                                        isDownloadable={false}
                                        style={{marginTop: 15}}
                                        onPressDelete={() => {
                                            this.setState({
                                                cv: null
                                            })
                                        }}/>) : null}

                        <Mutation mutation={DELETE_AGENT_CV}
                                  onCompleted={(data) => {
                                      let updatedUser = {...this.props.user};
                                      updatedUser = {...updatedUser, ...data?.delete_cv};
                                      this.props.setUser(updatedUser)
                                  }}
                                  onError={() => {
                                  }}>
                            {(deleteCV) => {
                                return (
                                    <>
                                        {this.props.user?.user_detail?.cv_url && !this.state.cv ? (
                                            <Attachment name={`CV`}
                                                        onPressDelete={() => {
                                                            deleteCV({
                                                                variables: {
                                                                    agentId: this.props.user?.id
                                                                }
                                                            })
                                                        }}
                                                        isDeletable={true}
                                                        isDownloadable
                                                        style={{marginTop: 15}}
                                                        onPressDownload={() => {
                                                            openInNewTab(`${Constants.APIConfig.STORAGE_URL}${this.props.user?.user_detail?.cv_url}`)
                                                        }}/>
                                        ) : null}

                                    </>
                                )
                            }}
                        </Mutation>


                        <View style={styles.titleConts}>
                            <Label small
                                   font_medium
                                   mb={10}
                                   align={'left'}>
                                {Strings.uploadOtherDocuments}
                            </Label>

                        </View>
                        <FilePicker type={FileType.PDF}
                                    multiple
                                    onFilePicked={(data, file) => {
                                        this.setState({
                                            otherDoc: file
                                        })
                                    }}/>
                        {this.state.otherDoc.map((item, index) => {
                            return (
                                <Attachment name={item.name}
                                            isDeletable
                                            isDownloadable={false}
                                            style={{marginTop: 15}}
                                            onPressDelete={() => {
                                                let updatedDoc = this.state.otherDoc.slice()
                                                updatedDoc.splice(index, 1)
                                                this.setState({
                                                    otherDoc: updatedDoc
                                                })
                                            }}/>
                            )
                        })}
                        <Mutation mutation={DELETE_AGENT_DOCUMENT}
                                  onCompleted={(data) => {
                                      let updatedUser = {...this.props.user};
                                      updatedUser = {...updatedUser, ...data?.delete_document};
                                      this.props.setUser(updatedUser)
                                  }}
                                  onError={() => {
                                  }}>
                            {(deleteAgentDoc) => {
                                return (
                                    <>
                                        {this.props.user?.user_document?.map((item, index) => {
                                            return (
                                                <Attachment name={`Doc_${item.id}`}
                                                            isDeletable={true}
                                                            onPressDelete={() => {
                                                                deleteAgentDoc({
                                                                    variables: {
                                                                        agentId: this.props.user?.id,
                                                                        userDocumentId: item?.id
                                                                    }
                                                                })
                                                            }}
                                                            isDownloadable
                                                            style={{marginTop: 15}}
                                                            onPressDownload={() => {
                                                                openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item?.doc_url}`)
                                                            }}/>
                                            )
                                        })
                                        }
                                    </>
                                )
                            }}

                        </Mutation>

                        <Mutation mutation={UPDATE_AGENT_PROFILE}
                                  onCompleted={(data) => {
                                      let updatedUser = {...this.props.user};
                                      updatedUser = {...updatedUser, ...data?.update_agent};
                                      this.setState({
                                          cv: null,
                                          otherDoc: []
                                      })
                                      this.props.setUser(updatedUser)
                                      showMessage("Profilen ble oppdatert", Constants.MessageType.SUCCESS)
                                  }}>
                            {(updateProfile, {loading}) => (
                                <CustomButton loading={loading}
                                              font_medium
                                              mt={20}
                                              mb={20}
                                              onPress={() => this.updateProfile(updateProfile)}
                                              mr={ThemeUtils.relativeWidth(1.5)}
                                              title={Strings.saveButton}/>
                            )}

                        </Mutation>


                    </View>


                </View>
            </View>


        );
    }

    renderFeedbackItem = ({item, index}) => {
        return (
            <>
                <Ripple style={styles.feedBackCont}
                        onPress={() => {
                            this.toggleFeedbackModal();
                            this.setState({
                                feedBackForEdit: item,
                                feedBack: item.feedback,
                                givenBy: item.given_by,
                                rating:item.rating
                            })
                        }}>

                    <View style={styles.feedBackLblCont}>
                        <View style={CommonStyle.flex}>
                            <StarRating disabled
                                        rating={item.rating}/>
                            <Label style={CommonStyle.flex}
                                   mt={10}
                                   small>
                                {item.feedback}
                            </Label>

                        </View>
                        <Icon name={'edit'}
                              style={styles.icEdit}
                              color={Color.PRIMARY}
                              size={ThemeUtils.fontLarge}/>
                    </View>
                    <Label font_medium>
                        {item.given_by}
                    </Label>

                </Ripple>
                <Hr/>
            </>
        )
    }

    renderFeedBackModal = () => {
        return (

            <Modal visible={this.state.addFeedbackVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => this.toggleFeedbackModal()}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Mutation mutation={DELETE_FEEDBACK}
                                  refetchQueries={[{
                                      query: AGENT_DETAILS,
                                      variables: {
                                          id: this.props.user.id,
                                      }
                                  }]}
                                  onCompleted={() => {
                                      this.setState({
                                          feedBackForEdit: null,
                                          feedBack: "",
                                          givenBy: "",
                                      })
                                      this.toggleFeedbackModal()
                                  }}>
                            {(deleteFeedBack, {loading}) => (
                                <Header onPressLeft={this.toggleFeedbackModal}
                                        headerRightFirst={this.state.feedBackForEdit ? 'delete' : null}
                                        onPressRightFirst={() => {
                                            if (!loading) {
                                                deleteFeedBack({
                                                    variables: {
                                                        id: this.state.feedBackForEdit?.id,
                                                        user_id: this.props.user?.id
                                                    }
                                                })
                                            }

                                        }}
                                        initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                        animatedTitle={this.state.feedBackForEdit ? Strings.editFeedBack : Strings.addFeedBack}
                                        animatedValue={this.scrollY}
                                />
                            )}

                        </Mutation>
                        <Animated.ScrollView contentContainerStyle={{
                            marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
                        }}
                                             keyboardShouldPersistTaps={'always'}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                                 {useNativeDriver: true})}>

                            <View style={styles.ratingContainer}>
                                <StarRatingInput rating={this.state.feedBackForEdit?.rating}
                                                 onStarSelected={(rate) => {
                                                     this.setState({
                                                         rating: rate
                                                     })
                                                 }}/>
                            </View>
                            <InputField textArea
                                        mt={15}
                                        labelProps={{
                                            small: true,
                                            font_medium: true
                                        }}
                                        value={this.state.feedBack}
                                        onChange={(text) => {
                                            this.setState({
                                                feedBack: text
                                            })
                                        }}
                                        labelText={Strings.feedback}
                                        placeholder={Strings.writeFeedBack}/>
                            <InputField type={'text'}
                                        labelText={Strings.feedbackGivenBy}
                                        labelProps={{
                                            small: true,
                                            font_medium: true
                                        }}
                                        value={this.state.givenBy}
                                        onChange={(text) => {
                                            this.setState({
                                                givenBy: text
                                            })
                                        }}
                                        placeholder={Strings.enterName}/>
                            <View style={styles.btnCont}>
                                <Mutation mutation={this.state.feedBackForEdit ? EDIT_FEEDBACK : ADD_FEEDBACK}
                                          refetchQueries={[{
                                              query: AGENT_DETAILS,
                                              variables: {
                                                  id: this.props.user.id,
                                              }
                                          }]}
                                          onCompleted={() => {
                                              this.setState({
                                                  feedBackForEdit: null,
                                                  feedBack: "",
                                                  givenBy: "",
                                              })
                                              this.toggleFeedbackModal()
                                          }}>
                                    {(addFeedBack, {loading}) => (
                                        <CustomButton
                                            title={this.state.feedBackForEdit ? Strings.updateButton : Strings.submit}
                                            loading={loading}
                                            onPress={() => {
                                                if (!IS_WEB) {
                                                    Keyboard.dismiss()
                                                }
                                                if (this.state.givenBy && this.state.feedBack && this.state.rating) {
                                                    let variables = {
                                                        agent_id: parseInt(this.props.user?.id),
                                                        rating: this.state.rating,
                                                        feedback: this.state.feedBack,
                                                        given_by: this.state.givenBy,
                                                    }
                                                    if (this.state.feedBackForEdit) {
                                                        variables.id = this.state.feedBackForEdit.id
                                                    } else {
                                                        variables = {
                                                            input: variables
                                                        }
                                                    }
                                                    addFeedBack({
                                                        variables: variables
                                                    })
                                                }
                                            }}
                                            mr={10}
                                            style={{flex: 1}}/>
                                    )}

                                </Mutation>
                                <CustomButton title={Strings.cancel}
                                              style={{flex: 1}}
                                              ml={10}
                                              borderWidth={1}
                                              borderColor={Color.PRIMARY}
                                              bgColor={Color.WHITE}
                                              textColor={Color.PRIMARY}
                                              onPress={this.toggleFeedbackModal}/>
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    _renderFeedback = () => {
        return (
            <View>
                {this.renderFeedBackModal()}
                <Query query={AGENT_DETAILS}
                       fetchPolicy={'network-only'}
                       onCompleted={(data) => {
                           let user = {...this.props.user, ...data.agent_detail};
                           this.props.setUser(user)
                       }}
                       variables={{
                           id: this.props.user.id,
                       }}>
                    {({loading, data, error}) => {
                        if (loading && !data) {
                            return <ActivityIndicator color={Color.PRIMARY}
                                                      style={CommonStyle.loader}/>
                        }
                        return (
                            <View>
                                <CustomButton mt={15}
                                              onPress={this.toggleFeedbackModal}
                                              ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              title={Strings.addFeedBack}/>
                                {data?.agent_detail?.feedback?.length ? (
                                    <Label small
                                           font_medium
                                           mt={15}
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                        {Strings.allFeedback}
                                    </Label>) : null}
                                <FlatList data={data?.agent_detail?.feedback ?? []}
                                          renderItem={this.renderFeedbackItem}/>
                            </View>
                        )
                    }}
                </Query>
            </View>
        )
    }

    renderMediaItem = ({item, index}) => {
        return (
            <>
                <Ripple style={styles.feedBackCont}
                        onPress={() => {
                            this.toggleMediaModal();
                            this.setState({
                                mediaForEdit: item,
                                title: item.title,
                                redirectionUrl: item.redirect_url
                            })
                        }}>
                    <View style={styles.mediaCont}>
                        <Image style={styles.mediaImg}
                               source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.media_url}`}}/>
                        <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               style={CommonStyle.flex}>
                            {item.title}
                        </Label>
                    </View>

                </Ripple>
                <Hr/>
            </>
        )
    }

    renderAddMediaModal = () => {
        return (
            <Modal visible={this.state.addMediaVisible}
                   hardwareAccelerated
                   onRequestClose={() => this.toggleMediaModal()}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Mutation mutation={DELETE_AGENT_MEDIA}
                                  refetchQueries={[{
                                      query: AGENT_MEDIA,
                                      variables: {
                                          id: this.props.user.id,
                                      }
                                  }]}
                                  onCompleted={() => {
                                      this.setState({
                                          mediaForEdit: null,
                                          title: "",
                                          files: [],
                                          redirectionUrl: "",
                                      })
                                      this.toggleMediaModal()
                                  }}>
                            {(deleteMedia, {loading}) => (
                                <Header onPressLeft={this.toggleMediaModal}
                                        headerRightFirst={this.state.mediaForEdit ? 'delete' : null}
                                        onPressRightFirst={() => {
                                            if (!loading) {
                                                deleteMedia({
                                                    variables: {
                                                        id: this.state.mediaForEdit?.id,
                                                        userId: this.props.user?.id
                                                    }
                                                })
                                            }
                                        }}
                                        initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                        animatedTitle={this.state.mediaForEdit ? Strings.editMedia : Strings.addMedia}
                                        animatedValue={this.scrollY}
                                />
                            )}

                        </Mutation>
                        <Animated.ScrollView contentContainerStyle={{
                            marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
                        }}
                                             keyboardShouldPersistTaps={'always'}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                                 {useNativeDriver: true})}>

                            <Label small
                                   font_medium
                                   mt={ThemeUtils.relativeRealHeight(6)}
                                   mb={15}>
                                {Strings.upload}
                            </Label>
                            <FilePicker type={FileType.IMAGE}
                                        onFilePicked={(data, file) => {
                                            if (file.length) {
                                                this.setState({
                                                    files: file,
                                                    fileUrl: IS_WEB ? URL.createObjectURL(file[0]) : file[0].uri
                                                })
                                            }
                                        }}
                                        style={{alignSelf: 'flex-start'}}>
                                {this.state.files.length ? (
                                    <Image source={IS_WEB ? this.state.fileUrl : {uri: this.state.fileUrl}}
                                           style={styles.img}/>
                                ) : this.state.mediaForEdit ? (
                                    <Image style={styles.img}
                                           source={{uri: `${Constants.APIConfig.STORAGE_URL}${this.state.mediaForEdit?.media_url}`}}/>
                                ) : (
                                    <View style={styles.SubViewIcon}>
                                        <Icon name={'add'}
                                              size={ThemeUtils.responsiveScale(60)}
                                              color={Color.INPUT_BORDER}/>
                                    </View>)}
                            </FilePicker>
                            <InputField textArea
                                        mt={15}
                                        labelProps={{
                                            small: true,
                                            font_medium: true
                                        }}
                                        error={this.state.errTitle}
                                        value={this.state.title}
                                        onChange={(text) => {
                                            this.setState({
                                                title: text
                                            })
                                        }}
                                        labelText={Strings.title}
                                        placeholder={Strings.title}/>
                            <InputField type={'text'}
                                        labelText={Strings.redirectionUrl}
                                        labelProps={{
                                            small: true,
                                            font_medium: true
                                        }}
                                        error={this.state.errUrl}
                                        value={this.state.redirectionUrl}
                                        onChange={(text) => {
                                            this.setState({
                                                redirectionUrl: text
                                            })
                                        }}
                                        placeholder={Strings.redirectionUrl}/>
                            <View style={styles.btnCont}>
                                <Mutation mutation={this.state.mediaForEdit ? EDIT_AGENT_MEDIA : ADD_AGENT_MEDIA}
                                          refetchQueries={[{
                                              query: AGENT_MEDIA,
                                              variables: {
                                                  id: this.props.user.id,
                                              }
                                          }]}
                                          onCompleted={() => {
                                              this.setState({
                                                  mediaForEdit: null,
                                                  title: "",
                                                  files: [],
                                                  redirectionUrl: "",
                                              })
                                              this.toggleMediaModal()
                                          }}>
                                    {(addMedia, {loading}) => (
                                        <CustomButton
                                            title={this.state.mediaForEdit ? Strings.updateButton : Strings.submit}
                                            loading={loading}
                                            onPress={() => {
                                                this.setState({
                                                    errTitle: validation('name', this.state.title),
                                                    errUrl: validation('link', this.state.redirectionUrl?.toLowerCase())
                                                }, () => {
                                                    if (!this.state.errTitle && !this.state.errUrl) {
                                                        let variables = {
                                                            userId: parseInt(this.props.user?.id),
                                                            image: this.state.files[0],
                                                            title: this.state.title,
                                                            url: this.state.redirectionUrl,
                                                        }
                                                        if (this.state.mediaForEdit) {
                                                            variables.id = this.state.mediaForEdit.id
                                                        }
                                                        addMedia({
                                                            variables: variables
                                                        })
                                                    }
                                                })
                                            }}
                                            mr={10}
                                            style={{flex: 1}}/>
                                    )}

                                </Mutation>
                                <CustomButton title={Strings.cancel}
                                              style={{flex: 1}}
                                              ml={10}
                                              borderWidth={1}
                                              borderColor={Color.PRIMARY}
                                              bgColor={Color.WHITE}
                                              textColor={Color.PRIMARY}
                                              onPress={this.toggleMediaModal}/>
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    _renderMedia = () => {
        return (
            <View>
                {this.renderAddMediaModal()}
                <Query query={AGENT_MEDIA}
                       variables={{
                           id: this.props.user.id,
                       }}>
                    {({loading, data, error}) => {
                        if (loading && !data) {
                            return <ActivityIndicator color={Color.PRIMARY}
                                                      style={CommonStyle.loader}/>
                        }
                        return (
                            <View>

                                <CustomButton mt={15}
                                              onPress={this.toggleMediaModal}
                                              ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              title={Strings.addMedia}/>

                                {data?.agent_media_list?.length ? (
                                    <Label small
                                           font_medium
                                           mt={15}
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                                        {Strings.allMedia}
                                    </Label>) : null}
                                <FlatList data={data?.agent_media_list ?? []}
                                          renderItem={this.renderMediaItem}/>
                            </View>
                        )
                    }}
                </Query>
            </View>
        )
    }

    render() {
        return (
            <Provider locale={enUS}>
                <Header tintColor={Color.WHITE}
                        navigation={IS_WEB ? this.props.history : this.props.navigation}/>
                <ScrollView contentContainerStyle={{flexGrow: 1}}
                            keyboardShouldPersistTaps={'always'}>

                    <View style={styles.container}>

                        <IndicatorViewPager
                            indicator={this._renderTabIndicator()}
                            style={{flex: 1, backgroundColor: 'white'}}>

                            <View>
                                <Animated.ScrollView
                                    keyboardShouldPersistTaps={'always'}>
                                    {this._renderagentDetails()}
                                </Animated.ScrollView>
                            </View>


                            <View>
                                <Animated.ScrollView
                                    keyboardShouldPersistTaps={'always'}>
                                    {this._renderFeedback()}
                                </Animated.ScrollView>
                            </View>
                            <View>
                                <Animated.ScrollView
                                    keyboardShouldPersistTaps={'always'}>
                                    {this._renderMedia()}
                                </Animated.ScrollView>
                            </View>

                        </IndicatorViewPager>
                    </View>


                </ScrollView>
            </Provider>
        );
    }


}

const mapStateToProps = (state) => {
    console.log(state.user)
    return {
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
