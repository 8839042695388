import React from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, Image, View} from 'react-native';
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
import moment from "moment";
//Network
import {useQuery} from 'react-apollo';
import {FAGLIG_LIST} from 'components/src/api/query';
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import HtmlView from "../../../../components/view/HTMLView";


export default function Professional(props) {
    const {navigation, history} = props;
    const scrollY = new Animated.Value(0);

    const {loading, error, data, fetchMore} = useQuery(FAGLIG_LIST, {
        variables: {
            first: 30,
            page: 1,
            order: [{field: "id", order: "DESC"}]
        }
    })


    const navigate = (faglig) => {
        if (IS_WEB) {
            history.push(`/${Routes.Professional}/${Routes.ProfessionalDetails}/${encryptId(faglig.id)}`, {
                faglig: faglig
            })
        } else {
            navigation.navigate(Routes.ProfessionalDetails, {
                faglig: faglig
            })
        }

    };

    const renderNews = ({item, index}) => {
        return (
            <View key={index.toString()}>
                <Hr/>
                <Ripple style={styles.newsCont}
                        onPress={() => navigate(item)}>
                    <View style={styles.imgCont}>
                        {item.cover_image_url ? (
                            <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.cover_image_url}`}}
                                   style={styles.imgNews}/>) : null}
                        <Label small
                               ml={item.cover_image_url ? 15 : 0}
                               style={CommonStyle.flex}
                               font_medium>
                            {item.title}
                        </Label>
                    </View>

                    <View style={{marginVertical: 10}}>
                        <HtmlView value={item.description}/>
                    </View>
                    <Label small
                           mt={5}
                           color={Color.SECONDARY_TEXT_COLOR}
                           numberOfLines={2}>
                        {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.dd_mm_yyyy_hh_mm)}
                    </Label>
                </Ripple>
                {index === data?.professionals?.data?.length - 1 && data?.paginatorInfo?.hasMorePages ? (
                    <ActivityIndicator color={Color.PRIMARY}/>
                ) : null}
            </View>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.professional}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <View style={styles.cont}>
                        <Icon name={'experienced-real-estate-agent'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.PRIMARY}/>

                        <Label small
                               mb={15}
                               mt={15}>
                            {'Her finner du siste nytt relatert til juridiske og faglige temaer  fra W Eiendomsmegling.'}
                        </Label>
                    </View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (
                        <FlatList data={data?.professionals?.data ?? []}
                                  renderItem={renderNews}
                                  ListEmptyComponent={
                                      <Label mt={20}
                                             font_medium
                                             align={'center'}>
                                          {"Ingen data"}
                                      </Label>
                                  }
                                  onEndReached={(info) => {
                                      if (data?.professionals?.paginatorInfo?.hasMorePages && !loading) {
                                          fetchMore({
                                              variables: {
                                                  page: (data?.professionals?.paginatorInfo?.currentPage ?? 0) + 1,
                                              },
                                              updateQuery: (prev, {fetchMoreResult}) => {
                                                  if (!fetchMoreResult) {
                                                      return prev;
                                                  } else {
                                                      let finalResult = Object.assign({}, fetchMoreResult);
                                                      finalResult.professionals.data = [...prev.professionals.data, ...fetchMoreResult.professionals.data];
                                                      return finalResult;
                                                  }
                                              },
                                          });
                                      }
                                  }}
                                  onEndReachedThreshold={0.2}
                                  keyExtractor={item => item.id.toString()}
                        />
                    )}

                </View>
            </Animated.ScrollView>
        </View>
    )
}
