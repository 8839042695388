import React, {useState} from 'react'
//Components
import {Animated, View} from 'react-native'
import {CheckBox, CustomButton, CustomTextArea, Dialog, Header, Label, Ripple} from "components/src/components";
//Utils
import {Color, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {useMutation} from "@apollo/react-hooks";
import {
    SEND_24_HRS_NOTIFICATION,
    SEND_48_HRS_NOTIFICATION,
    SEND_AGENT_NOTIFICATION,
    SEND_CUSTOM_NOTIFICATION
} from "../../../../../../api/mutation";


const POINTS = [{
    title: Strings.sendNotiAfter48HrsLogin,
    /*BookInspection,EnergyLabeling,SellerInterview,PhotoOrder,TakstOrder*/
    steps: [{
        name: Strings.energyLabeling,
        value: 'EnergyLabeling',
    }, {
        name: Strings.sellersInterview,
        value: 'SellersInterview',
    }, {
        name: Strings.photo,
        value: 'PhotoOrder',
    }, {
        name: Strings.takst,
        value: 'TakstOrder',
    }]
}, {
    title: Strings.sendNotiAfter24HrsPosting,
    /*ApproveDS,FinnNo,MarketingMaterial,SettlementInfo*/
    steps: [{
        name: Strings.approveDS,
        value: "ApproveDS",
    }, {
        name: Strings.finnNo,
        value: "FinnNo",
    }, {
        name: Strings.marketingMaterials,
        value: "MarketingMaterial",
    }, {
        name: Strings.settlementInfo,
        value: "SettlementInfo",
    }]
}, {
    title: Strings.otherReminder,
    /*TypeOne, TypeTwo*/
    steps: [{
        name: 'Påminnelse om boliglån',
        value: 'TypeOne'
    }, {
        name: 'Påminnelse om boliglån 2',
        value: 'TypeTwo',
    }]
}];

const HRS_48 = 0
const HRS_24 = 1
const OTHER = 2
const BUYER = "BUYER"
const SELLER = "SELLER"
const BOTH = "BOTH"

export default function StatusCheckpoint(props) {

    const {history, navigation} = props;
    const [notifyUsers, setUsers] = useState([]);

    const [step, setStep] = useState('');

    const [type, setType] = useState(HRS_48)

    const propertyId = IS_WEB ? history.location?.state?.id : navigation.getParam('id');

    const [visible, setDialogVisible] = useState(false);
    const [desc, setDesc] = useState("");

    const scrollY = new Animated.Value(0);

    const [sendNotification, {loading}] = useMutation(SEND_CUSTOM_NOTIFICATION);

    const [send48HrsNotification] = useMutation(SEND_48_HRS_NOTIFICATION);
    const [send24HrsNotification] = useMutation(SEND_24_HRS_NOTIFICATION);
    const [sendAgentNotification] = useMutation(SEND_AGENT_NOTIFICATION);

    const prepareNotification = () => {
        sendNotification({
            variables: {
                propertyId: propertyId,
                desc: desc,
                sendTo: notifyUsers
            }
        }).then(() => {
            IS_WEB ? history.replace(Routes.NotificationSuccess) : navigation.navigate(Routes.NotificationSuccess)
        }).catch(() => {

        })

    }

    const sendTypeNotification = () => {
        switch (type) {
            case HRS_48:
                send48HrsNotification({
                    variables: {
                        propertyId: propertyId,
                        notifyType: step
                    }
                }).then(() => {
                    IS_WEB ? history.replace(Routes.NotificationSuccess) : navigation.navigate(Routes.NotificationSuccess)
                }).catch(() => {

                })
                break;
            case HRS_24:
                send24HrsNotification({
                    variables: {
                        propertyId: propertyId,
                        notifyType: step
                    }
                }).then(() => {
                    IS_WEB ? history.replace(Routes.NotificationSuccess) : navigation.navigate(Routes.NotificationSuccess)
                }).catch(() => {

                })
                break;
            case OTHER:
                sendAgentNotification({
                    variables: {
                        propertyId: propertyId,
                        notifyType: step
                    }
                }).then(() => {
                    IS_WEB ? history.replace(Routes.NotificationSuccess) : navigation.navigate(Routes.NotificationSuccess)
                }).catch(() => {

                })

                break
        }
    }
    const renderPushSteps = (item, index, parentIndex) => {
        return (
            <Ripple key={index.toString()}
                    rippleContainerBorderRadius={4}
                    onPress={() => {
                        setStep(item.value);
                        setType(parentIndex)
                        setDialogVisible(true)
                    }}
                    style={styles.steps}>
                <Label small
                       font_medium
                       align={'center'}>
                    {item.name}
                </Label>
            </Ripple>
        )
    }
    const renderSteps = (item, parentIndex) => {
        return (
            <View key={parentIndex.toString()}>
                <Label small
                       mt={15}
                       mb={10}
                       font_medium>
                    {item.title}
                </Label>
                <View style={styles.stepCont}>
                    {item.steps.map((item, index) => renderPushSteps(item, index, parentIndex))}
                </View>
            </View>
        )
    };

    return (
        <View style={styles.cont}>
            <Header animatedTitle={"Push-varsel "}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Dialog visible={visible}
                    onNegativePress={() => setDialogVisible(false)}
                    onPositivePress={() => {
                        sendTypeNotification();
                        setDialogVisible(false)
                    }}
                    title={`${Strings.rememberTo} ${step}`}
                    desc={Strings.areYouSureYouWantToSend}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.scrollCont}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <Icon name={'notifications_moovin'}
                          size={ThemeUtils.fontXXLarge}
                          color={Color.PRIMARY}/>
                    <Label small
                           mt={15}>
                        {'Selger og kjøper får automatiske påminnelser og meldinger etter som salgsprosessen går sin gang. Men har kan du som megler enkelt sende ut en påminnelse til dine kunder ved et tastetrykk.'}
                    </Label>
                    {POINTS.map(renderSteps)}
                    <CustomTextArea labelText={Strings.pushNotificationDesc}
                                    onChange={setDesc}
                                    value={desc}
                                    placeholder={Strings.writeNotiHere}
                                    mt={15}/>

                    <Label small
                           font_medium
                           mt={15}
                           mb={10}>
                        {Strings.sendNotiTo}
                    </Label>
                    <CheckBox options={[{name: Strings.seller, value: SELLER}, {
                        name: Strings.buyer, value: BUYER
                    }]}
                              style={{marginVertical: 5}}
                              onCheckChanged={(val) => {
                                  setUsers(val)
                              }}/>
                    <CustomButton mt={15}
                                  mb={10}
                                  loading={loading}
                                  onPress={prepareNotification}
                                  title={Strings.send}/>
                </View>
            </Animated.ScrollView>
        </View>
    )
}
