import React, {useEffect, useState} from "react";
import BefaringLayout from "../../../layouts/BefaringLayout/index";
import {Button, Carousel, Col, Row, Spin, Tag, Timeline, Typography,} from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import BGImage from "./../../../../assets/befaring/bg_Image.jpg";
import Befaringsbekreftelse from "./../../../../assets/befaring/befarings_bekreftelse.jpg";
import "./before-befaring.scss";
import {formatDisplayDate, getDecryptedId} from "../../../utils/utils";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import {useQuery} from "react-apollo";
import {SEND_OFFER_DETAIL, TIMELINE_LIST} from "../../../../query/AgentQuery";
// import summeryImage from "../../../../assets/after_befaring/sammendrag-fra-befaring.png";
// import inspectionDetailsImage from "../../../../assets/after_befaring/introduksjon.png";
// import aboutWImage from "../../../../assets/after_befaring/omW.png";
// import aboutAgentImage from "../../../../assets/after_befaring/om-megler.png";
// import offerImage from "../../../../assets/after_befaring/oppdragskostnader.png";
// import preparationImage from "../../../../assets/after_befaring/forberedelser-til-visning.png";
// import questionToAskImage from "../../../../assets/after_befaring/questintoask.png";
import {STORAGE_URL} from "../../../utils/constant";
import Strings from "../../../../locales/nb-NO";
import MouseScrollImage from "../../../../assets/mouse-scroll.svg";


import {
    default as aboutWImage,
    default as preparationImage,
    default as questionToAskImage,
} from '../../../../assets/after_befaring/img1.jpg';
import {
    default as aboutAgentImage,
    default as inspectionDetailsImage,
    default as offerImage,
    default as summeryImage,
} from '../../../../assets/after_befaring/img2.jpg';

const formatMessage = ({id}) => {
    return Strings[id];
};
const {Title, Paragraph} = Typography;
const mainSliderProps = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: true,
    centerMode: false,
    vertical: true,
};

const thumbnailProps = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    swipeToSlide: true,
    slidesToScroll: 1,
    // default: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                arrows: false,

                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 600,
            settings: {
                arrows: false,

                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,

                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

function Befaring(props) {
    let router = props.history;
    let propertyId;
    if (props && props.match && props.match.params) {
        propertyId = getDecryptedId(props.match.params.propertyId);
    } else {
        // router.push('/');
    }
    if (propertyId === "error") {
        // router.push('/');
    }

    let carousel = React.createRef();
    let carousel1 = React.createRef();
    let statsRef = React.createRef();

    const [isDesktop, setIsDesktop] = useState(false);
    const [moreLink1, setMoreLink1] = useState(true);
    const [moreLink2, setMoreLink2] = useState(true);
    const [offerData, setOfferData] = useState();
    const [isOfferSent, setOfferSent] = useState(false);
    const [dataArray, setDataArray] = useState([]);
    const [timeline, setTimeline] = useState([]);
    const [productGallery, setProductGallery] = useState([]);
    const [firstCarouselIndex, setFirstCarouselIndex] = useState(0);
    const [secondCarouselIndex, setSecondCarouselIndex] = useState(0);
    const [isError, setError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const offerDetailQuery = useQuery(SEND_OFFER_DETAIL, {
        variables: {id: propertyId},
    });

    const timelineListQuery = useQuery(TIMELINE_LIST, {
        variables: {id: propertyId, status: 1},
    });

    useEffect(() => {
        setIsDesktop(window.innerWidth > 991.98);
        window.addEventListener("resize", setIsDesktop);
        AOS.init({
            duration: 800,
            easing: "ease-in",
            //   once: true,
        });
        console.log("state", firstCarouselIndex);
    });

    const handleScrollToStats = () => {
        window.scrollTo({
            top: statsRef.current.offsetTop,
            behavior: "smooth",
        });
        console.log("inside:", statsRef.current.offsetTop);
    };

    useEffect(() => {
        if (offerDetailQuery.data && !offerDetailQuery.loading) {
            console.log('Data from APIiiii====>>>', offerDetailQuery.data);
            let isOffer = !!(offerDetailQuery.data?.send_offer_detail &&
                offerDetailQuery.data?.send_offer_detail.sendOffer && offerDetailQuery.data?.send_offer_detail.sendOffer.is_offer_email)
            console.log('isOffer', isOffer);
            setOfferSent(isOffer);
            setOfferData(offerDetailQuery.data?.send_offer_detail);
            let isPersonalTemplate =
                offerDetailQuery.data?.send_offer_detail?.property_inspection?.template_type === 'PERSONAL';
            if (isPersonalTemplate) {
                if (isOffer) {
                    setProductGallery(
                        offerDetailQuery.data?.send_offer_detail?.agent?.agentAfterProductGallery
                            ?.AgentAfterGalleryMedia?.length > 0
                            ? offerDetailQuery.data?.send_offer_detail?.agent?.agentAfterProductGallery
                                ?.AgentAfterGalleryMedia
                            : [],
                    );
                } else {
                    setProductGallery(
                        offerDetailQuery.data?.send_offer_detail?.agent?.agentProductGallery
                            ?.AgentProductGalleryImages?.length
                            ? offerDetailQuery.data?.send_offer_detail?.agent?.agentProductGallery
                                ?.AgentProductGalleryImages
                            : [],
                    );
                }
            } else {
                if (isOffer) {
                    setProductGallery(
                        offerDetailQuery.data?.send_offer_detail?.office?.after_product_gallery
                            ?.AfterProductGalleryImages?.length > 0
                            ? offerDetailQuery.data?.send_offer_detail?.office?.after_product_gallery
                                ?.AfterProductGalleryImages
                            : [],
                    );
                } else {
                    setProductGallery(
                        offerDetailQuery.data?.send_offer_detail?.office?.product_gallery?.ProductGalleryImages
                            ?.length > 0
                            ? offerDetailQuery.data?.send_offer_detail?.office?.product_gallery
                                ?.ProductGalleryImages
                            : [],
                    );
                }
            }

            makeDisplayArray(offerDetailQuery.data?.send_offer_detail, isOffer);
        }
        if (offerDetailQuery.error) {
            setError(true);
        }
    }, [offerDetailQuery.data]);

    useEffect(() => {
        if (timelineListQuery.data && !timelineListQuery.loading) {
            const timelineData = timelineListQuery.data?.inspection_timeline_list?.filter(
                (item) => item.completed_date
            );
            timelineData?.sort(
                (a, b) =>
                    moment(a.completed_date).format("X") -
                    moment(b.completed_date).format("X")
            );
            console.log("Response=====>>>>>", timelineData);
            setTimeline(timelineData);
        }
        if (timelineListQuery.error) {
            setError(true);
        }
    }, [timelineListQuery.data]);

    const makeDisplayArray = (sendOfferDetail, isOfferSent) => {
        console.log('Offer Data from API==>', sendOfferDetail, isOfferSent);
        const displayArray = [];
        // FOR point 01
        if (isOfferSent) {
            let firstObject = {
                id: 1,
                displayNumber:"01",
                title: sendOfferDetail?.sendOffer?.headline,
                description: sendOfferDetail?.sendOffer?.description,
                date: '',
                agentName: '',
                agentEducation: '',
                isViewMore: false,
                image: summeryImage,
                viewMorePath: '',
                section: 'sammendrag-fra-befaring',
            };
            displayArray.push(firstObject);
        } else {
            const agentData = sendOfferDetail?.agent?.agentBefaringsbekreftelse
                ? sendOfferDetail?.agent?.agentBefaringsbekreftelse
                : sendOfferDetail?.office?.befaringsbekreftelse;
            const propertyInspection = sendOfferDetail?.property_inspection;
            if (agentData) {
                let firstObject = {
                    id: 1,
                    displayNumber:"01",
                    title: 'Befaringsbekreftelse',
                    description: agentData?.description,
                    date: propertyInspection.start_date || '',
                    time: propertyInspection.start_time || '',
                    agentName: '',
                    agentEducation: '',
                    isViewMore: false,
                    image: inspectionDetailsImage,
                    viewMorePath: '',
                    section: 'befaringsbekreftelse',
                };
                displayArray.push(firstObject);
            }
        }

        //For second point 02
        if (sendOfferDetail?.office && sendOfferDetail?.office?.about_w) {
            let aboutW = sendOfferDetail.office?.about_w;
            let secondObject = {
                id: 2,
                displayNumber:"02",
                title: aboutW.headline,
                description: aboutW?.description,
                date: '',
                time: '',
                agentName: '',
                agentEducation: '',
                isViewMore: false,
                image: `${STORAGE_URL}${aboutW.image}` || aboutWImage,
                viewMorePath: '',
                section: 'om-w',
            };
            displayArray.push(secondObject);
        }

        //For third point 03
        if (sendOfferDetail?.agent) {
            let agent = sendOfferDetail?.agent;
            let thirdObject = {
                id: 3,
                displayNumber:"03",
                title: 'Din Eiendomsmegler',
                description: agent?.user_detail?.about,
                date: '',
                time: '',
                agentName: `${agent?.first_name} ${agent?.last_name}`,
                agentEducation: agent?.user_detail?.education,
                viewMoreButtonName: 'Les mer',
                isViewMore: true,
                image: agent.profile_image ? `${STORAGE_URL}${agent.profile_image}` : aboutAgentImage,
                viewMorePath: `/om-agent/${props.match.params.propertyId}`, //TODO: Add route name here
                section: 'om-agent',
            };
            displayArray.push(thirdObject);
        }

        // For fourth point 04
        if (isOfferSent) {
            //Offer Here
            let fourthObject = {
                id: 4,
                displayNumber:"04",
                title: 'Tilbud på salg',
                //TODO: Replace the description for OFFER below
                description:
                    'Klikk under for å se over tilbudet, dersom det skulle være noen spørsmål er det bare til å kontakte megler. Om du ønsker å sette i gang prosessen kan avtalen signeres med BankID direkte herfra.',
                date: '',
                time: '',
                agentName: '',
                agentEducation: '',
                isViewMore: true,
                image: offerImage,
                viewMoreButtonName: 'Se ditt tilbud',
                viewMorePath: `/bypå/${props.match.params.propertyId}`, //TODO: Add route name here
                section: 'bypå',
            };
            displayArray.push(fourthObject);
        } else {
            //Preparation
            if (sendOfferDetail?.office && sendOfferDetail?.office?.preparation) {
                let preparation = sendOfferDetail?.office?.preparation;
                let fourthObject = {
                    id: 4,
                    displayNumber:"04",
                    title:
                        preparation?.headline || "Forberedelser til befaring",
                    description: preparation?.description || '',
                    date: '',
                    time: '',
                    agentName: '',
                    agentEducation: '',
                    isViewMore: false,
                    image: `${STORAGE_URL}${preparation?.image}` || preparationImage,
                    viewMorePath: '',
                    section: 'forberedelser',
                };
                displayArray.push(fourthObject);
            }
        }

        //For fifth point 05
        if (!isOfferSent) {
            //Question to ask
            if (sendOfferDetail?.office && sendOfferDetail?.office?.question_to_ask) {
                let questionToAsk = sendOfferDetail?.office?.question_to_ask;
                let fifthObject = {
                    id: 5,
                    displayNumber:"05",
                    title:
                        questionToAsk?.headline ||
                        formatMessage({
                            id: 'component.befaring.questiontoask.title',
                        }),
                    description: questionToAsk?.description || '',
                    date: '',
                    time: '',
                    agentName: '',
                    agentEducation: '',
                    isViewMore: false,
                    image: `${STORAGE_URL}${questionToAsk?.image}` || questionToAskImage,
                    viewMorePath: '',
                    section: 'spørsmål-å-stille',
                };
                displayArray.push(fifthObject);
            }
        }

        setDataArray(displayArray);
    };

    const getDisplayNumber = (number) => {
        if (number.toString().length === 1) {
            return "0" + number;
        } else {
            return number;
        }
    };

    const next = () => {
        carousel.next();
    };

    const previous = () => {
        carousel.prev();
    };

    const next1 = () => {
        carousel1.next();
    };

    const previous1 = () => {
        carousel1.prev();
    };

    const handleClick = index => {
        setIsOpen(true);
        setPhotoIndex(index);
    };

    return (
        <BefaringLayout>
            {offerDetailQuery.loading && !offerDetailQuery.data ? (
                <div className="loader-wrapper">
                    <Spin/>
                </div>
            ) : (
                <>
                    <div
                        className="befaring_overlay"
                        style={{
                            height: "100vh",
                            position: "relative",
                            backgroundImage: `url(${BGImage})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Title className="text-white w-befaring-heading" level={1}>
                            W - EIENDOMSMEGLING
                        </Title>
                        <hr className="heading_line"/>
                        <p
                            className="text-white"
                            style={{fontSize: "15px", marginTop: "1em"}}
                        >
                            {isOfferSent
                                ? `Tilbud på salg av ${offerData?.street_adress}`
                                : `Avtale om befaring av ${offerData?.street_adress}\n${moment(
                                    offerData?.property_inspection?.start_date
                                )
                                    .locale("nb_NO")
                                    .format("dddd, DD.MM")}${
                                    offerData?.property_inspection?.start_time
                                        ? ", kl. " +
                                        moment(
                                            offerData?.property_inspection?.start_time,
                                            "HH:mm:ss"
                                        )
                                            .locale("nb_NO")
                                            .format("HH.mm")
                                        : ""
                                    }`}
                        </p>
                        <div
                            className="text-white"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1em",
                            }}
                        >
                            <a
                                className="hero__scroll-button wow fadeInDown"
                                onClick={handleScrollToStats}
                            >
                <span className="hero__scroll-text">
                  <img
                      className="hero__scroll-icon"
                      src={MouseScrollImage}
                      alt=""
                  />
                  Les mer
                </span>
                                <i className="hero__scroll-bar icon-down-arrow-2"/>
                            </a>
                            {/*  {!isError && (
                                <Row>
                                    <Col md={12} xs={24}>
                                        <Button
                                            type="primary"
                                            className="w-befaring-buttons btn-secondary admin-forms-btn back-button btn-transparent bg-transparent"
                                            size="large"
                                            onClick={() => {
                                                if (isOfferSent) {
                                                    router.push(`/bypå/${props.match.params.propertyId}`);
                                                } else {
                                                    router.push(`/om-agent/${props.match.params.propertyId}`);
                                                }
                                            }}
                                        >
                                            {isOfferSent ? 'Tilbud på salg' : 'Om megler'}
                                        </Button>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Button
                                            type="primary"
                                            className="w-befaring-buttons btn-secondary admin-forms-btn back-button btn-transparent bg-transparent "
                                            size="large"
                                        >
                                            {isOfferSent ? (
                                                <a href="#tidslinje">Din salgsprosess</a>
                                            ) : (
                                                <a href="#produktgalleri">Produktgalleri</a>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            )}*/}
                        </div>
                    </div>
                    <div className="main-content-wrapper" id="intro" ref={statsRef}>
                        <Row type="flex" justify="center" align="middle">
                            <Col xs={24} lg={{span: 20, offset: 2}}>
                                {dataArray?.length > 0 &&
                                dataArray.map((e, index) => (
                                    <React.Fragment key={index}>
                                        {index % 2 === 0 ? (
                                            <Row
                                                type="flex"
                                                justify="space-between"
                                                className="item-row"
                                                id={e.section}
                                                gutter={32}
                                            >
                                                <Col md={12} xs={24} order={1}>
                                                    <div>
                                                        <div
                                                            class="img-responsive img-thumbnail ratio-4-3"
                                                            style={{backgroundImage: `url(${e.image})`}}
                                                            data-aos='fade-up'
                                                        />
                                                        {/* <img src={e.image} alt={e.title} className="img-fluid" /> */}
                                                    </div>
                                                </Col>
                                                <Col md={12} xs={24} order={2}>
                                                    <div
                                                        data-aos="fade-up"
                                                        className="new_befaring_content"
                                                    >
                                                        <Title
                                                            level={2}
                                                            className="text-secondary befaring_headings numberings"
                                                            style={{
                                                                display: "inline-flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <hr className="heading_line"/>
                                                            <span style={{ marginLeft: '0.4em' }}>{e?.displayNumber/*`${getDisplayNumber(
                                  index + 1,
                                )}`*/}</span>
                                                        </Title>
                                                        <Title level={2} className="befaring_headings">
                                                            {e.title}
                                                        </Title>
                                                        {e.date && (
                                                            <Title
                                                                level={3}
                                                                className="text-secondary befaring_headings"
                                                            >
                                                                {`${moment(e.date).format('dddd, DD.MMM')}`}
                                                                {e.time ? `, kl ${moment(e.time, 'HH:mm:ss').format('LT')}` : ''}
                                                            </Title>
                                                        )}
                                                        {e.agentName && (
                                                            <Title level={2} className="befaring_headings">
                                                                {" "}
                                                                {e.agentName}
                                                            </Title>
                                                        )}
                                                        {e.agentEducation && (
                                                            <Title
                                                                level={4}
                                                                className="text-secondary befaring_headings"
                                                            >
                                                                {e.agentEducation}
                                                            </Title>
                                                        )}
                                                        {/* {!e.isViewMore &&
                                (e.description.length > 480 && moreLink1 ? (
                                  <div>
                                    <p
                                      className="text-tenary default-font-style"
                                      dangerouslySetInnerHTML={{
                                        __html: e.description
                                          .replace(/(?:\r\n|\r|\n)/g, '<br />')
                                          .match(/.{1,480}/g)[0],
                                      }}
                                    />
                                    <span
                                      className="morelink"
                                      onClick={() => {
                                        setMoreLink1(false);
                                      }}
                                    >
                                      [ Les mer ]
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <p
                                      className="text-tenary default-font-style"
                                      dangerouslySetInnerHTML={{
                                        __html: e.description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                                      }}
                                    />
                                    <p
                                      className="morelink"
                                      onClick={() => {
                                        setMoreLink1(true);
                                      }}
                                    >
                                      {e.description.length > 480 && `[ Les mindre ]`}
                                    </p>
                                  </div>
                                ))} */}

                                                        {!e.isViewMore && (
                                                            <div>
                                                                <p
                                                                    className="text-tenary default-font-style"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: e.description?.replace(
                                                                            /(?:\r\n|\r|\n)/g,
                                                                            "<br />"
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {e.isViewMore && e.description?.length > 480 && (
                                                            <div className="d-flex">
                                                                <Paragraph ellipsis={{rows: 4}}>
                                    <span
                                        className="text-tenary default-font-style"
                                        dangerouslySetInnerHTML={{
                                            __html: e.description
                                                .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                                .match(/.{1,480}/g)[0],
                                        }}
                                    />{" "}
                                                                </Paragraph>
                                                            </div>
                                                        )}
                                                        {e.isViewMore ? (
                                                            <Button
                                                                style={{borderRadius: "2px"}}
                                                                type="primary"
                                                                className="btn-secondary btn-secondary admin-forms-btn"
                                                                size="large"
                                                                onClick={() => {
                                                                    router.push(`/befaring${e.viewMorePath}`);
                                                                }}
                                                            >
                                                                Mer informasjon &nbsp;
                                                                <i className=" icon-arrow-r"/>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row
                                                gutter={32}
                                                type="flex"
                                                justify="space-between"
                                                className="item-row"
                                                id={e.section}
                                            >
                                                <Col
                                                    md={{span: 12, order: 1}}
                                                    xs={{span: 24, order: 2}}
                                                >
                                                    <div
                                                        className="new_befaring_content"
                                                        data-aos="fade-up"
                                                    >
                                                        <Title
                                                            level={2}
                                                            className="text-secondary befaring_headings numberings"
                                                            style={{
                                                                display: "inline-flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <hr className="heading_line"/>
                                                            <span
                                                                style={{marginLeft: "1em"}}
                                                            >{e?.displayNumber}</span>
                                                        </Title>
                                                        <Title level={1} className="befaring_headings">
                                                            {e.title}
                                                        </Title>
                                                        {e.date && (
                                                            <Title
                                                                level={3}
                                                                className="text-secondary befaring_headings"
                                                            >
                                                                {`${moment(e.date).format('dddd, DD.MMM')}`}
                                                                {e.time ? `, kl ${moment(e.time, 'HH:mm:ss').format('LT')}` : ''}
                                                            </Title>
                                                        )}
                                                        {e.agentName && (
                                                            <Title level={2} className="befaring_headings">
                                                                {" "}
                                                                {e.agentName}
                                                            </Title>
                                                        )}
                                                        {e.agentEducation && (
                                                            <Title
                                                                level={4}
                                                                className="text-secondary befaring_headings"
                                                            >
                                                                {e.agentEducation}
                                                            </Title>
                                                        )}
                                                        {/* {!e.isViewMore &&
                                (e.description.length > 480 && moreLink2 ? (
                                  <div>
                                    <p
                                      className="text-tenary default-font-style"
                                      dangerouslySetInnerHTML={{
                                        __html: e.description
                                          .replace(/(?:\r\n|\r|\n)/g, '<br />')
                                          .match(/.{1,480}/g)[0],
                                      }}
                                    />
                                    <span
                                      className="morelink"
                                      onClick={() => {
                                        setMoreLink2(false);
                                      }}
                                    >
                                      [ Les mer ]
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <p
                                      className="text-tenary default-font-style"
                                      dangerouslySetInnerHTML={{
                                        __html: e.description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                                      }}
                                    />
                                    <p
                                      className="morelink"
                                      onClick={() => {
                                        setMoreLink2(true);
                                      }}
                                    >
                                      {e.description.length > 480 && `[ Les mindre ]`}
                                    </p>
                                  </div>
                                ))} */}
                                                        {!e.isViewMore && (
                                                            <div>
                                                                <p
                                                                    className="text-tenary default-font-style"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: e.description.replace(
                                                                            /(?:\r\n|\r|\n)/g,
                                                                            "<br />"
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        {e.isViewMore && (
                                                            <p
                                                                className="text-tenary default-font-style"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: e.description
                                                                        .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                                                        .match(/.{1,480}/g)[0],
                                                                }}
                                                            />
                                                        )}
                                                        {e.isViewMore ? (
                                                            <Button
                                                                style={{borderRadius: "2px"}}
                                                                type="primary"
                                                                className="btn-secondary btn-secondary admin-forms-btn "
                                                                size="large"
                                                                onClick={() => {
                                                                    router.push(`/befaring${e.viewMorePath}`);
                                                                }}
                                                            >
                                                                Se ditt tilbud &nbsp;
                                                                <i className=" icon-arrow-r"/>
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col
                                                    md={{span: 12, order: 2}}
                                                    xs={{span: 24, order: 1}}
                                                >
                                                    <div>
                                                        <div
                                                            class="img-responsive img-thumbnail ratio-4-3"
                                                            style={{backgroundImage: `url(${e.image})`}}
                                                            data-aos="fade-up"
                                                        />
                                                        {/* <img src={e.image} alt="Befaringsbekreftelse" className="img-fluid" /> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </React.Fragment>
                                ))}
                                {isOfferSent &&
                                timeline &&
                                timeline.length &&
                                timeline.length > 0 && (
                                    <Row id="tidslinje" gutter={32} className="item-row">
                                        <Col xs={24}>
                                            <div className="new_befaring_content">
                                                <Title
                                                    level={2}
                                                    className="text-secondary befaring_headings numberings"
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <hr className="heading_line"/>
                                                    <span style={{marginLeft: "1em"}}>
                              05
                            </span>
                                                </Title>
                                                <Title level={2} className="befaring_headings">
                                                    Din salgsprosess
                                                </Title>
                                                {/* <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                          ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
                        </p> */}
                                            </div>
                                        </Col>
                                        <Col
                                            xs={24}
                                            lg={14}
                                            className="list_border timeline-wrapper"
                                        >
                                            <Timeline
                                                color="#A5875B"
                                                className="timelineclass calendar-timeline"
                                                data-aos="fade-up"
                                            >
                                                {timeline.map((item) => (
                                                    <Timeline.Item key={Math.random()} color="#A5875B">
                                                        <div>
                                                            <Row>
                                                                <Col xs={3} lg={1} className="icondata">
                                                                    <i
                                                                        className={item.timeline.icon}
                                                                        style={{fontSize: "24px"}}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    xs={21}
                                                                    lg={23}
                                                                    className="timeline-content"
                                                                >
                                                                    <p className="titleclass aligned-title">
                                                                        <span>{item.timeline.name}</span>
                                                                        <span className="sellertag">
                                        {item.completed_date ? (
                                            <Tag>
                                                {formatDisplayDate(
                                                    item.completed_date
                                                )}
                                            </Tag>
                                        ) : (
                                            ""
                                        )}
                                      </span>
                                                                    </p>
                                                                    {/* <p>{item.timeline.description}</p> */}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Timeline.Item>
                                                ))}
                                            </Timeline>
                                        </Col>
                                    </Row>
                                )}
                                {productGallery &&
                                productGallery.length &&
                                productGallery.length > 0 && (
                                    <Row id="produktgalleri" gutter={32} className="item-row">
                                        <Col span={24}>
                                            <div className="new_befaring_content">
                                                <Title
                                                    level={2}
                                                    className="text-secondary befaring_headings numberings"
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <hr className="heading_line"/>
                                                    <span style={{marginLeft: "1em"}}>
                              06
                            </span>
                                                </Title>
                                                <Title level={2} className="befaring_headings">
                                                    Et utvalg av våre produkter
                                                </Title>
                                            </div>
                                            <div className="w_befaring_slider_container">
                                                {productGallery && (
                                                    <Row gutter={16}>
                                                        <Col xs={24}>
                                                            {productGallery?.length ? (
                                                                <Row>
                                                                    <Col xs={24} className="position-relative">
                                                                        <div
                                                                            className="arrows left w-arrow"
                                                                            onClick={previous}
                                                                        >
                                                                            <i className=" icon-arrow-l"/>
                                                                        </div>
                                                                        <Carousel
                                                                            initialSlide={firstCarouselIndex}
                                                                            ref={(node) => {
                                                                                carousel = node;
                                                                            }}
                                                                            beforeChange={(oldIndex, newIndex) => {
                                                                                setSecondCarouselIndex(newIndex);
                                                                                setFirstCarouselIndex(newIndex);
                                                                                // eslint-disable-next-line no-unused-expressions
                                                                                carousel1 && carousel1.goTo(newIndex);
                                                                            }}
                                                                            {...mainSliderProps}
                                                                        >
                                                                            {productGallery &&
                                                                            productGallery?.map((i, index) => (
                                                                                <div className="main_slider_box">
                                                                                    <img
                                                                                        src={`${STORAGE_URL}${i.url}`}
                                                                                        alt="logo"
                                                                                        className="img-fluid cursor-pointer"
                                                                                        onClick={() => handleClick(index)}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </Carousel>
                                                                        <div
                                                                            className="arrows right w-arrow"
                                                                            onClick={next}
                                                                        >
                                                                            <i className=" icon-arrow-r"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            ) : (
                                                                <Row>
                                                                    <Col xs={24} className="position-relative">
                                                                        <div>
                                                                            {formatMessage({
                                                                                id: "component.nodata",
                                                                            })}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            {productGallery && productGallery?.length ? (
                                                                <Row className="small-slider">
                                                                    <Col xs={24} className="position-relative">
                                                                        {/* <div className="arrows left" onClick={previous1}>
                                      <i className=" icon-arrow-l" />
                                    </div> */}
                                                                        <Carousel
                                                                            ref={(node) => {
                                                                                carousel1 = node;
                                                                            }}
                                                                            {...thumbnailProps}
                                                                            // infinite
                                                                            initialSlide={secondCarouselIndex}
                                                                            beforeChange={(oldIndex, newIndex) => {
                                                                                console.log(newIndex);
                                                                                setSecondCarouselIndex(newIndex);
                                                                                setFirstCarouselIndex(newIndex);
                                                                                // eslint-disable-next-line no-unused-expressions
                                                                                carousel && carousel.goTo(newIndex);
                                                                            }}
                                                                        >
                                                                            {productGallery &&
                                                                            productGallery?.map((i) => (
                                                                                <div
                                                                                    className="carousel_img w_befaring_slider w_befaring_thumbnail_silder">
                                                                                    <img
                                                                                        src={`${STORAGE_URL}${i.url}`}
                                                                                        alt="logo"
                                                                                        className="img-fluid thumbnail-slider-image"
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </Carousel>
                                                                        {/* <div className="arrows right" onClick={next1}>
                                      <i className=" icon-arrow-r" />
                                    </div> */}
                                                                    </Col>
                                                                </Row>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {isOpen ? (
                                    <Lightbox
                                        mainSrc={`${STORAGE_URL}${productGallery[photoIndex].url}`}
                                        nextSrc={`${STORAGE_URL}${
                                            productGallery[(photoIndex + 1) % productGallery?.length]?.url
                                            }`}
                                        prevSrc={`${STORAGE_URL}${
                                            productGallery[
                                            (photoIndex + productGallery.length - 1) % productGallery.length
                                                ]?.url
                                            }`}
                                        onCloseRequest={() => setIsOpen(false)}
                                        onMovePrevRequest={() =>
                                            setPhotoIndex(
                                                (photoIndex + productGallery.length - 1) % productGallery.length,
                                            )
                                        }
                                        onMoveNextRequest={() =>
                                            setPhotoIndex((photoIndex + 1) % productGallery.length)
                                        }
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </BefaringLayout>
    );
}

export default Befaring;
