export default {
  'component.energylabeling.title': 'Energimerking',
  'component.energylabeling.description':
    'Du som selger er ansvarlig for eiendommen som selges med energisertifikat. Du kan gjøre dette enkelt gjennom Enovas energimerkingsportal. Klikk på knappen nedenfor, så blir du omdirigert til energimerkingspåloggingen. Når du har fullført prosessen, fyll ut informasjonen nedenfor og last opp energisertifikatet.',
  'component.energylabeling.loginbtn': 'Logg inn til energimerking.no',
  'component.energylabeling.lettercode': 'Bokstavkode',
  'component.energylabeling.lettercode.placeholder': 'Velg bokstavkode',
  'component.energylabeling.colorcode': 'Fargekode',
  'component.energylabeling.colorcode.placeholder': 'Velg fargekode',
  'component.energylabeling.content.selectbox2.option1': 'Mørk grønn',
  'component.energylabeling.content.selectbox2.option2': 'Grønn',
  'component.energylabeling.content.selectbox2.option3': 'Gul',
  'component.energylabeling.content.selectbox2.option4': 'oransje',
  'component.energylabeling.content.selectbox2.option5': 'Rød',
  'component.energylabeling.house.heated': 'Hvordan varmes huset opp?',
  'component.energylabeling.electricity': 'Elektrisitet',
  'component.energylabeling.heatpump': 'Varmepumpe (Omgivelsesvarme)',
  'component.energylabeling.oil': 'Olje / parafin',
  'component.energylabeling.gas': 'Gass',
  'component.energylabeling.bioenergy': 'BioEnegry',
  'component.energylabeling.solarpower': 'Solenergi',
  'component.energylabeling.heating': 'Oppvarming',
  'component.energylabeling.fireplace': 'Peis (f.eks. Peis, stekeovn, komfyr)',
  'component.energylabeling.upload.certificate': 'Last opp energiattest',
  'component.energylabeling.content.button2': 'Last opp',
  'component.energylabeling.content.footer.button1': 'Lagre',
  'component.energylabeling.content.footer.button2': 'Avbryt',
};
