import {Platform} from "react-native";

import LinearGradient from './LinearGradientWeb';

let component;

if (Platform.OS !== 'web') {
    //Don't make it remove otherwise it will compile with web and cause error
    let id = 'react-native-linear-gradient';
    component = require(`${id}`).default
} else {
    component = LinearGradient

}

export default component;






