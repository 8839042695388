import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
    },
    contentCont: {

        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginTop: 50
    },
    scrollCont: {
        flexGrow: 1,
        paddingBottom:20
    },
    icMaterial: {
        marginVertical: 20,
    },
    materialCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingStart: 15,
        paddingEnd: 10,
        paddingVertical: 6,
        marginBottom: 8,
        marginTop: 10,
        borderRadius: 4,
        backgroundColor: Color.DARK_GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 10,
        justifyContent: 'space-between'
    },
    icFiles: {
        padding: 8,
    },
    lblAprovedDate: {
        backgroundColor: Color.DARK_GRAY,
        paddingVertical: 8,
        borderRadius: 4,
        marginVertical: 15
    },
    btnCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: 15,
    },
    topContainer: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        // alignContent: 'center'
    },
    uploadView:
        {
            flexDirection: 'row',
            width: 125,
            height: 48,
            alignItems: 'center',
            borderWidth: 2,
            borderColor: Color.INPUT_BORDER,
            justifyContent: 'center',
            alignContent: 'center',
            marginBottom: 15,
            borderRadius: 6
        },
    SubViewIcon: {
        backgroundColor: Color.INPUT_BORDER,
        height: ThemeUtils.responsiveScale(180),
        width: ThemeUtils.responsiveScale(180),
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start'
    },
    img:{
        height: ThemeUtils.responsiveScale(180),
        width: ThemeUtils.responsiveScale(180),
        borderRadius: 4,
        borderWidth:0.8,
        borderColor:Color.PRIMARY_LIGHT

    }

})
