import {StyleSheet} from 'react-native';
import {Color, IS_WEB, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    scrollCont: {
        flexGrow: 1,
        paddingVertical: 10,
        paddingTop: ThemeUtils.relativeRealHeight(5)
    },
    icCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: ThemeUtils.relativeRealHeight(1.5)
    },
    assignmentCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    filterCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingBottom: 15,
        flex: 1,
    },
    chk: {
        marginVertical: 10
    },
    btnCont: {
        flexDirection: 'row',
        margin: ThemeUtils.COMMON_HORIZONTAL_MARGIN,

    },
    btn: {
        flex: 1,
        marginHorizontal: 10
    },

    searchCont: {
        marginTop: 80
    },
    inptSearch: {
        flex: 1,
        marginHorizontal: 10,
        color: Color.PRIMARY,
        fontSize: ThemeUtils.fontNormal
    },
    inptSearchCont: {
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        paddingVertical: IS_WEB ? 15 : 0,
        paddingHorizontal: 20,
        backgroundColor: Color.GRAY,
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: 'Maax'
    },
    icClear: {
        height: ThemeUtils.fontNormal,
        width: ThemeUtils.fontNormal,
        borderRadius: ThemeUtils.fontNormal / 2,
        backgroundColor: Color.PRIMARY_LIGHT,
        alignItems: 'center',
        justifyContent: 'center'
    },
    icLoader: {
        marginTop: 20
    }
})
