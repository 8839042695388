import React, {useState} from "react";
//Components
import {Animated, Image, Keyboard, View} from "react-native";
import {
    CustomButton,
    FilePicker,
    FileType,
    Header,
    InputField,
    Label,
    RichTextEditor,
    RichToolbar
} from "components/src/components";
//Third party
//redux
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    Icon,
    IS_WEB,
    MessageUtils,
    showMessage,
    Strings,
    ThemeUtils,
    validation,
} from "components/src/utils";
import {styles} from "./styles.js";
import {useMutation} from "@apollo/react-hooks";
import {ADD_UPDATE_PREPARATION} from "../../../../../api/mutation";

const scrollY = new Animated.Value(0);

export default function Preparation(props) {
    const {navigation, history} = props;
    const details = IS_WEB ? history.location?.state?.details ?? {} : navigation.getParam('details', {})
    let richtext;

    const [discription, onChangeDiscription] = useState(details?.description ?? "");
    const [headLine, onChangeHeadLine] = useState(details?.headline ?? "");
    const [photo, onPhotoPiked] = React.useState([]);

    const [updateDetails, updateDetailsMutation] = useMutation(ADD_UPDATE_PREPARATION)

    const [errHeadline, setErrHeadline] = useState(null)
    const [errDesc, setErrDesc] = useState(null)
    const validate = () => {
        setErrHeadline(validation('headline', headLine));

        return !errHeadline

    };

    return (
        <View style={CommonStyle.flex}>
            <Header
                // animatedTitle={Strings.preparation}
                animatedTitle={"Avsnitt 04"}
                navigation={IS_WEB ? history : navigation}
                animatedValue={scrollY}
            />
            <Animated.ScrollView keyboardShouldPersistTaps={'always'}

                                 scrollEventThrottle={1}
                                 contentContainerStyle={{flexGrow: 1}}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true}
                                 )}
            >
                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.viewicon}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={"preparations"}
                                color={Color.PRIMARY_DARK}
                            />
                        </View>
                        <InputField
                            type={"text"}
                            mt={16}
                            error={errHeadline}
                            textArea={false}
                            placeholder={Strings.headLine}
                            onChange={(text) => onChangeHeadLine(text)}
                            labelProps={{
                                font_medium: true,
                                small: true,
                            }}
                            labelText={Strings.headLine}
                            value={headLine}
                        />
                        <Label small font_medium mb={15}>
                            {Strings.uploadPhotoTitle}
                        </Label>
                        <FilePicker style={{alignSelf: 'flex-start'}}
                                    type={FileType.IMAGE}
                                    onFilePicked={(data, files) => onPhotoPiked(files)}>
                            {photo.length ? (
                                <Image style={styles.SubViewIcon}
                                       source={IS_WEB ? {uri: URL.createObjectURL(photo[0])} : {uri: photo[0]?.uri}}/>
                            ) : details?.image ? (
                                <Image style={styles.SubViewIcon}
                                       source={{uri: `${Constants.APIConfig.STORAGE_URL}${details?.image}`}}/>
                            ) : (
                                <View style={styles.SubViewIcon}>
                                    <Icon name={'add'} size={ThemeUtils.responsiveScale(60)}
                                          color={Color.INPUT_BORDER}/>

                                </View>)}
                        </FilePicker>


                        <View style={{
                            borderWidth: 1,
                            borderColor: Color.DARK_GRAY,
                            marginVertical: 20,
                            borderRadius: 4
                        }}>
                            <RichTextEditor ref={(r) => richtext = r}
                                            placeholder={Strings.discription}
                                            initialContentHTML={discription}/>
                            <RichToolbar getEditor={() => richtext}/>
                        </View>
                        <View/>
                    </View>
                    <View style={styles.buttonContainer}>
                        <CustomButton loading={updateDetailsMutation.loading}
                                      font_medium
                                      onPress={async () => {
                                          if (!IS_WEB) {
                                              Keyboard.dismiss()
                                          }
                                          const content = await richtext.getContentHtml();
                                          const trueContent = content.toString();
                                          if (validate()) {
                                              if (trueContent) {
                                                  let variables = {
                                                      headline: headLine,
                                                      description: trueContent,
                                                  }
                                                  if (photo.length) {
                                                      variables.image = photo[0]
                                                  }
                                                  updateDetails({
                                                      variables: {
                                                          input: variables
                                                      }
                                                  }).then(() => {

                                                  }).catch(() => {

                                                  })
                                              } else {
                                                  showMessage(MessageUtils.Errors.descriptionRequired, Constants.MessageType.FAILED)
                                              }
                                          }
                                      }}
                                      mr={ThemeUtils.relativeWidth(1.5)}
                                      style={{flex: 1}} // Do not remove inline will not work in web
                                      title={Strings.save}
                        />

                        <CustomButton ml={ThemeUtils.relativeWidth(1.5)}
                                      font_medium
                                      onPress={() => {
                                          if (IS_WEB) {
                                              history.goBack();
                                          } else {
                                              Keyboard.dismiss();
                                              navigation.goBack();
                                          }
                                      }}
                                      style={{
                                          flex: 1,
                                          borderWidth: 1,
                                          borderColor: Color.DARK_GRAY,
                                      }} // Do not remove inline will not work in web
                                      textColor={Color.PRIMARY}
                                      bgColor={Color.WHITE}
                                      title={Strings.cancel}
                        />
                    </View>
                </View>
            </Animated.ScrollView>
        </View>
    );
}
