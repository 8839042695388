export default {
  'component.managelinks.title': 'Administrer linker',
  'component.managelinks.decription':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
  'component.managelinks.webmeglingmooving': 'Webmegling Moovin(g)',
  'component.managelinks.seemovie': 'Se film',
  'component.managelinks.sortdonatethrow': 'Sorter, doner og kast',
  'component.managelinks.facebookmarketplace': 'Facebook markedsplass',
  'component.managelinks.finnosquare': 'Finn.no-plassen',
  'component.managelinks.allmarketplace': 'Hele markedsplassen',
  'component.managelinks.preparepacking': 'Forbered pakking, ordrebokser, tape osv',
  'component.managelinks.ordermovingmaterials': 'Bestill bevegelige materialer',
  'component.managelinks.takeaiminnewappartment': 'Ta mål i ny leilighet.',
  'component.managelinks.orderawashout': 'Bestill en utvask',
  'component.managelinks.orderwashout': 'Bestill vasking',
  'component.managelinks.addlink': 'Legg til lenke',
};
