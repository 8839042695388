import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        zIndex: 1,

    },
    animatedTitleCont: {
        zIndex: 6,
        position: 'absolute',
/*        flexDirection: 'row',
        alignItems: 'center',*/
        elevation: 6
    },
    headerCont: {
        flexDirection: 'row',

        height: ThemeUtils.relativeRealHeight(8),
        backgroundColor: Color.TRANSPARENT,
        elevation: 6,
        paddingHorizontal: ThemeUtils.relativeRealWidth(ThemeUtils.isTablet() ? 1.5 : 4)

    },
    headerBg: {

        height: ThemeUtils.relativeRealHeight(8),
        backgroundColor: Color.PRIMARY,
        position: 'absolute',
        start: 0,
        end: 0,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        paddingHorizontal: ThemeUtils.relativeRealWidth(1.5)

    },
    icHeader: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        padding: ThemeUtils.relativeRealWidth(1.8),
    },
    icHeaderRightCont: {
        flex: 1,
        zIndex: 10,
        top: 0,
        bottom: 0,
        end: ThemeUtils.relativeRealWidth(1.5),
        position: 'absolute',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
    },
    headerTitleCont: {
        alignItems: 'center',
        justifyContent: 'center',
        start: 0,
        end: 0,
        position: 'absolute',
        alignSelf: 'center',
        elevation: 6,
    }
})
