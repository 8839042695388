import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center',
        justifyContent: 'space-around',
    },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#3A173C',
        width: "100%",
        borderWidth: 0.5,
    },
    MainView: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row'

    },
    titleView: {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'},
    MainViewPhoto: {marginTop: 20, marginBottom: 20},
    SearchMainView: {
        backgroundColor: Color.UPLOAD_BG_COLOR,
        paddingVertical:ThemeUtils.relativeRealHeight(2),
        flexDirection: 'row',
        alignItems:'center',
        height:40,
        paddingHorizontal:containerHorizontalMargin
    },
    Textinput: {
        flex: 1,
        marginHorizontal:10,

        color:Color.PRIMARY,
        fontSize:ThemeUtils.fontNormal
    },
    closeIconView: {
        alignContent: 'flex-end',
        alignSelf: 'center',
        padding:5
    },
    OrderedView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        borderWidth: 2,
        borderColor: Color.BORDER_RED,
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 4,
        borderRadius: 4
    },
    TimeView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_ORANGE,
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 4,
        borderRadius: 4
    },
    PhotoView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_BLUE,
        justifyContent: 'center',
        alignContent: 'center',
        marginBottom: 4,

        borderRadius: 4
    },
    ProjectView: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 2, padding: 5,
        borderColor: Color.BORDER_GREEN,
        justifyContent: 'center',
        alignContent: 'center',

        borderRadius: 4
    },
    IconView: {
        flexDirection: 'column'
    },


});

export {styles};
