import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Clipboard, View} from 'react-native';
import {CustomButton, Header, InputField, Label, Ripple} from "components/src/components";
//Third Party
import moment from 'moment-timezone'
//Utils
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {FINN_DETAILS} from "components/src/api/query";
import {ADD_FINN_AD} from 'components/src/api/mutation';

export default function SeeFinnAd(props) {
    const {history, navigation} = props;
    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');

    const [link, setLink] = useState('')
    const [finnData, setFinnData] = useState({})
    const scrollY = new Animated.Value(0);

    const {loading, data, error} = useQuery(FINN_DETAILS, {
        variables: {
            propertyId: propertyId,
            status: 1
        }
    });

    const [addFinnDetails, addFinnDetailsMutation] = useMutation(ADD_FINN_AD)

    useEffect(() => {
        if (data?.finn_ad_detail) {
            setFinnData(data?.finn_ad_detail)
            setLink(data?.finn_ad_detail?.url)
        }
    }, [data])

    useEffect(() => {
        if (data?.add_finn_no_ad) {
            setFinnData(data?.add_finn_no_ad);
        }
    }, [addFinnDetailsMutation.data])

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.seeFinnAd}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={styles.container}>
                    <Icon name={'see-finn-ad'}
                          color={Color.PRIMARY}
                          size={ThemeUtils.fontXXLarge}/>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (
                        <>
                            <View style={styles.inptCont}>
                                <InputField type={'text'}
                                            value={link}
                                            style={{flex: 1}}
                                            editable={!finnData.is_approved}
                                            errorEnabled={false}
                                            placeholder={Strings.pAttachLink}
                                            onChange={setLink}
                                            labelText={Strings.attachLink}
                                            mt={10}
                                            labelProps={{
                                                font_medium: true,
                                                small: true
                                            }}/>
                                <Ripple rippleContainerBorderRadius={4}
                                        onPress={() => {
                                            Clipboard.setString(link)
                                        }}
                                        style={styles.icCont}>
                                    <Icon name={'copy'}
                                          color={Color.PRIMARY_LIGHT}
                                          size={ThemeUtils.responsiveScale(20)}/>
                                </Ripple>
                            </View>
                            {!finnData?.is_approved ?
                                (<CustomButton align={'left'}
                                               mt={ThemeUtils.relativeRealHeight(2)}
                                               loading={addFinnDetailsMutation.loading}
                                               style={{
                                                   alignSelf: 'flex-start',
                                                   width: ThemeUtils.relativeRealWidth(25)
                                               }}
                                               onPress={() => {
                                                   addFinnDetails({
                                                       variables: {
                                                           input: {
                                                               property_id: propertyId,
                                                               url: link
                                                           }
                                                       }
                                                   }).then(() => {
                                                       IS_WEB ? history.goBack() : navigation.goBack()
                                                   })
                                               }}
                                               title={Strings.sendButton}/>) : null}
                            {finnData?.is_approved ?
                                (<Label small
                                        color={Color.PRIMARY}
                                        align={'center'}
                                        style={styles.lblAprovedDate}>
                                    {`${Strings.approvedOn} ${moment.utc(finnData.approved_date).format(DateUtils.hh_mm_dd_mm_yyy)}`}
                                </Label>) : null}
                            {finnData?.finn_comments?.length ? (
                                    <>
                                        <Label small
                                               font_medium
                                               mt={15}
                                               color={Color.PRIMARY}>
                                            {Strings.commentsFromSeller}
                                        </Label>
                                        {finnData.finn_comments.map(item => (
                                            <>

                                                <Label small
                                                       mt={15}
                                                       color={Color.PRIMARY}>
                                                    {item.comment}
                                                </Label>
                                                <Label small
                                                       font_medium
                                                       mt={10}
                                                       color={Color.DARK_GRAY}>
                                                    {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                                </Label>
                                            </>
                                        ))}
                                    </>)
                                : null}
                        </>)}

                </View>
            </Animated.ScrollView>
        </View>
    )
}
