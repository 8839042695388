import { decryptId } from '../../../utils/utils';
import { Button, Col, Form, Input, Radio, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Mutation,useQuery } from 'react-apollo';



import { STORE_QUEATIONS } from '../../../../mutation/AgentMutation';
import { PROPERTY_DETAIL } from '../../../../query/AgentQuery';
// import data from '../../../../mock/officeceodata';
import Strings from '../../../../locales/nb-NO';
import BefaringLayout from "../../../layouts/BefaringLayout";
const formatMessage=({id})=>{
  return Strings[id]
}
const { Title } = Typography;
const { Search } = Input;

export const QuestionForAgent = props => {
  let router=props.history;
  const [otherBrokerage, setOtherBrokerage] = useState(false);
  const [isPartner, setIsPartner] = useState(true);
  const [isSignAsPartner, setIsSignAsPartner] = useState(true);
  const [mainQue, setMainQue] = useState(true);

  const { match, location } = props;
  const { params } = match;

  const onFinish = (values, storeQuestion) => {
    console.log('Success:', values);

    const variables = {
      property_id: +decryptId(params.propertyId),
      same_contract_assignment: values.signingassignment,
      political_person: values.pepperson,
      client_claim: values.estate,
      client_married_partner: values.partner,
    }
    if (values.otherbrokername) {
      variables.name_of_brokerage = values.otherbrokername
    }
    if (values.partner === 1) {
      variables.client_partner_both_sign = values.signasclient
    }
    if (values.partner === 1 && variables.client_partner_both_sign === 0) {
      variables.contract_apply_housing = values.jointresidence
    }
    storeQuestion({
      variables: {
        input: {
          ...variables
        }
      }
    }).then(({ data }) => {
      if (data) {
        router.push(`/befaring/${params.propertyId}/oppdragsavtale1`);
      }
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };


  const onPartnerChange = e => {
    console.log("Merried change",e.target.value)
    setIsPartner(e.target.value);
  };

  const onSignAsPartner = e => {
    setIsSignAsPartner(e.target.value);
  };


  const property_question = useQuery(PROPERTY_DETAIL, {
    variables: {
      id: +decryptId(params.propertyId),
    },
  });


  console.log(property_question);


  React.useEffect(() => {
    if (property_question) {
      console.log("propertyQuerstion==>",property_question.data)
      setOtherBrokerage(!!(property_question?.data?.property?.contract_assignment !== null && property_question?.data?.property?.contract_assignment !== 0))
      setIsPartner(property_question?.data?.property?.client_married_partner !== null ? property_question?.data?.property?.client_married_partner : true)
      setIsSignAsPartner(property_question?.data?.property?.client_partner_both_sign !== null ? property_question?.data?.property?.client_partner_both_sign : true)

    }
  }, [property_question])



  return (
    <BefaringLayout>
      <div className="contentshadow bg-none">
        {property_question?.data && (

          <>
            <p className="text-small  text-secondary">
              {' '}
              {formatMessage({ id: 'component.agentinspection.missioncost.questions' })}{' '}
            </p>
            <Mutation mutation={STORE_QUEATIONS}>
              {storeQuestion => (
                <Form
                  layout="vertical"
                  className="user-forms"
                  name="question-for-agent"
                  colon={false}
                  onFinish={values => onFinish(values, storeQuestion)}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="signingassignment"
                    rules={[{
                      required: true,
                      message: 'Vennligst gjør et valg!',
                    }]}
                    initialValue={property_question?.data?.property?.contract_assignment !== null ? property_question?.data?.property?.contract_assignment : undefined}
                    label="Følgende megler(e) har arbeidet med salg av samme eiendomsoppdraget de siste 3 måneder før signatur av oppdraget:"
                  >
                    <Radio.Group>
                      <Radio className="mb-3" value={0} onChange={() => setOtherBrokerage(false)}>
                        Ingen
              </Radio>
                      <Radio className="mb-3" value={1} onChange={() => setOtherBrokerage(true)}>
                        Annet meglerforetak
              </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {otherBrokerage && (
                    <Row>
                      <Col lg={9} xs={24}>
                        <Form.Item
                          name="otherbrokername"
                          initialValue={property_question?.data?.property?.name_of_brokerage !== null ? property_question?.data?.property?.name_of_brokerage : undefined}
                          rules={[{
                            required: true,
                            message: 'Vennligst oppgi meglerforetak',
                          }]}
                          label="Følgende megler(e) har arbeidet med salg av samme eiendomsoppdraget de siste 3 måneder før signatur av oppdraget:"
                        >
                          <Input placeholder="Oppgi meglerforetak" size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Form.Item
                    name="pepperson"
                    rules={[{
                      required: true,
                      message: 'Vennligst gjør et valg!',
                    }]}
                    initialValue={property_question?.data?.property?.political_person !== null ? property_question?.data?.property?.political_person : undefined}
                    label="En politisk eksponert person (PEP) er en fysisk person som er i, eller har hatt, et høytstående offentlig verv eller stilling. Er oppdragsgiver en politisk eksponert person (PEP)?"
                  >
                    <Radio.Group>
                      <Radio className="mb-3" value={1}>
                        Ja
              </Radio>
                      <Radio className="mb-3" value={0}>
                        Nei
              </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="estate"
                    rules={[{
                      required: false,
                      message: 'Vennligst gjør et valg!',
                    }]}
                    initialValue={property_question?.data?.property?.client_claim !== null ? property_question?.data?.property?.client_claim : undefined}
                    label="Dersom en av oppdragsgiverne kjøper ut den andre/en av de andre oppdragsgiverne har oppdragstaker krav på fullt vederlag i henhold til punkt 4. Det samme gjelder dersom oppdragsgiver selger eiendommen til fraflyttet ektefelle. Hvis boligen har vært felles bolig for ektefeller, er boet mellom ektefellene oppgjort?"
                  >
                    <Radio.Group>
                      <Radio className="mb-3" value={1}>
                        Ja
              </Radio>
                      <Radio className="mb-3" value={0}>
                        Nei
              </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <p className="text-small  text-secondary"> Erklæring om sivilstand </p>

                  {mainQue ? (
                    <Form.Item
                      name="partner"
                      initialValue={property_question?.data?.property?.client_married_partner !== null ? property_question?.data?.property?.client_married_partner : undefined}
                      rules={[{
                        required: true,
                        message: 'Vennligst gjør et valg!',
                      }]} label="Er oppdragsgiver gift/registrert partner?">
                      <Radio.Group
                        onChange={e => {
                          onPartnerChange(e);
                        }}
                      >
                        <Radio className="mb-3" value={1}>
                          Ja
                </Radio>
                        <Radio className="mb-3" value={0}>
                          Nei
                </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}

                  {isPartner === 1 ? (
                    <Form.Item
                      name="signasclient"
                      initialValue={property_question?.data?.property?.client_partner_both_sign !== null ? property_question?.data?.property?.client_partner_both_sign : undefined}
                      rules={[{
                        required: true,
                        message: 'Vennligst gjør et valg!',
                      }]}
                      label="Er oppdragsgiver gift/registrert partner med hverandre og begge underskriver som oppdragsgiver?"
                    >
                      <Radio.Group
                        onChange={e => {
                          onSignAsPartner(e);
                        }}
                      >
                        <Radio className="mb-3" value={1}>
                          Ja
                </Radio>
                        <Radio className="mb-3" value={0}>
                          Nei
                </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}

                  {isSignAsPartner === 0 && isPartner===1 ? (
                    <Form.Item
                      name="jointresidence"
                      initialValue={property_question?.data?.property?.contract_apply_housing !== null ? property_question?.data?.property?.contract_apply_housing : undefined}

                      rules={[{
                        required: true,
                        message: 'Vennligst gjør et valg!',
                      }]}
                      label="Gjelder oppdraget bolig som oppdragsgiver og dennes ektefelle/registrerte partner bruker som fellesbolig?"
                    >
                      <Radio.Group>
                        <Radio className="mb-3" value={1}>
                          Ja
                </Radio>
                        <Radio className="mb-3" value={0}>
                          Nei
                </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}

                  <Row className="mt-3">

                    <Col xs={24}>
                      <Mutation mutation={STORE_QUEATIONS}>
                        {storeQuestion => (
                          <Button
                              type="primary"
                              className="btn-secondary btn-secondary admin-forms-btn"
                              style={{fontSize: '12px', padding: '8px'}}
                              size="large"
                            htmlType="submit"
                            onClick={() => console.log('hello')}
                          >
                            {formatMessage({
                              id: 'component.agentinspection.missioncost.gotoassiagree',
                            })}
                          </Button>
                        )}
                      </Mutation>
                      <Button
                        onClick={() => router.goBack()}
                        className="admin-forms-btn offer-btn ml-2"
                        style={{fontSize: '12px'}}
                        size="large"
                      >
                        {formatMessage({
                          id: 'component.sellerinterview.previous',
                        })}
                      </Button>
                    </Col>

                  </Row>
                </Form>
              )}
            </Mutation>
          </>
        )}
      </div>

    </BefaringLayout>
  );
};

export default QuestionForAgent;
