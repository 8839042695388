// internal app notifications key
import {IS_WEB} from "./index";
import {Linking} from 'react-native';

export const notificationKey = {
    LOGOUT: 'LOGOUT',
};

const APP_URL = {
    Android: 'https://play.google.com/store/apps/details?id=com.houzquest.wmt.android',
    Ios: 'https://apps.apple.com/us/app/id1480189810',
};

export const Permissions = {
    PHOTO: 'photo',
    CAMERA: 'camera',
    LOCATION: 'location',
    MICROPHONE: 'microphone',
    CONTACTS: 'contacts',
    EVENTS: 'event',
    STORAGE: 'storage',
    PHONE_CALL: 'callPhone',
    READ_SMS: 'readSms',
    RECEIVE_SMS: 'receiveSms',

    //support only IOS
    MOTION: 'motion',
    MEDIA_LIBRARY: 'mediaLibrary',
    SPEECH_RECOGNITION: 'speechRecognition',
    PUSH_NOTIFICATION: 'notification',

    //Android PermissionSign
    READ_EXTERNAL_STORAGE: 'READ_EXTERNAL_STORAGE',
    WRITE_EXTERNAL_STORAGE: 'WRITE_EXTERNAL_STORAGE',
};

const InsuranceType = {
    UTEN: "UtenAnticimex",
    MED: "MedAnticimex",
}

export const APIConfig = {
    //Local
  /*  BASE_URL: 'https://638a84274298.ngrok.io/graphql',
    STORAGE_URL: "https://638a84274298.ngrok.io/storage/",
    DOMAIN: "http://localhost:3000/",*/
    //Dev
    BASE_URL: 'https://devapi.weiendomsmegling.no/graphql',
    STORAGE_URL: 'https://duqywbqe47ltg.cloudfront.net/',
    DOMAIN:"https://agentm.weiendomsmegling.no/",
    FRONT:"https://dev.weiendomsmegling.no/",
    //Live
    /*BASE_URL: 'https://api.weiendomsmegling.no/graphql',
    STORAGE_URL: 'https://cdn.weiendomsmegling.no/',
    DOMAIN: "https://weiendomsmegling.no/",
    FRONT: "https://weiendomsmegling.no/"*/
};

const openInNewTab = (url) => {
    if (window && IS_WEB) {
        window.open(url, '_blank')
    } else {
        Linking.openURL(url)
    }
}

const ServiceCategory = {
    PhotoStyling: "PS",
    DigitalMarketing: "DM",
    PrintMarketing: "PM"
}

export const RequestCode = {};

// api response codes
export const ResponseCode = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    UNPROCESSABLE_REQUEST: 422,
    INTERNAL_SERVER_ERROR: 500,
    TOKEN_INVALID: 503,
    NO_INTERNET: 522,
    BAD_GATEWAY: 502,
};

const FieldId = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'laseName',
    PASSWORD: 'password',
    EMAIL: 'email',
    ADDRESS: 'address',
    ZIP_CODE: 'zipCode',
    PHONE: 'phone',
    FULL_NAME: 'fullName',
    CITY: 'city',
    OLD_PASSWORD: 'oldPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
    NEW_PASSWORD: 'newPassword',
    TITLE: 'Title',
    EDUCATION: 'Education',
    SUMMARY_ABOUT_AGENT: 'Summary About Agent',
    PURCHASE: 'purchase',
    COMMUNE: 'commune',
    GNR: 'gnr',
    BNR: 'bnr',
    FNR: 'fnr',
    ORG_NR: 'orgnr',
    SNR_ANDELSNR: 'snr',
    FNAME: 'fname',
    LNAME: 'lname',
    COMMENT: 'comment'
};

const UploadMediaType = {
    PHOTO: "PHOTO",
    THREE_D: "THREE_D",
    DS: "DS",
    FINN: "FINN",
};

const MessageType = {
    SUCCESS: 'success',
    FAILED: 'error',
    INFO: 'info',
};

const RADIO_OPTIONS = [
    {name: 'Ja', value: 'Yes'},
    {name: 'Nei', value: 'No'},
];
const UserType = {
    Admin: 1, //Admin
    Seller: 2,
    Agent: 3,
    Photographer: 4,
    Takst: 5,
    Stylist: 6,
    Examination: 7, //Book inspector
    Buyer: 8,
}

const ServiceType = {
    Photo: 'PHOTO',
    Other: "OTHER",
    Styling: "STYLING"
};

const PriceType = {
    VEDERLEGG: "Vederlagg",
    UTLEGG: "Utlegg"
};

const TIME_GAP = 1;

export default {
    TIME_GAP,
    PriceType,
    ServiceType,
    APP_URL,
    APIConfig,
    UserType,
    RADIO_OPTIONS,
    notificationKey,
    ResponseCode,
    RequestCode,
    Permissions,
    FieldId,
    MessageType,
    InsuranceType,
    openInNewTab,
    ServiceCategory,
    UploadMediaType
};
