import React, {useEffect, useState} from 'react'
//Components
import {Keyboard,ScrollView, TextInput, TouchableOpacity, View} from 'react-native'
//Third party
//redux
//Network
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles.js';
import {Label} from "../../index";

function SearchableDropDown(props) {
    const {results, placeholder, value, title, style, onItemSelect} = props
    const [searchText, setSearchText] = useState();
    const [showMenu, setShoWMenu] = useState(false);
    const [searchResults, setSearchResult] = useState();


    useEffect(() => {
        setSearchResult(results)
    }, [results])


    useEffect(() => {
        setSearchText(value)
    }, [value])


    return (
        <View style={[CommonStyle.flex, style]}>
            <View style={styles.searchMainView}>
                <Label small
                       font_medium
                       mb={ThemeUtils.relativeRealHeight(1.5)}>
                    {title}
                </Label>
                <View style={[styles.textInput, IS_WEB ? {outline: 'none'} : null]}>
                    <TextInput style={[CommonStyle.flex, IS_WEB ? {outline: 'none'} : null]}
                               onFocus={() => {
                                   setShoWMenu(true)
                                   setSearchText("")
                                   onItemSelect(null)
                               }}
                               onBlur={() => {
                                   setTimeout(() => {
                                       setShoWMenu(false)
                                   }, 100)
                               }}
                               onChangeText={(text) => {
                                   setSearchText(text)
                                   setSearchResult(text ? results.filter(item => item.name.toLowerCase().includes(text.toLowerCase())) : results)
                               }}
                               value={searchText}
                               placeholder={placeholder}/>

                    <Icon name={showMenu ? 'search' : 'drop-normal'}
                          size={12}
                          color={Color.PRIMARY_LIGHT}
                          style={[{paddingLeft: 5}, showMenu ? null : {transform: [{rotate: "90deg"}]}]}/>
                </View>
                {showMenu ? (
                    <View style={styles.itemContainer}>
                        <ScrollView keyboardShouldPersistTaps={'always'}>
                            {searchResults.map((item, index) => {
                                return (
                                    <TouchableOpacity activeOpacity={0.5}
                                                      key={index.toString()}
                                                      onPress={() => {
                                                          if(!IS_WEB){
                                                              Keyboard.dismiss();
                                                          }
                                                          onItemSelect(item)
                                                          setSearchText(item.name)
                                                      }}
                                                      style={styles.searchItem}>
                                        <Label small>
                                            {item.name}
                                        </Label>
                                    </TouchableOpacity>
                                )
                            })}
                        </ScrollView>
                    </View>) : null}
            </View>

        </View>
    );

}

export default SearchableDropDown;
SearchableDropDown.defaultProps = {
    results: [],
    placeholder : Strings.search
}
