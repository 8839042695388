import React, {useState} from 'react'
//Components
import {ActivityIndicator, Animated, SafeAreaView, TouchableOpacity, View} from 'react-native'
import {CustomButton, Header, Hr, Label, Modal} from "components/src/components";
import Search from '../Search'
//Third party
//redux
//Network
import {useQuery} from "@apollo/react-hooks";
import {AGENT_VENDOR_LIST} from "../../../../../api/query";
//Utils
import {Color, CommonStyle, encryptId, IS_WEB, Routes, Strings, ThemeUtils, UserType,} from "components/src/utils";
import styles from './styles'


const scrollY = new Animated.Value(0);
const StylistService = props => {

    const {history, navigation} = props;

    const [isSearchVisible, setSearchVisible] = useState(false);
    const [loadMore, setFetchMore] = useState(false);

    const {loading, data, error, fetchMore} = useQuery(AGENT_VENDOR_LIST, {
        variables: {
            input: {
                status: true,
                user_type_id: UserType.Stylist,
                page: 1,
                first: 30,
                key: "",
                sorting: {
                    field: 'id',
                    order: 'DESC',
                },
            },
        }
    });


    const renderSearchModal = () => {
        return (
            <Modal visible={isSearchVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Search onClose={() => setSearchVisible(false)}
                                userType={UserType.Stylist}
                                renderItem={renderVendor}/>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderVendor = (item, index) => {
        return (
            <>
                <TouchableOpacity activeOpacity={0.5}
                                  onPress={() => {
                                      if (IS_WEB) {
                                          history.push(`${Routes.Stylist}/${encryptId(item.id)}`)
                                      } else {
                                          setSearchVisible(false)
                                          navigation.navigate(Routes.StylistService, {
                                              vendorId: item.id
                                          })
                                      }
                                  }}
                                  key={index.toString()}
                                  style={styles.vendorCont}>
                    <Label font_medium>
                        {`${item.first_name} ${item.last_name}`}
                    </Label>
                    {item?.company_name ? (
                        <Label small
                               style={styles.lbl}>
                            {item?.company_name}
                        </Label>) : null}
                    {item.email ? (
                        <Label small
                               style={styles.lbl}>
                            {item.email}
                        </Label>) : null}
                    {item.mobile ? (
                        <Label small
                               style={styles.lbl}>
                            {item.mobile}
                        </Label>) : null}
                    {item.office_name ? (
                        <Label small
                               style={styles.lbl}>
                            {item.office_name}
                        </Label>) : null}
                    {item.address ? (
                        <Label small
                               style={styles.lbl}>
                            {item.address}
                        </Label>) : null}
                </TouchableOpacity>
                <Hr/>

            </>
        )
    }
    const renderVendorData = () => {
        return (
            <View>
                <Hr/>
                {data?.office_vendor?.data?.map(renderVendor)}
                {data?.office_vendor?.paginatorInfo?.hasMorePages ?
                    (<CustomButton title={Strings.loadMore}
                                   loading={loadMore}
                                   mt={10}
                                   mb={10}
                                   style={{
                                       width: ThemeUtils.relativeWidth(40),
                                       alignSelf: 'center'
                                   }}
                                   onPress={() => {
                                       setFetchMore(true)
                                       if (data?.office_vendor?.paginatorInfo?.hasMorePages && !loading) {
                                           fetchMore({
                                               variables: {
                                                   input: {
                                                       first: 30,
                                                       page: (data?.office_vendor?.paginatorInfo?.currentPage ?? 0) + 1,
                                                       status: true,
                                                       key: "",
                                                       sorting: {field: "id", order: "DESC"},

                                                   },
                                               },
                                               updateQuery: (prev, {fetchMoreResult}) => {
                                                   setFetchMore(false)
                                                   if (!fetchMoreResult) {
                                                       return prev;
                                                   } else {
                                                       let finalResult = Object.assign({}, fetchMoreResult);
                                                       finalResult.office_vendor.data = [...prev.office_vendor.data, ...fetchMoreResult.office_vendor.data];
                                                       return finalResult;
                                                   }
                                               },
                                           });
                                       }
                                   }}/>

                    ) : null}
            </View>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.stylist}
                    headerRightFirst={'search'}
                    onPressRightFirst={() => setSearchVisible(true)}
                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>
            {renderSearchModal()}
            <View style={styles.container}>
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}/>
                ) : data?.office_vendor?.data?.length ? renderVendorData() : (
                    <Label align={'center'}>
                        {"Ingen data"}
                    </Label>
                )}
            </View>
        </View>
    )

}

export default StylistService;
