import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, FlatList, Image, View} from 'react-native';
import {CustomButton, Header, Hr, Label, Ripple} from "components/src/components";
//Third party
import moment from "moment-timezone";
//Network
import {useQuery} from '@apollo/react-hooks'
import {NEWS_LIST} from 'components/src/api/query'
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from './styles';
import HtmlView from "../../../../components/view/HTMLView";


export default function News(props) {
    const {navigation, history} = props;
    const [loadMore, setFetchMore] = useState(false)
    const scrollY = new Animated.Value(0);

    const {loading, error, data, fetchMore} = useQuery(NEWS_LIST, {
        variables: {
            status: true,
            first: 10,
            page: 1
        }
    })

    const navigate = (news) => {
        if (IS_WEB) {
            history.push({
                pathname: `/${Routes.News}/${Routes.NewsDetails}/${encryptId(news.id)}`,
                news: news
            })
        } else {
            navigation.navigate(Routes.NewsDetails, {
                news: news
            })
        }
    };

    const renderNews = ({item, index}) => {
        return (
            <>
                <Hr/>
                <Ripple style={styles.newsCont}
                        onPress={() => navigate(item)}>
                    <View style={styles.imgCont}>
                        {item.cover_image_url ? (
                            <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.cover_image_url}`}}
                                   style={styles.imgNews}/>) : null}
                        <Label ml={item.cover_image_url ? 15 : 0}
                               style={CommonStyle.flex}
                               font_medium>
                            {item.title}
                        </Label>
                    </View>
                    <View style={{maxHeight: 48,marginTop:10 ,overflow: 'hidden'}}>
                        <HtmlView value={item.description}/>
                    </View>

                    <Label small
                           mt={5}
                           color={Color.SECONDARY_TEXT_COLOR}
                           numberOfLines={2}>
                        {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.dd_mm_yyyy_hh_mm)}
                    </Label>
                </Ripple>
            </>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            {console.log(Strings.news)}
            <Header animatedTitle={Strings.news}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <View style={styles.cont}>
                        <Icon name={'news'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.PRIMARY}/>

                        <Label small
                               mb={15}
                               mt={15}>
                            {'Her finner du siste nytt fra W Eiendomsmegling. Hold deg oppdatert'}
                        </Label>
                    </View>

                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (
                        <FlatList data={data?.news?.data ?? []}
                                  renderItem={renderNews}
                                  ListFooterComponent={
                                      data?.news?.paginatorInfo?.hasMorePages ?
                                          (
                                              <CustomButton title={Strings.loadMore}
                                                            loading={loadMore}
                                                            mt={10}
                                                            mb={10}
                                                            style={{
                                                                width: ThemeUtils.relativeWidth(40),
                                                                alignSelf: 'center'
                                                            }}
                                                            onPress={() => {
                                                                setFetchMore(true)
                                                                if (data?.news?.paginatorInfo?.hasMorePages && !loading) {
                                                                    fetchMore({
                                                                        variables: {
                                                                            page: (data?.news?.paginatorInfo?.currentPage ?? 0) + 1,
                                                                        },
                                                                        updateQuery: (prev, {fetchMoreResult}) => {
                                                                            setFetchMore(false)
                                                                            if (!fetchMoreResult) {
                                                                                return prev;
                                                                            } else {
                                                                                let finalResult = Object.assign({}, fetchMoreResult);
                                                                                finalResult.news.data = [...prev.news.data, ...fetchMoreResult.news.data];
                                                                                return finalResult;
                                                                            }
                                                                        },
                                                                    });
                                                                }
                                                            }}/>

                                          ) : null
                                  }
                                  ListEmptyComponent={
                                      <Label mt={20}
                                             font_medium
                                             align={'center'}>
                                          {"Ingen data"}
                                      </Label>
                                  }
                                  keyExtractor={item => item.id.toString()}
                        />
                    )}

                </View>
            </Animated.ScrollView>
        </View>
    )
}
