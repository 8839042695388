export default {
  'component.faq.title': 'Ofte stilte spørsmål',
  'component.faq.description':
    'Lær mer om Webmegling og salgsprosessen. Nedenfor finner du et utvalg ofte stilte spørsmål. Er det noe annet du lurer på? Ta kontakt så hjelper vi deg gjerne.',
  'component.faq.question1':
    'Jeg har ikke svar på alle spørsmålene i selgers intervju, er det dumt?',
  'component.faq.answer1':
    'Spørsmålene dekker i stor grad det potensielle budgivere til din bolig lurer på. I W eiendomsmegling er vi opptatt av å kunne gi deg det beste resultatet og da er det viktig at din megler kan besvare spørsmål som potensielle budgivere måtte ha. Enkelte av spørsmålene er obligatoriske, mens andre er kjekke å vite. Kontakt din megler på telefon, epost eller ved å bruke chatten hvis du er usikker.',
  'component.faq.question2': 'Vil opplysningene jeg fyller inn her publiseres noe sted?',
  'component.faq.answer2':
    'Opplysningene overføres kun til megler sitt brukergrensesnitt og tredjeparts programvaren som megler bruker. Enkelte av opplysningene brukes i markedsføringen, men du vil alltid få tilsendt korrektur som du må godkjenne før noe blir publiser.',
  'component.faq.question3': 'Hvem har tilgang til opplysningene jeg oppgir?',
  'component.faq.answer3':
    'Det er kun meglerne som jobber med salget av din bolig som har tilgang til opplysningene, samt andre i selskapet som jobber med kvalitetskontroll. Dersom du har spørsmål om personvern kan du ta kontakt med selskapets daglig leder, Frank Johannessen, på epost frank@webmegling.no',
  'component.faq.question4':
    'Erstatter denne plattformen kommunikasjonen mellom meg og megleren min?',
  'component.faq.answer4':
    'Nei, dette er kun ment som et verktøy for å gjøre salgsprosessen mer sømløs og oversiktlig, og vil aldri være den eneste kontakten med din megler. Du kan ta kontakt med megleren din på både telefon og epost når det er mest hensiktmessig, men samtidig så oppfordrer vi deg til å bruke chatten. Det er sikkert, raskt og enkelt.',
  'component.faq.question5':
    'Jeg får opp feilmelding når jeg prøver å lagre eller gå videre, hva gjør jeg?',
  'component.faq.answer5':
    'Hvis du får en feilmelding er dette trolig fordi du har glemt å legge inn en av de obligatoriske opplysningene. Dersom du ikke har alle opplysningene tilgjengelig så kan du komme tilbake og gjøre deg ferdig senere. Trykk på hjem knappen så kommer du tilbake til forsiden. Hvis feilen fortsetter er det fint om du tar kontakt med megler på chatten, så skal vi ta hånd om det med en gang.',
};
