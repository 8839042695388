import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';

import Icon from '../icon';
import styles, {HEIGHT} from './styles';
import Label from "../../../Label";

function BaseToast({
                       leadingIcon,
                       trailingIcon,
                       text1,
                       text2,
                       onPress,
                       onLeadingIconPress,
                       onTrailingIconPress,
                       style,
                       leadingIconContainerStyle,
                       trailingIconContainerStyle,
                       leadingIconStyle,
                       trailingIconStyle,
                       contentContainerStyle,
                       text1Style,
                       text2Style,
                       activeOpacity
                   }) {

    return (
        <TouchableOpacity
            style={[styles.base, styles.borderLeft, style]}
            onPress={onPress}
            activeOpacity={onPress ? activeOpacity : 1}>
            {leadingIcon && (
                <TouchableOpacity
                    style={[styles.leadingIconContainer, leadingIconContainerStyle]}
                    onPress={onLeadingIconPress}
                    activeOpacity={onLeadingIconPress ? activeOpacity : 1}>
                    <Icon
                        style={[styles.leadingIcon, leadingIconStyle]}
                        source={leadingIcon}
                    />
                </TouchableOpacity>
            )}

            <View style={[styles.contentContainer, contentContainerStyle]}>
                {text1?.length > 0 && (
                    <View style={{justifyContent: 'center'}}>
                        <Label xsmall
                               font_medium
                               textStyle={[styles.text1, text1Style]}>
                            {text1}
                        </Label>
                    </View>
                )}
                {text2?.length > 0 && (
                    <View>
                        <Label xsmall
                               font_medium
                               textStyle={[styles.text2, text2Style]} numberOfLines={2}>
                            {text2}
                        </Label>
                    </View>
                )}
            </View>

            {trailingIcon && (
                <TouchableOpacity
                    style={[styles.trailingIconContainer, trailingIconContainerStyle]}
                    onPress={onTrailingIconPress}
                    activeOpacity={onTrailingIconPress ? activeOpacity : 1}>
                    <Icon
                        style={[styles.trailingIcon, trailingIconStyle]}
                        source={trailingIcon}
                    />
                </TouchableOpacity>
            )}
        </TouchableOpacity>
    );
}

BaseToast.HEIGHT = HEIGHT;

BaseToast.propTypes = {
    leadingIcon: Icon.propTypes.source,
    trailingIcon: Icon.propTypes.source,
    text1: PropTypes.string,
    text2: PropTypes.string,
    onPress: PropTypes.func,
    onTrailingIconPress: PropTypes.func,
    onLeadingIconPress: PropTypes.func,
    style: PropTypes.style,
    leadingIconContainerStyle: PropTypes.style,
    trailingIconContainerStyle: PropTypes.style,
    leadingIconStyle: PropTypes.style,
    trailingIconStyle: PropTypes.style,
    contentContainerStyle: PropTypes.style,
    text1Style: PropTypes.style,
    text2Style: PropTypes.style,
    activeOpacity: PropTypes.number
};

BaseToast.defaultProps = {
    leadingIcon: undefined,
    text1: undefined,
    text2: undefined,
    onPress: undefined,
    onLeadingIconPress: undefined,
    onTrailingIconPress: undefined,
    style: undefined,
    leadingIconContainerStyle: undefined,
    trailingIconContainerStyle: undefined,
    leadingIconStyle: undefined,
    trailingIconStyle: undefined,
    contentContainerStyle: undefined,
    text1Style: undefined,
    text2Style: undefined,
    activeOpacity: 0.8
};

export default BaseToast;
