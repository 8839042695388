export default {
  'component.agentdocuments.title': 'Dokumenter',
  'component.agentdocuments.description':
    'Last opp documenter til kjøper eller selger her. Klikk på last opp document og angi et document navn og hvem dokumentet skal sendes til.',
  'component.agentdocuments.documentname': 'Dokumentnavn',
  'component.agentdocuments.documentname.location1': 'Lastet opp til selger og kjøper',
  'component.agentdocuments.documentname.location2': 'Lastet opp til selger',
  'component.agentdocuments.documentname.location3': 'Lastet opp til kjøper',
  'component.agentdocuments.uploaddocument': 'Last opp dokument',
  'component.agentdocuments.modal.title': 'Last opp dokument',
  'component.agentdocuments.modal.documenttitle': 'Dokument tittel',
  'component.agentdocuments.modal.documenttitle.placeholder': 'Skriv inn dokumenttittel',
  'component.agentdocuments.modal.upload': 'Last opp',
  'component.agentdocuments.modal.documentisfor': 'Dette dokumentet er til?',
  'component.agentdocuments.modal.seller': 'Selger',
  'component.agentdocuments.modal.buyer': 'Kjøper',
  'component.agentdocuments.deletetext': 'Er du sikker på at du vil slette dette dokumentet?',

};
