import {Dimensions, StyleSheet} from 'react-native';

import {Color} from '../utils';
import ThemeUtils from '../utils/ThemeUtils';

const myWidth = Dimensions.get('window').width;
const myHeight = Dimensions.get('window').height;
const relativeWidth = num => (myWidth * num) / 100;
const Style = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.WHITE
    },
    safeArea: {
        flex: 1,
        backgroundColor: Color.PRIMARY_DARK
    },
    flexRow: {
        flex: 1,
        flexDirection: 'row'
    },
    flex: {
        flex: 1,
    },
    selfCenter: {
        alignSelf: 'center'
    },
    content_center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollCont: {
        paddingBottom: 80
    },
    default_elevation: {
        backgroundColor: Color.WHITE,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    blankHeader: {
        backgroundColor: Color.WHITE,
        shadowColor: Color.BLACK,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
    },

    loader: {
        marginTop: 20
    },
    loaderSize: {
        height: ThemeUtils.fontSmall,
        width: ThemeUtils.fontSmall,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center'
    },


    inputExtra: {
        borderRadius: 4,
        minWidth: relativeWidth(3),
        backgroundColor: Color.GRAY,
        flex: 1,
        marginBottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    dot: {
        end: 8,
        top: 8,
        position: 'absolute',
        backgroundColor: Color.RED,
        borderWidth: 1,
        borderColor: Color.PRIMARY,
        borderRadius: 4,
        height: 8,
        width: 8
    }
});

export default Style;
