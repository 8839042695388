import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN

const styles = StyleSheet.create({
    cont: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    contentCont: {
        marginHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(18) : ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    lbl: {
        marginVertical: 10
    },
    radio: {
        flexDirection: 'row',
        marginBottom: 10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    lblCont: {
        flexDirection: 'row',
        marginTop: 10,
        alignItems: 'center'
    },
    logo: {
        alignSelf: 'center',
        height: ThemeUtils.responsiveScale(92),
        width: ThemeUtils.responsiveScale(180),
        marginVertical: ThemeUtils.relativeRealHeight(2)
    },
    nameCont: {
        marginEnd: ThemeUtils.relativeWidth(2)
    },
    totalLblCont: {
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderWidth: 1,
        marginTop:15,
        borderColor: Color.BLACK,
    }
});

export default styles;
