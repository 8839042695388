import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    scrollCont: {
        flexGrow: 1,

        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    modalContent: {
        flex: 1,
        backgroundColor: Color.PRIMARY_LIGHT,

        justifyContent:'center',
        paddingHorizontal:ThemeUtils.relativeRealWidth(10)
    },
    inptCont: {
        backgroundColor: Color.WHITE,
        borderRadius:8,
        paddingVertical:ThemeUtils.relativeRealHeight(1),
    },
    btnCont:{
        flexDirection:'row',
        alignItems: 'center'
    },
    divider:{
        width:1,
        backgroundColor:Color.PRIMARY_LIGHT
    },
    btnModal:{
        alignItems:'center',
        justifyContent:'center',
        flex:1,
        height:ThemeUtils.relativeRealHeight(4)
    }
});


