export default {
  'component.rate.title': 'Takst',
  'component.rate.description':
    'Vi anbefaler alle å selge hjem med en pris- eller tilstandsrapport. Hvis du ordner takstmannen ypurself, sjekk dette i skjemaet nedenfor, og du vil gi et nytt skjema for å legge inn nøkkelinformasjonen og løsningen for å laste opp takstdokumentet. Hvis du vil at vi skal kommunisere med takstmann, velger du tid og bestiller her. Bestillingen er uforpliktende og din bil vil bli kontaktet av og takstmann for ytterligere avklaring av de forskjellige takstproduktene og bekreftelse av tid.',
  'component.rate.praiser.checkbox': 'Jeg ordner takstmannen på egen hånd',
  'component.rate.content.line1.heading1': 'Dat0',
  'component.rate.prefered.desc':
    'Angi ønsket vindu for når du vil at Taksmann skal besøke deg. Minimumstidsvindu er 4 timer.',
  'component.rate.content.line2.heading1': 'Tidligste starttid',
  'component.rate.content.line2.heading2': 'Siste start tidspunkt',
  'component.rate.content.line3.heading1': 'kommentarer',
  'component.rate.content.line3.placeholder': 'Skriv Kommentarene Dine Her...',
  'component.rate.content.line4.button1': 'Large',
  'component.rate.content.line4.button2': 'Avbryt',
  'component.rate.taxdate': 'Takstdato',
  'component.rate.taxdate.placeholder': 'Angi takstdato',
  'component.rate.construction': 'Byggeår',
  'component.rate.construction.placeholder': 'Angi byggeår',
  'component.rate.valuerate': 'Verditakst',
  'component.rate.valuerate.placeholder': 'Angi verditakst',
  'component.rate.bta': 'BTA',
  'component.rate.bta.placeholder': 'Angi BTA',
  'component.rate.loanrate': 'Lånesats',
  'component.rate.loanrate.placeholder': 'Angi lånesats',
  'component.rate.bra': 'BRA',
  'component.rate.bra.placeholder': 'Angi BRA',
  'component.rate.prom': 'P-rom',
  'component.rate.prom.placeholder': 'Angi P-rom',
  'component.rate.uploadDoc': 'Last opp takstdokument',
};
