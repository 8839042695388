export const Color = {

    PRIMARY: '#191919',
    SECONDARY: '#A5875B',
    PRIMARY_LIGHT: '#19191977',
    PRIMARY_TRANSPARENT: '#191919AA',
    PRIMARY_LIGHTER: '#19191955',
    PRIMARY_DARK: '#191919', //Statusbar color of Android or ios App
    PRIMARY_TEXT_COLOR: '#191919', //Primary Text color
    SECONDARY_TEXT_COLOR: '#21212188', // Secondary text color like placeholder
    TERNARY_TEXT_COLOR: '#A5875B',
    EDIT_ICON: '#0889CC',
    COLOR_ACCENT: '#A5875B',
    ICON_BUTTON_ARROW:'#C5B8C4',
    INPUT_BORDER:'#1919194D',

    TEXT_COLOR_RED:'#E02832',
    BORDER_RED:'#E028324D',
    TEXT_COLOR_ORANGE:'#E0881B',
    BORDER_ORANGE:'#E0881B4D',
    TEXT_COLOR_BLUE:'#0589CC',
    BORDER_BLUE:'#0589CC4D',
    TEXT_COLOR_GREEN:'#25CC57',
    BORDER_GREEN:'#25CC574D',

    PRIMARY_BG_COLOR: '#191919',
    SECONDARY_BG_COLOR: '#ffffff',
    UPLOAD_BG_COLOR:'#C7C7C7',

    DARK_GRAY:'#C7C7C7',
    GRAY:"#F5F5F5",
    LIGHT_GRAY:"#FFFFFF4D",

    WHITE: '#FFFFFF',
    BLACK: '#000000',
    RED: '#FE4D4E',
    GREEN: '#2BD454',
    YELLOW: '#FFCC33',
    VIOLATE: '#B153E6',
    LIGHT_BLUE: '#33CCFF',

    TRANSPARENT: 'transparent',
    TRANSPARENT_BLACK: '#00000055',
    TRANSPARENT_WHITE: '#FFFFFF66',


    FACEBOOK_COLOR: '#3b5998',
    GOOGLE_COLOR: '#dd4b39',
    LINKEDIN_COLOR: '#0E76A8',
    TWITTER_COLOR: '#00ACEE',

    MessageColor: {
        SUCCESS: '#45A744',
        FAILED: '#DC3545',
        INFO: '#2B73B6',
    },
};
