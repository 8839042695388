import React, {useState} from 'react';
//Components
import {ActivityIndicator, Animated, ScrollView, View} from 'react-native';
import {
    Attachment,
    CustomButton,
    Header,
    Hr,
    IndicatorViewPager,
    Label,
    PagerTabIndicator
} from "components/src/components";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    openInNewTab,
    Strings,
    ThemeUtils
} from "components/src/utils";
import styles from "./styles";
import SellerInterview from "./SellerInterview";
import {useQuery} from "@apollo/react-hooks";
import {
    BOLIG_SELGER_INSURANCE,
    BUYER_CONTRACT_DOC_QUERY,
    ENERGY_LABELING,
    SETTLEMENT_INFO
} from "../../../../../../api/query";

const TABS = [{
    text: Strings.energyLabeling
}, {
    text: Strings.sellersInterview
}, {
    text: Strings.settlementInfo
}, {
    text: Strings.boligselgerforsikring
}, {
    text: Strings.purchasingContract
}];

const getEnergyLabelingValues = (value) => {
    switch (value) {
        case "Morkegronn":
            return "Mørkegrønn";
        case "Green":
            return "Grønn";
        case "Gul":
            return "Gul";
        case "Orange":
            return "Oransje";
        case "Red":
            return "Rød";
    }
};

const energyLabeling = [{
    id: 'letter_code',
    title: Strings.letterCode,
    value: 'A'
}, {
    id: 'color_code',
    title: Strings.colorCode,
    value: 'Green',
    formating: getEnergyLabelingValues
}, {
    id: 'HouseHeatType',
    title: Strings.howIsHouseHeated,
    value: 'Electricity, Heating, Solar Power'
}];

const address = [{
    id: 'address',
    title: Strings.address,
}, {
    id: 'city',
    title: Strings.city,
}, {
    id: 'zipcode',
    title: Strings.zipCode,
}];

const loan = [{
    id: 'denomination_kr',
    title: Strings.denominationKr,
}, {
    id: 'residual_debt_kr',
    title: Strings.residualDebtKr,
}, {
    id: 'reminder_date',
    title: Strings.reminderOnDate,
    format: DateUtils.dd_mm_yyyy
}];

const bank = [{
    id: 'bank_contact',
    title: Strings.contactPerson,
}, {
    id: 'bank_phone',
    title: Strings.phone,
}, {
    id: 'bank_email',
    title: Strings.email,
}, {
    id: 'loan_no',
    title: Strings.loanNo,
}];

const loanInfo = [{
    title: Strings.loan,
    subText: loan
}, {
    title: Strings.bank,
    subText: bank
}];

const receivables = [{
    id: 'receivables_account_no',
    title: Strings.accountNoForTransferOfReceivable,

}, {
    id: 'percentage',
    title: "Prosentdel",

}]

const settlementInfo = [{

    title: Strings.newAddress,
    subText: address,
    isSection: true,
}, /*, {
    title: 'Additional Loan 1',
    subText: bank,
    isSection: true,

}, {
    title: Strings.receivables,
    subText: receivables
}*/];


export default function InfoFromSeller(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0)
    const [currentScreen, setScreen] = useState(0)
    const propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const contractCommentQuery = useQuery(BUYER_CONTRACT_DOC_QUERY, {
        variables: {
            status: 1,
            propertyId: propertyId,
            commentBy: "SELLER"
        },
        fetchPolicy: 'network-only'
    });

    const energyLabelingQuery = useQuery(ENERGY_LABELING, {
        variables: {
            propertyId: propertyId,
            status: 1
        }
    });

    const settlementInfoQuery = useQuery(SETTLEMENT_INFO, {
        variables: {
            propertyId: propertyId,

        }
    });

    const boligQuery = useQuery(BOLIG_SELGER_INSURANCE, {
        variables: {
            propertyId: propertyId,
        }
    })

    const getMarginTop = () => {
        return scrollY.interpolate({
            inputRange: [0, 20],
            outputRange: [20, 0],
            extrapolate: 'clamp'
        })
    };

    const renderInfoLabel = (item, index, data) => {

        return (
            <View style={styles.lblCont}>
                <Label small
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       font_medium>
                    {item.title}
                </Label>
                <Label small
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mb={10}
                       mt={10}>
                    {Array.isArray(data[item.id]) ? data[item.id].map(item => item.name).join(', ') : item.formating ? item.formating(data[item.id]) : data[item.id]}
                </Label>
            </View>
        )
    };

    const renderBankInfo = (item, index, loanData) => {
        return (
            <View style={styles.lblCont}>
                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mt={10}
                       mb={10}
                       font_medium>
                    {`${item.title} ${index + 1}`}
                </Label>
                {item.subText.map((item, index) => renderInfoLabel(item, index, loanData))}
                <View style={styles.lblCont}>
                    <Label small
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           font_medium>
                        {Strings.shouldTheLoanBeRepaid}
                    </Label>
                    <Label small
                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mb={10}
                           mt={10}>
                        {loanData?.is_repaid ? Strings.yes : Strings.no}
                    </Label>
                </View>
            </View>
        )
    };

    const renderLoanInfo = () => {
        if (!settlementInfoQuery?.data) {
            return (
                <Label small
                       font_medium
                       style={CommonStyle.loader}>
                    {"Ingen data"}
                </Label>)
        }
        return settlementInfoQuery?.data?.property_settlement_information?.settlement_loans?.map((loanData, index) => {
            return (
                <>
                    {loanInfo.map((item) => renderBankInfo(item, index, loanData))}
                    <Hr/>
                </>)
        })
    }


    const renderReceivablesInfo = () => {
        if (!settlementInfoQuery?.data) {
            return (
                <Label align={'center'}
                       font_medium
                       style={CommonStyle.loader}>
                    {"Ingen data"}
                </Label>)
        }
        return settlementInfoQuery?.data?.property_settlement_information?.settlement_receivable?.map((receivablesData, index) => {
            return (
                <View style={styles.lblCont}>
                    <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                           mt={10}
                           mb={10}
                           font_medium>
                        {/*{index > 0 ? `${Strings.additionalReceivables} ${index}` : Strings.receivables}*/}
                        {`Eventuelt tilgodehavende ${index + 1}`}
                    </Label>
                    {receivables.map((item) => renderInfoLabel(item, index, receivablesData))}
                    {index > 0 ? <Hr/> : null}
                </View>
            )
        })
    }

    const renderInfoTitle = (item) => {
        return (
            <View style={styles.lblCont}>
                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mt={15}
                       font_medium>
                    {item.title}
                </Label>
                {item.subText.map((item, index) => renderInfoLabel(item, index, settlementInfoQuery?.data?.property_settlement_information ?? {}))}
                {item.isSection && (<Hr marginTop={10}/>)}
            </View>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}
                                  scrollEnabled={!ThemeUtils.isTablet()}/>;
    };

    return (
        <View style={CommonStyle.flex}>

            <Header navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.infoFromSeller}
                    headerRightFirst={currentScreen === 2 ? 'download' : null}
                    animatedValue={scrollY}
            />


            <Animated.View style={{marginTop: ThemeUtils.relativeRealHeight(1.5), flex: 1}}>
                <Icon name={'info-from-seller'}
                      style={{marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}
                      size={ThemeUtils.fontXXLarge}
                      color={Color.PRIMARY}/>
                <Label small
                       mt={20}
                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                       mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {'Her finner du informasjon fra selger i forskjellige faner. Dokumenter som er lastet opp overføres direkte til meglersystemet.'}
                </Label>
                <IndicatorViewPager indicator={renderTabIndicator()}
                                    onPageSelected={(e) => setScreen(e.position)}
                                    style={CommonStyle.flex}>
                    <View style={{marginTop: 20}}>
                        <Animated.ScrollView scrollEventThrottle={1}>
                            {energyLabelingQuery.loading && !energyLabelingQuery?.data ?
                                (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) : !energyLabelingQuery.data?.moovin_energy_labeling ? (
                                    <Label align={'center'}
                                           font_medium>
                                        {"Ingen data"}
                                    </Label>
                                ) : (
                                    <>
                                        {energyLabeling.map((item, index) => renderInfoLabel(item, index, energyLabelingQuery?.data?.moovin_energy_labeling ?? {}))}
                                        <Label small
                                               font_medium
                                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                               mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                               mt={25}
                                               mb={15}>
                                            {Strings.energyCerti}
                                        </Label>
                                        <Attachment isDownloadable
                                                    onPressDownload={() => {
                                                        openInNewTab(`${Constants.APIConfig.STORAGE_URL}${energyLabelingQuery.data?.moovin_energy_labeling?.document?.doc_url}`)
                                                    }}
                                                    name={energyLabelingQuery.data?.moovin_energy_labeling?.document?.title}
                                                    style={{marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN}}/>
                                    </>
                                )}

                        </Animated.ScrollView>
                    </View>
                    <View style={styles.cont}>

                        <SellerInterview propertyId={propertyId}/>

                    </View>
                    <View style={styles.cont}>
                        <Animated.ScrollView scrollEventThrottle={1}>
                            {!settlementInfoQuery?.data?.property_settlement_information ? (
                                <Label align={'center'}
                                       font_medium
                                       style={CommonStyle.loader}>
                                    {"Ingen data"}
                                </Label>
                            ) : (
                                <>
                                    {settlementInfo.map(renderInfoTitle)}
                                    <Label font_medium
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mt={ThemeUtils.relativeRealHeight(2)}>
                                        {"Det er ingen lån på eiendommen"}
                                    </Label>
                                    <Label small
                                           ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                           mt={5}
                                           mb={5}>
                                        {settlementInfoQuery?.data?.property_settlement_information?.loan_status ? Strings.no : Strings.yes}
                                    </Label>
                                    {renderLoanInfo()}
                                    {renderReceivablesInfo()}
                                </>)}

                        </Animated.ScrollView>
                    </View>
                    <View>

                        <ScrollView>
                            {boligQuery?.loading ? (
                                <ActivityIndicator color={Color.PRIMARY}
                                                   style={CommonStyle.loader}/>
                            ) : boligQuery?.data?.boligselger_Insurance_list?.filter(item => item?.selected_in_property).length ? (
                                <View style={styles.insuranceCont}>
                                    {boligQuery?.data?.boligselger_Insurance_list?.filter(item => item?.selected_in_property).map(item => (
                                        <Label mt={20}
                                               color={item?.selected_in_property ? Color.PRIMARY_TEXT_COLOR : Color.DARK_GRAY}>
                                            {item?.name}
                                        </Label>
                                    ))}

                                </View>) : (
                                <Label align={'center'}
                                       font_medium
                                       style={CommonStyle.loader}>
                                    {"Ingen data"}
                                </Label>
                            )}
                        </ScrollView>
                    </View>
                    <View>
                        <ScrollView>
                            <View>
                                {contractCommentQuery?.data?.buyer_contract_doc ? (
                                    <View style={styles.container}>
                                        <CustomButton title={"Åpne PDF"}
                                                      disabled={!contractCommentQuery?.data?.buyer_contract_doc?.doc_url}
                                                      mt={20}
                                                      mb={15}
                                                      onPress={() => {
                                                          openInNewTab(`${Constants.APIConfig.STORAGE_URL}${contractCommentQuery?.data?.buyer_contract_doc?.doc_url}`)
                                                      }}
                                        />
                                        <Label small
                                               font_medium>
                                            {Strings.Comments}
                                        </Label>

                                        {contractCommentQuery?.data?.buyer_contract_doc?.contractDocComment?.map(item => (
                                            <>

                                                <Label small
                                                       mt={10}
                                                       mb={5}
                                                       color={Color.PRIMARY}>
                                                    {item.comment}
                                                </Label>
                                                <Label small
                                                       font_medium
                                                       mb={10}
                                                       color={Color.DARK_GRAY}>
                                                    {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                                </Label>
                                            </>
                                        ))}
                                    </View>) : (
                                    <Label small
                                           font_medium
                                           align={'center'}
                                           style={CommonStyle.loader}>
                                        {"Ingen data"}
                                    </Label>
                                )}
                            </View>
                        </ScrollView>
                    </View>
                </IndicatorViewPager>
            </Animated.View>


        </View>
    )

}
