import React, {useEffect, useState} from "react";
//Components
import {ActivityIndicator, Animated, Image, ScrollView, TouchableOpacity, View} from "react-native";
import {
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    FlatGrid,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator
} from "components/src/components";
//Third party
//redux
//Utils
import {Color, CommonStyle, Constants, Icon, IS_WEB, Strings, ThemeUtils, validation,} from "components/src/utils";
import {styles} from "./styles.js";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    ADD_UPDATE_PRODUCT_GALLARY_IMAGE,
    DELETE_GALLARY_IMAGES,
    OFFICE_ADD_IMAGE_AFTER_BEFARING,
    OFFICE_DELETE_IMAGE_AFTER_BEFARING,
    OFFICE_REPLACE_IMAGE_AFTER_BEFARING,
    REPLACE_GALLERY_IMAGE
} from "../../../../../api/mutation";
import PhotoDetails from "../../../../../components/view/PhotoDetails";
import {FETCH_OFFICE_DETAIL} from "../../../../../api/query";
import {connect} from "react-redux";

const TABS = [{
    text: "Before Produktgalleri",
}, {
    text: "After Produktgalleri"
}];
const scrollY = new Animated.Value(0);

function ProductGallery(props) {
    const {navigation, history} = props;

    const [details, setDetails] = useState(IS_WEB ? history.location?.state?.details ?? {} : navigation.getParam('details', {}));
    const [headLine, onChangeHeadLine] = useState(details?.headline ?? "");
    const [errHeadLine, setErrHeadline] = useState("");
    const [photo, onPhotoPiked] = React.useState([]);
    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [images, setImages] = useState(details?.ProductGalleryImages ?? []);
    const [imageForPreview, setImageForPreview] = useState({});

    const [afterDetails, setAfterDetails] = useState(IS_WEB ? history.location?.state?.subDetails ?? {} : navigation.getParam('subDetails', {}));
    const [afterHeadLine, onChangeAfterHeadLine] = useState(afterDetails?.headline ?? "");
    const [errAfterHeadLine, setErrAfterHeadline] = useState("");
    const [afterPhoto, onAfterPhotoPiked] = React.useState([]);
    const [afterSelectionEnable, setAfterSelectionEnable] = useState(false);
    const [afterSelectedImages, setAfterSelectedImages] = useState([]);
    const [isAfterPhotoDetailVisible, setAfterPhotoDetailVisible] = useState(false);
    const [afterDeleteVisible, setAfterDeleteVisible] = useState(false);
    const [afterImages, setAfterImages] = useState(afterDetails?.AfterProductGalleryImages ?? []);
    const [afterImageForPreview, setAfterImageForPreview] = useState({});

    const {loading, data, refetch, error} = useQuery(FETCH_OFFICE_DETAIL, {
        fetchPolicy: 'network-only',
        variables: {
            office_id: props.user?.office_id
        }
    });

    useEffect(() => {
        if (data?.office?.product_gallery) {
            setDetails(data?.office?.product_gallery)
            if (data?.office?.product_gallery?.ProductGalleryImages?.length) {
                setImages(data?.office?.product_gallery?.ProductGalleryImages)
            }
        }

        if (data?.office?.after_product_gallery) {
            setAfterDetails(data?.office?.after_product_gallery)
            onChangeAfterHeadLine(data?.office?.after_product_gallery?.headline)
            if (data?.office?.after_product_gallery?.AfterProductGalleryImages?.length) {
                setAfterImages(data?.office?.after_product_gallery?.AfterProductGalleryImages)
            }
        }

    }, [data]);


    const [updateDetails, updateDetailsMutation] = useMutation(ADD_UPDATE_PRODUCT_GALLARY_IMAGE);

    const [replaceImage, replaceImageMutation] = useMutation(REPLACE_GALLERY_IMAGE);

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_GALLARY_IMAGES);

    const [updateAfterDetails, updateAfterDetailsMutation] = useMutation(OFFICE_ADD_IMAGE_AFTER_BEFARING);

    const [replaceAfterImage, replaceAfterImageMutation] = useMutation(OFFICE_REPLACE_IMAGE_AFTER_BEFARING);

    const [deleteAfterImages, deleteAfterImagesMutation] = useMutation(OFFICE_DELETE_IMAGE_AFTER_BEFARING);


    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);

    useEffect(() => {
        setAfterSelectionEnable(!!afterSelectedImages.length)
    }, [afterSelectedImages]);


    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };

    const addOrRemoveImageAfter = (id) => {
        let updatedImages = afterSelectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setAfterSelectedImages(updatedImages)
    };

    const renderImageGrid = (data) => {
        return (
            <View style={styles.ImageGridView}>
                <FlatGrid data={data}
                          spacing={15}
                          itemDimension={ThemeUtils.responsiveScale(150)}
                          renderItem={({item}) => {
                              return (
                                  <TouchableOpacity
                                      key={item.id}
                                      onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                                      onPress={() => {
                                          if (selectionEnable) {
                                              addOrRemoveImage(item.id)
                                          } else {
                                              setImageForPreview(item);
                                              setImageForPreview(item);
                                              setPhotoDetailVisible(true)
                                          }
                                      }}
                                      activeOpacity={0.7}>

                                      <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.url}`}}

                                             style={styles.GridImage}/>
                                      {selectionEnable ? (<View style={[styles.selectedImage]}>
                                          <Icon name={'tickmark'}
                                                size={ThemeUtils.fontNormal}
                                                color={selectedImages.includes(item.id) ? Color.SECONDARY : Color.GRAY}/>
                                      </View>) : null}

                                  </TouchableOpacity>

                              )
                          }}/>
            </View>
        )
    };

    const renderAfterImageGrid = (data) => {
        return (
            <View style={styles.ImageGridView}>
                <FlatGrid data={data}
                          spacing={15}
                          itemDimension={ThemeUtils.responsiveScale(150)}
                          renderItem={({item}) => {
                              return (
                                  <TouchableOpacity
                                      key={item.id}
                                      onLongPress={() => afterSelectionEnable ? null : addOrRemoveImageAfter(item.id)}
                                      onPress={() => {
                                          if (afterSelectionEnable) {
                                              addOrRemoveImageAfter(item.id)
                                          } else {
                                              setAfterImageForPreview(item);
                                              setAfterPhotoDetailVisible(true)
                                          }
                                      }}
                                      activeOpacity={0.7}>

                                      <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.url}`}}

                                             style={styles.GridImage}/>
                                      {afterSelectionEnable ? (<View style={[styles.selectedImage]}>
                                          <Icon name={'tickmark'}
                                                size={ThemeUtils.fontNormal}
                                                color={afterSelectedImages.includes(item.id) ? Color.SECONDARY : Color.GRAY}/>
                                      </View>) : null}

                                  </TouchableOpacity>

                              )
                          }}/>
            </View>
        )
    };

    const renderPhotoGallery = () => {
        return (
            <>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={deleteVisible}
                                onNegativePress={() => setDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteImages({
                                        variables: {
                                            ids: selectedImages,
                                            gallaryId: details?.id
                                        }
                                    }).then(() => {
                                        let updatedImages = images.slice();
                                        updatedImages = updatedImages.filter(image => !selectedImages.includes(image.id));
                                        setImages(updatedImages);
                                        setSelectedImages([])

                                    }).catch(() => {

                                    });
                                    setDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>


                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {Strings.Photos}
                </Label>)}
                {renderImageGrid(images)}
            </>
        );
    };

    const renderAfterPhotoGallery = () => {
        return (
            <>
                {afterSelectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${afterSelectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={afterDeleteVisible}
                                onNegativePress={() => setAfterDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteAfterImages({
                                        variables: {
                                            ids: afterSelectedImages,
                                            galleryId: afterDetails?.id
                                        }
                                    }).then(() => {
                                        let updatedImages = afterImages.slice();
                                        updatedImages = updatedImages.filter(image => !afterSelectedImages.includes(image.id));
                                        setAfterImages(updatedImages);
                                        setAfterSelectedImages([])

                                    }).catch(() => {

                                    });
                                    setAfterDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteAfterImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setAfterDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setAfterSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                    {Strings.Photos}
                </Label>)}
                {renderAfterImageGrid(afterImages)}
            </>
        );
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}/>;
    };

    return (
        <View style={CommonStyle.flex}>
            <Header
                navigation={IS_WEB ? history : navigation}
                animatedValue={scrollY}
            />
            <View style={styles.viewicon}>
                <Icon
                    size={ThemeUtils.fontXXLarge}
                    name={"product-gallery"}
                    color={Color.PRIMARY_DARK}
                />
                <Label large
                       font_medium
                       ml={10}>
                    {/*{Strings.productGallery}*/}
                    {`${Strings.productGallery} - 06`}
                </Label>
            </View>
            <IndicatorViewPager style={CommonStyle.flex}
                                indicator={_renderTabIndicator()}>
                <View>

                    <ScrollView
                        scrollEventThrottle={1}
                        style={{marginTop: 20}}>
                        <View>
                            <View style={styles.container}>
                                <View style={styles.topContainer}>

                                    <InputField
                                        type={"text"}
                                        textArea={false}
                                        placeholder={Strings.headLine}
                                        onChange={(text) => onChangeHeadLine(text)}
                                        labelProps={{
                                            font_medium: true,
                                            small: true,
                                        }}
                                        error={errHeadLine}
                                        labelText={Strings.headLine}
                                        value={headLine}
                                    />
                                    <Label small font_medium mb={15}>
                                        {Strings.uploadPhotoTitle}
                                    </Label>
                                    <FilePicker multiple
                                                style={{alignSelf: 'flex-start'}}
                                                type={FileType.IMAGE}
                                                onFilePicked={(data, files) => onPhotoPiked(files)}>

                                        <View style={styles.SubViewIcon}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.INPUT_BORDER}/>

                                        </View>
                                    </FilePicker>
                                    <View/>
                                </View>

                                <View style={styles.ImageGridView}>

                                    <FlatGrid data={photo}
                                              spacing={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              itemDimension={ThemeUtils.responsiveScale(150)}
                                              renderItem={({item}) => (
                                                  <Image source={{uri: IS_WEB ? URL.createObjectURL(item) : item.uri}}
                                                         style={styles.GridImage}/>
                                              )}/>
                                </View>
                                {/*<View>
                                    <InputField
                                        type={"text"}
                                        mt={16}
                                        textArea={true}
                                        placeholder={Strings.discription}
                                        onChange={(text) => onChangeDiscription(text)}
                                        labelProps={{
                                            font_medium: true,
                                            small: true,
                                        }}
                                        labelText={Strings.discription}
                                        value={discription}
                                    />
                                </View>*/}

                            </View>
                            <View style={styles.buttonContainer}>
                                <CustomButton loading={updateDetailsMutation.loading}
                                              font_medium
                                              onPress={() => {
                                                  setErrHeadline(validation('headline', headLine));
                                                  if (!errHeadLine) {
                                                      let variables = {
                                                          headline: headLine,
                                                      };
                                                      if (photo.length) {
                                                          variables.images = photo
                                                      }
                                                      updateDetails({
                                                          variables: {
                                                              input: variables
                                                          }
                                                      }).then(() => {
                                                          onPhotoPiked([])
                                                          refetch();
                                                      }).catch(() => {

                                                      })
                                                  }
                                              }}
                                              mr={ThemeUtils.relativeWidth(1.5)}
                                              style={{flex: 1}} // Do not remove inline will not work in web
                                              title={Strings.save}
                                />

                                <CustomButton ml={ThemeUtils.relativeWidth(1.5)}
                                              font_medium
                                              onPress={() => {
                                                  if (IS_WEB) {
                                                      history.goBack();
                                                  } else {
                                                      navigation.goBack();
                                                  }
                                              }}
                                              style={{
                                                  flex: 1,
                                                  borderWidth: 1,
                                                  borderColor: Color.DARK_GRAY,
                                              }} // Do not remove inline will not work in web
                                              textColor={Color.PRIMARY}
                                              bgColor={Color.WHITE}
                                              title={Strings.cancel}
                                />
                            </View>
                            <Hr/>
                            <View>

                                <Modal visible={isPhotoDetailVisible}
                                       onRequestClose={() => setPhotoDetailVisible(false)}>
                                    <PhotoDetails onClose={(isFromDelete, replaceData) => {

                                        if (replaceData) {
                                            setImages(replaceData?.data?.replace_gallery_images?.ProductGalleryImages)
                                        }
                                        if (isFromDelete) {
                                            let updatedImages = images.slice();
                                            updatedImages = updatedImages.filter(image => image.id !== imageForPreview.id);
                                            setImages(updatedImages)
                                        }
                                        setPhotoDetailVisible(false)
                                    }}

                                                  loading={replaceImageMutation.loading}
                                                  onReplaceImage={replaceImage}
                                                  replaceVariables={{
                                                      id: imageForPreview?.id,
                                                      gallaryId: details?.id
                                                  }}
                                                  deleteMutation={DELETE_GALLARY_IMAGES}
                                                  variables={{
                                                      ids: [imageForPreview?.id],
                                                      gallaryId: details?.id
                                                  }}
                                                  refetchQuery
                                                  imageForPreview={imageForPreview}
                                    />
                                </Modal>
                                {renderPhotoGallery()}

                            </View>
                        </View>
                    </ScrollView>

                </View>
                <View>
                    <ScrollView scrollEventThrottle={1}
                                style={{marginTop: 20}}>
                        <View>
                            <View style={styles.container}>
                                <View style={styles.topContainer}>

                                    <InputField
                                        type={"text"}
                                        textArea={false}
                                        placeholder={Strings.headLine}
                                        onChange={(text) => onChangeAfterHeadLine(text)}
                                        labelProps={{
                                            font_medium: true,
                                            small: true,
                                        }}
                                        error={errAfterHeadLine}
                                        labelText={Strings.headLine}
                                        value={afterHeadLine}
                                    />
                                    <Label small font_medium mb={15}>
                                        {Strings.uploadPhotoTitle}
                                    </Label>
                                    <FilePicker multiple
                                                style={{alignSelf: 'flex-start'}}
                                                type={FileType.IMAGE}
                                                onFilePicked={(data, files) => onAfterPhotoPiked(files)}>

                                        <View style={styles.SubViewIcon}>
                                            <Icon name={'add'}
                                                  size={ThemeUtils.responsiveScale(60)}
                                                  color={Color.INPUT_BORDER}/>

                                        </View>
                                    </FilePicker>
                                    <View/>
                                </View>

                                <View style={styles.ImageGridView}>

                                    <FlatGrid data={afterPhoto}
                                              spacing={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                              itemDimension={ThemeUtils.responsiveScale(150)}
                                              renderItem={({item}) => (
                                                  <Image source={{uri: IS_WEB ? URL.createObjectURL(item) : item.uri}}
                                                         style={styles.GridImage}/>
                                              )}/>
                                </View>
                                {/*<View>
                                    <InputField
                                        type={"text"}
                                        mt={16}
                                        textArea={true}
                                        placeholder={Strings.discription}
                                        onChange={(text) => onChangeDiscription(text)}
                                        labelProps={{
                                            font_medium: true,
                                            small: true,
                                        }}
                                        labelText={Strings.discription}
                                        value={discription}
                                    />
                                </View>*/}

                            </View>
                            <View style={styles.buttonContainer}>
                                <CustomButton loading={updateAfterDetailsMutation.loading}
                                              font_medium
                                              onPress={() => {
                                                  setErrAfterHeadline(validation('headline', afterHeadLine));
                                                  if (!errHeadLine) {
                                                      let variables = {
                                                          headline: afterHeadLine,
                                                      };
                                                      if (afterPhoto.length) {
                                                          variables.images = afterPhoto
                                                      }
                                                      updateAfterDetails({
                                                          variables: {
                                                              input: variables
                                                          }
                                                      }).then(() => {
                                                          onAfterPhotoPiked([])
                                                          refetch();
                                                      }).catch(() => {

                                                      })
                                                  }
                                              }}
                                              mr={ThemeUtils.relativeWidth(1.5)}
                                              style={{flex: 1}} // Do not remove inline will not work in web
                                              title={Strings.save}
                                />

                                <CustomButton ml={ThemeUtils.relativeWidth(1.5)}
                                              font_medium
                                              onPress={() => {
                                                  if (IS_WEB) {
                                                      history.goBack();
                                                  } else {
                                                      navigation.goBack();
                                                  }
                                              }}
                                              style={{
                                                  flex: 1,
                                                  borderWidth: 1,
                                                  borderColor: Color.DARK_GRAY,
                                              }} // Do not remove inline will not work in web
                                              textColor={Color.PRIMARY}
                                              bgColor={Color.WHITE}
                                              title={Strings.cancel}
                                />
                            </View>
                            <Hr/>
                            <View>

                                <Modal visible={isAfterPhotoDetailVisible}
                                       onRequestClose={() => setAfterPhotoDetailVisible(false)}>
                                    <PhotoDetails onClose={(isFromDelete, replaceData) => {

                                        if (replaceData) {
                                            refetch();
                                        }
                                        if (isFromDelete) {
                                            let updatedImages = afterImages.slice();
                                            updatedImages = updatedImages.filter(image => image.id !== afterImageForPreview.id);
                                            setAfterImages(updatedImages)
                                        }
                                        setAfterPhotoDetailVisible(false)
                                    }}

                                                  loading={replaceAfterImageMutation.loading}
                                                  onReplaceImage={replaceAfterImage}
                                                  replaceVariables={{
                                                      id: afterImageForPreview?.id,
                                                      galleryId: afterDetails?.id
                                                  }}
                                                  deleteMutation={OFFICE_DELETE_IMAGE_AFTER_BEFARING}
                                                  variables={{
                                                      ids: [afterImageForPreview?.id],
                                                      galleryId: afterDetails?.id
                                                  }}
                                                  refetchQuery
                                                  imageForPreview={afterImageForPreview}
                                    />
                                </Modal>
                                {renderAfterPhotoGallery()}

                            </View>
                        </View>
                    </ScrollView>
                </View>
            </IndicatorViewPager>

        </View>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(ProductGallery);
