import React, {useEffect, useState} from 'react';
//components
import {ActivityIndicator, Animated, BackHandler, FlatList, SafeAreaView, TextInput, View} from 'react-native';
import {CheckBox, CustomButton, Header, Hr, Label, Modal, Ripple, Tag} from "components/src/components";
//utils
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {useQuery} from "@apollo/react-hooks";
import {SIGNATURE_PROPERTY_LIST} from "components/src/api/query";

const FILTERS = [{
    name: Strings.seller1Signed,
    value: 1
}, {
    name: Strings.seller2Pending,
    value: 2
}, {
    name: Strings.bothSellerSigned,
    value: 3
}, {
    name: Strings.completed,
    value: 4
}];

export default function SignatureAssignment(props) {
    const {history, navigation} = props;
    const scrollY = new Animated.Value(0);
    const scrollYSearch = new Animated.Value(0);

    const [filterVisible, setFilterVisible] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filterParams, setFilterParams] = useState({});

    const [searchText, setSearchText] = useState('');

    const {loading, data, error, fetchMore} = useQuery(SIGNATURE_PROPERTY_LIST, {
        fetchPolicy:'network-only',
        variables: {
            input: {
                key: searchVisible ? searchText : "",
                status: true,
                filter: filterParams,
                first: 50,
                page: 1,
                sorting: {
                    field: "id",
                    order: "DESC"
                }

            }
        },
        onError: () => {

        }
    });

    useEffect(() => {

        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            () => {
                console.log(navigation)
                navigation.popToTop();
                return true;
            }
        );
        return () => backHandler.remove();
    }, []);

    useEffect(() => {
        let filterParams = {};

        if (!filterVisible) {
            if (filters.includes(1)) {
                filterParams.seller_one_signed = 0;
            }
            if (filters.includes(2)) {
                filterParams.seller_two_pending = 1;
            }
            if (filters.includes(3)) {
                filterParams.both_seller_signed = 2;
            }
            if (filters.includes(4)) {
                filterParams.oppdrag_no_assign = 1;
            }
            setFilterParams(filterParams)
        }
    }, [filterVisible]);

    const navigate = (item) => {
        if (IS_WEB) {
            history.push(Routes.AssignmentDetails, {
                assignment: item
            })
        } else {
            navigation.navigate(Routes.AssignmentDetails, {
                assignment: item
            })
        }
    };

    const getSearchBarMarginTop = () => {
        return scrollYSearch.interpolate({
            inputRange: [0, 62],
            outputRange: [110, ThemeUtils.APPBAR_HEIGHT],
            extrapolateRight: 'clamp'
        })
    }

    const getStatusColor = (item) => {
        if (item?.signed_by_agent === 0 && item?.signed_both_seller === 1 && item?.total_owner >= 2) {
            return Color.TEXT_COLOR_ORANGE;
        } else if (item?.signed_by_agent === 0 && item?.signed_both_seller === 2 && item?.total_owner >= 2) {
            return Color.TEXT_COLOR_GREEN;
        } else if (item.signed_by_agent === 0 && item.signed_both_seller === 1 && item.total_owner === 1) {
            return Color.TEXT_COLOR_ORANGE;
        } else if (item?.signed_by_agent === 1 && item?.seller_completed === 1) {
            return Color.TEXT_COLOR_BLUE;
        }
    };

    const getStatusLabel = (item) => {
        if (item?.signed_by_agent === 0 && item?.signed_both_seller === 1 && item?.total_owner >= 2) {
            return Strings.seller2Pending
        } else if (item?.signed_by_agent === 0 && item?.signed_both_seller === 2 && item?.total_owner >= 2) {
            return Strings.bothSellerSigned
        } else if (item.signed_by_agent === 0 && item.signed_both_seller === 1 && item.total_owner === 1) {
            return Strings.seller1Signed
        } else if (item?.signed_by_agent === 1 && item?.seller_completed === 1) {
            return Strings.completed
        }
    };

    const renderAssignments = ({item, index}) => {
        return (
            <View key={index.toString()}>
                <Ripple style={styles.assignmentCont}
                        onPress={() => navigate(item)}>
                    <Tag name={getStatusLabel(item)}
                         color={getStatusColor(item)}/>
                    <Label small
                           mt={15}
                           mb={5}
                           font_medium>
                        {`${item?.street_adress ?? ""} ${item?.zipcode ?? ""}`}
                    </Label>
                    <Label small
                           color={Color.SECONDARY_TEXT_COLOR}>
                        {item?.seller_sign_date ? moment(item.seller_sign_date).format(DateUtils.dd_mm_yyyy) : null}
                    </Label>
                </Ripple>
                <Hr/>
            </View>
        )
    };

    const renderFilterModal = () => {
        return (
            <Modal visible={filterVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setFilterVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setFilterVisible(false)}
                                style={CommonStyle.blankHeader}/>
                        <Animated.ScrollView contentContainerStyle={styles.scrollCont}>
                            <View style={[CommonStyle.flex]}>
                                <View style={styles.filterCont}>
                                    <Label xlarge
                                           font_medium
                                           mb={20}>
                                        {Strings.filters}
                                    </Label>
                                    <Label small
                                           font_medium
                                           mb={10}>
                                        {Strings.status}
                                    </Label>
                                    <CheckBox options={FILTERS}
                                              defaultChecked={filters}
                                              size={ThemeUtils.fontXLarge}
                                              style={styles.chk}
                                              onCheckChanged={setFilters}/>

                                </View>
                                <View style={styles.btnCont}>
                                    <View style={styles.btn}>
                                        <CustomButton title={Strings.apply}
                                                      onPress={() => setFilterVisible(false)}/>
                                    </View>
                                    <View style={styles.btn}>
                                        <CustomButton title={Strings.reset}
                                                      onPress={() => {
                                                          setFilters([]);
                                                          setFilterVisible(false)
                                                      }}
                                                      textColor={Color.PRIMARY}
                                                      borderColor={Color.PRIMARY_LIGHTER}
                                                      borderWidth={1}
                                                      bgColor={Color.WHITE}/>
                                    </View>
                                </View>
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };

    const renderSearchModal = () => {
        const searchBarMarginTop = getSearchBarMarginTop()
        return (
            <Modal visible={searchVisible}
                   hardwareAccelerated
                   animated
                   onRequestClose={() => setSearchVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <View style={CommonStyle.container}>
                        <Header onPressLeft={() => setSearchVisible(false)}
                                initialMarginLeft={20}
                                animatedTitle={Strings.search}
                                animatedValue={scrollYSearch}/>

                        <Animated.View style={[styles.inptSearchCont, {
                            transform: [{translateY: searchBarMarginTop}],
                        }]}>
                            <Icon name={'search'}
                                  color={Color.PRIMARY_LIGHT}
                                  size={ThemeUtils.fontLarge}/>
                            <TextInput style={[styles.inptSearch, IS_WEB ? {outline: 'none'} : null]}
                                       placeholder={Strings.phSearchAssignment}
                                       value={searchText}
                                       onChangeText={setSearchText}/>
                            {searchText ? (
                                <Ripple style={styles.icClear}
                                        onPress={() => setSearchText('')}
                                        rippleContainerBorderRadius={ThemeUtils.fontNormal / 2}>
                                    <Icon name={'close'}
                                          color={Color.WHITE}
                                          size={ThemeUtils.fontXXSmall}/>
                                </Ripple>) : null}
                        </Animated.View>

                        <Animated.ScrollView scrollEventThrottle={1}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollYSearch}}}],
                                                 {useNativeDriver: true})}
                                             contentContainerStyle={[styles.scrollCont]}>
                            <View style={styles.searchCont}>

                                {loading ? (
                                    <ActivityIndicator color={Color.PRIMARY}
                                                       style={CommonStyle.loader}/>
                                ) : searchText ?
                                    <FlatList
                                        data={data?.signature_property?.data}
                                        ListEmptyComponent={
                                            <Label mt={20}
                                                   font_medium
                                                   align={'center'}>
                                                {"Ingen data"}
                                            </Label>
                                        }
                                        renderItem={renderAssignments}
                                        keyExtractor={item => item.id.toString()}/> : null}
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    };
    return (
        <View style={CommonStyle.flex}>
            <Header navigation={IS_WEB ? history : navigation}
                    onPressLeft={() => {
                        IS_WEB ? history.replace(Routes.Super_User_Dashboard) : navigation.popToTop()
                    }}
                    headerRightFirst={'search'}
                    onPressRightSecond={() => setFilterVisible(true)}
                    onPressRightFirst={() => setSearchVisible(true)}
                    headerRightSecond={'filter'}
                    animatedValue={scrollY}
                    animatedTitle={Strings.signatureAssignment}/>
            {renderFilterModal()}
            {renderSearchModal()}
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View>
                    <View style={styles.icCont}>
                        <Icon name={'sign-everywhere'}
                              size={ThemeUtils.fontXXLarge}
                              color={Color.PRIMARY}/>
                    </View>
                </View>
                <Hr/>
                {loading ? (
                    <ActivityIndicator style={styles.icLoader}
                                       color={Color.PRIMARY}/>
                ) : (
                    <FlatList data={data?.signature_property?.data ?? []}
                              renderItem={renderAssignments}
                              extraData={data}
                              onEndReached={(info) => {
                                  if (data?.SignatureListProperties?.paginatorInfo?.hasMorePages && !loading) {
                                      fetchMore({
                                          variables: {
                                              page: (data?.SignatureListProperties?.paginatorInfo?.currentPage ?? 0) + 1,
                                          },
                                          updateQuery: (prev, {fetchMoreResult}) => {
                                              if (!fetchMoreResult) {
                                                  return prev;
                                              } else {
                                                  let finalResult = Object.assign({}, fetchMoreResult);
                                                  finalResult.SignatureListProperties.data = [...prev.SignatureListProperties.data, ...fetchMoreResult.SignatureListProperties.data];
                                                  return finalResult;
                                              }
                                          },
                                      });
                                  }
                              }}
                              onEndReachedThreshold={0.2}
                              keyExtractor={item => item.id.toString()}
                    />
                )}

            </Animated.ScrollView>
        </View>
    )
}
