import {StyleSheet} from "react-native";
import {Color, ThemeUtils} from "components/src/utils";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: Color.SECONDARY_BG_COLOR,
  },

  topContainer: {
    flex: 1,
    marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    alignContent: "center",
  },

  viewicon: {
    marginTop: 20,
    marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonContainer: {
    marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    marginVertical: 10,
    flexDirection: "row",
  },
  SubViewIcon: {
    backgroundColor: Color.GRAY,
    height: ThemeUtils.relativeWidth(41),
    width: ThemeUtils.relativeWidth(41),
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center'
},
  cont: {
    flex: 1,
    backgroundColor: Color.WHITE,
  },
  contentCont: {
    flex: 1,
    marginHorizontal: 20,
  },
  titleCont: {
    marginTop: 5,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  lblInst: {
    lineHeight: 18,
  },
  btnAddView: {
    paddingVertical: 15,
    backgroundColor: Color.PRIMARY
  },
  orderCont: {
    marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    marginTop: 15,
  },

  lineStyle: {
    backgroundColor: Color.LIGHT_GRAY,
    marginHorizontal: ThemeUtils.relativeRealWidth(3),
    marginVertical: 10
  },
  btnOkay: {
    marginHorizontal: ThemeUtils.relativeRealWidth(2)
  },
  bottomButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10
  },

  propertySelectionCont: {
    flexDirection: 'row',
    alignItems:'center',
    marginTop: 15,
    marginBottom:5
  },

  ImageGridView: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 20
  },
  GridImage: {
    borderRadius: ThemeUtils.relativeRealWidth(2.5),
    height: ThemeUtils.responsiveScale(200),
    borderWidth: 0.5,
    borderColor: Color.PRIMARY_LIGHTER
  },
  selectedImage: {
    padding:10,
    position:'absolute',
    top: ThemeUtils.relativeWidth(2.8),
    height: ThemeUtils.responsiveScale(200),
    width: ThemeUtils.responsiveScale(200),
    borderRadius: ThemeUtils.relativeRealWidth(2.5),
    backgroundColor: Color.PRIMARY_LIGHT
  },
  lblCont: {
    flex: 0.4,
    marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
  },
  serviceCont: {
    borderWidth: 1,
    borderRadius: 1,
    borderColor: Color.SECONDARY,
    paddingHorizontal: 15,
    paddingVertical: 15,
    marginVertical: 10
  },
  imgCont: {
    flex: 1,
    flexDirection:'row',
    paddingStart: 15,
    paddingBottom: 15
  },
  scrollCont: {
    paddingBottom: 15
  },
  imgBottom: {
    height: 100,
    width: 120,
    marginStart: 20
  },
  icCont: {
    zIndex: 100,
    position: 'absolute',
    justifyContent: 'center',
    alignItems:'center',
    alignSelf:'center',
    padding: 20,
    backgroundColor:Color.TRANSPARENT_BLACK,
    borderRadius:20,
    marginHorizontal:20,
    height:ThemeUtils.fontXXLarge,
    width:ThemeUtils.fontXXLarge
  },
  icContNext: {
    backgroundColor:Color.TRANSPARENT_BLACK,
    padding: 20,
    marginHorizontal: 20,
    zIndex: 100,
    borderRadius:20,
    alignSelf:'center',
    position: 'absolute',
    end: 0,
    height:ThemeUtils.fontXXLarge,
    width:ThemeUtils.fontXXLarge,
    justifyContent: 'center',
    alignItems:'center'
  }
});
export { styles };
