import React from 'react';
//Components
import {ScrollView, View} from 'react-native';
import {CustomButton, Header, Label, LogoForHeader} from "components/src/components";
//Utils
import {Color, decryptId, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import {useQuery} from "@apollo/react-hooks";
import {FINAL_SUBMIT_AGREEMENT, FIRST_SELLER_SUBMIT} from "../../../../../../api/query";


function SignSuccess(props) {

    const {navigation, history} = props;

    let params = IS_WEB ? JSON.parse(decryptId(props.match.params.totalOwner)) : props.navigation.getParam("params")
    let variables;
    if (params.total_owners === 1) {
        variables = {
            input: params
        }
    } else {
        variables = {
            email_verify: params?.is_email,
            property_id: params?.property_id
        }
    }

    useQuery(params.total_owners === 1 ? FIRST_SELLER_SUBMIT : FINAL_SUBMIT_AGREEMENT, {
        variables: variables
    });

    return (
        <View style={styles.container}>
            <Header tintColor={Color.SECONDARY}
                    navigation={IS_WEB ? history : navigation}
                    renderTitle={() => <LogoForHeader/>}/>

            <ScrollView contentContainerStyle={{flexGrow: 1, backgroundColor: Color.PRIMARY_BG_COLOR}}>
                <View style={styles.contentCont}>

                    <Label xxlarge
                           font_medium
                           color={Color.TERNARY_TEXT_COLOR}
                           ml={ThemeUtils.relativeRealWidth(6)}
                           mr={ThemeUtils.relativeRealWidth(6)}
                           align={'center'}>
                        {Strings.congratulations}
                    </Label>
                    <Label small
                           mt={10}
                           color={Color.WHITE}
                           ml={ThemeUtils.relativeRealWidth(6)}
                           mr={ThemeUtils.relativeRealWidth(6)}
                           align={'center'}>
                        {Strings.contractSigned}
                    </Label>
                </View>
                <CustomButton title={Strings.meglerSign}
                              onPress={() => IS_WEB ? history.replace(`/${Routes.SignatureAssignment}`) : navigation.replace(Routes.SignatureAssignment)}
                              mt={10}
                              ml={ThemeUtils.relativeRealWidth(4)}
                              mr={ThemeUtils.relativeRealWidth(4)}/>
            </ScrollView>


        </View>
    )
}

export default SignSuccess;
