import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
    },
    contentCont:{
        flexGrow:1,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    scrollCont: {
        flexGrow: 1,
    },
    icMaterial: {
        marginVertical: ThemeUtils.relativeRealHeight(1.5),
    },
    materialCont: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: ThemeUtils.relativeRealHeight(2),
        marginBottom: 8,
        marginTop:10,
        borderRadius: 4,
        backgroundColor: Color.DARK_GRAY
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 10,
        justifyContent: 'space-between'
    },
    icOpenExtCont:{
        padding:8,
    },
    lblAprovedDate:{
        backgroundColor:Color.DARK_GRAY,
        paddingVertical:8,
        borderRadius: 4,
        marginVertical:15
    }
})
