import {StyleSheet} from 'react-native';
import {Color,ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = 20;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        
        backgroundColor: Color.WHITE
    },
    scrollCont: {
        flex:1
    },

    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: 18,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    documentcontainer: {
        marginVertical: 15,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    iconView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex:10
    },
    imageProfile:
        {
            width: 150,
            height: 150,
            borderRadius: 12,
            marginBottom: 10
        },
    deviderline: {
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
    fieldView:
        {
            marginTop: 20,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
    buttonContainer:
        {
            marginTop: '80%',
            flexDirection: 'row',
            justifyContent: 'space-between',

        },
        btnCont:{
            height:ThemeUtils.relativeWidth(10),
            width:ThemeUtils.relativeWidth(100),
            // marginHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
            marginBottom:15,
            backgroundColor:Color.PRIMARY_DARK,
            flexDirection: 'row',
            justifyContent:'center',
            alignItems:'center'
    
        },

});
export {styles};
