export default {
  'component.oppdragnodetails.title': 'Furehogen 24, 1000620',
  'component.oppdragnodetails.seller': 'Selger',
  'component.oppdragnodetails.inspection': 'Befaringsapplikasjon',
  'component.oppdragnodetails.photo': 'Boligfoto',
  'component.oppdragnodetails.rate': 'Takst',
  'component.oppdragnodetails.styling': 'Styling',
  'component.oppdragnodetails.infofromseller': 'Info fra selger',
  'component.oppdragnodetails.marketing': 'Markedsføring',
  'component.oppdragnodetails.views': 'Visninger',
  'component.oppdragnodetails.bids': 'Bud',
  'component.oppdragnodetails.statistics': 'Statistikk',
  'component.oppdragnodetails.notification': 'Varsler',
  'component.oppdragnodetails.status&checkpoints': 'Status og sjekkpunkter',
  'component.oppdragnodetails.buyer': 'Kjøper',
  'component.oppdragnodetails.documents': 'Dokumenter',
  'component.oppdragnodetails.sellersorders': 'Selgers bestillinger',
  'component.oppdragno.addoppdragno': 'Legg til Oppdragsnr',
  'component.oppdragnodetails.timeline': 'Tidslinjer',
  'component.oppdragno.enteroppdragno': 'Skriv inn Oppdrag No.',
};
