export default {
  'component.statusandcheckpoint.title': 'Push-varsel',
  'component.statusandcheckpoint.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  'component.statusandcheckpoint.sendnotificationafter48hrs':
    'Send varsel etter 48 timer med den første loggen',
  'component.statusandcheckpoint.bookinspection': 'Bokinspeksjon',
  'component.statusandcheckpoint.energylabeling': 'Energimerking',
  'component.statusandcheckpoint.sellersinterview': 'Selgers intervju',
  'component.statusandcheckpoint.photo': 'Bilder',
  'component.statusandcheckpoint.takst': 'Takst',
  'component.statusandcheckpoint.sendnotificationafter24hrs':
    'Send varsel etter 24 timer etter utleggelse',
  'component.statusandcheckpoint.approveds': 'Godkjenn DS',
  'component.statusandcheckpoint.finnno': 'Finn.no',
  'component.statusandcheckpoint.marketingaterial': 'Markedsføringsmateriale',
  'component.statusandcheckpoint.settlementinformation': 'Oppgjørsinformasjon',
  'component.statusandcheckpoint.otherreminders': 'Andre påminnelser',
  'component.statusandcheckpoint.mortgage1': 'Pantelån 1',
  'component.statusandcheckpoint.mortgage2': 'Pantelån 2',
  'component.statusandcheckpoint.pushnotificationdescription': 'Trykkvarslingsbeskrivelse',
  'component.statusandcheckpoint.pushnotificationdescription.placeholder':
    'Skriv pushvarsling beskrivelse her ...',
  'component.statusandcheckpoint.sendnotificationto': 'Send varsel til?',
  'component.statusandcheckpoint.seller': 'Selger',
  'component.statusandcheckpoint.buyer': 'Kjøper',
  'component.statusandcheckpoint.modal.question.bookinspection':
    'Er du sikker på at du vil sende varsel om bokinspeksjon?',
  'component.statusandcheckpoint.modal.question.energylabeling':
    'Er du sikker på at du vil sende varsel om Energimerking?',
  'component.statusandcheckpoint.modal.question.sellersinterview':
    'Er du sikker på at du vil sende varsel om Selgers intervju?',
  'component.statusandcheckpoint.modal.question.photo':
    'Er du sikker på at du vil sende varsel om Bilde?',
  'component.statusandcheckpoint.modal.question.takst':
    'Er du sikker på at du vil sende varsel om Takst?',
};
