export default {
  'component.agentphotos.title1': 'Bestillingsinformasjon',
  'component.agentphotos.title2': 'Last opp bilder',
  'component.agentphotos.time': 'Tid',
  'component.agentphotos.additionalservices': 'Tilleggstjenester bestilt',
  'component.agentphotos.photos.descriprion':
    'Last opp alle bilder ved å trykke på knappen nedenfor. Vi ber om at du ikke laster opp bilder med større størrelse enn det maksimale som kreves for å skrive ut bilder i A5-format. Vær også oppmerksom på at det kan ta litt tid å laste opp alle bildene hvis du laster opp mange samtidig.',
  'component.agentphotos.photo': 'Bilder',
  'component.agentphotos.loadmore': 'Se flere',
  'component.agentphotos.phototitle': 'Bildetittel',
  'component.agentphotos.phototitle.placeholder': 'Skriv inn fototittel',
  'component.agentphotos.attachvideolink': 'Legg ved videolink',
  'component.agentphotos.attachvideolink.placeholder': 'Skriv inn Video Link',
  'component.agentphotos.videolink': 'Video Link',
  'component.agentphotos.attach360degreeviewlink': 'Legg ved 360 graders visningslink',
  'component.agentphotos.attach360degreeviewlink.placeholder': '360 graders visningslink',
  'component.agentphotos.uploadanewphoto': 'Last opp et nytt bilde',

  'component.agentphotos.uploadphoto': 'Last opp bilde',

  // photos

  'component.photos.orderinginformation': 'Bestillingsiformasjon',
  'component.photos.deletetext': 'Er du sikker på at du vil slette dette bildet?',
  'component.photos.uploadlabel': 'Last opp nytt bilde',
  'component.photos.replaceimage': 'Erstatt Bilde',
  'component.photos.comments': 'Kommentars',
  'component.photos.watchvideo': 'Se på video',
  'component.photos.see360view': 'Se 360 ​​visning',
};
