import React from 'react'
import withDrawer from "components/src/HOC/Drawer";
import {IS_WEB, Routes, Strings} from "components/src/utils";
import Home from "../Agent/Home";
import {connect} from "react-redux";
import Cookie from "js-cookie";
// import Profile from '../Authenticated/MyAccount/Profile';
// import Moving from './Moovin/PropertyListing';
// import Financing from './Financing'

const TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    route: Routes.Super_User_Dashboard,
    // screen: Home
}, {
    id: 2,
    name: Strings.tasks,
    icon: 'tasks-or-checklist',
    route: Routes.Tasks
}, {
    id: 3,
    name: Strings.profile,
    icon: 'profile',
    route: Routes.Profile,
    // screen: Profile
}];


const DRAWER_ITEMS = [{
    name: Strings.dashBoard,
    icon: 'dashboard',
}/*, {
    name: Strings.calendar,
    icon: 'calendar',
    route: Routes.Calendar
}*/, {
    name: Strings.news,
    icon: 'news',
    route: Routes.News

}, {
    name: Strings.digitalSalesAssignment,
    icon: 'digital-sales-assignment',
    route: Routes.DigitalSalesAssignment
}, {
    name: Strings.signatureAssignment,
    icon: 'sign-everywhere',
    section: true,
    route: Routes.SignatureAssignment

}, {
    name: Strings.financing,
    icon: 'financing',
    route: Routes.Financing

}, {
    name: Strings.professional,
    icon: 'experienced-real-estate-agent',
    route: Routes.Professional

}, {
    name: Strings.inspectionApplications,
    icon: 'inspection-application',
    route: Routes.InspectionApplication
}, {
    name: Strings.properties,
    icon: 'ds-property',
    route: Routes.PropertyListing

}, {
    name: Strings.officeTemplate,
    icon: 'office-template',
    route: Routes.OfficeTemplate
}, {
    name: Strings.WBefaring,
    icon: 'search',
    route: Routes.WBefaringDashboard
}, {
    name: Strings.B2BServices,
    icon: 'b2b-and-b2c',
    route: Routes.B2BServices
}, {
    name: Strings.B2CServices,
    icon: 'b2b-and-b2c',
    route: Routes.B2CServices
}, {
    name: "Leverandører",
    isAccordion: true,
}, {
    name: Strings.logout,
    icon: 'logout',
    // route: Routes.SignIn,
}];


const AGENT_TABS = [{
    id: 1,
    name: Strings.home,
    icon: 'home',
    route: Routes.AgentDashboard,
    screen: Home
}, {
    id: 2,
    name: Strings.tasks,
    icon: 'tasks-or-checklist',
    route: Routes.Tasks
}, {
    id: 3,
    name: Strings.profile,
    icon: 'profile',
    route: Routes.Profile,
    // screen: Profile
}];


const AGENT_DRAWER_ITEMS = [{
    name: Strings.dashBoard,
    icon: 'dashboard',
    // route: Routes.Dashboard,
}/*, {
        name: Strings.calendar,
        icon: 'calendar',
        route: Routes.Calendar
    }*/,
    {
        name: Strings.news,
        icon: 'news',
        route: Routes.News
    },
    {
        name: Strings.digitalSalesAssignment,
        icon: 'digital-sales-assignment',
        route: Routes.DigitalSalesAssignment
    },
    {
        name: Strings.properties,
        icon: 'ds-property',
        route: Routes.PropertyListing
    },
    {
        name: Strings.signatureAssignment,
        icon: 'sign-everywhere',
        section: true,
        route: Routes.SignatureAssignment
    },
    {
        name: Strings.financing,
        icon: 'financing',
        route: Routes.Financing
    },
    {
        name: Strings.inspectionApplications,
        icon: 'inspection-application',
        route: Routes.InspectionApplication
    },
    {
        name: Strings.professional,
        icon: 'experienced-real-estate-agent',
        route: Routes.Professional
    },
    {
        name: Strings.logout,
        icon: 'logout',
        // route: Routes.SignIn,
    }];


const getParams = (props) => {
    let user = props.user
    if (!user && IS_WEB) {
        user = JSON.parse(Cookie.get("Agent_user"))?.user;
    }

    return {
        tabs: user?.access_level === 2 ? TABS : AGENT_TABS,
        drawerItems: user?.access_level === 2 ? DRAWER_ITEMS : AGENT_DRAWER_ITEMS
    }
};
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(withDrawer(getParams))
