import React, {useState} from 'react'
import {View} from 'react-native'
import {Header, LogoForHeader, Notes} from "components/src/components";
import {Color, CommonStyle, Constants, decryptId, IS_WEB, Routes, showMessage, ThemeUtils} from "components/src/utils";
import styles from './styles';
import InspectionMarketingPackageMobile from './Mobile';
import InspectionMarketingPackageTab from './Tab';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BEFARING_PACKAGES, MARKETING_PACKAGE_DETAILS} from "../../../../../../api/query";
import {connect} from "react-redux";
import {SELECT_INSURANCE_TYPE} from "../../../../../../api/mutation";


function InspectionMarketingPackage(props) {
    const {history, inspection, navigation} = props;
    const {property} = inspection ? inspection : {}

    const [visible, setVisible] = useState(false)
    const [loadMore, setFetchMore] = useState(false);

    let propertyId = IS_WEB ? decryptId(props.match.params.id) : property?.id

    const {loading, data, refetch, error} = useQuery(BEFARING_PACKAGES, {
        variables: {
            propertyId: property?.id ?? propertyId
        }
    })

    const packageDetailsQuery = useQuery(MARKETING_PACKAGE_DETAILS)


    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)

                       setVisible(false)
                   }}/>

        )
    }


    return (
        <View style={[styles.container, {overflow: 'hidden',}]}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>} tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            <View style={CommonStyle.flex}>
                {ThemeUtils.isTablet() ? <InspectionMarketingPackageTab {...props}
                                                                  loadMore={loadMore}
                                                                  packageData={packageDetailsQuery?.data?.marketing_package_detail}
                                                                  propertyId={propertyId}
                                                                  refetch={refetch}
                                                                  data={data?.befaring_packages}
                                                                  loading={loading || packageDetailsQuery?.loading}/> :
                    <InspectionMarketingPackageMobile {...props}
                                                propertyId={propertyId}
                                                refetch={refetch}
                                                loadMore={loadMore}
                                                packageData={packageDetailsQuery?.data?.marketing_package_detail}
                                                data={data?.befaring_packages}
                                                loading={loading || packageDetailsQuery?.loading}/>}
            </View>
        </View>

    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(InspectionMarketingPackage)
