import about from './nb-NO/about';
import admindashboard from './nb-NO/admindashboard';
import adminstyling from './nb-NO/adminstyling';
import adsandpopups from './nb-NO/adsandpopups';
import agentdocuments from './nb-NO/agentdocuments';
import agentinspection from './nb-NO/agentinspection';
import agentphotos from './nb-NO/agentphotos';
import agentrate from './nb-NO/agentrate';
import agentstyling from './nb-NO/agentstyling';
import agenttimeline from './nb-NO/agenttimeline';
import allagents from './nb-NO/allagents';
import b2bservice from './nb-NO/b2bservice';
import b2cservice from './nb-NO/b2cservice';
import befaring from './nb-NO/befaring';
import bids from './nb-NO/bids';
import bookinspection from './nb-NO/bookinspection';
import buyer from './nb-NO/buyer';
import buyersignup from './nb-NO/buyer-signup';
import calender from './nb-NO/calendar';
import career from './nb-NO/career';
import changePassword from './nb-NO/changepassword';
import commontext from './nb-NO/commontext';
import component from './nb-NO/component';
import contact from './nb-NO/contact';
import contentmanagement from './nb-NO/contentmanagement';
import contractandsettlement from './nb-NO/contractandsettlement';
import contractinformation from './nb-NO/contractinformation';
import dashboard from './nb-NO/dashboard';
import digitalsalesassignment from './nb-NO/digitalsalesassignment';
import disclaimer from './nb-NO/disclaimer';
import display from './nb-NO/display';
import documents from './nb-NO/documents';
import editprofile from './nb-NO/editprofile';
import energylabeling from './nb-NO/energylabeling';
import faq from './nb-NO/faq';
import feedback from './nb-NO/feedback';
import finance from './nb-NO/finance';
import form from './nb-NO/form';
import homePage from './nb-NO/homePage';
import infoseller from './nb-NO/infoseller';
import inspection from './nb-NO/inspection';
import inspectionapplication from './nb-NO/inspectionapplication';
import instructional from './nb-NO/instructional';
import insurance from './nb-NO/insurance';
import jobs from './nb-NO/jobs';
import loanapplication from './nb-NO/loanapplication';
import login from './nb-NO/login';
import managevendors from './nb-NO/managevendors';
import marketingandsales from './nb-NO/marketingandsales';
import marketingmaterials from './nb-NO/marketingmaterials';
import menu from './nb-NO/menu';
import moovin from './nb-NO/moovin';
import moovingguide from './nb-NO/movingguide';
import myorders from './nb-NO/myorders';
import news from './nb-NO/news';
import notificationsent from './nb-NO/notificationsent';
import officeceo from './nb-NO/officeceo';
import officecontroller from './nb-NO/officecontroller';
import officeTemplate from './nb-NO/officeTemplate';
import offices from './nb-NO/offices';
import oppdragnodetails from './nb-NO/oppdragnodetails';
import orderinformation from './nb-NO/orderinformation';
import orderlist from './nb-NO/orderlist';
import photographers from './nb-NO/photographers';
import photos from './nb-NO/photos';
import photosorder from './nb-NO/photosorder';
import professional from './nb-NO/professional';
import purchasingcontract from './nb-NO/purchasingcontract';
import pwa from './nb-NO/pwa';
import rate from './nb-NO/rate';
import ratefinish from './nb-NO/ratefinish';
import rewards from './nb-NO/rewards';
import salesinformation from './nb-NO/salesinformation';
import salespreparation from './nb-NO/salespreparation';
import seefinnad from './nb-NO/seefinnad';
import sellerfooter from './nb-NO/sellerfooter';
import sellerinformation from './nb-NO/sellerinformation';
import sellerinterview from './nb-NO/sellerinterview';
import sellersorders from './nb-NO/sellersorders';
import settlement from './nb-NO/settlement';
import settlementinformation from './nb-NO/settlementinformation';
import sidemenu from './nb-NO/sidemenu';
import statistics from './nb-NO/statistics';
import statusandcheckpoint from './nb-NO/statusandcheckpoint';
import styling from './nb-NO/styling';
import stylingfinish from './nb-NO/stylingfinish';
import table from './nb-NO/table';
import takeover from './nb-NO/takeover';
import takst from './nb-NO/takst';
import team from './nb-NO/team';
import userFooter from './nb-NO/userFooter';
import userHeader from './nb-NO/userHeader';
import views from './nb-NO/views';
import managelinks from './nb-NO/managelinks';
import photofinish from './nb-NO/photofinish';
import metalisting from './nb-NO/metalisting';
import vitecmanagement from './nb-NO/vitecmanagement';
import homesettings from './nb-NO/homesettings';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...career,
  ...pwa,
  ...component,
  ...userHeader,
  ...homePage,
  ...form,
  ...userFooter,
  ...login,
  ...finance,
  ...contact,
  ...settlement,
  ...about,
  ...faq,
  ...documents,
  ...instructional,
  ...photosorder,
  ...bookinspection,
  ...energylabeling,
  ...rate,
  ...ratefinish,
  ...styling,
  ...stylingfinish,
  ...sidemenu,
  ...marketingandsales,
  ...seefinnad,
  ...digitalsalesassignment,
  ...marketingmaterials,
  ...display,
  ...myorders,
  ...sellerfooter,
  ...photos,
  ...salespreparation,
  ...orderlist,
  ...orderinformation,
  ...editprofile,
  ...buyer,
  ...sellersorders,
  ...changePassword,
  ...rewards,
  ...disclaimer,
  ...documents,
  ...infoseller,
  ...views,
  ...commontext,
  ...moovin,
  ...news,
  ...professional,
  ...salesinformation,
  ...settlementinformation,
  ...takeover,
  ...contractandsettlement,
  ...menu,
  ...feedback,
  ...oppdragnodetails,
  ...sellerinformation,
  ...agentrate,
  ...agentinspection,
  ...agentphotos,
  ...bids,
  ...agentstyling,
  ...statusandcheckpoint,
  ...notificationsent,
  ...agentdocuments,
  ...photographers,
  ...statistics,
  ...takst,
  ...adminstyling,
  ...inspection,
  ...allagents,
  ...officeceo,
  ...officecontroller,
  ...team,
  ...admindashboard,
  ...loanapplication,
  ...insurance,
  ...table,
  ...agenttimeline,
  ...adsandpopups,
  ...jobs,
  ...contentmanagement,
  ...purchasingcontract,
  ...moovingguide,
  ...contractinformation,
  ...buyersignup,
  ...sellerinterview,
  ...dashboard,
  ...inspectionapplication,
  ...managevendors,
  ...officeTemplate,
  ...befaring,
  ...calender,
  ...b2bservice,
  ...b2cservice,
  ...calender,
  ...offices,
  ...managelinks,
  ...photofinish,
  ...metalisting,
  ...vitecmanagement,
  ...homesettings,
};
