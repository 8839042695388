import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    contentCont: {
        flex:1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    schemeCont:{
        flexDirection:"row",
        alignItems:'center',
        marginTop:5,
    },
    lblCont:{
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:10
    },
    img: {
        marginTop: 20,
        marginBottom:10,
        marginStart: 20,
        height: ThemeUtils.responsiveScale(82),
        width: ThemeUtils.responsiveScale(277),
        maxWidth:277,
        maxHeight:82,
        backgroundColor: Color.GRAY
    },
    singleLineLabel: {
        marginTop: ThemeUtils.relativeRealHeight(0.5),
        marginBottom: ThemeUtils.relativeRealHeight(0.5)
    },
    title: {
        fontFamily: 'Maax-Medium',
        color:Color.PRIMARY_TEXT_COLOR
    },
    v2Cont:{
        flex: 1,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    }
})
