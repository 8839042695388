export default {
  'component.userHeader.about': 'Om Webmegling',
  'component.userHeader.selling-housing': 'Selge bolig',
  'component.userHeader.sell': 'Selge',
  'component.userHeader.sell-valuation': 'Verdivurdering',
  'component.userHeader.marketing': 'Markedsføring',
  'component.userHeader.technology': 'Teknologi',
  'component.userHeader.property-settlement': 'Eiendomsoppgjør',
  'component.userHeader.unsold-housing': 'Usolgt bolig?',
  'component.userHeader.offices': 'Kontorer',
  'component.userHeader.properties': 'Eiendommer',
  'component.userHeader.for-megler': 'For meglere',
  'component.userHeader.finance': 'Finansiering',
  'component.userHeader.contact': 'Kontakt',
  'component.userHeader.concept': 'W Konseptet',
  'component.userHeader.flex': 'W Flex',
  'component.userHeader.career': 'Karriere',
  'component.userHeader.login': 'Logg inn',
};
