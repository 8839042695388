import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    dashboardItem: {
        height: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45),
        width: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(30) : ThemeUtils.relativeRealWidth(45),
        marginEnd: ThemeUtils.isTablet()?ThemeUtils.relativeRealWidth(2):ThemeUtils.relativeRealWidth(3),
        marginTop: ThemeUtils.isTablet()?ThemeUtils.relativeRealWidth(2):ThemeUtils.relativeRealWidth(3),
        borderRadius: ThemeUtils.relativeRealWidth(1.5),

    },
    imgServices: {
        flex: 1,
        top: 0,
        bottom: 0,
        end: 0,
        start: 0,
        justifyContent: 'flex-end',
        borderRadius: ThemeUtils.relativeRealWidth(1.5),

    },
    lblCont: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1)
    },
    dashBoardCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginStart: ThemeUtils.relativeRealWidth(3)
    }
})
