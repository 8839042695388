import React from 'react'
//Components
import {ActivityIndicator, Animated, FlatList, TextInput, View} from 'react-native'
import {Header, Label, Ripple} from "components/src/components";
//Third party
//redux
import {connect} from "react-redux";
//Utils
import {Color, CommonStyle, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import {Query} from "@apollo/react-components";
import debounce from 'lodash.debounce';
import {PROPERTY_SHOWCASE_LIST} from "components/src/api/query";

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            searchValue: '',
        }
        this.scrollY = new Animated.Value(0)
        this.changeText = debounce(() => {
            this.setState((state) => ({searchValue: state.searchText}))
        }, 500)
    }


    render() {
        const {searchText, searchValue} = this.state
        return (
            <View style={CommonStyle.flex}>
                <Header style={CommonStyle.blankHeader}
                        onPressLeft={this.props.onClose}/>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Label xlarge
                                   font_medium
                                   mt={15}

                                   mb={15}
                                   align={'left'}>
                                {Strings.search}
                            </Label>

                        </View>
                    </View>


                    <View style={styles.SearchMainView}>


                        <Icon name={'search'}
                              color={Color.PRIMARY}
                              size={ThemeUtils.fontSmall}/>


                        <TextInput style={[styles.Textinput, IS_WEB ? {outline: 'none'} : null]}
                                   onChangeText={(text) => {
                                       this.setState({searchText: text}, () => {
                                           this.changeText()
                                       })
                                   }}
                                   placeholderTextColor={Color.PRIMARY}
                                   value={searchText}
                                   placeholder={Strings.search}/>
                        {searchText ?
                            (<Ripple style={styles.closeIconView}
                                     onPress={() => this.setState({
                                         searchText: '',
                                         searchValue: ''
                                     })}
                                     rippleContainerBorderRadius={20}>
                                <Icon name={'close'}
                                      size={ThemeUtils.fontXSmall}
                                      color={Color.PRIMARY} align={'right'}/>
                            </Ripple>) : null}

                    </View>


                    <Query query={PROPERTY_SHOWCASE_LIST}
                           variables={{
                               input: {
                                   first: 50,
                                   page: 1,
                                   key: searchValue,
                                   status: true
                               }
                           }}>
                        {({data, loading, error, fetchMore}) => {
                            if (loading) {
                                return (<ActivityIndicator color={Color.PRIMARY}
                                                           style={CommonStyle.loader}/>)
                            }
                            return (
                                <View>
                                    <FlatList data={data?.property_show_case_list?.data ?? []}
                                              ListEmptyComponent={
                                                  <View style={CommonStyle.content_center}>
                                                      <Label mt={20}>
                                                          {"Ingen data"}
                                                      </Label>
                                                  </View>
                                              }
                                              renderItem={this.props.renderItem}/>

                                </View>

                            )
                        }}
                    </Query>


                </View>

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        token: state.token,
        user: state.user || {},
    };
};

export default connect(mapStateToProps)(Search);
