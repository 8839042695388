export default {
  'component.photosorder.title': 'Bilder',
  'component.photosorder.description':
    'Standard fotopakke er inkludert i den faste prisen. Her kan du bestille tid for fotograf, og også bestille utvidede fototjenester.',
  'component.photosorder.card1': 'Last opp bildene dine',
  'component.photosorder.card2': 'Legg ved videolink',
  'component.photosorder.card3': 'Legg ved 360 graders view link',
  'component.photosorder.new': 'Ny',
  'component.photosorder.loadmore': 'Se flere',
  'component.photosorder.modal.button1': 'Large',
  'component.photosorder.modal.button2': 'Avbryt',
};
