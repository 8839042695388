import {StyleSheet} from 'react-native';
import {Color,ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        marginTop:30,
    },
    dropDown:{
        marginVertical: 10,
        marginHorizontal:15
    },
    dashboard:{
        backgroundColor:Color.GRAY
    },
    loader:{
        marginTop:ThemeUtils.relativeRealHeight(6)
    }
})
