import {StyleSheet} from 'react-native';
import {ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        marginTop:ThemeUtils.relativeRealHeight(1.5),
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN

    },
    ProCont:{
        flex:1,
        paddingHorizontal:ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:ThemeUtils.relativeRealHeight(2)
    },
    imgCont:{
        flexDirection:'row',
        alignItems:'center'
    },
    content:{
        flex:1
    }

})
