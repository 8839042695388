import React from 'react'
//Components
import {Animated, Keyboard, View} from 'react-native'

import {CustomButton, CustomTextArea, DatePicker, Header, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation} from '@apollo/react-components'
import {SELLER_RATE} from 'components/src/api/mutation'
//Utils
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    encryptId,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {styles} from './styles.js';
import moment from "moment";


class Rate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDate: null,
            taxDate: null,
            startTime: null,
            endTime: null,
            comments: undefined,
            tariffDoc: [],
            rateDetails: this.orderRateDetails,
            // part1Value: 1,
            pageShow: [],
            valuerate: this.orderRateDetails?.value_rate ?? '',
            errorvaluerate: null,
            loanrate: this.orderRateDetails?.loan_rate ?? '',
            errorloanrate: null,
            bta: this.orderRateDetails?.bta ?? '',
            errorbta: null,
            bra: this.orderRateDetails?.bra ?? '',
            errorbra: null,
            prom: this.orderRateDetails?.p_rom ?? '',
            errorprom: null,
            constyears: this.orderRateDetails?.construction_year ?? '',
            errorconstyear: null,
        }
        this.propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');
        this.scrollY = new Animated.Value(0)
    }


    validate = (placeOrder) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        const {orderDate, startTime, comments, endTime,} = this.state;

        if (orderDate) {
            if (startTime) {
                if (endTime) {
                    if (moment(endTime).diff(moment(startTime), 'h') >= Constants.TIME_GAP) {
                        placeOrder({
                            variables: {
                                input: {
                                    property_id: this.propertyId,
                                    order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                                    earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                    latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                    comment: comments,
                                },
                            },
                        });
                    } else {
                        showMessage(MessageUtils.Errors.errTimeGap, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.endTime, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.startTime, Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
        }

    };

    navigateToThankYou = () => {
        if (IS_WEB) {
            this.props.history.replace(`/${Routes.Rate}/${encryptId(this.propertyId)}`, {
                id: this.propertyId
            })
        } else {
            this.props.navigation.replace(Routes.Rate, {
                id: this.propertyId
            })
        }
    }

    _renderCheckBoxUnclickView = () => {
        return (
            <View>
                <DatePicker title={Strings.date}

                            min={new Date(moment().add(1, 'd'))} key={'taxDate'}
                            onSelectDate={(date) => this.setState({orderDate: date})}
                            placeholder={Strings.selectDate}
                />

                <Label
                    mt={18}
                    small
                    font_regular
                    align={'left'}>
                    {Strings.rateInstruction2}
                </Label>

                <DatePicker title={Strings.startTime}
                            onSelectDate={(date) => this.setState({
                                startTime: date,
                                endTime: checkDiff(date) ? add1Day() : add4Hours(date)
                            })}
                            placeholder={Strings.selectTime}
                            minTime={new Date(moment().add(1, 'd').set({hours: 0, minutes: 0, seconds: 0}))}
                            maxTime={add1Day()}
                            mode={'time'}/>
                <DatePicker title={Strings.endTime}
                            selectedDate={this.state?.startTime && !this.state.endTime ? checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime) : this.state.endTime ? new Date(moment(this.state.endTime)) : null}
                            minTime={checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime)}
                            maxTime={add1Day()}
                            placeholder={Strings.selectTime}
                            onSelectDate={(date) => {
                                this.setState({endTime: date})
                            }}
                            mode={'time'}/>
                <CustomTextArea
                    mt={18}
                    heightInRow={5}
                    value={this.state.comments}
                    placeholder={Strings.writeYourComment}
                    labelText={Strings.comments}
                    onChange={(comments) => {
                        this.setState({comments: comments})
                    }}/>

                <Mutation mutation={SELLER_RATE}
                          onCompleted={(data) => this.navigateToThankYou(data)}
                          onError={() => {
                          }}>
                    {(placeOrder, {loading, data}) => (
                        <CustomButton
                            mt={18}
                            mb={18}
                            title={Strings.order}
                            loading={loading}
                            onPress={() => this.validate(placeOrder)}/>
                    )}
                </Mutation>


            </View>
        );
    }

    render() {
        return (
            <View style={CommonStyle.container}>
                <Provider locale={enUS}>
                    <Header animatedTitle={Strings.rate}
                            navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedValue={this.scrollY}/>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         keyboardShouldPersistTaps={'always'}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                             {useNativeDriver: true})}>

                        <View style={styles.container}>
                            <View style={styles.topContainer}>
                                <View style={styles.labeliconView}>
                                    <Icon
                                        size={ThemeUtils.fontXXLarge}
                                        name={'rate'}
                                        color={Color.PRIMARY_DARK}/>
                                </View>
                                <Label
                                    mt={16}
                                    small
                                    align={'left'}>
                                    {Strings.rateInstruction1}
                                </Label>

                                {this._renderCheckBoxUnclickView()}

                            </View>

                        </View>
                    </Animated.ScrollView>
                </Provider>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rate);
