import React from 'react';

import {SafeAreaView} from 'react-native';
import {CommonStyle} from 'components/src/utils';

import {auth, database} from 'firebase'

export const withFirebase = (WrappedComponent) => {
    function FirebaseClass(props) {
        return (
            <SafeAreaView style={[CommonStyle.flex]}>
                <WrappedComponent {...props}
                                  auth={auth}
                                  database={database}/>
            </SafeAreaView>
        )
    }

    return FirebaseClass;
};

