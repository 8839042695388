import {StyleSheet} from 'react-native';
import {ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    cont: {
        paddingVertical: 10
    },
    icPage: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        marginStart: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    container:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    uploadDocCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingBottom: 15
    },
    bankLblCont: {
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 15
    },
    primaryLblCont:{
        flex:1,
        flexDirection: 'row',
        alignItems:'center'
    },
    files: {
        marginTop: 15
    }

});

export default style;
