export default {
  'component.adsandpopups.title': 'Annonser / popup-vinduer',
  'component.adsandpopups.description':
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
  'component.adsandpopups.salespreparations': 'Salgsforberedelser',
  'component.adsandpopups.marketing&sales': 'Markedsføring og salg',
  'component.adsandpopups.contract&settlement': 'Kontrakt og oppgjør',
  'component.adsandpopups.uploadimageorgif': 'Last opp bilde eller GIF',
  'component.adsandpopups.redirectionurl': 'Link til artikkel',
  'component.adsandpopups.redirectionurl.placeholder': 'Skriv inn link til artikkel',
};
