export default {
  'component.views.title': 'Visninger',
  'component.views.brokerview': 'Megler på visning',
  'component.views.sellerview': 'Selger på visning',
  'component.views.completed': 'Fullførte visninger',
  'component.views.stakeholders': 'Interessenter',
  'component.views.upcoming': 'Kommende visninger',
  'component.views.searchplaceholder': 'Søk etter navn, e-post og telefon',
  'component.views.stakeholdersDS': 'Interessenter fra DS',
  'component.views.stakeholdersSignin': 'Fra visningsliste',
};
