import React from 'react'
//Components
import {ActivityIndicator, Animated, Image, View} from 'react-native'
import {Header, Hr, Label, Ripple} from "components/src/components";
//Third party
//redux
//Network
import {useQuery} from "@apollo/react-hooks";
import {VENDORS_SERVICES} from "components/src/api/query";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    encryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils,
} from "components/src/utils";
import styles from './styles'


const scrollY = new Animated.Value(0);
const StylistServiceDetails = props => {

    let {history, navigation} = props;
    let vendorId = IS_WEB ? decryptId(props.match.params.vendorId) : navigation.getParam('vendorId');

    const {loading, data, error, fetchMore} = useQuery(VENDORS_SERVICES, {
        variables: {
            serviceCategory: Constants.ServiceType.Styling
        }
    });

    const renderServices = (item, index) => {
        return (
            <Ripple key={index}
                    onPress={() => {
                        if (IS_WEB) {
                            history.push(`/${Routes.Stylist}/${encryptId(item.name)}/${encryptId(vendorId)}/${encryptId(item.id)}`)
                        } else {
                            navigation.navigate(Routes.VendorServicesDetails, {
                                vendorId: vendorId,
                                serviceTypeId: item.id,
                                name: item.name
                            })
                        }

                    }}>
                {index === 0 ? (<Hr/>) : null}

                <View style={styles.optionContainer}>
                    <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.image}`}}
                           style={styles.imgThumb}/>
                    <View style={CommonStyle.flex}>
                        <Label font_medium
                            // mb={10}
                               ml={20}>
                            {item.name}
                        </Label>
                        {/*<Label small
                               ml={20}
                               numberOfLines={2}>
                            {item?.is_free ? item?.free_text : `Kr ${formatPrice(item.price)},-`}
                        </Label>*/}
                    </View>
                    <View style={styles.iconView}>
                        <Icon
                            size={ThemeUtils.fontLarge}
                            name={'drop-normal'}
                            color={Color.ICON_BUTTON_ARROW}/>
                    </View>
                </View>
                <Hr/>
            </Ripple>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Header animatedTitle={Strings.services}

                    initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? props.history : props.navigation}
                    animatedValue={scrollY}/>

            <View style={styles.container}>
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}/>
                ) : data?.vendor_service_type?.length ? data?.vendor_service_type?.map(renderServices) : (
                    <Label align={'center'}>
                        {"Ingen data"}
                    </Label>
                )}
            </View>
        </View>
    )

}

export default StylistServiceDetails;
