import {StyleSheet} from 'react-native';
import {ThemeUtils,Color} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        paddingHorizontal: 20,
        marginTop:50,
        
    },
    ProCont:{
        flex:1,
        paddingHorizontal:20,
        paddingVertical:10
    },
    imgCont:{
        flexDirection:'row',
        alignItems:'center'
    },
    content:{
        flex:1
    },
    SearchMainView:{ backgroundColor: Color.UPLOAD_BG_COLOR, flexDirection: 'row', justifyContent: 'space-between',marginTop:10 },
    ContainView:{ flexDirection: 'row', marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL, justifyContent: 'space-between', flex: 1 },
IconViews:{ flexDirection: 'row', alignSelf: 'center', },
Textinput:{ height: 50, marginLeft: 10, width: '100%',borderColor:Color.INPUT_BORDER },
closeIconView:{ alignContent: 'flex-end', alignSelf: 'center' },

   
})
