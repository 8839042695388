export default {
  'component.agentdashboard.title1': 'Nyheter',
  'component.agentdashboard.title2': 'Gjøremål',
  'component.agentdashboard.description':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam mollis nec enim ac blandit. Nullam eget posuere augue. Sed sed bibendum orci. In pretium malesuada tortor, eu aliquet purus tempor quis. Etiam sit amet urna ac est tincidunt sagittis sit amet at quam. ',
  'component.agentdashboard.marketing': 'Markedsføring',
  'component.agentdashboard.finnno': 'Finn.no stats',
  'component.agentdashboard.finnno.placeholder': 'Angi antall klikk',
  'component.agentdashboard.salesstats': 'Salgsstatistikk',
  'component.agentdashboard.inspection': 'Befaringer',
  'component.agentdashboard.newassignment': 'Oppdrag',
  'component.agentdashboard.sale': 'Salg',
  'component.agentdashboard.omsetning': 'Omsetning (tall i tusen)',
  'component.agentdashboard.duetoday': 'Forfaller i dag',
  'component.agentdashboard.duethisweek': 'Forfaller denne uken',
  'component.agentdashboard.activebids': 'Aktive bud',
  'component.agentdashboard.viewallnews': 'Vis alle nyheter',
  'component.agentdashboard.readmore': 'Les mer',
};
