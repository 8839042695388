export default {
  'component.contract.bankdetails': 'Bankinformasjon',
	'component.contract.nameofbank': 'Navn på bank',
	'component.contract.contactbank': 'Kontakt bank',
	'component.contract.emailcontact': 'Telefon / e-postkontakt',
	'component.contract.accountno': 'Kontonummer',
	'component.contract.additionalbank': 'Tilleggsbank',
	'component.contract.nameofbuyer1': 'Navnekjøper 1',
	'component.contract.nameofbuyer2': 'Navnekjøper 2',
	'component.contract.ownership': 'Eierinteresse i Hernia',
	'component.contract.homebuyer': 'Boligkjøperforsikring',
	'component.contract.readmorehomebuyer': 'Les mer om boligkjøperforsikring',
}
