import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const containerHorizontalMargin = ThemeUtils.COMMON_HORIZONTAL_MARGIN;
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    titleContainer: {
        marginTop: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    topContainer: {
        marginHorizontal: containerHorizontalMargin,
        marginBottom: 20,
        alignContent: 'center',
        justifyContent: 'space-around',
    },

    optionContainer: {
        marginVertical: 15,
        marginHorizontal: containerHorizontalMargin,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    imgThumb: {
        height: 80,
        width: 80,
        borderRadius: 10,
    },
    iconView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dividerLine: {
        height: 0.1,
        flexDirection: 'row',
        borderColor: '#9C8B9D',
        width: "100%",
        borderWidth: 0.5,
    },
    orderCont: {
        marginHorizontal: containerHorizontalMargin,
    },
    bottomButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 20,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
});

export default styles;
