export default {
  'component.salesinformation.title': 'Salgsinformasjon',
  'component.salesinformation.description':
    'Her finner du all kontaktinformasjon for kjøperen av din / deres eiendom samt informasjon om hva som er bindende avtalt.',
  'component.salesinformation.buyers': 'Kjøper detaljer',
  'component.salesinformation.appointmentdetails': 'Avtaleinformasjon',
  'component.salesinformation.purchaseprice': 'Kjøpesum',
  'component.salesinformation.takeoverdate': 'Overtagelsesdato',
  'component.salesinformation.back': 'Tilbake',
  'component.salesinformation.appointmentinfo': 'Informasjon om avtale',
  'component.salespreparation.energy': 'Energi',
  'component.salespreparation.upgrade': 'Oppgraderinger i eiendommen',
  'component.salespreparation.contractInfo': 'Kontraktsinformasjon',
};
