import React, {useEffect, useState} from 'react'
//Components
import {ActivityIndicator, Animated, View} from 'react-native'
import {Header, Hr, Label} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
//redux
//Utils
import {Color, Icon, IS_WEB, Strings, ThemeUtils} from "components/src/utils";
import {styles} from './styles.js';
import {useQuery} from "@apollo/react-hooks";
import {MOOVIN_PROPERTY_DETAILS} from "../../../../../../api/query";


export default function Seller(props) {

    const {navigation, history} = props;
    const [primarySeller, setPrimarySeller] = useState({})
    const [secondarySeller, setSecondarySeller] = useState({})
    const scrollY = new Animated.Value(0)

    const propertyId = IS_WEB ? props.history.location?.state?.id : props.navigation.getParam('id');

    const {loading, error, data} = useQuery(MOOVIN_PROPERTY_DETAILS, {
        variables: {
            id: propertyId
        }
    });

    useEffect(() => {
        if (data?.moovin_property_detail) {
            setPrimarySeller(data?.moovin_property_detail?.property_seller.find(item => item.is_primary_seller))
            setSecondarySeller(data?.moovin_property_detail?.property_seller.find(item => !item.is_primary_seller))
        }
    }, [data])
    return (
        <Provider locale={enUS}>

            <Header animatedTitle={Strings.seller}
                    navigation={IS_WEB ? history : navigation}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>

                <View style={styles.container}>
                    <View style={styles.topContainer}>
                        <View style={styles.titleContainer}>
                            <Icon
                                size={ThemeUtils.fontXXLarge}
                                name={'seller'}
                                color={Color.PRIMARY_DARK}/>

                        </View>
                    </View>
                    {loading ? (
                        <ActivityIndicator color={Color.PRIMARY}/>
                    ) : (
                        <View>
                            <View style={styles.topContainer}>
                                <Label small font_medium mt={25}>
                                    {Strings.seller1}
                                </Label>
                                <Label small font_medium mt={23}>
                                    {Strings.firstName}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {primarySeller?.firstName}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.lastName}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {primarySeller?.lastName}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.Email}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {primarySeller?.email}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.Phone}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {primarySeller?.mobilePhone}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.propertyAddress}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {data?.moovin_property_detail?.street_adress}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.city}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {data?.moovin_property_detail?.city}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.zipCode}
                                </Label>
                                <Label small font_regular mt={8} mb={18}>
                                    {data?.moovin_property_detail?.zipcode}
                                </Label>
                            </View>
                            {secondarySeller ? (<Hr/>) : null}
                            {secondarySeller ? (<View style={styles.topContainer}>
                                <Label small font_medium mt={25}>
                                    {Strings.seller2}
                                </Label>
                                <Label small font_medium mt={23}>
                                    {Strings.firstName}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {secondarySeller?.firstName}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.lastName}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {secondarySeller?.lastName}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.Email}
                                </Label>
                                <Label small font_regular mt={8}>
                                    {secondarySeller?.email}
                                </Label>
                                <Label small font_medium mt={25}>
                                    {Strings.Phone}
                                </Label>
                                <Label small font_regular mt={8}
                                       mb={25}>
                                    {secondarySeller?.mobilePhone}
                                </Label>
                            </View>) : null}
                        </View>
                    )}

                </View>


            </Animated.ScrollView>
        </Provider>);

}
