import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, TouchableOpacity, View} from 'react-native';
import {
    Attachment,
    CustomButton,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    PagerTabIndicator,
    RadioGroup,
    Toast
} from "components/src/components";
//Network
import {useMutation, useQuery} from 'react-apollo';
import {ADD_BUYER_DETAILS} from 'components/src/api/mutation';
//Utils
import styles from "./styles";
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    decryptId,
    Icon,
    IS_WEB,
    openInNewTab, showMessage,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import {
    BUYER_CONTRACT_DOC_QUERY,
    INFO_FROM_BUYER,
    ORIGIN_EQUITY,
    PURPOSE_OF_PURCHASE,
    VITECH_BUYER_INFO
} from "../../../../../../api/query";
import moment from "moment-timezone";


const TABS = [{
    text: Strings.addBuyer
}, {
    text: Strings.infoFromBuyer
}, {
    text: Strings.purchasingContract
}];


const BUYER_INPUTS = [{
    id: 'firstName',
    title: Strings.firstName,
    placeholder: Strings.firstName,
    type: 'text',
    validationType: 'name'
}, {
    id: 'lastName',
    title: Strings.lastName,
    placeholder: Strings.lastName,
    type: 'text',
    validationType: 'name'
}, {
    id: "personnummer",
    title: "Personnummer",
    placeholder: "Personnummer",
    type: 'text',
    validationType: 'personnummer'
}, {
    id: 'phone',
    title: Strings.phone,
    placeholder: Strings.phone,
    type: 'number',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: Strings.email,
    placeholder: Strings.email,
    type: 'email',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled: true,
    // validationType: 'eierandel'
}];

const COMPANY_INPUTS = [{
    id: "companyName",
    title: "Selskapsnavn",
    placeholder: "Selskapsnavn",
    type: 'text',
    validationType: 'name'
}, {
    id: "organisationNumber",
    title: "Organisasjonsnummer",
    placeholder: "Organisasjonsnummer",
    type: 'number',
    validationType: 'organizationNo'
}, {
    id: "contactPerson",
    title: "Navn på kontaktperson",
    placeholder: "Navn på kontaktperson",
    type: 'text',
    validationType: 'name'
}, {
    id: "contactPersonSSNNo",
    title: "Fødsel og personnummer kontaktperson",
    placeholder: "Fødsel og personnummer kontaktperson",
    type: 'email',
    validationType: 'securityNo'
}, {
    id: 'phone',
    title: "Mobil",
    placeholder: "Mobil",
    type: 'text',
    validationType: 'phoneNo'
}, {
    id: 'email',
    title: "Epost",
    placeholder: "Epost",
    type: 'text',
    validationType: 'email'
}, {
    id: 'interest',
    title: "Eierandel",
    placeholder: "Eierandel",
    type: 'text',
    disabled: true,
    // validationType: 'eierandel'
}];

const propertyInputs = [{
    id: 'purchase',
    title: Strings.purchase,
    placeholder: Strings.enterAmount,
    type: 'number',
    validationType: 'purchase'
}, {
    id: 'commune',
    title: Strings.commune,
    placeholder: Strings.enterCommune,
    type: 'text',
    validationType: 'commune'
}, {
    id: 'gnr',
    title: Strings.GNR,
    placeholder: Strings.enterGNR,
    type: 'text',
    validationType: 'gnr'
}, {
    id: 'bnr',
    title: Strings.BNR,
    placeholder: Strings.enterBNR,
    type: 'text',
    validationType: 'bnr'
}, {
    id: 'fnr',
    title: Strings.FNR,
    placeholder: Strings.enterFNR,
    type: 'text',
    validationType: 'fnr'
}, {
    id: 'org_nr',
    title: Strings.orgNr,
    placeholder: Strings.enterOrgNr,
    type: 'text',
    validationType: 'orgNr'
}, {
    id: 'snr_andelsnr',
    title: Strings.snrAndelsnr,
    placeholder: Strings.enterSNR,
    type: 'text',
    validationType: 'snr'
}];

const CONTACT_INFO = [{
    id: 'bank_name',
    title: Strings.bankName,

}, {
    id: 'bank_contact',
    title: Strings.bankContactPerson,

}, {
    id: 'bank_email',
    title: Strings.phoneEmailOfContactPerson,

}, {
    id: 'bank_mobile',
    title: Strings.accountNo,

}];

const BUYER_OWNERSHIP = [{
    id: 'first_name',
    title: Strings.firstName,
}, {
    id: 'last_name',
    title: Strings.lastName,

}, {
    id: "personnummer",
    title: "Personnummer",
}, {
    id: 'phone',
    title: Strings.phone,
}, {
    id: 'email',
    title: Strings.email,
}, {
    id: 'eierandel',
    title: "Eierandel",
}]

const COMPANY_OWNERSHIP = [{
    id: "companyName",
    title: "Selskapsnavn",
}, {
    id: "organisationNumber",
    title: "Organisasjonsnummer",
}, {
    id: "contact_person",
    title: "Navn på kontaktperson",
}, {
    id: "ssn_number",
    title: "Fødsel og personnummer kontaktperson",
}, {
    id: 'phone',
    title: "Mobil",
}, {
    id: 'email',
    title: "Epost",
}, {
    id: 'eierandel',
    title: "Eierandel",
}]


let toast;
export default function Buyer(props) {
    const {history, navigation} = props;

    const scrollY = new Animated.Value(0);

    const propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');

    const [primaryUser, setPrimary] = useState(0);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [buyer, setBuyer] = useState([{
        isBuyer: true,
        inputs: BUYER_INPUTS
    }]);
    const [buyerValues, setBuyerValues] = useState([{
        isBuyer: true,
        value: {}
    }]);
    const [buyerErrors, setBuyerErrors] = useState([{}]);

    const originQuery = useQuery(ORIGIN_EQUITY, {
        variables: {
            status: 1
        }
    });
    const purposeQuery = useQuery(PURPOSE_OF_PURCHASE, {
        variables: {
            status: 1
        }
    });

    const contractCommentQuery = useQuery(BUYER_CONTRACT_DOC_QUERY, {
        variables: {
            status: 1,
            propertyId: propertyId,
            commentBy: "BUYER"
        },
        fetchPolicy: 'network-only'
    });

    const vitechBuyer = useQuery(VITECH_BUYER_INFO, {
        variables: {
            id: propertyId
        }
    })

    useEffect(() => {
        if (vitechBuyer.data?.vitec_buyer_info?.length) {
            let buyerValues = vitechBuyer.data?.vitec_buyer_info.map((item, index) => {
                return {
                    isBuyer: item.contactType === 0,
                    value: item.contactType === 0 ? {
                        firstName: item.firstName,
                        lastName: item.lastName,
                        personnummer: "",
                        phone: item.mobilePhone,
                        email: item.email,
                        interest: "",
                        ...item,
                    } : {
                        companyName: item.companyName,
                        organisationNumber: item.organisationNumber,
                        phone: item.mobilePhone,
                        email: item.email,
                        contactPersonSSNNo: "",
                        contactPerson: "",
                        interest: "",
                        ...item,
                    }
                }
            });
            let updatedBuyer = buyerValues.map(item => ({
                isBuyer: item.isBuyer,
                inputs: item.isBuyer ? BUYER_INPUTS : COMPANY_INPUTS
            }));
            setBuyerErrors(new Array(buyerValues.length).fill({}))
            setBuyerValues(buyerValues)
            setBuyer(updatedBuyer)
        }
    }, [vitechBuyer.data]);

    const {loading, data, error} = useQuery(INFO_FROM_BUYER, {
        variables: {
            propertyId: propertyId
        }
    })

    const [addBuyerDetails, addBuyerMutation] = useMutation(ADD_BUYER_DETAILS);

    const registerBuyer = () => {
        const updatedErrors = {...errors};
        const updatedBuyerErrors = buyerErrors.slice();

        let combinedInputs = propertyInputs;
        let isError = false;
        for (let i = 0; i < combinedInputs.length; i++) {
            let item = combinedInputs[i];
            if (item.validationType) {
                updatedErrors[item.id] = validation(item.validationType, values[item.id] ? values[item.id].trim() : '');
                if (updatedErrors[item.id]) {
                    isError = true;
                }
            }
        }

        buyerValues.map((bankInfo, parentIndex) => {
            for (let i = 0; i < (bankInfo?.isBuyer ? BUYER_INPUTS.length : COMPANY_INPUTS.length); i++) {

                let item = bankInfo?.isBuyer ? BUYER_INPUTS[i] : COMPANY_INPUTS[i];
                let value = bankInfo.value[item.id] ? bankInfo.value[item.id].trim() : '';
                updatedBuyerErrors[parentIndex][item.id] = validation(item.validationType, value);
                if (updatedBuyerErrors[parentIndex][item.id]) {
                    isError = true;
                }
            }
        });

        if (isError) {
            setErrors(updatedErrors)
            setBuyerErrors(updatedBuyerErrors)
        } else {
            let copyBuyerValues = buyerValues.slice();
            let primaryBuyer = copyBuyerValues.splice(primaryUser, 1)[0]

            let coBuyerRequest = copyBuyerValues.map((item) => {
                let request = {
                    co_contactType: item.isBuyer ? 0 : 1,
                    co_buyer_email: item?.value?.email,
                    co_buyer_mobile: item?.value?.phone,
                    co_contactId: item.value?.contactId ?? null,
                    co_departmentId: item.value?.departmentId ?? null,
                    co_workPhone: item.value?.workPhone ?? null,
                    co_buyer_address: item.value?.address ?? null,
                    co_buyer_zipcode: item.value?.postalCode ?? null,
                    co_buyer_city: item.value?.city ?? null,
                    co_eierandel: `1/${buyerValues.length}`,
                    //co_eierandel: item.value?.interest ?? null
                }
                if (item.isBuyer) {
                    /*
                    person number
                    interest
                    */
                    request = {
                        ...request,
                        co_buyer_first_name: item.value?.firstName,
                        co_buyer_last_name: item.value?.lastName,
                        co_personnummer: item.value?.personnummer,
                    }
                } else {
                    /*
                    contact person
                    contact person SSn
                    interest
                    */
                    request = {
                        ...request,
                        co_companyName: item.value?.companyName,
                        co_buyer_first_name: item.value?.companyName,
                        co_buyer_last_name: null,
                        co_organisationNumber: item.value?.organisationNumber,
                        co_contact_person: item?.value?.contactPerson,
                        co_ssn_number: item.value?.contactPersonSSNNo
                    }
                }

                return request
            })

            let primaryBuyerRequest = {
                contactType: primaryBuyer?.isBuyer ? 0 : 1,
                buyer_email: primaryBuyer?.value?.email,
                buyer_mobile: primaryBuyer?.value?.phone,
                contactId: primaryBuyer?.value?.contactId ?? null,
                departmentId: primaryBuyer.value?.departmentId ?? null,
                workPhone: primaryBuyer.value?.workPhone ?? null,
                buyer_address: primaryBuyer.value?.address ?? null,
                buyer_city: primaryBuyer.value?.city ?? null,
                buyer_zipcode: primaryBuyer.value?.postalCode ?? null,
                // eierandel: primaryBuyer?.value?.interest,
                eierandel: `1/${buyerValues.length}`,
            }
            if (primaryBuyer?.isBuyer) {
                primaryBuyerRequest = {
                    ...primaryBuyerRequest,
                    buyer_first_name: primaryBuyer?.value?.firstName,
                    buyer_last_name: primaryBuyer?.value?.lastName,
                    personnummer: primaryBuyer?.value?.personnummer,

                }
            } else {
                primaryBuyerRequest = {
                    ...primaryBuyerRequest,
                    companyName: primaryBuyer?.value?.companyName,
                    buyer_first_name: primaryBuyer?.value?.companyName,
                    buyer_last_name: null,
                    organisationNumber: primaryBuyer?.value?.organisationNumber,
                    contact_person: primaryBuyer?.value?.contactPerson,
                    ssn_number: primaryBuyer.value?.contactPersonSSNNo
                }
            }



            addBuyerDetails({
                variables: {
                    input: {
                        property_id: propertyId,
                        upload_contract: files,
                        buyer_reg_link: IS_WEB ? `${Constants.APIConfig.DOMAIN}${Strings.buyer}/registrerdeg` : "",
                        ...values,
                        ...primaryBuyerRequest,
                        co_buyer: coBuyerRequest
                    }
                }
            }).then((data) => {
                showMessage(data?.data?.add_buyer_user_deails?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch((e) => {

            })
        }
    };

    const handleChangeText = (text, fieldId) => {
        let updatedValues = {...values};
        updatedValues[fieldId] = text;
        setValues(updatedValues)
    };

    const onFocus = (id) => {
        let updatedErrors = {...errors};
        updatedErrors[id] = null;
        setErrors(updatedErrors)

    };

    const renderInput = (item, index) => {

        return (
            <InputField key={item.id.toString()}
                        ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                        mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        disabled={item.disabled}
                        onChange={(text) => handleChangeText(text, item.id)}
                        onFocus={() => onFocus(item.id)}
                        error={errors[item.id]}
                        value={values[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const renderValues = (item, index, bankData) => {


        return (
            <View key={index.toString()}>
                <Label small
                       mt={10}
                       mb={5}
                       font_medium>
                    {item.title}
                </Label>
                <Label small
                       mb={10}>
                    {bankData[item.id]}
                </Label>
            </View>
        )


    };

    const renderBuyerOwnership = (item, index, bankData) => {

        return (
            <View key={index.toString()}>
                <Label small
                       mt={10}
                       mb={5}
                       font_medium>
                    {item.title}
                </Label>
                <Label small
                       mb={10}>
                    {bankData[item.id]}
                </Label>
            </View>
        )


    };

    const renderFiles = (item, isDeletable) => {
        return (
            <Attachment style={styles.files}
                        isDeletable={isDeletable}
                        name={item.name}
                        onPressDownload={isDeletable ? null : () => {
                            openInNewTab(`${Constants.APIConfig.STORAGE_URL}${item.doc_url}`)
                        }}
                        onPressDelete={() => setFiles([])}/>
        )
    };

    const renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}
                                  scrollEnabled/>;
    };

    const renderAdditionalBuyer = (parentItem, parentIndex) => {
        return (
            <View key={parentIndex.toString()}>
                {parentIndex !== 0 ? (<Hr/>) : null}
                <View style={styles.bankLblCont}>
                    <View style={styles.primaryLblCont}>
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => setPrimary(parentIndex)}>
                            <Icon name={primaryUser === parentIndex ? 'tickmark' : 'radio-normal'}
                                  color={Color.TERNARY_TEXT_COLOR}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                        <Label small
                               style={CommonStyle.flex}
                               ml={10}
                               font_medium>
                            {`${Strings.buyer} ${parentIndex + 1} :`}
                        </Label>
                    </View>
                    {((parentIndex !== 0 && !buyerValues[parentIndex]?.value?.contactId) && primaryUser !== parentIndex) ? (
                        <TouchableOpacity activeOpacity={0.5}
                                          onPress={() => removeBuyer(parentIndex)}>
                            <Icon name={'order-cancel'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.RED}/>
                        </TouchableOpacity>) : null}
                </View>
                <Label small
                       font_medium
                       mb={10}>
                    {"Er eier et aksjeselskap?"}
                </Label>
                <RadioGroup changeOnProps
                            small
                            selectedOptions={parentItem.isBuyer ? "No" : "Yes"}
                            disabled={primaryUser === parentIndex}
                            onSelectOption={(val) => {
                                replaceBuyerWithCompany(val === 'No' ? BUYER_INPUTS : COMPANY_INPUTS, parentIndex, parentItem.isBuyer,buyerValues[parentIndex]?.value)
                            }}
                            contentStyle={{flexDirection: 'row', marginBottom: 20}}
                            options={Constants.RADIO_OPTIONS}/>

                {parentItem.inputs.map((item, index) => renderBuyerForm(item, index, parentIndex, parentItem.isBuyer))}
            </View>
        )
    }

    const renderBuyerForm = (item, index, parentIndex, isBuyer) => {

        return (
            <InputField key={item.id.toString()}
                        textArea={item.type === 'textArea'}
                        type={item.type}
                        labelProps={{
                            small: true,
                            font_medium: true,
                        }}
                        style={{marginTop: 0}}
                        disabled={item.disabled}
                        onChange={(text) => handleBuyerChangeText(text, item.id, parentIndex, isBuyer)}
                        onFocus={() => onBuyerFocus(item.id, parentIndex)}
                        error={buyerErrors[parentIndex][item.id]}
                        value={item.id === "interest" ? `1/${buyer.length}` : buyerValues[parentIndex]?.value[item.id]}
                        labelText={item.title}
                        placeholder={item.placeholder}/>
        )
    };

    const handleBuyerChangeText = (text, fieldId, parentIndex, isBuyer) => {
        let updatedValues = buyerValues.slice();
        updatedValues[parentIndex] = {
            isBuyer: isBuyer,
            value: {
                ...updatedValues[parentIndex].value,
                [fieldId]: text.split(" ").join("")
            }
        };
        setBuyerValues(updatedValues)
    };

    const onBuyerFocus = (id, parentIndex) => {
        let updatedErrors = buyerErrors.slice();
        updatedErrors[parentIndex][id] = null;
        setBuyerErrors(updatedErrors)
    };

    const addAdditionlBuyer = () => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.push({
            isBuyer: true,
            value: {}
        });

        updatedError.push({});
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        updatedBuyer.push({
            isBuyer: true,
            inputs: BUYER_INPUTS
        });

        setBuyer(updatedBuyer)
    };

    const replaceBuyerWithCompany = (inputs, index, isBuyer,item) => {
        const updatedBuyer = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();

        updatedValue[index] = {
            isBuyer: !isBuyer,
            value: {
                contactId:item.contactId,
            }
        };
        updatedError[index] = {}
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)

        updatedBuyer[index] = {
            isBuyer: !isBuyer,
            inputs: inputs
        };
        setBuyer(updatedBuyer)
    };

    const removeBuyer = (index) => {
        const updatedBank = buyer.slice();
        const updatedValue = buyerValues.slice();
        const updatedError = buyerErrors.slice();
        updatedValue.splice(index, 1);
        updatedError.splice(index, 1);
        updatedBank.splice(index, 1);
        setBuyerValues(updatedValue)
        setBuyerErrors(updatedError)
        setBuyer(updatedBank)
    };

    const renderBuyer = (ownerShipData, dataIndex) => {
        return (
            <>
                <Label small
                       font_medium
                       mt={20}
                       mb={10}>
                    {`${Strings.buyer} ${dataIndex + 2}`}
                </Label>
                {ownerShipData.contact_type === 0 || ownerShipData.contactType === 0 ?
                    BUYER_OWNERSHIP.map((item, index) => renderBuyerOwnership(item, dataIndex, ownerShipData)) :
                    COMPANY_OWNERSHIP.map((item, index) => renderBuyerOwnership(item, dataIndex, ownerShipData))
                }
                <Hr/>
            </>
        )
    };

    return (
        <View style={CommonStyle.flex}>
            <Toast ref={r => toast = r}/>
            <Header navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.buyer}
                    animatedValue={scrollY}
            />

            <Icon name={'buyer'}
                  style={styles.icPage}
                  size={ThemeUtils.fontXXLarge}
                  color={Color.PRIMARY}/>
            <Label small
                   mt={20}
                   ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                   mr={ThemeUtils.COMMON_HORIZONTAL_MARGIN}>
                {'Nedenfor finner du informasjonen vedrørende kjøper som her hentet fra meglersystemet. Rediger dersom nødvendig og send så invitasjon til kjøper til Moovin systemet. Dette vil også fungere som akseptbrev for kjøper.Informasjonen fra kjøper vil du finn i fanen ved siden av når den er sendt tilbake fra kjøper.'}
            </Label>
            <IndicatorViewPager indicator={renderTabIndicator()}
                                style={CommonStyle.flex}>
                <View style={styles.cont}>
                    <Animated.ScrollView showsVerticalScrollIndicator={false}
                                         scrollEventThrottle={1}>
                        {vitechBuyer?.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <>

                                <View style={styles.container}>
                                    {/*<Label small
                                           mb={15}
                                           style={CommonStyle.flex}
                                           font_medium>
                                        {`${Strings.buyer} 1:`}
                                    </Label>*/}
                                    {/*{BUYER_INPUTS.map((item, index) => renderBuyerForm(item, index, 0, true))}*/}
                                    {buyer.length !== 0 ? (
                                        <>
                                            {buyer.map(renderAdditionalBuyer)}
                                        </>
                                    ) : null}
                                    <CustomButton mb={10}
                                                  mt={10}
                                                  title={"Legg til medeier"}
                                                  onPress={addAdditionlBuyer}/>
                                </View>

                                <Label font_medium
                                       ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mt={20}
                                       mb={10}>
                                    {Strings.propertyInfo}
                                </Label>
                                {propertyInputs.map(renderInput)}
                                <View style={styles.uploadDocCont}>
                                    <Label small
                                           font_medium
                                           mt={5}
                                           mb={10}>
                                        {Strings.uploadContractDraft}
                                    </Label>
                                    <FilePicker type={FileType.PDF}
                                                onFilePicked={(data, file) => setFiles(file)}/>
                                    {files.map((item) => renderFiles(item, true))}
                                    <CustomButton mt={20}
                                                  loading={addBuyerMutation.loading}
                                                  onPress={registerBuyer}
                                                  title={Strings.registerBuyer}/>
                                </View>
                            </>
                        )}
                    </Animated.ScrollView>
                </View>
                <View style={styles.cont}>
                    <Animated.ScrollView showsVerticalScrollIndicator={false}
                                         scrollEventThrottle={1}>
                        <View style={styles.container}>
                            {loading && !data ? (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) :
                                data?.property_info_from_buyer ? (
                                    <>

                                        <Label font_medium
                                               mt={20}>
                                            {"Finansiering"}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={10}
                                               mb={5}>
                                            {"Bank for egenkapital"}
                                        </Label>
                                        <Label small>
                                            {data?.property_info_from_buyer?.buyerBankDetail?.agencapital}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={20}
                                               mb={5}>
                                            {"Opprinnelse egenkapital"}
                                        </Label>
                                        {data?.property_info_from_buyer?.buyerBankDetail?.opparinelse_text ? (
                                            <Label small>
                                                {data?.property_info_from_buyer?.buyerBankDetail?.opparinelse_text}
                                            </Label>
                                        ) : (
                                            <Label small>
                                                {originQuery?.data?.opprinnelse_egenkapital_option?.find(item => item.id === data?.property_info_from_buyer?.buyerBankDetail?.opparinelse_id)?.name ?? ""}
                                            </Label>
                                        )}
                                        <Label font_medium
                                               mt={20}>
                                            {"Lån / Pant i boligen"}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={10}
                                               mb={5}>
                                            {"Skal du ha lån med pant i boligen?"}
                                        </Label>
                                        <Label small>
                                            {data?.property_info_from_buyer?.buyerBankDetail?.pant_boligen ? Strings.yes : Strings.no}
                                        </Label>
                                        {data?.property_info_from_buyer?.buyerBankDetail?.buyerBankInfo?.map((bankData, bankIndex) => {
                                            return (
                                                <>
                                                    <Label font_medium
                                                           mt={20}
                                                           mb={5}>
                                                        {`${Strings.additionalBank} ${bankIndex + 1}`}
                                                    </Label>
                                                    {CONTACT_INFO.map((item, index) => renderValues(item, index, bankData))}
                                                    <Hr/>
                                                </>
                                            )

                                        })}
                                        <Label font_medium
                                               mt={20}>
                                            {"Politisk eksponert person (PEP)"}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={10}
                                               mb={5}>
                                            {"Er du/dere en politisk(e) eksponert person(er)?"}
                                        </Label>
                                        <Label small>
                                            {data?.property_info_from_buyer?.buyerBankDetail?.pep_status ? Strings.yes : Strings.no}
                                        </Label>

                                        <Label small
                                               font_medium
                                               mt={20}
                                               mb={5}>
                                            {"Formål med kjøp"}
                                        </Label>
                                        {data?.property_info_from_buyer?.buyerBankDetail?.purchase_text ? (
                                            <Label small>
                                                {data?.property_info_from_buyer?.buyerBankDetail?.purchase_text}
                                            </Label>
                                        ) : (
                                            <Label small>
                                                {purposeQuery?.data?.purpose_purchase_option?.find(item => item.id === data?.property_info_from_buyer?.buyerBankDetail?.purpose_of_purchase_id)?.name ?? ""}
                                            </Label>
                                        )}
                                        <Label small
                                               font_medium
                                               mt={20}>
                                            {"Eventuelt tilgodehavende"}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mt={10}
                                               mb={5}>
                                            {"Overføres til kontonummer"}
                                        </Label>
                                        <Label small>
                                            {data?.property_info_from_buyer?.buyerBankDetail?.account_number}
                                        </Label>
                                        <Label font_medium
                                               mt={20}>
                                            {Strings.ownerShip}
                                        </Label>
                                        {renderBuyer(data?.property_info_from_buyer, -1)}
                                        {data?.property_info_from_buyer?.coBuyerInfo?.map(renderBuyer)}

                                        <Label font_medium
                                               mt={20}
                                               mb={5}>
                                            {Strings.contract}
                                        </Label>
                                        {data?.property_info_from_buyer?.buyerPropertyInfo?.document ?
                                            (<Attachment style={styles.files}
                                                         isDeletable={false}
                                                         name={item.name}
                                                         onPressDownload={() => {
                                                             openInNewTab(`${Constants.APIConfig.STORAGE_URL}${data?.property_info_from_buyer?.buyerPropertyInfo?.document?.doc_url}`)
                                                         }}
                                                         onPressDelete={() => setFiles([])}/>) : null}

                                    </>
                                ) : (
                                    <Label small
                                           font_medium
                                           align={'center'}>
                                        {"Ingen data"}
                                    </Label>
                                )}
                        </View>
                    </Animated.ScrollView>
                </View>

                <View>
                    <Animated.ScrollView>
                        {contractCommentQuery?.data?.buyer_contract_doc ? (
                            <View style={styles.container}>
                                <CustomButton title={"Åpne PDF"}
                                              disabled={!contractCommentQuery?.data?.buyer_contract_doc?.doc_url}
                                              mt={20}
                                              mb={15}
                                              onPress={() => {
                                                  openInNewTab(`${Constants.APIConfig.STORAGE_URL}${contractCommentQuery?.data?.buyer_contract_doc?.doc_url}`)
                                              }}
                                />
                                <Label small
                                       font_medium>
                                    {Strings.Comments}
                                </Label>

                                {contractCommentQuery?.data?.buyer_contract_doc?.contractDocComment?.map(item => (
                                    <>

                                        <Label small
                                               mt={10}
                                               mb={5}
                                               color={Color.PRIMARY}>
                                            {item.comment}
                                        </Label>
                                        <Label small
                                               font_medium
                                               mb={10}
                                               color={Color.DARK_GRAY}>
                                            {moment.utc(item.created_at, DateUtils.yyyy_mm_dd_hh_mm_ss).tz('Europe/Oslo').format(DateUtils.hh_mm_dd_mm_yyy)}
                                        </Label>
                                    </>
                                ))}
                            </View>) : (
                            <Label small
                                   font_medium
                                   align={'center'}
                                   style={CommonStyle.loader}>
                                {"Ingen data"}
                            </Label>
                        )}
                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>


        </View>
    )

}
