import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, TouchableOpacity, View} from 'react-native';
import {CustomButton, Header, Hr, InputField, Label, Modal, Ripple} from "components/src/components";
//Network
import {useMutation, useQuery} from "@apollo/react-hooks";
import {ASSIGNMENT_DETAILS} from "components/src/api/query";
//Utils
import {
    Color,
    CommonStyle,
    Constants,
    DateUtils,
    encryptId,
    IS_WEB,
    openInNewTab,
    Routes,
    Strings,
    ThemeUtils,
    validation
} from "components/src/utils";
import styles from './styles';
import moment from "moment";
import {ADD_OPP_DRAG_NO, AGENT_SIGN_AGREEMENT} from "../../../../../api/mutation";
import {connect} from "react-redux";
import {setAgentSession} from "../../../../../redux/action";


function AssignmentDetails(props) {
    const {navigation, history} = props;

    const scrollY = new Animated.Value(0);

    const [assignment, setAssignment] = useState(IS_WEB ? history.location.state?.assignment ?? {} : navigation.getParam('assignment', {}));
    const [oppDragError, setOppDragError] = useState(null);
    const [oppDragNo, setOppDragNo] = useState('');
    const [newOppDragNo, setNewOppDragNo] = useState('');
    const [oppDragVisible, setOppDragVisible] = useState(false);

    const [signAgreement, addSignAgreementMutation] = useMutation(AGENT_SIGN_AGREEMENT);

    const [addOppDragNo, addOppDragNoMutation] = useMutation(ADD_OPP_DRAG_NO, {
        refetchQueries: [{
            query: ASSIGNMENT_DETAILS,
            variables: {
                id: assignment.id
            }
        }]
    });

    const {loading, data, error, refetch} = useQuery(ASSIGNMENT_DETAILS, {
        variables: {
            id: assignment.id
        }
    })

    useEffect(() => {
        setOppDragError(null)
    }, [oppDragNo]);


    useEffect(() => {
        if (data?.signature_property_details) {
            setAssignment(data?.signature_property_details)
        }
    }, [data]);


    const handleAddOppDrag = () => {
        const error = validation('oppDragNo', oppDragNo)
        if (error) {
            setOppDragError(error)
        } else {
            addOppDragNo({
                variables: {
                    id: assignment?.id,
                    oppNo: oppDragNo
                }
            }).then(() => {
                setOppDragVisible(false)
            }).catch((e) => {
                setOppDragVisible(false)
            })
        }
    };

    const getStatusLabel = (item) => {
        if (item?.signed_by_agent === 0 && item?.signed_both_seller === 1 && item?.total_owner >= 2) {
            return Strings.seller2Pending
        } else if (item?.signed_by_agent === 0 && item?.signed_both_seller === 2 && item?.total_owner >= 2) {
            return Strings.bothSellerSigned
        } else if (item?.signed_both_seller === 1 && item?.total_owner === 1) {
            return Strings.seller1Signed
        } else if (item?.signed_by_agent === 1 && item?.seller_completed === 1) {
            return Strings.completed
        }
    };


    const renderAddOpdragModal = () => {
        return (
            <Modal visible={oppDragVisible}
                   transparent>
                <TouchableOpacity activeOpacity={1}
                                  onPress={() => setOppDragVisible(false)}
                                  style={styles.modalContent}>
                    <TouchableOpacity activeOpacity={1}
                                      style={styles.inptCont}>
                        <InputField align={'center'}
                                    ml={ThemeUtils.relativeRealWidth(4)}
                                    mr={ThemeUtils.relativeRealWidth(4)}
                                    mt={ThemeUtils.relativeRealHeight(2)}
                                    type={'number'}
                                    value={oppDragNo}
                                    onChange={setOppDragNo}
                                    placeholder={Strings.addOppDragNo}
                                    onFocus={() => setOppDragError(null)}
                                    error={oppDragError}
                                    labelText={Strings.addOppDragNo}/>
                        <Hr/>
                        <View style={styles.btnCont}>
                            <Ripple style={styles.btnModal}
                                    onPress={() => setOppDragVisible(false)}>
                                <Label>
                                    {Strings.cancel}
                                </Label>
                            </Ripple>
                            <Hr/>
                            <Ripple style={styles.btnModal}
                                    onPress={handleAddOppDrag}>
                                <Label color={Color.TEXT_COLOR_BLUE}>
                                    {addOppDragNoMutation.loading ?
                                        (<ActivityIndicator color={Color.PRIMARY}/>) :
                                        Strings.save}
                                </Label>
                            </Ripple>
                        </View>
                    </TouchableOpacity>


                </TouchableOpacity>
            </Modal>
        )
    };
    return (
        <View style={CommonStyle.flex}>
            {renderAddOpdragModal()}
            <Header initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                    navigation={IS_WEB ? history : navigation}
                    animatedTitle={Strings.assignmentDetails}
                    animatedValue={scrollY}/>
            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.scrollCont}
                                 onScroll={Animated.event(
                                     [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                     {useNativeDriver: true})}>
                <View style={CommonStyle.flex}>
                    <Label small
                           mt={ThemeUtils.relativeRealHeight(8)}
                           font_medium>
                        {Strings.status}
                    </Label>
                    <Label small
                           mt={5}>
                        {getStatusLabel(assignment)}
                    </Label>
                    <Label small
                           font_medium
                           mt={15}>
                        {Strings.oppDragNo}
                    </Label>
                    {assignment.oppdrags_nummer ? (
                        <Label small
                               mt={5}>
                            {assignment.oppdrags_nummer}
                        </Label>) : (
                        <CustomButton font_regular
                                      mt={10}
                                      onPress={() => setOppDragVisible(true)}
                                      title={`+${Strings.addOppDragNo}`}
                                      borderWidth={1}
                                      borderColor={Color.PRIMARY_LIGHTER}
                                      textColor={Color.PRIMARY}
                                      bgColor={Color.WHITE}/>
                    )}

                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.propertyAddress}
                    </Label>
                    <Label small
                           mt={5}>
                        {`${assignment?.street_adress} ${assignment?.zipcode ?? ""}`}
                    </Label>

                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.date}
                    </Label>
                    <Label small
                           mt={5}>
                        {assignment?.seller_sign_date ? moment(assignment.seller_sign_date).format(DateUtils.dd_mm_yyyy) : null}
                    </Label>

                    <Label small
                           mt={15}
                           font_medium>
                        {Strings.seller1}
                    </Label>
                    <Label small
                           mt={5}>
                        {`${assignment?.property_seller[0]?.firstName ?? ""} ${assignment?.property_seller[0]?.lastName ?? ""}`}
                    </Label>

                    {assignment?.property_seller[1] ?
                        (<Label small
                                mt={15}
                                font_medium>
                            {Strings.seller2}
                        </Label>) : null}
                    {assignment?.property_seller[1] ?
                        (<Label small
                                mt={5}>
                            {`${assignment?.property_seller[1]?.firstName ?? ""} ${assignment?.property_seller[1]?.lastName ?? ""}`}
                        </Label>) : null}
                </View>

                <CustomButton title={Strings.signInWithBankId}
                              loading={addSignAgreementMutation.loading}
                              onPress={() => {
                                  signAgreement({
                                      variables: {
                                          input: {
                                              redirect_url: `${Constants.APIConfig.DOMAIN}${Routes.SignAssignmentSuccess}/${encryptId(JSON.stringify({property_id: assignment.id,}))}`,
                                              cancel_url: `${Constants.APIConfig.DOMAIN}${Routes.AssignmentDetails}`,
                                          },
                                      }
                                  }).then((data) => {
                                      if (IS_WEB) {
                                          localStorage.setItem(
                                              'agentSign',
                                              JSON.stringify(data?.data.agentSignInitSession.initial_session),
                                          );
                                          openInNewTab(data?.data.agentSignInitSession.initial_session.AuthenticationUrl)
                                      } else {
                                          props.navigation.navigate(Routes.WebViewScreen, {
                                              redirect_url: `${Constants.APIConfig.DOMAIN}${Routes.SignAssignmentSuccess}/${encryptId(assignment?.id)}`,
                                              redirectRoute: Routes.SignAssignmentSuccess,
                                              cancel_url: `${Constants.APIConfig.DOMAIN}${Routes.AssignmentDetails}`,
                                              params: {
                                                  agentSign: data?.data?.agentSignInitSession?.initial_session,
                                                  propertyId: assignment?.id
                                              },
                                              url: data?.data?.agentSignInitSession?.initial_session?.AuthenticationUrl
                                          })
                                      }

                                  }).catch(() => {

                                  })
                              }}
                              mt={10}
                              mb={8}/>
                <CustomButton font_regular
                              mt={10}
                              mb={8}
                              loading={loading}
                              disabled={!data?.signature_property_details?.agreement_pdf_path}
                              onPress={() => {
                                  openInNewTab(`${Constants.APIConfig.STORAGE_URL}${data?.signature_property_details?.agreement_pdf_path}`)
                              }}
                              title={Strings.viewPdf}
                              borderWidth={1}
                              borderColor={Color.PRIMARY_LIGHTER}
                              textColor={Color.PRIMARY}
                              bgColor={Color.WHITE}/>
            </Animated.ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => {
    console.log(state)
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        setSession: (session) => setAgentSession(session)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentDetails);
