import React, {Component} from 'react';

import {Animated, TouchableOpacity, View} from 'react-native';
import DatePickerMob from '@ant-design/react-native/lib/date-picker-view'
import DatePickerWeb from 'react-mobile-datepicker';
//Third Party
import PropTypes from 'prop-types';
//Custom Components
import {Hr, Label, Modal, Ripple} from 'components/src/components';
import {Color, CommonStyle, DateUtils, Icon, IS_WEB, roundDate, Strings} from 'components/src/utils';
import styles from './styles';
import moment from "moment";
//Utils

const dateConfig = {
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
};


const timeConfig = {
    'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 15,
    },
};

class DatePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedDate: props.selectedDate
        };
        this.animatedHeight = new Animated.Value(0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedDate !== this.props.selectedDate) {
            this.setState({
                selectedDate: this.props.selectedDate
            })
        }
    }

    //utility
    startAnimation = () => {
        Animated.timing(this.animatedHeight, {
            toValue: IS_WEB ? 275 : 200,
            duration: 200,
        }).start()

    };

    handleHardwareBack = () => {
        this.closePopUp();
        return true;
    };

    //User interaction
    closePopUp = (date) => {
        Animated.timing(this.animatedHeight, {
            toValue: 0,
            duration: 200,
        }).start(() => {
            this.setState(prevState => ({
                visible: false,
                selectedDate: date ? date : prevState.selectedDate
            }), () => {
                console.log(this.state.selectedDate, "DATE")
                this.state.selectedDate && this.props.onSelectDate && this.props.onSelectDate(this.state.selectedDate)
            })
        });
    };


    render() {
        const {customConfig, children, options, round, value, maxTime, minTime, title, mode, min, max, onSelectDate, style, placeholder} = this.props;
        const {selectedDate, visible} = this.state;
        return (
            <View style={[{marginTop: !title ? 0 : 20}, style]}>
                {title ? (
                    <Label small
                           font_medium
                           mb={10}>
                        {title}
                    </Label>) : null}
                {IS_WEB ? (
                        <Modal transparent
                               hardwareAccelerated
                               visible={this.state.visible}
                               onRequestClose={this.handleHardwareBack}>
                            <View style={{flex: 1, justifyContent: 'center'}}>
                                <TouchableOpacity activeOpacity={1}
                                                  onPress={this.handleHardwareBack}
                                                  style={styles.container}/>
                                <DatePickerWeb
                                    theme={'ios'}
                                    isPopup={false}
                                    value={selectedDate ? new Date(roundDate(moment(selectedDate, mode === 'time' ? DateUtils.hh_mm_ss : DateUtils.yyyy_mm_dd), round)) : new Date(roundDate(moment().add(1, 'day'), round))}
                                    customHeader={
                                        <Label mt={11}
                                               align={'center'}>
                                            {mode === 'time' ? Strings.selectTime : Strings.selectDate}
                                        </Label>
                                    }
                                    min={mode === 'time' ? minTime : min}
                                    max={mode === 'time' ? maxTime : max}
                                    dateConfig={customConfig ? customConfig : mode === 'time' ? timeConfig : dateConfig}
                                    confirmText={Strings.done}
                                    cancelText={Strings.cancel}
                                    onChange={(date) => this.setState({
                                        selectedDate: date
                                    })}
                                    onSelect={this.closePopUp}
                                    onCancel={() => this.closePopUp()}
                                />
                            </View>

                        </Modal>
                    )
                    : (
                        <Modal transparent
                               hardwareAccelerated
                               visible={this.state.visible}
                               onRequestClose={this.handleHardwareBack}>
                            <View style={{flex: 1, justifyContent: 'flex-end'}}>
                                <TouchableOpacity activeOpacity={1}
                                                  onPress={this.handleHardwareBack}
                                                  style={styles.container}/>
                                <Animated.View style={{
                                    backgroundColor: "#FFF",
                                    height: this.animatedHeight,
                                }}>
                                    <View style={styles.mobilePickerCont}>
                                        <TouchableOpacity
                                            onPress={() => this.closePopUp()}>
                                            <Label color={Color.EDIT_ICON}>
                                                {Strings.cancel}
                                            </Label>
                                        </TouchableOpacity>
                                        <Label>
                                            {mode === 'time' ? Strings.selectTime : Strings.selectDate}
                                        </Label>
                                        <TouchableOpacity
                                            onPress={() => this.closePopUp(this.state.selectedDate)}>
                                            <Label color={Color.EDIT_ICON}>
                                                {Strings.okay}
                                            </Label>
                                        </TouchableOpacity>
                                    </View>
                                    <Hr/>
                                    <DatePickerMob
                                        locale={{
                                            dismissText: Strings.cancel,
                                            okText: Strings.okay
                                        }}
                                        minuteStep={15}
                                        visible={visible}
                                        value={selectedDate}
                                        mode={mode}
                                        defaultDate={value}
                                        minDate={min}
                                        maxDate={max}
                                        onChange={(date) => this.setState({
                                            selectedDate: date
                                        })}
                                        format={"DD-MM-YYYY"}
                                        onOk={this.closePopUp}
                                        onDismiss={() => this.closePopUp()}>
                                    </DatePickerMob>
                                </Animated.View>
                            </View>
                        </Modal>
                    )}

                {children ? (
                    <Ripple onPress={() => this.setState({visible: true}, this.startAnimation)}>
                        {children}
                    </Ripple>
                ) : (<>
                    <Ripple style={styles.dropDownCont}
                            onPress={() => this.setState({visible: true}, this.startAnimation)}>
                        <Label small
                               mt={IS_WEB ? 2 : 0}
                               font_regular
                               style={CommonStyle.flex}
                               color={this.state.selectedOption !== null ? Color.PRIMARY : Color.SECONDARY_TEXT_COLOR}>

                            {selectedDate ? moment(selectedDate, mode === 'time' ? DateUtils.hh_mm_ss : DateUtils.yyyy_mm_dd).format(mode === 'time' ? DateUtils.hh_mm : DateUtils.dd_mm_yyyy) : placeholder}
                        </Label>
                        <Icon name={mode === 'time' ? 'time' : 'calendar'}
                              size={12}
                              color={Color.PRIMARY}/>
                    </Ripple>
                </>)}
            </View>
        );
    }
}

DatePicker.defaultProps = {
    placeholder: Strings.selectTime,
    mode: 'date',
    value: new Date(),
    round: true
};

DatePicker.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
    min: PropTypes.date,
    max: PropTypes.date,
    onSelectDate: PropTypes.func,
    value: PropTypes.date,
    round: PropTypes.bool
};

export default DatePicker;
