import React, {useEffect, useRef, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Image, TouchableOpacity, View} from 'react-native'
import {
    CustomButton,
    Dialog,
    FilePicker,
    FileType,
    Header,
    Hr,
    IndicatorViewPager,
    InputField,
    Label,
    Modal,
    PagerTabIndicator,
    RichTextEditor,
    RichToolbar
} from "components/src/components";
//Utils
import styles from './styles';

import {Color, CommonStyle, Constants, Icon, IS_WEB, showMessage, Strings, ThemeUtils} from "components/src/utils";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
    AGENT_BEFARING_DETAILS,
    AGENT_BEFARING_MEDIA,
    BEFARING_AFTER_PRODUCT_GALLERY,
} from "../../../../../../api/query";
import {
    DELETE_BEFARING_MEDIA,
    PERSONAL_ADD_IMAGE_AFTER_BEFARING,
    PERSONAL_DELETE_IMAGE_AFTER_BEFARING,
    PERSONAL_REPLACE_IMAGE_AFTER_BEFARING,
    REPLACE_AGENT_BEFARING_MEDIA,
    UPDATE_AGENT_BEFARING,
    UPLOAD_AGENT_BEFARING_DATA
} from "../../../../../../api/mutation";
import PhotoDetails from "../../../../../../components/view/PhotoDetails";


const TABS = [{
    text: "Avsnitt 01"
}, {
    text: "Before Produktgalleri",
}, {
    text: "After Produktgalleri"
}];

const scrollY = new Animated.Value(0);

function WBefaring(props) {

    const {history, user, navigation} = props;

    const [brokerWords, setBrokerWords] = useState("");
    let richText = useRef();
    const [headLines, setHeadLines] = useState("");
    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);
    const [imageForPreview, setImageForPreview] = useState({});

    const [afterHeadLines, setAfterHeadLines] = useState("");
    const [afterSelectionEnable, setAfterSelectionEnable] = useState(false);
    const [afterSelectedImages, setAfterSelectedImages] = useState([]);
    const [afterDeleteVisible, setAfterDeleteVisible] = useState(false);
    const [isAfterPhotoDetailVisible, setAfterPhotoDetailVisible] = useState(false);
    const [afterImageForPreview, setAfterImageForPreview] = useState({});

    const [saveDesc, saveDescMutation] = useMutation(UPDATE_AGENT_BEFARING);

    const {loading, data, error} = useQuery(AGENT_BEFARING_DETAILS, {
        variables: {
            status: 1,
            agentId: user?.id
        }
    });

    const [uploadData, uploadDataMutation] = useMutation(UPLOAD_AGENT_BEFARING_DATA, {
        refetchQueries: [{
            query: AGENT_BEFARING_MEDIA,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [replaceImage, replaceImageMutation] = useMutation(REPLACE_AGENT_BEFARING_MEDIA, {
        refetchQueries: [{
            query: AGENT_BEFARING_MEDIA,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [deleteImage, deleteImageMutation] = useMutation(DELETE_BEFARING_MEDIA, {
        refetchQueries: [{
            query: AGENT_BEFARING_MEDIA,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const mediaQuery = useQuery(AGENT_BEFARING_MEDIA, {
        variables: {
            status: 1,
            agentId: user?.id
        }
    });

    /*-----------------------------------After--------------------------------------------*/
    const [uploadAfterData, uploadAfterDataMutation] = useMutation(PERSONAL_ADD_IMAGE_AFTER_BEFARING, {
        refetchQueries: [{
            query: BEFARING_AFTER_PRODUCT_GALLERY,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [replaceAfterImage, replaceAfterImageMutation] = useMutation(PERSONAL_REPLACE_IMAGE_AFTER_BEFARING, {
        refetchQueries: [{
            query: BEFARING_AFTER_PRODUCT_GALLERY,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [deleteAfterImage, deleteAfterImageMutation] = useMutation(PERSONAL_DELETE_IMAGE_AFTER_BEFARING, {
        refetchQueries: [{
            query: BEFARING_AFTER_PRODUCT_GALLERY,
            variables: {
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const afterBefaringQuery = useQuery(BEFARING_AFTER_PRODUCT_GALLERY, {
        variables: {
            status: 1,
            agentId: user?.id
        }
    });

    useEffect(() => {
        if (data?.agent_befaring_content) {
            setBrokerWords(data?.agent_befaring_content?.description)
        }
    }, [data]);

    useEffect(() => {
        if (mediaQuery?.data?.befaring_product_gallery) {
            setHeadLines(mediaQuery?.data?.befaring_product_gallery?.headline)
        }
    }, [mediaQuery]);

    useEffect(() => {
        if (afterBefaringQuery?.data?.befaring_after_product_gallery) {
            setAfterHeadLines(afterBefaringQuery?.data?.befaring_after_product_gallery?.headline)
        }
    }, [afterBefaringQuery.data]);


    useEffect(() => {
        setAfterSelectionEnable(!!afterSelectedImages.length)
    }, [afterSelectedImages]);

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);


    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };

    const addOrRemoveAfterImage = (id) => {
        let updatedImages = afterSelectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setAfterSelectedImages(updatedImages)
    };

    const validateHeadline = () => {
        if (headLines) {
            uploadData({
                variables: {
                    headline: headLines,
                }
            }).then(() => {
                showMessage(data?.data?.personal_befaring_product_media?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        }

    };
    const validateAfterHeadline = () => {
        if (afterHeadLines) {
            uploadAfterData({
                variables: {
                    headline: afterHeadLines,
                }
            }).then(() => {
                showMessage(data?.data?.personal_befaring_product_media?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        }

    };

    const validate = async () => {
        const content = await richText.current.getContentHtml();
        const brokerWords = content.toString();
        if (brokerWords) {
            saveDesc({
                variables: {
                    desc: brokerWords
                }
            }).then((data) => {
                showMessage(data?.data?.personal_befaring_content?.meta?.message, Constants.MessageType.SUCCESS)
            }).catch(() => {

            })
        }
    };

    const _renderTabIndicator = () => {
        return <PagerTabIndicator tabs={TABS}
                                  scrollEnabled/>;
    };

    const renderSurveyConfirmation = () => {
        return (
            <>
                <Label font_medium>
                    {Strings.wordsFromBroker}
                </Label>
                {/*<InputField
                    mt={10}
                    align={'center'}
                    type={'text'}
                    textArea
                    value={brokerWords}
                    onChange={setBrokerWords}
                />*/}
                <View style={{
                    borderWidth: 1,
                    borderColor: Color.DARK_GRAY,
                    marginVertical: 20,
                    borderRadius: 4
                }}>
                    <RichTextEditor ref={richText}
                                    placeholder={Strings.wordsFromBroker}
                                    initialContentHTML={brokerWords}/>
                    <RichToolbar getEditor={() => richText.current}/>
                </View>
            </>
        );
    };

    const renderPhotoGallery = () => {
        return (
            <>
                <Label font_medium>
                    {Strings.headLine}
                </Label>
                <InputField
                    mt={10}
                    align={'center'}
                    type={'text'}
                    value={headLines}
                    onChange={setHeadLines}
                />
                <View style={styles.buttonContainer}>
                    <CustomButton loading={uploadDataMutation.loading && headLines}
                                  onPress={validateHeadline}
                                  style={{flex: 0.45}}
                                  title={Strings.save}
                    />
                    <CustomButton borderWidth={1}
                                  borderColor={Color.PRIMARY_TEXT_COLOR}
                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                  bgColor={Color.WHITE}
                                  style={{flex: 0.45}}
                                  title={Strings.cancel}
                                  onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}/>
                </View>

                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>
                <FilePicker multiple
                            style={{alignSelf: 'flex-start'}}
                            type={FileType.IMAGE}
                            onFilePicked={(data, files) => {
                                if (files.length) {
                                    uploadData({
                                        variables: {
                                            headline: headLines,
                                            images: files
                                        }
                                    }).then(() => {
                                        showMessage(data?.data?.personal_befaring_product_media?.meta?.message, Constants.MessageType.SUCCESS)
                                    }).catch(() => {

                                    })
                                }
                            }}>
                    <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                        {uploadDataMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<Icon name={'add'}
                                   size={ThemeUtils.responsiveScale(60)}
                                   color={Color.PRIMARY_LIGHT}/>)}
                    </View>
                </FilePicker>


                <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={deleteVisible}
                                onNegativePress={() => setDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteImage({
                                        variables: {
                                            ids: selectedImages,
                                            galleryId: mediaQuery?.data?.befaring_product_gallery?.id
                                        }
                                    }).then(() => {
                                        setSelectedImages([])
                                    }).catch(() => {

                                    });
                                    setDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteImageMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectionEnable(false)
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}

                {renderImageGrid(mediaQuery?.data?.befaring_product_gallery?.AgentProductGalleryImages ?? [])}
            </>
        );
    };

    const renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                        onPress={() => {
                            if (selectionEnable) {
                                addOrRemoveImage(item.id)
                            } else {
                                setImageForPreview({
                                    ...item,
                                    media_url: item.url
                                });
                                setPhotoDetailVisible(true)
                            }
                        }}
                        activeOpacity={0.7}>

                        {selectionEnable && selectedImages.includes(item.id) ? (
                            <View style={[styles.selectedImage]}>
                                <Icon name={'tickmark'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.TERNARY_TEXT_COLOR}/>
                            </View>) : null}
                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.url}`}}
                               style={styles.GridImage}/>
                    </TouchableOpacity>

                )
            })}
        </View>
    };

    const renderAfterPhotoGallery = () => {
        return (
            <>
                <Label font_medium>
                    {Strings.headLine}
                </Label>
                <InputField
                    mt={10}
                    align={'center'}
                    type={'text'}
                    value={afterHeadLines}
                    onChange={setAfterHeadLines}
                />
                <View style={styles.buttonContainer}>
                    <CustomButton loading={uploadAfterDataMutation.loading && afterHeadLines}
                                  onPress={validateAfterHeadline}
                                  style={{flex: 0.45}}
                                  title={Strings.save}
                    />
                    <CustomButton borderWidth={1}
                                  borderColor={Color.PRIMARY_TEXT_COLOR}
                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                  bgColor={Color.WHITE}
                        style={{flex: 0.45}}
                        title={Strings.cancel}
                        onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}/>
                </View>

                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>
                <FilePicker multiple
                            style={{alignSelf: 'flex-start'}}
                            type={FileType.IMAGE}
                            onFilePicked={(data, files) => {
                                if (files.length) {
                                    uploadAfterData({
                                        variables: {
                                            headline: afterHeadLines,
                                            photos: files
                                        }
                                    }).then(() => {
                                        showMessage(data?.data?.personal_befaring_product_media?.meta?.message, Constants.MessageType.SUCCESS)
                                    }).catch(() => {

                                    })
                                }
                            }}>
                    <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                        {uploadDataMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<Icon name={'add'}
                                   size={ThemeUtils.responsiveScale(60)}
                                   color={Color.PRIMARY_LIGHT}/>)}
                    </View>
                </FilePicker>


                <Hr lineStyle={{marginTop: 20}}/>
                {afterSelectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${afterSelectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={afterDeleteVisible}
                                onNegativePress={() => setAfterDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteAfterImage({
                                        variables: {
                                            ids: afterSelectedImages,
                                            galleryId: afterBefaringQuery?.data?.befaring_after_product_gallery?.id
                                        }
                                    }).then(() => {
                                        setAfterSelectedImages([])
                                    }).catch(() => {

                                    });
                                    setAfterDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteAfterImageMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setAfterDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setAfterSelectionEnable(false)
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}

                {renderAfterImageGrid(afterBefaringQuery?.data?.befaring_after_product_gallery?.AgentAfterGalleryMedia ?? [])}
            </>
        );
    };

    const renderAfterImageGrid = (data) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => afterSelectionEnable ? null : addOrRemoveAfterImage(item.id)}
                        onPress={() => {
                            if (afterSelectionEnable) {
                                addOrRemoveAfterImage(item.id)
                            } else {
                                setAfterImageForPreview({
                                    ...item,
                                    media_url: item.url
                                });
                                setAfterPhotoDetailVisible(true)
                            }
                        }}
                        activeOpacity={0.7}>

                        {afterSelectionEnable && afterSelectedImages.includes(item.id) ? (
                            <View style={[styles.selectedImage]}>
                                <Icon name={'tickmark'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.TERNARY_TEXT_COLOR}/>
                            </View>) : null}
                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item.url}`}}
                               style={styles.GridImage}/>
                    </TouchableOpacity>

                )
            })}
        </View>
    };

    return (
        <View style={styles.cont}>
            <Header tintColor={Color.PRIMARY}
                    style={CommonStyle.blankHeader}
                    navigation={IS_WEB ? history : navigation}/>

            <View>
                <View style={styles.contentCont}>
                    <View style={styles.titleCont}>
                        <Icon name={'search'}
                              size={ThemeUtils.fontXXLarge}/>
                        <Label xlarge
                               font_medium
                               mt={5}
                               ml={10}>
                            {Strings.WBefaring}
                        </Label>
                    </View>
                </View>

            </View>
            <IndicatorViewPager
                indicator={_renderTabIndicator()}
                style={{flex: 1, backgroundColor: 'white'}}>
                <View>
                    {loading && !data ? (
                        <ActivityIndicator color={Color.PRIMARY}
                                           style={CommonStyle.loader}/>
                    ) : (<>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             contentContainerStyle={styles.flexGrow}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                 {useNativeDriver: true})}>
                            <View>
                                <View style={styles.orderCont}>
                                    {renderSurveyConfirmation()}
                                </View>
                            </View>
                        </Animated.ScrollView>
                        <View style={styles.bottomButtonContainer}>
                            <CustomButton
                                loading={saveDescMutation.loading}
                                style={{flex: 0.4}}
                                title={Strings.save}
                                onPress={validate}/>
                            <CustomButton borderWidth={1}
                                          borderColor={Color.PRIMARY_TEXT_COLOR}
                                          textColor={Color.PRIMARY_TEXT_COLOR}
                                          bgColor={Color.WHITE}
                                style={{flex: 0.4}}
                                title={Strings.cancel}
                                onPress={() => IS_WEB ? history.goBack() : navigation.goBack()}/>
                        </View>
                    </>)}

                </View>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            <Modal visible={isPhotoDetailVisible}
                                   onRequestClose={() => setPhotoDetailVisible(false)}>
                                <PhotoDetails onClose={() => {
                                    setPhotoDetailVisible(false)
                                }}
                                              loading={replaceImageMutation.loading}
                                              onReplaceImage={replaceImage}
                                              replaceVariables={{
                                                  id: imageForPreview?.id,
                                                  galleryId: mediaQuery?.data?.befaring_product_gallery?.id
                                              }}
                                              variables={{
                                                  ids: [imageForPreview?.id],
                                                  galleryId: mediaQuery?.data?.befaring_product_gallery?.id
                                              }}
                                              deleteMutation={DELETE_BEFARING_MEDIA}
                                              refetchQuery={{
                                                  refetchQueries: [{
                                                      query: AGENT_BEFARING_MEDIA,
                                                      variables: {
                                                          status: 1,
                                                          agentId: user?.id
                                                      }
                                                  }]
                                              }}
                                              imageForPreview={imageForPreview}
                                />
                            </Modal>
                            <View style={styles.orderCont}>
                                {mediaQuery?.loading ? (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) : renderPhotoGallery()}
                            </View>
                        </View>
                    </Animated.ScrollView>
                </View>
                <View>
                    <Animated.ScrollView scrollEventThrottle={1}
                                         contentContainerStyle={styles.flexGrow}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View>
                            <Modal visible={isAfterPhotoDetailVisible}
                                   onRequestClose={() => setAfterPhotoDetailVisible(false)}>
                                <PhotoDetails onClose={() => {
                                    setAfterPhotoDetailVisible(false)
                                }}
                                              loading={replaceAfterImageMutation.loading}
                                              onReplaceImage={replaceAfterImage}
                                              replaceVariables={{
                                                  id: afterImageForPreview?.id,
                                                  galleryId: afterBefaringQuery?.data?.befaring_after_product_gallery?.id
                                              }}
                                              variables={{
                                                  ids: [afterImageForPreview?.id],
                                                  galleryId: afterBefaringQuery?.data?.befaring_after_product_gallery?.id
                                              }}
                                              deleteMutation={PERSONAL_DELETE_IMAGE_AFTER_BEFARING}
                                              refetchQuery={{
                                                  refetchQueries: [{
                                                      query: BEFARING_AFTER_PRODUCT_GALLERY,
                                                      variables: {
                                                          status: 1,
                                                          agentId: user?.id
                                                      }
                                                  }]
                                              }}
                                              imageForPreview={afterImageForPreview}
                                />
                            </Modal>
                            <View style={styles.orderCont}>
                                {afterBefaringQuery?.loading ? (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) : renderAfterPhotoGallery()}
                            </View>
                        </View>
                    </Animated.ScrollView>
                </View>
            </IndicatorViewPager>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(WBefaring);
