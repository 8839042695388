import React, { useState, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import BefaringLayout from '../../../layouts/BefaringLayout/index';

import './start-inspection.scss';

const { Title } = Typography;

function index(props) {
  let router=props.history
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop(window.innerWidth > 991.98);
    window.addEventListener('resize', setIsDesktop);
  });
  return (
    <BefaringLayout>
      <div style={{ padding: isDesktop ? '4em' : '2em' }}>
        <Title level={2} className="text-primary">
          Emilie Pettersen
        </Title>
        <p>Slalåmveien 37, 1350 LOMMEDALEN LOMMEDALEN 1350</p>
        <div style={{ marginTop: '3em' }} className="dashboard-cards">
          <Row gutter={[24, 12]}>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="photoStyling common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className="icon-photo" />
                        </div>
                        <div>Photo / Styling</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="digitalMarketing common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className=" icon-digital-sales-assignment" />
                        </div>
                        <div>Digital Marketing</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="printMarketing common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className=" icon-print-marketing" />
                        </div>
                        <div>Print Marketing</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="broker common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className="icon-befaringsbekreftelse" />
                        </div>
                        <div>Broker</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="marketingPackage common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className="icon-marketing-package" />
                        </div>
                        <div>CalenMarkedspakkerdar</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="boligselgerForSikring common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className=" icon-offers-on-insurance" />
                        </div>
                        <div>Boligselgerforsikring</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="calendar common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className=" icon-calendar" />
                        </div>
                        <div>Calendar</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={6} className="dashboard-cols">
              <div className="dashboards-cards-container">
                <div className="MissionCost common_rules_img">
                  <div className="overlay">
                    <div className="description">
                      <Link to="#">
                        <div className="icon-wrapper">
                          <i className="icon-rate" />
                        </div>
                        <div>Missions Cost</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </BefaringLayout>
  );
}

export default index;
