import React, {useRef, useState} from 'react';
//Components
import {ActivityIndicator, Animated, View} from 'react-native';
import {Dashboard, Dialog, Header, Label} from "components/src/components";
//Network
import {useQuery} from "@apollo/react-hooks";
import {BID_QUERY, MOOVIN_PROPERTY_DETAILS} from "components/src/api/query";
//utils
import {Color, CommonStyle, decryptId, encryptId, IS_WEB, Routes, Strings, ThemeUtils} from "components/src/utils";
import styles from './styles';
import buyer from 'components/src/assets/images/Buyer.jpg';
import documents from 'components/src/assets/images/Documents.png';
import infoFromSeller from 'components/src/assets/images/InfofromSeller.png';
import marketing from 'components/src/assets/images/Marketing.png';
import photo from 'components/src/assets/images/Photo.png';
import rateBids from 'components/src/assets/images/MissionCost.jpg';
import rate from 'components/src/assets/images/MissionCost.jpg';
import seller from 'components/src/assets/images/Seller.jpg';
import sellerOrder from 'components/src/assets/images/SellersOrders.png';
import statistics from 'components/src/assets/images/Statistics.png';
import statusCheckpoints from 'components/src/assets/images/Status_Checkpoints.jpg';
import styling from 'components/src/assets/images/Styling.jpg';
import views from 'components/src/assets/images/Display.jpg';
import timeline from 'components/src/assets/images/Timeline.jpg';


const DASHBOARD = [{
    name: Strings.seller,
    icon: 'seller',
    bg: seller,
    route: Routes.Seller
}, {
    name: Strings.photos,
    icon: 'photo',
    bg: photo,
    route: Routes.Photo

}, {
    name: Strings.rate,
    icon: 'rate',
    bg: rate,
    route: Routes.Rate
}, {
    name: Strings.styling,
    icon: 'styling',
    bg: styling,
    route: Routes.Styling
}, {
    name: Strings.infoFromSeller,
    icon: 'info-from-seller',
    bg: infoFromSeller,
    route: Routes.InfoFromSeller,
}, {
    name: Strings.marketing,
    icon: 'digital-sales-assignment',
    bg: marketing,
    route: Routes.Marketing
}, {
    name: Strings.views,
    icon: 'book-inspection',
    bg: views,
    route: Routes.Views
}, {
    name: Strings.bids,
    icon: 'bid-round-handled-by-broker',
    bg: rateBids,
    route: Routes.Bids
}, {
    name: Strings.statistics,
    icon: 'statistics',
    bg: statistics,
    disabled: true
}, {
    name: Strings.notification,
    icon: 'notifications_moovin',
    bg: statusCheckpoints,
    route: Routes.notifications,
}, {
    name: Strings.buyer,
    icon: 'buyer',
    bg: buyer,
    route: Routes.Buyer
}, {
    name: Strings.documents,
    icon: 'documents',
    bg: documents,
    route: Routes.Document
}, {
    name: Strings.sellerOrder,
    icon: 'my-order',
    bg: sellerOrder,
    route: Routes.SellerOrder
}, {
    name: Strings.timeline,
    icon: 'timeline',
    bg: timeline,
    route: Routes.Timeline,
}];


export default function PropertyDashboard(props) {
    const {history, navigation} = props;
    const scrollY = useRef(new Animated.Value(0));
    const propertyData = IS_WEB ? history.location?.state?.propertyData ?? {
        id: parseInt(decryptId(props.match.params.id)),
        address: props.match.params.address,
        oppdrags_nummer: decryptId(props.match.params.oppdrag),
    } : navigation.getParam('propertyData', {});
    const [noBidVisible, setNoBidVisible] = useState(false)

    const bidQuery = useQuery(BID_QUERY, {
        variables: {
            propertyId: propertyData?.id
        }
    })

    const {loading, data, error} = useQuery(MOOVIN_PROPERTY_DETAILS, {
        fetchPolicy:'network-only',
        variables: {
            id: propertyData.id,
        }
    });


    const handleItemPress = (route) => {

        if (route) {
            if (route === Routes.Bids && !bidQuery?.data?.property_bids?.bids?.length) {
                setNoBidVisible(true)
            } else {
                if (IS_WEB) {
                    switch (route) {
                        case Routes.Photo:
                            if (!data?.moovin_property_detail?.order_photo) {
                                route = Routes.OrderPhoto
                            }
                            break;
                        case Routes.Rate:
                            if (!data?.moovin_property_detail?.orderrate && !data?.moovin_property_detail?.order_rate_detail) {
                                route = Routes.OrderRate
                            }
                            break;
                        case Routes.Styling:
                            if (!data?.moovin_property_detail?.orderstyling) {
                                route = Routes.OrderStyling
                            }
                            break;

                    }
                    history.push(`/${route}/${encryptId(propertyData?.id)}`, {
                        id: propertyData ? propertyData?.id : data?.moovin_property_agent?.property_id
                    })

                } else {
                    navigation.navigate(route, {
                        id: propertyData ? propertyData?.id : data?.moovin_property_agent?.property_id
                    })
                }
            }
        }
    }


    const firstAgent = data?.moovin_property_detail?.property_agent?.find(item => item.brokerRole === 1)
    const secondAgent = data?.moovin_property_detail?.property_agent?.find(item => item.brokerRole === 2)
    return (
        <View style={CommonStyle.flex}>
            <Header
                animatedTitle={propertyData?.street_adress ? `${propertyData.street_adress} ${propertyData.city} ${propertyData.zipcode}` : propertyData?.address}
                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                navigation={IS_WEB ? history : navigation}
                headerRightSecond={'chat'}
                onPressRightSecond={() => {
                    if (IS_WEB) {
                        history.replace(`/${Routes.Chat}/${encryptId(propertyData ? propertyData?.id : data?.moovin_property_agent?.property_id)}`)
                    } else {
                        navigation.navigate(Routes.Chat, {
                            propertyId: propertyData ? propertyData?.id : data?.moovin_property_agent?.property_id
                        })
                    }
                }}
                onPressRightFirst={() => IS_WEB ? history.replace(`/${Routes.Super_User_Dashboard}`) : navigation.pop(2)}
                headerRightFirst={'dashboard'}
                animatedValue={scrollY.current}/>
            {loading ? (
                <ActivityIndicator color={Color.PRIMARY}

                                   style={styles.loader}/>
            ) : (
                <Animated.ScrollView scrollEventThrottle={1}
                                     onScroll={Animated.event(
                                         [{nativeEvent: {contentOffset: {y: scrollY.current}}}],
                                         {useNativeDriver: true})}>
                    <View style={styles.cont}>
                        {/*<DropDown closeOnSelect
                              style={styles.dropDown}
                              options={agentData}
                              selectedOption={0}
                              onSelectOptions={(item)=>{
                                  updateAgentTeam({
                                      variables: {
                                          propertyId: parseInt(propertyData.id),
                                          agentTeamID:  item.value
                                      }
                                  }).catch(() => {

                                  })
                                  setAgent(item)
                              }}
                              title={Strings.agent}/>*/}
                        <Label mt={ThemeUtils.relativeRealHeight(4)}
                               ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               font_medium>
                            {Strings.agent}
                        </Label>
                        <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                               mt={5}
                               mb={15}>
                            {/*{`${data?.moovin_property_detail?.agent?.first_name ?? ""} ${data?.moovin_property_detail?.agent?.last_name ?? ""}`}*/}
                            {firstAgent?.name}
                        </Label>

                        {secondAgent ? (
                            <>
                                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       font_medium>
                                    {"Saksbehandler 2"}
                                </Label>
                                <Label ml={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                       mt={5}
                                       mb={15}>
                                    {/*{`${data?.moovin_property_detail?.agent?.first_name ?? ""} ${data?.moovin_property_detail?.agent?.last_name ?? ""}`}*/}
                                    {secondAgent?.name}
                                </Label>
                            </>) : null}
                        <Dialog visible={noBidVisible}
                                buttonTitleFirst={Strings.cancel}
                                color={Color.PRIMARY_TEXT_COLOR}
                                onPositivePress={() => {
                                    setNoBidVisible(false)
                                }}
                                onNegativePress={() => {
                                    setNoBidVisible(false)
                                }}
                                buttonTitleSecond={Strings.okay}
                                desc={Strings.noBids}/>
                        <Dashboard dashboardData={DASHBOARD}
                                   style={styles.dashboard}
                                   onPressItem={handleItemPress}/>
                    </View>
                </Animated.ScrollView>
            )}


        </View>
    )
}
