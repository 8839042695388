import gql from 'graphql-tag';

// DS detail page Mutations

export const SIGNUP_FOR_VIEWING = gql`
  mutation signup_for_viewing($input: SignupForViewingInput!) {
    signup_for_viewing(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SALES_PDF_STATEMENT = gql`
  mutation sales_statement_pdf($input: SalesStatmentPdfInput!) {
    sales_statement_pdf(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const GET_UPDATED = gql`
  mutation get_updated($input: SalesStatmentPdfInput!) {
    get_updated(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const CONTACT_BROKER = gql`
  mutation contact_broker(
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $address: String!
    $oppdragnummer: String!
    $brokerID: Int!
  ) {
    contact_broker(
      first_name: $first_name
      last_name: $last_name
      email: $email
      telephone: $phone
      address: $address
      oppdragnummer: $oppdragnummer
      broker_id: $brokerID
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// DS Financing

export const DS_FINANCING = gql`
  mutation send_financing_email($telefon: String!, $epost: String!) {
    send_financing_email(telefon: $telefon, epost: $epost) {
      meta {
        status
        message
        code
      }
    }
  }
`;
// add Finn AD

export const ADD_FINN_AD = gql`
  mutation add_finn_no_ad($input: finnNoInput) {
    add_finn_no_ad(input: $input) {
      id
      property_id
      url
      is_approved
      approved_date
      finn_comments {
        id
        comment
        created_at
      }
    }
  }
`;

// DIGITAL SALES ASSIGNMENT

export const ADD_DS_ASSIGNMENT = gql`
  mutation add_digital_sales_assi($input: DSAssignmentInput) {
    add_digital_sales_assi(input: $input) {
      id
      property_id
      url
      is_approved
      approved_date
      digital_sales_assi_comment {
        id
        comment
      }
    }
  }
`;

// upload marketing material

export const UPLOAD_MARKET_MATERIAL = gql`
  mutation upload_market_matreial($file: [Upload!], $title: String!, $property_id: Int) {
    upload_market_matreial(matreial_file: $file, title: $title, property_id: $property_id) {
      materials {
        id
        property_id
      }
    }
  }
`;

export const REPLACE_MATERIAL = gql`
  mutation replace_market_matreial_docu($matreial_file: [Upload!], $id: ID!) {
    replace_market_matreial_docu(matreial_file: $matreial_file, id: $id) {
      materials {
        id
      }
    }
  }
`;

// delete Document
export const DELETE_DOC = gql`
  mutation delete_property_document($id: Int!) {
    delete_property_document(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// upload document

export const UPLOAD_DOC = gql`
  mutation upload_document(
    $file: [Upload!]
    $title: String!
    $property_id: Int!
    $document_for: [String]!
  ) {
    upload_document(
      document_file: $file
      title: $title
      property_id: $property_id
      document_for: $document_for
    ) {
      document {
        id
        title
        doc_url
        doc_type
        is_buyer_seller_doc
        status
        property_id
      }
    }
  }
`;

// add Buyer

export const ADD_BUYER = gql`
  mutation addBuyer($input: BuyerInput) {
    add_buyer_user_deails(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// AGENT UPDATE PROFILE

export const AGENT_UPDATE_PROFILE = gql`
  mutation update_agent(
    $id: Int!
    $office_id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $mobile: String
    $title: String!
    $education: String
    $summary: String
    $upload_cv: Upload
    $other_document: [Upload!]
    $is_in_admin_office: Boolean!
  ) {
    update_agent(
      id: $id
      office_id: $office_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      mobile: $mobile
      title: $title
      education: $education
      about: $summary
      cv: $upload_cv
      other_doc: $other_document
      is_in_admin_office: $is_in_admin_office
    ) {
      id
      title
      first_name
      last_name
      email
      address
      mobile
      access_level
      office_id
      profile_image
      city
      zipcode
      user_detail {
        id
        user_id
        education
        about
        cv_url
        status
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
    }
  }
`;

export const AGENT_ADD_AGENT_FINANCING_DETAILS = gql`
  mutation agent_add_financing_detail($input: aplicantInput) {
    agent_add_financing_detail(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const UPDATE_PROPERTY_UTLEGG = gql`
  mutation update_property_utlegg($input: updatePropertyUtlegg!) {
    update_property_utlegg(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const START_INSPECTION = gql`
  mutation start_inspection($input: StartInspectionInput!) {
    start_inspection(input: $input) {
      id
      property_id
      template_id
      start_date
      start_time
      template_type
    }
  }
`;

export const PROPERTY_SERVICES = gql`
  mutation property_services($input: SavePropertyServices!) {
    property_services(input: $input) {
      id
      property_id
      usable_service_id
      usable_service_class
      price
      quantity
    }
  }
`;

export const NEW_PROPERTY_SERVICES = gql`
  mutation property_services($input: SavePropertyServices!) {
    property_services(input: $input) {
      id
      property_id
      usable_service_id
      usable_service_class
      price
      quantity
    }
  }
`;

export const SET_PREFERENCE = gql`
  mutation sendPreference($input: SavePropertyPreference!) {
    property_preference(input: $input) {
      id
      property_id
      preferable_price
      percentage
      fix_price
      rebate_amount
    }
  }
`;

export const AGENT_ASSIGN_OPPDRAG_NO = gql`
  mutation add_oppdragnummer($id: Int!, $oppdragNumber: String!) {
    add_oppdragnummer(id: $id, oppdrags_nummer: $oppdragNumber) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const ASSIGN_UTLEGG_SERVICE = gql`
  mutation assign_utlegg_service($input: assignUtleggService!) {
    assign_utlegg_service(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const AGENT_SIGN_ASSIGNMENT = gql`
  mutation agentSignInitSession($input: signantAPIInput) {
    agentSignInitSession(input: $input) {
      initial_session {
        AuthenticationUrl
        RequestID
        TrackingID
      }
    }
  }
`;

export const SAVE_PERSONAL_TEMPLATE_SERVICE = gql`
  mutation updatePersonalTemplate(
    $type: UploadType
    $templateId: Int
    $video: Upload
    $iFrame: String
  ) {
    personal_update_office_template(
      type: $type
      office_template_id: $templateId
      video: $video
      iframeLink: $iFrame
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const PERSONAL_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES = gql`
  mutation personalUploadPhotos3dAndPhotos(
    $images: [Upload!]
    $templateId: Int
    $type: PhotoType!
  ) {
    personal_photo_3d_service_media(photos: $images, office_template_id: $templateId, type: $type) {
      id
      media_url
    }
  }
`;

export const PERSONAL_TEMPLATE_STYLING_UPLOAD_IMAGES = gql`
  mutation personalStylingUploadImages($oldImage: Upload!, $newImage: Upload!, $templateId: Int) {
    personal_styling_service_media(
      old_photo: $oldImage
      new_photo: $newImage
      office_template_id: $templateId
    ) {
      id
      office_template_id
    }
  }
`;
export const DELETE_PERSONAL_SERVICE_MEDIA = gql`
  mutation deleteServiceMedia($ids: [Int!]!, $templateId: Int) {
    personal_delete_service_media(office_template_id: $templateId, ids: $ids) {
      id
      office_template_id
      media_url
    }
  }
`;
export const PERSONAL_TEMPLATE_PHOTO_360_ATTACH_LINK = gql`
  mutation attach360Link($url: String!, $templateId: Int) {
    personal_view360_service_media(url: $url, office_template_id: $templateId) {
      id
      office_template_id
    }
  }
`;
export const PERSONAL_REPLACE_3D_PHOTO_IMAGE = gql`
  mutation personalReplaceImage($id: Int!, $templateId: Int, $photo: Upload!, $type: PhotoType) {
    personal_replace_photo_3d_media(
      id: $id
      office_template_id: $templateId
      photo: $photo
      type: $type
    ) {
      id
      office_template_id
    }
  }
`;
export const PERSONAL_REPLACE_STYLING_IMAGE = gql`
  mutation personalReplaceStyleImage(
    $id: Int!
    $templateId: Int
    $photo: Upload!
    $oldPhoto: Upload!
  ) {
    personal_replace_styling_photo_url(
      id: $id
      office_template_id: $templateId
      old_photo: $oldPhoto
      new_photo: $photo
    ) {
      id
      office_template_id
    }
  }
`;
export const UPDATE_AGENT_BEFARING = gql`
  mutation updateAgentProductGallery($desc: String!) {
    personal_befaring_content(description: $desc) {
      id
      agent_id
    }
  }
`;
export const UPLOAD_AGENT_BEFARING_DATA = gql`
  mutation uploadAgentBefaringMedia($headline: String!, $images: [Upload!]) {
    personal_befaring_product_media(headline: $headline, photos: $images) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const UPLOAD_PERSONAL_AFTER_BEFARING_DATA = gql`
  mutation personal_after_befaring_product_media($headline: String!, $images: [Upload!]) {
    personal_after_befaring_product_media(headline: $headline, photos: $images) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const REPLACE_AGENT_BEFARING_MEDIA = gql`
  mutation replaceAgentBefaringMedia($id: Int!, $galleryId: Int!, $photo: Upload!) {
    replace_befaring_product_media(id: $id, agent_product_gallery_id: $galleryId, photo: $photo) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const REPLACE_AGENT_AFTER_BEFARING_MEDIA = gql`
  mutation replaceAgentAfterBefaringMedia($id: Int!, $galleryId: Int!, $photo: Upload!) {
    replace_after_befaring_product_media(id: $id, agent_product_gallery_id: $galleryId, photo: $photo) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const DELETE_BEFARING_MEDIA = gql`
  mutation delteBefringMedia($ids: [Int!]!, $galleryId: Int!) {
    personal_delete_befering_media(ids: $ids, agent_product_gallery_id: $galleryId) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const DELETE_AFTER_BEFARING_MEDIA = gql`
  mutation delteAfterBefringMedia($ids: [Int!]!, $galleryId: Int!) {
    personal_delete_after_befering_media(ids: $ids, agent_product_gallery_id: $galleryId) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SEND_INSPECTION_OFFER = gql`
  mutation send_inspection_offer(
    $property_id: Int!
    $headline: String
    $description: String
    $start_date: Date!
    $start_time: String!
    $is_confirmation_email: Boolean!
  ) {
    send_inspection_offer(
      property_id: $property_id
      headline: $headline
      description: $description
      start_date: $start_date
      start_time: $start_time
      is_confirmation_email: $is_confirmation_email
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const STORE_QUEATIONS = gql`
  mutation storeQuestion($input: selectedQuestions) {
    submit_agreement_question(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const OWNERSIGN_AGREEMENT = gql`
  mutation ownerSignAgreement($input: ownerSignInput) {
    owner_sign_agreement(input: $input) {
      ownerAgreement_sign
    }
  }
`;

export const SEND_CO_OWNER_EMAIL = gql`
  mutation agreement_submit($input: sellerSignupInput) {
    agreement_submit(input: $input) {
      meta {
        status
        message
        code
      }
    }
  }
`;
// notifications

export const AGENT_CUSTOM_PUSH_NOTIFICATION = gql`
  mutation custome_push_notification(
    $property_id: Int!
    $notification_desc: String!
    $send_to: [String]!
  ) {
    custome_push_notification(
      property_id: $property_id
      notification_desc: $notification_desc
      send_to: $send_to
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const RESEND_ORDER_NOTIFICATION = gql`
  mutation resend_order_notification($property_id: Int!, $notify_type: String!) {
    resend_order_notification(property_id: $property_id, notify_type: $notify_type) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const RESEND_MARKETING_NOTIFICATION = gql`
  mutation resend_market_and_contract_notify($property_id: Int!, $notify_type: String!) {
    resend_market_and_contract_notify(property_id: $property_id, notify_type: $notify_type) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const NOTIFICATION_BY_AGENT = gql`
  mutation notification_by_agent($property_id: Int!, $notify_type: String!) {
    notification_by_agent(property_id: $property_id, notify_type: $notify_type) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// prod
// export const BOOK_CALENDER_DATE = gql`
//   mutation calendar_book_date($date: Date!, $propertyId: Int!, $timelineId: Int!) {
//     calendar_book_date(property_id: $propertyId, timeline_id: $timelineId, book_date: $date) {
//       meta {
//         status
//         message
//         code
//       }
//     }
//   }
// `;

// dev
export const BOOK_CALENDER_DATE = gql`
  mutation calendar_book_date(
    $date: Date!
    $propertyId: Int!
    $timelineId: Int!
    $book_start_time: String
    $book_end_time: String
    $styling_type_id: Int
  ) {
    calendar_book_date(
      property_id: $propertyId
      timeline_id: $timelineId
      book_date: $date
      book_start_time: $book_start_time
      book_end_time: $book_end_time
      styling_type_id: $styling_type_id
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const DELETE_CALENDAR_BOOK_DATE = gql`
  mutation delete_calender_book_date($propertyID: Int!, $timelineID: Int!) {
    delete_calender_book_date(property_id: $propertyID, timeline_id: $timelineID) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// Photo Mutations

export const ATTACH_VIDEO_LINK = gql`
  mutation attach_video_link($input: AttachLinkInput!) {
    attach_video_link(input: $input) {
      attach_video {
        id
        property_id
        video_url
      }
    }
  }
`;
export const ATTACH_360_URL = gql`
  mutation attach_360_view_link($input: AttachLinkInput!) {
    attach_360_view_link(input: $input) {
      attach_video {
        id
        order_photo_id
        view_360_url
      }
    }
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation upload_photo($photo: [Upload!], $photoOrderId: Int) {
    upload_photo(photo: $photo, photo_order_id: $photoOrderId) {
      photos {
        id
        order_photo_id
      }
    }
  }
`;

export const DELETE_PHOTO = gql`
  mutation deletePhoto($ids: [Int!]!) {
    delete_photo(ids: $ids) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const ADD_FEEDBACK = gql`
  mutation addFeedback($input: FeedbackInput!) {
    add_agent_feedback(input: $input) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const EDIT_FEEDBACK = gql`
  mutation editFeedBack(
    $id: Int!
    $agent_id: Int!
    $rating: Decimal!
    $given_by: String!
    $feedback: String!
  ) {
    edit_agent_feedback(
      id: $id
      agent_id: $agent_id
      rating: $rating
      given_by: $given_by
      feedback: $feedback
    ) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const DELETE_FEEDBACK = gql`
  mutation deleteFeedBack($id: Int!, $user_id: Int!) {
    delete_agent_feedback(id: $id, agent_id: $user_id) {
      id
      user_id
      rating
      feedback
      given_by
    }
  }
`;

export const DELETE_AGENT_DOCUMENT = gql`
  mutation delete_document($agentId: Int!, $userDocumentId: Int!) {
    delete_document(agent_id: $agentId, user_document_id: $userDocumentId) {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
      user_type_id
      office_id
      title
      user_detail {
        id
        user_id
        education
        about
        cv_url
        status
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      feedback {
        id
        user_id
        rating
        feedback
        given_by
      }
    }
  }
`;

export const DELETE_AGENT_CV = gql`
  mutation delete_cv($agentId: Int!) {
    delete_cv(agent_id: $agentId) {
      id
      first_name
      last_name
      email
      address
      mobile
      profile_image
      city
      zipcode
      status
      access_level
      user_type_id
      office_id
      title
      user_detail {
        id
        user_id
        education
        about
        cv_url
        status
      }
      user_media {
        id
        user_id
        title
        redirect_url
        media_url
        status
      }
      user_document {
        id
        user_id
        doc_url
        doc_type
      }
      feedback {
        id
        user_id
        rating
        feedback
        given_by
      }
    }
  }
`;

export const AGENT_DELETE_AGENT_MEDIA = gql`
  mutation delete_agent_media($agent_id: Int!, $media_id: Int!) {
    delete_agent_media(agent_id: $agent_id, media_id: $media_id) {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
  }
`;

export const AGENT_ADD_AGENT_MEDIA = gql`
  mutation agent_add_media(
    $agent_id: Int!
    $title: String!
    $redirection_url: String!
    $media_image: Upload!
  ) {
    agent_add_media(
      agent_id: $agent_id
      title: $title
      redirection_url: $redirection_url
      media_image: $media_image
    ) {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
  }
`;

export const AGENT_EDIT_AGENT_MEDIA = gql`
  mutation agent_update_media(
    $id: Int!
    $agent_id: Int!
    $title: String!
    $redirection_url: String!
    $media_image: Upload
  ) {
    agent_update_media(
      id: $id
      agent_id: $agent_id
      title: $title
      redirection_url: $redirection_url
      media_image: $media_image
    ) {
      id
      user_id
      title
      redirect_url
      media_url
      status
    }
  }
`;

export const SEND_OFFER = gql`
  mutation send_inspection_offer(
    $property_id: Int!
    $headline: String
    $description: String
    $start_date: Date!
    $start_time: String!
    $is_confirmation_email: Boolean!
  ) {
    send_inspection_offer(
      property_id: $property_id
      headline: $headline
      description: $description
      start_date: $start_date
      start_time: $start_time
      is_confirmation_email: $is_confirmation_email
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const AGENT_ADD_CALENDAR_EVENT = gql`
  mutation agentAddEvent(
    $title: String!
    $property_id: Int!
    $user_type_seller: Boolean
    $user_type_buyer: Boolean
    $event_date: DateTime!
  ) {
    agent_add_calendar_event(
      title: $title
      property_id: $property_id
      user_type_seller: $user_type_seller
      user_type_buyer: $user_type_buyer
      event_date: $event_date
    ) {
      id
    }
  }
`;

export const AGENT_UPDATE_CALENDAR_EVENT = gql`
  mutation agentUpdateEvent(
    $id: Int!
    $title: String!
    $property_id: Int!
    $user_type_seller: Boolean
    $user_type_buyer: Boolean
    $event_date: DateTime!
  ) {
    agent_update_calendar_event(
      id: $id
      title: $title
      property_id: $property_id
      user_type_seller: $user_type_seller
      user_type_buyer: $user_type_buyer
      event_date: $event_date
    ) {
      id
    }
  }
`;

export const AGENT_DELETE_CALENDAR_EVENT = gql`
  mutation deleteAgentCalendarEvent($id: Int!) {
    agent_delete_calendar_event(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SAVE_PROPERTY_UTLEGG = gql`
  mutation save_proeprty_utlagg(
    $property_utlagg_service_id: Int!
    $property_id: Int!
    $is_delete_action: Boolean!
  ) {
    save_proeprty_utlagg(
      property_utlagg_service_id: $property_utlagg_service_id
      property_id: $property_id
      is_delete_action: $is_delete_action
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;

// import DS

export const IMPORT_DS = gql`
  mutation import_ds($oppdragsnummer: String!) {
    import_ds(oppdragsnummer: $oppdragsnummer) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const SAVE_BEFARING_NOTES = gql`
  mutation saveBefaringNotes($inspectionId: Int!, $image: Upload!) {
    save_befaring_note(property_inspection_id: $inspectionId, image: $image) {
      id
      property_inspection_id
      image
    }
  }
`;

export const UPDATE_BUYER_TIMELINE = gql`
  mutation updateBuyerPropertyTimeline($propertyId: ID!, $status: Int!) {
    update_buyer_timeline(property_id: $propertyId, status: $status) {
      success
    }
  }
`;

export const UPDATE_PROPERTY_TIME_LINE = gql`
  mutation updatePropertyTimeline($propertyId: ID!, $status: Int!) {
    update_property_timeline(property_id: $propertyId, status: $status) {
      success
    }
  }
`;
