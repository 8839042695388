import React, {useEffect, useState} from 'react'
//Components
import {View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {CommonStyle} from "components/src/utils";
import {connect} from "react-redux";


const QUESTIONS = [{
    id: 1,
    name: "Vi har kjøpt ny bolig og skal nå selge"
}, {
    id: 2,
    name: "Vi skal selge først og deretter kjøpe noe nytt"
}];

const OPTIONS_1 = [
    {name: 'Gratulerer! Hvordan foregikk den prosessen?', value: 1},
    {name: 'Var dere lenge på jakt før dere fant «drømmeboligen»?', value: 2},
];
const OPTIONS_2 = [
    {
        name: 'Fordelen med det er jo at dere da vet nøyaktig hva dere har i budsjett, men ' +
            'hva gjør dere om dere må overlevere boligen deres før dere har funnet en ny?', value: 3
    },
    {name: 'Har dere vært på (mange) visninger?', value: 4},
];

function FirstQuestion(props) {
    const {onChangeAnswer, fromStart, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();

    useEffect(() => {
        setAnswerFirst(fromStart === 1 ? 1 : 4)
    }, [fromStart])

    useEffect(() => {
        props.onAnswer(answers)
    }, [answers]);

    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {fromStart === 1 ? QUESTIONS[0].name : QUESTIONS[1].name}
            </Label>

            <RadioGroup options={fromStart === 1 ? OPTIONS_1 : OPTIONS_2}
                        style={{marginVertical: 10}}
                        textStyle={CommonStyle.flex}
                        labelProps={{
                            large: true
                        }}
                        onSelectOption={setAnswerFirst}/>

        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(FirstQuestion)
