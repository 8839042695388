import React, {useEffect, useState} from 'react'
//Components
import {TouchableOpacity, View} from 'react-native';
import {Label, RadioGroup} from "components/src/components";
//Utils
import {Color, CommonStyle, Icon, ThemeUtils} from "components/src/utils";
import {connect} from "react-redux";
import styles from "../../../../../../../../components/ui/RadioGroup/styles";


const OPTIONS_1 = [
    {name: 'Hva var bra?', value: 0},
    {name: 'Hva var mindre bra?', value: 1},
];
const OPTIONS_2 = [
    {name: 'Så alle gjorde alt helt likt, eller var det noen forskjeller?', value: 1},
];

const DET_VAR = [
    {name: 'Hva kan konsekvensen være for selger at megler fremstod på den måten?', value: 0},
    {name: 'Hva hadde deres synes om megleren dere velger hadde gjort det samme ved salg av deres bolig?', value: 1},
    {
        name: 'Hvordan skal dere sikre dere mot at megleren dere velger ikke gjør det samme ved salg av deres bolig?',
        value: 2
    },
];

function SecondQuestion(props) {
    const {onChangeAnswer,fromPrev, setForm, interViewForm} = props;

    const [answers, setAnswerFirst] = useState();
    const [answersSec, setAnswer] = useState();
    const [detVar, setDetVar] = useState();

    useEffect(() => {
        props.onAnswer(answersSec)
    }, [answersSec])

    return (
        <View>
            <Label xxlarge
                   font_medium
                   mt={20}>
                {"Gjorde alle en like god jobb eller var det noe som var mindre bra?"}
            </Label>
            <TouchableOpacity activeOpacity={1}
                              style={{marginVertical: 10}}
                              onPress={() => setAnswerFirst('Yes')}>
                <View style={[styles.container]}>
                    <Icon name={answers === 'Yes' ? 'radio-select' : 'radio-normal'}
                          size={ThemeUtils.fontNormal}
                          color={answers === 'Yes' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                    <Label large
                           style={[styles.rightText]}>
                        {'Alle var like gode'}
                    </Label>
                </View>
            </TouchableOpacity>

            {answers === 'Yes' ? (<RadioGroup options={OPTIONS_2}
                                              style={{
                                                  marginVertical: 10,
                                                  marginStart: ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)
                                              }}
                                              textStyle={CommonStyle.flex}
                                              labelProps={{
                                                  large: true
                                              }}
                                              onSelectOption={setAnswer}/>) : null}

            <TouchableOpacity activeOpacity={1}
                              style={{marginVertical: 10}}
                              onPress={() => setAnswerFirst('No')}>
                <View style={[styles.container]}>
                    <Icon name={answers === 'No' ? 'radio-select' : 'radio-normal'}
                          size={ThemeUtils.fontNormal}
                          color={answers === 'No' ? Color.SECONDARY : Color.PRIMARY_LIGHT}/>
                    <Label large
                           style={[styles.rightText]}>
                        {'Det var helt klart forskjeller'}
                    </Label>
                </View>
            </TouchableOpacity>

            {answers === 'No' ? (<RadioGroup options={OPTIONS_1}
                                             selectedOptions={detVar}
                                             style={{
                                                 marginVertical: 10,
                                                 marginStart: ThemeUtils.isTablet() ? 40 : ThemeUtils.relativeWidth(10)
                                             }}
                                             textStyle={CommonStyle.flex}
                                             labelProps={{
                                                 large: true
                                             }}
                                             onSelectOption={setDetVar}/>) : null}

            {answers==='No' && detVar ? (<RadioGroup options={DET_VAR}
                                   style={{
                                       marginVertical: 10,
                                       marginStart: ThemeUtils.isTablet() ? 80 : ThemeUtils.relativeWidth(20)
                                   }}
                                   textStyle={CommonStyle.flex}
                                   labelProps={{
                                       large: true
                                   }}
                                   onSelectOption={() => {
                                   }}/>) : null}
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        interViewForm: state.seller_interview
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}

};
export default connect(mapStateToProps, mapDispatchToProps)(SecondQuestion)
