import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: Color.SECONDARY_BG_COLOR
    },

    topContainer: {
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
        alignContent: 'center'
    },

    fieldContainer: {
        alignContent: 'center',
    },
    labeliconContainer: {
        marginTop: ThemeUtils.relativeRealHeight(1.5),
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    SettlementRadio: {marginRight: 25, marginBottom: 15},

    checkbox: {flexDirection: 'row', alignItems: 'center', marginBottom: ThemeUtils.relativeRealHeight(2)},
    energyradio: {marginTop: 10}

});
export {styles};
