import { StyleSheet } from 'react-native';
import { ThemeUtils, Color } from "components/src/utils";

export default StyleSheet.create({
    
    contentCont: {
        flex: 1,
        marginHorizontal: ThemeUtils.COMMON_MARGIN_HORIZONTAL,
    },
    subView:{marginTop:20}
   

})
