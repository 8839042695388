import React, {useEffect, useState} from 'react'

import {ActivityIndicator, Animated, SafeAreaView, TouchableOpacity, View} from 'react-native'
import {
    Agenda,
    CheckBox,
    CustomButton,
    DatePicker,
    Dialog,
    DropDown,
    Header,
    Hr,
    InputField,
    Label,
    Modal,
    Ripple,
    Toast
} from "components/src/components";

import {connect} from "react-redux";

import {
    Color,
    CommonStyle, Constants,
    DateUtils,
    IS_WEB,
    MessageUtils,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";

import styles from './styles'
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AGENT_CALENDAR_EVENT, AGENT_CALENDAR_PROPERTIES} from "../../../api/query";
import {AGENT_ADD_CALENDAR_EVENT, AGENT_UPDATE_CALENDAR_EVENT, DELETE_CALENDAR_EVENT} from "../../../api/mutation";
import moment from "moment";
import enUS from "@ant-design/react-native/lib/locale-provider/en_US";
import Provider from "@ant-design/react-native/lib/provider";


const USER_TYPES = [{
    name: Strings.seller,
    value: "Seller"
}, {
    name: Strings.buyer,
    value: "Buyer"
}]

function CalendarScreen(props) {
    const {history, navigation, database} = props;

    const scrollY = new Animated.Value(0)


    const [toast, setToast] = useState()
    const [addVisible, setAddVisible] = useState(false)
    const [eventForEdit, setEventForEdit] = useState(null)
    const [eventName, setEventName] = useState("");
    const [selectedProperty, setProperty] = useState();
    const [userType, setUserType] = useState([]);
    const [date, setDate] = useState();
    const [time, setTime] = useState();
    const [deleteVisible, setDeleteVisible] = useState(false);

    const {loading, data, error} = useQuery(AGENT_CALENDAR_EVENT);

    const agentPropertyList = useQuery(AGENT_CALENDAR_PROPERTIES);

    const [deleteEvent, deleteEventMutation] = useMutation(DELETE_CALENDAR_EVENT, {
        refetchQueries: [{
            query: AGENT_CALENDAR_EVENT,
        }]
    })

    const [addEventCalendar, addEventMutation] = useMutation(AGENT_ADD_CALENDAR_EVENT, {
        refetchQueries: [{
            query: AGENT_CALENDAR_EVENT,
        }]
    })

    const [updateEventCalendar, updateEventMutation] = useMutation(AGENT_UPDATE_CALENDAR_EVENT, {
        refetchQueries: [{
            query: AGENT_CALENDAR_EVENT,
        }]
    })

    useEffect(() => {
        if (eventForEdit) {
            setEventName(eventForEdit?.title);
            setUserType(eventForEdit?.user_type.split(','));
            setProperty(eventForEdit?.property_id)
            setDate(moment(eventForEdit?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.yyyy_mm_dd));
            setTime(moment(eventForEdit?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.hh_mm_ss));
            //setTime(eventForEdit?.end_datetime);
            setAddVisible(true)
        }
    }, [eventForEdit])


    useEffect(() => {
        if (!addVisible) {
            setEventName("");
            setUserType([]);
            setEventForEdit(null)
        }
    }, [addVisible])


    const renderEvent = (item) => {

        return (
            <View style={styles.eventCont}>
                {item?.data?.map(event => (
                    <TouchableOpacity activeOpacity={0.5}
                                      onPress={() => setEventForEdit(event)}
                                      style={styles.lblCont}>
                        <Label small
                               color={Color.WHITE}>
                            {event?.title}
                        </Label>
                    </TouchableOpacity>
                ))}
                <TouchableOpacity style={[styles.lblCont, styles.addNewLblCont]}
                                  onPress={() => {
                                      setDate(moment(item?.data[0]?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.yyyy_mm_dd));
                                      setTime(null)
                                      setAddVisible(true)
                                  }}
                                  activeOpacity={0.5}>
                    <Label small>
                        {`+ ${Strings.addNewEvent}`}
                    </Label>
                </TouchableOpacity>
                <Hr/>
            </View>
        )
    };

    const renderEmptyData = () => {
        return (
            <Ripple style={styles.emptyDataCont}
                    onPress={() => setAddVisible(true)}>
                <Label small
                       color={Color.DARK_GRAY}>
                    {`+ ${Strings.clickToAddEvent}`}
                </Label>
            </Ripple>
        )
    };

    const addEvent = (doChange) => {
        console.log(selectedProperty)
        if (eventName) {
            if (selectedProperty) {
                if (userType.length) {
                    if (date) {
                        if (time) {
                            let variables = {
                                title: eventName,
                                propertyId: selectedProperty,
                                isBuyer: userType.includes("Buyer"),
                                isSeller: userType.includes("Seller"),
                                date: `${date} ${time}`,
                            }
                            if (eventForEdit) {
                                variables.id = eventForEdit?.id
                            }
                            doChange({
                                variables: variables
                            }).then(() => {
                                setAddVisible(false)
                            }).catch(() => {
                            })

                        } else {
                            showMessage(MessageUtils.Errors.time, Constants.MessageType.FAILED)
                        }
                    } else {
                        showMessage(MessageUtils.Errors.orderDate, Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.userType, Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.property, Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.nameBlank, Constants.MessageType.FAILED)
        }
    };

    const renderAddEventModal = () => {
        return (
            <Modal visible={addVisible}
                   animated
                   hardwareAccelerated
                   onRequestClose={() => setAddVisible(false)}>
                <SafeAreaView style={CommonStyle.safeArea}>
                    <Toast ref={r => setToast(r)}/>
                    <View style={CommonStyle.container}>
                        <Dialog title={Strings.deleteEvent}
                                visible={deleteVisible}
                                loading={deleteEventMutation.loading}
                                onPositivePress={() => {
                                    deleteEvent({
                                        variables: {
                                            id: eventForEdit?.id
                                        }
                                    }).then((data) => {
                                        setDeleteVisible(false)
                                        setAddVisible(false)
                                        showMessage(data?.data?.agent_delete_calendar_event?.meta?.message, Constants.MessageType.SUCCESS)
                                    })
                                }}
                                onNegativePress={() => setDeleteVisible(false)}
                                desc={Strings.deleteEventDesc}
                        />
                        <Header onPressLeft={() => setAddVisible(false)}
                                animatedValue={scrollY}
                                headerRightFirst={eventForEdit ? 'delete' : null}
                                onPressRightFirst={() => setDeleteVisible(true)}

                                initialMarginLeft={ThemeUtils.COMMON_HORIZONTAL_MARGIN}
                                animatedTitle={eventForEdit ? Strings.editEvent : Strings.addEvent}/>
                        <Animated.ScrollView scrollEventThrottle={1}
                                             contentContainerStyle={{paddingBottom: 40}}
                                             onScroll={Animated.event(
                                                 [{nativeEvent: {contentOffset: {y: scrollY}}}],
                                                 {useNativeDriver: true})}>
                            <View style={styles.modalCont}>
                                <InputField labelText={Strings.eventTitle}
                                            type={'text'}
                                            errorEnabled={false}
                                            value={eventName}
                                            onChange={setEventName}
                                            placeholder={Strings.enterEventTitle}/>
                                {agentPropertyList?.loading ? (
                                    <ActivityIndicator color={Color.PRIMARY}/>
                                ) : (
                                    <DropDown title={Strings.property}
                                              options={agentPropertyList?.data?.agent_proeprties?.map(item => ({
                                                  name: `${item.street_adress} ${item.city} ${item?.zipcode}`,
                                                  value: item?.id
                                              }))}
                                              onSelectOptions={setProperty}
                                              selectedOption={selectedProperty}
                                              placeholder={Strings.selectProperty}/>
                                )}

                                <Label small
                                       mt={20}
                                       mb={10}
                                       font_medium>
                                    {Strings.userType}
                                </Label>
                                <CheckBox onCheckChanged={setUserType}
                                          style={styles.chk}
                                          defaultChecked={userType}
                                          options={USER_TYPES}/>

                                <DatePicker title={Strings.date}
                                            placeholder={Strings.selectDate}
                                            min={new Date(moment().add(1, 'd'))}
                                            selectedDate={date ? date : null}
                                            onSelectDate={(date) => setDate(moment(date).format(DateUtils.yyyy_mm_dd))}/>


                                <DatePicker title={Strings.time}
                                            onSelectDate={(date) => {
                                                console.log(date)
                                                setTime(moment(date).format(DateUtils.hh_mm_ss))
                                            }}
                                            placeholder={Strings.selectTime}
                                            selectedDate={time ? time : time}
                                            mode={'time'}/>

                                <View style={styles.btnCont}>
                                    <CustomButton title={eventForEdit ? Strings.saveButton : Strings.add}
                                                  mr={10}
                                                  loading={updateEventMutation.loading || addEventMutation.loading}
                                                  textColor={Color.WHITE}
                                                  onPress={() => addEvent(eventForEdit ? updateEventCalendar : addEventCalendar)}
                                                  style={{flex: 1}}/>
                                    <CustomButton title={Strings.cancel}
                                                  ml={10}
                                                  onPress={() => setAddVisible(false)}
                                                  bgColor={Color.WHITE}
                                                  borderWidth={1}
                                                  textColor={Color.PRIMARY_TEXT_COLOR}
                                                  borderColor={Color.DARK_GRAY}
                                                  style={{flex: 1}}/>

                                </View>
                            </View>
                        </Animated.ScrollView>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    let eventData = {}
    if (data?.agent_calendar_event && data?.agent_calendar_event.length) {
        /*EXAMPLE DATA*/
        /*{
                        '2012-05-22': [{name: ['item 1 - any js object']}],
                        '2012-05-21': [],
                        '2012-05-23': [{name: new Array(5).fill({name: 1})}],
                        '2012-05-25': [{name: new Array(2).fill({name: 1})}]
                    }*/
        data?.agent_calendar_event?.map(item => {
            let date = moment(item?.start_datetime, DateUtils.yyyy_mm_dd_hh_mm_ss).format(DateUtils.yyyy_mm_dd)
            if (eventData.hasOwnProperty(date)) {
                eventData[date][0]?.data?.push(item)
            } else {
                eventData[date] = [{data: [item]}]
            }
        })
    }
    return (

        <Provider locale={enUS}>
            <View style={CommonStyle.flex}>
                <Header navigation={IS_WEB ? history : navigation}
                        style={CommonStyle.blankHeader}/>
                {renderAddEventModal()}

                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : (
                    <Agenda onDayPress={(day) => {
                        setDate(day?.dateString)
                        setTime(null)
                    }}
                            items={eventData}
                            minDate={moment().add(1, 'd').format(DateUtils.yyyy_mm_dd)}
                            renderEmptyData={renderEmptyData}
                            renderItem={renderEvent}
                            selected={Object.keys(eventData)?.sort()[0] ?? moment().format(DateUtils.yyyy_mm_dd)}/>
                )}

            </View>
        </Provider>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        property: state.property
    }
}
export default connect(mapStateToProps)(CalendarScreen)
