import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },

    lblCont: {
        marginVertical:10,
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    imgCont:{
        marginVertical:10,
        // flexDirection:'row',
        height:ThemeUtils.relativeRealHeight(30)
    },
    serviceCont: {
        borderWidth: 1,
        borderRadius: 1,
        borderColor: Color.SECONDARY,
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10
    },
    imgCountCont:{
        zIndex:100,
        position:'absolute',
        end:10,
        bottom:10,
        paddingHorizontal:5,
        paddingVertical:3,
        borderRadius:3,
        backgroundColor:Color.TRANSPARENT_BLACK
    },
    icCont: {
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems:'center',
        alignSelf:'center',
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: 10,
        backgroundColor:Color.TRANSPARENT_BLACK,
        marginHorizontal:20,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge
    },
    icContNext: {
        backgroundColor:Color.TRANSPARENT_BLACK,
        borderRadius:ThemeUtils.relativeRealWidth(3),
        padding: 10,
        marginHorizontal: 20,
        zIndex: 100,
        alignSelf:'center',
        position: 'absolute',
        end: 0,
        height:ThemeUtils.fontXXLarge,
        width:ThemeUtils.fontXXLarge,
        justifyContent: 'center',
        alignItems:'center'
    }
})
