import React, {useEffect, useState} from 'react';
//Components
import {ActivityIndicator, Animated, Image, SafeAreaView, TouchableOpacity, View} from 'react-native'
import {Dialog, FilePicker, FileType, Hr, Label, Modal} from "components/src/components";
//Utils
import styles from './styles';

import {
    Color,
    CommonStyle,
    Constants,
    decryptId,
    Icon,
    IS_WEB,
    Routes,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {connect} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {PERSONAL_TEMPLATE_DETAILS} from "../../../../../../../api/query";
import {
    DELETE_PERSONAL_SERVICE_MEDIA,
    PERSONAL_REPLACE_3D_PHOTO_IMAGE,
    PERSONAL_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES
} from "../../../../../../../api/mutation";
import PhotoDetails from "../../../../../../../components/view/PhotoDetails";

const scrollY = new Animated.Value(0);

function OfficePhoto(props) {

    const {history, user, navigation} = props;

    const serviceFromPage = IS_WEB ? history.location?.state?.service ?? null : navigation.getParam('service', null);
    if (!serviceFromPage) {
        IS_WEB ? history.replace(Routes.PhotoStyling) : navigation.goBack();
    }
    const [service, setService] = useState([]);

    const [headLines, setHeadLines] = useState("");
    const [errHeadline, setErrHeadLines] = useState(null);

    const [introText, setIntroText] = useState("");
    const [errIntroText, setErrIntro] = useState(null);

    const [selectionEnable, setSelectionEnable] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const [servicePrice, setServicePrice] = useState([]);
    const [errServicePrice, setErrServicePrice] = useState({});

    const [isPhotoDetailVisible, setPhotoDetailVisible] = useState(false);

    const [deleteVisible, setDeleteVisible] = useState(false)

    const [imageForPreview, setImageForPreview] = useState({})


    const [replaceImage, replaceImageMutation] = useMutation(PERSONAL_REPLACE_3D_PHOTO_IMAGE, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [uploadImages, uploadImagesMutation] = useMutation(PERSONAL_TEMPLATE_PHOTO_3D_UPLOAD_IMAGES, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const [deleteImages, deleteImagesMutation] = useMutation(DELETE_PERSONAL_SERVICE_MEDIA, {
        refetchQueries: [{
            query: PERSONAL_TEMPLATE_DETAILS,
            variables: {
                id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                status: 1,
                agentId: user?.id
            }
        }]
    });

    const {loading, data, error} = useQuery(PERSONAL_TEMPLATE_DETAILS, {
        variables: {
            id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
            status: 1,
            agentId: user?.id
        }
    });

    useEffect(() => {
        setSelectionEnable(!!selectedImages.length)
    }, [selectedImages]);


    useEffect(() => {
        if (data?.personal_template_details) {
            setService(data?.personal_template_details)
        }
    }, [data])

    const addOrRemoveImage = (id) => {
        let updatedImages = selectedImages.slice();
        if (updatedImages.includes(id)) {
            updatedImages.splice(updatedImages.indexOf(id), 1)
        } else {
            updatedImages.push(id)
        }
        setSelectedImages(updatedImages)
    };


    const renderPhotoGallery = () => {
        return (
            <>
                <Label small font_medium mt={10} mb={15}>
                    {Strings.uploadPhoto}
                </Label>

                <FilePicker multiple
                            style={{alignSelf: 'flex-start'}}
                            type={FileType.IMAGE}
                            onFilePicked={(data, files) => {
                                if (files.length) {
                                    uploadImages({
                                        variables: {
                                            templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                                            images: files,
                                            type: serviceFromPage.id === 9 ? Constants.UploadMediaType.FINN : Constants.UploadMediaType.DS
                                        }
                                    }).catch(() => {

                                    })
                                }
                            }}>
                    <View style={[styles.SubViewIcon, {backgroundColor: Color.GRAY}]}>
                        {uploadImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (<Icon name={'add'}
                                   size={ThemeUtils.responsiveScale(60)}
                                   color={Color.PRIMARY_LIGHT}/>)}

                    </View>
                </FilePicker>
                <Hr lineStyle={{marginTop: 20}}/>
                {selectionEnable ? (
                    <View style={styles.propertySelectionCont}>

                        <Icon name={'tickmark'}
                              size={ThemeUtils.fontNormal}
                              color={Color.PRIMARY}/>
                        <Label small
                               font_medium
                               ml={15}
                               style={CommonStyle.flex}>
                            {`${selectedImages.length} ${Strings.photoselected}`}
                        </Label>
                        <Dialog title={Strings.deletePhoto}
                                visible={deleteVisible}
                                onNegativePress={() => setDeleteVisible(false)}
                                onPositivePress={() => {
                                    deleteImages({
                                        variables: {
                                            ids: selectedImages,
                                            templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                        }
                                    }).then(() => {
                                        setSelectedImages([])
                                    }).catch(() => {

                                    })
                                    setDeleteVisible(false)
                                }}
                                desc={Strings.deleteConfirmation}/>
                        {deleteImagesMutation.loading ? (
                            <ActivityIndicator color={Color.PRIMARY}/>
                        ) : (
                            <TouchableOpacity activeOpacity={0.6}
                                              onPress={() => {
                                                  setDeleteVisible(true)
                                              }}>
                                <Icon name={'delete'}
                                      size={ThemeUtils.fontNormal}
                                      color={Color.RED}/>
                            </TouchableOpacity>)}
                        <TouchableOpacity onPress={() => {
                            setSelectedImages([])
                        }} activeOpacity={0.6} style={{marginLeft: 20}}>
                            <Icon name={'close'}
                                  size={ThemeUtils.fontNormal}/>
                        </TouchableOpacity>
                    </View>
                ) : (<Label small
                            font_medium
                            mt={15}
                            mb={5}>
                    {Strings.Photos}
                </Label>)}
                {loading && !data ? (
                    <ActivityIndicator color={Color.PRIMARY}
                                       style={CommonStyle.loader}/>
                ) : renderImageGrid(service ?? [])}
            </>
        );
    };

    const renderImageGrid = (data, isDeletable) => {
        return <View style={styles.ImageGridView}>
            {data.map(item => {
                return (
                    <TouchableOpacity
                        key={item.id}
                        onLongPress={() => selectionEnable ? null : addOrRemoveImage(item.id)}
                        onPress={() => {
                            if (selectionEnable) {
                                addOrRemoveImage(item.id)
                            } else {
                                setImageForPreview(item)
                                setPhotoDetailVisible(true)
                            }
                        }}
                        activeOpacity={0.7}>

                        {selectionEnable && selectedImages.includes(item.id) ? (<View style={[styles.selectedImage]}>
                            <Icon name={'tickmark'}
                                  size={ThemeUtils.fontNormal}
                                  color={Color.TERNARY_TEXT_COLOR}/>
                        </View>) : null}

                        <Image source={{uri: `${Constants.APIConfig.STORAGE_URL}${item?.media_url}`}}

                               style={styles.GridImage}/>
                    </TouchableOpacity>

                )
            })}
        </View>
    };

    return (
        <View style={styles.cont}>


            <Animated.ScrollView scrollEventThrottle={1}
                                 contentContainerStyle={styles.flexGrow}>
                <View>
                    <View style={styles.orderCont}>

                        <Modal visible={isPhotoDetailVisible}
                               onRequestClose={() => setPhotoDetailVisible(false)}>

                                    <PhotoDetails onClose={() => setPhotoDetailVisible(false)}
                                                  loading={replaceImageMutation.loading}
                                                  onReplaceImage={replaceImage}
                                                  replaceVariables={{
                                                      id: imageForPreview?.id,
                                                      type: service?.service_type_id === 9 ? Constants.UploadMediaType.FINN : Constants.UploadMediaType.DS,
                                                      templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                                  }}
                                                  variables={{
                                                      ids: [imageForPreview?.id],
                                                      templateId: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null)
                                                  }}
                                                  deleteMutation={DELETE_PERSONAL_SERVICE_MEDIA}
                                                  refetchQuery={{
                                                      refetchQueries: [{
                                                          query: PERSONAL_TEMPLATE_DETAILS,
                                                          variables: {
                                                              id: serviceFromPage?.office_template?.id ?? (IS_WEB ? decryptId(props.match.params?.id) : null),
                                                              status: 1,
                                                              agentId: user?.id
                                                          }
                                                      }]
                                                  }}
                                                  imageForPreview={imageForPreview}
                                    />
                        </Modal>
                        {renderPhotoGallery()}
                    </View>
                </View>
            </Animated.ScrollView>


        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(OfficePhoto);
