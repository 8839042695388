import {StyleSheet} from "react-native";
import {Color, ThemeUtils} from "components/src/utils";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR,
        marginHorizontal: ThemeUtils.isTablet() ? ThemeUtils.relativeRealWidth(12) : 0,
        marginTop: ThemeUtils.relativeRealHeight(3)
    },
    mob:{
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
    },
    imgTab: {
        height:ThemeUtils.relativeRealHeight(40),
    },
    image: {
        height:ThemeUtils.relativeRealHeight(40)
    }

});
export {styles};
