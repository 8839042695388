const DateUtils = {
    yyyy_mm_dd:'YYYY-MM-DD',
    hh_mm_ss:'HH:mm:ss',
    dd_mm_yyyy:'DD-MM-YYYY',
    hh_mm_a:'HH:mm A',
    hh_mm:'HH:mm',
    yyyy_mm_dd_hh_mm_ss:'YYYY-MM-DD HH:mm:ss',
    hh_mm_dd_mm_yyy:'HH:mm, DD-MM-YYYY',
    MMM_dd_mm_yyyy:"dddd, DD-MM-YYYY",
    dd_mm_yyyy_hh_mm:"DD-MM-YYYY, HH:mm",
};

export default DateUtils;
