import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Color.SECONDARY_BG_COLOR
    },
    lblCont: {
        marginVertical: ThemeUtils.relativeRealHeight(1)
    },
    propertyDetailsCont: {
        marginTop: 20,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    inspectionDetailsCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    utlegCont: {
        flexDirection: 'row',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical: ThemeUtils.relativeRealHeight(1),
        alignItems: 'center'
    },
    serviceLblCont: {
        flex: 1,
        marginHorizontal: ThemeUtils.relativeRealWidth(2)
    },
    btnCont: {
        flexDirection: 'row',
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginVertical: ThemeUtils.relativeRealHeight(2)
    },
    sendOfferCont: {
        marginHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },


});
export {styles};
