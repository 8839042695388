import {StyleSheet} from 'react-native'
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Color.SECONDARY_BG_COLOR,
    },
    divider: {
        backgroundColor: Color.DARK_GRAY,
        width: 1,
    },
    contentCont: {
        flex: 0.5
    },
    datePickerCont: {
        flexDirection:"row",

        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: ThemeUtils.relativeRealHeight(1),
        marginHorizontal: ThemeUtils.relativeRealWidth(2),
        backgroundColor: Color.GRAY,
        borderRadius: 4,
        marginTop: 10
    },
    modalCont: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: ThemeUtils.relativeWidth(20),
        backgroundColor: Color.PRIMARY_TRANSPARENT
    },
    dialogCont: {
        backgroundColor: Color.WHITE,
        padding: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        borderRadius: 8,
        maxHeight: 800
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 20
    },
    btnCont: {
        flexDirection: 'row',
        marginTop: 20
    },
    lblCont: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderColor: Color.PRIMARY,
        borderWidth: 1,
        borderRadius: 4,
        marginEnd: 20,
        marginBottom: 15
    },
    selectedLbl: {
        backgroundColor: Color.SECONDARY,
    },
    pkgCont: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10
    },
    dateCont: {
        flexDirection: 'row',
        alignItems: 'center',
        marginEnd: 20
    },
    energyradio: {
        marginVertical: 10,

    },
    stepperCont: {
        flexDirection: 'row',
        marginHorizontal: ThemeUtils.relativeRealWidth(2),
    },
    dotCont: {
        alignItems: 'center',
    },
    dot: {
        height: 12,
        width: 12,
        marginVertical: 6,
        borderRadius: 6,
        marginLeft: 0.5,
        backgroundColor: Color.SECONDARY
    },
    line: {
        flex: 1,
        marginVertical: 10,
        minHeight: 50,
        backgroundColor: Color.SECONDARY,
        width: 1
    },
    lblStepperCont: {
        flex: 1,
        marginHorizontal: 15,
    },
    stepperTitleCont: {
        flexDirection: 'row',
    },
    markedDateCont:{
        alignSelf:'stretch',
        flexDirection: 'row',
        alignItems:'center'
    },
    lblCompleted: {
        alignSelf: 'flex-start',
        backgroundColor: Color.SECONDARY,
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: 4,
        borderRadius: 4,
        marginTop: 15,
    },
    icDelete:{
        padding:5
    },
    orderConfirm: {
        flexDirection: 'row',
        alignItems: "center",
        marginTop: ThemeUtils.relativeRealHeight(2)
    },
})
