import React from 'react'
//Component
import {ActivityIndicator, Animated, FlatList, Keyboard, View} from 'react-native'
import {
    AddServiceView,
    CostingAddServiceView,
    CustomButton,
    CustomTextArea,
    DatePicker,
    Header,
    Label,
} from "components/src/components";
//Third party
import Provider from "@ant-design/react-native/lib/provider";
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import moment from "moment";
//Redux
import {logout, setToken, setUser} from "components/src/redux/action";
import {connect} from "react-redux";
//Network
import {Mutation, Query} from "@apollo/react-components";
import {SELLER_ADD_PHOTO_SERVICE} from "components/src/api/mutation";
//Utils
import {styles} from './styles.js';
import {
    add1Day,
    add4Hours,
    checkDiff,
    Color,
    CommonStyle,
    Constants,
    DateUtils, decryptId, encryptId,
    formatPrice,
    Icon,
    IS_WEB,
    MessageUtils,
    Routes,
    showMessage,
    Strings,
    ThemeUtils
} from "components/src/utils";
import {PURCHASED_SERVICES} from "components/src/api/query";


// import { DatePickerView } from 'antd-mobile';

class OrderPhoto extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            orderDate: undefined,
            orderDateDisplay: '',

            startTime: undefined,
            earlierStartTimeDisplay: '',

            endTime: undefined,
            latestStartTimeDisplay: '',

            comments: undefined,
            addServices: [],
            selectedAddServices: [],
            selectedAddPhotoServices: [],
            allServices: [],

            totalAmount: 0,
            displayAmount: undefined,
        };
        if (props.property?.order_photo) {
            // IS_WEB ? props.history.goBack() : props.navigation.goBack()
        }
        this.propertyId = IS_WEB ? props.history.location?.state?.id ?? parseInt(decryptId(props.match.params.id), 10) : props.navigation.getParam('id');
        this.scrollY = new Animated.Value(0)
    }

    navigate = () => {
        if (IS_WEB) {
            this.props.history.replace(`/${Routes.Photo}/${encryptId(this.propertyId)}`, {
                id: this.propertyId
            })
        } else {
            this.props.navigation.replace(Routes.Photo, {
                id: this.propertyId
            })
        }
    };


    placeOrder = (placeOrder) => {
        if (!IS_WEB) {
            Keyboard.dismiss()
        }
        const {orderDate, comments, selectedAddServices, selectedAddPhotoServices, startTime, endTime} = this.state;

        if (orderDate) {
            if (startTime) {
                if (endTime) {
                    if (moment(endTime).diff(moment(startTime), 'h') >= Constants.TIME_GAP) {
                        placeOrder({
                            variables: {
                                input: {
                                    property_id: this.propertyId,
                                    order_date: moment(orderDate).format(DateUtils.yyyy_mm_dd),
                                    earliest_start_time: moment(startTime).format(DateUtils.hh_mm_ss),
                                    latest_start_time: moment(endTime).format(DateUtils.hh_mm_ss),
                                    comment: comments,
                                    service_id: selectedAddServices,
                                    vendor_service_ids: selectedAddPhotoServices
                                }
                            }
                        })

                    } else {
                        showMessage(MessageUtils.Errors.errTimeGap,Constants.MessageType.FAILED)
                    }
                } else {
                    showMessage(MessageUtils.Errors.endTime,Constants.MessageType.FAILED)
                }
            } else {
                showMessage(MessageUtils.Errors.startTime,Constants.MessageType.FAILED)
            }
        } else {
            showMessage(MessageUtils.Errors.orderDate,Constants.MessageType.FAILED)
        }
    };

    calculateTotalAmount = () => {
        let addServices = this.state.selectedAddServices;
        let addPhotoServices = this.state.selectedAddPhotoServices;
        let allPurchasedServices = [];

        this.state.addServices.map(service => {
            if (service?.vendor_services?.length) {
                service.vendor_services?.filter(item => addPhotoServices.includes(item.id)).map(item => {
                    allPurchasedServices.push(item)
                })
            } else {
                service.purchased_office_template?.purchased_office_services?.filter(item => addServices.includes(item.id)).map(item => {
                    allPurchasedServices.push(item)
                })
            }
        });

        const total = allPurchasedServices.map(item => {
            if (item?.service_selected?.length && item?.service_selected[0]?.quantity) {
                return parseInt(item.price) * item?.service_selected[0].quantity
            } else {
                return parseInt(item.price)
            }

        }).reduce((a, b) => a + b, 0);

        this.setState({
            totalAmount: total,
            allServices: allPurchasedServices,
            displayAmount: `Kr ${formatPrice(total)},-`
        });
    };

    deleteItem = (id) => {
        let updatedServices = this.state.selectedAddServices.slice();
        updatedServices.splice(updatedServices.indexOf(id), 1);
        this.setState({selectedAddServices: updatedServices}, () => this.calculateTotalAmount())
    };

    deletePhotoItem = (id) => {
        let updatedServices = this.state.selectedAddPhotoServices.slice();
        updatedServices.splice(updatedServices.indexOf(id), 1);
        this.setState({selectedAddPhotoServices: updatedServices}, () => this.calculateTotalAmount())
    };

    addOrRemoveItem = (id, parentId) => {
        let updatedServices = this.state.selectedAddServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }

        this.setState({selectedAddServices: updatedServices}, () => this.calculateTotalAmount())

    };

    addOrRemovePhotoItem = (id, parentId) => {
        let updatedServices = this.state.selectedAddPhotoServices.slice();
        if (updatedServices.includes(id)) {
            updatedServices.splice(updatedServices.indexOf(id), 1)
        } else {
            updatedServices.push(id)
        }

        this.setState({selectedAddPhotoServices: updatedServices}, () => this.calculateTotalAmount())

    };

    renderAdditionalServiceItem = (item, index, parentIndex) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
                            isSelected={this.state.selectedAddServices.includes(item.id)}
                            onPress={() => this.addOrRemoveItem(parseInt(item.id), parentIndex)}/>)
    };

    renderAdditionalPhotoServiceItem = (item, index, parentIndex) => {
        return (
            <AddServiceView title={item.name}
                            price={parseInt(item.price) ? `Kr ${formatPrice(item.price)},-` : item.free_text}
                            image={item.image}
                            name={'disclaimer'}
                            extraNote={item.external_url}
                            mb={9}
                            desc={item.description}
                            isSelected={this.state.selectedAddPhotoServices.includes(item.id)}
                            onPress={() => this.addOrRemovePhotoItem(parseInt(item.id), parentIndex)}/>)
    };

    renderAdditionalService = ({item, index}) => {
        let data = [];
        if (item.vendor_services?.length) {
            data = item.vendor_services.filter(item => !item.service_selected)
        } else {
            data = item.purchased_office_template.purchased_office_services.filter(item => !item.service_selected)
        }
        if (!data?.length) {
            return (<View/>)
        }
        if (item?.vendor_services?.length) {
            return (
                <View key={index.toString()}>
                    <Label font_medium
                           mb={10}>
                        {item.name}
                    </Label>
                    {data.map((item, childIndex) => this.renderAdditionalPhotoServiceItem(item, childIndex, index))}
                </View>
            )
        }
        return (
            <View key={index.toString()}>
                <Label font_medium
                       mb={10}>
                    {item.name}
                </Label>
                {data.map((item, childIndex) => this.renderAdditionalServiceItem(item, childIndex, index))}
            </View>
        )
    };

    renderCostingServiceItem = ({item, index}) => {

        return (
            <CostingAddServiceView title={item.name}
                                   mt={5}
                                   mb={5}
                                   color={Color.SECONDARY}
                                   iconColor={Color.SECONDARY}
                                   price={item.is_free ? item.free_text : `Kr ${formatPrice(item.price)},-`}
                                   onDelete={() => item.vendor_id ? this.deletePhotoItem(item.id) : this.deleteItem(item.id)}/>
        )
    };

    renderTotalCostService = () => {
        return (
            this.state.totalAmount > 0 ?
                <View style={styles.totalAmountMainContainer}>
                    <View style={styles.seperaterView}/>
                    <View style={styles.totalAmountSubContainer}>
                        <CostingAddServiceView color={Color.WHITE}
                                               iconColor={Color.WHITE}
                                               isTotalAmount={true}
                                               title={Strings.totalCosting}
                                               price={this.state.displayAmount}/>
                    </View>
                </View> : null
        )

    };

    render() {
        return (
            <View style={CommonStyle.container}>
                <Provider locale={enUS}>

                    <Header navigation={IS_WEB ? this.props.history : this.props.navigation}
                            animatedTitle={Strings.boligPhoto}
                            animatedValue={this.scrollY}/>
                    <Animated.ScrollView key={"mainSCroll"}
                                         keyboardShouldPersistTaps={'always'}
                                         scrollEventThrottle={1}
                                         onScroll={Animated.event(
                                             [{nativeEvent: {contentOffset: {y: this.scrollY}}}],
                                             {useNativeDriver: true})}>
                        <View style={styles.container}>
                            <View style={styles.topContainer}>
                                <View style={styles.titleCont}>
                                    <Icon
                                        size={ThemeUtils.fontXXLarge}
                                        name={'photo'}
                                        color={Color.PRIMARY_DARK}/>
                                </View>


                                <Label
                                    mt={16}
                                    font_regular
                                    small
                                    align={'left'}>
                                    {Strings.photoServiceInstruction1}
                                </Label>

                                <DatePicker min={new Date(moment().add(1, 'd'))}
                                            title={Strings.date}
                                            onSelectDate={(date) => this.setState({orderDate: date})}
                                            placeholder={Strings.selectDate}/>

                                <Label
                                    mt={16}
                                    mb={10}
                                    small
                                    font_regular
                                    align={'left'}>
                                    {Strings.photoServiceInstruction2}
                                </Label>

                                <DatePicker onSelectDate={(date) => this.setState({
                                    startTime: date,
                                    endTime: checkDiff(date) ? add1Day() : add4Hours(date)
                                })}
                                            title={Strings.startTime}
                                            minTime={new Date(moment().add(1, 'd').set({
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            }))}
                                            maxTime={add1Day()}
                                            placeholder={Strings.selectTime}
                                            round={true}
                                            mode={'time'}/>


                                <DatePicker onSelectDate={(date) => this.setState({endTime: date})}
                                            selectedDate={this.state?.startTime && !this.state.endTime ? checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime) : this.state.endTime ? new Date(moment(this.state.endTime)) : null}
                                            minTime={checkDiff(this.state?.startTime) ? add1Day() : add4Hours(this.state.startTime)}
                                            maxTime={add1Day()}
                                            title={Strings.endTime}
                                            style={{marginTop: 20}}
                                            placeholder={Strings.selectTime}
                                            mode={'time'}/>


                                <CustomTextArea mt={18}
                                                heightInRow={5}
                                                value={this.state.comments}
                                                placeholder={Strings.writeYourComment}
                                                labelText={Strings.comments}
                                                onChange={(comments) => {
                                                    this.setState({comments: comments})
                                                }}/>


                                {/*<Query query={SELLER_PHOTO_SERVICES}
                                       fetchPolicy={'cache-and-network'}
                                       variables={{
                                           status: 1,
                                           propertyId: this.props.property?.id,
                                       }}>
                                    {({loading, data, error}) => {
                                        if (loading && !data) {
                                            return <ActivityIndicator color={Color.PRIMARY}
                                                                      style={CommonStyle.loader}/>
                                        }

                                        return (

                                        )
                                    }}

                                </Query>*/}

                                <Query query={PURCHASED_SERVICES}
                                       onCompleted={(data) => {
                                           let additioanlSServices = [];
                                           data?.purchased_services?.purchased_services.map((service, index) => {
                                               let vendorServices = [];
                                               if (index === 0) {
                                                   vendorServices = data?.purchased_services?.service_types[0];
                                               }
                                               if (index === 1) {
                                                   // vendorServices = data?.purchased_services?.service_types[1];
                                                   return;
                                               }
                                               if (index === 3) {
                                                   vendorServices = data?.purchased_services?.service_types[2];
                                               }
                                               if (index === 4) {
                                                   vendorServices = data?.purchased_services?.service_types[3];
                                               }
                                               if (vendorServices?.vendor_services?.length) {
                                                   additioanlSServices.push(vendorServices)
                                               } else {
                                                   additioanlSServices.push(service)
                                               }
                                           });
                                           this.setState({addServices: additioanlSServices})
                                       }}
                                       variables={{
                                           status: 1,
                                           property_id: parseInt(this.propertyId),
                                           service_category: "PS"
                                       }}>
                                    {(vendorServiceQuery) => {
                                        if (vendorServiceQuery?.loading && !vendorServiceQuery?.data) {
                                            return <ActivityIndicator color={Color.PRIMARY}
                                                                      style={CommonStyle.loader}/>
                                        }
                                        return (
                                            <>
                                                <Label
                                                    mt={18}
                                                    mb={13}
                                                    font_medium
                                                    align={'left'}>
                                                    {Strings.additionalService2}
                                                </Label>
                                                <FlatList data={this.state.addServices}
                                                          extraData={this.state}
                                                          keyExtractor={(item, index) => `${index}`}
                                                          renderItem={this.renderAdditionalService}

                                                />
                                            </>
                                        )
                                    }}
                                </Query>

                            </View>
                            {Array.isArray(this.state.allServices) &&
                            this.state.allServices.length > 0 &&
                            <FlatList
                                style={styles.costingFlatList}
                                data={this.state.allServices}
                                extraData={this.state}
                                keyExtractor={(item, index) => `${index}`}
                                renderItem={this.renderCostingServiceItem}
                                ListFooterComponent={this.renderTotalCostService}
                            />}

                            <Mutation mutation={SELLER_ADD_PHOTO_SERVICE}
                                      onCompleted={this.navigate}
                                      onError={() => {
                                      }}>
                                {(placeOrder, {loading}) => (
                                    <CustomButton
                                        ml={20}
                                        mr={20}
                                        mt={18}
                                        mb={18}
                                        onPress={() => this.placeOrder(placeOrder)}
                                        loading={loading}
                                        title={Strings.order}/>
                                )}

                            </Mutation>
                        </View>
                    </Animated.ScrollView>
                </Provider>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    if (state === undefined) {
        return {};
    }
    return {
        property: state.property,
        token: state.token,
        user: state.user || {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        logout: () => dispatch(logout())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderPhoto);
