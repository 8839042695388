import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from "components/src/utils";

export default StyleSheet.create({
    cont: {
        flex: 1,
        marginTop: ThemeUtils.relativeRealHeight(1.5),

    },
    icTitle: {
        marginTop: 2,
        marginLeft: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        marginBottom: 20
    },
    dashboard: {
        flexGrow: 1,
        backgroundColor: Color.GRAY
    }
})
