import {StyleSheet} from 'react-native';
import {Color, ThemeUtils} from 'components/src/utils';

const style = StyleSheet.create({
    timelineCont: {
        paddingVertical: 20,
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    stepperCont: {
        flexDirection: 'row',
    },
    dotCont: {
        alignItems: 'center',
    },
    dot: {
        height: 12,
        width: 12,
        marginVertical: 6,
        borderRadius: 6,
        marginLeft: 0.5,
        backgroundColor: Color.SECONDARY
    },
    line: {
        flex: 1,
        marginVertical: 10,
        minHeight: 20,
        backgroundColor: Color.SECONDARY,
        width: 1
    },
    lblCont: {
        flex: 1,
        marginHorizontal: 15,
    },
    stepperTitleCont: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    lblCompleted: {
        alignSelf: 'flex-start',
        backgroundColor: Color.PRIMARY_BG_COLOR,
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: 4,
        borderRadius: 4,
        marginVertical: 15,
    },
    lblMoreData: {
        alignSelf: 'flex-start',
        backgroundColor: Color.PRIMARY,
        paddingHorizontal: ThemeUtils.relativeRealWidth(2),
        paddingVertical: 4,
        borderRadius: 4,
        marginTop: 15,
    },
    icTimeline:{
        marginBottom:5,
        flexDirection:'row',
        alignItems:'center',
        marginStart:ThemeUtils.COMMON_HORIZONTAL_MARGIN
    },
    icon:{
        height:ThemeUtils.fontXLarge,
        width:ThemeUtils.fontXLarge,
    }
});

export default style;
