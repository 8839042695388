import {StyleSheet} from 'react-native';
import {ThemeUtils} from '../../../../../utils';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: ThemeUtils.relativeRealHeight(6)
    },
    vendorCont: {
        paddingHorizontal: ThemeUtils.COMMON_HORIZONTAL_MARGIN,
        paddingVertical:ThemeUtils.relativeRealHeight(2)
    },
    lbl:{
        marginTop:ThemeUtils.relativeRealHeight(1)
    }
});

export default styles;
