export default {
  'component.sellerfooter.find-us': 'Hvor Finner Du Oss',
  'component.sellerfooter.corporate-info': 'Selskapsinformasjon',
  'component.sellerfooter.media-press': 'Medie og Presse',
  'component.sellerfooter.social-media': 'Sosiale Medier',
  'component.sellerfooter.footer-menulist1.item1': 'Webmegling AS',
  'component.sellerfooter.footer-menulist1.item2': 'Kokstadflaten 35',
  'component.sellerfooter.footer-menulist1.item3': '5257 KOKSTAD',
  'component.sellerfooter.footer-menulist2.item1': 'Kontaktinformasjon',
  'component.sellerfooter.footer-menulist2.item2': 'Karriere',
  'component.sellerfooter.footer-menulist3.item1': 'Kommer snart',
  'component.sellerfooter.privacy': 'Personvern',
  'component.sellerfooter.terms': 'Brukervilkår',
  'component.sellerfooter.cookie': 'Informasjonskapsler',
};
