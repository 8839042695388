export default {
  'component.agentinspection.title': 'Befaringsapplikasjon',
  'component.agentinspection.description':
    'Når vi selger hjemmet ditt, er det lovpålagt at en av våre meglere kommer til ditt hjem og vedlikeholder hjemmet ditt. Du bestiller tid for dette på denne siden. Megleren vil da kontakte deg for å bekrefte klokkeslettet.',
  'component.agentinspection.oppdragno': 'Oppdragsnr.',
  'component.agentinspection.sellername': 'Selgernavn',
  'component.agentinspection.time': 'Tid',
  'component.agentinspection.inspectionagent': 'Inspeksjonsagent',
  'component.agentinspection.propertylist': 'Eiendomsliste',
  'component.agentinspection.personaltemplate': 'Rediger personlig mal',
  'component.agentinspection.searchbar': 'Søk etter oppdragsnr. eller addresse',
  'component.agentinspection.clientname': 'Navn',
  'component.agentinspection.datetime': 'Befaring dato&tid',
  'component.agentinspection.propertydetail': 'Eiendomsdetaljer',
  'component.agentinspection.clientemail': 'Epost',
  'component.agentinspection.clientphone': 'Telefon',
  'component.agentinspection.confirmdesc':
    'Klikk nedenfor for å sende befaringsbekreftelse på epost til kunden.',
  'component.agentinspection.inspectionconfirmation': 'Send befaringsbekreftelse',
  'component.agentinspection.choosetemplate': 'Velg befaringsmal',
  'component.agentinspection.chooseservice': 'Velg variable utlegg',
  'component.agentinspection.salesguarantee': 'Er det salgsgaranti?',
  'component.agentinspection.editservice': 'Rediger tjeneste',
  'component.agentinspection.servicename': 'Tjenestenavn',
  'component.agentinspection.serviceprice': 'Tjenestepris',
  'component.agentinspection.startinspection': 'Start befaring',
  'component.agentinspection.sendoffer': 'Send tilbud',
  'component.agentinspection.summarybefaring': 'Sammendrag fra Befaring',
  'component.agentinspection.headline': 'Overskrift',
  'component.agentinspection.photos': 'Bilder / Styling',
  'component.agentinspection.360degree': '360 Visning',
  'component.agentinspection.drone': 'Drone',
  'component.agentinspection.video': 'Film',
  'component.agentinspection.digitalsignature': 'Digital markedsføring',
  'component.agentinspection.introfilm': 'SoMe Film',
  'component.agentinspection.wads': 'W Ads',
  'component.agentinspection.digitalsalesassi': 'Digital salgsoppgave',
  'component.agentinspection.printmarketing': 'Print markedsføring',
  'component.agentinspection.newspaperadvertising': 'Avisannonsering',
  'component.agentinspection.saleposter': 'Til salgs plakat',
  'component.agentinspection.broker': 'Megler',
  'component.agentinspection.homeowner-insurance': 'Boligselgerforsikring',
  'component.agentinspection.openpdf': 'Åpne PDF',
  'component.agentinspection.missioncost': 'Oppdragskostnader',
  'component.agentinspection.setpreference': 'Oppdragskostnader',
  // 'component.agentinspection.setpreference': 'Angi preferansen',
  'component.agentinspection.preferprice': 'Din foretrukne pris',
  'component.agentinspection.setpercentage': 'Angi prosent',
  'component.agentinspection.setpercentage.form': 'prosent',
  'component.agentinspection.befaring': 'W - Befaring',
  'component.agentinspection.productgallery': 'Produktgalleri',
  'component.agentinspection.befaringtitle': 'Befaringsbekreftelse',
  'component.agentinspection.uploadimages': 'Last opp bilder',
  'component.agentinspection.surveyconfirmation': 'Noen ord fra megler',
  'component.agentinspection.missioncost.questions': 'Spørsmål',
  'component.agentinspection.missioncost.gotoassiagree': 'Gå til oppdragsavtale',
};
