import React, {useState} from 'react'
import {View} from 'react-native'
import {Header, LogoForHeader, Notes} from "components/src/components";
import {Color, CommonStyle, Constants, decryptId, IS_WEB, Routes, showMessage, ThemeUtils} from "components/src/utils";
import styles from './styles';
import BoliselgerforsikringMobile from './Mobile';
import BoliselgerforsikringTab from './Tab';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BOLIG_SELGER_INSURANCE} from "../../../../../../api/query";
import {connect} from "react-redux";
import {SELECT_INSURANCE_TYPE} from "../../../../../../api/mutation";




function Boligselgerforsikring(props) {
    const {history, inspection, navigation} = props;
    const {property} = inspection

    const [visible, setVisible] = useState(false)
    let propertyId = IS_WEB ? decryptId(props.match.params.id) : property?.id
    const [setInsurance, setInsuranceMutation] = useMutation(SELECT_INSURANCE_TYPE)
    const {loading, data, error} = useQuery(BOLIG_SELGER_INSURANCE, {
        variables: {
            propertyId: parseInt(propertyId)
        }
    })

  /*  const handleOptionsChange = (id) => {
        setInsurance({
            variables: {
                propertyId: propertyId,
                id: id
            }
        }).then((data) => {
            showMessage(data?.data?.select_boligselger_Insurance?.meta.message, Constants.MessageType.SUCCESS)
        }).catch(() => {

        })
    };
*/
    const handleOptionsChange = ({type,id}) => {
        setInsurance({
            variables: {
                propertyId: propertyId,
                id: id,
                insuranceType:type
            }
        }).then((data) => {
        }).catch(() => {

        })
    };

    const renderNoteModal = () => {
        return (

            <Notes visible={visible}
                   id={props.inspection?.id}
                   onDashboardPress={() => {
                       IS_WEB ? history.goBack() : navigation.goBack()
                       setVisible(false)
                   }}
                   onClose={(data) => {
                       // setNotes(data)

                       setVisible(false)
                   }}/>

        )
    }


    return (
        <View style={[styles.container, {overflow: 'hidden',}]}>
            {renderNoteModal()}

            <Header headerRightSecond={'faq'}
                    headerRightThird={'edit'}
                    onPressRightThird={() => setVisible(true)}
                    onPressRightSecond={() => IS_WEB ? history.push(`/${Routes.ClientQuestion}`) : navigation.navigate(Routes.ClientQuestion)}
                    headerRightFirst={'dashboard'}
                    onPressRightFirst={() => IS_WEB ? history.goBack() : navigation.goBack()}
                    renderTitle={() => <LogoForHeader/>}
                    tintColor={Color.SECONDARY} navigation={IS_WEB ? history : navigation}/>
            <View style={CommonStyle.flex}>
                {ThemeUtils.isTablet() ? <BoliselgerforsikringTab {...props}
                                                                  data={data?.boligselger_Insurance_list}
                                                                  onChangeOptions={handleOptionsChange}
                                                                  loading={loading}/> :
                    <BoliselgerforsikringMobile {...props}
                                                data={data?.boligselger_Insurance_list}
                                                onChangeOptions={handleOptionsChange}
                                                loading={loading}/>}
            </View>
        </View>

    )
}

const mapStateToProps = (state) => {

    return {
        inspection: state.inspection,
    };
};
export default connect(mapStateToProps)(Boligselgerforsikring)
